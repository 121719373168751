<template>
  <div
      :id="id"
      :key="keyChange"
      ref="elem"
      :config-properties="JSON.stringify(internal_config)"
      :data-hide-desktop="!internal_config.showDesktop"
      :data-hide-mobile="!internal_config.showMobile"
      :style="style()"
      class="sitebuilder-element-container"
      element="LottieFileComponent">
    <div
        class="sitebuilder-element-lottie-file-content"
        :class="{'sitebuilder-element-lottie-file-content-with-controls' : internal_config.controls}"
        :style="{
          background: internal_config.backgroundColor
      }">
      <lottie-vue-player
          :key="`lottie-player-${keyPlayer}`"
          :autoplay="internal_config.autoplay"
          :backgroundColor="internal_config.backgroundColor"
          :loop="internal_config.loop"
          :player-controls="internal_config.controls"
          :speed="internal_config.speed"
          :src="internal_config.code ? `https://cheetah-api.builderall.com/api/get-lottie-files/${internal_config.code}.json` : internal_config.url"
          :style="styleLottieContent()">
      </lottie-vue-player>
    </div>
  </div>
</template>
<script>

import Model from './Model';
import {merge} from "lodash";

export default {
  name: 'LottieFileComponent',
  components: {},
  props: ['config', 'childs', 'height_start', 'device', 'id', 'preview'],
  data() {
    return {
      ...Model.data(),
      keyPlayer: 1,
      loading: false,
    };
  },
  computed: {},
  created() {
  },
  mounted() {
    var vm = this;
    this.$root.$on('setLottieFileObj', (idElement) => {
      if (+this.id === +idElement) {
        vm.keyPlayer += 1
      }
    });
    this.build(this.$props.config);
    // this.$root.$emit('applyAnimation', this, 'sitebuilder-element-container');
  },
  methods: {
    reloadElement() {
      if (!this.loading) {
        this.loading = true
        this.keyPlayer += 1
        setTimeout(() => {
          this.loading = false
        }, 200)
      }
    },
    width() {
      return this.internal_config.width;
    },
    height() {
      return this.internal_config.height;
    },
    minHeight() {
      return this.internal_config.minHeight;
    },
    animation() {
      return false;
    },
    onResize: function (x, y, width, height) {
      this.internal_config.width = width + 'px';
      this.internal_config.height = height + 'px';
    },
    focusElement(e) {
      this.$root.$emit('focusElement', this);

      e.stopPropagation();
      e.preventDefault();
    },
    showResources() {
    },
    handler: function (e) {
      this.$root.$emit('focusElement', this);
      this.$root.$emit('handlerElement', this, true);
      e.stopPropagation();
      e.preventDefault();
    },
    style() {
      const styleObj = {
        width: this.internal_config.width,
        'max-width': this.internal_config.maxWidth,
        height: this.internal_config.height,
        'min-height': this.internal_config.minHeight,
        left: this.internal_config.left,
        'z-index': this.internal_config.zIndex,
        margin: '0 auto',
        'padding-top': this.internal_config.paddingTop,
        'padding-left': this.internal_config.paddingLeft,
        'padding-right': this.internal_config.paddingRight,
        'padding-bottom': this.internal_config.paddingBottom,
        top: this.internal_config.top
      };

      if (this.device === 'mobile') {
        return merge(styleObj, {height: this.internal_config.minHeight});
      }

      return styleObj;
    },

    styleLottieContent() {

      if (this.device === 'mobile') {
        return {width: '100%', height: this.minHeight()};
      }

      return {width: '100%', height: this.internal_config.height,};
    },

    build(config) {
      delete config.animation
      this.internal_config = merge(this.internal_config, config);
      this.$forceUpdate();
      this.reloadElement()
    },
  },
};

</script>

<style lang="scss">
.sitebuilder-element-lottie-file-content {
  &.sitebuilder-element-lottie-file-content-with-controls {
    position: relative;

    #lottie-controls {
      position: absolute;
    }
  }

  .vue-lottie-player {
    background: transparent;
  }
}
</style>
