var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:("mtf card-list desktop-element clean-theme-desktop " + (!!(_vm.position & 1) ? 'alt': '')),style:(_vm.cardStyle())},[_c('div',{staticClass:"card-list__content-info"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show.thumbnail),expression:"show.thumbnail"}],staticClass:"card-list__thumbnail",style:(_vm.thumbnailStyle())}),_c('ul',{staticClass:"details"},[_c('li',{staticClass:"author"},[_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.show.author),expression:"show.author"}],style:(_vm.authorStyle()),attrs:{"href":_vm.showUrl(("/a/" + (_vm.article.author.slug)))},domProps:{"textContent":_vm._s(_vm.article.author.name)}})]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.show.date),expression:"show.date"}],staticClass:"date",style:(_vm.authorStyle()),domProps:{"textContent":_vm._s(_vm.formatDate(_vm.article.scheduled_date, _vm.config.dateFormat))}}),_vm._l((_vm.article.categories),function(ref){
var id = ref.id;
var name = ref.name;
var slug = ref.slug;
return _c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.show.categories),expression:"show.categories"}],key:id,staticClass:"categories"},[_c('a',{style:(_vm.categoryStyle()),attrs:{"href":_vm.showUrl(("/c/" + slug))},domProps:{"textContent":_vm._s(name)}})])})],2)]),_c('div',{staticClass:"card-list__content-preview",style:({
      backgroundColor: _vm.colors.color_2
    })},[_c('a',{staticClass:"clean-text-css",style:(_vm.titleStyle()),attrs:{"href":_vm.showUrl(("/blog/" + (_vm.article.url)))},domProps:{"textContent":_vm._s(_vm.article.title)}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.show.content),expression:"show.content"}],staticClass:"clean-text-css",style:(_vm.contentStyle()),domProps:{"innerHTML":_vm._s(_vm.article.content.substr(0, 300))}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.show.button),expression:"show.button"}],staticClass:"read-more",style:(_vm.alignButton())},[_c('a',{staticClass:"clean-text-css",style:(_vm.buttonStyle()),attrs:{"href":_vm.showUrl(("/blog/" + (_vm.article.url)))},domProps:{"textContent":_vm._s(_vm.config.readMoreText)}})])])]),_c('div',{class:("card-list mobile-element clean-theme-mobile " + (!!(_vm.position & 1) && 'alt')),style:(_vm.cardStyle())},[_c('div',{staticClass:"card-list__content-info",style:(_vm.contentInfo())},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show.thumbnail),expression:"show.thumbnail"}],staticClass:"card-list__thumbnail",style:(_vm.thumbnailStyle(false))}),_c('ul',{staticClass:"details"},[_c('li',{staticClass:"author"},[_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.show.author),expression:"show.author"}],style:(_vm.authorStyle(false)),attrs:{"href":_vm.showUrl(("/a/" + (_vm.article.author.slug)))},domProps:{"textContent":_vm._s(_vm.article.author.name)}})]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.show.date),expression:"show.date"}],staticClass:"date",style:(_vm.authorStyle(false)),domProps:{"textContent":_vm._s(_vm.formatDate(_vm.article.scheduled_date, _vm.config.dateFormat))}}),_vm._l((_vm.article.categories),function(ref){
    var id = ref.id;
    var name = ref.name;
    var slug = ref.slug;
return _c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.show.categories),expression:"show.categories"}],key:id,staticClass:"categories"},[_c('a',{style:(_vm.categoryStyle(false)),attrs:{"href":_vm.showUrl(("/c/" + slug))},domProps:{"textContent":_vm._s(name)}})])})],2)]),_c('div',{staticClass:"card-list__content-preview",style:({
      backgroundColor: _vm.colors.color_2
    })},[_c('a',{staticClass:"clean-text-css",style:(_vm.titleStyle(false)),attrs:{"href":_vm.showUrl(("/blog/" + (_vm.article.url)))},domProps:{"textContent":_vm._s(_vm.article.title)}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.show.content),expression:"show.content"}],staticClass:"clean-text-css",style:(_vm.contentStyle(false)),domProps:{"innerHTML":_vm._s(_vm.article.content.substr(0, 300))}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.show.button),expression:"show.button"}],staticClass:"read-more",style:(_vm.alignButton())},[_c('a',{style:(_vm.buttonStyle(false)),attrs:{"href":_vm.showUrl(("/blog/" + (_vm.article.url)))},domProps:{"textContent":_vm._s(_vm.config.readMoreText)}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }