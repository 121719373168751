export default {
  data() {
    return {
      props: {
        height: '200px',
        width: '200px',
        minHeight: '50px',
        zIndex: 'auto',
        rotation: '0deg',
        opacity: 1,
        allImages: [
          'https://cdn.pixabay.com/photo/2015/12/12/15/24/amsterdam-1089646_1280.jpg',
          'https://cdn.pixabay.com/photo/2015/12/12/15/24/amsterdam-1089646_1280.jpg',
          'https://cdn.pixabay.com/photo/2015/05/15/14/27/eiffel-tower-768501_1280.jpg',
          'https://cdn.pixabay.com/photo/2015/12/12/15/24/amsterdam-1089646_1280.jpg',
          'https://cdn.pixabay.com/photo/2015/12/12/15/24/amsterdam-1089646_1280.jpg',
          'https://cdn.pixabay.com/photo/2015/12/12/15/24/amsterdam-1089646_1280.jpg',
          'https://cdn.pixabay.com/photo/2015/12/12/15/24/amsterdam-1089646_1280.jpg',
        ],
        images: [],
        columns: 3,
        theme: 'matriz',
        imagesPerPage: 6,
        navigationWithNumber: true,
        navigationBackground: '#FFFFFF',
        navigationHover: '#C0C1C2',
        navigationActive: '#41A9FF',
        currentPage: 0,
        currentImage: 0,
        horizontalMargin: 5,
        verticalMargin: 5,
        lastPage: 0,
        //isShowImage: false,
        //showImageUrl: "",
        overflow: 'hidden',
        border: '0 none #000000',
        boxShadow: '1px 1px 1px 1px blue',
        borderRadius: '0 0 0 0',
        paddingTop: '15px',
        paddingLeft: '15px',
        paddingRight: '15px',
        paddingBottom: '15px',
        marginLeft: '0',
        marginRight: '0',
        animation: {
          in: {
            action: 'time', //time/scroll/click/hover
            time: 0, // sec
            scroll: 0, // px
            effect: 'none',
            speed: 'slow',
            infinite: false
          },
          out: {
            action: 'time', //time/scroll/click
            time: 0, // sec
            scroll: 0, // px
            effect: 'none',
            delay: '1',
            speed: '1',
            infinite: false
          }
        },
      },
      internal_config: {
        zIndex: 'auto',
        rotation: '0deg',
        overflow: 'hidden',
        allImages: [
          'https://cdn.pixabay.com/photo/2015/12/12/15/24/amsterdam-1089646_1280.jpg',
          'https://cdn.pixabay.com/photo/2015/12/12/15/24/amsterdam-1089646_1280.jpg',
          'https://cdn.pixabay.com/photo/2015/05/15/14/27/eiffel-tower-768501_1280.jpg',
          'https://cdn.pixabay.com/photo/2015/12/12/15/24/amsterdam-1089646_1280.jpg'
        ],
        columns: 3,
        images: [],
        imagesPerPage: 6,
        navigationWithNumber: true,
        navigationBackground: '#FFFFFF',
        navigationHover: '#C0C1C2',
        navigationActive: '#41A9FF',
        currentPage: 0,
        currentImage: 0,
        lastPage: 0,
        //isShowImage: false,
        //showImageUrl: "",
        height: '200px',
        width: '200px',
        minHeight: '50px',
        left: '0px',
        top: '0px',
        showDesktop: true,
        showMobile: true,
        opacity: 1,
        boxShadow: '0 0 0 0 black initial',
        borderRadius: '0 0 0 0',
        paddingTop: '15px',
        paddingLeft: '15px',
        paddingRight: '15px',
        paddingBottom: '15px',
        marginLeft: '0',
        marginRight: '0',
        
          
      },
      name: 'image-gallery'
    };
  }
};
