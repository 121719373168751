<template>
    <div class="mx-sm-5 mt-sm-3">
        <div class="table-members-area-title">
            <p class="clean-text-css">{{ $t('ct-tab-areas-title')}}</p>
        </div>
        <template v-if="loading">
            <div class="d-flex justify-content-center mb-4 mt-2">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </template>
        <template v-else>

            <div class="table-members-area">
                <div class="table-members-area-row table-th row d-sm-flex d-none">
                    <div class="table-members-area-col col-12" :class="hasExpireDate ? 'col-sm-3' : 'col-sm-4'">{{ $t('ct-member-name')}}</div>
                    <div class="table-members-area-col" :class="hasExpireDate ? 'col-sm-3' : 'col-sm-4'">{{ $t('ct-member-description') }}</div>
                    <div class="table-members-area-col col-sm-2" v-if="hasExpireDate">{{ $t('ct-member-expire') }}</div>
                    <div class="table-members-area-col col-sm-2">{{ $t('ct-member-status') }}</div>
                    <div class="table-members-area-col col-sm-2"></div>
                </div>

                <div class="table-members-area-row row" v-for="(area, index) in members_areas" :key="index">
                    <div class="table-members-area-col col-12" :class="(hasExpireDate) ? 'col-sm-3' : 'col-sm-4'">
                        <p class="d-block d-sm-none clean-text-css p-0">{{ $t('ct-member-name')}}</p>
                        <span>{{area.name}}</span>
                    </div>
                    <div class="table-members-area-col" :class="hasExpireDate ? 'col-sm-3' : 'col-sm-4'">
                        <p class="d-block d-sm-none clean-text-css">{{ $t('ct-member-description')}}</p>
                        <span>{{area.description}}</span>
                    </div>

                    <div class="table-members-area-col col-12 col-sm-2" v-if="hasExpireDate">
                        <p class="d-block d-sm-none clean-text-css">{{ $t('ct-member-expire')}}</p>
                        <span v-if="area.user_permission && area.user_permission.expire">{{ getDate(area.user_permission.expire)}}</span>
                        <span v-else>-</span>
                    </div>
                    
                    <div class="table-members-area-col col-sm-2">
                        <p class="d-block d-sm-none clean-text-css">{{ $t('ct-member-status')}}</p>
                        <template v-if="area.user_permission">
                            <span v-if="area.user_permission.active">{{ $t('ct-member-enable')}}</span>
                            <span v-if="!area.user_permission.active">{{ $t('ct-member-disabled')}}</span>
                        </template>
                        <template v-else-if="parseInt(area.type_id) === 1">
                            <span>{{ $t('ct-member-enable')}}</span>
                        </template>
                        <template v-else>
                            <span>{{ $t('ct-member-disabled')}}</span>
                        </template>
                    </div>
                    <div v-if="area.name !== 'blog'" class="table-members-area-col col-sm-2">
                        <p class="d-block d-sm-none clean-text-css">{{ $t('ct-member-go-to-area')}}</p>
                        <a href="#" class="clean-text-css" @click.prevent="goToHomePageRestrict(area)" :title="$t('ct-member-go-to-area')"><i class="mdi mdi-account-arrow-right"></i></a>
                    </div>
                    <div v-else class="table-members-area-col col-sm-2">
                        <p class="d-block d-sm-none clean-text-css">{{ $t('ct-member-go-to-area')}}</p>
                        <a href="/feed" style="color: black; text-decoration: inherit;" class="clean-text-css" :title="$t('ct-member-go-to-area')"><i class="mdi mdi-account-arrow-right"></i></a>
                    </div>
                    <div class="table-members-area-col col-12 d-block d-sm-none">
                        <div class="divisor-mobile"></div>
                    </div>

                </div>
            </div>

        </template>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import {getInfoUser} from "../../../../js/WebsiteUser";
    import Errors from "../../../../js/Errors";
    import {mixinRestrictArea} from "../../../../js/mixins/restrictArea";
    import axios from "axios";

    export default {
        name: 'Areas',
        mixins: [mixinRestrictArea],
        data() {
            return {
                errors: new Errors(),
                loading: true,
                members_areas: false,
                hasExpireDate: false
            }
        },
        methods: {

            getDate(d, type = 'date') {
                var date = new Date(d);

                function addZero(n) {
                    return parseInt(n) > 9 ? "" + n : "0" + n;
                }

                let minutes = [date.getHours(), date.getMinutes()].join(":");

                if (typeof OPTIONS_INIT_SITE !== 'undefined' && typeof OPTIONS_INIT_SITE.options !== 'undefined' && typeof OPTIONS_INIT_SITE.options.lang !== 'undefined' &&
                 OPTIONS_INIT_SITE.options.lang === "pt_BR") {
                    return `${[
                    addZero(date.getDate()),
                    addZero(date.getMonth() + 1),
                    date.getFullYear()
                    ].join("/")} ${type === "datetime" ? addZero(minutes) : ""}`;
                }
                
                return `${[
                    addZero(date.getMonth() + 1),
                    addZero(date.getDate()),
                    date.getFullYear()
                ].join("/")} ${type === "datetime" ? addZero(minutes) : ""}`;
            },
            async goToHomePageRestrict(area) {

                let redirect_config = (area.redirect_config && this.$isJson(area.redirect_config)) ? JSON.parse(area.redirect_config) : false;

                if ( parseInt(area.elearning_course_id) && redirect_config && redirect_config.elearning && redirect_config.elearning.type === 'elearning-course') {

                    let md5Password = localStorage.getItem('login-user-token-md5');
                    let loginUser = localStorage.getItem('login-user');

                    loginUser = this.$isJson(loginUser) ? JSON.parse(loginUser) : false;

                    let target = redirect_config.elearning.target === 'blank' && this.open_by === 'page' ? 'blank' : 'same';
                    let id = area.id;

                    await axios.post('/website/user/elearning/get-course/' + id + '/' + loginUser.email + '/' + md5Password)
                        .then((res) => {

                            if (res.data.success) {
                                if (target === 'blank') {
                                    window.open(res.data.url_course, '_blank');
                                } else
                                    window.location.href = res.data.url_course;
                            }
                        })
                        .finally(() => this.loading = false);

                } else {

                    let homePage = pagesConfig.find((page) => page !== null && parseInt(page.id) === parseInt(area.home_page_id));
                    if (homePage) {
                        window.location.href = `${window.origin}/${homePage.url}`
                    }
                }

            }
        },
        computed: {
            ...mapGetters(['userWebsite']),
        },
        created() {

            this.members_areas = (!!restrictsArea) ? restrictsArea : false;

            if (!this.userWebsite.members_area) {
                getInfoUser()
                    .then(({data}) => {

                        if (data.success) {
                            let user = data.data;

                            if (user.restrict_permissions && user.restrict_permissions.length > 0) {
                                this.$store.dispatch('setPermissions', user.restrict_permissions);
                                let vm = this;
                                user.restrict_permissions.map(permission => {
                                    let member = vm.members_areas.find(member => parseInt(member.id) === parseInt(permission.restrict_area_code));
                                    member['user_permission'] = permission;

                                    if(member.user_permission && member.user_permission.expire)
                                        vm.hasExpireDate = true;
                                });

                                this.members_areas = Object.assign({}, this.members_areas, vm.members_areas);
                                this.$store.dispatch('setMembersAreas', this.members_areas);
                            }
                        }
                    }).catch(error => console.error(error))
                    .finally(() => this.loading = false);
            } else this.loading = false
        }
    }
</script>

<style lang="scss" scoped>

    .table-members-area-title {
        margin-right: -15px;
        margin-left: -15px;
        margin-bottom: 5px;

        p{
            font-size: 18px !important;
            padding-right: 15px !important;
            padding-left: 15px !important;
        }
    }

    i{
        font-size: 25px !important;
        padding-right: 15px !important;
        padding-left: 15px !important;
        cursor: pointer;
    }

    .table-members-area {
        .table-th {
            border-bottom: 1px solid #ddd;
            margin-bottom: .5rem;
            font-weight: bold !important;
        }

        .table-members-area-row {
            padding: .25rem 0;

            .table-members-area-col {
                p {
                    font-size: 16px !important;
                    font-weight: bold !important;
                    &:not(.p-0){
                        padding: .5rem 0 0 !important;
                    }
                }
            }
        }

        .divisor-mobile {
            border-bottom: 1px solid #ddd;
            padding: .25rem 0;
        }
    }
</style>