export default {
  data() {
    return {
      'originUid': '',
      iconLoading: false,
      errorMessage: [],
      idSimpleForm: 1 + Math.floor((200000 - 1) * Math.random()),
      props: {
        'currentIndex': 0,
        'tag': '',
        'horizontal': false,
        'zIndex': 'auto', 'maxWidth': '500px',
        'fieldAnimated': false,

        'socialproof_campaign_id': '0',
        'socialproof_user_name_tag': '0',
        'columns' : 1,

        "navBar": {
          "type": "number",
          "showTitle": true,
          "backgroundColorActive": "#5B5B5BFF",
          "backgroundColorInactive": "#FFFFFFFF",
          "borderRadius": "60px 60px 60px 60px",
          "border": "0 none #000000",
          "activeTextColor": "#FFFFFFFF",
          "inactiveTextColor": "#5B5B5BFF",
          "titleTextColor": "#5B5B5BFF",
          "size": "12px",
          "titleSize": "9px"
        },

        "form": {
          "showTitle": true,
          "showSteps": false,
          "showDescription": true,
          "showPreviousButton": true
        },

        horizontal_config: {
          custom: false,
          container : { width: 50 },
          btnPlace : { width: 50 , padding: 15},
          fields: []
        },

        horizontal_config_mobile: {
          custom: false,
          container: {width: 50},
          btnPlace: {width: 50, padding: 15},
          fields: [],
        },

        newField: {
          'tag': 'EMAIL',
          'label': 'Email',
          'required': 'yes',
          'help_text': null,
          'visibility': 'visible',
          'default_value': null,
          'type': {'name': 'Text', 'identifier': 'text', 'description': 'Text'},
          'options': [],
          'icon': {'selected': null, 'position': null}
        },
        "groups": [
          {
            "name": "Personal details",
            "description": "Tell us something more about you",
            "icon": "",
            "fields": [
              {
                "tag": "firstname",
                "label": "First Name",
                "required": "yes",
                "help_text": "",
                "visibility": "visible",
                "default_value": null,
                "type": {
                  "name": "Text",
                  "identifier": "text",
                  "description": "Text"
                },
                "options": [
                ],
                "status": "edit",
                "field": "text"
              },
              {
                "tag": "lastname",
                "label": "Last Name",
                "required": "yes",
                "help_text": "",
                "visibility": "visible",
                "default_value": null,
                "type": {
                  "name": "Text",
                  "identifier": "text",
                  "description": "Text"
                },
                "options": [
                ],
                "status": "add",
                "field": "text"
              },
              {
                "tag": "phonenumber",
                "label": "Phone Number",
                "required": "no",
                "help_text": "",
                "visibility": "visible",
                "default_value": null,
                "type": {
                  "name": "Text",
                  "identifier": "text",
                  "description": "Text"
                },
                "options": [
                ],
                "status": "add",
                "field": "text"
              }
            ]
          },
          {
            "name": "Social Profiles",
            "description": "Your presence on the social network",
            "icon": "",
            "fields": [
              {
                "tag": "twitter",
                "label": "Twitter",
                "required": "no",
                "help_text": "",
                "visibility": "visible",
                "default_value": null,
                "type": {
                  "name": "Text",
                  "identifier": "text",
                  "description": "Text"
                },
                "options": [
                ],
                "status": "edit",
                "field": "text"
              },
              {
                "tag": "facebook",
                "label": "Facebook",
                "required": "no",
                "help_text": "",
                "visibility": "visible",
                "default_value": null,
                "type": {
                  "name": "Text",
                  "identifier": "text",
                  "description": "Text"
                },
                "options": [
                ],
                "status": "add",
                "field": "text"
              },
              {
                "tag": "google",
                "label": "Google Plus",
                "required": "no",
                "help_text": "",
                "visibility": "visible",
                "default_value": null,
                "type": {
                  "name": "Text",
                  "identifier": "text",
                  "description": "Text"
                },
                "options": [
                ],
                "status": "add",
                "field": "text"
              }
            ]
          },
          {
            "name": "Create your account",
            "description": "Fill in your credentials",
            "icon": "",
            "fields": [
              {
                "tag": "email",
                "label": "Email",
                "required": "yes",
                "help_text": "",
                "visibility": "visible",
                "default_value": null,
                "type": {
                  "name": "Text",
                  "identifier": "text",
                  "description": "Text"
                },
                "options": [
                ],
                "status": "add",
                "field": "text"
              },
              {
                "tag": "password",
                "label": "Password",
                "required": "yes",
                "help_text": "",
                "visibility": "visible",
                "default_value": null,
                "type": {
                  "name": "Text",
                  "identifier": "text",
                  "description": "Text"
                },
                "options": [
                ],
                "status": "add",
                "field": "text"
              },
              {
                "tag": "confirpassword",
                "label": "Confirm Password",
                "required": "yes",
                "help_text": "",
                "visibility": "visible",
                "default_value": null,
                "type": {
                  "name": "Text",
                  "identifier": "text",
                  "description": "Text"
                },
                "options": [
                ],
                "status": "add",
                "field": "text"
              }
            ]
          }
        ],
        'target': '_self',
        'action': '',
        'redirect': '',
        'successMessage': '',
        'isRequiredMessage': '',
        'receiver': '',
        'subject': '',
        'showLabel': true,
        'showPlaceholder': true,
        'placeholderColor': '#5c5c5c',
        'recaptchaSiteKey': '',
        'recaptchaSecretKey': '',

        'left': '0',
        'top': '0',
        'rotation': '0deg',

        'width': '300px',
        'height': '300px',
        'border': '0 none #000000',

        'labelStyle': '#000000',

        'paddingTop': '15px',
        'paddingLeft': '15px',
        'paddingRight': '15px',
        'paddingBottom': '15px',

        'inputHeight': '38px',
        'textareaHeight': '',
        'inputBgColor': '#FFFFFF',
        'inputBorder': '1px solid #000000',
        'inputRadius': '0 0 0 0',

        'inputStyle': {
          'family': 'Arial',
          'size': '20px',
          'color': '#000000',
          'align': 'left',
          'weight': '400',
          'style': 'initial'
        },
        'animation': {
          'in': {
            'action': 'time', // time/scroll/click/hover
            'time': 0, // sec
            'scroll': 0, // px
            'effect': 'none',
            'speed': 'slow',
            'infinite': false
          },
          'out': {
            'action': 'time', // time/scroll/click
            'time': 0, // sec
            'scroll': 0, // px
            'effect': 'none',
            'delay': '1',
            'speed': '1',
            'infinite': false
          }
        },
        'button': {
          'height': '50px',
          'width': '50%',
          'text': 'My Button',
          'subText': '',
          'icon': '',
          'iconPosition': 'left',
          'border': '0 none #000000',
          'borderRadius': '0 0 0 0',
          'backgroundColor': '#21309b',
          'backgroundColorHover': '#21309b',
          'textColorHover': '#FFFFFF',
          'backgroundImage': 'none', /* url("photographer.jpg"); The image used */
          'backgroundPosition': 'center', /* Center the image todo this resource, not possible change at the moment */
          'backgroundOrigin': 'border-box', /* padding-box|border-box|content-box Do not repeat the image */
          'backgroundRepeat': 'no-repeat', /* repeat | repeat-x | repeat-y | no-repeat | space | round   Do not repeat the image */
          'backgroundSize': 'cover', /* 'auto | 50% | 100% 100% | cover | contain  'Resize the background image to cover the entire container */
          'boxShadow': '1px 1px 1px 1px blue',
          'margin': '15px',
          'textStyle': {
            'family': 'arial, helvetica, sans-serif',
            'size': '15px',
            'sizeMobile': '',
            'color': 'White',
            'align': 'center',
            'weight': '700',
            'style': 'initial'
          },
          'subTextStyle': {
            'family': 'arial, helvetica, sans-serif',
            'size': '15px',
            'sizeMobile': '',
            'color': 'White',
            'align': 'center',
            'weight': '400',
            'style': 'initial'
          }
        },
        "buttonPrevious": {
          "height": "50px",
          "width": "100%",
          "text": "Previous",
          "subText": "",
          "icon": "",
          "iconPosition": "left",
          "border": "0 none #000000",
          "borderRadius": "60px 60px 60px 60px",
          "backgroundColor": "#aCbEd0",
          "backgroundColorHover": "#81a5c8",
          "backgroundImage": "none",
          "backgroundPosition": "center",
          "backgroundOrigin": "border-box",
          "backgroundRepeat": "no-repeat",
          "backgroundSize": "cover",
          "boxShadow": "1px 1px 1px 1px #5B5B5B00 ",
          "textStyle": {
            "family": "Muli, sans-serif",
            "size": "16px",
            "color": "White",
            "align": "center",
            "weight": "700",
            "style": "initial"
          },
          "subTextStyle": {
            "family": "arial, helvetica, sans-serif",
            "size": "15px",
            "color": "White",
            "align": "center",
            "weight": "400",
            "style": "initial"
          },
          "subtext": ""
        },
        "buttonNext": {
          "height": "50px",
          "width": "100%",
          "text": "Next",
          "subText": "",
          "icon": "",
          "iconPosition": "left",
          "border": "0 none #000000",
          "borderRadius": "60px 60px 60px 60px",
          "backgroundColor": "#5B5B5BFF",
          "backgroundColorHover": "#5B5B5BFF",
          "backgroundImage": "none",
          "backgroundPosition": "center",
          "backgroundOrigin": "border-box",
          "backgroundRepeat": "no-repeat",
          "backgroundSize": "cover",
          "boxShadow": "1px 1px 1px 1px #5B5B5B00 ",
          "textStyle": {
            "family": "Muli, sans-serif",
            "size": "16px",
            "color": "White",
            "align": "center",
            "weight": "700",
            "style": "initial"
          },
          "subTextStyle": {
            "family": "arial, helvetica, sans-serif",
            "size": "15px",
            "color": "White",
            "align": "center",
            "weight": "400",
            "style": "initial"
          },
          "subtext": ""
        },
      },
      internal_config: {

        "navBar": {
          "type": "number",
          "showTitle": true,
          "backgroundColorActive": "#5B5B5BFF",
          "backgroundColorInactive": "#FFFFFFFF",
          "borderRadius": "60px 60px 60px 60px",
          "border": "0 none #000000",
          "activeTextColor": "#FFFFFFFF",
          "inactiveTextColor": "#5B5B5BFF",
          "titleTextColor": "#5B5B5BFF",
          "size": "12px",
          "titleSize": "9px"
        },

        "form": {
          "showTitle": true,
          "showSteps": false,
          "showDescription": true,
          "showPreviousButton": true
        },

        'currentIndex': 0,
        'zIndex': 'auto',
        'fieldAnimated': false,
        "groups": [
          {
            "name": "Personal details",
            "description": "Tell us something more about you",
            "icon": "",
            "fields": [
              {
                "tag": "firstname",
                "label": "First Name",
                "required": "yes",
                "help_text": "",
                "visibility": "visible",
                "default_value": null,
                "type": {
                  "name": "Text",
                  "identifier": "text",
                  "description": "Text"
                },
                "options": [
                ],
                "status": "edit",
                "field": "text"
              },
              {
                "tag": "lastname",
                "label": "Last Name",
                "required": "yes",
                "help_text": "",
                "visibility": "visible",
                "default_value": null,
                "type": {
                  "name": "Text",
                  "identifier": "text",
                  "description": "Text"
                },
                "options": [
                ],
                "status": "add",
                "field": "text"
              },
              {
                "tag": "phonenumber",
                "label": "Phone Number",
                "required": "no",
                "help_text": "",
                "visibility": "visible",
                "default_value": null,
                "type": {
                  "name": "Text",
                  "identifier": "text",
                  "description": "Text"
                },
                "options": [
                ],
                "status": "add",
                "field": "text"
              }
            ]
          },
          {
            "name": "Social Profiles",
            "description": "Your presence on the social network",
            "icon": "",
            "fields": [
              {
                "tag": "twitter",
                "label": "Twitter",
                "required": "no",
                "help_text": "",
                "visibility": "visible",
                "default_value": null,
                "type": {
                  "name": "Text",
                  "identifier": "text",
                  "description": "Text"
                },
                "options": [
                ],
                "status": "edit",
                "field": "text"
              },
              {
                "tag": "facebook",
                "label": "Facebook",
                "required": "no",
                "help_text": "",
                "visibility": "visible",
                "default_value": null,
                "type": {
                  "name": "Text",
                  "identifier": "text",
                  "description": "Text"
                },
                "options": [
                ],
                "status": "add",
                "field": "text"
              },
              {
                "tag": "google",
                "label": "Google Plus",
                "required": "no",
                "help_text": "",
                "visibility": "visible",
                "default_value": null,
                "type": {
                  "name": "Text",
                  "identifier": "text",
                  "description": "Text"
                },
                "options": [
                ],
                "status": "add",
                "field": "text"
              }
            ]
          },
          {
            "name": "Create your account",
            "description": "Fill in your credentials",
            "icon": "",
            "fields": [
              {
                "tag": "email",
                "label": "Email",
                "required": "yes",
                "help_text": "",
                "visibility": "visible",
                "default_value": null,
                "type": {
                  "name": "Text",
                  "identifier": "text",
                  "description": "Text"
                },
                "options": [
                ],
                "status": "add",
                "field": "text"
              },
              {
                "tag": "password",
                "label": "Password",
                "required": "yes",
                "help_text": "",
                "visibility": "visible",
                "default_value": null,
                "type": {
                  "name": "Text",
                  "identifier": "text",
                  "description": "Text"
                },
                "options": [
                ],
                "status": "add",
                "field": "text"
              },
              {
                "tag": "confirpassword",
                "label": "Confirm Password",
                "required": "yes",
                "help_text": "",
                "visibility": "visible",
                "default_value": null,
                "type": {
                  "name": "Text",
                  "identifier": "text",
                  "description": "Text"
                },
                "options": [
                ],
                "status": "add",
                "field": "text"
              }
            ]
          }
        ],
        'left': '0',
        'top': '0',
        'rotation': '0deg',

        'target': '_self',
        'action': '',
        'receiver': '',
        'subject': '',
        'showLabel': true,
        'showPlaceholder': true,
        'placeholderColor': '#5c5c5c',
        'recaptchaSiteKey': '',
        'recaptchaSecretKey': '',

        'width': '300px',
        'height': '300px',
        'border': '0 none #000000',

        'paddingTop': '15px',
        'paddingLeft': '15px',
        'paddingRight': '15px',
        'paddingBottom': '15px',

        'inputHeight': '38px',
        'inputBgColor': '#FFFFFF',
        'inputBorder': '1px solid #000000',
        'inputRadius': '0 0 0 0',

        'inputStyle': {
          'family': 'Arial',
          'size': '20px',
          'color': '#000000',
          'align': 'left',
          'weight': '400',
          'style': 'initial'
        },
        'animation': {
          'in': {
            'action': 'time', // time/scroll/click/hover
            'time': 0, // sec
            'scroll': 0, // px
            'effect': 'none',
            'speed': 'slow',
            'infinite': false
          },
          'out': {
            'action': 'time', // time/scroll/click
            'time': 0, // sec
            'scroll': 0, // px
            'effect': 'none',
            'delay': '1',
            'speed': '1',
            'infinite': false
          }
        },
        newField: {
          'tag': 'EMAIL',
          'label': 'Email',
          'required': 'yes',
          'help_text': null,
          'visibility': 'visible',
          'default_value': null,
          'type': {'name': 'Text', 'identifier': 'text', 'description': 'Text'},
          'options': [],
          'icon': {'selected': null, 'position': null}
        },
        'button': {
          'height': '50px',
          'width': '50%',
          'text': 'My Button',
          'subText': '',
          'icon': '',
          'iconPosition': 'left',
          'border': '0 none #000000',
          'borderRadius': '0 0 0 0',
          'backgroundColor': '#21309b',
          'backgroundColorHover': '#21309b',
          'backgroundImage': 'none', /* url("photographer.jpg"); The image used */
          'backgroundPosition': 'center', /* Center the image todo this resource, not possible change at the moment */
          'backgroundOrigin': 'border-box', /* padding-box|border-box|content-box Do not repeat the image */
          'backgroundRepeat': 'no-repeat', /* repeat | repeat-x | repeat-y | no-repeat | space | round   Do not repeat the image */
          'backgroundSize': 'cover', /* 'auto | 50% | 100% 100% | cover | contain  'Resize the background image to cover the entire container */
          'boxShadow': '1px 1px 1px 1px blue',
          'margin': '15px',
          'textStyle': {
            'family': 'arial, helvetica, sans-serif',
            'size': '15px',
            'sizeMobile': '',
            'color': 'White',
            'align': 'center',
            'weight': '700',
            'style': 'initial'
          },
          'subTextStyle': {
            'family': 'arial, helvetica, sans-serif',
            'size': '15px',
            'sizeMobile': '',
            'color': 'White',
            'align': 'center',
            'weight': '400',
            'style': 'initial'
          }
        },
        "buttonPrevious": {
          "height": "50px",
          "width": "100%",
          "text": "Previous",
          "subText": "",
          "icon": "",
          "iconPosition": "left",
          "border": "0 none #000000",
          "borderRadius": "60px 60px 60px 60px",
          "backgroundColor": "#aCbEd0",
          "backgroundColorHover": "#81a5c8",
          "backgroundImage": "none",
          "backgroundPosition": "center",
          "backgroundOrigin": "border-box",
          "backgroundRepeat": "no-repeat",
          "backgroundSize": "cover",
          "boxShadow": "1px 1px 1px 1px #5B5B5B00 ",
          "textStyle": {
            "family": "Muli, sans-serif",
            "size": "16px",
            "color": "White",
            "align": "center",
            "weight": "700",
            "style": "initial"
          },
          "subTextStyle": {
            "family": "arial, helvetica, sans-serif",
            "size": "15px",
            "color": "White",
            "align": "center",
            "weight": "400",
            "style": "initial"
          },
          "subtext": ""
        },
        "buttonNext": {
          "height": "50px",
          "width": "100%",
          "text": "Next",
          "subText": "",
          "icon": "",
          "iconPosition": "left",
          "border": "0 none #000000",
          "borderRadius": "60px 60px 60px 60px",
          "backgroundColor": "#5B5B5BFF",
          "backgroundColorHover": "#5B5B5BFF",
          "backgroundImage": "none",
          "backgroundPosition": "center",
          "backgroundOrigin": "border-box",
          "backgroundRepeat": "no-repeat",
          "backgroundSize": "cover",
          "boxShadow": "1px 1px 1px 1px #5B5B5B00 ",
          "textStyle": {
            "family": "Muli, sans-serif",
            "size": "16px",
            "color": "White",
            "align": "center",
            "weight": "700",
            "style": "initial"
          },
          "subTextStyle": {
            "family": "arial, helvetica, sans-serif",
            "size": "15px",
            "color": "White",
            "align": "center",
            "weight": "400",
            "style": "initial"
          },
          "subtext": ""
        },
      }
    };
  }
};
