<template>
  <div
    :id="id"
    :class="`sitebuilder-element-container ${internal_config.fullWidth ? 'full-width-element' : ''}`"
    element="BookingCalendarComponent"
    :data-hide-desktop="!internal_config.showDesktop"
    :data-hide-mobile="!internal_config.showMobile"
    :config-properties="JSON.stringify(internal_config)"
    :style="style()">
      <div
        v-if="internal_config.calendar.src"
        class="embed-responsive embed-responsive-16by9"
        :style="{
          width : width,
          height : internal_config.fullHeight ? '100%' : height,
          minHeight : minHeight
          }"
        >
        <iframe
          :width="internal_config.width"
          :height="internal_config.height"
          :src="internal_config.calendar.src"
          :style="{
            borderRadius: radius,
            border : border
          }"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          class="embed-responsive-item"
        />
      </div>
      <div v-else>
        <div style="background-color: white; height: 300px; width: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column">
          <h6 class="text-center">{{ $t('ct-select-booking-calendar') }}</h6>
        </div>
      </div>
  </div>
</template>
<script>

	import Model from './Model';

	export default {
		name: 'BookingCalendarComponent',
		props: ['config', 'device', 'id', 'preview'],
		data() {
			return Model.data()
		},
		computed: {
			width() {
				return this.internal_config.width;
			},
			minHeight() {
				return this.internal_config.minHeight;
			},
			height() {
				return this.internal_config.height;
			},
			border() {
				return this.internal_config.border;
			},
			radius() {
				return this.internal_config.borderRadius;
			},
		},
		methods: {
			style() {
				let style = `left:  ${this.internal_config.left};
                     padding-top: ${this.internal_config.paddingTop};
                     padding-left: ${this.internal_config.paddingLeft};
                     z-index: ${this.internal_config.zIndex};max-width: ${this.internal_config.maxWidth};margin: 0 auto;
                     padding-right: ${this.internal_config.paddingRight};
                     padding-bottom: ${this.internal_config.paddingBottom};
                     top: ${this.internal_config.top}`;
				return style;
			},
		},
		mounted() {
			let device, childs, id, config;
			({device, childs, id, config} = this.$props);

			this.internal_config = config;
			this.device = device;

			if (typeof id !== 'undefined') {
				this.id = id;
      }

			this.internal_config = window.mergeRecursive(this.props, this.$props.config);

      this.$root.$on('setConfigElement', (id, obj) => {
        if(this.id == id){
          this.config = obj;
          this.internal_config = obj;
        }
      });

			let objIds = [];
			$('[id]').each(function () {
				var ids = $('[id="' + this.id + '"]');
				if (ids.length > 1 && ids[0] == this && parseInt(this.id))
					objIds.push(this.id);
			});
			if (objIds.includes(this.id)) {
				this.id = Date.now() + parseInt(Math.random() * 10000);
			}
		}
	}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  [data-device="desktop"] .embed-responsive {
    min-height: unset !important;
  }
</style>
