export default {
    data() {
      return {
        sidebar: '2',
        keyChange: 0,
        props: {
          'name': 'LaunchAppComponent',
          'zIndex': 'auto',
          'paddingTop': '0px',
          'paddingLeft': '15px',
          'paddingRight': '15px',
          'paddingBottom': '15px'
        },
        internal_config: {
          'width': '500px',
          'height': '100px',
          'zIndex': 'auto',
          'showDesktop': true,
          'showMobile': true,
          'paddingTop': '0px',
          'paddingLeft': '15px',
          'paddingRight': '15px',
          'paddingBottom': '15px',
          left: '0px',
          top: '0px',
          show: {
              ranking: false,
              count: false,
              share_theme: 'circle',
              share_align: 'center',
              share_copy: true,
              share_email: true,
              share_facebook: true,
              share_twitter: true,
              share_linkedin: true,
              share_whatsapp: true,
              share_telegram: true,
          },
          url: 'page',
          rank_position: '-',
          rank_total: '-',
          input: {
            height: '30px',
            family: 'Lato, sans-serif',
            size: '25px',
            sizeMobile: '15px',
            color: 'black',
            align: 'left',
            weight: '400',
            style: 'initial',
            stroke: 'none',
          },
          button: {
            theme: 'circle',
            background: '#fff',
            color: '#000',
            text: 'copy',
            width: '60px',
            height: '60px',
            radius: '0%',
            family: 'Lato, sans-serif',
            size: '25px',
            sizeMobile: '15px',
            color: 'black',
            align: 'left',
            weight: '400',
            style: 'initial',
            stroke: 'none',
            verticalPadding: '5px',
            horizontalPadding: '5px',
            paddingLeft: '5px',
          },
          texts: {
            padding: {
              left: '0px',
              top: '20px',
              between: '20px'
            },
            position_title: {
              family: 'Lato, sans-serif',
              size: '25px',
              sizeMobile: '15px',
              color: 'black',
              align: 'left',
              weight: '400',
              style: 'initial',
              stroke: 'none'
            },
            position_result: {
              family: 'Lato, sans-serif',
              size: '25px',
              sizeMobile: '15px',
              color: 'black',
              align: 'left',
              weight: '400',
              style: 'initial',
              stroke: 'none'
            },
            count_title: {

            },
            count_result: {

            }
          }
        }
      };
    }
  };
  