<template>
    <form @submit.prevent="submitLogin" method="POST" ref="form-login">

        <div class="alert alert-danger" v-if="errors.has('alert')">
            <p class="mb-0 clean-text-css">{{ errors.get('alert') }}</p>
        </div>

        <div class="alert alert-danger" v-if="errors.has('failed')">
            <p class="mb-0 clean-text-css">{{ errors.get('failed') }}</p>
        </div>

        <div class="alert alert-warning" v-if="errors.has('throttle')">
            <p class="mb-0 clean-text-css">{{ errors.get('throttle') }}</p>
        </div>

        <template v-if="(hasAuthBoth() && !login_token) || (this.auth_types.includes('register') && !login_token) || blog_login">
            <div class="form-group">
                <input
                        type="email"
                        id="email"
                        class="form-control form-control-alternative"
                        v-model="user.email"
                        :class="{'is-invalid': errors.has('email') }"
                        :placeholder="$t('ct-email')"
                        @keydown="errors.clear('email')"
                        required
                />
                <span v-if="errors.has('email')" class="invalid-feedback" v-text="errors.get('email')"></span>
            </div>
            <div class="form-group">
                <input
                        type="password"
                        id="password"
                        class="form-control form-control-alternative"
                        v-model="user.password"
                        :class="{'is-invalid': errors.has('password') }"
                        :placeholder="$t('ct_password_')"
                        @keydown="errors.clear('password')"
                        required
                />
                <span v-if="errors.has('password')" class="invalid-feedback" v-text="errors.get('password')"></span>
            </div>
        </template>
        <template v-else-if="hasAuthBoth()">
            <div class="form-group">
                <input
                        type="text"
                        id="token"
                        class="form-control form-control-alternative"
                        v-model="user.token"
                        :class="{'is-invalid': errors.has('token') }"
                        :placeholder="$t('ct-token')"
                        @keydown="errors.clear('token')"
                        required
                />
                <span v-if="errors.has('token')" class="invalid-feedback" v-text="errors.get('token')"></span>
            </div>
        </template>
        <!-- <div class="form-group text-center">-->
        <!--     <a href="#" @click.prevent="changeType('remember')">{{ $t('ct-forgot-password')}}</a>-->
        <!-- </div>-->

        <div class="form-group text-center d-flex align-items-center justify-content-center flex-column">
            <button type="submit" class="btn btn-success text-center"
                    v-if="(hasAuthBoth() && !login_token) || (this.auth_types.includes('register') && !login_token) || blog_login">{{
                $t('ct-sign-in')}}
            </button>
            <button type="submit" class="btn btn-success text-center mt-2" v-if="hasAuthBoth() && login_token">{{
                $t('ct-sign-in') }}
            </button>
            <button class="btn btn-success text-center mt-2" v-if="hasAuthBoth() && login_token"
                    @click.prevent="login_token = false">{{ $t('ct-sign-in-email-password')}}
            </button>
            <button @click.prevent="login_token = true" class="btn btn-success text-center mt-2"
                    v-if="hasAuthBoth() && !login_token">{{ $t('ct-sign-in-token')}}
            </button>
            <button @click.prevent="changeType('login-ba')" class="btn btn-success text-center mt-2"
                    v-if="login_ba">{{$t('ct-access-ba-credentials')}}</button>
        </div>

    </form>
</template>

<script>

    import Errors from "../../../../js/Errors";
    import {loginRegister, loginToken} from "../../../../js/WebsiteUser";
    import {mixinRestrictArea} from "../../../../js/mixins/restrictArea";

    export default {
        name: "Login",
        mixins: [mixinRestrictArea],
        data() {
            return {
                errors: new Errors(),
                user: {
                    email: '',
                    password: ''
                },

                auth_types: 'register',
                login_token: false,
                login_ba: false,
                blog_login: false,
            }
        },

        methods: {

            changeType(active) {
                this.$emit('change', active)
            },

            hasAuthBoth() {



                let RestrictAreaVue = (typeof this.$parent.$parent.setLogin === 'function') ? this.$parent.$parent : this.$parent.$parent.$parent;
                this.auth_types = RestrictAreaVue.auth_types;

                if (this.auth_types.includes('register') && !this.auth_types.includes('token') && !this.auth_types.includes('both'))
                    this.login_token = false;

                if(window.location.pathname.indexOf('blog-login') !== -1) {
                    this.blog_login = true;
                }

                if (this.auth_types.includes('login-ba')){
                    this.login_ba = true;
                }

                return this.auth_types.includes('both') || (this.auth_types.includes('token') && this.auth_types.includes('register'));



            },

            successThen(data){
                let RestrictAreaVue = (typeof this.$parent.$parent.setLogin === 'function') ? this.$parent.$parent : this.$parent.$parent.$parent;

                if (data.success) {
                    RestrictAreaVue.setLogin(data.data);
                } else {
                    if (data.validator) {
                        this.errors.record(data.errors);
                    }
                }
            },

            errorCatch(data){
                if (data && data.validator) {
                    this.errors.record(data.errors);
                } else
                    this.errors.record({alert: [this.$t('ct-login-error')]});
            },

            loginToken() {
                loginToken(this.user)
                    .then((res) => {
                        this.successThen(res.data);
                    })
                    .catch(error => {
                        this.errorCatch(error.data);
                    })
                    .finally(() => this.$loading.hide());
            },

            loginRegister() {
                loginRegister(this.user)
                    .then((res) => {
                        this.successThen(res.data);
                    })
                    .catch(error => {
                        this.errorCatch(error.response.data);
                    })
                    .finally(() => this.$loading.hide());
            },

            submitLogin() {

                this.user.layout_id = this.getCurrentPage().layout_id;
                this.errors.clear();
                this.$loading.show();

                if (this.login_token) {
                    this.loginToken();
                } else {
                    this.loginRegister();
                }
            }
        }
    }
</script>
