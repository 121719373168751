<template>
    <div :data-field-group-name="data.visibility !== 'hidden' ? data.tag : ''">
        <div :class="[this.icon && this.icon.selected ? 'input-group mb-3' : 'form-group']" class="form-datetime"
             v-show="this.data.visibility !== 'hidden'">
            <label v-show="this.showLabel"
                   :class="{'d-block w-100': this.showLabel && this.icon && this.icon.selected }"> {{label}} </label>
            <div class="input-group-prepend" v-if="this.icon && this.icon.position === 'left'">
                <span class="input-group-text"><i :class="['mdi', this.icon.selected]"></i></span>
            </div>
            <datetime ref="item" :type="type" v-model="value" input-class="form-control" :name="this.data.tag" value-zone="local" zone="local"
                      :placeholder="placeholder"></datetime>
            <input type="hidden" v-if="is_mailingboss" v-model="value_real" :name="this.data.tag" :placeholder="placeholder">
            <div class="input-group-prepend" v-if="this.icon && this.icon.position === 'right'">
                <span class="input-group-text"><i :class="['mdi', this.icon.selected]"></i></span>
            </div>
            <small  style="width: 100%" class="form-text text-muted" v-show="data.help_text != ''"
                   :class="{'d-block w-100': this.showLabel && this.icon && this.icon.selected }">{{data.help_text}}</small>
        </div>
    </div>
</template>

<script>

    // Import this component
    import {Datetime} from 'vue-datetime';


    export default {
        props: ['label', 'data', 'value', 'type', 'showPlaceholder', 'showLabel', 'icon', 'is_mailingboss'],
        components: {
            Datetime
        },
        data() {
            return {
              value_real: ''
            }
        },
        computed: {
            placeholder() {
                return this.showPlaceholder ? this.label : ''
            },
            getTimeZone() {
                try {
                    console.log('Intl.DateTimeFormat().resolvedOptions().timeZone', Intl.DateTimeFormat().resolvedOptions().timeZone);
                    return Intl.DateTimeFormat().resolvedOptions().timeZone
                } catch (e) {
                    return '';
                }
            }
        },
        watch: {
            'value': function (value) {
              if(value) {
                this.value_real = this.formatValue(value);
              }
            }
        },
        methods: {
            formatValue(d) {
                function addZero(n){
                    return parseInt(n) > 9 ? "" + n: "0" + n;
                }

                var date = new Date(d);
                let minutes = [addZero(date.getHours()), addZero(date.getMinutes()), addZero(date.getSeconds())].join(':');
                return `${[date.getFullYear(), addZero(date.getMonth() + 1), addZero(date.getDate())].join('-')} ${this.type === 'datetime' ? minutes : ''}`;
            }
        }
    }


</script>

<style lang="scss" scoped>
    .form-datetime {
        div.vdatetime {
            flex: 1;
        }
    }
</style>
