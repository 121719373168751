export default {
  data() {
    return {
      props: {
        'tag': '',
        'width': '550px',
        'zIndex': 'auto',
        'maxWidth': '500px',

        'height': '50px',
        'heightContainer': '300px',

        'heightBody': '90px',

        'paddingTop': '15px',
        'paddingLeft': '15px',
        'paddingRight': '15px',
        'paddingBottom': '15px',

        'showDesktop': true,
        'showMobile': true,

        'border': '0 none #000000',
        'backgroundColor': '#F0F0F0',
        'borderRadius': '0 0 0 0',

        'borderContainer': '0 none #000000',
        'borderRadiusContainer': '0 0 0 0',
        'backgroundColorContainer': '#F0F0F0',

        'textStyle': {
          'family': 'arial, helvetica, sans-serif',
          'size': '15px',
          'sizeMobile': '15px',
          'color': '#000000',
          'align': 'center',
          'weight': '700',
          'style': 'initial',
          'stroke' : 'none'
        },

        'icon': '',
        'iconPosition': 'left',

        'animation': {
          'in': {
            'action': 'time',   //time/scroll/click/hover
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'speed': 'slow',
            'infinite': false,
          },
          'out': {
            'action': 'time',   //time/scroll/click
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'delay': '1',
            'speed': '1',
            'infinite': false,
          },
        }
      },
      elements: [],
      internal_config: {
        'tag': '',
        'height': '50px',
        'width': '550px',
        'zIndex': 'auto',
        'maxWidth': '500px',

        showDesktop: true,
        showMobile: true,

        'paddingTop': '15px',
        'paddingLeft': '15px',
        'paddingRight': '15px',
        'paddingBottom': '15px',

        'animation': {
          'in': {
            'action': 'time',   //time/scroll/click/hover
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'speed': 'slow',
            'infinite': false,
          },
          'out': {
            'action': 'time',   //time/scroll/click
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'delay': '1',
            'speed': '1',
            'infinite': false,
          },
        }
      },
      name:
        'accordion',
    }
  }
}
