export default {
  data() {
    return {
      props: {
        'tag': '',
        'height': '150px',
        'width': '550px',
        'zIndex': 'auto',
        'maxWidth': '500px',

        'paddingTop': '5px',
        'paddingLeft': '5px',
        'paddingRight': '5px',
        'paddingBottom': '5px',


        'paddingTopContainer': '5px',
        'paddingLeftContainer': '5px',
        'paddingRightContainer': '5px',
        'paddingBottomContainer': '5px',


        'border': '0 none #000000',
        'boxShadow': '0 0 0 0 black initial',
        'background-color': '#000000',
        'backgroundImage': 'none',/*url("photographer.jpg"); The image used */
        'backgroundPosition': 'center', /* Center the image todo this resource, not possible change at the moment */
        'backgroundOrigin': 'border-box', /* padding-box|border-box|content-box Do not repeat the image */
        'backgroundRepeat': 'no-repeat', /*repeat | repeat-x | repeat-y | no-repeat | space | round   Do not repeat the image */
        'backgroundSize': 'cover', /* 'auto | 50% | 100% 100% | cover | contain  'Resize the background image to cover the entire container */
        'borderRadius': '0 0 0 0',

        'showDesktop': true,
        'showMobile': true,
        'backDrop': false,

        'animation': {
          'in': {
            'action': 'time',   //time/scroll/click/hover
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'speed': 'slow',
            'infinite': false,
          },
          'out': {
            'action': 'time',   //time/scroll/click
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'delay': '1',
            'speed': '1',
            'infinite': false,
          },
        }
      },
      elements: [],
      internal_config: {
        'tag': '',
        'height': '50px',
        'width': '550px',
        'zIndex': 'auto',
        'maxWidth': '500px',

        showDesktop: true,
        showMobile: true,

        'paddingTop': '15px',
        'paddingLeft': '15px',
        'paddingRight': '15px',
        'paddingBottom': '15px',

        'animation': {
          'in': {
            'action': 'time',   //time/scroll/click/hover
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'speed': 'slow',
            'infinite': false,
          },
          'out': {
            'action': 'time',   //time/scroll/click
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'delay': '1',
            'speed': '1',
            'infinite': false,
          },
        }
      },
      name:
        'slidergroup',
    }
  }
}
