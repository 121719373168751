<template>
    <div :id="this.id"
         class="sitebuilder-element-container"
         element="CounterTextComponent"
         :data-hide-desktop="!this.internal_config.showDesktop"
         :data-hide-mobile="!this.internal_config.showMobile"
         :style="style()">

        <div class="vdr" :style="{ 'transform': transform }">
            <div class="text-area"
                 v-bind:style="{height : height,width : width,border : border,'border-radius': radius,background: background,'box-shadow': boxShadow,}">
                <a :style="textStyle()" ref="obj">{{ internal_config.init }}</a>
            </div>
        </div>
    </div>
</template>

<script>

import Data from './Model';
import mixinComponents from "@/js/mixins/MixinComponents";

export default {
    name: 'CounterTextComponent',
    props: ['config', 'id', 'preview', 'device'],
    mixins: [mixinComponents],
    data() {
        return Data.data();
    },

    computed: {

        background() {
            let bg = this.internal_config.backgroundColor;

            if (this.internal_config.backgroundImage && this.internal_config.backgroundImage !== 'none') {
                bg += ' url("' + this.internal_config.backgroundImage + '")';
                bg += ' ' + this.internal_config.backgroundPosition;
                bg += ' / ' + this.internal_config.backgroundSize;
                bg += ' ' + this.internal_config.backgroundRepeat;
                bg += ' ' + this.internal_config.backgroundOrigin;
            }

            return bg;
        },
        transform() {
            return `rotate(${this.$props.device === 'desktop' ? this.internal_config.rotation : 0}) scale(1)`;
        }
    },

    methods: {

        build(config) {
            this.internal_config = config;

        },

        textStyle() {
            return ` width: ${this.internal_config.width};
                 height: ${this.internal_config.height};
                 font-family: ${this.internal_config.textStyle.family};
                 font-size: ${this.internal_config.textStyle.size};
                 text-align: ${this.internal_config.textStyle.align};
                 font-weight: ${this.internal_config.textStyle.weight};
                 font-style: ${this.internal_config.textStyle.style};
                 text-decoration: ${this.internal_config.textStyle.stroke};
                 color: ${this.internal_config.textStyle.color}`;
        },

        style() {
            return `left:  ${this.internal_config.left};
				padding-top: ${this.internal_config.paddingTop};
                padding-left: ${this.internal_config.paddingLeft};
                z-index: ${this.internal_config.zIndex};max-width: ${this.internal_config.maxWidth};margin: 0 auto;
                padding-right: ${this.internal_config.paddingRight};
                padding-bottom: ${this.internal_config.paddingBottom};
				top: ${this.internal_config.top};`;
        },

        animateValue(obj, start, end, duration) {
            var range = end - start;
            var current = start;
            var increment = end > start ? 1 : -1;
            var stepTime = Math.abs(Math.floor(duration / range));
            var timer = setInterval(function () {
                current += increment;
                obj.innerHTML = current;
                if (current == end) {
                    clearInterval(timer);
                }
            }, stepTime);
        }
    },

    mounted() {
        this.build(this.$props.config);

        this.internal_config = window.mergeRecursive(this.props, this.$props.config);

        this.$el.classList.add('preview');

        let vm = this;

        this.$el.addEventListener('mouseover', function () {
            document.body.style.setProperty('--button-background-color--hover', vm.internal_config.backgroundColorHover);
            document.body.style.setProperty('--button-text-color--hover', vm.internal_config.textColorHover);
        });

        this.animateValue(this.$refs.obj, parseFloat(this.internal_config.init), parseFloat(this.internal_config.finish), parseInt(this.internal_config.timer));
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

@media (max-width: 800px) {
    .text-area {
        width: auto !important;

        a {
            width: 300px !important;
        }
    }
}

[data-device="mobile"] .text-area {
    width: auto !important;

    a {
        width: 300px !important;
    }
}

.text-area a {
    text-align: center;
    display: table-cell;
    vertical-align: middle;
}

.preview .text-area:hover {
    cursor: pointer;
    color: var(--button-text-color--hover) !important;
}

</style>
