<template>
  <div :id="id"
       class="sitebuilder-element-container"
       element="CheckoutComponent"
       :data-hide-desktop="!internal_config.showDesktop"
       :data-hide-mobile="!internal_config.showMobile"
       :data-tag="dataTag"
       :style="style">

    <div
        ref="mountingPoint"
        class="s-checkout-widget pt-2"
        :dir="dir()"
    ></div>
    </div>
</template>

<script>

import Data from './Model';
import {apiUrl} from './scTools'

export default {
  name: 'CheckoutComponent',
  props: ['config', 'id', 'preview', 'device'],
  data() {
    return Data.data();
  },
  computed: {
    dataTag() {
      if (this.internal_config.tag) {
        return this.internal_config.tag;
      }
      return '';
    },
    style() {
      let width = `${this.internal_config.width}`;
      if (!width.match(/px$/)) {
        width += 'px';
      }
      let maxWidth = `${this.internal_config.steps === 3 ? 768 : 450}`;
      if (!maxWidth.match(/px$/)) {
        maxWidth += 'px';
      }
      return `left:  ${this.internal_config.left};
              z-index: ${this.internal_config.zIndex};
              width:  ${width};
              max-width:  ${maxWidth};
              margin: 0 auto;
              top: ${this.internal_config.top}`;
    }
  },
  methods: {
      dir() {
          var idiomaNavegador = (navigator.browserLanguage !== undefined) ? navigator.browserLanguage : navigator.language;

          if (
              (typeof langUser != 'undefined' && ['he', 'ja', 'ar'].includes(langUser)) ||
              (typeof idiomaNavegador != 'undefined' && ['he', 'ja', 'ar'].includes(idiomaNavegador.substring(0, 2)))
          ) {
              return 'rtl';
          } else return false;
      },
    build(config) {
      this.internal_config = {...this.internal_config, ...config};
    },
    updateHeightParent() {
      let jqElement = $('#' + this.id);
      let heightFinal = parseInt(jqElement.css('height')) + parseInt(jqElement.css('top'));
      if (parseInt(this.$parent.height) < heightFinal) {
        this.$parent.internal_config.height = heightFinal + 'px';
      }
    },
    apiUrl,

    loadAssets() {
      if (!window.checkoutConfigs) {
        window.checkoutConfigs = {baseURL: apiUrl(), sellable: {}}
      }

      window.checkoutConfigs.sellable[this.internal_config.sellableHash] = {...this.internal_config}

      this.$refs.mountingPoint.innerHTML = ''
      let mount = document.createElement('div')
      mount.classList.add('sc-widget')
      mount.setAttribute('data-version', this.internal_config.version)
      mount.setAttribute('data-hash', this.internal_config.sellableHash)
      this.$refs.mountingPoint.appendChild(mount)
      this.$nextTick(() => {
        // load translations
        let trans = document.createElement('script')
        trans.src = ` ${window.checkoutConfigs.baseURL}/lang/${this.internal_config.language}?scope=checkoutTranslations`
        trans.onload = () => {
          // load js
          let js = document.createElement('script')
          js.src = `${window.checkoutConfigs.baseURL}/widget/app.js?v=${this.internal_config.version}`
          document.body.appendChild(js)
        }
        document.body.appendChild(trans)
      })
    },
  },

  mounted() {
    setInterval(this.updateHeightParent, 500);
    this.build(this.$props.config);
    this.loadAssets();
  }
}
</script>
