var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sitebuilder-element-container",style:(_vm.style()),attrs:{"id":this.id,"element":"SliderGroupComponent","data-hide-desktop":!this.internal_config.showDesktop,"data-hide-mobile":!this.internal_config.showMobile}},[_c('div',{staticClass:"vdr page-gallery minHeightUnsetDesk",class:{'page-gallery-padding' : _vm.internal_config.effectSlide === 'side-padding'},style:(_vm.styleGallery()),attrs:{"data-preview":"true"}},[_vm._l((_vm.elements),function(element,index){return _c('div',{staticClass:"slider-page-content height-100 minHeightUnsetDesk",class:[("slider-page-" + index), ("" + (_vm.internal_config.effectSlide === 'side-padding'?("side-padding-page side-padding-page-" + index):''))],style:({
                  border : _vm.border,
                  borderRadius: _vm.radius,

                  background: _vm.background,
                  backgroundAttachment: _vm.backgroundAttachment,


                  paddingTop : _vm.paddingTop,
                  paddingLeft : _vm.paddingLeft,
                  paddingRight : _vm.paddingRight,
                  paddingBottom : _vm.paddingBottom,
                  minHeight : _vm.minHeight
                  })},[_c(element.name,{key:index,tag:"component",attrs:{"config":element.config,"device":_vm.device,"is_popup":_vm.is_popup,"id":element.id,"height_start":index,"preview":_vm.preview,"childs":element.childs}})],1)}),_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.arrowOptions),expression:"arrowOptions"}],staticClass:"carousel-control-prev",attrs:{"href":'#SliderGroupComponent' + this.id}},[_c('div',{staticClass:"mdi mdi-arrow-left-circle",style:(_vm.styleArrow()),on:{"click":_vm.prevSlider}})]),_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.arrowOptions),expression:"arrowOptions"}],staticClass:"carousel-control-next",attrs:{"href":'#SliderGroupComponent' + this.id}},[_c('div',{staticClass:"mdi mdi-arrow-right-circle",style:(_vm.styleArrow()),on:{"click":_vm.nextSlider}})])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }