<template>
    <form @submit.prevent="loginBA" method="POST" autocomplete="off">
        <div class="alert alert-danger" v-if="errors.has('alert')">
            <p class="mb-0 clean-text-css">{{ errors.get('alert') }}</p>
        </div>

        <div class="alert alert-danger" v-if="errors.has('failed')">
            <p class="mb-0 clean-text-css">{{ errors.get('failed') }}</p>
        </div>

        <div class="alert alert-warning" v-if="errors.has('throttle')">
            <p class="mb-0 clean-text-css">{{ errors.get('throttle') }}</p>
        </div>

        <div class="form-group">
            <input
                    type="email"
                    id="email"
                    class="form-control form-control-alternative"
                    v-model="user.email"
                    :class="{'is-invalid': errors.has('email') }"
                    :placeholder="$t('ct-email')"
                    @keydown="errors.clear('email')"
                    required
            />
            <span v-if="errors.has('email')" class="invalid-feedback" v-text="errors.get('email')"></span>
        </div>
        <div class="form-group">
            <input
                    type="password"
                    id="password"
                    class="form-control form-control-alternative"
                    v-model="user.password"
                    :class="{'is-invalid': errors.has('password') }"
                    :placeholder="$t('ct_password_')"
                    @keydown="errors.clear('password')"
                    required
            />
            <span v-if="errors.has('password')" class="invalid-feedback" v-text="errors.get('password')"></span>
        </div>

        <div class="form-group text-center d-flex align-items-center justify-content-center flex-column">
            <button type="submit" class="btn btn-success text-center">{{ $t('ct-login') }}</button>

            <button class="btn btn-success text-center mt-2" v-if="login_register"
                    @click.prevent="changeType('login')">{{ $t('ct-sign-in-email-password')}}
            </button>
            <button @click.prevent="changeType('login-token')" class="btn btn-success text-center mt-2"
                    v-if="login_token">{{ $t('ct-sign-in-token')}}
            </button>
        </div>

    </form>
</template>

<script>
    import Errors from "../../../../js/Errors";
    import {loginBA} from "../../../../js/WebsiteUser";
    import {mixinRestrictArea} from "../../../../js/mixins/restrictArea";

    export default {
        name: "LoginBA",
        mixins: [mixinRestrictArea],

        data() {
            return {
                errors: new Errors(),
                user: {
                    email: null,
                    password: null
                },

                auth_types: 'register',
                login_register: false,
                login_token: false,
                blog_login: false,
                login_ba: false,

            }
        },

        methods: {

            changeType(active) {
                this.$emit('change', active)
            },

            hasAuthBoth() {


                let RestrictAreaVue = (typeof this.$parent.$parent.setLogin === 'function') ? this.$parent.$parent : this.$parent.$parent.$parent;
                this.auth_types = RestrictAreaVue.auth_types;

                if(this.auth_types.includes('register'))
                    this.login_token = true;

                if (this.auth_types.includes('register') && !this.auth_types.includes('token') && !this.auth_types.includes('both'))
                    this.login_token = false;

                if(window.location.pathname.indexOf('blog-login') !== -1) {
                    this.blog_login = true;
                }

                if (this.auth_types.includes('register')){
                    this.login_register = true;
                }

                if (this.auth_types.includes('login-ba')){
                    this.login_ba = true;
                }

            },

            loginBA() {
                this.$loading.show();
                this.user.layout_id = this.getCurrentPage().layout_id;
                this.user.page_id = this.getCurrentPage().id;
                this.errors.clear();

                loginBA(this.user)
                    .then((res) => {

                        if (res.data.success) {
                            let RestrictAreaVue = (typeof this.$parent.$parent.setLogin === 'function') ? this.$parent.$parent : this.$parent.$parent.$parent;
                            RestrictAreaVue.setLogin(res.data.data);
                        } else {
                            if (res.data.validator) {
                                this.errors.record(res.data.errors);
                            }
                        }
                    })
                    .catch(({response}) => {

                        if (response.data.errors && response.data.errors)
                            this.errors.record(response.data.errors);
                        else
                            this.errors.record({alert: [this.$t('ct-login-error')]});

                    })
                    .finally(() => this.$loading.hide());
            }
        },
        created() {
            this.hasAuthBoth();
        }
    }
</script>
