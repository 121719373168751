<template>
    <div :id="this.id"
         class="sitebuilder-element-container"
         element="CounterBarComponent"
         :data-hide-desktop="!this.internal_config.showDesktop"
         :data-hide-mobile="!this.internal_config.showMobile"
         :style="style()">

        <div class="vdr">

            <div class="progress"
                 v-bind:style="{height : height,width : width,border : border,'border-radius': radius,background: background,'box-shadow': boxShadow,}">
                <div class="progress-bar" ref="obj" role="progressbar" aria-valuenow="75" aria-valuemin="0"
                     aria-valuemax="100" :style="{backgroundColor:internal_config.backgroundColorBar}"></div>
            </div>

        </div>
    </div>
</template>

<script>

import Data from './Model';
import mixinComponents from "@/js/mixins/MixinComponents";

export default {
    name: 'CounterBarComponent',
    props: ['config', 'id', 'preview', 'device'],
    mixins: [mixinComponents],
    data() {
        return Data.data();
    },

    computed: {

        background() {
            let bg = this.internal_config.backgroundColor;

            if (this.internal_config.backgroundImage && this.internal_config.backgroundImage !== 'none') {
                bg += ' url("' + this.internal_config.backgroundImage + '")';
                bg += ' ' + this.internal_config.backgroundPosition;
                bg += ' / ' + this.internal_config.backgroundSize;
                bg += ' ' + this.internal_config.backgroundRepeat;
                bg += ' ' + this.internal_config.backgroundOrigin;
            }

            return bg;
        }
    },

    methods: {

        build(config) {
            this.internal_config = config;
        },

        style() {

            return `left:  ${this.internal_config.left};
                padding-top: ${this.internal_config.paddingTop};
                padding-left: ${this.internal_config.paddingLeft};
                z-index: ${this.internal_config.zIndex};max-width: ${this.internal_config.maxWidth};margin: 0 auto;
                padding-right: ${this.internal_config.paddingRight};
                padding-bottom: ${this.internal_config.paddingBottom};
                top: ${this.internal_config.top};
                transform: rotate(${this.$props.device == 'desktop' ? this.internal_config.rotation : '0deg'}) scale(1)`;
        },

        animateValue(obj, start, end, duration) {
            var range = end - start;
            var current = start;
            var increment = end > start ? 1 : -1;
            var stepTime = Math.abs(Math.floor(duration / range));
            var timer = setInterval(function () {
                current += increment;
                obj.style.width = parseInt(current) + '%';
                if (current == end) {
                    clearInterval(timer);
                }
            }, stepTime);
        }
    },

    mounted() {
        this.build(this.$props.config);

        this.internal_config = window.mergeRecursive(this.props, this.$props.config);

        this.animateValue(this.$refs.obj, parseInt(this.internal_config.init), parseInt(this.internal_config.finish), parseInt(this.internal_config.timer));
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

@media (max-width: 800px) {
    .progress {
        width: auto !important;
    }
}

[data-device="mobile"] .progress {
    width: auto !important;
}
</style>
