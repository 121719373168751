<template>
   <div :id="id"
       class="sitebuilder-element-container"
       element="LaunchAppComponent"
       :data-hide-desktop="!this.internal_config.showDesktop"
       :data-hide-mobile="!this.internal_config.showMobile"
       :config-properties="JSON.stringify(internal_config)" :style="style()">





       <div class="launch-app-container" style="padding-top: 30px">
        <div class="desktop-element">
          <div class="form-row align-items-center">

            <div class="form-group col-9">
              <input :style="styleInput()" type="text" class="form-control" readonly :value="`${internal_config.url}`">
            </div>

            <div class="form-group col-3" @click="copyCLipBoard(internal_config.url)">
              <button class="btn btn-copy-launch" :style="buttonTextStyle()"  @click="copyCLipBoard(internal_config.url)"> {{ $t('ct-launch-copy') }} </button>
            </div>

          </div>
        </div>



        <div class="form-row align-items-center mobile-element">

          <div class="input-group mb-3">
            <input  :style="styleInput(true)" type="text" class="form-control" readonly :value="`${internal_config.url}`">
            <div class="input-group-append" @click="copyCLipBoard(internal_config.url)">
              <span :style="buttonTextStyle(true)" @click="copyCLipBoard(internal_config.url)" style="cursor: pointer" class="input-group-text btn-copy-launch"> <i class="fa fa-copy"></i> </span>
            </div>
          </div>

        </div>


        <span :style="shareBarDivStyle()" class="share-bar">
            <a v-show="internal_config.show.share_email" :href="`mailto:?body=${internal_config.url}`" target="_blank"  :style="shareButtonStyle()" class="post-share-icon far fa-envelope"></a>
            <a v-show="internal_config.show.share_facebook" :href="`https://www.facebook.com/sharer/sharer.php?u=${internal_config.url}`" target="_blank"  :style="shareButtonStyle()" class="post-share-icon fab fa-facebook-f"></a>
            <a v-show="internal_config.show.share_twitter"  :href="`https://www.twitter.com/share?url=${internal_config.url}`" target="_blank"  :style="shareButtonStyle()" class="post-share-icon fab fa-twitter"></a>
            <a v-show="internal_config.show.share_linkedin" :href="`https://www.linkedin.com/cws/share?url=${internal_config.url}`" target="_blank"  :style="shareButtonStyle()" class="post-share-icon fab fa-linkedin-in"></a>
            <a v-show="internal_config.show.share_whatsapp" :href="`https://wa.me/?text=${internal_config.url}`" target="_blank" :style="shareButtonStyle()" class="post-share-icon fab fa-whatsapp"></a>
            <a v-show="internal_config.show.share_telegram" :href="`https://telegram.me/share/url?url=${internal_config.url}`" :style="shareButtonStyle()" class="post-share-icon fab fa-telegram"></a>
        </span>

        <div  :style="{ 'padding-left': internal_config.texts.padding.left, 'padding-top': internal_config.texts.padding.top }" style=" padding-top: 55px">

          <div v-show="internal_config.show.ranking" >
            <p :style="titlePositionStyle()" class="clean-text-css">{{ $t('ct-launch-position') }}</p>
            <p :style="resultPositionStyle()" class="clean-text-css launch-rank-position">{{ internal_config.rank_position }}</p>
          </div>

          <div v-show="internal_config.show.count" :style="{'margin-top': internal_config.texts.padding.between }" class="form-group">
            <p :style="titlePositionStyle()" class="clean-text-css">{{ $t('ct-launch-count') }}</p>
            <p :style="resultPositionStyle()" class="clean-text-css launch-rank-total">{{ internal_config.rank_total }}</p>
          </div>  

        </div>
        
              


       </div>
  </div>

</template>

<script>

import elementData from './Model';
import _ from 'lodash'
import axios from 'axios'



export default {
 name: 'LaunchAppComponent',
  props: ['config', 'id'],
  data() {
    return elementData.data();
  },
  methods: {
    shareControl(key) {

    },
    build (config) {
      this.internal_config = _.merge(this.internal_config, config);
   

    },
    handler: function(e) {
      this.$root.$emit('focusElement', this);
      this.$root.$emit('handlerElement', this, true);
      e.stopPropagation();
      e.preventDefault();
    },
    focusElement(e) {
      this.$root.$emit('focusElement', this);
      this.$root.$emit('handlerElement', this);

      e.stopPropagation();
      e.preventDefault();
    },
    onResize: function(x, y, width, height) {
      this.internal_config.width = width + 'px';
      this.internal_config.height = height + 'px';
    },
    shareBarDivStyle() {
      return `display: flex; justify-content: ${this.internal_config.show.share_align}; flex-wrap: wrap;`;
    },
    shareButtonStyle() {
      let prop = (this.internal_config.show.share_theme === 'circle') ? 'border-radius: 50%;' : '';
      return prop;
    },
    style() {
      return  `width: ${this.internal_config.width};
               height:  ${this.internal_config.height};
               z-index: ${this.internal_config.zIndex};
               margin: 0 auto;
               padding-top: ${this.internal_config.paddingTop};
               padding-left: ${this.internal_config.paddingLeft};
               padding-right: ${this.internal_config.paddingRight};
               padding-bottom: ${this.internal_config.paddingBottom};
               left:  ${this.internal_config.left};
               top: ${this.internal_config.top}`;
    },
    buttonTextStyle() {
        return    `font-family:  ${this.internal_config.button.family};
                  font-size: ${this.internal_config.button.size}px;
                  text-align: ${this.internal_config.button.align};
                  font-weight: ${this.internal_config.button.weight};
                  font-style: ${this.internal_config.button.style};
                  text-decoration: ${this.internal_config.button.stroke};
                  color: ${this.internal_config.button.color};
                  background: ${this.internal_config.button.background};  
                  border-radius : ${this.internal_config.button.radius}; 
                
                  padding-left: ${this.internal_config.button.horizontalPadding};
                  padding-right: ${this.internal_config.button.horizontalPadding};
                  padding-top: ${this.internal_config.button.verticalPadding};
                  padding-bottom: ${this.internal_config.button.verticalPadding};
                  margin-left: ${this.internal_config.button.paddingLeft};`
    },
    titlePositionStyle() {
        return    `font-family:  ${this.internal_config.texts.position_title.family};
                  font-size: ${this.internal_config.texts.position_title.size}px;
                  text-align: ${this.internal_config.texts.position_title.align};
                  font-weight: ${this.internal_config.texts.position_title.weight};
                  font-style: ${this.internal_config.texts.position_title.style};
                  text-decoration: ${this.internal_config.texts.position_title.stroke};
                  color: ${this.internal_config.texts.position_title.color}`;
    },
    resultPositionStyle() {
        return    `font-family:  ${this.internal_config.texts.position_result.family};
                  font-size: ${this.internal_config.texts.position_result.size}px;
                  text-align: ${this.internal_config.texts.position_result.align};
                  font-weight: ${this.internal_config.texts.position_result.weight};
                  font-style: ${this.internal_config.texts.position_result.style};
                  text-decoration: ${this.internal_config.texts.position_result.stroke};
                  color: ${this.internal_config.texts.position_result.color}`;
    },
    styleInput() {
      return     `font-family:  ${this.internal_config.input.family};
                  font-size: ${this.internal_config.input.size}px;
                  text-align: ${this.internal_config.input.align};
                  font-weight: ${this.internal_config.input.weight};
                  font-style: ${this.internal_config.input.style};
                  text-decoration: ${this.internal_config.input.stroke};
                  color: ${this.internal_config.input.color};
                  height: ${this.internal_config.input.height}`;
    },
     copyCLipBoard(value) {
                let dummy = document.createElement('input'),
                    text = value;

                document.body.appendChild(dummy);
                dummy.value = text;
                dummy.select();
                document.execCommand('copy');
                document.body.removeChild(dummy);
                console.log('final')
                // let content_button = $('.btn-copy-launch').html();
                $('.btn-copy-launch').html('<i class="fa fa-check"></i>')
                $('.btn-copy-launch').fadeOut(500).fadeIn(500);

            },
  },
  mounted() {
        this.build(this.$props.config);
        let vm = this;


        var aid = 0;

        try{

           /**
           * Get a param value from the url
           *
           * @param {String} name
           * @param {String} [url]
           */
          function getUrlParam(name, url){
              var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(url || decodeURIComponent(window.location.href));

              if(results && results.length) {
                  return results[1];
              }

              return 0;
          }

          /**
             * Get cookie by name
             */
            function getCookie(name) {
                var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
                return v ? v[2] : null;
            }

          /**
           * Get aid from location.hash
           */
          function getAidFromHash() {
              var hash         = location.hash,
                  hasHash      = !!hash.length,
                  _idPaiOffice = null;

              if (hasHash) {
                  if (match = hash.match(/aid=([0-9]+)/)) {
                      _idPaiOffice = match[1];
                  }
              }

              return _idPaiOffice;
          }

          aid =  getUrlParam('aid') || getCookie('IdPaiOffice') ||  getAidFromHash()

          let launch = getUrlParam('launch');


          if(launch !== 0) {
            aid =  getUrlParam('uid')
          }


        }catch(e){
          console.log(e)
        }


         
            /**
           * configuração para listas do tipo ranking
           */
		    	  let originUrl = new URL(window.location.href)
		      	let subscriber_id = originUrl.searchParams.get('mbsubscriber_uid');

            try{

                if(subscriber_id === null) {

                  let id = localStorage.getItem('mbsubscriber_uid');

                  if(id !== null) {
                    subscriber_id = id;
                  }

                } else {
                  localStorage.setItem('mbsubscriber_uid', subscriber_id);
                }

            }catch(e) {
              console.log(e)
            }

          

          


            if(subscriber_id !== null) {

                if(aid !== 0 && aid !== null ) {
                      vm.internal_config.url = `${window.location.origin}/${vm.internal_config.url}?aid=${aid}&origin_uid=`;
                }else {
                      vm.internal_config.url = `${window.location.origin}/${vm.internal_config.url}?origin_uid=`;
                }

                vm.internal_config.url = vm.internal_config.url + subscriber_id

                

                axios.get(`/cheetah/pixel/ranking?subscriber_id=${subscriber_id}`)
                .then( res => {
                    console.log(res.data);
                    vm.internal_config.rank_position = res.data.rank_position;
                    vm.internal_config.rank_total = res.data.rank_total;

                    $('.launch-rank-position').text(res.data.rank_position)
                    $('.launch-rank-total').text(res.data.rank_total)
                } )

            }


  }
}
</script>

<style scoped>

.launch-app-container{
  padding-left: 5px;
  padding-right: 5px;
}

 .post-share-icon {
        margin-top: 5px;
        margin-left: 2px;
        padding: 15px;
        font-size: 20px;
        width: 50px;
        height: 50px;
        text-align: center;
        text-decoration: none;
    }

    /* Add a hover effect if you want */
    .post-share-icon:hover {
        opacity: 0.7;
    }

    /* Set a specific color for each brand */

    /* Facebook */
    .fa-facebook-f {
        background: #3B5998;
        color: white;
    }

    /* Twitter */
    .fa-twitter {
        background: #55ACEE;
        color: white;
    }

    /* Instagram */
    .fa-instagram {
        background: #d00174;
        color: white;
    }

    /* Linkedin */
    .fa-linkedin-in {
        background: #2867b2;
        color: white;
    }

    /* Youtube */
    .fa-pinterest {
        background: #ff0000;
        color: white;
    }

    /* Medium */
    .fa-medium-m {
        background: #12100e;
        color: white;
    }

    /* Email */
    .fa-envelope {
        background: #363636;
        color: white;
    }

    /* Copy */
    .fa-link {
        background: #363636;
        color: white;
    }

    /* whatsapp */
    .fa-whatsapp {
        background: #25D366;
        color: white;
    }

    /* telegram */
    .fa-telegram {
        background: #0088cc;
        color: white;
    }

</style>

