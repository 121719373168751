<template>
  <div
    class="sitebuilder-element-container"
    element="WebinarVideoComponent"
    :data-hide-desktop="!this.internal_config.showDesktop"
    :data-hide-mobile="!this.internal_config.showMobile"
    :id="id"
    @contextmenu.prevent="handler($event)"
    :style="style()"
  >
    <div class="vdr resizable" :style="{'width': width,'height' : height, 'transform': transform}">
      <div v-show="!this.preview"></div>
      <div class="embed-responsive embed-responsive-16by9">
        <iframe
          :width="internal_config.width"
          :height="internal_config.height"
          :src="url"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          class="embed-responsive-item"
          v-bind:style="{'border-radius': radius,border : border}"
        ></iframe>
      </div>
    </div>
  </div>
</template>
<script>
import Model from "./Model";

export default {
  name: "WebinarVideoComponent",
  components: {
    Model,
  },
  props: ["config", "height_start", "device", "id", "preview"],
  data() {
    return Model.data();
  },
  computed: {
    width() {
      return this.internal_config.width;
    },
    height() {
      return this.internal_config.height;
    },
    transform() {
      return `rotate(${this.$props.device === 'desktop' ? this.internal_config.rotation : 0}) scale(1)`;
    },
    border() {
      if (typeof this.internal_config.border !== 'undefined') {
					let splitedBorder = this.internal_config.border.split(' ');

					if (typeof splitedBorder[2] !== 'undefined' && this.$globalStoreHelpers.isValidHex(splitedBorder[2])) {
						splitedBorder[2] = this.$globalStoreHelpers.hexToRGBA(splitedBorder[2]);
						this.internal_config.border = splitedBorder.join(' ');
					}
				}
				return this.internal_config.border;
    },
    radius() {
      return this.internal_config.borderRadius;
    }
  },
  methods: {
    debug() {
      // console.log(this.internal_config);
    },

    animation() {
      return true;
    },
    getUrl() {
      let isEditor =
        document.querySelectorAll(".panel-components-right").length > 0;
      let url = this.internal_config.src;
      url =  url +  "embed.php?id=" +  this.internal_config.webinarId +  "&secret=" + this.internal_config.webinarSecret;
      console.log(url);
      return url;
    },

    centerElement: function() {
      let element = document.getElementById(this.id);
      let parentNode = document.getElementById(this.id).parentNode;

      let newLeft =
        parentNode.offsetWidth / 2 - parseInt(element.scrollWidth) / 2 + "px";

      this.$el.style.left = newLeft;
      this.internal_config.left = newLeft;

    },
    onResize: function(x, y, width, height) {
      this.internal_config.width = width + "px";
      this.internal_config.height = height + "px";

    },
    toggle: function() {
      this.$root.$emit("toggleElement", this);
    },
    deleteElement() {
      this.$root.$emit("deleteElement", this);
    },
    duplicateElement() {
      this.$root.$emit("duplicateElement", this, this.internal_config);
    },
    focusElement(e) {
      this.$root.$emit("focusElement", this);
      this.$root.$emit("handlerElement", this);

      if (this.$el.getBoundingClientRect().top < 75) {
        this.$refs.defaultOptions.classList.add("top-option");
      } else {
        this.$refs.defaultOptions.classList.remove("top-option");
      }

      e.stopPropagation();
      e.preventDefault();
    },
    showResources() {
    },
    callReorder(idx) {
      this.$parent.changeOrderPanel(this, idx);
    },
    //change order on html
    changeOrderPanel(elem, idx) {
      let id = elem.$el.id,
        sibling =
          idx === 1
            ? document.getElementById(id).nextSibling
            : document.getElementById(id).previousSibling;

      if (sibling === null) {
        return true;
      }

      let vm = this;
      this.$root.$emit("changeOrderElementsInside", vm.id, id, sibling.id, idx);
    },
    handler: function(e) {
      // this.$el.classList.add('animated', 'jello', 'infinite');
      this.$root.$emit("focusElement", this);
      this.$root.$emit("handlerElement", this);
      e.stopPropagation();
      e.preventDefault();
    },
    style() {
      let style = `left:  ${this.internal_config.left};
					 z-index: ${this.internal_config.zIndex};max-width: ${this.internal_config.maxWidth};margin: 0 auto;
					 width: ${this.internal_config.width};
					 height: ${this.internal_config.height};
					 padding-top: ${this.internal_config.paddingTop};
					 padding-left: ${this.internal_config.paddingLeft};
                     padding-right: ${this.internal_config.paddingRight};
                     padding-bottom: ${this.internal_config.paddingBottom};
                     top: ${this.internal_config.top};`;
      return style;
    }
  },
  created() {
    let device, childs, id, config;
    ({ device, childs, id, config } = this.$props);
    this.internal_config = config;
    this.device = device;
    if (typeof id !== "undefined") {
      this.id = id;
    }
    this.internal_config = window.mergeRecursive(
      this.props,
      this.$props.config
    );
  },
  mounted() {
      let device, childs, id, config;
			({device, childs, id, config} = this.$props);

			this.internal_config = config;
			this.device = device;

			if (typeof id !== 'undefined') {
				this.id = id;
      }

      let isEditor =
        document.querySelectorAll(".panel-components-right").length > 0;
      let url = this.internal_config.src;
      url =  url +  "embed.php?id=" +  this.internal_config.webinarId +  "&secret=" + this.internal_config.webinarSecret;
      this.url = url;
      // return url;


			Object.keys(this.props).forEach(prop => {
				if (typeof this.internal_config[prop] === 'undefined') {
					this.internal_config[prop] = this.props[prop];
				}
			});

      this.$root.$emit('applyAnimation', this, 'sitebuilder-element-container');

  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vdr {
  /*height: auto !important;*/
}
</style>
