<template>
    <div :id="this.id"
         :class="'sitebuilder-element-container'  + hideControl()"
         element="ImageComponent"
         :data-tag="dataTag()"
         :data-hide-desktop="!this.internal_config.showDesktop"
         :data-hide-mobile="!this.internal_config.showMobile"
         :style="style()"
         @click="linkAction()"
         :key="$props.componentKey">
        <lazy-component v-if="!internal_config.showNormal" :style="`transform: rotate(${device === 'desktop' ? internal_config.rotation : 0}) scale(1);`">
            <img ref="image" :src="internal_config.src"
                 :alt="internal_config.alt" :title="internal_config.title"
                 class="sitebuilder-element"
                 v-bind:style="{opacity: opacity,'border-radius': radius, border : border, 'box-shadow': boxShadow}"/>
        </lazy-component>
        <div v-if="this.internal_config.showNormal" :style="`transform: rotate(${this.$props.device === 'desktop' ? this.internal_config.rotation : 0}) scale(1);`">
            <img ref="image" :src="this.internal_config.src"
                 :alt="this.internal_config.alt" :title="this.internal_config.title"
                 class="sitebuilder-element normal-image-element"
                 v-bind:style="{opacity: opacity,'border-radius': radius, border : border, 'box-shadow': boxShadow, 'cursor' : `${internal_config.link.value || internal_config.link.valueID || internal_config.link.tel ? 'pointer' : 'default'}`}"/>
        </div>
    </div>
</template>

<script>

import ImageData from './Model';

	export default {
		name: 'ImageComponent',
		props: ['config', 'src', 'id', 'preview', 'device'],
		computed: {
			opacity() {
				return this.internal_config.opacity;
			},
			border() {
				if (typeof this.internal_config.border !== 'undefined') {
					let splitedBorder = this.internal_config.border.split(' ');

					if (typeof splitedBorder[2] !== 'undefined' && this.$globalStoreHelpers.isValidHex(splitedBorder[2])) {
						splitedBorder[2] = this.$globalStoreHelpers.hexToRGBA(splitedBorder[2]);
						this.internal_config.border = splitedBorder.join(' ');
					}
				}
				return this.internal_config.border;
			},
			boxShadow() {
				return this.internal_config.boxShadow;
			},
			radius() {
				return this.internal_config.borderRadius;
			},
		},
		data() {
			return ImageData.data();
		},
		methods: {

            forceRerender() {
                this.componentKey += 1;
            },

			dataTag() {
				if (this.internal_config.tag) {
					return this.internal_config.tag;
				}
				return '';
			},
			hideControl() {
				var classAdd = '';
				if (!this.internal_config.showMobile) {
					classAdd = classAdd + ' hide-on-mobile'
				}

				if (!this.internal_config.showDesktop) {
					classAdd = classAdd + ' hide-on-desktop'
				}

				return classAdd;
			},

			build(config) {
				this.internal_config = config;
			},

			linkAction: function () {

				this.$root.$emit('linkAction', this.internal_config.link);

			},
			style() {
				var style = `width: ${this.internal_config.width};
                     padding-top: ${this.internal_config.paddingTop};
                     padding-left: ${this.internal_config.paddingLeft};
                     z-index: ${this.internal_config.zIndex};max-width: ${this.internal_config.maxWidth};margin: 0 auto;
                     padding-right: ${this.internal_config.paddingRight};
                     padding-bottom: ${this.internal_config.paddingBottom};
                     left:  ${this.internal_config.left};
										 top: ${this.internal_config.top};`;
				return style;
			},
		},


		mounted() {

			this.build(this.$props.config);

			Object.keys(this.props).forEach(prop => {
				if (typeof this.internal_config[prop] === 'undefined') {
					this.internal_config[prop] = this.props[prop];
				}
			});

			this.internal_config.src = this.internal_config.src.replace('omb11.com', 'storage.builderall.com');

			if (this.$props.device === 'mobile' || this.$el.getBoundingClientRect().top < 2000) {
				this.internal_config.showNormal = true;
			} else {
				this.internal_config.showNormal = false;
                this.forceRerender();
			}

            var defaultClass = 'sitebuilder-element-container';
            var hasLink = this.internal_config.link.value || this.internal_config.link.valueID || this.internal_config.link.tel;

            if (hasLink) {
                this.$el.classList.add('link-action');
                defaultClass += ' link-action'
            }

            this.$root.$emit('applyAnimation', this, defaultClass);
        }
	}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    [element="ImageComponent"] img {
        width: 100%;
        height: auto;
    }

    .resizable.vdr {
        width: 100% !important;
        height: 100% !important;
        left: 0 !important;
        top: 0 !important;
    }

</style>
