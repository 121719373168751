<template>
  <div class="image-gallery-alternating-container"
       :class="{'tiles-grid-repeat': internal_config.imagesPerPage >= 4}"
       :style="{
            gridColumnGap: `${internal_config.verticalMargin}px`,
            gridRowGap: `${internal_config.horizontalMargin}px`,
            opacity: opacity
          }">
    <div class="image-gallery-alternating--photo"
         v-for="(image, index) in internal_config.images"
         :key="image"
         :style="{
              backgroundImage: 'url(' + image + ')',
              cursor: $props.preview ? 'pointer': '',
              borderRadius: radius,
              border : border,
             }"
         @click="$emit('showPhoto', index)"
    ></div>
  </div>
</template>

<script>
export default {
  name: "AlternatingGallery",
  props: ['internal_config'],
  computed: {
    minHeight() {
      return this.internal_config.minHeight;
    },
    opacity() {
      return this.internal_config.opacity;
    },
    border() {
      return this.internal_config.border;
    },
    radius() {
      return this.internal_config.borderRadius;
    }
  },
}
</script>

<style lang="scss" scoped>

.image-gallery-alternating-container {
  width: 100%;
  display: grid;
  counter-reset: div;
  grid-template-columns: repeat(2, 1fr);

  &.tiles-grid-repeat {
    grid-template-columns: repeat(4, 1fr);

    .image-gallery-alternating--photo:nth-child(6n + 4),
    .image-gallery-alternating--photo:nth-child(6n + 1) {
      grid-column: auto /span 2;
      grid-row: auto /span 2;
    }
  }

  .image-gallery-alternating--photo {
    display: flex;
    overflow: hidden;
    float: left;
    background-position: top center;
    background-size: cover;
    position: relative;

    &:nth-child(6n + 4),
    &:nth-child(6n + 1) {
      grid-column: auto /span 2;
      grid-row: auto /span 2;
    }
  }
}
</style>
