export const mixinRestrictAreaConfig  = {
    data(){
        return {
            config: {
                hide_register_btn: 0,
                style: 'light',
                access_denied: {
                    message: this.$t('ct-restrict-area-not-have-permission'),
                    btn: {
                        enable: 1,
                        text: this.$t('ct-home'),
                        position: 'right'
                    },
                    link: {
                        type: 'home',
                        value: null,
                        page_id: null
                    }
                },
                background: {
                    image: '',
                    color: '',
                    repeat: 'repeat',
                    size: 'contain'
                }
            },
        }
    },

    methods: {
        redirectLink() {
            var type = this.config.access_denied.link.type;

            switch (type) {
                case 'page':
                    var page = pagesConfig.find(page => +page.id === +this.config.access_denied.link.page_id);
                    if (page) {
                        window.location.href = `${window.origin}/${page.url}`
                    }
                    break;

                case 'web':
                    if (this.config.access_denied.link.value) {
                        window.location.href = this.config.access_denied.link.value;
                    }
                    break;

                default:
                    try {
                        let homePage;

                        homePage = pagesConfig.find(page => page !== null && (page.initial === '1'));

                        if (!homePage)
                            homePage = pagesConfig.find((page, index) => page !== null && (page.initial === '0' && index === 0));

                        window.location.href = `${window.origin}/${homePage.url}`

                    } catch (e) {
                        console.warn('gethomePage', e);
                    }
            }
        },
    },

    created() {

        const merge = (target, source) => {
            // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
            for (const key of Object.keys(source)) {
                if (source[key] instanceof Object) Object.assign(source[key], merge(target[key], source[key]))
            }

            // Join `target` and modified `source`
            Object.assign(target || {}, source)
            return target
        }

        var restrictArea = (typeof this.$parent.$parent.setLogin === 'function') ? this.$parent.$parent : this.$parent.$parent.$parent;

        if(typeof restrictArea.form_theme === 'undefined') {
            restrictArea = this.$parent
        }

        if (restrictArea.form_theme && restrictArea.form_theme.config) {
            this.config = merge(this.config, restrictArea.form_theme.config);
        }
    }
}

export const mixinRestrictAreaForm  = {
    props: ['type_active', 'areas_register', 'active_page', 'auth_types'],

    data(){
        return {
            componentKey: 0
        }
    },

    methods: {

        forceRerender() {
            this.componentKey += 1;
        },

        changeType(active) {
            this.$emit('change', active);
            this.forceRerender();
        },

        getActualPage() {
            return this.active_page;
        },
    }
}

