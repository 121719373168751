var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("sitebuilder-element-container " + (_vm.internal_config.fullWidth ? 'full-width-element' : '')),style:(_vm.style()),attrs:{"id":_vm.id,"element":"BookingCalendarComponent","data-hide-desktop":!_vm.internal_config.showDesktop,"data-hide-mobile":!_vm.internal_config.showMobile,"config-properties":JSON.stringify(_vm.internal_config)}},[(_vm.internal_config.calendar.src)?_c('div',{staticClass:"embed-responsive embed-responsive-16by9",style:({
        width : _vm.width,
        height : _vm.internal_config.fullHeight ? '100%' : _vm.height,
        minHeight : _vm.minHeight
        })},[_c('iframe',{staticClass:"embed-responsive-item",style:({
          borderRadius: _vm.radius,
          border : _vm.border
        }),attrs:{"width":_vm.internal_config.width,"height":_vm.internal_config.height,"src":_vm.internal_config.calendar.src,"frameborder":"0","allow":"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})]):_c('div',[_c('div',{staticStyle:{"background-color":"white","height":"300px","width":"100%","display":"flex","align-items":"center","justify-content":"center","flex-direction":"column"}},[_c('h6',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('ct-select-booking-calendar')))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }