import { render, staticRenderFns } from "./PostBlog.vue?vue&type=template&id=51da3bff&scoped=true&"
import script from "./PostBlog.vue?vue&type=script&lang=js&"
export * from "./PostBlog.vue?vue&type=script&lang=js&"
import style0 from "./PostBlog.vue?vue&type=style&index=0&id=51da3bff&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51da3bff",
  null
  
)

export default component.exports