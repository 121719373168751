var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"elem",staticClass:"sitebuilder-element-container is-receiver",style:(_vm.style()),attrs:{"element":"BoxComponent","data-hide-desktop":!this.internal_config.showDesktop,"data-hide-mobile":!this.internal_config.showMobile,"id":_vm.id,"data-tag":_vm.dataTag()}},[_c('div',{ref:"boxElem",staticClass:"sitebuilder-element-receiver",style:({
       'border-radius': _vm.radius,
        background: _vm.backgroundColor,
        backgroundImage: _vm.backgroundImage,
        backgroundSize: _vm.backgroundSize,
        backgroundPosition: _vm.backgroundPosition,
        backgroundAttachment: _vm.backgroundAttachment,
        backgroundOrigin: _vm.backgroundOrigin,
        backgroundRepeat: _vm.backgroundRepeat,
        height : _vm.height,
        minHeight : _vm.minHeight,
        width : _vm.width,
        border : _vm.border,
        'box-shadow': _vm.boxShadow,
       })},[(typeof _vm.internal_config.hotmart !== 'undefined' && _vm.internal_config.hotmart.enabled)?_c('div',{attrs:{"id":"box_hotmart"}}):_vm._e(),(typeof _vm.internal_config.eduzz !== 'undefined' && _vm.internal_config.eduzz.enabled)?_c('div',{attrs:{"id":"elements"}}):_vm._e(),_vm._l((_vm.elements),function(element,index){return _c(element.name,{key:index,tag:"component",attrs:{"config":element.config,"device":_vm.device,"is_popup":_vm.is_popup,"id":element.id,"height_start":index,"preview":_vm.preview,"childs":element.childs}})})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }