<template>
  <div
    class="image-gallery-mixed-container"
    :style="{
            gridTemplateColumns: `repeat(${internal_config.columns}, auto)`,
            gridColumnGap: `${internal_config.verticalMargin}px`,
            gridRowGap: `${internal_config.horizontalMargin}px`,
            opacity: opacity
          }"
  >
    <div
      class="image-gallery-mixed-photo"
      v-for="(image, index) in this.images"
      :class="{'image-gallery-photo-principal': image.principal}"
      :key="index"
      :style="{
              backgroundImage: 'url(' + image.src + ')',
              cursor: $props.preview ? 'pointer': '',
              borderRadius: radius,
              border : border,
             }"
      @click="$emit('showPhoto', index)"
    ></div>
  </div>
</template>

<script>

export default {
  name: "MixedGallery",
  props: ['internal_config'],
  computed: {
    minHeight() {
      return this.internal_config.minHeight;
    },
    opacity() {
      return this.internal_config.opacity;
    },
    border() {
      return this.internal_config.border;
    },
    radius() {
      return this.internal_config.borderRadius;
    }
  },

  data() {
    return {
      images: [],
    }
  },

  watch: {
    'internal_config.images': function (newValue) {
      this.build();
    },
    'internal_config.columns': function (newValue) {
      this.build();
    },
    'internal_config.imagesPerPage': function (newValue) {
      this.build();
    },
  },

  methods:{
    build(){
      this.images = [];

      let columns = this.internal_config.columns;
      var maxLength = this.internal_config.images.length;

      var column = 0;

      for( let i=0; i < maxLength; i++){

        let principal = false;

        if(column === i){
          principal = true;
          column += parseInt(columns) +1;
        }

        this.images.push({
          src: this.internal_config.images[i],
          principal: principal
        })
      }
    }
  },

  created() {
    this.build();
  }
}
</script>

<style lang="scss" scoped>
/** GALLERY */

.image-gallery-mixed-container {
  display: grid;
  width: 100%;
  height: 100%;
}

.image-gallery-mixed-photo {
  overflow: hidden;
  float: left;
  background-position: top center;
  background-size: cover;
  position: relative;

  &.image-gallery-photo-principal{
    grid-column: 1 / -1;
  }
}
</style>
