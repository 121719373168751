<template>
  <div>
    <!-- Desktop -->
    <div @click="openUrl(showUrl(`/blog/${article.url}`))" class="card-hover desktop-element hover-theme-desktop mtf" :style="cardStyle()">

      <div class="card-hover__content-info">

        <!-- Title -->
        <h3 class="clean-text-css" :style="titleStyle()">
          {{ article.title }}
        </h3>

        <hr
          :style="{ background: colors.color_1}"
        />

        <!-- Author -->
        <div
          v-if="show.author"
          :style="authorStyle()">
          <span> {{ article.author.name }} </span>
        </div>
      </div>

      <!-- Content -->
      <div
        class="card-hover__content-preview"
        :style="contentStyle()"
      />
      <div class="card-hover__footer">

        <!-- Categories -->
        <div class="card-hover__footer-categories">
          <span>
          </span>
        </div>

        <!-- Date -->
        <div
          class="icon-holder"
          :style="authorStyle()"
          v-show="show.date"
        >
          <span>
            <i
              :style="{ color: colors.color_2 }"
              class="fa fa-calendar" />
            {{ formatDate(article.scheduled_date, config.dateFormat) }}
          </span>
        </div>
      </div>

      <div class="color-overlay"></div>
    </div>

    <!-- Mobile -->
    <div @click="openUrl(showUrl(`/blog/${article.url}`))"  class="card-hover mobile-element hover-theme-mobile" :style="cardStyle(false)">

      <div class="card-hover__content-info">

        <!-- Title -->
        <h3 class="clean-text-css" :style="titleStyle(false)">
          {{ article.title }}
        </h3>

        <hr
          :style="{ background: colors.color_1}"
        />

        <!-- Author -->
        <div
          v-if="show.author"
          :style="authorStyle(false)">
          <span> {{ article.author.name }} </span>
        </div>
      </div>

      <!-- Content -->
      <div
        class="card-hover__content-preview"
        :style="contentStyle(false)"
      />
      <div class="card-hover__footer">

        <!-- Categories -->
        <div class="card-hover__footer-categories">
          <span>
          </span>
        </div>

        <!-- Date -->
        <div
          class="icon-holder"
          :style="authorStyle(false)"
          v-show="show.date"
        >
          <span>
            <i
              :style="{ color: colors.color_2 }"
              class="fa fa-calendar" />
            {{ formatDate(article.scheduled_date, config.dateFormat) }}
          </span>
        </div>
      </div>

      <div class="color-overlay"></div>
    </div>
  </div>
</template>

<script>
  import {mixinBlogComponents} from "@/js/mixins/BlogComponents";

  export default {
    props: ['article', 'config', 'colors', 'show', 'styles', 'sizes'],
    name: 'ArticleCardHover',
    mixins: [mixinBlogComponents],
    methods: {
      openUrl(url) {
        if (url !== '#') {
          window.open(url, '_self');
        }
      },
      cardStyle(desktop = true) {
        return `
        background: url(${this.article.thumbnail});
        background-size: cover;
        background-position: center;
        display: flex;
        cursor: pointer;
        align-items: center;
        height: ${this.sizes.card.height !== '0px' ? this.sizes.card.height : '400px'};`;
      },
      contentStyle(desktop = true) {
        return `font-family:  ${this.styles.content.family};
                  font-size: ${(desktop) ? this.styles.content.size : this.styles.content.sizeMobile} !important;
                  text-align: ${this.styles.content.align};
                  font-weight: ${this.styles.content.weight};
                  font-style: ${this.styles.content.style};
                  text-decoration: ${this.styles.content.stroke};
                  color: ${this.styles.content.color};
                  -webkit-text-fill-color: ${this.styles.content.color};
                  height: ${this.sizes.content.height !== '0px' ? this.sizes.content.height : 'auto'};
                  overflow: hidden`;
      },
      titleStyle(desktop = true) {
        return `font-family:  ${this.styles.title.family};
                  font-size: ${(desktop) ? this.styles.title.size : this.styles.title.sizeMobile} !important;
                  text-align: ${this.styles.title.align};
                  font-weight: ${this.styles.title.weight};
                  font-style: ${this.styles.title.style};
                  text-decoration: ${this.styles.title.stroke};
                  height: ${this.sizes.title.height !== '0px' && desktop ? this.sizes.title.height : 'auto'};
                  color: ${this.styles.title.color};
                  -webkit-text-fill-color: ${this.styles.title.color};
                  margin-bottom: ${!desktop && '30px'};
                  padding-left: 10px;
                  padding-right: 10px;
                  `;
      },
      authorStyle(desktop = true) {
        return `font-family:  ${this.styles.author.family};
                  font-size: ${(desktop) ? this.styles.author.size : this.styles.author.sizeMobile} !important;
                  text-align: ${this.styles.author.align};
                  font-weight: ${this.styles.author.weight};
                  font-style: ${this.styles.author.style};
                  text-decoration: ${this.styles.author.stroke};
                  margin-left: 10px;
                  margin-right: 10px;
                  color: ${this.styles.author.color}`;
      },
      categoryStyle(desktop = true) {
        return `background-color: ${this.colors.color_3};
              box-shadow: none;
              border-radius: ${this.styles.categories.borderRadius};
              font-family:  ${this.styles.categories.family};
              font-size: ${(desktop) ? this.styles.categories.size : this.styles.categories.sizeMobile} !important;
              text-align: ${this.styles.categories.align};
              font-weight: ${this.styles.categories.weight};
              font-style: ${this.styles.categories.style};
              text-decoration: ${this.styles.categories.stroke};
              color: ${this.styles.categories.color};
              height: ${this.styles.categories.height};
              overflow: hidden`;
      }
    },
    mounted() {
    }
  };
</script>

<style scoped>
  .card-hover{
    position: relative;

    box-shadow: 3px 3px 20px rgba(0,0,0,0.5);

    overflow: hidden;
  }
  .card-hover *{
    position: relative;
    z-index: 2;
  }
  .card-hover:hover .color-overlay{
    background:rgba(74, 100, 114, 0.8);
  }
  .card-hover:hover .card-hover__content-preview{
    bottom: -10px;
    opacity: 1;
  }
  .card-hover, .color-overlay{
    border-radius: 0.7em;
  }
  .color-overlay{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    transition: 0.7s background;
    background: rgba(74, 100, 114, 0.3);
    z-index: 1;
  }

  .card-hover__content-info{
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-top: 2em;
  }
  .card-hover__content-info hr{
    margin-top: -0.4em;
    margin-bottom: 1em;
    width: 5em;
    height: 0.25em;
    box-sizing: content-box;
    border: none;
  }

  .card-hover__content-preview{
    line-height: 1.1em;

    position: relative;
    bottom: -4em;
    opacity: 0;
    background: transparent;
    transition: opacity 0.3s, bottom 0.3s;
  }

  .card-hover__footer{
    display: inline-block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 10em;
    background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.9));
    border-radius:  0 0 0.7em 0.7em;
  }

  .card-hover__footer > .icon-holder{
    position: absolute;
    bottom: 0;
    height: 40px;
    width: 100%;
    display: inline-block;
  }

  .card-hover__footer-categories {
    top: 50px;
  }
</style>
