export default {
    data() {
        return {
            props: {
                width: '410',
                height: '615',
            },
            internal_config: {
                type: '',
                sellableHash: '',
                baloon_text: '',
                buy_button_text: '',
                pay_button_text: '',
                bottom_info: '',
                buy_button_subtext: '',
                language: '',
                showDesktop: true,
                showMobile: true,
                zIndex: 1,
                width: '410',
                height: '615'
            },
            name: 'checkout'
        }
    }
}
