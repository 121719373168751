<template>
  <div :id="this.id" class="sitebuilder-element-container"
       element="SliderGroupComponent"
       :data-hide-desktop="!this.internal_config.showDesktop"
       :data-hide-mobile="!this.internal_config.showMobile"
       :style="style()">
    <div class="vdr page-gallery minHeightUnsetDesk" data-preview="true"
         :class="{'page-gallery-padding' : internal_config.effectSlide === 'side-padding'}"
         v-bind:style="styleGallery()">
      <div v-for="(element,index) in elements"
           class="slider-page-content height-100 minHeightUnsetDesk"
           :class="[`slider-page-${index}`, `${internal_config.effectSlide === 'side-padding'?`side-padding-page side-padding-page-${index}`:''}`]"
           v-bind:style="{
                    border : border,
                    borderRadius: radius,

                    background: background,
                    backgroundAttachment: backgroundAttachment,


                    paddingTop : paddingTop,
                    paddingLeft : paddingLeft,
                    paddingRight : paddingRight,
                    paddingBottom : paddingBottom,
                    minHeight : minHeight
                    }">
        <component :is="element.name" :config="element.config" :key="index"
                   :device="device"
                   :is_popup="is_popup"
                   :id="element.id" :height_start="index" :preview="preview"
                   :childs="element.childs"></component>

      </div>
      <a v-show="arrowOptions" class="carousel-control-prev" :href="'#SliderGroupComponent' + this.id">
        <div class="mdi mdi-arrow-left-circle" @click="prevSlider" :style="styleArrow()"></div>
      </a>
      <a v-show="arrowOptions" class="carousel-control-next" :href="'#SliderGroupComponent' + this.id">
        <div class="mdi mdi-arrow-right-circle" @click="nextSlider" :style="styleArrow()"></div>
      </a>
    </div>

  </div>
</template>

<script>
import SliderData from "./Model";

export default {
	name: "SliderGroupComponent",
	props: ['config', 'childs', 'height_start', 'device', 'id', 'preview', 'is_popup'],
	computed: {
		backgroundColor() {

			if (this.$globalStoreHelpers.isValidHex(this.internal_config.backgroundColor)) {
				this.internal_config.backgroundColor = this.$globalStoreHelpers.hexToRGBA(this.internal_config.backgroundColor);
			}

			return this.internal_config.backgroundColor;
		},
		backgroundImage() {
			if (this.internal_config.backgroundImage && this.internal_config.backgroundImage !== 'none') {
				if (this.internal_config.backgroundImage !== null)
					this.internal_config.backgroundImage = this.internal_config.backgroundImage.replace('omb11.com', 'storage.builderall.com');
				return 'url("' + this.internal_config.backgroundImage + '")';
			}
			return '';
		},
		backgroundPosition() {
			if (this.internal_config.backgroundImage && this.internal_config.backgroundImage !== 'none') {
				return this.internal_config.backgroundPosition
			}
			return '';
		},
		backgroundOrigin() {
			if (this.internal_config.backgroundImage && this.internal_config.backgroundImage !== 'none') {
				return this.internal_config.backgroundOrigin
			}
			return '';
		},
		backgroundRepeat() {
			if (this.internal_config.backgroundImage && this.internal_config.backgroundImage !== 'none') {
				return this.internal_config.backgroundRepeat
			}
			return '';
		},
		backgroundSize() {
			if (this.internal_config.backgroundImage && this.internal_config.backgroundImage !== 'none') {
				return this.internal_config.backgroundSize
			}
			return '';
		},
		backgroundAttachment() {
			if (this.internal_config.backgroundImage && this.internal_config.backgroundImage !== 'none') {
				return this.internal_config.backgroundAttachment
			}
			return '';
		},
		arrowOptions() {
			return this.internal_config.arrowOptions;
		},
		timerSlider() {
			return this.internal_config.timerSlider;
		},
		minHeight() {
			return this.internal_config.minHeight;
		},
		border() {
			return this.internal_config.border + '!important';
		},
		boxShadow() {
			return this.internal_config.boxShadow;
		},
		radius() {
			return this.internal_config.borderRadius;
		},
		paddingTop() {
			return this.internal_config.paddingTopContainer;
		},
		paddingLeft() {
			return this.internal_config.paddingLeftContainer;
		},
		paddingRight() {
			return this.internal_config.paddingRightContainer;
		},
		paddingBottom() {
			return this.internal_config.paddingBottomContainer;
		},
// bg-color bg-image position/bg-size bg-repeat bg-origin
		background() {
			let bg = this.internal_config.backgroundColor;

			if (this.internal_config.backgroundImage !== 'none' && typeof this.internal_config.backgroundImage !== 'undefined') {

				this.internal_config.backgroundImage = this.internal_config.backgroundImage.replace('omb11.com', 'storage.builderall.com');

				bg += ' url("' + this.internal_config.backgroundImage + '")';
				bg += ' ' + this.internal_config.backgroundPosition;
				bg += ' / ' + this.internal_config.backgroundSize;
				bg += ' ' + this.internal_config.backgroundRepeat;
				bg += ' ' + this.internal_config.backgroundOrigin;
			}

			return bg;
		},
	},
	data() {
		return SliderData.data();
	},
	methods: {
		async tagsAdvancedLink(id_usuario_omb10) {

			let response = await new Promise(resolve => {
				var xhttp = new XMLHttpRequest();
				xhttp.open(
					"POST",
					"https://office.builderall.com/us/office/user/get-tags/" + id_usuario_omb10,
					true
				);

				xhttp.setRequestHeader("X-Requested-With", "XMLHttpRequest");
				xhttp.onload = function () {
					if (xhttp.readyState == 4 && xhttp.status == 200) {
						var res = JSON.parse(xhttp.responseText);
						resolve(res);
					}
				};
				xhttp.onerror = function () {
					resolve(false);
				};
				xhttp.withCredentials = true;
				xhttp.send();

			});

			return response;
		},

		replaceLinkWithTags() {

			let buttoncomporntn = document.querySelectorAll('[element="ButtonComponent"]');
			let hasTagLink = false;
			buttoncomporntn.forEach(element => {
				var link = element.querySelectorAll('a');

				link.forEach(le => {
					var myRe = /\[([^}]*?)]/g;

					if (!!le.href) {
						var myArray = myRe.exec(le.href);
						if (!!myArray) {
							hasTagLink = true;
						}
					}
				})
			});

			if (hasTagLink && typeof OPTIONS_INIT_SITE !== 'undefined' && !!OPTIONS_INIT_SITE.options.id_usuario_omb10) {
				var links = document.querySelectorAll('a.bt-text-area');
				this.tagsAdvancedLink(OPTIONS_INIT_SITE.options.id_usuario_omb10)
					.then(response => {

						links.forEach(le => {
							var myRe = /\[([^}]*?)]/g;

							if (!!le.href) {
								var myArray = myRe.exec(le.href);
								if (!!myArray) {
									var linkReplace = le.href;

									Object.keys(response).forEach(function (item) {
										if (!!response[item]) { // diferente de null

											if (typeof response[item] === 'string') {

												linkReplace = linkReplace.replace(/\[([^}]*?)]/g, (match, group, offset, string) => {
													return group.toLowerCase() in response ? encodeURI(response[group.toLowerCase()]) : match
												})
											} else if (typeof response[item] === 'object') {

												linkReplace = linkReplace.replace(/\[([^}]*?)]/g, (match, group, offset, string) => {
													return group.toLowerCase() in response[item] ? encodeURI(response[item][group.toLowerCase()]) : match
												})
											}
										}
									});

									le.setAttribute('href', linkReplace);
									le.classList.add("overwrite-click");
									le.childNodes.forEach(elm => elm.classList.add("overwrite-click"));
								}
							}
						})

					});
			}
		},


		changeHeight(size, positive) {

			if (this.device == 'desktop') {
				let elementBounding = this.$el.getBoundingClientRect();

				if (positive)
					this.internal_config.height = parseInt(elementBounding.height) + size + 'px';
				else
					this.internal_config.height = parseInt(elementBounding.height) - parseInt(size) + 'px';

				if (typeof this.$parent.changeHeight !== 'undefined') {
					this.$parent.changeHeight(size, positive, this.id, $(this.$el).position().top, $(this.$el).offset().left, elementBounding.width);
				} else {
					this.$parent.$parent.changeHeight(size, positive, this.id, $(this.$el).position().top, $(this.$el).offset().left, elementBounding.width);
				}
			}
		},

		destroyAutoPlay() {
			clearTimeout(this.internal_config.slider);
		},
		checkExists: function (vm) {

			let element = document
				.getElementById(vm.id)
				.getElementsByClassName(`slider-page-${vm.internal_config.currentIndex}`);

			if (typeof element[0] === 'undefined') {
				vm.buildSlider();
			} else {
				vm.internal_config.currentIndex = 0;
				element[0].style.left = 0;
				element[0].style.position = "relative";

				if (this.internal_config.effectSlide === 'side-padding') {

					const elements = document
						.getElementById(this.id)
						.getElementsByClassName(`side-padding-page`);

					if (elements.length >= 3)
						vm.buildSidePaddingSlider();
				}

				vm.autoPlaySlider();
			}

		},
		buildSidePaddingSlider() {

			const elements = document
				.getElementById(this.id)
				.getElementsByClassName(`side-padding-page`);

			const margin = this.internal_config.marginBetween || 80;
			const gap = this.internal_config.gapBetween || 50;

			elements[0].style.setProperty('width', `calc(100% - ${margin * 2}px)`, "important");
			elements[0].style.setProperty('overflow', 'hidden');
			elements[0].style.setProperty('margin', '0 auto');

			elements[1].style.setProperty('margin-left', `-${margin - gap}px`);

			elements[elements.length - 1].style.setProperty('left', `calc(-100% + ${margin - gap}px)`);
		},


		buildSlider() {

			this.internal_config.currentIndex = 0;
			let vm = this;

			setTimeout(() => {
				this.checkExists(vm);
			}, 100);

		},
		prevSlider() {


			const {currentIndex} = this.internal_config;
			const nextIndex = currentIndex ? currentIndex - 1 : this.$el.querySelectorAll('[element="SliderPageComponent"]').length - 1;

			const nextImage = document
				.getElementById(this.id)
				.getElementsByClassName(`slider-page-${nextIndex}`)[0];

			const prevImage = document
				.getElementById(this.id)
				.getElementsByClassName(`slider-page-${currentIndex}`)[0];

			nextImage.style.position = "relative";
			prevImage.style.position = "absolute";

			switch (this.internal_config.effectSlide) {

				case 'slide':
					nextImage.style.left = "-100%";
					prevImage.style.left = 0;

					nextImage.setAttribute(
						"class",
						`slider-page-${nextIndex} slider-in-left slider-active`
					);
					prevImage.setAttribute(
						"class",
						`slider-page-${currentIndex} slider-out-right`
					);
					prevImage.classList.remove("slider-active");
					break;

				case 'side-padding':

					const margin = this.internal_config.marginBetween || 80;
					const gap = this.internal_config.gapBetween || 50;

					const elements = document
						.getElementById(this.id)
						.getElementsByClassName(`side-padding-page`);

					if (elements.length < 3) {
						nextImage.style.left = "-100%";
						prevImage.style.left = 0;

						nextImage.setAttribute(
							"class",
							`slider-page-${nextIndex} slider-active`
						);

						prevImage.setAttribute(
							"class",
							`slider-page-${currentIndex}`
						);
						prevImage.classList.remove("slider-active");
					} else {
						for (let i = 0; i < elements.length; i++) {
							elements[i].style.setProperty('z-index', '0');
						}

						const nextElementIndex = nextIndex + 1 === elements.length ? 0 : nextIndex + 1;
						const lastElementIndex = nextIndex === 0 ? (elements.length - 1) : nextIndex - 1;

						// 100 left to 0
						elements[nextIndex].style.setProperty('width', `calc(100% - ${margin * 2}px)`, "important");
						elements[nextIndex].style.setProperty('overflow', 'hidden');
						elements[nextIndex].style.setProperty('margin', '0 auto');
						elements[nextIndex].style.setProperty('left', `0`);
						elements[nextIndex].style.setProperty('z-index', '1');

						elements[nextElementIndex].style.setProperty('margin-left', `-${margin - gap}px`);
						elements[nextElementIndex].style.setProperty('left', `100%`);
						elements[nextElementIndex].style.setProperty('z-index', '1');

						elements[lastElementIndex].style.setProperty('left', `calc(-100% + ${margin - gap}px)`);
						elements[lastElementIndex].style.setProperty('width', '100%');
						elements[lastElementIndex].style.setProperty('margin', `0`);
					}

					break;

				case 'fade':

					const elmts = document
						.getElementById(this.id)
						.getElementsByClassName(`slider-page-content`);

					for (let i = 0; i < elmts.length; i++) {
						elmts[i].style.setProperty('display', 'none');
					}

					nextImage.style.left = 0;
					prevImage.style.left = 0;

					nextImage.setAttribute(
						"class",
						`slider-page-${nextIndex} slider-active`
					);
					$(nextImage).fadeIn();

					prevImage.setAttribute(
						"class",
						`slider-page-${currentIndex}`
					);
					prevImage.classList.remove("slider-active");
					$(prevImage).fadeOut();

					break;

				default:

					nextImage.style.left = "-100%";
					prevImage.style.left = 0;

					nextImage.setAttribute(
						"class",
						`slider-page-${nextIndex} slider-active`
					);

					prevImage.setAttribute(
						"class",
						`slider-page-${currentIndex}`
					);
					prevImage.classList.remove("slider-active");
			}

			this.internal_config.currentIndex = nextIndex;

			this.autoPlaySlider();

		},
		nextSlider() {

			const {currentIndex} = this.internal_config;

			const nextIndex =
				currentIndex === this.$el.querySelectorAll('[element="SliderPageComponent"]').length - 1 ? 0 : currentIndex + 1;

			const nextImage = document
				.getElementById(this.id)
				.getElementsByClassName(`slider-page-${nextIndex}`)[0];

			const prevImage = document
				.getElementById(this.id)
				.getElementsByClassName(`slider-page-${currentIndex}`)[0];

			nextImage.style.position = "relative";
			prevImage.style.position = "absolute";

			switch (this.internal_config.effectSlide) {

				case 'slide':
					nextImage.style.left = "100%";
					prevImage.style.left = 0;

					nextImage.setAttribute(
						"class",
						`slider-page-${nextIndex} slider-in-right slider-active`
					);
					prevImage.setAttribute(
						"class",
						`slider-page-${currentIndex} slider-out-left`
					);
					prevImage.classList.remove("slider-active");
					break;

				case 'side-padding':

					const margin = this.internal_config.marginBetween || 80;
					const gap = this.internal_config.gapBetween || 50;

					const elements = document
						.getElementById(this.id)
						.getElementsByClassName(`side-padding-page`);

					if (elements.length < 3) {
						nextImage.style.left = "100%";
						prevImage.style.left = 0;

						nextImage.setAttribute(
							"class",
							`slider-page-${nextIndex} slider-active`
						);

						prevImage.setAttribute(
							"class",
							`slider-page-${currentIndex}`
						);
						prevImage.classList.remove("slider-active");
					} else {
						for (let i = 0; i < elements.length; i++) {
							elements[i].style.setProperty('z-index', '0');
						}

						// 100 left to 0
						elements[nextIndex].style.setProperty('width', `calc(100% - ${margin * 2}px)`, "important");
						elements[nextIndex].style.setProperty('overflow', 'hidden');
						elements[nextIndex].style.setProperty('margin', '0 auto');
						elements[nextIndex].style.setProperty('left', `0`);
						elements[nextIndex].style.setProperty('z-index', '1');

						const nextElementIndex = nextIndex + 1 === elements.length ? 0 : nextIndex + 1;
						elements[nextElementIndex].style.setProperty('margin-left', `-${margin - gap}px`);
						elements[nextElementIndex].style.setProperty('left', `100%`);

						const lastElementIndex = nextIndex === 0 ? (elements.length - 1) : nextIndex - 1;
						elements[lastElementIndex].style.setProperty('left', `calc(-100% + ${margin - gap}px)`);
						elements[lastElementIndex].style.setProperty('z-index', '1');
						elements[lastElementIndex].style.setProperty('width', '100%');
					}

					break;
				case 'fade':
					const elmts = document
						.getElementById(this.id)
						.getElementsByClassName(`slider-page-content`);

					for (let i = 0; i < elmts.length; i++) {
						elmts[i].style.setProperty('display', 'none');
					}
					nextImage.style.left = 0;
					prevImage.style.left = 0;

					nextImage.setAttribute(
						"class",
						`slider-page-${nextIndex} slider-active`
					);
					$(nextImage).fadeIn();

					prevImage.setAttribute(
						"class",
						`slider-page-${currentIndex}`
					);
					prevImage.classList.remove("slider-active");
					$(prevImage).fadeOut();

					break;

				default:

					nextImage.style.left = 0;
					prevImage.style.left = "100%";

					nextImage.setAttribute(
						"class",
						`slider-page-${nextIndex} slider-active`
					);

					prevImage.setAttribute(
						"class",
						`slider-page-${currentIndex}`
					);
					prevImage.classList.remove("slider-active");
			}

			this.internal_config.currentIndex = nextIndex;

			this.autoPlaySlider();
		},
		autoPlaySlider() {
			this.destroyAutoPlay();

			if (!this.internal_config.autoPlaySlider) return false;

			if (this.$el.querySelectorAll('[element="SliderPageComponent"]').length > 1) {
				this.internal_config.slider = setTimeout(() => {
					this.nextSlider();
				}, this.internal_config.timerSlider);
			}
		},
		animation() {
			return false;
		},
		styleGallery() {

			if (this.internal_config.effectSlide === 'side-padding') {
				const margin = this.internal_config.marginBetween || 80;
				return `margin-left: ${margin}px;
                minHeight : ${this.minHeight};
                overflow: hidden`;
			}

			return `minHeight : ${this.minHeight}`
		},

		style() {
			return `width: ${this.internal_config.width};
                 height:  ${this.internal_config.height};
                 z-index: ${this.internal_config.zIndex};max-width: ${this.internal_config.maxWidth};margin: 0 auto;
                 padding-top: ${this.internal_config.paddingTop};
                 padding-left: ${this.internal_config.paddingLeft};
                 padding-right: ${this.internal_config.paddingRight};
                 padding-bottom: ${this.internal_config.paddingBottom};
                 left:  ${this.internal_config.left};
                 top: ${this.internal_config.top}`;
		},
		styleArrow() {
			return `font-size: ${this.internal_config.arrowSize};
                color: ${this.internal_config.arrowColor}`;
		},
		styleResize() {
			return `{width: ${this.internal_config.width}, height:  ${this.internal_config.height}}`;
		}
	},
	mounted() {
		let vm = this;

		setInterval(() => {
			vm.replaceLinkWithTags();
		}, 1000)


		let device, childs, id, config;
		({device, childs, id, config} = this.$props);

		if (typeof config.backgroundColor === 'undefined') {
			config.backgroundColor = '#FFFFFF';
		}
		this.internal_config = config;
		this.device = device;

		if (typeof childs === 'undefined') {
			childs = [];
			this.internal_config.childs = [];
		}

		if (typeof id !== 'undefined') {
			this.id = id;
		}

		let objIds = [];
		$('[id]').each(function () {
			var ids = $('[id="' + this.id + '"]');
			if (ids.length > 1 && ids[0] == this && parseInt(this.id))
				objIds.push(this.id);
		});

		if (objIds.includes(this.id)) {
			let newId = Date.now() + parseInt(Math.random() * 10000);
			this.id = newId;

			childs.forEach((el, index) => {
				try {
					childs[index].config.parent_id = newId;
				} catch (e) {
				}
			});

			this.$refs.elem.id = newId;
		}

		this.elements = childs.sort((a, b) => (a.config.order_mobile > b.config.order_mobile) ? 1 : ((b.config.order_mobile > a.config.order_mobile) ? -1 : 0));

		// this.internal_config = window.mergeRecursive(this.props, this.$props.config);
		let internal_config = window.mergeRecursive(this.props, this.$props.config);
		this.internal_config = {...internal_config, ...this.internal_config};

		this.buildSlider();


		this.$root.$on('showSlider', (idShow) => {

			let elements = this.$el.querySelectorAll('[element="SliderPageComponent"]');

			let found = false;

			let nextIndex = 0;

			for (let i = 0; i < elements.length; i++) {
				if (elements[i].id == idShow) {
					found = true;
					nextIndex = i;
					break;
				}
			}

			const {currentIndex} = this.internal_config;

			if (currentIndex !== nextIndex && found) {

				const nextImage = document
					.getElementById(this.id)
					.getElementsByClassName(`slider-page-${nextIndex}`)[0];

				const prevImage = document
					.getElementById(this.id)
					.getElementsByClassName(`slider-page-${currentIndex}`)[0];

				nextImage.style.position = "relative";
				prevImage.style.position = "absolute";

				switch (this.internal_config.effectSlide) {

					case 'slide':
						nextImage.style.left = "100%";
						prevImage.style.left = 0;

						nextImage.setAttribute(
							"class",
							`slider-page-${nextIndex} slider-in-right slider-active`
						);
						prevImage.setAttribute(
							"class",
							`slider-page-${currentIndex} slider-out-left`
						);
						prevImage.classList.remove("slider-active");
						break;
					case 'fade':
						const elmts = document
							.getElementById(this.id)
							.getElementsByClassName(`slider-page-content`);

						for (let i = 0; i < elmts.length; i++) {
							elmts[i].style.setProperty('display', 'none');
						}

						nextImage.style.left = 0;
						prevImage.style.left = 0;

						nextImage.setAttribute(
							"class",
							`slider-page-${nextIndex} slider-active`
						);
						$(nextImage).fadeIn();

						prevImage.setAttribute(
							"class",
							`slider-page-${currentIndex}`
						);
						prevImage.classList.remove("slider-active");
						$(prevImage).fadeOut();

						break;

					default:

						nextImage.style.left = 0;
						prevImage.style.left = "100%";

						nextImage.setAttribute(
							"class",
							`slider-page-${nextIndex} slider-active`
						);

						prevImage.setAttribute(
							"class",
							`slider-page-${currentIndex}`
						);
						prevImage.classList.remove("slider-active");
				}

				this.internal_config.currentIndex = nextIndex;

				this.autoPlaySlider();
			}

		});
	}

};
</script>
<style scoped>
.carousel-control-next, .carousel-control-prev {
	width: 10%;
}
</style>
<style lang="scss">
[data-device="desktop"] {
  .minHeightUnsetDesk {
    min-height: unset !important;
  }
}

.height-100 {
  height: 100% !important;
}

.slider-modal-images {
  display: flex;
  flex-direction: column;

  :nth-child(4) {
    text-align: right;
  }
}

.slider-area-images {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  margin: 20px 0 20px 0;
}

.slider-box-image {
  position: relative;
  background-color: rgba(29, 64, 89, 0.8);
  border-radius: 10px;

  .slider-box-image {
    width: 100%;
    height: 150px;
    opacity: 1;
    display: block;
    transition: 0.5s ease;
    backface-visibility: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  &:hover .slider-box-image {
    opacity: 0.3;
  }

  .slider-box-option {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    width: 34px;
    height: 34px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;

    span {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        background-color: gray;
      }
    }
  }

  &:hover .slider-box-option {
    opacity: 1;
  }
}

//CSS SLIDER
.page-gallery {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;

  &.page-gallery-padding {
    > div:not(.slider-navigation-page):not(.slider-navigation-edition) {
      transition: 1s;
      overflow: hidden;

    }
  }

  //todo improve
  > div:not(.slider-navigation-page):not(.slider-navigation-edition) {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 100%;
    top: 0 !important;
    background-size: cover;
  }
}

[data-device="mobile"] {
  .page-gallery {
    > div.slider-active {
      position: relative;
    }

  }
}

.slider-navigation-edition,
.slider-navigation-page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;

  div {
    color: #d1d1d1;
    font-size: 25px;
    z-index: 50;
  }

  &:hover {
    cursor: pointer;
  }
}

.slider-navigation-edition {
  height: 10%;

  div {
    color: #0071ff;
  }
}

.slider-in-right {
  left: -100%;
  -webkit-animation: slideIn 1s forwards;
  animation: slideIn 1s forwards;
}

.slider-in-left {
  left: 100%;
  -webkit-animation: slideIn 1s forwards;
  animation: slideIn 1s forwards;
}

.slider-out-right {
  -webkit-animation: slideOutRight 1s forwards;
  animation: slideOutRight 1s forwards;
}

.slider-out-left {
  -webkit-animation: slideOutLeft 1s forwards;
  animation: slideOutLeft 1s forwards;
}

@keyframes slideIn {
  100% {
    left: 0;
  }
}

@keyframes slideOutRight {
  100% {
    left: 100%;
  }
}

@keyframes slideOutLeft {
  100% {
    left: -100%;
  }
}

@-webkit-keyframes slideOutLeft {
  100% {
    left: -100%;
  }
}

@-webkit-keyframes slideIn {
  100% {
    left: 0;
  }
}

@-webkit-keyframes slideOutRight {
  100% {
    left: 100%;
  }
}
</style>
