<template>
  <div :id="this.id"
       :data-hide-desktop="!this.internal_config.showDesktop"
       :data-hide-mobile="!this.internal_config.showMobile"
       :style="style()"
       class="sitebuilder-element-container"
       element="GetResponseFormComponent">
    <div
        :style="`transform: rotate(${this.$props.device === 'desktop' ? this.internal_config.rotation : 0}) scale(1);`">
      <form
          :id="internal_config.listUid" ref="form"
          :class="horizontal ? 'horizontal-form': ''"
          class="form-getresponse"
          method="post"
      >
        <div ref="container" class="inputs-area"></div>

        <div v-if="internal_config.recaptchaSiteKey" class="recaptcha-container">
          <vue-recaptcha :sitekey="internal_config.recaptchaSiteKey"></vue-recaptcha>
        </div>

        <div class="place-btn" v-on:click="submit($event)">
          <div class="bt-sb"
               v-bind:style="{
                            width : buttonWidth,
                            border : buttonBorder,
                            'border-radius': buttonRadius,
                            background: buttonBackground,
                            'box-shadow': buttonBoxShadow
                           }">
            <div class="btn-text-area"
                 v-bind:style="{minHeight: buttonHeight}">
              <div :style="textStyle()"
                   class="sb-text">{{ internal_config.button.text }}
              </div>
              <small v-show="internal_config.button.subText != ''" :style="subTextStyle()"
                     class="sb-sub-text">{{ internal_config.button.subText }}</small>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

import Vue from 'vue';
import Http from '../../../../js/Http';
import Data from './Model';
import VueRecaptcha from 'vue-recaptcha';
import axios from 'axios';

import CheckFields from "../Fields/CheckFields.vue";
import SelectFields from "../Fields/SelectFields.vue";
import TextFields from "../Fields/Text.vue";
import DateFields from "../Fields/DateFields.vue";

export default {
  name: 'GetResponseFormComponent',
  props: ['config', 'id', 'preview', 'device'],
  components: {
    VueRecaptcha
  },
  computed: {
    horizontal() {
      return this.internal_config.horizontal;
    },
    buttonWidth() {
      return parseInt(this.internal_config.button.width) + '%';
    },
    buttonHeight() {
      return parseInt(this.internal_config.button.height) + 'px';
    },
    buttonBorder() {
      return this.internal_config.button.border;
    },
    buttonBoxShadow() {
      return this.internal_config.button.boxShadow;
    },
    buttonRadius() {
      return this.internal_config.button.borderRadius;
    },
    buttonBackground() {
      let bg = this.internal_config.button.backgroundColor;

      if (this.$globalStoreHelpers.isValidHex(bg)) {
        bg = this.$globalStoreHelpers.hexToRGBA(bg);
      }

      if (this.internal_config.backgroundImage && this.internal_config.backgroundImage !== 'none') {
        bg += ' url("' + this.internal_config.button.backgroundImage + '")';
        bg += ' ' + this.internal_config.button.backgroundPosition;
        bg += ' / ' + this.internal_config.button.backgroundSize;
        bg += ' ' + this.internal_config.button.backgroundRepeat;
        bg += ' ' + this.internal_config.button.backgroundOrigin;
      }

      return bg;
    }
  },
  data() {
    return Data.data();
  },
  methods: {
    submit(e) {
      $('body').addClass('loading');

      let form = this.$refs.container;
      var inputs = form.getElementsByClassName("form-group");
      let not_pass = false;
      for (var i = 0, len = inputs.length; i < len; i++) {
        var field = inputs[i].querySelector('input, select, textarea');
        var checkFieldsRequired = inputs[i].hasAttribute('data-check-required');

        if (inputs[i].style.display === 'none') {
          continue;
        }

        if (!checkFieldsRequired) {

          if (field.required && field.value === '') {
            not_pass = true;

            field.style.outlineColor = 'red';
            field.style.outlineWidth = '1px';
            field.style.outlineStyle = 'solid';

          } else {
            field.style.outlineColor = 'transparent';
            field.style.outlineWidth = '1px';
            field.style.outlineStyle = 'solid';
          }
        } else {

          try {

            let inputsCheck = inputs[i].querySelectorAll('[type=checkbox][data-required=true],[type=radio][data-required=true]');
            let checked = 0;

            if (inputsCheck.length > 0) {

              inputsCheck.forEach(element => {
                if (element.checked) checked++;
              });

              if (checked === 0) {
                not_pass = true;

                inputsCheck[0].style.outlineColor = 'red';
                inputsCheck[0].style.outlineWidth = '1px';
                inputsCheck[0].style.outlineStyle = 'solid';
              } else {
                inputsCheck[0].style.outlineColor = 'transparent';
                inputsCheck[0].style.outlineWidth = '1px';
                inputsCheck[0].style.outlineStyle = 'solid';
              }
            }

          } catch (e) {
            console.warn('Error', e);
          }
        }
      }

      let recaptchaResponse = (document.getElementById('g-recaptcha-response') || {}).value;
      if (this.internal_config.recaptchaSiteKey && (recaptchaResponse === '' || recaptchaResponse === null)) {
        not_pass = true;
      }

      if (not_pass) {
        $('body').removeClass('loading');
        this.$root.$emit('errorMessagePopup', [this.$t('ct-please-check-the-required-fields')]);
      } else {

        var formdata = $(this.$refs.form).serializeArray();
        var data = {};
        $(formdata).each(function (index, obj) {
          data[obj.name] = obj.value;
        });

        if (this.internal_config.dayOfCycle == '')
          this.internal_config.dayOfCycle = null;

        var obj = {
          'email': data.email,
          'dayOfCycle': this.internal_config.dayOfCycle,
          'name': data.name,
          "campaign": {
            "campaignId": this.$refs.form.id
          },
          "customFieldValues": []
        }

        delete data.email
        delete data.name

        for (var k in data) {
          var field = {
            "customFieldId": k.replace('[]', ''),
            "value": [
              data[k]
            ]
          }
          obj.customFieldValues.push(field);
        }

        axios.post('/cheetah/get_response_form_submit?token=' + this.internal_config.token + '&id=' + this.id, obj).then(res => {
          Http.api().get('/get-response/campaign/' + this.internal_config.token + '/' + this.$refs.form.id).then(r => {

            var msg = this.internal_config.successMessage
            if (msg === undefined || msg == '') {
              msg = this.$t('ct-get-response-contact-added-successfully');
            }
            if (this.internal_config.action == 'message') {
              $(this.$refs.form).trigger("reset");
              this.$root.$emit('successMessagePopup', msg);
            } else {
              if (this.internal_config.tab == 'same') {
                window.location = this.internal_config.redirectUrl;
              } else {
                window.open(this.internal_config.redirectUrl, '_blank');
                this.$root.$emit('successMessagePopup', msg);
              }
            }

            $('body').removeClass('loading');

          }).catch(err => {
            $('body').removeClass('loading');
            this.$root.$emit('errorMessagePopup', err.message);
          });
        }).catch(err => {
          $('body').removeClass('loading');

          try {
            const {message, context} = err.response.data
            let errors = []
            const contextErrors = context.map((cont) => cont.errorDescription)

            if (message === "Contact already added") {
              errors = [this.$t('ct-get-response-contact-already-added')];
            } else {
              errors = [this.$t('ct-get-response-validation-error')];
            }

            if (errors.length) {
              errors = [...errors, ...contextErrors]
            }
            this.$root.$emit('errorMessagePopup', errors);
          } catch (e) {
            this.$root.$emit('errorMessagePopup', [this.$t('ct-get-response-validation-error')]);
          }
        });

      }

    },
    buildFields: function (vm) {

      this.$refs.container.innerHTML = '';

      vm.internal_config.fields.forEach(field => {
        var buildField = field;
        let identifier = field.field;
        let typeField = typeof field.target_field_type !== 'undefined' ? field.target_field_type : ''
        const validTypeField = ['textarea', 'text', 'multiselect', 'select', 'dropdown', 'datetime', 'date', 'checkbox', 'radio']

        buildField.tag = field.target_field_id;

        if (validTypeField.includes(typeField)) {
          identifier = typeField
        }

        if (['single_select', 'multi_select'].includes(typeField)) {
          if (typeField === 'single_select') {
            identifier = 'select'
          } else if (typeField === 'multi_select') {
            identifier = 'multiselect'
          }
        }

        if (typeof identifier === "undefined") {
          identifier = "text";
        }

        if (['email', 'name'].includes(buildField.tag)) {
          buildField.required = 'yes';
          buildField.visibility = 'visible';
        }

        if (identifier === 'textarea') vm.createTextField(field, true);
        if (identifier === 'text') vm.createTextField(field, false);
        if (identifier === 'multiselect') vm.createDropDown(field, true);
        if (identifier === 'select') vm.createDropDown(field, false);
        if (identifier === 'dropdown') vm.createDropDown(field, false);
        if (identifier === 'datetime') vm.createDateTime(field, true);
        if (identifier === 'date') vm.createDateTime(field, false);
        if (identifier === 'checkbox') vm.createCheckField(field, 'checkbox');
        if (identifier === 'radio') vm.createCheckField(field, 'radio');
      });
    },

    textStyle() {
      return `font-family:  ${this.internal_config.button.textStyle.family};
                 font-size: ${this.internal_config.button.textStyle.size};
                 text-align: ${this.internal_config.button.textStyle.align};
                 font-weight: ${this.internal_config.button.textStyle.weight};
                 font-style: ${this.internal_config.button.textStyle.style};
                 color: ${this.internal_config.button.textStyle.color}`;
    },

    subTextStyle() {
      return `font-family:  ${this.internal_config.button.subTextStyle.family};
                 font-size: ${this.internal_config.button.subTextStyle.size};
                 text-align: ${this.internal_config.button.subTextStyle.align};
                 font-weight: ${this.internal_config.button.subTextStyle.weight};
                 font-style: ${this.internal_config.button.subTextStyle.style};
                 color: ${this.internal_config.button.subTextStyle.color}`;
    },
    build(config) {
      this.internal_config = config;
    },
    style() {
      return `left:  ${this.internal_config.left};
						padding-top: ${this.internal_config.paddingTop};
						  width: ${this.internal_config.width};
                     z-index: ${this.internal_config.zIndex};max-width: ${this.internal_config.maxWidth};margin: 0 auto;
                padding-left: ${this.internal_config.paddingLeft};
                padding-right: ${this.internal_config.paddingRight};
                padding-bottom: ${this.internal_config.paddingBottom};
								top: ${this.internal_config.top};`;
    },

    createCheckField(field, type) {
      let ComponentClass = Vue.extend(CheckFields);
      let instance = new ComponentClass({
        propsData: {
          type: type,
          label: field.label,
          required: field.required,
          options: field.options,
          showLabel: true,
          showPlaceholder: this.internal_config.showPlaceholder,
          selected: [],
          data: field,
        }
      });
      instance.$mount();
      this.$refs.container.appendChild(instance.$el)
    }
    ,
    createDropDown(field, multiple) {
      let ComponentClass = Vue.extend(SelectFields);
      let instance = new ComponentClass({
        propsData: {
          label: field.label,
          options: field.options,
          showLabel: this.internal_config.showLabel,
          showPlaceholder: this.internal_config.showPlaceholder,
          selected: [],
          data: field,
          multiple: multiple,
        }
      });
      instance.$mount();
      this.$refs.container.appendChild(instance.$el)
    },
    createDateTime(field, useCurrent) {

      let ComponentClass = Vue.extend(DateFields);
      let instance = new ComponentClass({
        propsData: {
          label: field.label,
          data: field,
          showLabel: this.internal_config.showLabel,
          showPlaceholder: this.internal_config.showPlaceholder,
          value: new Date().toString(),
          type: (useCurrent ? 'datetime' : 'date'),
        }
      });
      instance.$mount();
      this.$refs.container.appendChild(instance.$el)
    },
    createTextField(field, area) {
      let ComponentClass = Vue.extend(TextFields);
      let instance = new ComponentClass({
        propsData: {
          label: field.label,
          showLabel: this.internal_config.showLabel,
          showPlaceholder: this.internal_config.showPlaceholder,
          value: '',
          data: field,
          area: area,
        }
      });
      instance.$mount();
      this.$refs.container.appendChild(instance.$el)
    },
    inputStyle() {

      let vm = this;

      let elements = this.$el.getElementsByClassName('form-control');
      [].forEach.call(elements, function (el) {
        el.style.backgroundColor = vm.internal_config.inputColor;
        el.style.border = vm.internal_config.inputBorder;
        if (el.tagName === 'TEXTAREA' && vm.internal_config.textareaHeight) {
          el.style.height = vm.internal_config.textareaHeight;
          el.classList.add('form-control-textarea-has-height');
        } else {
          el.style.height = vm.internal_config.inputHeight;
        }
        el.style.borderRadius = vm.internal_config.inputRadius;

        el.style.fontFamily = vm.internal_config.inputStyle.family;
        el.style.fontSize = vm.internal_config.inputStyle.size;
        el.style.textAlign = vm.internal_config.inputStyle.align;
        el.style.fontStyle = vm.internal_config.inputStyle.style;
        el.style.fontStyle = vm.internal_config.inputStyle.style;
        el.style.color = vm.internal_config.inputStyle.color;
      });

      vm.$el.style.color = vm.internal_config.labelStyle;
    }
    ,
  },

  mounted() {

    let vm = this;

    this.build(this.$props.config);
    this.internal_config.button.subText = this.internal_config.button.subtext;

    Object.keys(this.props).forEach(prop => {
      if (typeof this.internal_config[prop] === 'undefined') {
        this.internal_config[prop] = this.props[prop];
      }
    });

    this.$root.$emit('applyAnimation', this, 'sitebuilder-element-container');


    this.$el.classList.add('preview');

    this.$el.addEventListener('mouseover', function () {

      if (vm.$globalStoreHelpers.isValidHex(vm.internal_config.button.backgroundColorHover)) {
        vm.internal_config.button.backgroundColorHover = vm.$globalStoreHelpers.hexToRGBA(vm.internal_config.button.backgroundColorHover);
      }
      if (vm.$globalStoreHelpers.isValidHex(vm.internal_config.button.textColorHover)) {
        vm.internal_config.button.textColorHover = vm.$globalStoreHelpers.hexToRGBA(vm.internal_config.button.textColorHover);
      }

      document.body.style.setProperty('--button-background-color--hover', vm.internal_config.button.backgroundColorHover);
      document.body.style.setProperty('--button-text-color--hover', vm.internal_config.button.textColorHover);
    });


    if (this.internal_config.listUid !== '') {
      this.buildFields(this);
    }

    this.inputStyle();
  },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.preview .bt-sb:hover {
  cursor: pointer;
  background-color: var(--button-background-color--hover) !important;
  color: var(--button-text-color--hover) !important;
  -webkit-text-fill-color: var(--button-text-color--hover) !important;
}


.horizontal-form .inputs-area {
  display: flex;
  justify-content: space-between;
}

.btn-text-area.show-label {
  margin-top: 32px;
}

.sb-text {
  line-height: 25px;
}

.place-btn {
  text-align: center;
}

.btn-text-area {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

[data-device="tablet"] .btn-text-area,
[data-device="mobile"] .btn-text-area {
  width: auto !important;
}

</style>

<style lang="scss">

.form-getresponse.horizontal-form .inputs-area > div {
  margin-right: 15px;
}

select.form-control[multiple],
select.form-control[size],
textarea.form-control:not(.form-control-textarea-has-height) {
  height: auto !important;
}

.preview .bt-sb:hover {
  cursor: pointer;
  background-color: var(--button-background-color--hover) !important;
  color: var(--button-text-color--hover) !important;
}

.recaptcha-container {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}
</style>
