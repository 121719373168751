<template>
    <div :id="this.id"
         class="sitebuilder-element-container"
         element="ButtonComponent"
         :data-hide-desktop="!this.internal_config.showDesktop"
         :data-hide-mobile="!this.internal_config.showMobile"
         :data-tag="dataTag()"
         :style="style()">

        <div v-bind:style="styleButton()" class="bt-sb bt-hover desktop-element">
            <a class="bt-text-area action0" v-if="htmlLink()"
               :href="hrefLink('href')"
               :data-action="internal_config.link.type"
               rel="noreferrer"
               :data-tag="dataTagBuilderall()"
               :target="hrefLink('href') != 'http://' ? hrefLink('target') : '_self'"
               @click.prevent="linkAction"
               v-bind:style="{ height : height, width : width, cursor: `${internal_config.cursorType}`}">
                <span class="btn sb-text" :style="textStyle()">{{internal_config.text}}</span>
                <small class="btn sb-sub-text" v-show="this.internal_config.subtext != ''"
                       :style="subTextStyle()">{{internal_config.subtext}}</small>
            </a>
            <a class="bt-text-area action1" v-if="!htmlLink()" @click.prevent="linkAction"
               :data-action="internal_config.link.type"
               :data-tag="dataTagBuilderall()"
               v-bind:style="{ height : height, width : width, cursor: `${internal_config.cursorType}`}">
                <span class="btn sb-text" :style="textStyle()">{{internal_config.text}}</span>
                <small class="btn sb-sub-text" v-show="this.internal_config.subtext != ''"
                       :style="subTextStyle()">{{internal_config.subtext}}</small>
            </a>
        </div>
        <div v-bind:style="styleButton()" class="bt-sb bt-hover mobile-element">
            <a class="bt-text-area action0" v-if="htmlLink()"
               :href="hrefLink('href')"
               :data-tag="dataTagBuilderall()"
               :data-action="internal_config.link.type"
               :target="hrefLink('target')"
               @click.prevent="linkAction"
               v-bind:style="{ height : height, width : width}">
                <span class="btn sb-text" :style="textStyleMobile()">{{internal_config.text}}</span>
                <!--                <br :v-show="this.internal_config.subtext != ''"/>-->
                <small class="btn sb-sub-text" v-show="this.internal_config.subtext != ''"
                       :style="subTextStyle()">{{internal_config.subtext}}</small>
            </a>
            <a class="bt-text-area action1" v-if="!htmlLink()"
             @click.prevent="linkAction"
               :data-tag="dataTagBuilderall()"
               :data-action="internal_config.link.type"
               v-bind:style="{ height : height, width : this.internal_config.maxWidth}">
                <span class="btn sb-text" :style="textStyleMobile()">{{internal_config.text}}</span>
                <!--                <br :v-show="this.internal_config.subtext != ''"/>-->
                <small class="btn sb-sub-text" v-show="this.internal_config.subtext != ''"
                       :style="subTextStyle()">{{internal_config.subtext}}</small>
            </a>
        </div>
    </div>
</template>

<script>

import Data from './Model';
import axios from 'axios';


export default {
        name: 'ButtonComponent',
        props: ['config', 'id', 'preview', 'device'],
        computed: {
            width() {
                return this.internal_config.width;
            },
            height() {
                return this.internal_config.height;
            },
            border() {

                if (typeof this.internal_config.border !== 'undefined') {
                    let splitedBorder = this.internal_config.border.split(' ');

                    if (typeof splitedBorder[2] !== 'undefined' && this.$globalStoreHelpers.isValidHex(splitedBorder[2])) {
                        splitedBorder[2] = this.$globalStoreHelpers.hexToRGBA(splitedBorder[2]);
                        this.internal_config.border = splitedBorder.join(' ');
                    }
                }
                return this.internal_config.border;
            },
            boxShadow() {
                return this.internal_config.boxShadow;
            },
            radius() {
                return this.internal_config.borderRadius;
            },
            backgroundColor() {

                if (this.$globalStoreHelpers.isValidHex(this.internal_config.backgroundColor)) {
                    this.internal_config.backgroundColor = this.$globalStoreHelpers.hexToRGBA(this.internal_config.backgroundColor);
                }

                return this.internal_config.backgroundColor;
            },
            backgroundImage() {
                if (this.internal_config.backgroundImage !== 'none' && typeof this.internal_config.backgroundImage !== 'undefined') {
                    this.internal_config.backgroundImage = this.internal_config.backgroundImage.replace('omb11.com', 'storage.builderall.com');
                    return 'url("' + this.internal_config.backgroundImage + '")';
                }
                return '';
            },
            backgroundPosition() {
                if (this.internal_config.backgroundImage !== 'none' && typeof this.internal_config.backgroundImage !== 'undefined') {
                    return this.internal_config.backgroundPosition
                }
                return '';
            },
            backgroundOrigin() {
                if (this.internal_config.backgroundImage !== 'none' && typeof this.internal_config.backgroundImage !== 'undefined') {
                    return this.internal_config.backgroundOrigin
                }
                return '';
            },
            backgroundRepeat() {
                if (this.internal_config.backgroundImage !== 'none' && typeof this.internal_config.backgroundImage !== 'undefined') {
                    return this.internal_config.backgroundRepeat
                }
                return '';
            },
            backgroundSize() {
                if (this.internal_config.backgroundImage !== 'none' && typeof this.internal_config.backgroundImage !== 'undefined') {
                    return this.internal_config.backgroundSize
                }
                return '';
            },
        },
        data() {
            return Data.data();
        },
        methods: {

            htmlLink() {
                if (this.internal_config.link.type === 'web' || this.internal_config.link.type === 'page' || this.internal_config.link.type === 'email' || this.internal_config.link.type === 'popup') {
                    return true
                } else {
                    return false;
                }
            },
            htmlVideo() {
                if (this.internal_config.link.type === 'video' && this.internal_config.link.value !== '') {
                    // return this.internal_config.link.value;
                }
            },

            getValidUrl(url = "") {

                let buttonUrl;

                try {
                    buttonUrl = window.decodeURIComponent(url);
                }
                catch (e) {
                    buttonUrl = url;
                }

                  const newUrl = buttonUrl;

                /**
                 * tratamento links do office porque ao invés de arrumar o script deles
                 * decidiram encodificar todas as urls de funil antes de adicionar no editor
                 * se alguma url de cadastro de funil não funcionar porque esqueceu de converter não arrumar a função porque tá certo ignorar essa condição mesmo
                 * se arrumar essa vai quebrar os que ja funcionam,
                 * encaminhar problema ao fernando jr
                 */

                if(newUrl.indexOf('redir=') !== -1){
                    return url;
                }

                if (/^(:\/\/)/.test(newUrl)) {
                    return `https${newUrl}`;
                }
                if (!/^(f|ht)tps?:\/\//i.test(newUrl) && newUrl.indexOf('https') === -1) {
                    return `https://${newUrl}`;
                }

                 if (!/^(f|ht)tps?:\/\//i.test(newUrl) && newUrl.indexOf('https') !== -1) {
                    return `${newUrl}`;
                }

                return newUrl;
            },
            dataTagBuilderall() {
                let vm = this;
                if (this.internal_config.link.type == "builderall") {


                    let appendScripts = true;

                    // if(typeof window.tags_script == 'undefined') {
                    //     window.tags_script = true;
                    // }else{
                    //     appendScripts = false;
                    // }

                    if(appendScripts) {
                        // $('[data-tag]').off('click');
                        setTimeout(function () {

                            // $('[data-tag]').off('click');

                            var s = document.createElement("script");
                            s.type = "text/javascript";
                            s.src = "/static/office/script.js";
                            $("head").append(s);
                            setTimeout(function () {
                                $('.sitebuilder-element [data-tag]').css('display', 'initial');
                            }, 4000);
                        },2000);
                    }


                    // vm.internal_config.link.type = 'web';



                    return this.internal_config.link.valueID;
                }
            },

            hrefLink(key) {
                let obj = {};

                if(this.internal_config.link.type === 'email'){
                   obj.href = 'mailto:' + this.internal_config.link.value.value +
                                           '?cc=' + (this.internal_config.link.value.cc == '') ? ' ' : this.internal_config.link.value.cc +
                                           '&bcc=' + (this.internal_config.link.value.bcc == '') ? ' ' : this.internal_config.link.value.bcc +
                                           '&subject=' + (this.internal_config.link.value.subject == '') ? ' ' : this.internal_config.link.value.subject +
                                           '&body='+ this.internal_config.link.value.body;

                    this.internal_config.link.target = '_self'
                    return obj.href
                }

                if (this.internal_config.link.type === 'web') {
                    obj.href = this.getValidUrl(this.internal_config.link.value)

                  if(obj.href.indexOf('[') !== -1 && obj.href.indexOf(']') !== -1){

                    let test = obj.href.replace('https://', '');

                    if(test.slice(0, 1) === '[' && test.slice(test.length - 1, test.length) === ']') {
                      obj.href = test;
                    }

                  }
                  // console.log(obj.href);

                } else if (this.internal_config.link.type === 'page') {

                    pagesConfig.forEach(page => {
                        if (this.internal_config.link.valueID == page.id) {
                            obj.href = '/' + page.url;
                        }
                    })

                } else {
                    obj.href = '#';
                }

                if(obj.href == 'http://'){
					          obj.href = '#';
                }

                obj.target = this.internal_config.link.target;

                return obj[key];
            },

            linkAction: function () {

            let vm = this;

              if( typeof this.internal_config.multipleActions !== 'undefined' && this.internal_config.multipleActions.length > 0) {
                axios.get(`/cheetah/link/action?btn_id=${this.id}`)
                .then( (res) => {
                    let response = res.data;
                    if(response !== 'error'){


                        let link = false;

                        let actions = vm.internal_config.multipleActions.sort((a, b) => a.limit - b.limit)

                        actions.forEach( (config) => {
                            console.log(parseInt(config.limit) <= parseInt(response) );
                            if(parseInt(response) <= parseInt(config.limit) && !link){
                                link = config;
                                return;
                            }
                        } )

                        /**
                         * se tiver estourado o limite de links e a ação de rotator estiver ativada, volta o contador para 1
                         */
                        if(!link && typeof this.internal_config.multipleActionsRotator !== 'undefined' && this.internal_config.multipleActionsRotator){
                            this.clearClicks()
                            link = actions[0];
                        }

                        /**
                         * se não encontrar link porém sem rotator, permanece no ultimo
                         */
                         if(!link){
                            link = actions[actions.length -1];
                        }

                        vm.$root.$emit('linkAction', link.link);

                    }else{
                       vm.$root.$emit('linkAction', this.internal_config.link);
                    }
                } )
              } else {
                  this.$root.$emit('linkAction', {...this.internal_config.link, element_id: this.id});
              }



            },
            clearClicks() {
                axios.get(`/cheetah/link/action?btn_id=${this.id}&clear=1`)
                .then( res => { console.log(res.data) } )
            },
            buildIcon: function () {

                $(this.$el).find('i').detach();

                if (this.internal_config.icon !== '') {
                    if (this.internal_config.iconPosition === 'left') {
                        $('<i>').addClass('mdi ' + this.internal_config.icon).prependTo($(this.$el).find('.sb-text').get(0));
                        $('<i>').addClass('mdi ' + this.internal_config.icon).prependTo($(this.$el).find('.sb-text').get(1));
                    }
                    if (this.internal_config.iconPosition === 'right') {
                        $('<i>').addClass('mdi ' + this.internal_config.icon).appendTo($(this.$el).find('.sb-text').get(0));
                        $('<i>').addClass('mdi ' + this.internal_config.icon).appendTo($(this.$el).find('.sb-text').get(1));
                    }
                }
            },
            build(config) {
                this.internal_config = config;

                if (this.internal_config.link.type === 'showHidePanel') {
                    if (typeof this.internal_config.link.hiddenPanelName !== 'undefined' && this.internal_config.link.hiddenPanelName !== '') {
                        let buttonID = '#' + this.$el.id;
                        $(buttonID).attr('text-show-panel', this.internal_config.link.hiddenPanelName)
                        $(buttonID).attr('text-hide-panel', this.internal_config.text);

                        $(buttonID).attr('panel-reference-id', this.internal_config.link.value.replace('cheetah-panel-show-hide-', ''))

                        if (this.internal_config.icon !== '') {
                            $(buttonID).attr('icon-name', this.internal_config.icon);
                            $(buttonID).attr('icon-position', this.internal_config.iconPosition);
                        }
                    }


                }

                this.buildIcon();
            },
            textStyle() {

                let colorFont = this.internal_config.textStyle.color;
                if (this.$globalStoreHelpers.isValidHex(colorFont)) {
                    colorFont = this.$globalStoreHelpers.hexToRGBA(colorFont);
                }

                return `font-family:  ${this.internal_config.textStyle.family};
                 font-size: ${this.internal_config.textStyle.size};
                 text-align: ${this.internal_config.textStyle.align};
                 text-decoration: ${this.internal_config.textStyle.stroke};
                 font-weight: ${this.internal_config.textStyle.weight};
                 font-style: ${this.internal_config.textStyle.style};
                 color: ${colorFont}`;
            },
            textStyleMobile() {

                let colorFont = this.internal_config.textStyle.color;
                if (this.$globalStoreHelpers.isValidHex(colorFont)) {
                    colorFont = this.$globalStoreHelpers.hexToRGBA(colorFont);
                }

                return `font-family:  ${this.internal_config.textStyle.family};
                 font-size: ${this.internal_config.textStyle.sizeMobile} !important;
                 text-align: ${this.internal_config.textStyle.align};
                 text-decoration: ${this.internal_config.textStyle.stroke};
                 font-weight: ${this.internal_config.textStyle.weight};
                 font-style: ${this.internal_config.textStyle.style};
                 color: ${colorFont}`;
            },
            subTextStyle() {

                let colorFont = this.internal_config.subTextStyle.color;
                if (this.$globalStoreHelpers.isValidHex(colorFont)) {
                    colorFont = this.$globalStoreHelpers.hexToRGBA(colorFont);
                }

                return `font-family:  ${this.internal_config.subTextStyle.family};
                 font-size: ${((this.device === 'desktop') ? this.internal_config.subTextStyle.size : this.internal_config.subTextStyle.sizeMobile)} !important;
                 text-align: ${this.internal_config.subTextStyle.align};
                 font-weight: ${this.internal_config.subTextStyle.weight};
                 font-style: ${this.internal_config.subTextStyle.style};
                 text-decoration: ${this.internal_config.subTextStyle.stroke};
                 color: ${colorFont}`;
            },
            style() {
                return `left:  ${this.internal_config.left};
				        padding-top: ${this.internal_config.paddingTop};
                     z-index: ${this.internal_config.zIndex};max-width: ${this.internal_config.maxWidth};margin: 0 auto;
                padding-left: ${this.internal_config.paddingLeft};
                padding-right: ${this.internal_config.paddingRight};
                padding-bottom: ${this.internal_config.paddingBottom};
                top: ${this.internal_config.top};`;
            },
            styleButton() {
                return `height : ${this.height};
                        width : ${this.width};
                        border : ${this.border};
                        border-radius: ${this.radius};
                        background: ${this.backgroundColor};
                        backgroundImage: ${this.backgroundImage};
                        backgroundSize: ${this.backgroundSize};
                        backgroundPosition: ${this.backgroundPosition};
                        backgroundOrigin: ${this.backgroundOrigin};
                        backgroundRepeat: ${this.backgroundRepeat};
                        box-shadow: ${this.boxShadow};
                        transform: rotate(${this.$props.device == 'desktop' ? this.internal_config.rotation : 0}) scale(1);
                        `;
            },
            styleResize() {
                return `{width: ${this.internal_config.width}, height:  ${this.internal_config.height}}`;
            },
            handler: function (e) {
                this.$root.$emit('focusElement', this);
                this.$root.$emit('handlerElement', this);
                e.stopPropagation();
                e.preventDefault();
            },

            dataTag() {
                if (this.internal_config.tag) {
                    return this.internal_config.tag;
                }
                return '';
            },
        },
        mounted() {
            this.build(this.$props.config);

            this.internal_config = window.mergeRecursive(this.props, this.$props.config);

            // this.$root.$emit('applyAnimation', this, 'sitebuilder-element-container');

            if (this.internal_config.hover === false) {
                let children = this.$el.children;
                for (let x = 0; x < children.length; x++) {
                    children[x].classList.remove("bt-hover");
                }
            } else {
                this.$nextTick(() => {

                    let backgroundColorHover = this.internal_config.backgroundColorHover;
                    let textColorHover = this.internal_config.textColorHover;

                    if (this.$globalStoreHelpers.isValidHex(backgroundColorHover)) {
                        backgroundColorHover = this.$globalStoreHelpers.hexToRGBA(backgroundColorHover);
                    }

                    if (this.$globalStoreHelpers.isValidHex(textColorHover)) {
                        textColorHover = this.$globalStoreHelpers.hexToRGBA(textColorHover);
                    }

                    this.$el.addEventListener('mouseover', function () {
                        document.body.style.setProperty('--button-background-color--hover', backgroundColorHover);
                        document.body.style.setProperty('--button-text-color--hover', textColorHover);
                    });
                });
            }
        },

        updated(){
            this.$root.$emit('applyAnimation', this, 'sitebuilder-element-container');
        }
    }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .vdr, .bt-sb {
        display: flex;
        align-content: center;
        align-items: center;
    }

    .bt-text-area {

        .btn {
            padding: 0 !important;
        }

    }

    .bt-hover:hover {
        background: var(--button-background-color--hover) !important;
        color: var(--button-text-color--hover) !important;
        -webkit-text-fill-color: var(--button-text-color--hover) !important;

        .btn {
            color: var(--button-text-color--hover) !important;
            -webkit-text-fill-color: var(--button-text-color--hover) !important;
        }
    }

    .bt-sb:hover {

        a{
            text-decoration: none !important;
        }
    }
</style>
<style lang="scss">

    [data-device="mobile"]  {
        .bt-sb {
            width: 100% !important;
        }
    }
    .bt-text-area {
        width: 100% !important;
        margin: 0 auto;
        vertical-align: middle;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .bt-sb {
        margin: 0 auto;
    }

    [data-device="desktop"]  {
        [element="ButtonComponent"] {
            text-align: center !important;

        }
    }

    .bt-text-area {
        /*display: table-cell;*/
        /*vertical-align: middle;*/

        .sb-sub-text,
        .sb-text {
            width: 100%;
        }

        .sb-text {
            padding-bottom: 0 !important;
        }

        .sb-sub-text {
            padding-top: 0 !important;
        }

        .top,
        .bottom {
            width: 100%;
            display: block;
            text-align: center;
        }
    }


</style>
