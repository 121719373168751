<template>
  <div>
    <a
      v-if="item.children.length > 0"
      :style="textStyle"
      class="nav-link text-wrap dropdown-toggle"
      v-toggle
    >
      <i
        class="mdi"
        v-if="item.icon.selected"
        v-show="item.icon.position === 'left'"
        :class="item.icon.selected"
      ></i>
      {{item.name}}
      <i
        class="mdi"
        v-if="item.icon.selected"
        v-show="item.icon.position === 'right'"
        :class="item.icon.selected"
      ></i>
    </a>
    <a v-else :style="textStyle" class="nav-link text-wrap" @click="linkAction(item)">
      <i
        class="mdi"
        v-if="item.icon.selected"
        v-show="item.icon.position === 'left'"
        :class="item.icon.selected"
      ></i>
      {{item.name}}
      <i
        class="mdi"
        v-if="item.icon.selected"
        v-show="item.icon.position === 'right'"
        :class="item.icon.selected"
      ></i>
    </a>
    <div class="toggle">
      <a
        :style="subTextStyle"
        class="burguer-sub-item dropdown-item d-block text-wrap"
        v-for="child in item.children"
        @click="linkAction(child)"
      >
        <i
          class="mdi"
          v-if="child.icon.selected"
          v-show="child.icon.position === 'left'"
          :class="child.icon.selected"
        ></i>
        {{child.name}}
        <i
          class="mdi"
          v-if="child.icon.selected"
          v-show="child.icon.position === 'right'"
          :class="child.icon.selected"
        ></i>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: "itemMenuMobile",
  props: {
    item: {
      type: Object,
      required: true
    },
    textStyle: {
      type: Object,
      required: true
    },
    subTextStyle: {
      type: Object,
      required: true
    }
  },
  methods: {
    linkAction: async function (item) {
      await this.$parent.myFunction();

      if (item.link.type === 'newAnchor') {
        setTimeout(() => {
          this.$root.$emit("linkAction", item.link);
        }, 800);
      } else {
        this.$root.$emit("linkAction", item.link);
      }
    },
  }
};
</script>
<style scoped lang="scss">
.nav-link {
  background: var(--menu-item-background);

  //Hover for menu item
  &:hover {
    background: var(--menu-item-background--hover);
  }

  //Dropdown active
  &.dropdown-toggle.active {
    background: var(--menu-item-background-active);
  }
}

.dropdown-item {
  background: var(--sub-item-background);

  //Hover for sub item
  &:hover {
    background: var(--sub-item-background--hover);
  }
}

.toggle {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in;
}

.dropdown-toggle.active::after {
  border-bottom: 0.3em solid;
  border-top: 0;
}
</style>
