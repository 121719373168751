export default {
    data() {
      return {
        props: {
          'name':'BlogDatesListComponent',
          'width': '920px',
          'height': '50px',
          'zIndex': 'auto',
          'border': '0 none #000000',
          'boxShadow': '0 0 0 0 black initial',
          'borderRadius': '0 0 0 0',
          'paddingTop': '0px',
          'paddingLeft': '15px',
          'paddingRight': '15px',
          'paddingBottom': '15px',
          'maxWidth' : '500px',

          themeBlog: 'feed',
          'showDesktop': true, 'showMobile': true,
          'animation': {
            'in': {
              'action': 'time',   //time/scroll/click/hover
              'time': 0,       // sec
              'scroll': 0,     // px
              'effect': 'none',
              'speed': 'slow',
              'infinite': false,
            },
            'out': {
              'action': 'time',   //time/scroll/click
              'time': 0,       // sec
              'scroll': 0,     // px
              'effect': 'none',
              'delay': '1',
              'speed': '1',
              'infinite': false,
            },
          }
        },
        elements: [],
        post: {
          content: '',
          categories: [],
          author: {}
        },
        postSearch: '',
        page: 34,
        perPage: 8,
        paginator: {
          pages: [],
          actual_page: 1,
          last_page: 100,
          show: false,
        },
        internal_config: {
          'dates': [],
          'width': '50px',
          'height': '50px',
          lbltext: 'search',
          btnText: 'search',
          'zIndex': 'auto',
          'border': '0 none #000000',
          'boxShadow': '0 0 0 0 black initial',
          'borderRadius': '0 0 0 0',
          'paddingTop': '15px',
          'paddingLeft': '15px',
          'paddingRight': '15px',
          'paddingBottom': '15px',
          'colorDetails': '#5AD67D',
          'colorReadMore': '#5AD67D',
          'colorTitle': '#000',
          'colorSubTitle': '#ddd',
          'theme': 1,
          'dateFormat': 'YYYY-MM-DD',
          'textReadMore': 'Read More',
          'titleStyle': {
            'family': 'Lato, sans-serif',
            'size': '25px',
            'sizeMobile': '15px',
            'color': 'black',
            'align': 'left',
            'weight': '400',
            'style': 'initial',
            'stroke' : 'none'
          },
          'contentStyle': {
            'family': 'Lato, sans-serif',
            'size': '15px',
            'sizeMobile': '15px',
            'color': '#4F4F4F',
            'align': 'left',
            'weight': '400',
            'style': 'initial',
            'stroke' : 'none'
          },
          'cardBgColor': 'white',
          'animation': {
            'in': {
              'action': 'time',   //time/scroll/click/hover
              'time': 0,       // sec
              'scroll': 0,     // px
              'effect': 'none',
              'speed': 'slow',
              'infinite': false,
            },
            'out': {
              'action': 'time',   //time/scroll/click
              'time': 0,       // sec
              'scroll': 0,     // px
              'effect': 'none',
              'delay': '1',
              'speed': '1',
              'infinite': false,
            },
          }
        }
      }
    }
  }
