<template></template>
<script>
	export default {
		name: 'SeparatorPanel',
		data() {
			return {
				props: {}
			}
		},
		methods: {
			//#f461c1, #f23a32, #3461c1, #FFF
			wavesSeparator(first, second, third, fourth,custom, value1, value2, value3, value4) {

				let firstValue = value1 * 6;
				let secondValue = value2 * 19;
				let tValue = value3 * 5;
				let foValue = value4 * 9;

				if(!custom){
					firstValue = 28;
					secondValue = 150;
					tValue = 24;
					foValue = 44;
				}

				return '<svg class="waves-separator"\n' +
					'           xmlns="http://www.w3.org/2000/svg"\n' +
					'           xmlns:xlink="http://www.w3.org/1999/xlink"\n' +
					'           viewBox="0 ' + tValue + ' ' + secondValue + ' ' + firstValue + ' "\n' +
					'           preserveAspectRatio="none">\n' +
					'        <defs>\n' +
					'          <path id="gentle-wave"\n' +
					'                d="M-160 ' + foValue + 'c30 0\n' +
					'        58-18 88-18s\n' +
					'        58 18 88 18\n' +
					'        58-18 88-18\n' +
					'        58 18 88 18\n' +
					'        v44h-352z"/>\n' +
					'        </defs>\n' +
					'        <g class="parallax1">\n' +
					'          <use xlink:href="#gentle-wave" x="50" y="3" fill="' + first + '"/>\n' +
					'        </g>\n' +
					'        <g class="parallax2">\n' +
					'          <use xlink:href="#gentle-wave" x="50" y="0" fill="' + second + '"/>\n' +
					'        </g>\n' +
					'        <g class="parallax3">\n' +
					'          <use xlink:href="#gentle-wave" x="50" y="9" fill="' + third + '"/>\n' +
					'        </g>\n' +
					'        <g class="parallax4">\n' +
					'          <use xlink:href="#gentle-wave" x="50" y="6" fill="' + fourth + '"/>\n' +
					'        </g>\n' +
					'      </svg>'

			},
			dripColors(first, second, third, classAdd,custom, value1, value2, value3) {

				let firstValue = ~~value1 + 100;
				let secondValue = value2 * 160;
				if (classAdd === 'down' || !custom) {
					firstValue = 100;
					secondValue = 1600;
				}

				return '<svg id="" preserveAspectRatio="xMidYMax meet" class="svg-separator sep1 ' + classAdd + '" viewBox="0 0 ' + secondValue + ' ' + firstValue + '" style="" data-height="100">\n' +
					'<path class="" style="opacity: 1;fill: ' + first + '" d="M1040,56c0.5,0,1,0,1.6,0c-16.6-8.9-36.4-15.7-66.4-15.7c-56,0-76.8,23.7-106.9,41C881.1,89.3,895.6,96,920,96\n' +
					'C979.5,96,980,56,1040,56z"></path>\n' +
					'<path class="" style="opacity: 1;fill: ' + first + '" d="M1699.8,96l0,10H1946l-0.3-6.9c0,0,0,0-88,0s-88.6-58.8-176.5-58.8c-51.4,0-73,20.1-99.6,36.8\n' +
					'c14.5,9.6,29.6,18.9,58.4,18.9C1699.8,96,1699.8,96,1699.8,96z"></path>\n' +
					'<path class="" style="opacity: 1;fill: ' + first + '" d="M1400,96c19.5,0,32.7-4.3,43.7-10c-35.2-17.3-54.1-45.7-115.5-45.7c-32.3,0-52.8,7.9-70.2,17.8\n' +
					'c6.4-1.3,13.6-2.1,22-2.1C1340.1,56,1340.3,96,1400,96z"></path>\n' +
					'<path class="" style="opacity: 1;fill: ' + first + '" d="M320,56c6.6,0,12.4,0.5,17.7,1.3c-17-9.6-37.3-17-68.5-17c-60.4,0-79.5,27.8-114,45.2\n' +
					'c11.2,6,24.6,10.5,44.8,10.5C260,96,259.9,56,320,56z"></path>\n' +
					'<path class="" style="opacity: 1;fill: ' + first + '" d="M680,96c23.7,0,38.1-6.3,50.5-13.9C699.6,64.8,679,40.3,622.2,40.3c-30,0-49.8,6.8-66.3,15.8\n' +
					'c1.3,0,2.7-0.1,4.1-0.1C619.7,56,620.2,96,680,96z"></path>\n' +
					'<path class="" style="opacity: 1;fill: ' + first + '" d="M-40,95.6c28.3,0,43.3-8.7,57.4-18C-9.6,60.8-31,40.2-83.2,40.2c-14.3,0-26.3,1.6-36.8,4.2V106h60V96L-40,95.6\n' +
					'z"></path>\n' +
					'<path class="" style="opacity: 1;fill: ' + second + '" d="M504,73.4c-2.6-0.8-5.7-1.4-9.6-1.4c-19.4,0-19.6,13-39,13c-19.4,0-19.5-13-39-13c-14,0-18,6.7-26.3,10.4\n' +
					'C402.4,89.9,416.7,96,440,96C472.5,96,487.5,84.2,504,73.4z"></path>\n' +
					'<path class="" style="opacity: 1;fill: ' + second + '" d="M1205.4,85c-0.2,0-0.4,0-0.6,0c-19.5,0-19.5-13-39-13s-19.4,12.9-39,12.9c0,0-5.9,0-12.3,0.1\n' +
					'c11.4,6.3,24.9,11,45.5,11C1180.6,96,1194.1,91.2,1205.4,85z"></path>\n' +
					'<path class="" style="opacity: 1;fill: ' + second + '" d="M1447.4,83.9c-2.4,0.7-5.2,1.1-8.6,1.1c-19.3,0-19.6-13-39-13s-19.6,13-39,13c-3,0-5.5-0.3-7.7-0.8\n' +
					'c11.6,6.6,25.4,11.8,46.9,11.8C1421.8,96,1435.7,90.7,1447.4,83.9z"></path>\n' +
					'<path class="" style="opacity: 1;fill: ' + second + '" d="M985.8,72c-17.6,0.8-18.3,13-37,13c-19.4,0-19.5-13-39-13c-18.2,0-19.6,11.4-35.5,12.8\n' +
					'c11.4,6.3,25,11.2,45.7,11.2C953.7,96,968.5,83.2,985.8,72z"></path>\n' +
					'<path class="" style="opacity: 1;fill: ' + second + '" d="M743.8,73.5c-10.3,3.4-13.6,11.5-29,11.5c-19.4,0-19.5-13-39-13s-19.5,13-39,13c-0.9,0-1.7,0-2.5-0.1\n' +
					'c11.4,6.3,25,11.1,45.7,11.1C712.4,96,727.3,84.2,743.8,73.5z"></path>\n' +
					'<path class="" style="opacity: 1;fill: ' + second + '" d="M265.5,72.3c-1.5-0.2-3.2-0.3-5.1-0.3c-19.4,0-19.6,13-39,13c-19.4,0-19.6-13-39-13\n' +
					'c-15.9,0-18.9,8.7-30.1,11.9C164.1,90.6,178,96,200,96C233.7,96,248.4,83.4,265.5,72.3z"></path>\n' +
					'<path class="" style="opacity: 1;fill: ' + second + '" d="M1692.3,96V85c0,0,0,0-19.5,0s-19.6-13-39-13s-19.6,13-39,13c-0.1,0-0.2,0-0.4,0c11.4,6.2,24.9,11,45.6,11\n' +
					'C1669.9,96,1684.8,96,1692.3,96z"></path>\n' +
					'<path class="" style="opacity: 1;fill: ' + second + '" d="M25.5,72C6,72,6.1,84.9-13.5,84.9L-20,85v8.9C0.7,90.1,12.6,80.6,25.9,72C25.8,72,25.7,72,25.5,72z"></path>\n' +
					'<path class="" style="fill: ' + third + '" d="M-40,95.6C20.3,95.6,20.1,56,80,56s60,40,120,40s59.9-40,120-40s60.3,40,120,40s60.3-40,120-40\n' +
					's60.2,40,120,40s60.1-40,120-40s60.5,40,120,40s60-40,120-40s60.4,40,120,40s59.9-40,120-40s60.3,40,120,40s60.2-40,120-40\n' +
					's60.2,40,120,40s59.8,0,59.8,0l0.2,143H-60V96L-40,95.6z"></path>\n' +
					'</svg>'
			},
			midArrow(first, second, third,custom, value1, value2, value3) {

				let firstValue = value1 * 40;
				let secondValue = value2 * 200;
				let tValue = value3 * 37;

				if (!custom) {
					firstValue = 200;
					secondValue = 1600;
					tValue  = 186;
				}

				return '<svg id="" preserveAspectRatio="xMidYMax meet" class="svg-separator sep2" viewBox="0 0 ' + secondValue + ' ' + firstValue + '" style="display: block;" data-height="200">\n' +
					'<polygon class="" style="fill: ' + first + '" points="-4,0 ' + secondValue + ',' + firstValue + ' ' + secondValue + ',' + firstValue + ' -4,' + firstValue + ' \t"></polygon>\n' +
					'<polygon class="" style="opacity: 1;fill: ' + second + '" points="' + secondValue + ',' + firstValue + ' ' + secondValue + ',' + tValue + ' -4,0 -4,0 \t"></polygon>\n' +
					'<polygon class="" style="opacity: 1;fill: ' + third + '" points="' + secondValue + ',' + tValue + ' ' + secondValue + ',' + (tValue - 15) + ' -4,0 -4,0 \t"></polygon></svg>'
			},
			wavesFixed(first, second, third,custom, value1, value2, value3) {
				return '<svg id="" preserveAspectRatio="xMidYMax meet" class="svg-separator sep3" viewBox="0 0 1600 100" style="display: block;" data-height="100">\n' +
					'<path class="" style="opacity: 1;fill: ' + first + '" d="M-40,71.627C20.307,71.627,20.058,32,80,32s60.003,40,120,40s59.948-40,120-40s60.313,40,120,40s60.258-40,120-40s60.202,40,120,40s60.147-40,120-40s60.513,40,120,40s60.036-40,120-40c59.964,0,60.402,40,120,40s59.925-40,120-40s60.291,40,120,40s60.235-40,120-40s60.18,40,120,40s59.82,0,59.82,0l0.18,26H-60V72L-40,71.627z"></path>\n' +
					'<path class="" style="opacity: 1;fill: ' + second + '" d="M-40,83.627C20.307,83.627,20.058,44,80,44s60.003,40,120,40s59.948-40,120-40s60.313,40,120,40s60.258-40,120-40s60.202,40,120,40s60.147-40,120-40s60.513,40,120,40s60.036-40,120-40c59.964,0,60.402,40,120,40s59.925-40,120-40s60.291,40,120,40s60.235-40,120-40s60.18,40,120,40s59.82,0,59.82,0l0.18,14H-60V84L-40,83.627z"></path>\n' +
					'<path class="" style="fill: ' + third + '" d="M-40,95.627C20.307,95.627,20.058,56,80,56s60.003,40,120,40s59.948-40,120-40s60.313,40,120,40s60.258-40,120-40s60.202,40,120,40s60.147-40,120-40s60.513,40,120,40s60.036-40,120-40c59.964,0,60.402,40,120,40s59.925-40,120-40s60.291,40,120,40s60.235-40,120-40s60.18,40,120,40s59.82,0,59.82,0l0.18,138H-60V96L-40,95.627z"></path></svg>'
			},
			polygonCraqued(first, second, third,custom, value1, value2, value3) {

				let firstValue = value1 * 40;
				let secondValue = value2 * 200;

				if (!custom) {
					firstValue = 200;
					secondValue = 1600;
				}

				return '<svg id="" preserveAspectRatio="xMidYMax meet" class="svg-separator sep5" viewBox="0 0 ' + secondValue + ' ' + firstValue + '" style="display: block;" data-height="200">\n' +
					'<polygon class="" style="fill: ' + first + '" points="1488,134 1304,100 1068,152 909.935,92.044 672,198 364,142 242,32 -4,95 -4,204 2004,204 1604,0 "></polygon> \n' +
					'<polygon class="" style="opacity: 1;fill: ' + second + '" points="672,198 364,142 242,32 -4,95 -4,82.333 242,32 374,136 "></polygon> \n' +
					'<polygon class="" style="opacity: 1;fill: ' + second + '" points="894,86 672,198 909.935,92.044 "></polygon> \n' +
					'<polygon class="" style="opacity: 1;fill: ' + third + '" points="1068,152 1302,86 1486,126 1604,0 1488,134 1304,100 "></polygon></svg>'
			},
			polygonUp(first, second, third,custom, value1, value2, value3) {

				let firstValue = value1 * 20;
				let secondValue = value2 * 180;

				if (!custom) {
					firstValue = 200;
					secondValue = 1600;
				}

				return '<svg id="" preserveAspectRatio="xMidYMax meet" class="svg-separator sep6" viewBox="0 0 ' + secondValue + ' ' + firstValue + '" style="display: block;" data-height="200">\n' +
					'<polygon class="" style="fill: ' + first + '" points="-4,188.586 174,76 292,124 402,60 536,108 752,8 882,66 990,32 1210,116 1380,64 1536,150 1604,115.09 1804,204 -4,204 "></polygon> \n' +
					'<polygon class="" style="opacity: 1;fill: ' + second + ';" points="174,76 174,78 -4,198 -4,188.586 "></polygon> \n' +
					'<polygon class="" style="opacity: 1;fill: ' + second + ';" points="292,124 302,138 402,64 402,60 "></polygon> \n' +
					'<polygon class="" style="opacity: 1;fill: ' + second + ';" points="536,108 544,120 752,12 752,8 "></polygon> \n' +
					'<polygon class="" style="opacity: 1;fill: ' + second + ';" points="882,66 890,78 990,36 990,32 "></polygon> \n' +
					'<polygon class="" style="opacity: 1;fill: ' + second + ';" points="1210,116 1222,130 1380,68 1380,64 "></polygon> \n' +
					'<polygon class="" style="opacity: 1;fill: ' + second + ';" points="1536,150 1546,164 1604,124 1604,115.09 "></polygon> \n' +
					'<polygon class="" style="opacity: 1;fill: ' + third + ';" points="174,76 292,124 282,140 174,78 "></polygon> \n' +
					'<polygon class="" style="opacity: 1;fill: ' + third + ';" points="402,60 536,108 528,120 402,64 "></polygon> \n' +
					'<polygon class="" style="opacity: 1;fill: ' + third + ';" points="752,8 882,66 874,80 752,12 "></polygon> \n' +
					'<polygon class="" style="opacity: 1;fill: ' + third + ';" points="990,32 990,36 1192,130 1210,116 "></polygon> \n' +
					'<polygon class="" style="opacity: 1;fill: ' + third + ';" points="1380,64 1536,150 1526,168 1380,68 "></polygon></svg>'
			},
			bannerLateral(first, second, third,custom, value1, value2, value3) {

				let firstValue = value1 * 20;
				let secondValue = value2 * 180;

				if (!custom) {
					firstValue = 200;
					secondValue = 1600;
				}


				return '<svg id="" preserveAspectRatio="xMidYMax meet" class="svg-separator sep7" viewBox="0 0 ' + secondValue + ' ' + firstValue + '" style="display: block;" data-height="200">\n' +
					'<polygon class="" style="fill: ' + first + '" points="2011.912,205.954 -14.224,206 -9.912,2.046 1616.224,162.165 "></polygon> \n' +
					'<polygon class="" style="opacity: 1;fill: ' + second + ';" points="167.262,39.589 107.551,33.709 123.443,15.177 183.154,21.057 "></polygon> \n' +
					'<polygon class="" style="opacity: 1;fill: ' + third + ';" points="286.684,51.348 226.973,45.468 242.865,26.936 302.577,32.816 "></polygon> \n' +
					'<polygon class="" style="opacity: 1;fill: ' + third + ';" points="47.839,27.83 -11.872,21.95 4.021,3.418 63.732,9.298 "></polygon> \n' +
					'<polygon class="" style="opacity: 1;fill: ' + second + ';" points="406.107,63.107 346.395,57.227 362.288,38.695 421.999,44.575 "></polygon> \n' +
					'<polygon class="" style="opacity: 1;fill: ' + third + ';" points="525.529,74.866 465.818,68.986 481.71,50.454 541.422,56.334 "></polygon> \n' +
					'<polygon class="" style="opacity: 1;fill: ' + second + ';" points="644.952,86.625 585.24,80.745 601.133,62.213 660.844,68.093 "></polygon> \n' +
					'<polygon class="" style="opacity: 1;fill: ' + third + ';" points="764.374,98.384 704.663,92.504 720.555,73.973 780.267,79.852 "></polygon> \n' +
					'<polygon class="" style="opacity: 1;fill: ' + second + ';" points="883.797,110.143 824.085,104.263 839.978,85.732 899.689,91.611 "></polygon> \n' +
					'<polygon class="" style="opacity: 1;fill: ' + third + ';" points="1003.219,121.902 943.508,116.023 959.4,97.491 1019.111,103.37 "></polygon> \n' +
					'<polygon class="" style="opacity: 1;fill: ' + second + ';" points="1122.641,133.661 1062.93,127.782 1078.823,109.25 1138.534,115.129 "></polygon> \n' +
					'<polygon class="" style="opacity: 1;fill: ' + third + ';" points="1242.064,145.42 1182.353,139.541 1198.245,121.009 1257.956,126.888 "></polygon> \n' +
					'<polygon class="" style="opacity: 1;fill: ' + second + ';" points="1361.486,157.179 1301.775,151.3 1317.668,132.768 1377.379,138.647 "></polygon> \n' +
					'<polygon class="" style="opacity: 1;fill: ' + third + ';" points="1480.909,168.938 1421.198,163.059 1437.09,144.527 1496.801,150.406 "></polygon> \n' +
					'<polygon class="" style="opacity: 1;fill: ' + second + ';" points="1600.331,180.697 1540.62,174.818 1556.513,156.286 1616.224,162.165 "></polygon></svg>'
			},
			drops(first, second,custom, value1, value2) {
				return '<svg id="" preserveAspectRatio="xMidYMax meet" class="svg-separator sep9 bottom" viewBox="0 0 1600 200" style="display: block;" data-height="200">\n' +
					'<circle class="" style="opacity: .8;fill: ' + first + '" cx="8" cy="112" r="26"></circle> \n' +
					'<circle class="" style="opacity: .8;fill: ' + first + '" cx="8" cy="64" r="14"></circle> \n' +
					'<circle class="" style="opacity: .6;fill: ' + first + '" cx="8" cy="34" r="8"></circle> \n' +
					'<circle class="" style="opacity: .6;fill: ' + first + '" cx="8" cy="16" r="2"></circle> \n' +
					'<circle class="" style="opacity: .8;fill: ' + first + '" cx="96" cy="112" r="26"></circle> \n' +
					'<circle class="" style="opacity: .8;fill: ' + first + '" cx="96" cy="64" r="14"></circle> \n' +
					'<circle class="" style="opacity: .6;fill: ' + first + '" cx="96" cy="34" r="8"></circle> \n' +
					'<circle class="" style="opacity: .6;fill: ' + first + '" cx="96" cy="16" r="2"></circle> \n' +
					'<circle class="" style="opacity: .8;fill: ' + first + '" cx="184" cy="112" r="26"></circle> \n' +
					'<circle class="" style="opacity: .8;fill: ' + first + '" cx="184" cy="64" r="14"></circle> \n' +
					'<circle class="" style="opacity: .6;fill: ' + first + '" cx="184" cy="34" r="8"></circle> \n' +
					'<circle class="" style="opacity: .6;fill: ' + first + '" cx="184" cy="16" r="2"></circle> \n' +
					'<circle class="" style="opacity: .8;fill: ' + first + '" cx="272" cy="112" r="26"></circle> \n' +
					'<circle class="" style="opacity: .8;fill: ' + first + '" cx="272" cy="64" r="14"></circle> \n' +
					'<circle class="" style="opacity: .6;fill: ' + first + '" cx="272" cy="34" r="8"></circle> \n' +
					'<circle class="" style="opacity: .6;fill: ' + first + '" cx="272" cy="16" r="2"></circle> \n' +
					'<circle class="" style="opacity: .8;fill: ' + first + '" cx="360" cy="112" r="26"></circle> \n' +
					'<circle class="" style="opacity: .8;fill: ' + first + '" cx="360" cy="64" r="14"></circle> \n' +
					'<circle class="" style="opacity: .6;fill: ' + first + '" cx="360" cy="34" r="8"></circle> \n' +
					'<circle class="" style="opacity: .6;fill: ' + first + '" cx="360" cy="16" r="2"></circle> \n' +
					'<circle class="" style="opacity: .8;fill: ' + first + '" cx="448" cy="112" r="26"></circle> \n' +
					'<circle class="" style="opacity: .8;fill: ' + first + '" cx="448" cy="64" r="14"></circle> \n' +
					'<circle class="" style="opacity: .6;fill: ' + first + '" cx="448" cy="34" r="8"></circle> \n' +
					'<circle class="" style="opacity: .6;fill: ' + first + '" cx="448" cy="16" r="2"></circle> \n' +
					'<circle class="" style="opacity: .8;fill: ' + first + '" cx="536" cy="112" r="26"></circle> \n' +
					'<circle class="" style="opacity: .8;fill: ' + first + '" cx="536" cy="64" r="14"></circle> \n' +
					'<circle class="" style="opacity: .6;fill: ' + first + '" cx="536" cy="34" r="8"></circle> \n' +
					'<circle class="" style="opacity: .6;fill: ' + first + '" cx="536" cy="16" r="2"></circle> \n' +
					'<circle class="" style="opacity: .8;fill: ' + first + '" cx="624" cy="112" r="26"></circle> \n' +
					'<circle class="" style="opacity: .8;fill: ' + first + '" cx="624" cy="64" r="14"></circle> \n' +
					'<circle class="" style="opacity: .6;fill: ' + first + '" cx="624" cy="34" r="8"></circle> \n' +
					'<circle class="" style="opacity: .6;fill: ' + first + '" cx="624" cy="16" r="2"></circle> \n' +
					'<circle class="" style="opacity: .8;fill: ' + first + '" cx="712" cy="112" r="26"></circle> \n' +
					'<circle class="" style="opacity: .8;fill: ' + first + '" cx="712" cy="64" r="14"></circle> \n' +
					'<circle class="" style="opacity: .6;fill: ' + first + '" cx="712" cy="34" r="8"></circle> \n' +
					'<circle class="" style="opacity: .6;fill: ' + first + '" cx="712" cy="16" r="2"></circle> \n' +
					'<circle class="" style="opacity: .8;fill: ' + first + '" cx="800" cy="112" r="26"></circle> \n' +
					'<circle class="" style="opacity: .8;fill: ' + first + '" cx="800" cy="64" r="14"></circle> \n' +
					'<circle class="" style="opacity: .6;fill: ' + first + '" cx="800" cy="34" r="8"></circle> \n' +
					'<circle class="" style="opacity: .6;fill: ' + first + '" cx="800" cy="16" r="2"></circle> \n' +
					'<circle class="" style="opacity: .8;fill: ' + first + '" cx="888" cy="112" r="26"></circle> \n' +
					'<circle class="" style="opacity: .8;fill: ' + first + '" cx="888" cy="64" r="14"></circle> \n' +
					'<circle class="" style="opacity: .6;fill: ' + first + '" cx="888" cy="34" r="8"></circle> \n' +
					'<circle class="" style="opacity: .6;fill: ' + first + '" cx="888" cy="16" r="2"></circle> \n' +
					'<circle class="" style="opacity: .8;fill: ' + first + '" cx="976" cy="112" r="26"></circle> \n' +
					'<circle class="" style="opacity: .8;fill: ' + first + '" cx="976" cy="64" r="14"></circle> \n' +
					'<circle class="" style="opacity: .6;fill: ' + first + '" cx="976" cy="34" r="8"></circle> \n' +
					'<circle class="" style="opacity: .6;fill: ' + first + '" cx="976" cy="16" r="2"></circle> \n' +
					'<circle class="" style="opacity: .8;fill: ' + first + '" cx="1064" cy="112" r="26"></circle> \n' +
					'<circle class="" style="opacity: .8;fill: ' + first + '" cx="1064" cy="64" r="14"></circle> \n' +
					'<circle class="" style="opacity: .6;fill: ' + first + '" cx="1064" cy="34" r="8"></circle> \n' +
					'<circle class="" style="opacity: .6;fill: ' + first + '" cx="1064" cy="16" r="2"></circle> \n' +
					'<circle class="" style="opacity: .8;fill: ' + first + '" cx="1152" cy="112" r="26"></circle> \n' +
					'<circle class="" style="opacity: .8;fill: ' + first + '" cx="1152" cy="64" r="14"></circle> \n' +
					'<circle class="" style="opacity: .6;fill: ' + first + '" cx="1152" cy="34" r="8"></circle> \n' +
					'<circle class="" style="opacity: .6;fill: ' + first + '" cx="1152" cy="16" r="2"></circle> \n' +
					'<circle class="" style="opacity: .8;fill: ' + first + '" cx="1240" cy="112" r="26"></circle> \n' +
					'<circle class="" style="opacity: .8;fill: ' + first + '" cx="1240" cy="64" r="14"></circle> \n' +
					'<circle class="" style="opacity: .6;fill: ' + first + '" cx="1240" cy="34" r="8"></circle> \n' +
					'<circle class="" style="opacity: .6;fill: ' + first + '" cx="1240" cy="16" r="2"></circle> \n' +
					'<circle class="" style="opacity: .8;fill: ' + first + '" cx="1328" cy="112" r="26"></circle> \n' +
					'<circle class="" style="opacity: .8;fill: ' + first + '" cx="1328" cy="64" r="14"></circle> \n' +
					'<circle class="" style="opacity: .6;fill: ' + first + '" cx="1328" cy="34" r="8"></circle> \n' +
					'<circle class="" style="opacity: .6;fill: ' + first + '" cx="1328" cy="16" r="2"></circle> \n' +
					'<circle class="" style="opacity: .8;fill: ' + first + '" cx="1416" cy="112" r="26"></circle> \n' +
					'<circle class="" style="opacity: .8;fill: ' + first + '" cx="1416" cy="64" r="14"></circle> \n' +
					'<circle class="" style="opacity: .6;fill: ' + first + '" cx="1416" cy="34" r="8"></circle> \n' +
					'<circle class="" style="opacity: .6;fill: ' + first + '" cx="1416" cy="16" r="2"></circle> \n' +
					'<circle class="" style="opacity: .8;fill: ' + first + '" cx="1504" cy="112" r="26"></circle> \n' +
					'<circle class="" style="opacity: .8;fill: ' + first + '" cx="1504" cy="64" r="14"></circle> \n' +
					'<circle class="" style="opacity: .6;fill: ' + first + '" cx="1504" cy="34" r="8"></circle> \n' +
					'<circle class="" style="opacity: .6;fill: ' + first + '" cx="1504" cy="16" r="2"></circle> \n' +
					'<circle class="" style="opacity: .8;fill: ' + first + '" cx="1592" cy="112" r="26"></circle> \n' +
					'<circle class="" style="opacity: .8;fill: ' + first + '" cx="1592" cy="64" r="14"></circle> \n' +
					'<circle class="" style="opacity: .6;fill: ' + first + '" cx="1592" cy="34" r="8"></circle> \n' +
					'<circle class="" style="opacity: .6;fill: ' + first + '" cx="1592" cy="16" r="2"></circle> \n' +
					'<path class="" style="fill: ' + second + '" d="M1638,192c0-25.405-20.595-46-46-46c-20.729,0-38.25,13.713-44,32.561 c-5.75-18.848-23.271-32.561-44-32.561s-38.25,13.713-44,32.561c-5.75-18.848-23.271-32.561-44-32.561s-38.25,13.713-44,32.561 c-5.75-18.848-23.271-32.561-44-32.561s-38.25,13.713-44,32.561c-5.75-18.848-23.271-32.561-44-32.561s-38.25,13.713-44,32.561 c-5.75-18.848-23.271-32.561-44-32.561s-38.25,13.713-44,32.561c-5.75-18.848-23.271-32.561-44-32.561s-38.25,13.713-44,32.561 C1014.25,159.713,996.729,146,976,146s-38.25,13.713-44,32.561C926.25,159.713,908.729,146,888,146s-38.25,13.713-44,32.561 C838.25,159.713,820.729,146,800,146s-38.25,13.713-44,32.561C750.25,159.713,732.729,146,712,146s-38.25,13.713-44,32.561 C662.25,159.713,644.729,146,624,146s-38.25,13.713-44,32.561C574.25,159.713,556.729,146,536,146s-38.25,13.713-44,32.561 C486.25,159.713,468.729,146,448,146s-38.25,13.713-44,32.561C398.25,159.713,380.729,146,360,146s-38.25,13.713-44,32.561 C310.25,159.713,292.729,146,272,146s-38.25,13.713-44,32.561C222.25,159.713,204.729,146,184,146s-38.25,13.713-44,32.561 C134.25,159.713,116.729,146,96,146s-38.25,13.713-44,32.561C46.25,159.713,28.729,146,8,146c-25.405,0-46,20.595-46,46 c0,24.056,18.469,43.787,42,45.816V238h1596v-0.708C1621.589,233.504,1638,214.675,1638,192z"></path></svg>';
			},
			wavesLateral(first, second, third,custom, value1, value2, value3) {

				let firstValue = value2 * 20;
				let secondValue = value1 * 180;

				if (!custom) {
					firstValue = 200;
					secondValue = 1600;
				}

				return '<svg id="" preserveAspectRatio="xMidYMax meet" class="svg-separator sep10" viewBox="0 0 ' + secondValue + ' ' + firstValue + '" style="display: block;" data-height="200">\n' +
					'<path class="" style="opacity: 1;fill: ' + first + '" d="M-16,129.803C28.268,129.803,43.874,86,74.839,86 c26.605,0,15.874,35.884-0.894,27.723c8.831,0,8.943-12.52,0.894-12.52c-12.967,0-9.167,38.455,26.829,38.455s1.409,0,1.409,0 v16.097H-16V129.803z"></path> \n' +
					'<path class="" style="opacity: 1;fill: ' + second + ';" d="M-114,123.448C-17.538,133.448,16.468,38,83.943,38 c57.974,0,34.59,78.192-1.949,60.41c19.244,0,19.487-27.282,1.949-27.282c-28.256,0-19.974,83.795,58.462,83.795s3.071,0,3.071,0 V180H-114V123.448z"></path> \n' +
					'<path class="" style="opacity: 1;fill: ' + second + ';" d="M158,172.749C238.596,172.749,267.01,93,323.386,93 c48.439,0,28.901,65.332-1.628,50.474c16.079,0,16.282-22.795,1.628-22.795c-23.609,0-16.689,70.013,48.846,70.013s2.566,0,2.566,0 L158,192V172.749z"></path> \n' +
					'<path class="" style="opacity: 1;fill: ' + first + '" d="M156,160.626c53.515,0,72.381-52.953,109.815-52.953 c32.163,0,19.19,43.38-1.081,33.514c10.676,0,10.811-15.136,1.081-15.136c-15.676,0-11.081,46.488,32.433,46.488s1.704,0,1.704,0 V192H156V160.626z"></path> \n' +
					'<path class="" style="opacity: 1;fill: ' + first + '" d="M-108.04,114.107c68.046,0,92.035-67.331,139.634-67.331 c40.897,0,24.4,55.159-1.375,42.615c13.575,0,13.747-19.245,1.375-19.245c-19.933,0-14.09,59.111,41.24,59.111s2.166,0,2.166,0V154 h-183.04V114.107z"></path> \n' +
					'<path class="" style="fill: ' + third + '" d="M-4,142.333C121.654,142.333,165.952,18,253.846,18 c75.519,0,45.058,101.856-2.538,78.692c25.067,0,25.385-35.538,2.538-35.538c-36.808,0-26.019,109.154,76.154,109.154 s1276,0,1876,0V216H-4V142.333z"></path></svg>'
			},
			zigzag(first, second, third,custom, value1, value2, value3) {

				return '<svg id="" preserveAspectRatio="xMidYMax meet" class="svg-separator sep11" viewBox="0 0 1600 100" style="display: block;" data-height="100">\n' +
					'<polygon class="" style="fill: ' + first + '" points="-40,63.667 19.833,3.833 80,64 140,4 200,64 260,4 320,64 380,4 440,64 500,4 560,64 620,4 680,64 740,4 800,64 860,4 920,64 980,4 1040,64 1100,4 1160,64 1220,4 1280.333,64.333 1340.333,4.333 1400,64 1460,4 1520,64 1578,6 1636,64 1636,104 -40,104 "></polygon> \n' +
					'<polygon class="" style="opacity: 1;fill: ' + second + ';" points="-40,86 20,26 80,86 140,26 200,76 260,4 200,64 140,4 80,64 19.833,3.833 -40,63.667 "></polygon> \n' +
					'<polygon class="" style="opacity: 1;fill: ' + third + ';" points="1159,69 1220,8 1281,73 1340,14 1399,73 1460,12 1521,73 1578,16 1634,72 1636,73.333 1636,64 1578,6 1520,64 1460,4 1400,64 1340.333,4.333 1280.333,64.333 1220,4 1160,64 1100,4 1040,64 1100,10 "></polygon></svg>'
			},
			bigArrow(first, second, third,custom, value1, value2, value3) {

				let firstValue = value2 * 20;
				let secondValue = value1 * 180;

				let valueBanner = 1604;
				if(parseInt(secondValue)> 1600){
					valueBanner = 2004;
				}

				if (!custom) {
					firstValue = 200;
					secondValue = 1600;
					valueBanner = 1604;
				}


				return '<svg id="" preserveAspectRatio="xMidYMax meet" class="svg-separator sep12" viewBox="0 0 ' + secondValue + ' ' + firstValue + '" style="display: block;" data-height="200">\n' +
					'<polygon class="" style="fill: ' + first + '" points="-4,24 800,198 ' + valueBanner + ',24 ' + valueBanner + ',204 -4,204 "></polygon> \n' +
					'<polygon class="" style="opacity: 1;fill: ' + second + ';" points="-4,0 800,198 ' + valueBanner + ',0 ' + valueBanner + ',11.833 800,198 -4,12 "></polygon> \n' +
					'<polygon class="" style="opacity: 1;fill: ' + third + ';" points="-4,12 -4,24 800,198 ' + valueBanner + ',24 ' + valueBanner + ',11.833 800,198 "></polygon></svg>';
			},
			cloudsEffect(first,custom, value1) {

				let firstValue = value1 * 20;

				if (!custom) {
					firstValue = 100;
				}

				return '<svg id="clouds" class="svg-separator" xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="' + firstValue + '" viewBox="0 0 100 ' + firstValue + '" preserveAspectRatio="none">\n' +
					'    <path style="fill: ' + first + '" ' +
					'      d="M-5 ' + firstValue + ' Q 0 20 5 ' + firstValue + ' Z\n' +
					'         M0 ' + firstValue + ' Q 5 0 10 ' + firstValue + '\n' +
					'         M5 ' + firstValue + ' Q 10 30 15 ' + firstValue + '\n' +
					'         M10 ' + firstValue + ' Q 15 10 20 ' + firstValue + '\n' +
					'         M15 ' + firstValue + ' Q 20 30 25 ' + firstValue + '\n' +
					'         M20 ' + firstValue + ' Q 25 -10 30 ' + firstValue + '\n' +
					'         M25 ' + firstValue + ' Q 30 10 35 ' + firstValue + '\n' +
					'         M30 ' + firstValue + ' Q 35 30 40 ' + firstValue + '\n' +
					'         M35 ' + firstValue + ' Q 40 10 45 ' + firstValue + '\n' +
					'         M40 ' + firstValue + ' Q 45 50 50 ' + firstValue + '\n' +
					'         M45 ' + firstValue + ' Q 50 20 55 ' + firstValue + '\n' +
					'         M50 ' + firstValue + ' Q 55 40 60 ' + firstValue + '\n' +
					'         M55 ' + firstValue + ' Q 60 60 65 ' + firstValue + '\n' +
					'         M60 ' + firstValue + ' Q 65 50 70 ' + firstValue + '\n' +
					'         M65 ' + firstValue + ' Q 70 20 75 ' + firstValue + '\n' +
					'         M70 ' + firstValue + ' Q 75 45 80 ' + firstValue + '\n' +
					'         M75 ' + firstValue + ' Q 80 30 85 ' + firstValue + '\n' +
					'         M80 ' + firstValue + ' Q 85 20 90 ' + firstValue + '\n' +
					'         M85 ' + firstValue + ' Q 90 50 95 ' + firstValue + '\n' +
					'         M90 ' + firstValue + ' Q 95 25 100 ' + firstValue + '\n' +
					'         M95 ' + firstValue + ' Q 100 15 105 ' + firstValue + ' Z"></path>\n' +
					'</svg>'
			},
			curveEffect(first, second, third,custom, value1, value2, value3) {

				let firstValue = 15 + (~~value1 * 5);

				if (!custom) {
					firstValue = 40;
				}

        const viewBox = this.$isDeviceIOS() ? '0.2 0.2' : '0.1 0.1'

				return '<svg class="separator svg-separator-new" style="background-color: ' + first + ';" width="100%" height="80" viewBox="'+ viewBox +' 180 ' + firstValue + '" preserveAspectRatio="none">\n' +
					'            <g transform="translate(-18.298844,-77.973964)">\n' +
					'                <path style="fill:' + second + ';" d="M 31.615583,86.351641 H 192.16499 v 26.901969 c 0,0 -32.03411,-14.237983 -59.62682,-12.72484 -22.34188,1.2252 -54.779359,9.72634 -54.779359,9.72634 0,0 -22.029534,3.62882 -34.471238,-1.88988 -12.441702,-5.51871 -11.67199,-22.013589 -11.67199,-22.013589 z" />\n' +
					'                <path style="fill:' + third + '" d="M 18.441597,78.106256 H 198.58126 v 39.288614 c 0,0 -43.10672,-27.825245 -73.47599,-19.687823 -30.369264,8.137423 -46.832208,12.548653 -46.832208,12.548653 0,0 -32.775418,8.05972 -46.735258,0 C 17.577964,102.19598 18.441597,78.106256 18.441597,78.106256 Z" />\n' +
					'            </g>\n' +
					'        </svg>'
			},
			arrowReverse(first, second, third,custom, value1, value2, value3) {

				let firstValue = 19 + (~~value1 * 5);

				if (!custom) {
					firstValue = 44;
				}

				return '<svg class="separator svg-separator-new" style="background-color: ' + first + ';" xmlns="http://www.w3.org/2000/svg" width="100%" height="80" viewBox="0.4 0.2 200 ' + firstValue + '" preserveAspectRatio="none">\n' +
					'                <g class="separator" transform="translate(-9.2218046,-83.494585)">\n' +
					'                    <path style="fill:' + second + '" d="M 9.484815,89.716055 H 209.81018 V 126.90507 L 110.46368,93.705147 9.579391,127.39334 Z" />\n' +
					'                    <path style="fill:' + third + '" d="M 9.3544335,83.626877 H 209.68181 V 120.29057 L 110.46368,93.705147 9.4490103,120.77195 Z" />\n' +
					'                </g>\n' +
					'            </svg>'
			},
			pixels(first, second, third,custom, value1, value2, value3) {

				let firstValue = 10 + (~~value2 * 5);
				let secondValue = 138  + (~~value1 * 5);

				if (!custom) {
					firstValue = 178;
					secondValue = 30;
				}

				return '<svg class="separator svg-separator-new" style="background-color: ' + first + ';"  xmlns="http://www.w3.org/2000/svg" width="100%" height="80" viewBox="0.2 0 ' + secondValue + ' ' + firstValue + '" preserveAspectRatio="none">\n' +
					'            <g transform="translate(-14.514284,-115.36352)">\n' +
					'                <path style="fill:' + second + ';" d="m 14.74107,115.49581 h 178.02679 v 30.61607 h -29.10417 v -12.47321 h -10.58333 v -8.31548 h -13.98512 v 13.98512 h -20.41072 v -13.98512 h -8.31547 v 7.18155 h -7.18155 v 10.9613 H 85.422617 v -10.9613 H 68.791666 V 121.54343 H 51.40476 v 15.875 H 29.860117 v -9.82739 h -8.693452 v -7.55952 h -6.520089 v -4.53571 z" />\n' +
					'                <path style="fill:' + third + ';" d="M 14.741071,112.54762 H 192.76786 v 30.61607 H 163.66369 V 130.69048 H 153.08036 V 122.375 h -13.98512 v 13.98512 H 118.68452 V 122.375 h -8.31547 v 7.18155 h -7.18155 v 10.9613 H 85.422617 v -10.9613 H 68.791666 V 118.59524 H 51.40476 v 15.875 H 29.860118 v -9.82739 h -8.693452 v -7.55952 h -6.520089 v -4.53571 z"/>\n' +
					'            </g>\n' +
					'        </svg>'
			},
			bigRound(first, second,custom, value1, value2) {

				let firstValue = value2 * 20;
				let secondValue = ~~value1 * 5;

				if (!custom) {
					firstValue = 200;
					secondValue = 19;
				}

				return '<svg class="separator svg-separator-new" style="background-color: ' + first + ';"   xmlns="http://www.w3.org/2000/svg" width="100%" height="80" viewBox="0.1 0.3 ' + firstValue + ' ' + secondValue + '" preserveAspectRatio="none">\n' +
					'                <g transform="translate(-0.21755166,-100.15454)">\n' +
					'                    <path style="fill:' + second + ';" d="M 0.2688579,100.29477 H 200.98548 c 0,0 -99.37375,39.84098 -200.7166221,0 z" />\n' +
					'                </g>\n' +
					'            </svg>'
			},
			breaker(first, second, third,custom, value1, value2, value3) {

				let secondValue = ~~value1 * 5;

				if (!custom) {
					secondValue = 151;
				}

				return '<svg class="separator svg-separator-new" style="background-color: ' + first + ';"   xmlns="http://www.w3.org/2000/svg" width="100%" height="80" viewBox="0.2 0 ' + secondValue + ' 27" preserveAspectRatio="none">\n' +
					'            <g transform="translate(-18.766517,-159.24846)">\n' +
					'                <path style="fill:' + second + ';" d="m 18.898809,169.7732 h 11.150298 v -10.20536 l 10.016369,10.01637 10.016369,10.01637 v -9.82738 h 20.032738 v -10.20536 l 20.127232,20.12723 v -9.92187 h 19.938245 v -10.01637 l 19.93824,19.93824 v -9.92187 h 19.93824 v -10.20536 l 20.12723,20.12723 v 5.19718 H 18.898809 Z" />\n' +
					'              <path style="fill:' + third + ';" d="m 18.898809,171.88988 h 11.150298 v -10.20536 l 10.016368,10.01637 10.016368,10.01637 v -9.82738 h 20.032739 v -10.20536 l 20.127233,20.12723 v -9.92187 h 19.938245 v -10.01637 l 19.93824,19.93824 v -9.92187 h 19.93825 v -10.20536 l 20.12723,20.12723 v 5.19718 H 18.898809 Z"/>\n' +
					'            </g>\n' +
					'        </svg>'
			}
		}
	}
</script>
<style lang="scss">

    .svg-separator-new {
        transform: translateY(-80px);
        position: absolute;
    }

  .waves-separator {
    display: block;
    width: 100%;
    height: 60px;
    max-height: 60px;
    margin: 0;
    z-index: 5;
    bottom: 0;
    position: absolute;
    left: 0;
    float: left;

    .parallax1 > use {
      animation: move-forever1 10s linear infinite;

      &:nth-child(1) {
        animation-delay: -2s;
      }
    }

    .parallax2 > use {
      animation: move-forever2 8s linear infinite;

      &:nth-child(1) {
        animation-delay: -2s;
      }
    }

    .parallax3 > use {
      animation: move-forever3 6s linear infinite;

      &:nth-child(1) {
        animation-delay: -2s;
      }
    }

    .parallax4 > use {
      animation: move-forever4 4s linear infinite;

      &:nth-child(1) {
        animation-delay: -2s;
      }
    }

    @keyframes move-forever1 {
      0% {
        transform: translate(85px, 0%);
      }
      100% {
        transform: translate(-90px, 0%);
      }
    }

    @keyframes move-forever2 {
      0% {
        transform: translate(-90px, 0%);
      }
      100% {
        transform: translate(85px, 0%);
      }
    }

    @keyframes move-forever3 {
      0% {
        transform: translate(85px, 0%);
      }
      100% {
        transform: translate(-90px, 0%);
      }
    }

    @keyframes move-forever4 {
      0% {
        transform: translate(-90px, 0%);
      }
      100% {
        transform: translate(85px, 0%);
      }
    }
  }

  .svg-separator {
    width: 100%;
    margin: 0;
    bottom: 0;
    position: absolute;
    left: 0;
    float: left;

    display: block;
    background: 0 0;
    right: 0;
    z-index: 9;

    .svg-separator.bottom {
      top: auto;
      bottom: 0;
    }

    &.sep1.down {
      transform: rotate(180deg) translateY(-95%);
    }

    .sep2 {
      transform: translateY(-100%) translateY(2px) scale(1, 1);
      transform-origin: top;
    }

    &.sep3 {
      transform: rotate(180deg) translateY(-95%);
    }

    &.sep9 {
      transform: translateY(0%) translateY(-2px) scale(-1, -1);
      transform-origin: bottom;
    }

  }

  [data-device="tablet"],
  [data-device="mobile"] {

      .svg-separator-new {
          transform: translateY(-30px);
          position: absolute;
          max-height: 30px;
      }

      .svg-separator {
          max-height: 100%;

          &.sep3,
          &.sep1.down {
              transform: rotate(180deg) translateY(-95%);
          }
      }
  }

</style>
