<template>
  <div :id="this.id"
       class="sitebuilder-element-container"
       element="Image3DComponent"
       :data-hide-desktop="!this.internal_config.showDesktop"
       :data-hide-mobile="!this.internal_config.showMobile"
       :style="style()" @click="linkAction()">
    <div class="sitebuilder-element image3D-container"
         role="img"
         :aria-label="internal_config.alt"
         :aria-title="internal_config.title"
         :style="{'background-image': `url(${internal_config.src})`, opacity: opacity, 'border-radius': radius, border : border}"
         ref="image"
         @mousemove.self="mouseMove"
         @mouseout.self="mouseout"
         @mousedown.self="mousedown"
         @mouseup.self="mouseup"
    >
      <img :src="this.internal_config.src" class="sitebuilder-element">
    </div>
  </div>
</template>

<script>
import ImageData from './Model';
import MixinComponents from "@/js/mixins/MixinComponents";

export default {
	name: 'Image3DComponent',
	props: ['config', 'src', 'id', 'preview', 'device'],
  mixins: [MixinComponents],
	computed: {
		opacity() {
			return this.internal_config.opacity;
		},
	},
	data() {
		return ImageData.data();
	},
	methods: {
		animation() {
			return true;
		},
		linkAction: function () {
			this.$root.$emit('linkAction', this.internal_config.link);
		},
		build(config) {
			this.internal_config = config;
		},
		mouseover(e) {
			let el = this.$refs.image

			const height = el.clientHeight
			const width = el.clientWidth

			const xVal = e.layerX
			const yVal = e.layerY
			const yRotation = 20 * ((xVal - width / 2) / width)

			const xRotation = -20 * ((yVal - height / 2) / height)

			const string = 'perspective(500px) scale(1.1) rotateX(' + xRotation + 'deg) rotateY(' + yRotation + 'deg)'
			el.style.transform = string
		},
		mouseout() {
			let el = this.$refs.image
			el.style.transform = 'perspective(500px) scale(1) rotateX(0) rotateY(0)'
		},
		mousedown() {
			let el = this.$refs.image
			el.style.transform = 'perspective(500px) scale(0.9) rotateX(0) rotateY(0)'

		},
		mouseup() {
			let el = this.$refs.image
			el.style.transform = 'perspective(500px) scale(1.1) rotateX(0) rotateY(0)'
		},
		mouseMove(e) {
			let el = this.$refs.image

			const height = el.clientHeight
			const width = el.clientWidth
			var xVal = e.layerX
			var yVal = e.layerY

      if(xVal < 0){
				xVal = -1*xVal
        if(xVal > 200) {
					xVal = xVal /2
        }
      }

			if(yVal < 0 ){
				yVal = -1*yVal
				if(yVal > 200) {
					yVal = yVal /2
				}
      }

			const yRotation = 20 * ((xVal - width / 2) / width)
			const xRotation = -20 * ((yVal - height / 2) / height)
			const string = 'perspective(500px) scale(1.1) rotateX(' + xRotation + 'deg) rotateY(' + yRotation + 'deg)'

			el.style.transform = string
		},

		style() {
      var style = `width: ${this.internal_config.width};
                    max-width: ${this.internal_config.maxWidth};
                     height: auto;
                     z-index: ${this.internal_config.zIndex};
                     max-width: ${this.internal_config.maxWidth};
                     margin: 0 auto;
                     padding-top: ${this.internal_config.paddingTop};
                     padding-left: ${this.internal_config.paddingLeft};
                     padding-right: ${this.internal_config.paddingRight};
                     padding-bottom: ${this.internal_config.paddingBottom};
                     left:  ${this.internal_config.left};
                     top: ${this.internal_config.top}`;
      return style;
		},

	},
	mounted() {

		this.build(this.$props.config);

		this.internal_config = window.mergeRecursive(this.props, this.$props.config);
		this.internal_config.src = this.internal_config.src.replace('omb11.com', 'storage.builderall.com');

		this.$root.$emit('applyAnimation', this, 'sitebuilder-element-container');
	}
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
img {
	width: 100%;
	height: auto !important;
}

/* Styles for the image3D-container block */
.image3D-container {
	display: block;
	height: 100%;
	width: 100%;
	margin: 0 auto;
	transition: box-shadow 0.1s, transform 0.1s;

	background-size: 100%;
	background-repeat: no-repeat;
}

.image3D-container:hover {
	box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.6);
	cursor: pointer;
}

.image3D-container img {
  visibility: hidden;
}
</style>
