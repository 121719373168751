var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sitebuilder-element-container",style:(_vm.style()),attrs:{"id":this.idSimpleForm,"data-id":this.id,"element":"MultiStepFormComponent","data-hide-desktop":!this.internal_config.showDesktop,"data-hide-mobile":!this.internal_config.showMobile}},[_c('div',{style:(("transform: rotate(" + (this.$props.device === 'desktop' ? this.internal_config.rotation : 0) + ") scale(1)"))},[(_vm.checkGdpr())?_c('form',{ref:"form",class:_vm.horizontal ? 'form-simple horizontal-form': 'form-simple',attrs:{"target":_vm.internal_config.target,"method":"post","action":_vm.internal_config.action,"receiver":_vm.internal_config.receiver,"subject":_vm.internal_config.subject},on:{"submit":function($event){$event.preventDefault();return _vm.submit($event, _vm.internal_config)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.originUid),expression:"originUid"}],attrs:{"type":"hidden","name":"origin_uid"},domProps:{"value":(_vm.originUid)},on:{"input":function($event){if($event.target.composing){ return; }_vm.originUid=$event.target.value}}}),_c('ul',{ref:"progressbar",attrs:{"id":"progressbar"}}),_c('div',{ref:"container",staticClass:"inputs-area"}),(_vm.internal_config.recaptchaSiteKey)?_c('div',{staticClass:"recaptcha-container"},[_c('vue-recaptcha',{attrs:{"sitekey":_vm.internal_config.recaptchaSiteKey}})],1):_vm._e(),_c('div',{staticClass:"place-btn",style:({ margin: _vm.buttonMargin, display: 'flex', justifyContent: 'center', alignItems: 'center' })},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPreviousButton),expression:"showPreviousButton"}],staticClass:"bt-sb bt-sb-previous mx-1",style:({
            width : _vm.buttonPreviousWidth,
            'box-shadow': _vm.buttonPreviousBoxShadow,
            border : _vm.buttonPreviousBorder,
            'border-radius': _vm.buttonPreviousRadius,
            background: _vm.buttonPreviousBackground,
          })},[_c('div',{staticClass:"bt-text-area d-flex align-items-center flex-wrap overflow-hidden",style:({minHeight: _vm.buttonPreviousHeight})},[_c('a',{ref:"buttonPreviousClick",staticClass:"sb-text button-previous-sb-button",style:(_vm.textPreviousStyle()),on:{"click":function($event){return _vm.previous($event)}}},[_vm._v(_vm._s(_vm.internal_config.buttonPrevious.text))]),(_vm.internal_config.buttonPrevious.subText)?_c('small',{staticClass:"sb-sub-text",style:(_vm.subTextPreviousStyle()),on:{"click":function($event){return _vm.$refs.buttonPreviousClick.click()}}},[_vm._v(_vm._s(_vm.internal_config.buttonPrevious.subText))]):_vm._e()])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSubmitButton),expression:"showSubmitButton"}],staticClass:"bt-sb bt-sb-submit mx-1",style:({
            width : _vm.buttonWidth,
            'box-shadow': _vm.buttonBoxShadow,
            border : _vm.buttonBorder,
            'border-radius': _vm.buttonRadius,
            background: _vm.buttonBackground,
          })},[_c('div',{staticClass:"bt-text-area d-flex align-items-center flex-wrap overflow-hidden",style:({minHeight: _vm.buttonHeight})},[_c('a',{ref:"buttonClick",staticClass:"sb-text button-sb-button",style:(_vm.textStyle()),on:{"click":function($event){return _vm.submit($event, _vm.internal_config)}}},[_vm._v(_vm._s(_vm.internal_config.button.text))]),(_vm.internal_config.button.subText)?_c('small',{staticClass:"sb-sub-text",style:(_vm.subTextStyle()),on:{"click":function($event){return _vm.$refs.buttonClick.click()}}},[_vm._v(_vm._s(_vm.internal_config.button.subText))]):_vm._e()])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showNextButton),expression:"showNextButton"}],staticClass:"bt-sb bt-sb-next mx-1",style:({
            width : _vm.buttonNextWidth,
            'box-shadow': _vm.buttonNextBoxShadow,
            border : _vm.buttonNextBorder,
            'border-radius': _vm.buttonNextRadius,
            background: _vm.buttonNextBackground,
          })},[_c('div',{staticClass:"bt-text-area d-flex align-items-center flex-wrap overflow-hidden",style:({minHeight: _vm.buttonNextHeight})},[_c('a',{ref:"buttonNextClick",staticClass:"sb-text button-next-sb-button",style:(_vm.textNextStyle()),on:{"click":function($event){return _vm.next($event)}}},[_vm._v(_vm._s(_vm.internal_config.buttonNext.text))]),(_vm.internal_config.buttonNext.subText)?_c('small',{staticClass:"sb-sub-text",style:(_vm.subTextNextStyle()),on:{"click":function($event){return _vm.$refs.buttonNextClick.click()}}},[_vm._v(_vm._s(_vm.internal_config.buttonNext.subText))]):_vm._e()])])])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }