var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sitebuilder-element-container",style:(_vm.style()),attrs:{"id":_vm.id,"element":"blogNewsSliderComponent","data-hide-desktop":!_vm.internal_config.showDesktop,"data-hide-mobile":!_vm.internal_config.showMobile,"config-properties":JSON.stringify(_vm.internal_config)}},[_c('div',{ref:"blogNewsSlider",staticClass:"blog-news-slider",style:({
        opacity: _vm.opacity,
        borderRadius: _vm.radius,
        border : _vm.border,
        boxShadow: _vm.boxShadow,
        minHeight : _vm.minHeight
        })},_vm._l((_vm.news),function(blogNew,index){return _c('div',{key:index,class:("news-slider-" + index),style:({
          backgroundImage: ("url('" + (blogNew.thumbnail) + "')"),
          cursor: _vm.internal_config.optionNews.redirectUrl
          ? 'pointer'
          : ''
        }),on:{"click":function($event){return _vm.redirectPost(blogNew.url)}}},[(_vm.showTitle || _vm.showDescription)?_c('div',{staticClass:"news-info-slider",style:(_vm.infoContentStyle())},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTitle),expression:"showTitle"}],staticClass:"clean-text-css desktop-element",style:(_vm.titleTextStyle()),domProps:{"innerHTML":_vm._s(blogNew.title)}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTitle),expression:"showTitle"}],staticClass:"clean-text-css mobile-element news-info-slider-content",style:(_vm.titleTextStyle( false )),domProps:{"innerHTML":_vm._s(blogNew.title)}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDescription),expression:"showDescription"}],staticClass:"clean-text-css desktop-element",style:(_vm.subTextStyle()),domProps:{"innerHTML":_vm._s(blogNew.content)}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDescription),expression:"showDescription"}],staticClass:"clean-text-css mobile-element",style:(_vm.subTextStyle(false)),domProps:{"innerHTML":_vm._s(blogNew.content)}})]):_vm._e()])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showArrows),expression:"showArrows"}],staticClass:"mdi mdi-chevron-left arrow-left",style:(_vm.styleArrow()),on:{"click":_vm.prevSlider}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showArrows),expression:"showArrows"}],staticClass:"mdi mdi-chevron-right arrow-right",style:(_vm.styleArrow()),on:{"click":_vm.nextSlider}})])}
var staticRenderFns = []

export { render, staticRenderFns }