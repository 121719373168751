<template>
    <div class="sitebuilder-element-container is-receiver"
         element="BoxComponent"
         :data-hide-desktop="!this.internal_config.showDesktop"
         :data-hide-mobile="!this.internal_config.showMobile" :id="id"
         :data-tag="dataTag()"
         ref="elem"
         :style="style()">

        <div v-bind:style="{
       'border-radius': radius,
        background: backgroundColor,
        backgroundImage: backgroundImage,
        backgroundSize: backgroundSize,
        backgroundPosition: backgroundPosition,
        backgroundAttachment: backgroundAttachment,
        backgroundOrigin: backgroundOrigin,
        backgroundRepeat: backgroundRepeat,
        height : height,
        minHeight : minHeight,
        width : width,
        border : border,
        'box-shadow': boxShadow,
       }"
             ref="boxElem"
             class="sitebuilder-element-receiver">


			<!-- HOTMART INTEGRATION -->
			 <div v-if="typeof internal_config.hotmart !== 'undefined' && internal_config.hotmart.enabled" id="box_hotmart"></div>

			 	<!-- EDUZZ INTEGRATION -->
			 <div v-if="typeof internal_config.eduzz !== 'undefined' && internal_config.eduzz.enabled" id="elements"></div>
			<!-- HOTMART INTEGRATION -->


            <component v-for="(element,index) in elements" :is="element.name" :config="element.config" :key="index"
                       :device="device"
                       :is_popup="is_popup"
                       :id="element.id" :height_start="index" :preview="preview" :childs="element.childs"></component>
        </div>
    </div>
</template>
<script>

	import Model from './Model';

	export default {
		name: 'BoxComponent',
		components: {
			Model
		},
		props: ['config', 'childs', 'height_start', 'device', 'id', 'preview', 'is_popup'],
		data() {
			return Model.data()
		},
		computed: {
			width() {
				return this.internal_config.width;
			},
			height() {
				return this.internal_config.height;
			},
			minHeight() {
				return this.internal_config.minHeight;
			},
			border() {
				if (typeof this.internal_config.border !== 'undefined') {
					let splitedBorder = this.internal_config.border.split(' ');

					if (typeof splitedBorder[2] !== 'undefined' && this.$globalStoreHelpers.isValidHex(splitedBorder[2])) {
						splitedBorder[2] = this.$globalStoreHelpers.hexToRGBA(splitedBorder[2]);
						this.internal_config.border = splitedBorder.join(' ');
					}
				}
				return this.internal_config.border;
			},
			boxShadow() {
				return this.internal_config.boxShadow;
			},
			radius() {
				return this.internal_config.borderRadius;
			},
			backgroundColor() {
				if (this.$globalStoreHelpers.isValidHex(this.internal_config.backgroundColor)) {
					this.internal_config.backgroundColor = this.$globalStoreHelpers.hexToRGBA(this.internal_config.backgroundColor);
				}


				if(typeof this.internal_config.hotmart !== 'undefined' && this.internal_config.hotmart.enabled) {
					this.internal_config.backgroundColor = this.internal_config.hotmart.background
				}

				return this.internal_config.backgroundColor;
			},
			backgroundImage() {
				if (this.internal_config.backgroundImage !== 'none' && this.internal_config.backgroundImage ) {

					this.internal_config.backgroundImage = this.internal_config.backgroundImage.replace('omb11.com', 'storage.builderall.com');

					return 'url("' + this.internal_config.backgroundImage + '")';
				}
				return '';
			},
			backgroundPosition() {
				if (this.internal_config.backgroundImage !== 'none' && this.internal_config.backgroundImage) {
					return this.internal_config.backgroundPosition
				}
				return '';
			},
			backgroundAttachment() {
				if (this.internal_config.backgroundImage !== 'none' && this.internal_config.backgroundImage) {
					return this.internal_config.backgroundAttachment
				}
				return '';
			},
			backgroundOrigin() {
				if (this.internal_config.backgroundImage !== 'none' && this.internal_config.backgroundImage) {
					return this.internal_config.backgroundOrigin
				}
				return '';
			},
			backgroundRepeat() {
				if (this.internal_config.backgroundImage !== 'none' && this.internal_config.backgroundImage) {
					return this.internal_config.backgroundRepeat
				}
				return '';
			},
			backgroundSize() {
				if (this.internal_config.backgroundImage !== 'none' && this.internal_config.backgroundImage) {
					return this.internal_config.backgroundSize
				}
				return '';
			},
		},
		methods: {

			changeHeight(size, positive) {

				if (this.device == 'desktop') {
					let elementBounding = this.$el.getBoundingClientRect();

					if (positive)
						this.internal_config.height = parseInt(elementBounding.height) + size + 'px';
					else
						this.internal_config.height = parseInt(elementBounding.height) - parseInt(size) + 'px';

					if (typeof this.$parent.changeHeight !== 'undefined') {
						this.$parent.changeHeight(size, positive, this.id, $(this.$el).position().top, $(this.$el).offset().left, elementBounding.width);
					} else {
						this.$parent.$parent.changeHeight(size, positive, this.id, $(this.$el).position().top, $(this.$el).offset().left, elementBounding.width);
					}
				}
			},

			dataTag() {
				if (this.internal_config.tag) {
					return this.internal_config.tag;
				}
				return '';
			},

			hideControl() {
				var classAdd = '';
				if (!this.internal_config.showMobile) {
					classAdd = classAdd + ' hide-on-mobile'
				}

				if (!this.internal_config.showDesktop) {
					classAdd = classAdd + ' hide-on-desktop'
				}

				return classAdd;
			},

			style() {


				let style = `left:  ${this.internal_config.left};
                     padding-top: ${this.internal_config.paddingTop};
                     padding-left: ${this.internal_config.paddingLeft};
                     z-index: ${this.internal_config.zIndex};max-width: ${this.internal_config.maxWidth};margin: 0 auto;
                     padding-right: ${this.internal_config.paddingRight};
                     padding-bottom: ${this.internal_config.paddingBottom};
										 top: ${this.internal_config.top}`;
				return style;
			},
		},
		mounted() {
			let device, childs, id, config;
			({device, childs, id, config} = this.$props);

			if (typeof config.backgroundColor === 'undefined') {
				config.backgroundColor = '#FFFFFF';
			}
			this.internal_config = config;
			this.device = device;

			if (typeof childs === 'undefined') {
				childs = [];
			}
			this.elements = childs.sort((a, b) => (a.config.order_mobile > b.config.order_mobile) ? 1 : ((b.config.order_mobile > a.config.order_mobile) ? -1 : 0));
			if (typeof id !== 'undefined') {
				this.id = id;
			}

			this.internal_config = window.mergeRecursive(this.props, this.$props.config);


			let vm = this;

			setTimeout(function () {
				if (vm.internal_config.parallaxActive == true && navigator.userAgent.match(/iPad/i) == null) {
					$(vm.$refs.boxElem).jarallax({
						type: vm.internal_config.parallaxEffect,
						speed: vm.internal_config.parallaxSpeed / 10,
						imgSize: vm.internal_config.backgroundSize,
						imgPosition: vm.internal_config.backgroundPosition,
						imgRepeat: vm.internal_config.backgroundRepeat,
					});
				}
			}, 1000);

			if(this.internal_config.pinConfig.pin){
				this.$root.$emit('applyPin', this);
			}


			if(typeof this.internal_config.eduzz !== 'undefined' && this.internal_config.eduzz.enabled) {

				var script = document.createElement('script');

				script.setAttribute('src', 'https://cdn.eduzzcdn.com/sun/bridge/bridge.js');

				document.head.appendChild(script);

				setTimeout( () => {
						window.Eduzz.Checkout.init({
							contentId: this.internal_config.eduzz.code,
							target: "elements",
							errorCover: false,
						})
				}, 1000 )
			}

			if(typeof this.internal_config.hotmart !== 'undefined' && this.internal_config.hotmart.enabled) {

				 var script = document.createElement('script');

				script.setAttribute('src', 'https://pay.hotmart.com/assets/js/hotmart.upsell_checkout.js');

				document.head.appendChild(script);

				setTimeout( () => {
					let opts = {
					key: this.internal_config.hotmart.key,
					width: this.internal_config.width ,
					height: this.internal_config.height
					}
					Upsell.Widget.show(opts);
				}, 2000 )
			}

		},
		updated() {
			this.$root.$emit('applyAnimation', this, 'sitebuilder-element-container');
			if(this.internal_config.pinConfig.pin){
                this.$root.$emit('applyPin', this);
            }
		}
	}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

    [data-device="mobile"] {
        .sitebuilder-element-receiver {
            max-width: 100% !important;
            width: 100% !important;
            height: auto !important;
            min-height: 50px;
        }
    }
</style>
