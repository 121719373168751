var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sitebuilder-element-container card big-is-receiver",class:{'place-menu-collapse-show-on-hover': _vm.isOpenContainerByHover},style:(_vm.style()),attrs:{"id":this.id,"element":"PlaceMenuComponent","data-hide-desktop":!this.internal_config.showDesktop,"data-hide-mobile":!this.internal_config.showMobile}},[_c('div',{staticClass:"card-header",style:({
          background: _vm.background,
          borderRadius: _vm.radius,
          height : _vm.height,
          border: '0 !important'
      }),attrs:{"id":'heading'+this.id},on:{"click":function($event){return _vm.collapseThis($event)},"mouseover":function($event){return _vm.handleMouseover($event)},"mouseleave":function($event){return _vm.handleMouseleave($event)}}},[_c('h5',{staticClass:"mb-0 clean-text-css h-100"},[_c('button',{staticClass:"btn btn-link w-100 h-100 icon-place desktop-element big-menu-text",style:(_vm.textStyle())},[_vm._v("\n        "+_vm._s(_vm.internal_config.text)+"\n      ")]),_c('button',{staticClass:"btn btn-link w-100 h-100 icon-place  mobile-element big-menu-text",style:(_vm.textStyleMobile())},[_vm._v("\n        "+_vm._s(_vm.internal_config.text)+"\n      ")])])]),(_vm.isOpenContainerByHover)?_c('div',{staticClass:"place-menu-collapse-divisor",style:({
    height: (_vm.bottomMargin + "px"),
    top: ((parseInt(this.height)) + "px")
  })}):_vm._e(),_c('div',{staticClass:"place-menu-collapse card-body big-menu-place-childs",style:(_vm.getStyle()),attrs:{"id":'place-menu-'+this.id}},[_c('div',{class:'sitebuilder-element-receiver real-place big-is-receiver '  + (this.internal_config.positionContainer === 'width-full' ? 'container' : '')},_vm._l((_vm.elements),function(element,index){return _c(element.name,{key:index,tag:"component",attrs:{"config":element.config,"device":_vm.device,"is_popup":_vm.is_popup,"id":element.id,"height_start":index,"preview":_vm.preview,"childs":element.childs}})}),1)])])}
var staticRenderFns = []

export { render, staticRenderFns }