export default {
  data() {
    return {
      props: {
        'tag': '',
        'content': 'Texto exemplo',
        'width': '300px',
        'checkBoxTextBackgroundHover': false,
        'textBackgroundColorHover': '#ffffff',
        'fontSizeMobile': '12px',
        'alignMobile': 'center',
        'checkBoxTextBackgroundHover': false,
        'textBackgroundColorHover': '#ffffff',
        'paddingTop': '15px',
        'paddingLeft': '15px',
        'paddingRight': '15px',
        'paddingBottom': '15px',
        'zIndex': 'auto','maxWidth':'768px',
        'rotation': '0deg',
        'animation': {
          'in': {
            'action': 'time',   //time/scroll/click/hover
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'speed': 'slow',
            'infinite': false,
          },
          'out': {
            'action': 'time',   //time/scroll/click
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'delay': '1',
            'speed': '1',
            'infinite': false,
          },
        },
      },
      internal_config: {
        content: 'Texto exemplo',
        'checkBoxTextBackgroundHover': false,
        'textBackgroundColorHover': '#ffffff',
        fontSizeMobile: '12px',
        alignMobile: 'left',
        width: '300px',
        left: '0px',
        top: '0px',
        showDesktop: true,
        showMobile: true,
        'paddingTop': '15px',
        'paddingLeft': '15px',
        'paddingRight': '15px',
        'paddingBottom': '15px',
        'rotation': '0deg',

        
      },
      editing: false,
    }
  }
}
