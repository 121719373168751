<template>
  <div
      class="sitebuilder-element-container"
      element="SocialBarComponent"
      :data-hide-desktop="!this.internal_config.showDesktop"
      :data-hide-mobile="!this.internal_config.showMobile"
      :id="id"
      :style="style()"
  >
    <div :style="`transform: rotate(${this.$props.device === 'desktop' ? this.internal_config.rotation : 0}) scale(1)`">
      <div v-bind:style="{'border-radius': radius,border : border}">
        <div id="socialId"
             class="embed-responsive embed-responsive-16by9 socialbar-parent"
             v-bind:style="getOrientation()"
        >
          <a
              class="socialbar-div"
              v-if="this.internal_config.checkFacebook"
              :href="getUrl('Facebook')"
              :target="target"
              rel="noreferrer" @click="linkAnchorForce"
          >
            <img :src="getSrc('Facebook')" class="socialbar-icon"/>
          </a>
          <a class="socialbar-div"
             v-if="this.internal_config.checkTwitter"
             :href="getUrl('Twitter')"
             :target="target"
             @click="linkAnchorForce">
            <img :src="getSrc('Twitter')" class="socialbar-icon"/>
          </a>
          <a
              class="socialbar-div"
              v-if="this.internal_config.checkInstagram"
              :href="getUrl('Instagram')"
              :target="target"
              rel="noreferrer" @click="linkAnchorForce"
          >
            <img :src="getSrc('Instagram')" class="socialbar-icon"/>
          </a>
          <a
              class="socialbar-div"
              v-if="this.internal_config.checkLinkedin"
              :href="getUrl('Linkedin')"
              :target="target"
              rel="noreferrer" @click="linkAnchorForce"
          >
            <img :src="getSrc('Linkedin')" class="socialbar-icon"/>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Model from "./Model";

export default {
  name: "SocialBarComponent",
  props: ["config", "height_start", "device", "id", "preview"],
  data() {
    return Model.data();
  },
  computed: {
    width() {
      return this.internal_config.width;
    },
    height() {
      return this.internal_config.height;
    },
    border() {
      return this.internal_config.border;
    },
    radius() {
      return this.internal_config.borderRadius;
    },
    target() {
      return this.internal_config.sbNewAba ? '_blank' : '';
    }
  },
  methods: {
    animation() {
      return true;
    },
    getUrl(type) {
      if (type == "Facebook") {
        return this.internal_config.sbFacebook;
      }
      if (type == "Twitter") {
        return this.internal_config.sbTwitter;
      }
      if (type == "Instagram") {
        return this.internal_config.sbInstagram;
      }
      if (type == "Linkedin") {
        return this.internal_config.sbLinkedin;
      }
    },
    getOrientation() {
      let {orientation, spaceBetween} = this.internal_config;

      orientation = orientation ? "block" : "inline-flex";
      return `display: ${orientation}; gap: ${spaceBetween}px`;
    },
    getSrc(type) {
      return this.internal_config[`image${type}`]
          ? this.internal_config[`image${type}`]
          : "vazio";
    },
    style() {
      let {
        width,
        left,
        zIndex,
        paddingTop,
        paddingLeft,
        paddingRight,
        paddingBottom,
        top,
        rotation
      } = this.internal_config;

      let style = `  width: ${width};
                     left:  ${left};
                     z-index: ${zIndex};max-width: ${this.internal_config.maxWidth};margin: 0 auto;
                     padding-top: ${paddingTop};
                     padding-left: ${paddingLeft};
                     padding-right: ${paddingRight};
                     padding-bottom: ${paddingBottom};
           top: ${top};
           transform: rotate(${this.$props.device == 'desktop' ? rotation : 0}) scale(1)`;

      return style;
    },

    linkAnchorForce() {
      this.$root.$emit("linkAnchorForce");
    }
  },
  created() {

    let {device, id, config} = this.$props;

    this.internal_config = config;

    this.device = device;
    if (typeof id !== "undefined") {
      this.id = id;
    }

    this.internal_config = Object.assign({}, this.internal_config, window.mergeRecursive(this.props, this.$props.config));
  },
  mounted() {
    this.$root.$emit("applyAnimation", this, "sitebuilder-element-container");
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.socialbar-parent:before {
  content: none;
}

.socialbar-div {
  height: auto;
  width: 100%;
}

.socialbar-icon {
  height: auto;
  width: 100%;
}

[data-device="mobile"] .socialbar-parent {
  display: inline-flex !important;
}
</style>
