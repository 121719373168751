<template>
  <div
    :id="this.id"
    class="sitebuilder-element-container"
    element="ImageGalleryComponent"
    :data-hide-desktop="!this.internal_config.showDesktop"
    :data-hide-mobile="!this.internal_config.showMobile"
    :config-properties="JSON.stringify(internal_config)"
    :style="style()"
  >
    <!-- GALLERY -->
    <div ref="image-gallery"
         class="image-gallery-container"
           :class="{
              'paginate-with-min': (parseInt(internal_config.height) <= 300 && internal_config.allImages.length > parseInt(internal_config.imagesPerPage)),
              'paginate-with-max': (parseInt(internal_config.height) > 500 && internal_config.allImages.length > parseInt(internal_config.imagesPerPage)),
              'without-paginate': internal_config.allImages.length <= parseInt(internal_config.imagesPerPage)}"
           :style="{ minHeight : minHeight, 'transform': transform}">

        <matriz-gallery v-if="internal_config.theme === 'matriz'" :internal_config="internal_config" @showPhoto="showPhoto"/>
        <alternating-gallery v-else-if="internal_config.theme === 'alternating'" :internal_config="internal_config" @showPhoto="showPhoto"/>
        <tiles-gallery v-else-if="internal_config.theme === 'tiles'" :internal_config="internal_config" @showPhoto="showPhoto"/>
        <mixed-gallery v-else-if="internal_config.theme === 'mixed'" :internal_config="internal_config" @showPhoto="showPhoto"/>
        <one-main v-else-if="internal_config.theme === 'one-main'" :internal_config="internal_config" @showPhoto="showPhoto"/>

        <!-- PAGINATE -->
        <div class="paginate" v-if="internal_config.allImages.length > parseInt(internal_config.imagesPerPage)">
        <div v-if="internal_config.navigationWithNumber">
          <a
            v-for="(page, index) in internal_config.lastPage"
            :key="index"
            class="number"
            :class="{ active: (internal_config.currentPage+1) == page }"
            :style="styleNavigation()"
            @click="paginate(page-1)"
          >{{ page }}</a>
        </div>
        <div v-else>
          <a
            v-for="(page, index) in internal_config.lastPage"
            :key="index"
            class="circle"
            :class="{ active: (internal_config.currentPage+1) == page }"
            :style="styleNavigation()"
            @click="paginate(page-1)"
          ></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageGalleryData from "./Model";
import MatrizGallery from "./themes/MatrizGallery";
import AlternatingGallery from "./themes/AlternatingGallery";
import TilesGallery from "./themes/TilesGallery";
import MixedGallery from "./themes/MixedGallery";
import OneMain from "./themes/OneMainGallery";

export default {
  name: "ImageGalleryComponent",
  props: ["config", "id", "preview", "device"],
  components: {
    OneMain,
    MixedGallery,
    TilesGallery,
    AlternatingGallery,
    MatrizGallery,
  },
  computed: {
    minHeight() {
      return this.internal_config.minHeight;
    },
    opacity() {
      return this.internal_config.opacity;
    },
    border() {
      return this.internal_config.border;
    },
    boxShadow() {
      return this.internal_config.boxShadow;
    },
    radius() {
      return this.internal_config.borderRadius;
    },
    transform() {
      return `rotate(${this.$props.device === 'desktop' ? this.internal_config.rotation : 0}) scale(1)`;
    }
  },
  data() {
    return ImageGalleryData.data();
  },
  methods: {
    paginate(page) {
      const { images, allImages, imagesPerPage } = this.internal_config;

      const lastPhoto = allImages.length;

      this.internal_config.currentPage = page;

      const idStart = Math.floor(page * imagesPerPage);
      const idEnd =
        idStart + +imagesPerPage > lastPhoto
          ? lastPhoto
          : idStart + +imagesPerPage;

      images.splice(0, images.length);
      for (let index = idStart; index < idEnd; index++) {
        images.push(allImages[index]);
      }
    },
    showPhoto(index) {
      const { images } = this.internal_config;
      this.$root.$emit("galleryShowImageUrl", true, images[index]);
    },
    build() {
      const { allImages, imagesPerPage } = this.internal_config;
      this.internal_config.lastPage = Math.ceil(
        allImages.length / imagesPerPage
      );

      this.paginate(0);
    },
    styleNavigation() {
      const {
        navigationBackground,
        navigationHover,
        navigationActive
      } = this.internal_config;
      return `--background-color: ${navigationBackground};
              --background-color--hover: ${navigationHover};
              --background-color-active: ${navigationActive};`;
    },
    style() {
      var style = `width: ${this.internal_config.width};
                     height:  ${this.internal_config.height};
                     z-index: ${this.internal_config.zIndex};max-width: ${this.internal_config.maxWidth};margin: 0 auto;
                     padding-top: ${this.internal_config.paddingTop};
                     padding-left: ${this.internal_config.paddingLeft};
                     padding-right: ${this.internal_config.paddingRight};
                     padding-bottom: ${this.internal_config.paddingBottom};
                     left:  ${this.internal_config.left};
                     top: ${this.internal_config.top};`;
      return style;
    },
    hover() {
      this.$emit("highlightHover", {
        name: this.name,
        posicao: this.$refs.image.getBoundingClientRect()
      });
    }
  },
  mounted() {
    this.internal_config = window.mergeRecursive(
      this.props,
      this.$props.config
    );

    this.$root.$emit("applyAnimation", this, "sitebuilder-element-container");


    this.build();
  }
};
</script>
<style lang="scss" scoped>
/** GALLERY */

.image-gallery-container {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 85% 15%;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;

  &.paginate-with-max{
    grid-template-rows: calc(100% - 75px) 75px;
  }

  &.paginate-with-min{
    grid-template-rows: calc(100% - 35px) 35px;
  }

  &.without-paginate{
    grid-template-rows: 100%;
  }
}

[data-device="tablet"] .image-gallery-container,
[data-device="mobile"] .image-gallery-container{
  grid-template-rows: calc(100% - 40px) 40px;
}

.image-gallery-photos-container {
  display: grid;
  width: 100%;
  height: 100%;
}

.image-gallery-photo {
  overflow: hidden;
  float: left;
  overflow: hidden;
  background-position: top center;
  background-size: cover;
  position: relative;
  cursor: pointer;
}

/** PAGINATE */

.paginate,
.paginate > div {
  display: grid;
  grid-gap: 10px;
  grid-auto-flow: column;
  align-items: center;
  justify-content: center;
  z-index: 99;

  & a {
    background: var(--background-color);
    border-radius: 50%;
    cursor: pointer;

    &.number {
      text-align: center;
      font-weight: "bold";
      width: 30px;
      border: 2px solid #222;
    }

    &.circle {
      //border: 1px solid #222;
      width: 15px;
      height: 15px;
    }

    &.active {
      background: var(--background-color-active);
    }

    &:hover {
      background: var(--background-color--hover);
    }
  }
}
</style>
