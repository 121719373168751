<template>




 <div :id="id" @contextmenu.prevent="handler($event)"
       class="sitebuilder-element-container"
       element="BlogDatesListComponent"
       :data-hide-desktop="!this.internal_config.showDesktop"
       :data-hide-mobile="!this.internal_config.showMobile"
       :config-properties="JSON.stringify(internal_config)" :style="style()" v-on:click="focusElement($event)">

  

        <div :style="titleStyle()">
            <span>2020</span> <br>
            <span>2019</span> <br>
            <span>2018</span> <br>
            <span>2017</span>
        </div>


</div>

  
</template>

<script>

import elementData from './Model';



export default {
    name: 'BlogDatesListComponent',
    props: ['config', 'id'],
    components : { },
     data() {
			return elementData.data();
    },
    methods: {
         focusElement(e) {
            this.$root.$emit("focusElement", this);
            this.$root.$emit("handlerElement", this);

            e.stopPropagation();
            e.preventDefault();
        },
        onResize: function(x, y, width, height) {
            this.internal_config.width = width + "px";
            this.internal_config.height = height + "px";

         
        },
        build(config) {
            this.internal_config = config;
        },
         animation() {
        return false;
        },
        showResources() {
       
        },

        titleStyle(){
            return `font-family:  ${this.internal_config.titleStyle.family};
                  font-size: ${this.internal_config.titleStyle.size};
                  text-align: ${this.internal_config.titleStyle.align};
                  font-weight: ${this.internal_config.titleStyle.weight};
                  font-style: ${this.internal_config.titleStyle.style};
                  width: ${this.internal_config.width};
                  text-decoration: ${this.internal_config.titleStyle.stroke};
                  color: ${this.internal_config.titleStyle.color}`;
        },


        //style methods

        style(){
           let {
        left,
        zIndex,
        paddingTop,
        paddingLeft,
        paddingRight,
        paddingBottom,
        top
      } = this.internal_config;

      let style = `
                     left:  ${left};
                     z-index: ${zIndex};max-width: ${this.internal_config.maxWidth};margin: 0 auto;
                     padding-top: ${paddingTop};
                     padding-left: ${paddingLeft};
                     padding-right: ${paddingRight};
                     padding-bottom: ${paddingBottom};
					 top: ${top}`;

      return style; 
        },
    },
      created() {
        let device, childs, id, config;
        ({ device, childs, id, config } = this.$props);
        this.internal_config = config;
        this.device = device;
        if (typeof id !== "undefined") {
            this.id = id;
        }
        this.internal_config = window.mergeRecursive(
        this.props,
        this.$props.config
        );
  },
  mounted(){

        let vm = this;

         this.build(this.$props.config);
         this.$root.$on('setConfigElement', (id, obj) => {
            if(this.id == id){
            this.config = obj;
            this.internal_config = obj;
            }
        });


        

         let objIds = [];
            $("[id]").each(function() {
            var ids = $('[id="' + this.id + '"]');
            if (ids.length > 1 && ids[0] == this && parseInt(this.id))
                objIds.push(this.id);
            });
        if (objIds.includes(this.id)) {
        this.id = Date.now() + parseInt(Math.random() * 10000);
        }
  }
}
</script>

<style>

</style>