<template>
    <div :id="this.id"
         class="sitebuilder-element-container"
         element="LineComponent"
         :data-tag="dataTag()"
         :data-hide-desktop="!this.internal_config.showDesktop"
         :data-hide-mobile="!this.internal_config.showMobile"
         :style="style()">

        <div class="resizable vdr" :style="{'transform': transform}">
            <div class="container-line-hor">
                <div class="arrow arrow-left" v-if="internal_config.arrowLeft.active" :style="styleArrowLeft()"></div>
                <div class=" sitebuilder-element"
                     v-bind:style="{'border-radius': radius, 'border-bottom' : border, 'box-shadow': boxShadow, marginLeft: marginleft, marginRight : marginright}"></div>
                <div class="arrow arrow-right" v-if="internal_config.arrowRight.active"
                     :style="styleArrowRight()"></div>
            </div>

        </div>
    </div>
</template>

<script>

	import Model from './Model';

	export default {
		name: 'LineComponent',
		props: ['config', 'src', 'id', 'preview', 'device'],
		computed: {
			border() {
				if (typeof this.internal_config.border !== 'undefined') {
					let splitedBorder = this.internal_config.border.split(' ');

					if (typeof splitedBorder[2] !== 'undefined' && this.$globalStoreHelpers.isValidHex(splitedBorder[2])) {
						splitedBorder[2] = this.$globalStoreHelpers.hexToRGBA(splitedBorder[2]);
						this.internal_config.border = splitedBorder.join(' ');
					}
				}
				return this.internal_config.border;
			},
			boxShadow() {
				return this.internal_config.boxShadow;
			},
			radius() {
				return this.internal_config.borderRadius;
			},
			marginleft() {
				if (this.internal_config.arrowLeft.active) {
					return this.internal_config.arrowLeft.size + 'px';
				}
				return '';
			},
			marginright() {
				if (this.internal_config.arrowRight.active) {
					return this.internal_config.arrowRight.size + 'px';
				}
				return '';
			},
      transform() {
        return `rotate(${this.$props.device === 'desktop' ? this.internal_config.rotation : 0}) scale(1)`;
      }
		},
		data() {
			return Model.data();
		},
		methods: {
			dataTag() {
				if (this.internal_config.tag) {
					return this.internal_config.tag;
				}
				return '';
			},

			build(config) {
				this.internal_config = config;
			},
			style() {
				var style = `width: ${this.internal_config.width};
                     z-index: ${this.internal_config.zIndex};max-width: ${this.internal_config.maxWidth};margin: 0 auto;
                     padding-top: ${this.internal_config.paddingTop};
                     padding-left: ${this.internal_config.paddingLeft};
                     padding-right: ${this.internal_config.paddingRight};
                     padding-bottom: ${this.internal_config.paddingBottom};
                     left:  ${this.internal_config.left};
										 top: ${this.internal_config.top};`;
				return style;
			},
			styleArrowLeft() {

				var style = `border-top-width: ${this.internal_config.arrowLeft.size}px;
                     border-bottom-width: ${this.internal_config.arrowLeft.size}px;
                     border-right-width: ${this.internal_config.arrowLeft.size}px;
                     top: calc(50% - ${this.internal_config.arrowLeft.size}px);
                     border-right-color: ${this.internal_config.arrowLeft.color};`;
				return style;
			},
			styleArrowRight() {

				var style = `border-top-width: ${this.internal_config.arrowRight.size}px;
                     border-bottom-width: ${this.internal_config.arrowRight.size}px;
                     border-left-width: ${this.internal_config.arrowRight.size}px;
                     top: calc(50% - ${this.internal_config.arrowRight.size}px);
                     border-left-color: ${this.internal_config.arrowRight.color};`;
				return style;
			},
			styleResize() {
				return `{width: ${this.internal_config.width}, height:  ${this.internal_config.height}}`;
			},
		},
		mounted() {

			this.build(this.$props.config);

			Object.keys(this.props).forEach(prop => {
				if (typeof this.internal_config[prop] === 'undefined') {
					this.internal_config[prop] = this.props[prop];
				}
			});

			this.$root.$emit('applyAnimation', this, 'sitebuilder-element-container');

		}
	}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    [data-device="desktop"] [element="LineComponent"] .vdr {
        padding-top: 15px !important;
        padding-bottom: 15px !important;
    }

    .container-line-hor {
        position: relative;
    }

    .arrow {
        position: absolute;
        width: 0;
        height: 0;
    }

    .arrow-right {
        border-top-color: transparent;
        border-top-style: solid;
        border-bottom-color: transparent;
        border-bottom-style: solid;

        border-left-style: solid;
        right: 0;
    }

    .arrow-left {
        border-top-color: transparent;
        border-top-style: solid;
        border-bottom-color: transparent;
        border-bottom-style: solid;

        border-right-style: solid;
    }

</style>
<style scoped>

    .resizable.vdr {
        width: 100% !important;
        height: auto !important;
        left: 0 !important;
        top: 0 !important;
    }
</style>
