export default {
  data() {
    return {
      props: {
        'name': 'BlogRssComponent',
        'zIndex': 'auto',
        'paddingTop': '0px',
        'paddingLeft': '15px',
        'paddingRight': '15px',
        'paddingBottom': '15px'
      },
      elements: [],
      internal_config: {
        'theme': '01',
        'width': '100px',
        'height': '100px',
        'zIndex': 'auto',
        'showDesktop': true,
        'showMobile': true,
        'paddingTop': '0px',
        'paddingLeft': '15px',
        'paddingRight': '15px',
        'paddingBottom': '15px',
        left: '0px',
        top: '0px'
      }
    };
  }
};
