export default {
    data() {
      return {
        props: {
          'name':'PostBlogComponent',
          'width': '650px',
          'height': '50px',
          'zIndex': 'auto',
          'border': '0 none #000000',
          'boxShadow': '0 0 0 0 black initial',
          'borderRadius': '0 0 0 0',
          'paddingTop': '0px',
          'paddingLeft': '15px',
          'paddingRight': '15px',
          'paddingBottom': '15px',
          'maxWidth' : '500px',

          themeBlog: 'feed',
          'showDesktop': true, 'showMobile': true,

          'animation': {
            'in': {
              'action': 'time',   //time/scroll/click/hover
              'time': 0,       // sec
              'scroll': 0,     // px
              'effect': 'none',
              'speed': 'slow',
              'infinite': false,
            },
            'out': {
              'action': 'time',   //time/scroll/click
              'time': 0,       // sec
              'scroll': 0,     // px
              'effect': 'none',
              'delay': '1',
              'speed': '1',
              'infinite': false,
            },
          }
        },
        elements: [],
        post: {
          title: '',
          content: '',
          categories: [],
          author: {}
        },
        internal_config: {
          'width': '50px',
          'height': '50px',
          'zIndex': 'auto',
          'border': '0 none #000000',
          'boxShadow': '0 0 0 0 black initial',
          'borderRadius': '0 0 0 0',
          'paddingTop': '15px',
          'paddingLeft': '15px',
          'paddingRight': '15px',
          'paddingBottom': '15px',
          'animation': {
            'in': {
              'action': 'time',   //time/scroll/click/hover
              'time': 0,       // sec
              'scroll': 0,     // px
              'effect': 'none',
              'speed': 'slow',
              'infinite': false,
            },
            'out': {
              'action': 'time',   //time/scroll/click
              'time': 0,       // sec
              'scroll': 0,     // px
              'effect': 'none',
              'delay': '1',
              'speed': '1',
              'infinite': false,
            },
          }
        }
      }
    }
  }
