export const getUrlRedirect = (lang) => {
    const urlEnUS = 'https://builderall.com'
    const urls = {
        'pt_BR': 'https://builderall.com.br',
        'fr_FR': 'https://fr.builderall.com',
        'nl_NL': 'https://nl.builderall.com',
        'es_ES': 'https://es.builderall.com',
        'it_IT': 'https://it.builderall.com',
        'vi_VI': 'https://vi.builderall.com',
        'pl_PL': 'https://pl.builderall.com',
        'de_DE': 'https://builderall.de',
    }

    return typeof urls[lang] !== 'undefined' ?  urls[lang] : urlEnUS
}

export const isImpact = () => {
    let actualUrl = new URL(window.location.href)
    let impactParam = actualUrl.searchParams.get('irclickid');
    return !!impactParam
}

export const urlSearchParams = () => {
    return new URLSearchParams(window.location.search);
}