<template>
  <div :id="this.id"
       class="sitebuilder-element-container"
       element="MailingBossFormComponent"
       :class="internal_config.recaptchaSiteKey ? 'recaptcha' : ''"
       :data-tag="dataTag()"
       :data-hide-desktop="!this.internal_config.showDesktop"
       :data-hide-mobile="!this.internal_config.showMobile"
       :style="style()">
    <div
        :style="`transform: rotate(${this.$props.device === 'desktop' ? this.internal_config.rotation : 0}) scale(1);`">
      <!--      <div class="divBlock" v-show="!this.preview"></div>-->
      <form v-if="checkGdpr()" :class="horizontal ? 'form-mboss horizontal-form': 'form-mboss'"
            :target="internal_config.target" method="post" ref="form"
            :action="getAction">
        <input type="hidden" name="origin_uid" v-model="originUid">

        <div class="inputs-area" ref="container"></div>
        <div class="recaptcha-container" v-if="this.internal_config.recaptchaSiteKey">
          <vue-recaptcha :sitekey="this.internal_config.recaptchaSiteKey"></vue-recaptcha>
        </div>
        <div class="place-btn" :style="{ margin: buttonMargin }">
          <div class="bt-sb"
               v-bind:style="{
                        width : buttonWidth,
                        border : buttonBorder,
                        'border-radius': buttonRadius,
                        background: buttonBackground,
                        'box-shadow': buttonBoxShadow
                        }">
            <div class="bt-text-area d-flex align-items-center flex-wrap overflow-hidden"
                 :style="{minHeight: buttonHeight}">
              <a v-on:click="submit($event)" class="sb-text"
                 ref="buttonClick"
                 :style="textStyle()">{{ internal_config.button.text }}</a>
              <small class="sb-sub-text"
                     v-show="this.internal_config.button.subText != ''"
                     @click="$refs.buttonClick.click()"
                     :style="subTextStyle()">{{ internal_config.button.subText }}</small>
            </div>
          </div>
        </div>
      </form>


      <sweet-modal ref="modal" width="30vw">
        <div class="list-group-mailing-boss list-group"></div>
        <button slot="button" class="btn btn-outline-info" @click="close">Cancel</button>
        <button slot="button" class="btn btn-outline-success" @click="selectList">Select</button>
      </sweet-modal>

    </div>
  </div>
</template>

<script>

import Vue from 'vue';
import Data from './Model';
import {SweetModal} from 'sweet-modal-vue';
import VueRecaptcha from 'vue-recaptcha';

import CheckFields from "../Fields/CheckFields.vue";
import SelectFields from "../Fields/SelectFields.vue";
import TextFields from "../Fields/Text.vue";
import DateFields from "../Fields/DateFields.vue";
import axios from "axios";
import MixinForm from "@/components/apps/Forms/MixinForm";

export default {
  name: 'MailingBossFormComponent',
  props: ['config', 'id', 'preview', 'device'],
  mixins: [MixinForm],
  components: {
    SweetModal,
    VueRecaptcha
  },
  computed: {
    getAction() {
      if (typeof this.internal_config !== 'undefined' && typeof this.internal_config.url_subscribe !== 'undefined') {
        return this.internal_config.url_subscribe.replace('http://', 'https://')
      }

      return 'https://app.mailingboss.com/lists/' + this.internal_config.listUid + '/subscribe'

    },
    horizontal() {
      return this.internal_config.horizontal;
    },
    buttonWidth() {
      return parseInt(this.internal_config.button.width) + '% !important';
    },
    buttonHeight() {
      return parseInt(this.internal_config.button.height) + 'px';
    },
    buttonBorder() {
      return this.internal_config.button.border;
    },
    buttonBoxShadow() {
      return this.internal_config.button.boxShadow;
    },
    buttonRadius() {
      return this.internal_config.button.borderRadius;
    },
    buttonBackground() {
      let bg = this.internal_config.button.backgroundColor;

      if (this.$globalStoreHelpers.isValidHex(bg)) {
        bg = this.$globalStoreHelpers.hexToRGBA(bg);
      }

      if (this.internal_config.backgroundImage && this.internal_config.backgroundImage !== 'none') {
        bg += ' url("' + this.internal_config.button.backgroundImage + '")';
        bg += ' ' + this.internal_config.button.backgroundPosition;
        bg += ' / ' + this.internal_config.button.backgroundSize;
        bg += ' ' + this.internal_config.button.backgroundRepeat;
        bg += ' ' + this.internal_config.button.backgroundOrigin;
      }

      return bg;
    },
  },
  data() {
    return Data.data();
  },
  methods: {
    dataTag() {
      if (this.internal_config.tag) {
        return this.internal_config.tag;
      }
      return '';
    },

    async registerOnSocialProof() {

      try {

        let campaignId = parseInt(this.internal_config.socialproof_campaign_id);
        let userNameTAG = this.internal_config.socialproof_user_name_tag;

        let inputUserName = this.$refs.form.querySelector(`[name="${userNameTAG}"]`);

        if (campaignId && userNameTAG && inputUserName) {
          var formData = new FormData();
          formData.set('user_name', inputUserName.value);

          await axios({
            'method': 'post',
            'url': '/cheetah/social-proof/register/' + campaignId,
            data: formData,
          }).then((res) => {
          });
        }
      } catch (e) {
        console.error(e);
      }
    },

    submit(e) {


      let form = this.$refs.container;
      var inputs = form.getElementsByClassName("form-group");
      let not_pass = false;

      localStorage.removeItem('mboss-data')
      let input_email = form.querySelector('[name="EMAIL"]')
      let input_email_confirm = form.querySelector('[name="EMAIL_CONFIRM"]')

      if (input_email && input_email_confirm) {
        if (input_email.value !== input_email_confirm.value) {
          this.$root.$emit('errorMessagePopup', [this.$t('ct-emails-dont-match-publication')]);
          input_email_confirm.classList += ' is-invalid'
          return;
        } else {
          input_email_confirm.classList.remove("is-invalid");
        }

      }


      for (var i = 0, len = inputs.length; i < len; i++) {
        var field = inputs[i].querySelector('input, select, textarea');
        var checkFieldsRequired = inputs[i].hasAttribute('data-check-required');


        try {

          if (field.name.toLowerCase().indexOf('email') !== -1 && field.value !== '') {
            localStorage.setItem('leadEmail', field.value);
          }

          if (field.name.toLowerCase() === 'fname' && field.value !== '') {
            localStorage.setItem('leadName', field.value);
          }

          if (field.name.toLowerCase() === 'name' && field.value !== '') {
            localStorage.setItem('leadName', field.value);
          }

          if (field.name.toLowerCase() === 'fnome' && field.value !== '') {
            localStorage.setItem('leadName', field.value);
          }

          if (field.name.toLowerCase() === 'nome' && field.value !== '') {
            localStorage.setItem('leadName', field.value);
          }

          if (field.name.toLowerCase() === 'cadastro_nome' && field.value !== '') {
            localStorage.setItem('leadName', field.value);
          }

        } catch (error) {
          console.log(error)
        }


        if (inputs[i].style.display === 'none') {
          continue;
        }

        if (!checkFieldsRequired) {

          if (field.required && field.value === '') {
            not_pass = true;

            field.style.outlineColor = 'red';
            field.style.outlineWidth = '1px';
            field.style.outlineStyle = 'solid';

          } else {
            field.style.outlineColor = 'transparent';
            field.style.outlineWidth = '1px';
            field.style.outlineStyle = 'solid';
          }
        } else {

          try {

            let inputsCheck = inputs[i].querySelectorAll('[type=checkbox][data-required=true],[type=radio][data-required=true]');
            let checked = 0;

            if (inputsCheck.length > 0) {

              inputsCheck.forEach(element => {
                if (element.checked) checked++;
              });

              if (checked === 0) {
                not_pass = true;

                inputsCheck[0].style.outlineColor = 'red';
                inputsCheck[0].style.outlineWidth = '1px';
                inputsCheck[0].style.outlineStyle = 'solid';
              } else {
                inputsCheck[0].style.outlineColor = 'transparent';
                inputsCheck[0].style.outlineWidth = '1px';
                inputsCheck[0].style.outlineStyle = 'solid';
              }
            }

          } catch (e) {
            console.warn('Error', e);
          }
        }
      }

      let recaptchaResponse = (document.getElementById('g-recaptcha-response') || {}).value;
      if (this.internal_config.recaptchaSiteKey && (recaptchaResponse === '' || recaptchaResponse === null)) {
        not_pass = true;
      }

      function postSubmit() {
        if (typeof __baCRMTracking !== 'undefined') {
          __baCRMTracking.onSubmit(e)
        }

        if (localStorage.getItem('MAILING_BOSS_TRACKING') && localStorage.getItem('leadEmail') != null && typeof MailingBossTracking != 'undefined') {
          console.log('mboss lead register');
          var options = {
            leadEmail: localStorage.getItem('leadEmail'),
            reference: localStorage.getItem('MAILING_BOSS_REFERENCE')
          };
          MailingBossTracking.init(localStorage.getItem('MAILING_BOSS_TOKEN'), options);
        }

        this.registerOnSocialProof();
        this.$refs.form.submit();
      }

      if (not_pass) {

        this.$root.$emit('errorMessagePopup', [this.$t('ct-please-check-the-required-fields')]);

      } else {

        let vm = this;

        if (this.internal_config.recaptchaSiteKey) {

          var bodyFormData = new FormData();
          bodyFormData.set('id', this.id);
          bodyFormData.set('g-recaptcha-response', recaptchaResponse);

          axios({
            'method': 'post',
            'url': '/cheetah/mailingboss_form_submit?id=' + this.id,
            data: bodyFormData,
          })
              .then((res) => {

                if (res.data.success == true) {
                  postSubmit.call(vm);
                } else {
                  this.$root.$emit('errorMessagePopup', [res.data.msg]);
                }
              });

        } else {
          postSubmit.call(this);
        }
      }

    },

    close() {
      if (this.internal_config.listUid === '') {
        // $(this.$el).detach();
      }
      this.$refs.modal.close();
    },
    buildFields: function (vm) {

      this.$refs.container.innerHTML = '';

      vm.internal_config.dataList.forEach(field => {

        const {identifier} = field.type;
        if (identifier === 'textarea') vm.createTextField(field, true);
        if (identifier === 'text') vm.createTextField(field, false);
        if (identifier === 'multiselect') vm.createDropDown(field, true);
        if (identifier === 'dropdown') vm.createDropDown(field, false);
        if (identifier === 'datetime') vm.createDateTime(field, true);
        if (identifier === 'date') vm.createDateTime(field, false);
        if (identifier === 'checkbox') vm.createCheckField(field, 'checkbox');
        if (identifier === 'radio') vm.createCheckField(field, 'radio');
      });
      // let element = document.querySelectorAll('body');
      // element.classList.remove("loading");
    },
    selectList() {
      //
      // let vm = this;
      //
      // let dataRef = $('.list-group-item.selected').attr('data-ref');
      // this.internal_config.listUid = dataRef;
      //
      // this.getFields(dataRef, vm);
      // vm.close();
    }
    ,


    textStyle() {
      let sizeMobile = typeof this.internal_config.button.textStyle.sizeMobile !== 'undefined' && this.internal_config.button.textStyle.sizeMobile ?
          this.internal_config.button.textStyle.sizeMobile : false;

      return `font-family:  ${this.internal_config.button.textStyle.family};
                 font-size: ${(this.device === 'mobile' && sizeMobile) ? sizeMobile : this.internal_config.button.textStyle.size};
                 text-align: ${this.internal_config.button.textStyle.align};
                 font-weight: ${this.internal_config.button.textStyle.weight};
                 font-style: ${this.internal_config.button.textStyle.style};
                 color: ${this.internal_config.button.textStyle.color}`;
    },

    subTextStyle() {
      let sizeMobile = typeof this.internal_config.button.subTextStyle.sizeMobile !== 'undefined' && this.internal_config.button.subTextStyle.sizeMobile ?
          this.internal_config.button.subTextStyle.sizeMobile : false;
      return `font-family:  ${this.internal_config.button.subTextStyle.family};
                 font-size: ${(this.device === 'mobile' && sizeMobile) ? sizeMobile : this.internal_config.button.subTextStyle.size};
                 text-align: ${this.internal_config.button.subTextStyle.align};
                 font-weight: ${this.internal_config.button.subTextStyle.weight};
                 font-style: ${this.internal_config.button.subTextStyle.style};
                 color: ${this.internal_config.button.subTextStyle.color}`;
    },

    buildIcon: function () {

      $(this.$el).find('i').detach();

      if (this.internal_config.button.icon !== '') {
        if (this.internal_config.button.iconPosition === 'left') {
          $('<i>').addClass('mdi ' + this.internal_config.button.icon).prependTo($(this.$el).find('.sb-text').get(0));
        }
        if (this.internal_config.button.iconPosition === 'right') {
          $('<i>').addClass('mdi ' + this.internal_config.button.icon).appendTo($(this.$el).find('.sb-text').get(0));
        }
      }
    }
    ,
    build(config) {
      this.internal_config = config;
      this.buildIcon();
    }
    ,
    style() {
      return `left:  ${this.internal_config.left};
						padding-top: ${this.internal_config.paddingTop};
						  width: ${this.internal_config.width};
                     z-index: ${this.internal_config.zIndex};max-width: ${this.internal_config.maxWidth};margin: 0 auto;
                padding-left: ${this.internal_config.paddingLeft};
                padding-right: ${this.internal_config.paddingRight};
                padding-bottom: ${this.internal_config.paddingBottom};
								top: ${this.internal_config.top};`;
    },

    createCheckField(field, type) {
      let ComponentClass = Vue.extend(CheckFields);
      let instance = new ComponentClass({
        propsData: {
          type: type,
          label: field.label,
          required: field.required,
          options: field.options,
          showLabel: this.internal_config.showLabel,
          showPlaceholder: this.internal_config.showPlaceholder,
          selected: [],
          data: field,
        }
      });
      instance.$mount();
      this.$refs.container.appendChild(instance.$el)
    }
    ,
    createDropDown(field, multiple) {

      var selected = multiple ? [] : '';

      if (field.default_value) {
        if (multiple) {
          selected.push(field.default_value);
        } else {
          selected = field.default_value
        }
      }

      let ComponentClass = Vue.extend(SelectFields);
      let instance = new ComponentClass({
        propsData: {
          label: field.label,
          options: field.options,
          showLabel: this.internal_config.showLabel,
          showPlaceholder: this.internal_config.showPlaceholder,
          selected: selected,
          data: field,
          multiple: multiple,
        }
      });
      instance.$mount();
      this.$refs.container.appendChild(instance.$el)
    }
    ,
    createDateTime(field, useCurrent) {

      let ComponentClass = Vue.extend(DateFields);
      let instance = new ComponentClass({
        propsData: {
          label: field.label,
          data: field,
          showLabel: this.internal_config.showLabel,
          showPlaceholder: this.internal_config.showPlaceholder,
          value: new Date().toString(),
          type: (useCurrent ? 'datetime' : 'date'),
          is_mailingboss: true
        }
      });
      instance.$mount();
      this.$refs.container.appendChild(instance.$el)
    }
    ,
    createTextField(field, area) {
      let ComponentClass = Vue.extend(TextFields);
      let instance = new ComponentClass({
        propsData: {
          label: field.label,
          showLabel: this.internal_config.showLabel,
          showPlaceholder: this.internal_config.showPlaceholder,
          value: '',
          data: field,
          area: area,
        }
      });
      instance.$mount();
      this.$refs.container.appendChild(instance.$el)
    }
    ,
    inputStyle() {

      let vm = this;

      let elements = this.$el.getElementsByClassName('form-control');
      [].forEach.call(elements, function (el) {
        el.style.backgroundColor = vm.internal_config.inputBgColor;
        el.style.border = vm.internal_config.inputBorder;
        if (el.tagName === 'TEXTAREA' && vm.internal_config.textareaHeight) {
          el.style.height = vm.internal_config.textareaHeight;
          el.classList.add('form-control-textarea-has-height');
        } else {
          el.style.height = vm.internal_config.inputHeight;
        }
        el.style.borderRadius = vm.internal_config.inputRadius;

        el.style.fontFamily = vm.internal_config.inputStyle.family;
        el.style.fontSize = vm.internal_config.inputStyle.size;
        el.style.textAlign = vm.internal_config.inputStyle.align;
        el.style.fontStyle = vm.internal_config.inputStyle.style;
        el.style.fontStyle = vm.internal_config.inputStyle.style;
        el.style.color = vm.internal_config.inputStyle.color;
      });

      vm.$el.style.color = vm.internal_config.labelStyle;
    },

    getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return false;
    },

    checkGdpr() {

      try {
        if (window.gdprSettings && window.gdprSettings.active) {
          if (this.internal_config.recaptchaSiteKey) {
            return this.getCookie('cookieControl')
          }
        }
      } catch (e) {
        console.log(e)
      }


      return true;
    }

  },

  mounted() {

    let vm = this;

    this.build(this.$props.config);
    this.internal_config.button.subText = this.internal_config.button.subtext;

    Object.keys(this.props).forEach(prop => {
      if (typeof this.internal_config[prop] === 'undefined') {
        this.internal_config[prop] = this.props[prop];
      }
    });

    this.$root.$emit('applyAnimation', this, 'sitebuilder-element-container');


    // if (this.$props.preview) {
    this.$el.classList.add('preview');

    this.$el.addEventListener('mouseover', function () {

      if (vm.$globalStoreHelpers.isValidHex(vm.internal_config.button.backgroundColorHover)) {
        vm.internal_config.button.backgroundColorHover = vm.$globalStoreHelpers.hexToRGBA(vm.internal_config.button.backgroundColorHover);
      }
      if (vm.$globalStoreHelpers.isValidHex(vm.internal_config.button.textColorHover)) {
        vm.internal_config.button.textColorHover = vm.$globalStoreHelpers.hexToRGBA(vm.internal_config.button.textColorHover);
      }

      document.body.style.setProperty('--button-background-color--hover', vm.internal_config.button.backgroundColorHover);
      document.body.style.setProperty('--button-text-color--hover', vm.internal_config.button.textColorHover);
    });
    // }

    /**
     * configuração para listas do tipo ranking
     */
    let originUrl = new URL(window.location.href)
    let param = originUrl.searchParams.get('origin_uid');
    if (param !== null) {
      this.originUid = param
    }


    if (this.internal_config.listUid === '') {
      this.listsOfUser();
    } else {
      this.buildFields(this);
    }

    this.inputStyle();

    this.setColumns();

    $('input[name="PHONE"]').keyup(function (e) {
      if (/\D/g.test(this.value)) {
        this.value = this.value.replace(/\D/g, '');
      }
    });

    if (typeof this.internal_config.horizontal_config !== 'undefined') {
      var configMobile = (typeof this.internal_config.horizontal_config_mobile !== 'undefined') ? this.internal_config.horizontal_config_mobile : false;
      this.testAlan(this.internal_config.horizontal_config, configMobile)
    }
  },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.sitebuilder-element-container .vdr,
.vdr, .bt-sb,
form.form-mboss {
  height: auto !important;
}

.place-btn {
  text-align: center;

  .bt-sb {
    display: inline-block;
  }
}

.bt-text-area {
  display: block;
}

.bt-sb:hover {
  cursor: pointer;
  background: var(--button-background-color--hover) !important;
  color: var(--button-text-color--hover) !important;
  -webkit-text-fill-color: var(--button-text-color--hover) !important;
}

[data-device="mobile"] .bt-text-area {
  width: auto !important;
}
</style>
<style lang="scss">

select.form-control[multiple], select.form-control[size],
textarea.form-control:not(.form-control-textarea-has-height) {
  height: auto !important;
}

.list-item {
  display: block;
  width: 100%;
  border: 1px solid lightgrey;
  border-radius: 5px;
  margin-top: 2px;
  cursor: pointer;
}

.list-item.selected {
  border: 1px solid blue;
}

.recaptcha-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
</style>
