<template>
    <div :id="this.idSimpleForm"
	:data-id="this.id"
         class="sitebuilder-element-container"
         element="SimpleFormComponent"
		:class="internal_config.recaptchaSiteKey ? 'recaptcha' : ''"
         :data-tag="dataTag()"
         :data-hide-desktop="!this.internal_config.showDesktop"
         :data-hide-mobile="!this.internal_config.showMobile"
         :style="style()">
      <div :style="`transform: rotate(${this.$props.device === 'desktop' ? this.internal_config.rotation : 0}) scale(1)`">
        <form  v-if="checkGdpr()" @submit.prevent="submit($event, internal_config)"
              :class="horizontal ? 'form-mboss horizontal-form': 'form-mboss'" ref="form"
              method="post" :action="internal_config.action"
              :receiver="internal_config.receiver" :subject="internal_config.subject">
          <input type="hidden" name="origin_uid" v-model="originUid">
            <div class="inputs-area" ref="container"></div>
            <div class="recaptcha-container" v-if="internal_config.recaptchaSiteKey">
              <vue-recaptcha :sitekey="internal_config.recaptchaSiteKey"></vue-recaptcha>
            </div>
            <div class="place-btn" :style="{ margin: buttonMargin }">
                <div class="bt-sb"
                     v-bind:style="{
                        width : buttonWidth,
                        border : buttonBorder,
                        'border-radius': buttonRadius,
                        background: buttonBackground,
                        'box-shadow': buttonBoxShadow
                        }">
                  <div class="bt-text-area d-flex align-items-center flex-wrap overflow-hidden"
                       :style="{minHeight: buttonHeight}">
                    <a v-on:click="submit($event, internal_config)"
                       class="sb-text"
                       ref="buttonClick"
                       :class="{'disabled' : iconLoading}"
                       :style="textStyle()">
                      <span class="spinner-border spinner-border-sm mr-1" role="status"
                            aria-hidden="true" v-if="iconLoading"></span>{{ internal_config.button.text }}
                    </a>
                    <small class="sb-sub-text"
                           @click="$refs.buttonClick.click()"
                           v-show="this.internal_config.button.subText != ''"
                           :style="subTextStyle()">{{ internal_config.button.subText }}</small>
                  </div>
                </div>
            </div>
        </form>
      </div>
    </div>
</template>

<script>

	import Vue from 'vue';
	import axios from 'axios';
	import Data from './Model';
	import VueRecaptcha from 'vue-recaptcha';

	import CheckFields from "../Fields/CheckFields.vue";
	import SelectFields from "../Fields/SelectFields.vue";
	import TextFields from "../Fields/Text.vue";
	import DateFields from "../Fields/DateFields.vue";
  import MixinForm from "@/components/apps/Forms/MixinForm";

	export default {
		name: 'SimpleFormComponent',
		props: ['config', 'id', 'preview', 'device'],
    mixins: [MixinForm],
		components: {
			VueRecaptcha
		},
		computed: {
			horizontal() {
				return this.internal_config.horizontal;
			},
			buttonWidth() {
				return parseInt(this.internal_config.button.width) + '% !important';
			},
			buttonHeight() {
				return parseInt(this.internal_config.button.height) + 'px';
			},
			buttonBorder() {
				return this.internal_config.button.border;
			},
			buttonBoxShadow() {
				return this.internal_config.button.boxShadow;
			},
			buttonRadius() {
				return this.internal_config.button.borderRadius;
			},
			buttonBackground() {
				let bg = this.internal_config.button.backgroundColor;

				if(this.$globalStoreHelpers.isValidHex(bg)){
					bg = this.$globalStoreHelpers.hexToRGBA(bg);
				}

				if (this.internal_config.backgroundImage && this.internal_config.backgroundImage !== 'none') {
					bg += ' url("' + this.internal_config.button.backgroundImage + '")';
					bg += ' ' + this.internal_config.button.backgroundPosition;
					bg += ' / ' + this.internal_config.button.backgroundSize;
					bg += ' ' + this.internal_config.button.backgroundRepeat;
					bg += ' ' + this.internal_config.button.backgroundOrigin;
				}

				return bg;
			}

		},
		data() {
			return Data.data();
		},
		methods: {
            registerOnSocialProof() {

                try {

                    let campaignId = parseInt(this.internal_config.socialproof_campaign_id);
                    let userNameTAG = this.internal_config.socialproof_user_name_tag;

                    let inputUserName = this.$refs.form.querySelector(`[name="${userNameTAG}"]`);

                    if (campaignId && userNameTAG && inputUserName) {
                        var formData = new FormData();
                        formData.set('user_name', inputUserName.value);

                        axios({
                            'method': 'post',
                            'url': '/cheetah/social-proof/register/' + campaignId,
                            data: formData,
                        }).then((res) => {
                        });
                    }
                } catch (e) {
                    console.error(e);
                }
            },

			generateId(){
				return 1 + Math.floor((200000 - 1) * Math.random());
			},
			dataTag() {
				if (this.internal_config.tag) {
					return this.internal_config.tag;
				}
				return '';
			},

			getValidUrl(url = "") {
				let newUrl = url.trim().replace(/\s/g, "");
				newUrl = window.decodeURIComponent(newUrl);

				if (/^(:\/\/)/.test(newUrl)) {
					return `http${newUrl}`;
				}
				if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
					return `http://${newUrl}`;
				}

				return newUrl;
			},

			submit(e, config) {

				e.preventDefault()

				var kvpairs = [];
				let form = document.getElementById(this.idSimpleForm).querySelector('form');
                var inputs = form.querySelectorAll('.form-group, .input-group');
				let valid = true;
				let message = [];

				const inputsValidation = form.querySelectorAll('input');

				for (let i = 0; i < inputsValidation.length; i++) {
					const x = inputsValidation[i];
					
					if (x.getAttribute('validation') === 'fullname') {
						const regex = /^[a-zA-Z]{2,}\s+[a-zA-Z]{2,}(?:\s+[a-zA-Z]{2,})*$/;

						if (!regex.test(x.value)) {
							valid = false;
							message.push(this.$t('ct-please-enter-valid-fullname')+'.')
						}
					}
				}


                for (var i = 0; i < inputs.length; i++) {
                    var el = inputs[i].querySelector('input, select, textarea');
                    var checkFieldsRequired = inputs[i].hasAttribute('data-check-required');

                    if(!checkFieldsRequired) {
                        config.fields.forEach(field => {

							try {

								if(el.name.toLowerCase().indexOf('email') !== -1 && el.value !== ''){
									localStorage.setItem('leadEmail',el.value);
								}

								if(el.name.toLowerCase() === 'fname' && el.value !== ''){
									localStorage.setItem('leadName',el.value);
								}

								if(el.name.toLowerCase() === 'name' && el.value !== ''){
									localStorage.setItem('leadName',el.value);
								}

								if(el.name.toLowerCase() === 'fnome' && el.value !== ''){
									localStorage.setItem('leadName',el.value);
								}

								if(el.name.toLowerCase() === 'nome' && el.value !== ''){
									localStorage.setItem('leadName',el.value);
								}

								if(el.name.toLowerCase() === 'cadastro_nome' && el.value !== ''){
									localStorage.setItem('leadName',el.value);
								}

							} catch (error) {
								console.log(error)
							}

                        	if(el.name.toLowerCase().indexOf('email') !== -1 && el.value !== ''){
								localStorage.setItem('leadEmail',el.value);
                            }
                            if (field.tag === el.name && field.required === 'yes' && el.value === '') {
								valid = false;
								var isRequiredMessage = config.isRequiredMessage != '' ? config.isRequiredMessage : "is required";
                                message.push(field.label + " " + isRequiredMessage + ".")
                            }
                        });

                        kvpairs.push(encodeURIComponent(el.name) + "=" + encodeURIComponent(el.value));
                    } else {

                        let inputsCheck = inputs[i].querySelectorAll('[type=checkbox][data-check-field],[type=radio][data-check-field]');
                        let checked = 0;
                        let values = [];

                        if (inputsCheck.length > 0) {

                            inputsCheck.forEach(element => {
                                if (element.checked) {
                                    checked++;
                                    values.push(element.value);
                                }

                            });

                            let inputsCheckRequired = inputs[i].querySelectorAll('[type=checkbox][data-required=true],[type=radio][data-required=true]');

                            if (inputsCheckRequired.length > 0 && checked === 0) {

                                config.fields.forEach(field => {
                                    let name = field.field === 'checkbox' ? field.tag + '[]' : field.tag;

                                    if (name === el.name && field.required === 'yes' && checked === 0 && (field.field === 'radio' || field.field === 'checkbox')) {
                                        valid = false;
                                        var isRequiredMessage = config.isRequiredMessage !== '' ? config.isRequiredMessage : "is required";
                                        message.push(field.label + " " + isRequiredMessage + ".")
                                    }
                                });

                            }

                            if(values.length > 0) {
                                values.forEach(value => {
                                    kvpairs.push(encodeURIComponent(el.name) + "=" + encodeURIComponent(value));
                                });
                            }else{
                                kvpairs.push(encodeURIComponent(el.name) + "=");
                            }
                        }
                    }

				}

				var queryString = kvpairs.join("&");


				if (valid) {

					let action = this.internal_config.action;

					if (action !== '') {

						if (typeof __baCRMTracking !== 'undefined') {
							__baCRMTracking.onSubmit(e)
						}

						if(localStorage.getItem('MAILING_BOSS_TRACKING') && localStorage.getItem('leadEmail') != null && typeof MailingBossTracking != 'undefined'){
							console.log('mboss lead register');
                            var options = {
                                leadEmail: localStorage.getItem('leadEmail'),
                                reference: localStorage.getItem('MAILING_BOSS_REFERENCE')
                            };
                            MailingBossTracking.init(localStorage.getItem('MAILING_BOSS_TOKEN'), options);
                        }

						let vm = this;

						if (action.indexOf('app.mailingboss.com') > 0) {
							if (typeof config.redirect !== 'undefined' && config.redirect !== '') {
									if (action.indexOf("?") !== -1) {
										this.internal_config.action = action + '&redir=' + vm.getValidUrl(config.redirect);
									} else {
										this.internal_config.action = action + '?redir=' + vm.getValidUrl(config.redirect);
									}
									}
									setTimeout(function () {
										vm.registerOnSocialProof();
										vm.$refs.form.submit();
									}, 2000);
							} else {
								let recaptchaResponse = (document.getElementById('g-recaptcha-response') || {}).value;
								if (this.internal_config.recaptchaSiteKey && (recaptchaResponse === '' || recaptchaResponse === null)) {
									valid = false;
									message.push("unverified captcha");
							}

							if (!valid) {
								this.$root.$emit('errorMessagePopup', message);
								return;
							}

							vm.registerOnSocialProof();
							if (vm.internal_config.action.indexOf('https://office.builderall.com/br/office/create') > -1) {
								vm.iconLoading = true;
								let body = document.getElementsByTagName('body')[0]
								body.classList.add('loading-office-checkout');
							}


							if (
								typeof config.redirect !== 'undefined' &&
								config.redirect !== ''
								) {
								axios.post(vm.internal_config.action, queryString)
								.then( res => {
									window.location.href = vm.getValidUrl(config.redirect);
								} )

								setTimeout(function () {
									window.location.href = vm.getValidUrl(config.redirect);
								}, 2000);
							} else {
								this.$refs.form.submit();
							}


							}

					} else {

						if (config.receiver === '' || config.receiver === null) {
							valid = false;
							message.push("receiver not configured");
						}

						if (config.subject === '' || config.subject === null) {
							valid = false;
							message.push("subject not configured");
						}

						let recaptchaResponse = (document.getElementById('g-recaptcha-response') || {}).value;
						if (this.internal_config.recaptchaSiteKey && (recaptchaResponse === '' || recaptchaResponse === null)) {
							valid = false;
							message.push("unverified captcha");
						}

						if (valid) {

							if (typeof __baCRMTracking !== 'undefined') {
								__baCRMTracking.onSubmit(e)

							}

							if(this.internal_config.recaptchaSiteKey) {
								queryString = queryString + '&g-recaptcha-response=' + recaptchaResponse + '&id=' + this.id;
							}

							let vm = this;

							queryString = queryString + '&receiver=' + config.receiver + '&subject=' + config.subject;
							axios.post('/cheetah/form_submit', queryString)
								.then(res => {
                                    vm.registerOnSocialProof();

									vm.$root.$emit('successMessagePopup', config.successMessage);
									if (config.redirect !== '') {
										window.location.href = this.getValidUrl(config.redirect);
									}
								})
						}
					}


				}
				if (!valid) {
					// this.errorMessage = message;
					// this.$refs.required_fields.open()
					e.preventDefault();
					this.$root.$emit('errorMessagePopup', message);
				}

				// this.$refs.form.submit()
			},
			save() {
				this.buildFields(this)
				this.$refs.modal.close();
			},
			buildFields: function (vm) {

				this.$refs.container.innerHTML = '';

				vm.internal_config.fields.forEach(field => {
					let identifier = field.field;
					if (typeof identifier === 'undefined') {
						identifier = 'text';
					}


					if (identifier === 'textarea') vm.createTextField(field, true);
					if (identifier === 'text') vm.createTextField(field, false);
					if (identifier === 'password') vm.createTextField(field, false, true);
					if (identifier === 'multiselect') vm.createDropDown(field, true);
					if (identifier === 'select') vm.createDropDown(field, false);
					if (identifier === 'dropdown') vm.createDropDown(field, false);
					if (identifier === 'datetime') vm.createDateTime(field, true);
					if (identifier === 'date') vm.createDateTime(field, false);
					if (identifier === 'checkbox') vm.createCheckField(field, 'checkbox');
					if (identifier === 'radio') vm.createCheckField(field, 'radio');
				});
				$('body').removeClass('loading');
			},

			textStyle() {
        let sizeMobile = typeof this.internal_config.button.textStyle.sizeMobile !== 'undefined' && this.internal_config.button.textStyle.sizeMobile ?
            this.internal_config.button.textStyle.sizeMobile : false;
				return `font-family:  ${this.internal_config.button.textStyle.family};
                 font-size: ${(this.device === 'mobile' && sizeMobile) ? sizeMobile : this.internal_config.button.textStyle.size};
                 text-align: ${this.internal_config.button.textStyle.align};
                 font-weight: ${this.internal_config.button.textStyle.weight};
                 font-style: ${this.internal_config.button.textStyle.style};
                 color: ${this.internal_config.button.textStyle.color}`;
			},

			subTextStyle() {
        let sizeMobile = typeof this.internal_config.button.subTextStyle.sizeMobile !== 'undefined' && this.internal_config.button.subTextStyle.sizeMobile ?
            this.internal_config.button.subTextStyle.sizeMobile : false;
				return `font-family:  ${this.internal_config.button.subTextStyle.family};
                 font-size: ${(this.device === 'mobile' && sizeMobile) ? sizeMobile : this.internal_config.button.subTextStyle.size};
                 text-align: ${this.internal_config.button.subTextStyle.align};
                 font-weight: ${this.internal_config.button.subTextStyle.weight};
                 font-style: ${this.internal_config.button.subTextStyle.style};
                 color: ${this.internal_config.button.subTextStyle.color}`;
			}
			,
			buildIcon: function () {

				$(this.$el).find('i').detach();

				if (this.internal_config.button.icon !== '') {
					if (this.internal_config.button.iconPosition === 'left') {
						$('<i>').addClass('mdi ' + this.internal_config.button.icon).prependTo($(this.$el).find('.sb-text').get(0));
					}
					if (this.internal_config.button.iconPosition === 'right') {
						$('<i>').addClass('mdi ' + this.internal_config.button.icon).appendTo($(this.$el).find('.sb-text').get(0));
					}
				}
			}
			,
			build(config) {
				this.internal_config = config;

				this.buildIcon();
			}
			,
			style() {
				return `left:  ${this.internal_config.left};
				        padding-top: ${this.internal_config.paddingTop};
                     z-index: ${this.internal_config.zIndex};max-width: ${this.internal_config.maxWidth};margin: 0 auto;
                padding-left: ${this.internal_config.paddingLeft};
                padding-right: ${this.internal_config.paddingRight};
                padding-bottom: ${this.internal_config.paddingBottom};
                width: ${this.internal_config.width};
								top: ${this.internal_config.top};`;
			}
			,

			createCheckField(field, type) {
				let ComponentClass = Vue.extend(CheckFields);
				let instance = new ComponentClass({
					propsData: {
						type: type,
						showLabel: this.internal_config.showLabel,
						showPlaceholder: this.internal_config.showPlaceholder,
						label: field.label,
						options: field.options,
                        required: field.required,
						selected: [],
						data: field,
					}
				});
				instance.$mount();
				this.$refs.container.appendChild(instance.$el)
			},
			createDropDown(field, multiple) {
				let ComponentClass = Vue.extend(SelectFields);
				let instance = new ComponentClass({
					propsData: {
						label: field.label,
						options: field.options,
						selected: field.default_value,
						showLabel: this.internal_config.showLabel,
						showPlaceholder: this.internal_config.showPlaceholder,
						data: field,
						multiple: multiple,
					}
				});
				instance.$mount();
				this.$refs.container.appendChild(instance.$el)
			},
			createDateTime(field, useCurrent) {

				let ComponentClass = Vue.extend(DateFields);
				let instance = new ComponentClass({
					propsData: {
						label: field.label,
						data: field,
						showLabel: this.internal_config.showLabel,
						showPlaceholder: this.internal_config.showPlaceholder,
						value: new Date().toString(),
						type: (useCurrent ? 'datetime' : 'date'),
                        icon: field.icon
					}
				});
				instance.$mount();
				this.$refs.container.appendChild(instance.$el)
			},
			createTextField(field, area, pass) {


				let ComponentClass = Vue.extend(TextFields);
				let instance = new ComponentClass({
					propsData: {
						label: field.label,
						pass: pass,
						value: field.default_value,
						showLabel: this.internal_config.showLabel,
						showPlaceholder: this.internal_config.showPlaceholder,
						data: field,
						area: area,
                        icon: field.icon,
						validation: field.validation
					}
				});
				instance.$mount();


				this.$refs.container.appendChild(instance.$el)
			},
    	placeholderStyle() {
				const elements = this.$refs.container.getElementsByClassName(
					'form-control'
				);

				for (let element of elements) {
					element.style.setProperty('--placeholder-color', this.internal_config.placeholderColor || '#5c5c5c');
				}
    	},
			inputStyle() {

				let vm = this;

				let elements = this.$refs.container.getElementsByClassName('form-control');
				[].forEach.call(elements, function (el) {
					el.style.backgroundColor = vm.internal_config.inputBgColor;
					el.style.border = vm.internal_config.inputBorder;

                    if(el.tagName === 'TEXTAREA' && vm.internal_config.textareaHeight) {
                        el.style.height = vm.internal_config.textareaHeight;
                        el.classList.add('form-control-textarea-has-height');
                    } else {
                        el.style.height = vm.internal_config.inputHeight;
                    }
					el.style.borderRadius = vm.internal_config.inputRadius;

					el.style.fontFamily = vm.internal_config.inputStyle.family;
					el.style.fontSize = vm.internal_config.inputStyle.size;
					el.style.textAlign = vm.internal_config.inputStyle.align;
					el.style.fontStyle = vm.internal_config.inputStyle.style;
					el.style.fontStyle = vm.internal_config.inputStyle.style;
					el.style.color = vm.internal_config.inputStyle.color;
				});

				vm.$el.style.color = vm.internal_config.labelStyle;
			},

	  getCookie(cname) {
			var name = cname + "=";
			var decodedCookie = decodeURIComponent(document.cookie);
			var ca = decodedCookie.split(';');
			for (var i = 0; i < ca.length; i++) {
				var c = ca[i];
				while (c.charAt(0) == ' ') {
					c = c.substring(1);
				}
				if (c.indexOf(name) == 0) {
					return c.substring(name.length, c.length);
				}
			}
			return false;
		},

		checkGdpr() {

			try{
				if(window.gdprSettings && window.gdprSettings.active) {
					if(this.internal_config.recaptchaSiteKey) {
						return this.getCookie('cookieControl')
					}
				}
			}catch(e) {
				console.log(e)
			}


			return true;
		}
		},

    mounted() {

      let vm = this;

      this.build(this.$props.config);

      this.internal_config.button.subText = this.internal_config.button.subtext;

      Object.keys(this.props).forEach(prop => {
        if (typeof this.internal_config[prop] === 'undefined') {
          this.internal_config[prop] = this.props[prop];
        }
      });


      // if (this.$props.preview) {
      this.$root.$emit('applyAnimation', this, 'sitebuilder-element-container');

      this.$el.classList.add('preview');

      this.$el.addEventListener('mouseover', function () {

        if (vm.$globalStoreHelpers.isValidHex(vm.internal_config.button.backgroundColorHover)) {
          vm.internal_config.button.backgroundColorHover = vm.$globalStoreHelpers.hexToRGBA(vm.internal_config.button.backgroundColorHover);
        }
        if (vm.$globalStoreHelpers.isValidHex(vm.internal_config.button.textColorHover)) {
          vm.internal_config.button.textColorHover = vm.$globalStoreHelpers.hexToRGBA(vm.internal_config.button.textColorHover);
        }

        document.body.style.setProperty('--button-background-color--hover', vm.internal_config.button.backgroundColorHover);
        document.body.style.setProperty('--button-text-color--hover', vm.internal_config.button.textColorHover);
      });
      // }

      /**
       * configuração para listas do tipo ranking
       */
      let originUrl = new URL(window.location.href)
      let param = originUrl.searchParams.get('origin_uid');
      if (param !== null) {
        this.originUid = param
      }


      this.buildFields(this);

      this.inputStyle();
      this.placeholderStyle()

      this.setColumns();


      if (typeof this.internal_config.horizontal_config !== 'undefined') {
        var configMobile = (typeof this.internal_config.horizontal_config_mobile !== 'undefined') ? this.internal_config.horizontal_config_mobile : false;
        this.testAlan(this.internal_config.horizontal_config, configMobile)
      }

    },
  }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .sitebuilder-element-container .vdr,
    .vdr, .bt-sb,
    form.form-simple {
        height: auto !important;
    }

    .place-btn {
        text-align: center;

        .bt-sb {
            display: inline-block;
        }
    }

    .bt-text-area {
      display: block;

      .sb-text.disabled {
        pointer-events: none;
      }
    }

    [data-device="mobile"] .bt-text-area {
        width: auto !important;
    }

    .bt-sb:hover {
        cursor: pointer;
        background: var(--button-background-color--hover) !important;
        color: var(--button-text-color--hover) !important;
        -webkit-text-fill-color: var(--button-text-color--hover) !important;
    }

</style>
<style lang="scss">


label{
  max-width: 100%;
  word-wrap: break-word
}

    select.form-control[multiple], select.form-control[size],
    textarea.form-control:not(.form-control-textarea-has-height) {
        height: auto !important;
    }

  input.form-control, textarea.form-control {
    	&::placeholder {
      	color: var(--placeholder-color);
    	}
  	}



    .list-item {
        display: block;
        width: 100%;
        border: 1px solid lightgrey;
        border-radius: 5px;
        margin-top: 2px;
        cursor: pointer;
    }

    .list-item.selected {
        border: 1px solid blue;
    }

    .recaptcha-container {
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;
    }
</style>
