<template>
    <div :id="this.id"
         class="sitebuilder-element-container"
         element="LineVComponent"
         :data-tag="dataTag()"
         :data-hide-desktop="!this.internal_config.showDesktop"
         :data-hide-mobile="!this.internal_config.showMobile"
         :style="style()">

        <div class="resizable vdr" :style="{'transform': transform}">

            <div class="container-line-ver">
                <div class="arrow arrow-top" v-if="internal_config.arrowTop.active" :style="styleArrowTop()"></div>
                <div class=" sitebuilder-element"
                     v-bind:style="{'border-radius': radius, 'border-left' : border, 'box-shadow': boxShadow}"></div>
                <div class="arrow arrow-bottom" v-if="internal_config.arrowBottom.active"
                     :style="styleArrowBottom()"></div>
            </div>


        </div>
    </div>
</template>

<script>

	import Model from './Model';

	export default {
		name: 'LineVComponent',
		props: ['config', 'src', 'id', 'preview', 'device'],
		computed: {
			border() {
				if (typeof this.internal_config.border !== 'undefined') {
					let splitedBorder = this.internal_config.border.split(' ');

					if (typeof splitedBorder[2] !== 'undefined' && this.$globalStoreHelpers.isValidHex(splitedBorder[2])) {
						splitedBorder[2] = this.$globalStoreHelpers.hexToRGBA(splitedBorder[2]);
						this.internal_config.border = splitedBorder.join(' ');
					}
				}
				return this.internal_config.border;
			},
			boxShadow() {
				return this.internal_config.boxShadow;
			},
			radius() {
				return this.internal_config.borderRadius;
			},
      transform() {
        return `rotate(${this.$props.device === 'desktop' ? this.internal_config.rotation : 0}) scale(1)`;
      },
		},
		data() {
			return Model.data();
		},
		methods: {

			animation() {
				return true;
			},

			build(config) {
				this.internal_config = config;
			},
			style() {
				var style = `height: ${this.internal_config.height} !important;
                     z-index: ${this.internal_config.zIndex};max-width: ${this.internal_config.maxWidth};margin: 0 auto;
                     padding-top: ${this.internal_config.paddingTop};
                     padding-left: ${this.internal_config.paddingLeft};
                     padding-right: ${this.internal_config.paddingRight};
                     padding-bottom: ${this.internal_config.paddingBottom};
                     left:  ${this.internal_config.left};
										 top: ${this.internal_config.top};`;
				return style;
			},
			styleArrowTop() {

				var style = `border-left-width: ${this.internal_config.arrowTop.size}px;
                     border-right-width: ${this.internal_config.arrowTop.size}px;
                     left: calc(50% - ${this.internal_config.arrowTop.size}px);
                     border-bottom-width: ${this.internal_config.arrowTop.size}px;
                     top: -${this.internal_config.arrowTop.size}px;
                     border-bottom-color: ${this.internal_config.arrowTop.color};`;
				return style;
			},
			styleArrowBottom() {

				var style = `border-left-width: ${this.internal_config.arrowBottom.size}px;
                     border-right-width: ${this.internal_config.arrowBottom.size}px;
                     left: calc(50% - ${this.internal_config.arrowBottom.size}px);
                     border-top-width: ${this.internal_config.arrowBottom.size}px;
                     border-top-color: ${this.internal_config.arrowBottom.color};`;
				return style;
			},
			dataTag() {
				if (this.internal_config.tag) {
					return this.internal_config.tag;
				}
				return '';
			},
		},
		mounted() {

			this.build(this.$props.config);

			this.internal_config = window.mergeRecursive(this.props, this.$props.config);

			this.$root.$emit('applyAnimation', this, 'sitebuilder-element-container');


		}
	}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    [data-device="desktop"] [element="LineVComponent"] .vdr {
        padding-right: 15px !important;
        padding-left: 15px !important;
        height: inherit !important;
    }

    [element="LineVComponent"] .vdr {
        height: 100% !important;
    }

    .container-line-ver {
        position: relative;
        width: 100%;
        height: 100%;

        .sitebuilder-element {
            width: 1px;
            height: 100%;

        }
    }

    .arrow {
        position: absolute;
        width: 0;
        height: 0;
    }

    .arrow-top {
        border-left-color: transparent;
        border-left-style: solid;
        border-right-color: transparent;
        border-right-style: solid;

        border-bottom-style: solid;
        right: 0;
    }

    .arrow-bottom {
        border-left-color: transparent;
        border-left-style: solid;
        border-right-color: transparent;
        border-right-style: solid;

        border-top-style: solid;
    }

</style>
<style scoped lang="scss">

    .resizable.vdr {
        height: 100% !important;
        width: auto !important;
        left: 0 !important;
        top: 0 !important;

    }
</style>
