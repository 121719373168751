export default {
    data() {
      return {
        props: {
          'name':'SocialBar',
          'width': '50px',
          'height': '50px',
          'zIndex': 'auto','maxWidth':'768px',
          'rotation': '0deg',
          'border': '0 none #000000',
          'boxShadow': '0 0 0 0 black initial',
          'borderRadius': '0 0 0 0',
          'paddingTop': '0px',
          'paddingLeft': '15px',
          'paddingRight': '15px',
          'paddingBottom': '15px',

          'orientation': true,

          'imageFacebook':'https://storage.builderall.com//public-image/new/Icons/Social_Network/social_00037.png',
          'imageTwitter':'https://storage.builderall.com//public-image/new/Icons/Social_Network/social_00065.png',
          'imageInstagram':'https://storage.builderall.com//public-image/new/Icons/Social_Network/social_00045.png',
          'imageLinkedin':'https://storage.builderall.com//public-image/new/Icons/Social_Network/social_00049.png',
          'spaceBetween': 0,

          'sbNewAba':'',

          'sbFacebook':'',
          'checkFacebook':true,

          'sbTwitter':'',
          'checkTwitter':true,

          'sbInstagram':'',
          'checkInstagram': true,

          'sbLinkedin':'',
          'checkLinkedin':'',


          'animation': {
            'in': {
              'action': 'time',   //time/scroll/click/hover
              'time': 0,       // sec
              'scroll': 0,     // px
              'effect': 'none',
              'speed': 'slow',
              'infinite': false,
            },
            'out': {
              'action': 'time',   //time/scroll/click
              'time': 0,       // sec
              'scroll': 0,     // px
              'effect': 'none',
              'delay': '1',
              'speed': '1',
              'infinite': false,
            },
          },
        },
        elements: [],
        internal_config: {
          'width': '50px',
          'height': '50px',
          'zIndex': 'auto','maxWidth':'768px',
          'rotation': '0deg',
          'border': '0 none #000000',
          'boxShadow': '0 0 0 0 black initial',
          'borderRadius': '0 0 0 0',
          'paddingTop': '15px',
          'paddingLeft': '15px',
          'paddingRight': '15px',
          'paddingBottom': '15px',
  

          'imageFacebook':'https://storage.builderall.com//public-image/new/Icons/Social_Network/social_00037.png', 
          'imageTwitter':'https://storage.builderall.com//public-image/new/Icons/Social_Network/social_00065.png', 
          'imageInstagram':'https://storage.builderall.com//public-image/new/Icons/Social_Network/social_00045.png', 
          'imageLinkedin':'https://storage.builderall.com//public-image/new/Icons/Social_Network/social_00049.png', 

          'sbNewAba':'',
  
          'sbFacebook':'',
          'checkFacebook':true,

          'sbTwitter':'',
          'checkTwitter': true,

          'sbInstagram':'',
          'checkInstagram': true,

          'sbLinkedin':'',
          'checkLinkedin':'',

          'animation': {
            'in': {
              'action': 'time',   //time/scroll/click/hover
              'time': 0,       // sec
              'scroll': 0,     // px
              'effect': 'none',
              'speed': 'slow',
              'infinite': false,
            },
            'out': {
              'action': 'time',   //time/scroll/click
              'time': 0,       // sec
              'scroll': 0,     // px
              'effect': 'none',
              'delay': '1',
              'speed': '1',
              'infinite': false,
            },
          },
        }
      }
    }
  }
  