export default {
    data() {
        return {
            name: 'ContentNavUser',
            typeAuth: false,
            props: {
                activeTab: false, //only publish
                'tag': '',
                'height': '50px',
                'width': '150px',
                'zIndex': 'auto',
                'maxWidth': '500px',

                showDesktop: true,
                showMobile: true,

                visibleTabs: ['profile', 'areas'], // home

                htmlTabHome: '',

                iconsEnable: '0',

                tabNav: {

                    'border': '0 none #000000',
                    'borderRadius': '3px 3px 3px 3px',
                    'boxShadow': '0 0 0 0 black initial',

                    backgroundTabColor: 'transparent',
                    backgroundTabActiveColor: '#007bff',
                    backgroundTabHoverColor: '#dedede',

                    'textColorTabActive': '#FFFFFF',
                    'textColorHover': '#FFFFFF',

                    'textStyle': {
                        'family': 'arial, helvetica, sans-serif',
                        'size': '15px',
                        'sizeMobile': '15px',
                        'color': '#007bff',
                        'align': 'center',
                        'weight': '700',
                        'style': 'initial',
                        'stroke': 'none'
                    }
                },

                tabContent: {
                    'border': '0 none #000000',
                    'borderRadius': '0 0 0 0',
                    'boxShadow': '0 0 0 0 black initial',

                    'backgroundColor': '#F5F5F5',

                    'textStyle': {
                        'family': 'arial, helvetica, sans-serif',
                        'size': '15px',
                        'sizeMobile': '15px',
                        'color': 'black',
                        'align': 'center',
                        'weight': '700',
                        'style': 'initial',
                        'stroke': 'none'
                    },
                },

                'paddingTop': '15px',
                'paddingLeft': '15px',
                'paddingRight': '15px',
                'paddingBottom': '15px',

                'animation': {
                    'in': {
                        'action': 'time', // time/scroll/click/hover
                        'time': 0, // sec
                        'scroll': 0, // px
                        'effect': 'none',
                        'speed': 'slow',
                        'infinite': false
                    },
                    'out': {
                        'action': 'time', // time/scroll/click
                        'time': 0, // sec
                        'scroll': 0, // px
                        'effect': 'none',
                        'delay': '1',
                        'speed': '1',
                        'infinite': false
                    }
                },
            },
            internal_config: {
                width: '150px',
                height: '50px',
                left: '0px',
                top: '0px',
                opacity: 1,
            }
        };
    }

};
