var _translations;
import { languages } from './translation/index';

class Translate{
  constructor() {
    this.translations = {};
    this.lang = '';
  }

  init(language = 'en_US', callback) {
    this.lang = language;

    if(languages[language])
      _translations = languages[language];
    else if(languages['en_US'])
      _translations = languages['en_US'];
  }

  get(key, isEmpty = '') {

    if (
        typeof _translations !== 'undefined' &&
        typeof _translations[key] !== 'undefined'
    ) {
      return _translations[key];
    }

    if (languages['en_US'] && languages['en_US'][key]) { return languages['en_US'][key] }

    return isEmpty || key;
  }
}

export default Translate;
