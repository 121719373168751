var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sitebuilder-element-container",style:(_vm.style()),attrs:{"id":this.id,"element":"SliderGalleryComponent","data-hide-desktop":!this.internal_config.showDesktop,"data-hide-mobile":!this.internal_config.showMobile},on:{"contextmenu":function($event){$event.preventDefault();return _vm.handler($event)}}},[(_vm.$isDeviceIOS())?_c('div',{staticClass:"slider-gallery-ios-helper"},[_c('div',{staticClass:"slider-navigation-helper"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.arrowOptions),expression:"arrowOptions"}],staticClass:"slider-navigation"},[_c('div',{staticClass:"mdi mdi-arrow-left-circle",style:(_vm.styleArrow()),on:{"click":_vm.prevSlider}}),_c('div',{staticClass:"mdi mdi-arrow-right-circle",style:(_vm.styleArrow()),on:{"click":_vm.nextSlider}})])]),_c('div',{staticClass:"slider-gallery-helper"},[_c('transition-group',{ref:"slider-gallery",staticClass:"slider-gallery-ios",style:({
      opacity: _vm.opacity,
      'border-radius': _vm.radius,
      border : _vm.border,
      'box-shadow': _vm.boxShadow,
      minHeight : _vm.minHeight,
      'transform': _vm.transform
      }),attrs:{"tag":"div","name":"slider-gallery-ios","duration":{ enter: 1000, leave: 1000 }}},_vm._l((_vm.internal_config.images),function(image,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.internal_config.currentIndex === index),expression:"internal_config.currentIndex === index"}],key:index,class:("slider-image-" + index),style:({ backgroundImage: ("url('" + image + "')") })})}),0)],1)]):[_c('div',{ref:"slider-gallery",staticClass:"slider-gallery",style:({
      opacity: _vm.opacity,
      'border-radius': _vm.radius,
      border : _vm.border,
      'box-shadow': _vm.boxShadow,
      minHeight : _vm.minHeight,
      'transform': _vm.transform
      })},_vm._l((_vm.internal_config.images),function(image,index){return _c('div',{key:index,class:("slider-image-" + index),style:({ backgroundImage: ("url('" + image + "')") })})}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.arrowOptions),expression:"arrowOptions"}],staticClass:"slider-navigation"},[_c('div',{staticClass:"mdi mdi-arrow-left-circle",style:(_vm.styleArrow()),on:{"click":_vm.prevSlider}}),_c('div',{staticClass:"mdi mdi-arrow-right-circle",style:(_vm.styleArrow()),on:{"click":_vm.nextSlider}})])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }