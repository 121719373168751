<template>
    <form @submit.prevent="rememberPassword" method="POST" autocomplete="off">
        <div class="alert alert-success" v-if="errors.has('alert_success')">
            <p class="mb-0 clean-text-css">{{ errors.get('alert_success') }}</p>
        </div>

        <div class="alert alert-danger" v-if="errors.has('alert')">
            <p class="mb-0 clean-text-css">{{ errors.get('alert') }}</p>
        </div>

        <div class="form-group">
            <input
                    type="email"
                    id="email"
                    class="form-control form-control-alternative"
                    v-model="user.email"
                    :class="{'is-invalid': errors.has('email') }"
                    :placeholder="$t('ct-email')"
                    @keydown="errors.clear('email')"
                    required
            />
            <span v-if="errors.has('email')" class="invalid-feedback" v-text="errors.get('email')"></span>
        </div>

        <div class="form-group text-center">
            <button type="submit" class="btn btn-success text-center">{{ $t('ct-confirm') }}</button>
        </div>
    </form>
</template>

<script>
    import Errors from "../../../../js/Errors";
    import {forgot} from "../../../../js/WebsiteUser";
    import {mixinRestrictArea} from "../../../../js/mixins/restrictArea";

    export default {
        name: "RememberPassword",
        mixins: [mixinRestrictArea],
        data() {
            return {
                errors: new Errors(),
                user: {
                    email: '',
                },
            }
        },

        methods: {

            changeType(active) {
                this.$emit('change', active)
            },

            rememberPassword() {

                this.$loading.show();
                this.user.layout_id = this.getCurrentPage().layout_id;

                this.user.url = `${window.origin}`;

                forgot(this.user)
                    .then((res) => {
                        this.$loading.hide();
                        if (res.data.success) {
                            this.user = {
                                email: ''
                            };
                            this.errors.record({alert_success: [this.$t('ct-remember-true')]});
                            this.$loading.hide();

                            let callRedirect = (typeof this.$parent.$parent.$parent.callRedirect === 'function') ? this.$parent.$parent.$parent.callRedirect : this.$parent.$parent.callRedirect;

                            callRedirect(false);
                        } else {
                            if (res.data.validator) {
                                this.errors.record(res.data.errors);
                            }
                        }
                    })
                    .catch(error => {
                        console.warn(error);
                        this.errors.record({alert: [this.$t('ct-remember-error')]});
                    })
                    .finally(() => this.$loading.hide());
            }
        }
    }
</script>