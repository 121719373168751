<template>

  <div>
    <div v-for="post in options.posts" :key="post.number" class="blog-card"
         :style="{'background-color': options.cardBgColor}"
         :class="{ 'alt' : (options.theme == 3 && post.number % 2 == 0) || options.theme == 2 }">
      <div class="meta" :style="{'background-color': options.cardBgColor}">
        <div  class="photo" :class="{'gray-scale' : !post.readEnable}" :style="{'background-image': 'url(' +  post.thumbnail + ')', 'background-color': options.cardBgColor}"></div>
        <ul class="details">
          <li class="author"><a :href="'/a/' + post.author.slug">{{ post.author.name }}</a></li>
          <li class="date"> {{ post.date_show }}</li>
          <li class="tags">
            <ul>
              <li v-for="category in post.categories" :key="category.id"><a :href="'/c/' + category.slug">{{ category.name }}</a></li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="description"  :style="{'background-color': options.cardBgColor}">

        <h1 class="clean-text-css desktop-element" :style="options.titleStyle"> {{ post.title }}</h1>
        <h1 class="clean-text-css mobile-element" :style="options.titleStyleMobile"> {{ post.title }}</h1>

        <p class="clean-text-css desktop-element" :style="options.contentStyle" v-html="post.content.substr(0, 250).toLowerCase()">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad eum dolorum architecto obcaecati enim dicta
          praesentium, quam nobis! Neque ad aliquam facilis numquam. Veritatis, sit.
        </p>
        <p class="clean-text-css mobile-element" :style="options.contentStyleMobile" v-html="post.content.substr(0, 250).toLowerCase()">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad eum dolorum architecto obcaecati enim dicta
            praesentium, quam nobis! Neque ad aliquam facilis numquam. Veritatis, sit.
        </p>
        <p v-if="post.readEnable"  class="read-more" >
          <a :style="{color: options.colorReadMore }" :href="'/' + post.url">{{ options.textReadMore }}</a>
        </p>
      </div>
    </div>
  </div>

</template>

<script>

  export default {
    name: 'FeedNewsTheme1',
    props: ['options'],
    methods: {
      altMethod() {

        if (this.options.theme == 2) {
          return true
        }

        if (this.options.theme == 3) {

          if (typeof data !== 'undefined' && data.number % 2 == 0) {
            return true;
          }
        }

        return false;

      },


    },

    created() {
      let root = document.documentElement;
      root.style.setProperty('--deailt-color', this.colorDetails);
      root.style.setProperty('--btn-theme-grid-color-readmorehover', this.options.colorReadMoreHover);

    },
    watch: {
      options() {
        let root = document.documentElement;
        root.style.setProperty('--deailt-color', this.options.colorDetails);
        root.style.setProperty('--btn-theme-grid-color-readmorehover', this.options.colorReadMoreHover);
      }
    },
  }
</script>

<style scoped>


  .gray-scale{
    filter: grayscale(100%);
  }

  /*PEN STYLES*/
  * {
    box-sizing: border-box;
  }

  body {
    background: #f1f1f1;
    margin: 2rem;
  }

  .blog-card {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    margin: 1rem auto;
    box-shadow: 0 3px 7px -1px rgba(0, 0, 0, 0.1);
    margin-bottom: 1.6%;
    background: #fff;
    line-height: 1.4;
    font-family: sans-serif;
    border-radius: 5px;
    overflow: hidden;
    z-index: 0;
  }

  .blog-card a {
    color: inherit;
  }

  .blog-card a:hover {
    color: #5ad67d;
  }

  .blog-card:hover .photo {
    -webkit-transform: scale(1.3) rotate(3deg);
    transform: scale(1.3) rotate(3deg);
  }

  .blog-card .meta {
    position: relative;
    z-index: 0;
    height: 200px;
  }

  .blog-card .photo {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    -webkit-transition: -webkit-transform 0.2s;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
  }

  .blog-card .details,
  .blog-card .details ul {
    margin: auto;
    padding: 0;
    list-style: none;
  }

  .blog-card .details {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -100%;
    margin: auto;
    -webkit-transition: left 0.2s;
    transition: left 0.2s;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    padding: 10px;
    width: 100%;
    font-size: 0.9rem;
  }

  .blog-card .details a {
    -webkit-text-decoration: dotted underline;
    text-decoration: dotted underline;
  }

  .blog-card .details ul li {
    display: inline-block;
  }

  /* .blog-card .details .author:before {
    font-family: FontAwesome;
    margin-right: 10px;
    content: "\f007";
  }
  .blog-card .details .date:before {
    font-family: FontAwesome;
    margin-right: 10px;
    content: "\f133";
  }
  .blog-card .details .tags ul:before {
    font-family: FontAwesome;
    content: "\f02b";
    margin-right: 10px;
  } */
  .blog-card .details .tags li {
    margin-right: 2px;
  }

  .blog-card .details .tags li:first-child {
    margin-left: -4px;
  }

  .blog-card .description {
    padding: 1rem;
    background: #fff;
    position: relative;
    z-index: 1;
  }

  .blog-card .description h1,
  .blog-card .description h2 {
    font-family: Poppins, sans-serif;
  }

  .blog-card .description h1 {
    line-height: 1;
    margin: 0;
    font-size: 1.7rem;
  }

  .blog-card .description h2 {
    font-size: 1rem;
    font-weight: 300;
    text-transform: uppercase;
    color: #a2a2a2;
    margin-top: 5px;
  }

  .blog-card .description .read-more {
    text-align: right;
  }

  .blog-card .description .read-more a {
    color: #5ad67d;
    display: inline-block;
    position: relative;
  }

  .blog-card .description .read-more:hover a{
    color: var(--btn-theme-grid-color-readmorehover) !important;
  }

  .blog-card .description .read-more a:after {
    content: "\F055";
    font-family: "Material Design Icons";
    margin-left: -10px;
    opacity: 0;
    vertical-align: middle;
    -webkit-transition: margin 0.3s, opacity 0.3s;
    transition: margin 0.3s, opacity 0.3s;
  }

  .blog-card .description .read-more a:hover:after {
    margin-left: 5px;
    opacity: 1;
  }

  .blog-card p {
    position: relative;
    margin: 1rem 0 0;
  }

  .blog-card p:first-of-type {
    margin-top: 1.25rem;
  }

  .blog-card p:first-of-type:before {
    content: "";
    position: absolute;
    height: 5px;
    background: var(--my-color-var);
    width: 35px;
    top: -0.75rem;
    border-radius: 3px;
  }

  .blog-card:hover .details {
    left: 0%;
  }

  @media (min-width: 640px) {
    .blog-card {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-direction: row;
      /* max-width: 900px; */
    }

    .blog-card .meta {
      flex-basis: 40%;
      height: auto;
    }

    .blog-card .description {
      flex-basis: 60%;
    }

    .blog-card .description:before {
      -webkit-transform: skewX(-3deg);
      transform: skewX(-3deg);
      content: "";
      background: var(--deailt-color);
      width: 30px;
      position: absolute;
      left: -10px;
      top: 0;
      bottom: 0;
      z-index: -1;
    }

    .blog-card.alt {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      flex-direction: row-reverse;
    }

    .blog-card.alt .description:before {
      left: inherit;
      right: -10px;
      -webkit-transform: skew(3deg);
      transform: skew(3deg);
    }

    .blog-card.alt .details {
      padding-left: 25px;
    }
  }


</style>
