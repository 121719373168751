import moment from "moment";

export const mixinBlogComponents = {
    methods: {
        showUrl(url) {
            const vm = this;

            if (vm.article.date_cycle === 'Scheduled' && moment(new Date(vm.article.scheduled_date)).diff(new Date()) > 0) {
                return '#'
            }

            if (typeof vm.article.date_cycle !== 'undefined' &&
                vm.article.date_cycle === 'Instantaneous' || 'Scheduled' &&
                window.location.hash.indexOf('#/editor/') === -1) {
                return `${url}`;
            } else {
                return '#';
            }
        },

        formatDate(date, format) {
            const locale = window.navigator.userLanguage || window.navigator.language;
            moment.locale(locale)

            return moment.utc(String(date)).local().format(format);
        },
    }
};
