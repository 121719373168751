<template>
  <div
    :id="this.id"
    @contextmenu.prevent="handler($event)"
    class="sitebuilder-element-container"
    element="TimerComponent"
    :data-tag="dataTag()"
    :data-hide-desktop="!this.config.showDesktop"
    :data-hide-mobile="!this.config.showMobile"
    :style="style()"
  >
    <div :style="`transform: rotate(${this.$props.device === 'desktop' ? this.internal_config.rotation : 0}) scale(1)`">
      <flip-clock v-if="this.internal_config.theme === 'panels'" :preview="preview" :config="this.internal_config" :device="$props.device"  @updateData="updateData" />
      <time-circles ref="timecirlces" v-else-if="this.internal_config.theme === 'circles'" @updateData="updateData" @setCallbackChangeDevice="setCallbackChangeDevice" :device="$props.device" :preview="preview" :options="this.internal_config.options" :config="this.internal_config" />
      <time-show ref="timeshow" v-else :preview="preview" @updateData="updateData" @setCallbackChangeDevice="setCallbackChangeDevice" :options="this.internal_config.options" :config="this.internal_config" />
    </div>
  </div>
</template>

<script>
  import Data from './Model';
  import ApplyConfig from './ApplyConfig';
  import FlipClock from './FlipClock/FlipClock.vue';
  import TimeCircles from './TimeCircles/TimeCircles';
  import TimeShow from './TimeShow/TimeShow';

  var oneTime = false;

  export default {
    name: 'TimerComponent',
    props: ['config', 'id', 'preview', 'device'],
    components: {
      Data,
      FlipClock,
      TimeCircles,
      TimeShow
    },
    computed: {},
    data() {
      return Data.data();
    },

    methods: {
      /**
       * Default actions - Begin
       */
      animation() {
        return true;
      },
      dataTag(){
        if (this.internal_config.tag) {
          return this.internal_config.tag;
        }
        return '';
      },
      centerElement: function() {
        let element = document.getElementById(this.id);
        let parentNode = document.getElementById(this.id).parentNode;

        let newLeft = parentNode.offsetWidth / 2 - parseInt(element.scrollWidth) / 2 + 'px';

        this.$el.style.left = newLeft;
        this.internal_config.left = newLeft;

      },

      linkElement: function() {
        this.$root.$emit('linkComponentShow', this);
      },

      onResize: function(x, y, width, height) {
        this.internal_config.width = width + 'px';
        this.internal_config.height = height + 'px';

      },

      toggle: function() {
        this.$root.$emit('toggleElement', this);
      },

      deleteElement() {
        this.$root.$emit('deleteElement', this);
      },

      duplicateElement() {
        this.$root.$emit('duplicateElement', this, this.internal_config);
      },

      focusElement(e) {
        this.$root.$emit('focusElement', this);
        this.$root.$emit('handlerElement', this);

        if (this.$el.getBoundingClientRect().top < 75) {
          this.$refs.defaultOptions.classList.add('top-option');
        } else {
          this.$refs.defaultOptions.classList.remove("top-option");
        }
        e.stopPropagation();
        e.preventDefault();
      },

      showResources() {
      },

      callReorder(idx) {
        if (typeof this.$parent.changeOrderPanel !== 'undefined') {
          this.$parent.changeOrderPanel(this, idx);
        } else {
          this.$parent.$parent.changeOrderPanel(this, idx);
        }
      },

      build(config) {
        this.internal_config = {...this.internal_config, ...config};
      },

      style() {
        return `left:  ${this.internal_config.left};
              z-index: ${this.internal_config.zIndex};
              max-width: ${this.internal_config.maxWidth};
              margin: 0 auto;
              top: ${this.internal_config.top};`;
      },

      handler: function(e) {
        this.$root.$emit('focusElement', this);
        this.$root.$emit('handlerElement', this);
        e.stopPropagation();
        e.preventDefault();
      },

      saveElementDefault() {
        this.$root.$emit('saveElement', this);
      },

      setCallbackChangeDevice(values){
        this.$emit('setCallbackChangeDevice', values);
      },
      /**
       * Default actions - End
       */

      updateData(){
        if(!oneTime) {
          let applyConfig = new ApplyConfig(this);
          this.internal_config = applyConfig.treatment();

          oneTime = true;
        }
      }
    },
    mounted() {
      this.build(this.$props.config);

      var isEditor = document.querySelectorAll('.panel-components-right').length > 0;

      // if(!oneTime){

        let applyConfig = new ApplyConfig(this);
        this.internal_config = applyConfig.treatment();

        oneTime = true;

        if(!isEditor){

            let values = applyConfig.build();
            this.internal_config.settings = {...this.internal_config.settings, ...values };
          }
      // }

      Object.keys(this.props).forEach(prop => {
        if (typeof this.internal_config[prop] === 'undefined') {
          this.internal_config[prop] = this.props[prop];
        }
      });

      this.$nextTick(() => {
        this.$root.$emit('applyAnimation', this, 'sitebuilder-element-container');
      });
    },
  };
</script>

<style scoped lang="scss" scoped>
  .flip-clock-wrapper ul li a div div.inn{
    background: #333 !important;
  }

  [element=TimerComponent].widget-focused {
    outline: 2px solid #007bff !important;

    .default-options{
      display: inline-flex;
      position: absolute;
      top: -26px;
      font-size: 12px;
      color: #007bff;
      padding: 3px;
      background-color: white;
      border: 1px solid blue;
      z-index: 12;

      .font-awesome-icon {
        padding-left: 5px;
      }
    }
  }
</style>
