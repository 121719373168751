<template>
    <form @submit.prevent="loginToken" method="POST" autocomplete="off">
        <div class="alert alert-success" v-if="errors.has('alert_success')">
            <p class="mb-0 clean-text-css">{{ errors.get('alert_success') }}</p>
        </div>

        <div class="alert alert-danger" v-if="errors.has('alert')">
            <p class="mb-0 clean-text-css">{{ errors.get('alert') }}</p>
        </div>

        <div class="form-group">
            <input
                    type="token"
                    id="token"
                    class="form-control form-control-alternative"
                    v-model="user.token"
                    :class="{'is-invalid': errors.has('token') }"
                    :placeholder="$t('ct-token')"
                    @keydown="errors.clear('token')"
                    required
            />
            <span v-if="errors.has('token')" class="invalid-feedback" v-text="errors.get('token')"></span>
        </div>

        <div class="form-group text-center">
            <button type="submit" class="btn btn-success text-center">{{ $t('ct-login') }}</button>
        </div>
    </form>
</template>

<script>
    import Errors from "../../../../js/Errors";
    import {loginToken} from "../../../../js/WebsiteUser";
    import {mixinRestrictArea} from "../../../../js/mixins/restrictArea";

    export default {
        name: "LoginToken",
        mixins: [mixinRestrictArea],

        data() {
            return {
                errors: new Errors(),
                user: {
                    token: '',
                },
            }
        },

        methods: {

            changeType(active) {
                this.$emit('change', active)
            },

            loginToken() {
                this.$loading.show();
                this.user.layout_id = this.getCurrentPage().layout_id;
                this.errors.clear();

                loginToken(this.user)
                    .then((res) => {

                        if (res.data.success) {
                            let RestrictAreaVue = (typeof this.$parent.$parent.setLogin === 'function') ? this.$parent.$parent : this.$parent.$parent.$parent;
                            RestrictAreaVue.setLogin(res.data.data);
                        } else {
                            if (res.data.validator) {
                                this.errors.record(res.data.errors);
                            }
                        }
                    })
                    .catch(error => {
                        this.errors.record({alert: [this.$t('ct-login-error')]});
                    })
                    .finally(() => this.$loading.hide());
            }
        }
    }
</script>
