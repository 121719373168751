<template>
    <div :id="this.id"
         class="sitebuilder-element-container"
         element="AccordionGroupComponent"
         :data-hide-desktop="!this.internal_config.showDesktop"
         :data-hide-mobile="!this.internal_config.showMobile"
         :style="style()">

        <div class="vdr">

            <div class="container-accordion-group" v-bind:style="{
        borderRadius: radius,

        background: backgroundColor,
        backgroundImage: backgroundImage,
        backgroundSize: backgroundSize,
        backgroundPosition: backgroundPosition,
        backgroundOrigin: backgroundOrigin,
        backgroundRepeat: backgroundRepeat,

        border : border,
        boxShadow: boxShadow,
        paddingTop : paddingTop,
        paddingLeft : paddingLeft,
        paddingRight : paddingRight,
        paddingBottom : paddingBottom,
        }">
                <component v-for="(element,index) in elements" :is="element.name" :config="element.config" :key="index"
                           :device="device"
                           :is_popup="is_popup"
                           :id="element.id" :height_start="index" :preview="preview"
                           :childs="element.childs"></component>
            </div>
        </div>


    </div>
</template>

<script>

	import Data from './Model';

	export default {
		name: 'AccordionGroupComponent',
		props: ['config', 'childs', 'height_start', 'device', 'id', 'preview', 'is_popup'],
		components: {
			Data,
		},
		computed: {
			paddingTop() {
				return this.internal_config.paddingTopContainer;
			},
			paddingLeft() {
				return this.internal_config.paddingLeftContainer;
			},
			paddingRight() {
				return this.internal_config.paddingRightContainer;
			},
			paddingBottom() {
				return this.internal_config.paddingBottomContainer;
			},
			boxShadow() {
				return this.internal_config.boxShadow;
			},
			border() {
				if (typeof this.internal_config.border !== 'undefined') {
					let splitedBorder = this.internal_config.border.split(' ');

					if (typeof splitedBorder[2] !== 'undefined' && this.$globalStoreHelpers.isValidHex(splitedBorder[2])) {
						splitedBorder[2] = this.$globalStoreHelpers.hexToRGBA(splitedBorder[2]);
						this.internal_config.border = splitedBorder.join(' ');
					}
				}
				return this.internal_config.border;
			},
			backgroundColor() {

				if (this.$globalStoreHelpers.isValidHex(this.internal_config.backgroundColor)) {
					this.internal_config.backgroundColor = this.$globalStoreHelpers.hexToRGBA(this.internal_config.backgroundColor);
				}

				return this.internal_config.backgroundColor;
			},
			backgroundImage() {
				if (this.internal_config.backgroundImage && this.internal_config.backgroundImage !== 'none') {
					this.internal_config.backgroundImage = this.internal_config.backgroundImage.replace('omb11.com', 'storage.builderall.com');
					return 'url("' + this.internal_config.backgroundImage + '")';
				}
				return '';
			},
			backgroundPosition() {
				if (this.internal_config.backgroundImage && this.internal_config.backgroundImage !== 'none') {
					return this.internal_config.backgroundPosition
				}
				return '';
			},
			backgroundOrigin() {
				if (this.internal_config.backgroundImage && this.internal_config.backgroundImage !== 'none') {
					return this.internal_config.backgroundOrigin
				}
				return '';
			},
			backgroundRepeat() {
				if (this.internal_config.backgroundImage && this.internal_config.backgroundImage !== 'none') {
					return this.internal_config.backgroundRepeat
				}
				return '';
			},
			backgroundSize() {
				if (this.internal_config.backgroundImage && this.internal_config.backgroundImage !== 'none') {
					return this.internal_config.backgroundSize
				}
				return '';
			},
			radius() {
				return this.internal_config.borderRadius;
			},
// bg-color bg-image position/bg-size bg-repeat bg-origin
			background() {
				let bg = this.internal_config.backgroundColor;

				if (this.internal_config.backgroundImage && this.internal_config.backgroundImage !== 'none') {

					this.internal_config.backgroundImage = this.internal_config.backgroundImage.replace('omb11.com', 'storage.builderall.com');

					bg += ' url("' + this.internal_config.backgroundImage + '")';
					bg += ' ' + this.internal_config.backgroundPosition;
					bg += ' / ' + this.internal_config.backgroundSize;
					bg += ' ' + this.internal_config.backgroundRepeat;
					bg += ' ' + this.internal_config.backgroundOrigin;
				}

				return bg;
			},

		},
		data() {
			return Data.data();
		},
		methods: {
			animation() {
				return false;
			},
			style() {
				return `left:  ${this.internal_config.left};
                        top: ${this.internal_config.top};
                        padding-top: ${this.internal_config.paddingTop};
                        padding-left: ${this.internal_config.paddingLeft};
                        padding-right: ${this.internal_config.paddingRight};
                        padding-bottom: ${this.internal_config.paddingBottom};
                        z-index: ${this.internal_config.zIndex};
                        width: ${this.internal_config.width};
                        max-width: ${this.internal_config.maxWidth};
												margin: 0 auto`;
			},
			build() {

			},
		},
		mounted() {

			let device, childs, id, config;
			({device, childs, id, config} = this.$props);

			if (typeof config.backgroundColor === 'undefined') {
				config.backgroundColor = '#FFFFFF';
			}
			this.internal_config = config;
			this.device = device;

			if (typeof childs === 'undefined') {
				childs = [];
				this.internal_config.childs = [];
			}


			this.elements = childs.sort((a, b) => (a.config.order_mobile > b.config.order_mobile) ? 1 : ((b.config.order_mobile > a.config.order_mobile) ? -1 : 0));

			// this.internal_config = window.mergeRecursive(this.props, this.$props.config);
			let internal_config = window.mergeRecursive(this.props, this.$props.config);
			this.internal_config = {...internal_config, ...this.internal_config};

		}
	}

</script>
<style lang="scss" scoped>

    .card-body {
        position: relative;
    }

    .sitebuilder-element-container {

        .vdr {
            height: unset !important;

        }
    }


    .container-accordion-group {
        overflow: hidden;
    }

</style>
