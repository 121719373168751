<template>
    <div class="wrapper-login">
        <span v-text="$t('ct-comment-theme-title')" />
        <div>
            <a 
              :href="`/blog-login?post=${url}`" 
              v-text="$t('ct-sign-up')"
            />
            <a 
              :href="`/blog-login?post=${url}`" 
              v-text="$t('ct-sign-in')"
            />
      </div>
    </div>
</template>

<script>
export default {
    name: 'DarkGreenTheme',
    props: ['url']
}
</script>

<style lang="scss" scoped>
.wrapper-login {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    max-width: 500px;

    background: #1e1f26;
    padding: 40px;
    border-radius: 5px;

    span {
        font-family: 'Muli', sans-serif;
        font-size: 20px;
        font-weight: 900;
        color: #FFFFFF;

        margin-bottom: 20px;
    }

    div {
        width: 100%;
        display: flex;
        justify-content: space-around;
        flex-direction: column;

        a {
            font-family: 'Lato', sans-serif;

            padding: 10px 16px;
            border-radius: 5px;

            text-decoration: none;
            transition: 0.3s;

            &:nth-of-type(1) {
                color: #000;
                background: #47cf73;

                &:hover {
                    background: #2fb45a;
                }
            }

            &:nth-of-type(2) {
                color: #FFFFFF;
                background: #444857;

                margin-top: 20px;

                &:hover {
                    background: #5a6074;
                }
            }
        }
    }
}

@media (min-width: 600px) {
    .wrapper-login {
        div {
            flex-direction: row;

            a {
                &:nth-of-type(2) {
                    margin-top: 0px;
                }
            }
        }
    }
}
</style>
