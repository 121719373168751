import axios from 'axios';

const getUrl = (type) => {
    let url = '';

    if (process.env.NODE_ENV === 'development' ||  location.hostname === "publication.localhost" ) {
        url = 'http://api.localhost';
    } else {
        if (
            location.hostname.indexOf('publication-master.builderall.io') > -1 
           /* || location.hostname.indexOf('.cheetahspeedtest.com') > -1*/
        ) {
            url = 'https://api-master.builderall.io';
        } else if (location.hostname.indexOf('publication-dev.builderall.io') > -1) {
            url = 'https://api-dev.builderall.io';
        } else {
            url = 'https://cheetah-api.builderall.com';
        }
    }
    if (type === 'api') {
        return url + '/api';
    }

    return url;
};

const http = new (function() {

    this.api = function() {
        let instance = axios.create({
            baseURL: getUrl('api'),
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }
        });

        instance.interceptors.request.use(function (config) {
            let lang = (typeof OPTIONS_INIT_SITE !== 'undefined' && typeof OPTIONS_INIT_SITE.options !== 'undefined' && typeof OPTIONS_INIT_SITE.options.lang !== 'undefined') ? OPTIONS_INIT_SITE.options.lang : 'pt-BR';
            config.headers['Accept-Language'] = lang.split('_').join('-');
            return config;
        }, function (error) {
            return Promise.reject(error);
        });

        return instance;
    };

    this.authApi = function() {
        var token = localStorage.getItem('login-user-token')
            ? localStorage.getItem('login-user-token')
            : '';

        let instance = axios.create({
            baseURL: getUrl('api'),
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        });

        instance.interceptors.request.use(function (config) {
            let lang = (typeof OPTIONS_INIT_SITE !== 'undefined' && typeof OPTIONS_INIT_SITE.options !== 'undefined' && typeof OPTIONS_INIT_SITE.options.lang !== 'undefined') ? OPTIONS_INIT_SITE.options.lang : 'pt-BR';
            config.headers['Accept-Language'] = lang.split('_').join('-');
            return config;
        }, function (error) {
            return Promise.reject(error);
        });

        return instance;
    };
})();

export default http;
