export default {
  data() {
    return {
      props: {
        'tag': '',
        //90deg if false
        'horizontal': true,

        'height': '150px',

        'zIndex': 'auto','maxWidth':'768px',
        'rotation': '0deg',

        'border': '1px solid #000000',
        'boxShadow': '0 0 0 0 blue',
        'borderRadius': '0 0 0 0',

        'paddingTop': '15px',
        'paddingLeft': '15px',
        'paddingRight': '15px',
        'paddingBottom': '15px',

        'arrowTop': {
          'active': false,
          'color': '#000000',
          'size': '5px',
        },
        'arrowBottom': {
          'active': false,
          'color': '#000000',
          'size': '5px',
        },

        'animation': {
          'in': {
            'action': 'time',   //time/scroll/click/hover
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'speed': 'slow',
            'infinite': false,
          },
          'out': {
            'action': 'time',   //time/scroll/click
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'delay': '1',
            'speed': '1',
            'infinite': false,
          },
        },
      },
      internal_config: {
        'horizontal': true,

        'height': '150px',

        'zIndex': 'auto','maxWidth':'768px',
        'rotation': '0deg',

        'border': '1px solid #000000',
        'boxShadow': '0 0 0 0 blue',
        'borderRadius': '0 0 0 0',

        'paddingTop': '15px',
        'paddingLeft': '15px',
        'paddingRight': '15px',
        'paddingBottom': '15px',

        'arrowTop': {
          'active': false,
          'color': '#000000',
          'size': '5px',
        },
        'arrowBottom': {
          'active': false,
          'color': '#000000',
          'size': '5px',
        },

        'animation': {
          'in': {
            'action': 'time',   //time/scroll/click/hover
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'speed': 'slow',
            'infinite': false,
          },
          'out': {
            'action': 'time',   //time/scroll/click
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'delay': '1',
            'speed': '1',
            'infinite': false,
          },
        },
      },
      name: 'line',
    }
  }
}
