<template>
  <div :data-field-group-name="data.visibility !== 'hidden' ? data.tag : ''">
    <div class="form-group" v-show="this.data.visibility !== 'hidden'" data-check-required="required">
      <label v-show="this.showLabel"> {{label}} </label>
      <div class="form-check" v-for="element in options">
        <input :type="type" :value="element.value" class="form-check-input" data-check-field="true" :data-required="required === 'yes'"
               :name=" type==='checkbox'?data.tag+'[]':data.tag"
               :checked="selected == element.value || item.selected.indexOf(element.value) !== -1"
               :placeholder="placeholder"
               :id="`${type}-${data.tag}-${element.value}`"
               @click="changeValue(element.value)">
        <label class="form-check-label" :for="`${type}-${data.tag}-${element.value}`" :value="element.value">
          {{element.name}}
        </label>

      </div>
      <small  style="width: 100%" class="form-text text-muted" v-show="data.help_text != ''">{{data.help_text}}</small>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CheckFieldsMailingBoss',
    props: ['type', 'options', 'label', 'selected', 'data', 'required', 'showPlaceholder', 'showLabel'],
    data() {
      return {
        item: {
          selected: []
        }
      }
    },
    computed: {
      placeholder() {
        return this.showPlaceholder ? this.label : ''
      },
    },
    methods: {
      changeValue(val) {

        if (this.type === 'checkbox') {
          if (this.item.selected.indexOf(val) !== -1) {
            this.item.selected.splice(this.item.selected.indexOf(val), 1);
          } else {
            this.item.selected.push(val);
          }
        } else {
          this.selected = val;
        }

        let vm = this;
        let options = this.options;

        this.options = {};
        this.$nextTick(() => {
          vm.options = options;
        })
      }
    },
    created() {
      if(this.data.default_value)
        this.changeValue(this.data.default_value);
    }
  }
</script>
