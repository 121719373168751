var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"panel",class:'sitebuilder-element-panel'  + _vm.hideControl(),attrs:{"data-fixed":_vm.fixedBanner(),"data-fixed-mobile":_vm.fixedBannerMobile(),"data-tag":_vm.dataTag(),"data-footer-fixed":_vm.fixedFooterBanner(),"data-footer-fixed-mobile":_vm.fixedFooterBannerMobile()}},[_c('div',{ref:"elem",staticClass:"panel panel-primary draggable container-fluid",style:({
        'border-radius': _vm.radius,
        background: _vm.backgroundColor,
        backgroundImage: _vm.backgroundImage,
        backgroundSize: _vm.backgroundSize,
        backgroundPosition: _vm.backgroundPosition,
        backgroundOrigin: _vm.backgroundOrigin,
        backgroundRepeat: _vm.backgroundRepeat,
	    backgroundAttachment: _vm.backgroundAttachment,
        height : _vm.height,
        minHeight : _vm.minHeight,
        borderWidth : _vm.borderWidth,
        borderStyle : _vm.borderStyle(),
        borderColor : _vm.borderColor(),
        'box-shadow': _vm.boxShadow,
        }),attrs:{"element":"PanelComponent","id":this.internal_config.id,"data-hide-desktop":!this.internal_config.showDesktop,"data-hide-mobile":!this.internal_config.showMobile,"data-anchor-name":(this.internal_config && typeof this.internal_config.anchorName !== 'undefined' && this.internal_config.anchorName !== '')? this.internal_config.anchorName : ''}},[((_vm.$isDeviceIOS() && !_vm.internal_config.parallaxActive && _vm.backgroundAttachment === 'fixed') || (_vm.device !== 'desktop'  && _vm.backgroundAttachment === 'fixed'))?_c('div',{staticClass:"panel-helper-ios"},[_c('div',{staticClass:"panel-helper-ios-image",style:({
                    'border-radius': _vm.radius,
                    background: _vm.backgroundColor,
                    backgroundImage: _vm.backgroundImage,
                    backgroundSize: _vm.backgroundSize,
                    backgroundPosition: _vm.backgroundPosition,
                    backgroundOrigin: _vm.backgroundOrigin,
                    backgroundRepeat: _vm.backgroundRepeat,
                    backgroundAttachment: _vm.backgroundAttachment,
                    border : _vm.border,
                    'box-shadow': _vm.boxShadow,
                })})]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.internal_config.video != '' && _vm.internal_config.videoInternal == false),expression:"internal_config.video != '' && internal_config.videoInternal == false"}],staticClass:"panel-video-place"},[_c('video',{staticClass:"panel-video",attrs:{"playsinline":"","autoplay":_vm.internal_config.autoplay,"loop":_vm.internal_config.loop,"id":"myVideo"},domProps:{"muted":_vm.internal_config.muted}},[_c('source',{attrs:{"src":_vm.srcVideo,"type":_vm.typeVideo}})])]),_c('div',{staticClass:"panel-body container",style:({
             background: _vm.internalBackgroundColor,
            backgroundImage: _vm.internalBackgroundImage,
            backgroundSize: _vm.internalBackgroundSize,
            backgroundPosition: _vm.internalBackgroundPosition,
            backgroundOrigin: _vm.internalBackgroundOrigin,
            backgroundRepeat: _vm.internalBackgroundRepeat,
			backgroundAttachment: _vm.internalBackgroundAttachment,
            minHeight : _vm.minHeight,
            })},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.internal_config.video != '' && _vm.internal_config.videoInternal == true),expression:"internal_config.video != '' && internal_config.videoInternal == true"}],staticClass:"panel-video-place"},[_c('video',{staticClass:"panel-video",attrs:{"playsinline":"","autoplay":_vm.internal_config.autoplay,"loop":_vm.internal_config.loop,"id":"myVideoInternal"},domProps:{"muted":_vm.internal_config.muted}},[_c('source',{attrs:{"src":_vm.srcVideo,"type":_vm.typeVideo}})])]),(_vm.props.columns && _vm.device !== 'desktop')?_c('div',{staticClass:"row"},_vm._l((_vm.elements),function(element,index){return _c(element.name,{key:index,tag:"component",class:("col-" + (12/_vm.props.columns_count)),style:(("width: " + (100 / _vm.props.columns_count) + "% !important")),attrs:{"config":element.config,"childs":element.childs,"device":_vm.device,"id":element.id,"height_start":index,"preview":_vm.preview},on:{"setCallbackChangeDevice":_vm.setCallbackChangeDevice}})}),1):_vm._l((_vm.elements),function(element,index){return _c(element.name,{key:index,tag:"component",attrs:{"config":element.config,"childs":element.childs,"device":_vm.device,"is_popup":_vm.is_popup,"id":element.id,"height_start":index,"preview":_vm.preview}})})],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.internal_config.arrowActive),expression:"internal_config.arrowActive"}],staticClass:"arrow-panel-down",style:({
                         width: _vm.widthArrow,
                         left: _vm.leftArrow,
                         'border-left': _vm.transparentArrowSide,
                         'border-right': _vm.transparentArrowSide,
                         'border-top': _vm.colorArrowSide})})]),_c('separator-panel',{ref:"separatorData",attrs:{"data-hide-desktop":!this.internal_config.showDesktop,"data-hide-mobile":!this.internal_config.showMobile}}),_c('div',{attrs:{"data-hide-desktop":!this.internal_config.showDesktop,"data-hide-mobile":!this.internal_config.showMobile},domProps:{"innerHTML":_vm._s(this.internal_config.separatorHtml)}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }