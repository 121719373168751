<template>
  <div
    :id="this.id"
    class="sitebuilder-element-container"
    element="MenuComponent"
    :class="{ 'full-width-element': this.internal_config.fullWidth }"
    :data-tag="dataTag()"
    :data-hide-desktop="!this.internal_config.showDesktop"
    :data-hide-mobile="!this.internal_config.showMobile"
    :style="style()"
    :dir="dir()"
    @mouseout="hideDropDown($event)"
  >
    <div :style="`transform: rotate(${this.$props.device === 'desktop' ? this.internal_config.rotation : 0}) scale(1)`">
      <nav
        class="navbar navbar-expand-lg desktop-menu-element"
        v-bind:style="{
          'cursor':'pointer',
          'border-radius': radius,
          background: backgroundColor,
          border : border,
          cursor: 'pointer',
          'box-shadow': boxShadow,
        }"
      >
        <div class="collapse navbar-collapse"
             :class="[{'flex-column':typeof this.internal_config.brand != 'undefined' && typeof this.internal_config.brand.position != 'undefined' && this.internal_config.brand.posi3tion === 'center'}, {'justify-content-center':this.internal_config.align === 'center'}, {'justify-content-end':this.internal_config.align === 'right'}]">
          <ul :class="vertical ? 'navbar-nav flex-column' : 'navbar-nav' ">
            <li class="nav-item item-menu-sb" v-for="item in internal_config.itemList"
                v-show="calcDrip(item)"
                :style="itemMenuStyle()">
              <a
                :class="[item.children.length > 0 ? 'dropdown-toggle' : '', itemMenuPositionClass, item.hasOwnProperty('active') ? 'item-active' : '']"
                :href ="hrefUrl(item)"
                :data-action="item.link.type"
                class="nav-link d-flex align-items-center"
                data-toggle="dropdown"
                @click.prevent="linkAction(item)"
                @mouseover="showDropDown(item.name, true)"
                :id="item.children.length > 0 ? item.name:''"
                v-on:mouseover="mouseover"
                v-on:mouseleave="mouseleave"
                :style="[textStyle(), itemMenuDesktopStyle()]"
                >
                  <span class="d-block text-truncate px-1">
                    <i
                    class="mdi"
                    v-if="item.icon.selected"
                    v-show="item.icon.position === 'left'"
                    :class="item.icon.selected"></i>
                    {{item.name}}
                    <i
                    class="mdi"
                    v-if="item.icon.selected"
                    v-show="item.icon.position === 'right'"
                    :class="item.icon.selected"></i>
                  </span>
                </a>
              <div
                class="dropdown-menu"
                v-if="item.children.length > 0"
                :ref="item.name"
                v-bind:style="{background: backgroundColorSubItem}"
              >
                 <a
                  class="dropdown-item"
                  v-for="child in item.children"
                  :class="[  child.hasOwnProperty('active') ? 'item-active' : '' ]"
                  :href="hrefUrl(child)"
                  :data-action="child.link.type"
                  :style="[subTextStyle(), itemMenuDesktopStyle()]"
                   @click.prevent="linkAction(child)"
                  :target="child.link.target"
                  v-on:mouseover="mouseSubOver"
                  v-on:mouseleave="mouseSubOut"
                >
                  <span class="d-block px-1">
                    <i
                    class="mdi"
                    v-if="child.icon.selected"
                    v-show="child.icon.position === 'left'"
                    :class="child.icon.selected"></i>
                    {{child.name}}
                    <i
                    class="mdi"
                    v-if="child.icon.selected"
                    v-show="child.icon.position === 'right'"
                    :class="child.icon.selected"></i>
                  </span>
                </a>
              </div>
            </li>
          </ul>
          <!-- <div class="lItemMenu"></div>
          <div class="bItemMenu"></div> -->
        </div>
      </nav>

      <!-- Top Navigation Menu -->
      <div class="mobile-menu-element">
        <div class="topnav " v-bind:style="{background: backgroundColor,}">
          <div class="navbar-brand w-100 py-1 px-3 m-0 position-absolute" v-if="typeof this.internal_config.brand != 'undefined' && typeof this.internal_config.brand.image != 'undefined' && this.internal_config.brand.image" :class="[{'d-flex justify-content-start': typeof this.internal_config.brand != 'undefined' && typeof this.internal_config.brand.position != 'undefined' && this.internal_config.brand.position === 'left'}, {'d-flex justify-content-center': typeof this.internal_config.brand != 'undefined' && typeof this.internal_config.brand.position != 'undefined' && this.internal_config.brand.position === 'center'}, {'d-flex justify-content-end': typeof this.internal_config.brand != 'undefined' && typeof this.internal_config.brand.position != 'undefined' && this.internal_config.brand.position === 'right'}]" href="#">
            <img v-if="internal_config.brand.image && internal_config.brand.image !== 'none'" :src="this.internal_config.brand.image" alt="logo" :style="styleBrand()">
          </div>
          <a
            href="javascript:void(0);"
            class="icon"
            :class="menuIconPositionClass"
            :style="styleIconMenuBurgue"
            @click="myFunction"
            ref="burguerMenu"
          >
            <transition name="icon-change" v-if="internal_config.burguerMenu.showCloseIcon">
              <div
                class="mdi font-awesome-icon position-absolute"
                :class="typeof internal_config.burguerMenu.displayCloseIcon != 'undefined' && internal_config.burguerMenu.displayCloseIcon ? 'mdi-close' : internal_config.burguerMenu.icon"
                style="font-size: 20px;"
              ></div>
            </transition>
            <div
              v-else
              class="mdi font-awesome-icon position-absolute"
              :class="internal_config.burguerMenu.icon"
              style="font-size: 20px;"
            ></div>
          </a>
        </div>
        <div id="myLinks" ref="burguer">
          <div v-for="item in internal_config.itemList">
            <item-menu-mobile :item="item" :text-style="textStyle()" :sub-text-style="subTextStyle()" v-show="calcDrip(item)"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Data from "./Model";
import ItemMenuMobile from "./children/ItemMenuMobile";
import moment from "moment/moment";

export default {
  name: "MenuComponent",
  props: ["config", "id", "preview", "device"],
  components: {
    ItemMenuMobile,
    Data
  },
  computed: {
    vertical() {
      return this.internal_config.vertical;
    },
    border() {
      if (typeof this.internal_config.border !== "undefined") {
        let splitedBorder = this.internal_config.border.split(" ");

        if (
          typeof splitedBorder[2] !== "undefined" &&
          this.$globalStoreHelpers.isValidHex(splitedBorder[2])
        ) {
          splitedBorder[2] = this.$globalStoreHelpers.hexToRGBA(
            splitedBorder[2]
          );
          this.internal_config.border = splitedBorder.join(" ");
        }
      }
      return this.internal_config.border;
    },
    boxShadow() {
      return this.internal_config.boxShadow;
    },
    radius() {
      return this.internal_config.borderRadius;
    },
    backgroundColor() {
      if (
        this.$globalStoreHelpers.isValidHex(
          this.internal_config.backgroundColor
        )
      ) {
        this.internal_config.backgroundColor = this.$globalStoreHelpers.hexToRGBA(
          this.internal_config.backgroundColor
        );
      }
      return this.internal_config.backgroundColor;
    },
    backgroundColorItem() {
      if (
        this.$globalStoreHelpers.isValidHex(
          this.internal_config.btnStyle.backgroundColor
        )
      ) {
        this.internal_config.btnStyle.backgroundColor = this.$globalStoreHelpers.hexToRGBA(
          this.internal_config.btnStyle.backgroundColor
        );
      }
      return this.internal_config.btnStyle.backgroundColor;
    },
    backgroundColorSubItem() {
      if (
        this.$globalStoreHelpers.isValidHex(
          this.internal_config.subStyle.backgroundColor
        )
      ) {
        this.internal_config.subStyle.backgroundColor = this.$globalStoreHelpers.hexToRGBA(
          this.internal_config.subStyle.backgroundColor
        );
      }
      return this.internal_config.subStyle.backgroundColor;
    },
    menuIconPositionClass() {
      return {
        leftIconPosition: this.internal_config.burguerMenu.position === 'left',
        rightIconPosition:
                this.internal_config.burguerMenu.position === 'right',
        centerIconPosition:
                this.internal_config.burguerMenu.position === 'center'
      };
    },
    itemMenuPositionClass() {
      return {
        'justify-content-end': this.internal_config.btnStyle.align === 'right',
        'justify-content-start': this.internal_config.btnStyle.align === 'left',
        'justify-content-center': this.internal_config.btnStyle.align === 'center'
      };
    },
    styleIconMenuBurgue() {

      return `color: ${this.internal_config.burguerMenu.color};
      background: ${this.internal_config.burguerMenu.backgroundColor};
      width: 3.6em;
      height: 3.4em;`;
    }
  },
  data() {
    return Data.data();
  },
  methods: {
    dataTag() {
      if (this.internal_config.tag) {
        return this.internal_config.tag;
      }
      return "";
    },
    animation() {
      return false;
    },
    myFunction() {
      let x = this.$refs.burguer;
      let selectedEffect = this.internal_config.menuMobileEffect;
      let options = {};

      if (x.style.display === 'block') {
        this.internal_config.burguerMenu.displayCloseIcon = false;
      } else {
        this.internal_config.burguerMenu.displayCloseIcon = true;
      }

      if (selectedEffect === 'size') {
        options = { to: { width: 0, height: 0 } };
      }

      if (selectedEffect) {
        $(x).toggle(selectedEffect, options, 500);
      } else {
        $(x).toggle();
      }
    },

    mouseover(e) {
      let element = e.toElement;


      if (
        this.$globalStoreHelpers.isValidHex(
          this.internal_config.btnStyle.backgroundColorHover
        )
      ) {
        this.internal_config.btnStyle.backgroundColorHover = this.$globalStoreHelpers.hexToRGBA(
          this.internal_config.btnStyle.backgroundColorHover
        );
      }

      if (element.classList.contains("nav-link"))
        element.style.backgroundColor = this.internal_config.btnStyle.backgroundColorHover;
    },

    mouseleave(e) {
      let element = e.fromElement;

      if (
        this.$globalStoreHelpers.isValidHex(
          this.internal_config.btnStyle.backgroundColor
        )
      ) {
        this.internal_config.btnStyle.backgroundColor = this.$globalStoreHelpers.hexToRGBA(
          this.internal_config.btnStyle.backgroundColor
        );
      }

      if (element.classList.contains("nav-link")){

         if(element.getAttribute('class').indexOf('item-active') !== -1) {
          element.style.backgroundColor = this.internal_config.btnStyle.backgroundColorActive;
         }else {
          element.style.backgroundColor = this.internal_config.btnStyle.backgroundColor;
         }


      }
    },
    mouseSubOver(e) {
      let element = e.toElement;

      if (
        this.$globalStoreHelpers.isValidHex(
          this.internal_config.subStyle.backgroundColorHover
        )
      ) {
        this.internal_config.subStyle.backgroundColorHover = this.$globalStoreHelpers.hexToRGBA(
          this.internal_config.subStyle.backgroundColorHover
        );
      }

      if (element.classList.contains("dropdown-item"))
        element.style.backgroundColor = this.internal_config.subStyle.backgroundColorHover;
    },

    mouseSubOut(e) {
      let element = e.fromElement;

      if (
        this.$globalStoreHelpers.isValidHex(
          this.internal_config.subStyle.backgroundColor
        )
      ) {
        this.internal_config.subStyle.backgroundColor = this.$globalStoreHelpers.hexToRGBA(
          this.internal_config.subStyle.backgroundColor
        );
      }

       if (element.classList.contains("dropdown-item")) {

        if(element.getAttribute('class').indexOf('item-active') !== -1) {
          element.style.backgroundColor = this.internal_config.subStyle.backgroundColorActive;
         }else {
          element.style.backgroundColor = this.internal_config.subStyle.backgroundColor;
         }

      }
    },

    clickDropDown(id, item) {
      $(".dropdown-menu").hide();

      if (typeof this.$refs[id] !== "undefined")
        this.$refs[id][0].style.display =
          this.$refs[id][0].style.display == "none" ? "block" : "none";

      this.linkAction(item);
    },

    showDropDown(id, show) {
      $(".dropdown-menu").hide();

      if (typeof this.$refs[id] !== "undefined") {
        $(this.$refs[id][0]).css('max-height', window.innerHeight);
        this.$refs[id][0].style.display =
          this.$refs[id][0].style.display == "none" ? "block" : "none";
      }

      if (typeof this.$refs[id] !== "undefined")
        this.$refs[id][0].style.display = show ? "block" : "none";
    },

    hideDropDown(e) {
      if (e.toElement.className == "panel-body container") {
        $(".dropdown-menu").hide();
      }
    },
    // linkElement: function () {
    // this.$root.$emit('linkComponentShow', this);
    // },
    linkAction: function(item) {
      // if (this.internal_config.link.type === 'video' && this.internal_config.link.value !== '') {
      //   this.$refs.videoModalIframe.src = this.internal_config.link.value;
      //   this.$refs.videoModal.open();
      // } else {
      this.$root.$emit("linkAction", item.link);
      // }
    },
    hrefUrl(item){
      let url = '';

        if(item.link.type == "web" ){
          url = item.link.value
          return url

        }else{
          url= "#"
          return url
        }
      },
    buildIcon: function() {
      $(this.$el)
        .find("i")
        .detach();

      if (this.internal_config.icon !== "") {
        if (this.internal_config.iconPosition === "left") {
          $("<i>")
            .addClass("mdi " + this.internal_config.icon)
            .prependTo(
              $(this.$el)
                .find(".sb-text")
                .get(0)
            );
        }
        if (this.internal_config.iconPosition === "right") {
          $("<i>")
            .addClass("mdi " + this.internal_config.icon)
            .appendTo(
              $(this.$el)
                .find(".sb-text")
                .get(0)
            );
        }
      }
    },
    build(config) {
      this.internal_config = config;

      this.buildIcon();
    },
    itemMenuStyle() {
      return {
        marginLeft:  parseInt(this.internal_config.spacing)/2 + 'px',
        marginRight:  parseInt(this.internal_config.spacing)/2 + 'px',
        borderRadius: this.internal_config.itemMenuBorderRadius,
        background: this.internal_config.btnStyle.backgroundColor
      };
    },
    textStyle() {
      return {
        fontFamily: this.internal_config.btnStyle.family,
        fontSize: this.internal_config.btnStyle.size,
        textAlign: this.internal_config.btnStyle.align,
        fontWeight: this.internal_config.btnStyle.weight,
        fontStyle: this.internal_config.btnStyle.style,
        color: this.internal_config.btnStyle.color,
        border: this.internal_config.itemMenuBorder,
        boxShadow: this.internal_config.itemMenuBoxShadow,
        '--menu-item-background': this.internal_config.btnStyle.backgroundColor,
        '--menu-item-background--hover': this.internal_config.btnStyle.backgroundColorHover,
        '--menu-item-color--hover': this.internal_config.btnStyle.colorHover || this.internal_config.btnStyle.color,
        '--menu-item-background-active': this.internal_config.btnStyle.backgroundColorActive
      };
    },
    subTextStyle() {
      return {
        fontFamily: this.internal_config.subStyle.family,
        fontSize: this.internal_config.subStyle.size,
        textAlign: this.internal_config.subStyle.align,
        fontWeight: this.internal_config.subStyle.weight,
        fontStyle: this.internal_config.subStyle.style,
        color: this.internal_config.subStyle.color,
        border: this.internal_config.itemMenuSubBorder,
        display: 'grid',
        '--sub-item-background': this.internal_config.subStyle.backgroundColor,
        '--sub-item-background--hover': this.internal_config.subStyle.backgroundColorHover,
        '--sub-item-color--hover': this.internal_config.subStyle.colorHover || this.internal_config.subStyle.color,
        '--sub-item-background-active': this.internal_config.subStyle.backgroundColorActive
      };
    },
    itemMenuDesktopStyle() {
      return {
        paddingLeft: parseInt(this.internal_config.itemMenuWidth) /10 + 'px',
        paddingRight: parseInt(this.internal_config.itemMenuWidth) /10 + 'px',
        height: parseInt(this.internal_config.itemMenuHeight) + 'px',
        paddingTop: 0,
        paddingBottom: 0,
        borderRadius: this.internal_config.itemMenuBorderRadius,
      };
    },
    styleBrand(){
      return {
        maxWidth: parseInt(this.internal_config.brand.width) + 'px',
        width: parseInt(this.internal_config.brand.width) + 'px',
        maxHeight: '50px',
        padding: 0
      };
    },
    style() {
      return `left:  ${this.internal_config.left};
                     z-index: ${this.internal_config.zIndex};max-width: ${this.internal_config.maxWidth};margin: 0 auto;
				        padding-top: ${this.internal_config.paddingTop};
                padding-left: ${this.internal_config.paddingLeft};
                padding-right: ${this.internal_config.paddingRight};
                padding-bottom: ${this.internal_config.paddingBottom};
                top: ${this.internal_config.top};`;
    },

    dir(){
      if (
              typeof langUser != 'undefined' && (
              langUser === 'he' ||
              langUser === 'ja' ||
              langUser === 'ar')
      ) {
        return 'rtl';
      } else return false;
    },
    styleResize() {
      return `{width: ${this.internal_config.width}, height:  ${this.internal_config.height}}`;
    },
    calcDrip(field) {
      if (!this.internal_config.isDrip) {
        return true
      } else {

        if (typeof field.typeOfPredefined === 'undefined' || field.typeOfPredefined === 'none') {
          return true;
        } else {
          if (field.typeOfPredefined === 'date') {
            if (moment.utc() > moment(field.datePredefined)) {
              return true
            }
          } else {

            let dateVar = moment(localStorage.getItem('timeFirstAccess-' + this.id), "x").format("DD MMM YYYY hh:mm a")

            let dateToRelease = moment(dateVar)
                    .add(field.timePredefinedDays, 'days')
                    .add(field.timePredefinedHours, 'hours')
                    .add(field.timePredefinedMinutes, 'minutes')
                    .add(field.timePredefinedSeconds, 'seconds');

            if (moment() > dateToRelease) {
              return true;
            }

          }
        }

      }
    }
  },
  mounted() {
    let config = this.$props.config;


    try{

      let isIndex = window.location.pathname === '/' ? true : false;
      let actualPage = false;

      if(isIndex) {
        actualPage = parseInt(pagesConfig.filter( page => page.initial === '1' )[0].id)
      }else{
        let path = window.location.pathname.replace('/', '').toLowerCase();
        actualPage = parseInt(pagesConfig.filter( page => page.url.toLowerCase() === path )[0].id)
      }

      config.itemList.forEach(item => {
        if(item.link.type === 'page') {
          if(parseInt(item.link.valueID) === actualPage) { item.active = true; }
        }

        item.children.forEach( child => {
          if(child.link.type === 'page') {
          if(parseInt(child.link.valueID) === actualPage) { child.active = true; item.active = true }
        }
          } )

      })

    }catch(e) {
      console.log(e)
    }

    this.build(config);

    if (['undefined', 'string'].includes(typeof this.internal_config.burguerMenu)) {
      this.internal_config.burguerMenu = {
        position: 'right',
        icon: 'mdi-menu',
        showCloseIcon: true,
        displayCloseIcon: false,
        backgroundColor: '#000',
        color: '#fff'
      };
    }

    this.internal_config.burguerMenu.displayCloseIcon = false;

    Object.keys(this.props).forEach(prop => {
      if (typeof this.internal_config[prop] === "undefined") {
        this.internal_config[prop] = this.props[prop];
      }
    });

    this.$root.$emit("applyAnimation", this, "sitebuilder-element-container");

    this.$el.classList.add("preview");

    if (this.internal_config.zIndex === "auto") {
      this.internal_config.zIndex = 1;
    }

    // this.$el.addEventListener('mouseover', function () {
    // 	document.body.style.setProperty('--button-background-color--hover', vm.internal_config.backgroundColorHover);
    // });


    if(typeof this.internal_config.isDrip !== 'undefined' && this.internal_config.isDrip){
      let firstAccessTime = localStorage.getItem('timeFirstAccess-'+this.id);
      if(firstAccessTime == null){
        firstAccessTime = moment().valueOf();
        localStorage.setItem('timeFirstAccess-'+this.id,firstAccessTime);
      }
    }

  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.sitebuilder-element-container {
  width: auto !important;
  height: auto !important;

  .nav-link {
    background: var(--menu-item-background);

    //Hover for menu item
    &:hover {
      color: var(--menu-item-color--hover) !important;
      background: var(--menu-item-background--hover);
    }

    //Dropdown active
    &.dropdown-toggle.active {
      background: var(--menu-item-background-active);
    }

     &.item-active {
      background: var(--menu-item-background-active);
     }
  }

  .dropdown-item {
    background: var(--sub-item-background);

    //Hover for sub item
    &:hover {
      color: var(--sub-item-color--hover) !important;
      background: var(--sub-item-background--hover);
    }


     &.item-active {
      background: var(--sub-item-background-active);
     }
  }

  .navbar {
    padding: 0;
    height: auto;
    white-space: nowrap;

    /*.item-menu-sb:first-of-type {*/
    /*  margin-left: 0 !important;*/
    /*}*/
  }

  .navbar-nav .dropdown-menu {
    padding: 0;
    margin: 0;
    min-width: 0px;
    border-radius: 0;
    overflow-y: auto;
  }

  .dropdown-menu .dropdown-item {
    align-items: center;
  }

  .topnav {
    overflow: hidden;
    background-color: #333;
    position: relative;
    min-height: 57px;
    text-align: center;
    cursor: pointer;

    .burguer-sub-item {
      padding-left: 15px;
    }

    a {
      color: white;
      padding: 14px 16px;
      text-decoration: none;
      font-size: 17px;
      display: block;

      &.icon {
        background: black;
        display: block;
        position: absolute;
        top: 0;
      }

      &:hover {
        background-color: #ddd;
        color: black;
      }
    }
  }

  #myLinks {
    display: none;
  }

  .lItemMenu {
    width: 10px;
    background-color: rgb(2, 148, 255);
    position: relative;
    transform: skewY(45deg);
    top: 5px;
  }

  .bItemMenu {
    height: 10px;
    width: 120px;
    background-color: rgb(2, 148, 255);
    transform: skew(45deg) translateX(5px);
    position: relative;
    bottom: 4px;
  }

  .leftIconPosition {
    left: 0;
  }

  .rightIconPosition {
    right: 0;
  }

  .centerIconPosition {
    right: calc(50% - 30px);
  }

  .active {
    background-color: #4caf50;
    color: white;
  }

  .icon-change-enter, .icon-change-leave-to {
    transform: rotate(180deg) scale(0.5);
    opacity: 0;
  }

  .icon-change-enter-to, .icon-change-leave {
    transform: rotate(0deg) scale(1);
    opacity: 1;
  }

  .icon-change-enter-active, .icon-change-leave-active {
    transition: opacity 0.3s, transform 0.3s;
  }
}

[data-device="mobile"]{
  .desktop-menu-element {
    display: none;
  }

  .mobile-menu-element {
    display: block;
  }
}

[data-device="desktop"]{
  .desktop-menu-element {
    display: block;
  }

  .mobile-menu-element {
    display: none;
  }
  .navbar-expand-lg .navbar-nav{
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-collapse {
    display: -ms-flexbox!important;
    display: flex!important;
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
  }

  .navbar-expand-lg{
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}


</style>
<style lang="scss">
.nav-item {
  position: relative !important;
}

.navbar-nav.flex-column .dropdown-menu {
  left: 100%;
  top: 0;
}
</style>
