<template>
    <div :id="this.id"
         class="sitebuilder-element-container page height-100"
         element="SliderPageComponent"
         :data-hide-desktop="!this.internal_config.showDesktop"
         :data-hide-mobile="!this.internal_config.showMobile"
         :style="style()"
         ref="elem">

        <div :id="this.id" class=" height-100">
            <div class="sitebuilder-element-receiver height-100" v-bind:style="{
        borderRadius: radius,
        border: border,

        background: backgroundColor,
        backgroundImage: backgroundImage,
        backgroundSize: backgroundSize,
        backgroundPosition: backgroundPosition,
        backgroundOrigin: backgroundOrigin,
        backgroundRepeat: backgroundRepeat,
        backgroundAttachment: backgroundAttachment,
        minHeight:'50px'

        }">
                <component v-for="(element,index) in elements" :is="element.name" :config="element.config" :key="index"
                           :device="device"
                           :is_popup="is_popup"
                           :id="element.id" :height_start="index" :preview="preview"
                           :childs="element.childs"></component>
            </div>
        </div>
    </div>
</template>

<script>

	import Data from './Model';

	export default {
		name: 'SliderPageComponent',
		props: ['config', 'childs', 'height_start', 'device', 'id', 'preview', 'is_popup'],
		components: {
			Data
		},
		computed: {
			border() {
				return this.internal_config.border;
			},
			radius() {
				return this.internal_config.borderRadius;
			},
			backgroundColor() {

				if (this.$globalStoreHelpers.isValidHex(this.internal_config.backgroundColor)) {
					this.internal_config.backgroundColor = this.$globalStoreHelpers.hexToRGBA(this.internal_config.backgroundColor);
				}

				return this.internal_config.backgroundColor;
			},
			backgroundImage() {
				if (this.internal_config.backgroundImage && this.internal_config.backgroundImage !== 'none') {
					if(this.internal_config.backgroundImage !== null)
						this.internal_config.backgroundImage = this.internal_config.backgroundImage.replace('omb11.com', 'storage.builderall.com');
					return 'url("' + this.internal_config.backgroundImage + '")';
				}
				return '';
			},
			backgroundPosition() {
				if (this.internal_config.backgroundImage && this.internal_config.backgroundImage !== 'none') {
					return this.internal_config.backgroundPosition
				}
				return '';
			},
			backgroundOrigin() {
				if (this.internal_config.backgroundImage && this.internal_config.backgroundImage !== 'none') {
					return this.internal_config.backgroundOrigin
				}
				return '';
			},
			backgroundRepeat() {
				if (this.internal_config.backgroundImage && this.internal_config.backgroundImage !== 'none') {
					return this.internal_config.backgroundRepeat
				}
				return '';
			},
			backgroundSize() {
				if (this.internal_config.backgroundImage && this.internal_config.backgroundImage !== 'none') {
					return this.internal_config.backgroundSize
				}
				return '';
			},
			backgroundAttachment() {
				if (this.internal_config.backgroundImage && this.internal_config.backgroundImage !== 'none') {
					return this.internal_config.backgroundAttachment
				}
				return '';
			},
		},
		data() {
			return Data.data();
		},

		methods: {
			changeHeight(size, positive) {

				if (this.device == 'desktop') {
					let elementBounding = this.$el.getBoundingClientRect();

					if (positive)
						this.internal_config.height = parseInt(elementBounding.height) + size + 'px';
					else
						this.internal_config.height = parseInt(elementBounding.height) - parseInt(size) + 'px';

					if (typeof this.$parent.changeHeight !== 'undefined') {
						this.$parent.changeHeight(size, positive, this.id, $(this.$el).position().top, $(this.$el).offset().left, elementBounding.width);
					} else {
						this.$parent.$parent.changeHeight(size, positive, this.id, $(this.$el).position().top, $(this.$el).offset().left, elementBounding.width);
					}
				}
			},

			animation() {
				return false;
			},
			style() {
				return `left:  ${this.internal_config.left};
                top: ${this.internal_config.top};
				        padding-top: ${this.internal_config.paddingTop};
                padding-left: ${this.internal_config.paddingLeft};
                padding-right: ${this.internal_config.paddingRight};
                padding-bottom: ${this.internal_config.paddingBottom};
                z-index: ${this.internal_config.zIndex};
                width: ${this.internal_config.width};
                max-width: ${this.internal_config.maxWidth};
                margin: 0 auto;`;
			},
			build() {
				// console.log('build');
			},
		},
		mounted() {

			let device, childs, id, config;
			({device, childs, id, config} = this.$props);

			if (typeof config.backgroundColor === 'undefined') {
				config.backgroundColor = '#FFFFFF';
			}
			this.internal_config = config;
			this.device = device;

			if (typeof childs === 'undefined') {
				childs = [];
				this.internal_config.childs = [];
			}

			if (typeof id !== 'undefined') {
				this.id = id;
			}


			this.elements = childs.sort((a, b) => (a.config.order_mobile > b.config.order_mobile) ? 1 : ((b.config.order_mobile > a.config.order_mobile) ? -1 : 0));

			// this.internal_config = window.mergeRecursive(this.props, this.$props.config);
			let internal_config = window.mergeRecursive(this.props, this.$props.config);
			this.internal_config = {...internal_config, ...this.internal_config};


			this.build();

		}
	}

</script>
<style lang="scss" scoped>

    .sitebuilder-element-container.page {
        width: 100% !important;
        top: 0 !important;
        left: 0 !important;
        position: relative;
        background-color: transparent !important;
    }

    .height-100 {
        height: 100% !important;
    }

    [data-device="mobile"] .sitebuilder-element-receiver {
        height: auto !important;
    }

</style>
