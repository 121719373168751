<template>
  <div>
    <div class="timer-show-container" :class="`timer-show-${config.theme}`" data-item="NewTimer" style="">
      <div class="timer-type-text-title-helper" v-show="config.theme === 'text'" v-bind:style="timeshow.descTextStyle">{{ config.textDescription }}</div>
      <div class="timer-show-content d-flex justify-content-center">
        <div class="timer-show-block block-days" v-bind:style="{ 'background-color': this.timeshow.backgroundContent }">
          <div class="timer-show-element-days timer-show-element">
            <span class="timer-type-text-number" data-timer-element="days" v-bind:style="timeshow.numberTextStyle">{{ timeshow.values.days }}</span>
            <div class="timer-type-text-title text-truncate" v-bind:style="timeshow.labelTextStyle" v-show="!(config.theme === 'text' || config.theme === 'pill')">{{ $t('ct-days') }}</div>
            <div class="timer-type-text-title" v-show="config.theme === 'text'" v-bind:style="timeshow.labelTextStyle" >{{ $t('ct-days-init') }}</div>
            <div class="timer-type-text-title" v-bind:style="timeshow.labelTextStyle" v-show="config.theme === 'pill'">{{ $t('ct-days-init-2') }}</div>
          </div>
        </div>
        <div class="timer-show-block block-hours" v-bind:style="{ 'background-color': this.timeshow.backgroundContent }">
          <div class="timer-show-element-hours timer-show-element" style="">
            <span class="timer-type-text-number" data-timer-element="hours" v-bind:style="timeshow.numberTextStyle">{{ timeshow.values.hours }}</span>
            <div class="timer-type-text-title text-truncate" v-bind:style="timeshow.labelTextStyle" v-show="!(config.theme === 'text' || config.theme === 'pill')">{{ $t('ct-hours') }}</div>
            <div class="timer-type-text-title" v-show="config.theme === 'text'" v-bind:style="timeshow.labelTextStyle" >{{ $t('ct-hours-init') }}</div>
            <div class="timer-type-text-title" v-bind:style="timeshow.labelTextStyle" v-show="config.theme === 'pill'">{{ $t('ct-hours-init-2') }}</div>
          </div>
        </div>
        <div class="timer-show-block block-minutes" v-bind:style="{ 'background-color': this.timeshow.backgroundContent }">
          <div class="timer-show-element-minutes timer-show-element" style="">
            <span class="timer-type-text-number" data-timer-element="minutes" v-bind:style="timeshow.numberTextStyle">{{ timeshow.values.minutes }}</span>
            <div class="timer-type-text-title text-truncate" v-bind:style="timeshow.labelTextStyle" v-show="!(config.theme === 'text' || config.theme === 'pill')"> {{ $t('ct-minutes') }}</div>
            <div class="timer-type-text-title" v-show="config.theme === 'text'" v-bind:style="timeshow.labelTextStyle" > {{ $t('ct-minutes-init') }}</div>
            <div class="timer-type-text-title" v-bind:style="timeshow.labelTextStyle" v-show="config.theme === 'pill'"> {{ $t('ct-minutes-init-2') }}</div>
          </div>
        </div>
        <div class="timer-show-block block-seconds" v-bind:style="{ 'background-color': this.timeshow.backgroundContent }">
          <div class="timer-show-element-seconds timer-show-element" style="">
            <span class="timer-type-text-number" data-timer-element="seconds" v-bind:style="timeshow.numberTextStyle">{{ timeshow.values.seconds }}</span>
            <div class="timer-type-text-title text-truncate" v-bind:style="timeshow.labelTextStyle" v-show="!(config.theme === 'text' || config.theme === 'pill')">{{ $t('ct-seconds') }}</div>
            <div class="timer-type-text-title" v-show="config.theme === 'text'" v-bind:style="timeshow.labelTextStyle" >{{ $t('ct-seconds-init') }}</div>
            <div class="timer-type-text-title" v-bind:style="timeshow.labelTextStyle" v-show="config.theme === 'pill'">{{ $t('ct-seconds-init-2') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import XDate from 'xdate';
  import Countdown from 'countdown';
  export default {
    name: 'TimeShow', // Timer do Alanshow
    props: {
      preview: false, config: {}
    },

    data() {
      return {
        timeshow: {
          callLink: false,
          values: {

            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
            finish: '',
            date: ''
          },

          settings: {
            active: 'false', counter: '', link: ''
          },

          numberTextStyle: {},
          labelTextStyle: {},
          descTextStyle: {},
          backgroundContent: '',
          backgroundColor: '',
          appearance: {}
        }
      };
    },

    watch: {
      config: {
        handler(val) {
          this.clear();
          this.styleNumber();
        },
        deep: true
      }
    },

    methods: {
      styleNumber() {
        this.appearance = this.config.appearance[this.config.theme];

        if(this.appearance.fontDesc) {
          this.timeshow.descTextStyle = {
            fontFamily: this.appearance.fontDesc.family,
            fontSize: this.appearance.fontDesc.size,
            fontStyle: this.appearance.fontDesc.size,
            fontWeight: this.appearance.fontDesc.weight,
            textAlign: this.appearance.fontDesc.align,
            color: this.appearance.fontDesc.color,
            backgroundColor: '',
          };
        }

        if(!this.appearance.backgroundColor){
          this.timeshow.numberTextStyle['backgroundColor'] = '';
        }

        if(!this.appearance.backgroundColorEffect)
          this.timeshow.backgroundContent = '';


        this.timeshow.numberTextStyle = {
          fontFamily: this.config.numberTextStyle.family,
          fontStyle: this.config.numberTextStyle.style,
          fontSize: this.config.numberTextStyle.size,
          fontWeight: this.config.numberTextStyle.weight,
          textAlign: this.config.numberTextStyle.align,
          color: this.config.numberTextStyle.color
        };

        this.timeshow.labelTextStyle = {
          fontFamily: this.config.labelTextStyle.family,
          fontStyle: this.config.labelTextStyle.style,
          fontSize: this.config.labelTextStyle.size,
          fontWeight: this.config.labelTextStyle.weight,
          textAlign: this.config.labelTextStyle.align,
          color: this.config.labelTextStyle.color
        };

        if (this.config.theme === 'squares') {

          if(this.appearance.backgroundColorEffect){
            this.timeshow.backgroundContent = '#FFF'
          }

          if (this.appearance.backgroundColor) {
            this.timeshow.numberTextStyle['backgroundColor'] = this.appearance.backgroundColor;
          }else{
            this.timeshow.numberTextStyle['backgroundColor'] = '';
          }

          if (this.appearance.backgroundColorEffect) {
            this.timeshow.backgroundContent = this.appearance.backgroundColorEffect;
          }else this.timeshow.backgroundContent = '';
        }

        if(this.config.theme === 'pill' && this.appearance.backgroundColor){
          this.timeshow.backgroundContent = this.appearance.backgroundColor;
        }
      },

      setValue(index, value) {
        this.timeshow.values[index] = value;
      },

      getDate() {
        let configuration = this.config.settings;

        if (this.config.type !== 'decrease_sum_time_user_start' && this.config.type !== 'start_user_every_access') {
          this.timeshow.values.date = configuration.original_date_finish;
        } else {
          let date = XDate()
                  .addDays(this.config.settings.days_to_sum)
                  .addHours(this.config.settings.hours_to_sum)
                  .addMinutes(this.config.settings.minutes_to_sum)
                  .addSeconds(this.config.settings.seconds_to_sum);

          this.timeshow.values.date = date;
        }

        this.timeshow.values.finish = XDate(this.timeshow.values.date);

        var now = XDate();
        var finish = XDate(this.timeshow.values.date);
        var totalSeconds = now.diffSeconds(finish);

        this.timeshow.values.days = Math.floor(totalSeconds / 86400);
        totalSeconds %= 86400;
        this.timeshow.values.hours = Math.floor(totalSeconds / 3600);
        totalSeconds %= 3600;
        this.timeshow.values.minutes = Math.floor(totalSeconds / 60);
        this.timeshow.values.seconds = Math.floor(totalSeconds % 60);

        this.interval();
      },

      interval(link, settings) {
        /*var vm = this;
        var settings = {...this.timeshow.settings, ...this.timeshow.values};

        this.timeshow.settings.active = true;

        if (XDate(settings.finish, true) > XDate(true)) {
          this.timeshow.settings.active = true;
          var _settings = this.timeshow.settings;

          this.timeshow.settings.counter = window.setInterval(function() {
            if (XDate(settings.finish, true) > XDate(true) && vm.timeshow.settings.active) {
              var diffSecondsTotal = XDate(settings.finish, true).diffSeconds(XDate(true));
              settings.finish = XDate().addSeconds(diffSecondsTotal * (-1));

              vm.setValue('days', Countdown(settings.finish, null, Countdown.DAYS | Countdown.HOURS | Countdown.MINUTES | Countdown.SECONDS).days);
              vm.setValue('hours', Countdown(settings.finish, null, Countdown.DAYS | Countdown.HOURS | Countdown.MINUTES | Countdown.SECONDS).hours);
              vm.setValue('minutes', Countdown(settings.finish, null, Countdown.DAYS | Countdown.HOURS | Countdown.MINUTES | Countdown.SECONDS).minutes);
              vm.setValue('seconds', Countdown(settings.finish, null, Countdown.DAYS | Countdown.HOURS | Countdown.MINUTES | Countdown.SECONDS).seconds);
              vm.verify(XDate(settings.finish, true));
            } else {
              vm.execute();
              vm.setValue('days', 0);
              vm.setValue('hours', 0);
              vm.setValue('minutes', 0);
              vm.setValue('seconds', 0);
            }
          }, 1000);
        } else {
          vm.execute();
          vm.setValue('days', 0);
          vm.setValue('hours', 0);
          vm.setValue('minutes', 0);
          vm.setValue('seconds', 0);
        }*/
      },

      clear() {
        clearInterval(this.timeshow.settings.counter);
      },

      verify(date) {
        if (date <= XDate(true) && this.timeshow.settings.active) {
          clearInterval(this.timeshow.settings.counter);
        }
      },

      execute() {
        this.clear();
        if (this.timeshow.settings.active) {
          this.timeshow.settings.active = false;
        }
      }
    },

    mounted() {
      this.styleNumber();
      this.appearance = this.config.appearance[this.config.theme];
    }
  };
</script>

<style lang="scss" scoped>


  [data-device="mobile"] #editor-content{
    .timer-show-container{
      width: 100%!important;
      margin: 0 !important;
      padding: 0 5px !important;

      &.timer-show-pill{
        .timer-type-text-number{
          font-size: 28px !important;
        }

        .timer-type-text-title{
          font-size: 15px !important;
        }
      }


      &.timer-show-squares{
        .timer-type-text-number{
          font-size: 28px !important;
        }

        .timer-type-text-title{
          font-size: 15px !important;
        }

        .timer-show-block{
          margin: 0 !important;
          min-width: 60px !important;
        }
      }

      &.timer-show-text{
        .timer-type-text-title-helper{
          font-size: 18px !important;
        }

        .timer-type-text-number, .timer-type-text-title{
          font-size: 30px !important;
        }
      }

      &.timer-show-simple{
        .timer-show-element{
          margin: 0 5px !important;
        }

        .timer-type-text-number{
          font-size: 30px !important;
        }
        .timer-type-text-title{
          font-size: 15px !important;
        }
      }
    }
  }

  @media (max-width: 600px) {
    .timer-show-container{
      width: 100%!important;
      margin: 0 !important;
      padding: 0 5px !important;

      &.timer-show-pill{
        .timer-type-text-number{
          font-size: 28px !important;
        }

        .timer-type-text-title{
          font-size: 15px !important;
        }
      }


      &.timer-show-squares{
        padding: 0!important;

        .timer-type-text-number{
          font-size: 25px !important;
        }

        .timer-type-text-title{
          font-size: 15px !important;
        }

        .timer-show-block{
          margin: 0 2px!important;
          min-width: 60px !important;
        }
      }

      &.timer-show-text{
        .timer-type-text-title-helper{
          font-size: 18px !important;
        }

        .timer-type-text-number, .timer-type-text-title{
          font-size: 30px !important;
        }
      }

      &.timer-show-simple{
        .timer-show-element{
          margin: 0 5px !important;
        }

        .timer-type-text-number{
          font-size: 30px !important;
        }
        .timer-type-text-title{
          font-size: 15px !important;
        }
      }
    }
  }

  @media (max-width: 980px) {
    .timer-show-container{
      width: 85%;
      margin: 0 auto;
    }
  }

  .timer-show-container{
    height: 100%;
    text-align: center;
    white-space: nowrap;
    line-height: normal;

    .timer-show-content{
      .timer-show-block{
        height: 100%;
        width: auto;
        flex: 1;
        text-align: center;


        .timer-show-element{
          display: inline-block;
          border-radius: 3px;
          width: 100%;
          height: 100%;
        }
      }
    }

    &.timer-show-squares{

      .timer-show-block{
        background-color: rgb(0, 156, 155);
        border-radius: 12px;
        padding: .5rem .5rem .5rem .5rem;
        margin: 0 3px;
        min-width: 75px;

        .timer-show-element{
          display: inline-block;
          font-family: sans-serif;
          border-radius: 3px;
          width: 100%;
          height: 100%;
        }

        .timer-type-text-number{
          display: block;
          width: 100%;
          height: 90%;
          background-color: rgb(0, 192, 191);
          border-radius: 3px;
          padding: .5rem .25rem;
        }

        .timer-type-text-title{
          width: 100%;
          height: 10%;
          padding-top: .25rem;
          display: block;
        }
      }
    }

    &.timer-show-pill {

      .timer-show-content {
        display: flex;
      }

      .timer-show-block {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 50px;
        padding: 10px;
        border-radius: 3px;
        border-right: 1px solid rgba(255, 255, 255, 0.45);

        &:first-child {
          border-top-left-radius: 50px;
          border-bottom-left-radius: 50px;
        }

        &:last-child {
          border-top-right-radius: 50px;
          border-bottom-right-radius: 50px;
        }

        .timer-show-element {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .timer-type-text-number {
          height: 75%;
        }

        .timer-type-text-title {
          padding: 3px 10px 3px 10px;
        }
      }
    }

    &.timer-show-simple {

      .timer-show-block {
        min-width: 50px;

        .timer-show-element {
          margin: 0 10px;
        }

        .timer-type-text-number {
          font-size: 65px;
        }

        .timer-type-text-title {
          font-size: 30px;
          text-transform: uppercase;
        }
      }
    }

    &.timer-show-text {

      .timer-type-text-title-helper {
        display: block;
        text-align: center;
      }

      .timer-show-content {
        text-align: center;
      }

      .timer-show-block {

        .timer-show-element {
          margin: 0 3px;
        }

        .timer-show-element {
          display: flex;
          flex-direction: row;

          .timer-type-text-number, .timer-type-text-title {
            letter-spacing: 2px;
          }
        }
      }
    }
  }
</style>
