
import http from '@/http';

const isJson = (data) => {
    try {
        return JSON.parse(data);
    } catch (e) {
        return false;
    }
};

const getTypeAuth = (loginUser) => {

    if (isJson(loginUser)) {
        let user = JSON.parse(loginUser);
        if (typeof user.type !== 'undefined' && user.type === 'token')
            return 'token';
        return 'login'
    }
    return false
};

const storageON  = () => {
    try {
        localStorage.setItem("__test", "data");
    } catch (e) {
        return false;
    }
    return true;
}

const state = {
    website_user: { // Member area user
        user: storageON() ? (isJson(localStorage.getItem('login-user')) || false) : false,
        token: storageON() ? (localStorage.getItem('login-user-token') || '') : '',
        token_md5: storageON() ? (localStorage.getItem('login-user-token-md5') || '') : '',
        expire: '',
        type: storageON() ? (getTypeAuth(localStorage.getItem('login-user')) || false) : false,
        restrict_permissions: {},
        members_area: false,
    },
};

const mutations = {
    DEFINIR_USUARIO_LOGADO(state, { token, token_md5, expire, user }) {
        state.website_user = {
            user: user,
            token: token,
            token_md5: token_md5,
            expire: expire,
        };
    },

    DEFINIR_USUARIO(state, {name, email, token_md5 }) {
        state.website_user.user = {
            name:  name,
            email: email
        };

        localStorage.setItem('login-user', JSON.stringify(state.website_user.user));
        localStorage.setItem('login-user-all', JSON.stringify(state.website_user));

        if(token_md5){
            localStorage.setItem('login-user-token-md5', token_md5);
        }
    },

    DEFINIR_USUARIO_PERMISSIONS(state, infos) {
        state.website_user.restrict_permissions = infos;
    },

    DEFINIR_USUARIO_MEMBERSAREA(state, areas) {
        state.website_user.members_area = areas;
    },
    DESLOGAR_USUARIO(state) {
        state.website_user = {
            user: false,
            type: false,
            token: '',
            token_md5: '',
            expire: ''
        };
    }
};

const actions = {
    efetuarLogin({ commit }, data) {
        commit('DEFINIR_USUARIO_LOGADO', data);
    },
    setUser({ commit }, data) {
        commit('DEFINIR_USUARIO', data);
    },
    setPermissions({ commit }, data) {
        commit('DEFINIR_USUARIO_PERMISSIONS', data);
    },
    setMembersAreas({ commit }, data) {
        commit('DEFINIR_USUARIO_MEMBERSAREA', data);
    },
    efetuarLogout({ commit }) {
        localStorage.removeItem('login-user');
        localStorage.removeItem('login-user-all');
        localStorage.removeItem('login-user-token');
        localStorage.removeItem('login-user-token-md5');

        commit('DESLOGAR_USUARIO');

    },
};

const getters = {
    isAuthenticated: state => !!state.website_user.token,
    typeAuthentication: state => state.website_user.type,
    userWebsite: state => state.website_user,
    userWebsiteName: state => state.website_user.user.name,
    usuarioEstaLogado: state => state
};

export default {
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
