export default {
  data() {
    return {
      tabActive: 'normal',
      sidebar: '2',
      keyChange: 0,
      props: {
        effect: '3',
        direction: 'left_to_right',
        'tag': '',
        'width': '300px',
        'zIndex': 'auto',
        'maxWidth': '500px',
        'height': '300px',
        // Image hover
        cover: {
          'backgroundColor': '#FFFFFF',
          'backgroundImage': 'none', /*url("photographer.jpg"); The image used */
          'backgroundPosition': 'center', /* left top | left center | left bottom | right top | right center | right bottom | center top | center center | center bottom */
          'backgroundOrigin': 'padding-box', /* padding-box|border-box|content-box Do not repeat the image */
          'backgroundRepeat': 'no-repeat', /*repeat | repeat-x | repeat-y | no-repeat | space | round   Do not repeat the image */
          'backgroundSize': '100% 100%', /* 'auto | 50% | 100% 100% | cover | contain  'Resize the background image to cover the entire container */
        },

        hover: {
          'backgroundColor': '#FFFFFF',
          'backgroundImage': 'none', /*url("photographer.jpg"); The image used */
          'backgroundPosition': 'center', /* left top | left center | left bottom | right top | right center | right bottom | center top | center center | center bottom */
          'backgroundOrigin': 'border-box', /* padding-box|border-box|content-box Do not repeat the image */
          'backgroundRepeat': 'no-repeat', /*repeat | repeat-x | repeat-y | no-repeat | space | round   Do not repeat the image */
          'backgroundSize': 'cover', /* 'auto | 50% | 100% 100% | cover | contain  'Resize the background image to cover the entire container */
        },

        'minHeight': '50px',
        'border': '0 none #000000',
        'borderRadius': '0 0 0 0',
        'boxShadow': '0 0 0 0 black initial',
        'paddingTop': '15px',
        'paddingLeft': '15px',
        'paddingRight': '15px',
        'paddingBottom': '15px',
        'parallaxActive': false,
        'parallaxEffect': 'scroll',
        'parallaxSpeed': 20,
      },
      elements: [],
      internal_config: {
        effect: '3',
        width: '300px',
        height: '300px',
        'minHeight': '50px',
        'zIndex': 'auto', 'maxWidth': '500px',
        borderRadius: '0 0 0 0',
        'parallaxActive' : false,
        'parallaxEffect' : 'scroll',
        'parallaxSpeed' : 20,
        'paddingTop': '15px',
        'paddingLeft': '15px',
        'paddingRight': '15px',
        'paddingBottom': '15px',
      }
    }
  }
}
