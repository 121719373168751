<template>
  <div
      class="sitebuilder-element-container"
      element="PandaVideoComponent"
      :data-hide-desktop="!this.internal_config.showDesktop"
      :data-hide-mobile="!this.internal_config.showMobile"
      :id="id"
      :style="style()"
  >
    <div class="css">
      <div id="the-sticky-div" class="embed-responsive embed-responsive-16by9">
        <div class="sticky-video-content">
          <div class="video">
            <iframe v-if="internal_config.videoConfigs"
                    :id="internal_config.videoConfigs.id"
                    :src="getUrl()"
                    :width="internal_config.width"
                    :height="internal_config.height"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    class="embed-responsive-item"
                    v-bind:style="{'border-radius': radius,border : border}"/>
            <div class="embed-responsive-item" v-else>
              <div
                  style="background-color: white; height: 200px; width: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column">
                <h6 class="text-center">{{ $t('ct-select-video-panda') }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Model from "./Model";

export default {
  name: "PandaVideoComponent",
  components: {},
  props: ["config", "height_start", "device", "id", "preview"],
  data() {
    return Model.data();
  },

  computed: {
    width() {
      return this.internal_config.width;
    },
    height() {
      return this.internal_config.height;
    },
    border() {
      return this.internal_config.border;
    },
    radius() {
      return this.internal_config.borderRadius;
    }
  },
  methods: {
    getUrl() {
      let url = this.internal_config.videoConfigs.video_player;
      let linkTemp = document.createElement('a');
      linkTemp.href = url;
      let search = linkTemp.search !== '';

      url =  url + ((search) ? '&' : '?');

      if (this.internal_config.checkBoxAutoPlay) {
        url = url + "autoplay=true";
      }

      return url;
    },
    animation() {
      return true;
    },
    style() {
      let style = `left:  ${this.internal_config.left};
					 z-index: ${this.internal_config.zIndex};
					 max-width: ${this.internal_config.maxWidth};
					 margin: 0 auto;
					 width: ${this.internal_config.width};
					 height: ${this.internal_config.height};
					 padding-top: ${this.internal_config.paddingTop};
					 padding-left: ${this.internal_config.paddingLeft};
           padding-right: ${this.internal_config.paddingRight};
           padding-bottom: ${this.internal_config.paddingBottom};
					 top: ${this.internal_config.top};
					 transform: rotate(${this.$props.device == "desktop" ? this.internal_config.rotation : 0}) scale(1)`;
      return style;
    },
    addScriptOnHead() {
      if (!document.querySelector('script[src^="https://player.pandavideo.com.br/api.v2.js"]')) {
        let s = document.createElement('script');
        s.src = 'https://player.pandavideo.com.br/api.v2.js?nowprocket&version=1665967882479';
        s.async = true;
        document.head.appendChild(s);
      }
    },
  },
  created() {
    let device, childs, id, config;
    ({device, childs, id, config} = this.$props);
    this.internal_config = config;
    this.device = device;
    if (typeof id !== "undefined") {
      this.id = id;
    }
    this.internal_config = window.mergeRecursive(
        this.props,
        this.$props.config
    );
  },
  mounted() {
    this.addScriptOnHead();

    this.$root.$emit("applyAnimation", this, "sitebuilder-element-container");

  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#the-sticky-div {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
</style>
