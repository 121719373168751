<template>
  <div :id="this.id"
       class="sitebuilder-element-container card big-is-receiver"
       element="PlaceMenuComponent"
       :data-hide-desktop="!this.internal_config.showDesktop"
       :data-hide-mobile="!this.internal_config.showMobile"
       :class="{'place-menu-collapse-show-on-hover': isOpenContainerByHover}"
       :style="style()">
    <div class="card-header" :id="'heading'+this.id"
         @click="collapseThis($event)"
         @mouseover="handleMouseover($event)"
         @mouseleave="handleMouseleave($event)"
         :style="{
            background: background,
            borderRadius: radius,
            height : height,
            border: '0 !important'
        }">
      <h5 class="mb-0 clean-text-css h-100">
        <button class="btn btn-link w-100 h-100 icon-place desktop-element big-menu-text" :style="textStyle()">
          {{ internal_config.text }}
        </button>
        <button class="btn btn-link w-100 h-100 icon-place  mobile-element big-menu-text" :style="textStyleMobile()">
          {{ internal_config.text }}
        </button>
      </h5>
    </div>
    <div class="place-menu-collapse-divisor"
         :style="{
      height: `${bottomMargin}px`,
      top: `${parseInt(this.height)}px`
    }" v-if="isOpenContainerByHover"></div>
    <div :id="'place-menu-'+this.id"
         class="place-menu-collapse card-body big-menu-place-childs"
         v-bind:style="getStyle()">
      <div
          :class="'sitebuilder-element-receiver real-place big-is-receiver '  + (this.internal_config.positionContainer === 'width-full' ? 'container' : '')">
        <component v-for="(element,index) in elements" :is="element.name" :config="element.config" :key="index"
                   :device="device"
                   :is_popup="is_popup"
                   :id="element.id" :height_start="index" :preview="preview" :childs="element.childs"></component>
      </div>
    </div>
  </div>
</template>

<script>

import Data from './Model';

export default {
  name: 'PlaceMenuComponent',
  props: ['config', 'childs', 'height_start', 'device', 'id', 'preview', 'is_popup'],
  computed: {
    height() {
      return this.internal_config.height;
    },
    bottomMargin() {
      return typeof this.internal_config.bottomMargin !== 'undefined' ? this.internal_config.bottomMargin : 0;
    },
    border() {
      return this.internal_config.border;
    },
    radius() {
      return this.internal_config.borderRadius;
    },
    background() {
      return this.internal_config.backgroundColor;
    },
    isOpenContainerByHover() {
      return !this.internal_config.enableOnlyLink && this.internal_config.typeOpenContainer === 'by-hover'
    },
    heightContainer() {
      return this.internal_config.heightContainer;
    },
    widthContainer() {
      if (this.internal_config.positionContainer === 'width-full')
        return document.getElementById('editor-content').getBoundingClientRect().width + 'px';

      const widthContainer = parseInt(this.internal_config.widthContainer) + parseInt(this.internal_config.leftMargin);
      return `${widthContainer}px`;
    },
    borderContainer() {
      return this.internal_config.borderContainer;
    },
    topContainer() {
      return `${parseInt(this.height) + parseInt(this.bottomMargin)}px`;
    },
    leftMarginContainer() {
      return `-${this.internal_config.leftMargin}px`;
    },
    leftContainer() {
      if (this.internal_config.positionContainer === 'width-full') {

        let rect = document.getElementById(this.id).getBoundingClientRect();
        let docEl = document.documentElement;

        return (rect.left + (window.pageXOffset || docEl.scrollLeft || 0)) * -1 + 'px';
      } else if (this.internal_config.positionContainer === 'start-menu-item') {
        return '0px';
      }

      return this.internal_config.leftContainer;
    },
    radiusContainer() {
      return this.internal_config.borderRadiusContainer;
    },
    backgroundContainer() {
      return this.internal_config.backgroundColorContainer;
    },
  },
  data() {
    return Data.data();
  },
  methods: {
    getLeftContainer() {
      console.log(this.internal_config.positionContainer);
      if (this.internal_config.positionContainer === 'width-full') {

        let rect = document.getElementById(this.id).getBoundingClientRect();
        let docEl = document.documentElement;

        return (rect.left + (window.pageXOffset || docEl.scrollLeft || 0)) * -1 + 'px';
      } else if (this.internal_config.positionContainer === 'start-menu-item') {
        return '0px';
      }

      return this.internal_config.leftContainer;
    },

    getStyle() {
      return {
        borderRadius: this.radiusContainer,
        background: this.backgroundContainer,
        marginLeft: this.leftMarginContainer,
        left: this.leftContainer,
        top: this.topContainer,
        height: this.heightContainer,
        width: this.widthContainer,
        border: this.borderContainer
      };
    },
    handleMouseover() {
      if (this.internal_config.typeOpenContainer === 'by-hover') {
        $('.place-menu-collapse:not(#place-menu-' + this.id)
            .hide();

        $('.big-menu-text').removeClass('selected');
        $('.zindexBig').removeClass('zindexBig');
      }
    },
    handleMouseleave() {
      if (this.internal_config.typeOpenContainer === 'by-hover') {
      }
    },
    collapseThis(e) {
      e.stopPropagation();
      e.preventDefault();

      $('.place-menu-collapse:not(#place-menu-' + this.id)
          .hide();

      $('.big-menu-text').removeClass('selected');
      $('.zindexBig').removeClass('zindexBig');

      if (this.internal_config.enableOnlyLink) {
        this.$root.$emit('linkAction', this.internal_config.link);

        return;
      }

      let elem = $('#place-menu-' + this.id);
      if (elem.is(":visible")) {
        elem.hide();
        document.getElementById('page-mask').hidden = true;
      } else {
        document.getElementById('page-mask').hidden = false;
        elem.show().parents('.big-is-receiver.card').find('.big-menu-text').addClass('selected');

        elem.show().parents('[element="GroupMenuComponent"]').addClass('zindexBig');
        this.internal_config.leftContainer = (document.getElementById(this.id).offsetLeft * -1) + 'px';
        elem.css({'left': this.getLeftContainer()});

        document.body.style.setProperty('--big-menu-item-background--selected', this.internal_config.backgroundColorSelected);
        document.body.style.setProperty('--big-menu-item-color--selected', this.internal_config.textColorSelected);
      }

    },
    style() {
      return `left:  ${this.internal_config.left};
                top: ${this.internal_config.top};
                padding-top: ${this.internal_config.paddingTop};
                padding-left: ${this.internal_config.paddingLeft};
                padding-right: ${this.internal_config.paddingRight};
                padding-bottom: ${this.internal_config.paddingBottom};
                z-index: ${this.internal_config.zIndex};
                width: ${this.internal_config.width};
                max-width: ${this.internal_config.maxWidth};
                border: 0 !important;
                margin: 0 auto;`;
    },
    build() {

      this.buildIcon();
      $('.place-menu-collapse').hide();

      this.internal_config.leftContainer = (document.getElementById(this.id).offsetLeft * -1) + 'px';

      let vm = this;
      this.$el.addEventListener('mouseover', function () {
        document.body.style.setProperty('--big-menu-item-background--hover', vm.internal_config.backgroundColorHover);
        document.body.style.setProperty('--big-menu-item-color--hover', vm.internal_config.textColorHover);
      });
    },

    textStyle() {

      if (typeof this.internal_config.textStyle == 'undefined') {
        this.internal_config.textStyle = {
          'family': 'arial, helvetica, sans-serif',
          'size': '15px',
          'sizeMobile': '15px',
          'color': 'White',
          'align': 'center',
          'weight': '700',
          'style': 'initial',
          'stroke': 'none'
        }
      }
      return {
        fontFamily: this.internal_config.textStyle.family,
        fontSize: this.internal_config.textStyle.size + ' !important',
        textAlign: this.internal_config.textStyle.align,
        fontWeight: this.internal_config.textStyle.weight,
        fontStyle: this.internal_config.textStyle.style,
        textDecoration: this.internal_config.textStyle.stroke,
        color: this.internal_config.textStyle.color,
        border: this.internal_config.border + ' !important',
        borderRadius: this.internal_config.borderRadius,
      };
    },
    textStyleMobile() {

      if (typeof this.internal_config.textStyle == 'undefined') {
        this.internal_config.textStyle = {
          'family': 'arial, helvetica, sans-serif',
          'size': '15px',
          'sizeMobile': '15px',
          'color': 'White',
          'align': 'center',
          'weight': '700',
          'style': 'initial',
          'stroke': 'none'
        }
      }

      return {
        fontFamily: this.internal_config.textStyle.family,
        fontSize: this.internal_config.textStyle.sizeMobile + ' !important',
        textAlign: this.internal_config.textStyle.align,
        fontWeight: this.internal_config.textStyle.weight,
        fontStyle: this.internal_config.textStyle.style,
        textDecoration: this.internal_config.textStyle.stroke,
        color: this.internal_config.textStyle.color,
        border: this.internal_config.border + ' !important',
        borderRadius: this.internal_config.borderRadius,
      };
    },
    buildIcon: function () {
      $(this.$el).find('.icon-place.big-menu-text i').detach();

      if (this.internal_config.icon !== '') {
        if (this.internal_config.iconPosition === 'left') {
          $('<i>').addClass('mdi ' + this.internal_config.icon).prependTo($(this.$el).find('.icon-place').get(0));
          $('<i>').addClass('mdi ' + this.internal_config.icon).prependTo($(this.$el).find('.icon-place').get(1));
        }
        if (this.internal_config.iconPosition === 'right') {
          $('<i>').addClass('mdi ' + this.internal_config.icon).appendTo($(this.$el).find('.icon-place').get(0));
          $('<i>').addClass('mdi ' + this.internal_config.icon).appendTo($(this.$el).find('.icon-place').get(1));
        }
      }
    },
  },
  mounted() {

    let device, childs, id, config;
    ({device, childs, id, config} = this.$props);

    this.internal_config = config;
    this.device = device;

    if (typeof childs === 'undefined') {
      childs = [];
      this.internal_config.childs = [];
    }
    this.elements = childs.sort((a, b) => (a.config.order_mobile > b.config.order_mobile) ? 1 : ((b.config.order_mobile > a.config.order_mobile) ? -1 : 0));

    this.$nextTick(() => {
      let internal_config = window.mergeRecursive(this.props, this.$props.config);
      this.internal_config = {...internal_config, ...this.internal_config};

      this.build();

      if (this.internal_config.widthContainerFull) {
        this.internal_config.positionContainer = 'width-full';
      }
    });
  }
}

</script>
<style lang="scss" scoped>
.place-menu-collapse-divisor {
  position: absolute;
  width: 100%;
  top: 40px;
  display: none;
}

.place-menu-collapse-show-on-hover {
  &:hover {

    .place-menu-collapse, .place-menu-collapse-divisor {
      display: block !important;
    }
  }
}

.card-body {
  position: relative;
}

.sitebuilder-element-container.card {
  height: auto !important;
  width: 100% !important;
  top: 0 !important;
  left: 0 !important;
  position: relative;
  background-color: transparent !important;

  .real-place {
    height: 100%;
  }
}

[data-device="tablet"],
[data-device="mobile"] {

  .big-menu-place-childs,
  .real-place {
    height: auto !important;
    width: 100% !important;
    top: 0 !important;
    left: 0 !important;
  }

  .card-header {
    height: auto !important;
  }

  .mobile-element.big-menu-text {
    display: block !important;
  }
}

.card-header {
  padding: 0 !important;
}

.place-menu-collapse {
  position: relative;
}

[data-device="desktop"] {
  .big-menu-place-childs {
    position: absolute;
    z-index: 99 !important;

    .real-place {
      position: relative;
    }
  }
}

.big-menu-text {

  display: block;

  &:hover {
    background: var(--big-menu-item-background--hover);
    color: var(--big-menu-item-color--hover) !important;
  }

  &.selected {
    background: var(--big-menu-item-background--selected);
    color: var(--big-menu-item-color--selected) !important;
  }
}

.change-place-item-blue {
  display: none;
}

[data-device="desktop"] {

  .desktop-element.big-menu-text {
    display: block !important;
  }

  [element="GroupMenuComponent"] {


    .change-place-item-blue {
      display: block;
    }

    /*.change-place-item-blue-prev:first-of-type,*/
    /*.change-place-item-blue-next:last-of-type {*/
    /*  display: none;*/
    /*}*/

  }

}


</style>
