export default {
  data() {
    return {
      'originUid': '',
      iconLoading: false,
      errorMessage: [],
      idSimpleForm: 1 + Math.floor((200000 - 1) * Math.random()),
      props: {
        'tag': '',
        'originUid': '',
        'zIndex': 'auto','maxWidth':'768px',
        'rotation': '0deg',

        'socialproof_campaign_id': '0',
        'socialproof_user_name_tag': 'FNAME',



        horizontal_config: {
          custom: false,
          container : { width: 50 },
          btnPlace : { width: 50 , padding: 15},
          fields: []
        },
        newField: {
          "tag": "EMAIL",
          "label": "Email",
          "required": 'yes',
          "help_text": null,
          "visibility": 'visible',
          "default_value": null,
          "type": {"name": "Text", "identifier": "text", "description": "Text"},
          "options": []
        },
        'fields': [{
          "tag": "EMAIL",
          "label": "Email",
          "required": "yes",
          "help_text": null,
          "visibility": "visible",
          "default_value": null,
          "type": {"name": "Text", "identifier": "text", "description": "Text"},
          "options": []
        }],
        'target': '_self',
        'action': '',
        'receiver': '',
        'subject': '',
        'showLabel': true,
        'showPlaceholder': true,
        'placeholderColor': '#5c5c5c',
        'recaptchaSiteKey': '',
        'recaptchaSecretKey': '',

        'left': '0',
        'top': '0',

        'width': '300px',
        'height': '300px',
        'border': '0 none #000000',

        'paddingTop': '15px',
        'paddingLeft': '15px',
        'paddingRight': '15px',
        'paddingBottom': '15px',

        'inputHeight': '38px',
        'textareaHeight': '',
        'inputBgColor': '#FFFFFF',
        'inputBorder': '1px solid #000000',
        'inputRadius': '0 0 0 0',

        'inputStyle': {
          'family': 'Arial',
          'size': '20px',
          'color': '#000000',
          'align': 'left',
          'weight': '400',
          'style': 'initial',
        },
        'animation': {
          'in': {
            'action': 'time',   //time/scroll/click/hover
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'speed': 'slow',
            'infinite': false,
          },
          'out': {
            'action': 'time',   //time/scroll/click
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'delay': '1',
            'speed': '1',
            'infinite': false,
          },
        },
        'button': {
          'height': '50px',
          'width': '50%',
          'text': 'My Button',
          'subText': '',
          'icon': '',
          'iconPosition': 'left',
          'border': '0 none #000000',
          'borderRadius': '0 0 0 0',
          'backgroundColor': '#21309b',
          'backgroundColorHover': '#21309b',
          'backgroundImage': 'none',/*url("photographer.jpg"); The image used */
          'backgroundPosition': 'center', /* Center the image todo this resource, not possible change at the moment */
          'backgroundOrigin': 'border-box', /* padding-box|border-box|content-box Do not repeat the image */
          'backgroundRepeat': 'no-repeat', /*repeat | repeat-x | repeat-y | no-repeat | space | round   Do not repeat the image */
          'backgroundSize': 'cover', /* 'auto | 50% | 100% 100% | cover | contain  'Resize the background image to cover the entire container */
          'boxShadow': '1px 1px 1px 1px blue',
          'margin': '15px',
          'textStyle': {
            'family': 'arial, helvetica, sans-serif',
            'size': '15px',
            'color': 'White',
            'align': 'center',
            'weight': '700',
            'style': 'initial',
          },
          'subTextStyle': {
            'family': 'arial, helvetica, sans-serif',
            'size': '15px',
            'color': 'White',
            'align': 'center',
            'weight': '400',
            'style': 'initial',
          },
        },
      },
      internal_config: {
        'zIndex': 'auto','maxWidth':'768px',
        'rotation': '0deg',
        'fields': [{
          "tag": "EMAIL",
          "label": "Email",
          "required": "yes",
          "help_text": null,
          "visibility": "visible",
          "default_value": null,
          "type": {"name": "Text", "identifier": "text", "description": "Text"},
          "options": []
        }],

        'left': '0',
        'top': '0',

        'target': '_self',
        'action': '',
        'receiver': '',
        'subject': '',
        'showLabel': true,
        'showPlaceholder': true,
        'placeholderColor': '#5c5c5c',
        'recaptchaSiteKey': '',
        'recaptchaSecretKey': '',

        'width': '300px',
        'height': '300px',
        'border': '0 none #000000',

        'paddingTop': '15px',
        'paddingLeft': '15px',
        'paddingRight': '15px',
        'paddingBottom': '15px',

        'inputHeight': '38px',
        'inputBgColor': '#FFFFFF',
        'inputBorder': '1px solid #000000',
        'inputRadius': '0 0 0 0',

        'inputStyle': {
          'family': 'Arial',
          'size': '20px',
          'color': '#000000',
          'align': 'left',
          'weight': '400',
          'style': 'initial',
        },
        'animation': {
          'in': {
            'action': 'time',   //time/scroll/click/hover
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'speed': 'slow',
            'infinite': false,
          },
          'out': {
            'action': 'time',   //time/scroll/click
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'delay': '1',
            'speed': '1',
            'infinite': false,
          },
        },
        newField: {
          "tag": "EMAIL",
          "label": "Email",
          "required": "yes",
          "help_text": null,
          "visibility": "visible",
          "default_value": null,
          "type": {"name": "Text", "identifier": "text", "description": "Text"},
          "options": []
        },
        'button': {
          'height': '50px',
          'width': '50%',
          'text': 'My Button',
          'subText': '',
          'icon': '',
          'iconPosition': 'left',
          'border': '0 none #000000',
          'borderRadius': '0 0 0 0',
          'backgroundColor': '#21309b',
          'backgroundColorHover': '#21309b',
          'backgroundImage': 'none',/*url("photographer.jpg"); The image used */
          'backgroundPosition': 'center', /* Center the image todo this resource, not possible change at the moment */
          'backgroundOrigin': 'border-box', /* padding-box|border-box|content-box Do not repeat the image */
          'backgroundRepeat': 'no-repeat', /*repeat | repeat-x | repeat-y | no-repeat | space | round   Do not repeat the image */
          'backgroundSize': 'cover', /* 'auto | 50% | 100% 100% | cover | contain  'Resize the background image to cover the entire container */
          'boxShadow': '1px 1px 1px 1px blue',
          'margin': '15px',
          'textStyle': {
            'family': 'arial, helvetica, sans-serif',
            'size': '15px',
            'color': 'White',
            'align': 'center',
            'weight': '700',
            'style': 'initial',
          },
          'subTextStyle': {
            'family': 'arial, helvetica, sans-serif',
            'size': '15px',
            'color': 'White',
            'align': 'center',
            'weight': '400',
            'style': 'initial',
          },
        },
      }
    }
  }
}
