.<template>

    <div>
      <div>
            
         <div class="container my-5">
          <div class="row">
            <div v-for="post in options.posts" :key="post.number" class="col-blog">
              <div class="component-card">
                <div class="component-card_image">
                  <div class="component-card_image-inside">
                    <img :src="'https://omb11.com' + post.thumbnail" alt="" title="" style="" />
                  </div>
                </div>
                <div class="blog-detail">
                  <h3 :style="options.titleStyle" class="clean-text-css">{{ post.title }}</h3>
                  <h3 :style="options.titleStyleMobile" class="clean-text-css mobile-element">{{ post.title }}</h3>
                  <label >{{ post.date_create }}</label>
                  <p :style="options.contentStyle" class="content-preview" v-html="post.content.substr(0, 500).toLowerCase()"></p>
                  <p :style="options.contentStyleMobile" class="content-preview mobile-element" v-html="post.content.substr(0, 500).toLowerCase()"></p>
                  <a v-if="post.readEnable" :style="{color: options.colorReadMore }" @click="redir(post.url)" class="btn btn-read-more"  href="#">{{ options.textReadMore }} </a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  
</template>

<script>
export default {
    name: 'FeedNewsThemeGrid',
    props: ['options'], 
    created(){
        let root = document.documentElement;
        root.style.setProperty('--btn-theme-grid-color-readmore', this.options.colorReadMore);
        root.style.setProperty('--btn-theme-grid-color-details', this.options.colorDetails);
        root.style.setProperty('--btn-theme-grid-color-border', '2px solid '+this.options.colorReadMore);
        root.style.setProperty('--btn-theme-grid-hover-color', this.options.colorReadMore);
        root.style.setProperty('--btn-theme-grid-color', this.options.colorDetails);
      },
    watch:{
      options(){
        let root = document.documentElement;
        root.style.setProperty('--btn-theme-grid-color-readmore', this.options.colorReadMore);
        root.style.setProperty('--btn-theme-grid-color-details', this.options.colorDetails);
        root.style.setProperty('--btn-theme-grid-color-border', '2px solid '+this.options.colorReadMore);
        root.style.setProperty('--btn-theme-grid-hover-color', this.options.colorReadMore);
        root.style.setProperty('--btn-theme-grid-color', this.options.colorDetails);
      }
    },
    methods:{
     redir(string){
        window.location.href =   string;
      },
    }
}
</script>
<style scoped>



.col-blog {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

  [data-device="mobile"] .col-blog {
   -ms-flex: 0 0 50%;
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }

  .content-preview{
    max-height: 80px;
    overflow: hidden;
  }


[data-device="mobile"] .content-preview{
  display: none;
}

.component-card:hover img, .component-card:focus img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.component-card_image {
  background: #fff;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.2%;
  position: relative;
}
.component-card_image-inside {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.component-card_image-inside img {
  background-size: cover;
  height: auto !important;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: all .25s ease-in-out;
  transition: all .25s ease-in-out;
  width: 100%;
}
.component-card .blog-detail {
  background: #fff;
  margin: 0 20px;
  padding: 20px;
  position: relative;
  top: -80px;
}
.component-card .blog-detail h3 {
  font-size: 22px;
  margin: 0;
  text-transform: uppercase;
}
.component-card .blog-detail label {
  color: #737373;
  font-size: 14px;
}
.component-card .blog-detail p {
  margin-bottom: 1rem;
  margin-top: 0;
}
.component-card .blog-detail .btn {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: .25rem;
  color: var(--btn-theme-grid-color-readmore);
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: .375rem .75rem;
  text-align: center;
  -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  vertical-align: middle;
}
.component-card .blog-detail .btn:hover {
  background-color: var(--btn-theme-grid-hover-color);
  color: var(--btn-theme-grid-color);
  text-decoration: none;
}
/* 
.btn-read-more :hover{
  background-color: green;
} */

.component-card .blog-detail .btn-read-more {
  background: transparent;
  border-radius: 0;
  border: var(--btn-theme-grid-color-border);
  outline: none;
  text-transform: uppercase;
}


</style>

