class Errors {
    /**
     * Create a new Errors instance.
     */
    constructor() {
        this.errors = {};
    }

    /**
     * Determine if an errors exists for the given field.
     *
     * @param {string} field
     */
    has(field) {
        const checkPath = (obj, path) => {
            if (path.includes('.')) {
                if (obj.hasOwnProperty(path.split('.')[0])) {
                    return checkPath(
                        obj[path.split('.')[0]],
                        path
                            .split('.')
                            .slice(1)
                            .join('.')
                    );
                } else return false;
            } else {
                return obj.hasOwnProperty(path);
            }
        };

        if (field.includes('.')) {
            return checkPath(this.errors, field);
        } else return this.errors.hasOwnProperty(field);
    }

    /**
     * Determine if we have any errors.
     */
    any() {
        this.reload(this.errors);

        return Object.keys(this.errors).length > 0;
    }
    /**
     * Retrieve the error message for a field.
     *
     * @param {string} field
     */
    get(field) {
        const checkMsg = (obj, path) => {
            if (path.includes('.')) {
                if (obj.hasOwnProperty(path.split('.')[0])) {
                    return checkMsg(
                        obj[path.split('.')[0]],
                        path
                            .split('.')
                            .slice(1)
                            .join('.')
                    );
                } else return false;
            } else {
                return window.$t(obj.hasOwnProperty(path) ? (Array.isArray(obj[path]) ? obj[path][0] : obj[path]) : '');
            }
        };

        return checkMsg(this.errors, field);
    }

    /**
     * Record the new errors.
     *
     * @param {object} errors
     */
    record(errors) {
        this.errors = errors;
    }
    /**
     * Record the new errors.
     *
     * @param {string} field
     * @param {string} message
     * @param {string|null} name
     */
    add(field, message, name) {
        if (typeof name !== 'undefined') {
            if (!this.errors[name]) this.errors[name] = {};
            this.errors[name][field] = [];
            this.errors[name][field].push(message);
        } else {
            this.errors[field] = [];
            this.errors[field].push(message);
        }

        this.reload(this.errors);
    }

    validateFormat(form, name) {
        let fields = form.querySelectorAll('input,select,textarea');

        let validate = [];

        function add(field, message, name) {
            if (typeof name !== 'undefined') {
                if (!validate[name]) validate[name] = {};
                validate[name][field] = {};
                validate[name][field][0] = message;
            } else {
                validate[field] = [];
                validate[field].push([message]);
            }
        }

        Array.prototype.forEach.call(fields, node => {
            if (node.hasAttribute('required') && !node.value) {
                add(node.name, `The ${node.name} field is required.`, name);
            }

            if (
                node.hasAttribute('maxlength') &&
                node.value &&
                node.value.length > node.getAttribute('maxlength')
            ) {
                add(node.name, `The ${node.name} maxlength.`, name);
            }

            if (
                node.hasAttribute('minlength') &&
                node.value &&
                node.value.length < node.getAttribute('minlength')
            ) {
                add(node.name, `The ${node.name} minlength.`, name);
            }

            if (
                node.hasAttribute('max') &&
                node.value &&
                node.value > node.getAttribute('max')
            ) {
                add(node.name, `The ${node.name} max.`, name);
            }

            if (
                node.hasAttribute('min') &&
                node.value &&
                node.value < node.getAttribute('min')
            ) {
                add(node.name, `The ${node.name} min.`, name);
            }
        });

        this.record(validate);

        return this.any();
    }

    /**
     * Clear one or all error fields.
     *
     * @param {string|null} field
     */
    clear(field) {
        if (field) {
            const checkMsg = (obj, path) => {
                if (path.includes('.')) {
                    if (obj.hasOwnProperty(path.split('.')[0])) {
                        return checkMsg(
                            obj[path.split('.')[0]],
                            path
                                .split('.')
                                .slice(1)
                                .join('.')
                        );
                    } else return false;
                } else {
                    return obj.hasOwnProperty(path) ? delete obj[path] : false;
                }
            };

            return checkMsg(this.errors, field);
        }

        this.errors = {};
    }

    reload(errors) {
        let copy = errors;
        Object.keys(copy).forEach(index => {
            if (
                Object.keys(copy[index]).length === 0 &&
                copy[index].constructor === Object
            ) {
                delete copy[index];
            }
        });

        this.errors = {};
        this.errors = copy;
    }
}
export default Errors;
