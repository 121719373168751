const MixinForm = {
    watch: {
        'device': function () {
            if (typeof this.internal_config.horizontal_config !== 'undefined') {
                var configMobile = (typeof this.internal_config.horizontal_config_mobile !== 'undefined') ? this.internal_config.horizontal_config_mobile : false;
                this.testAlan(this.internal_config.horizontal_config, configMobile)
            }
        }
    },
    computed: {
        buttonMargin() {
            const horizontal = this.internal_config.horizontal
            const margin = this.internal_config.button.margin

            return horizontal ? `0px 0px 0px ${margin}px` : `${margin}px 0px 0px`
        },
    },
    methods: {
        setColumns() {
            if (typeof this.internal_config.columns === 'undefined') {
                return false;
            }

            let container = this.$el.querySelector('.inputs-area');

            if (this.internal_config.columns <= 1) {
                container.classList.remove("form-row");

                container.querySelectorAll('.form-group').forEach(input => {
                    for (let i = 1; i <= 12; i++) {
                        input.parentElement.classList.remove("col-" + i);
                    }
                })

            } else {

                container.classList.add("form-row");

                container.querySelectorAll('.form-group').forEach(input => {
                    for (let i = 1; i <= 12; i++) {
                        input.parentElement.classList.remove("col-" + i);
                    }
                    input.parentElement.classList.add(`col-${12 / this.internal_config.columns}`);
                })

            }
        },

        testAlan(data, dataMobile = false) {

            if (!this.internal_config.horizontal)
                return;


            this.internal_config.horizontal_config = data;
            if (dataMobile) {
                this.internal_config.horizontal_config_mobile = dataMobile;
            }

            let containerFields = this.$el.querySelector('.inputs-area');
            let placeBtn = this.$el.querySelector('.place-btn');
            let btn = this.$el.querySelector('.bt-sb');
            let container = this.$el.querySelector('.inputs-area');
            let dataValues = (this.device === 'mobile' && dataMobile) ? dataMobile : data;

            if (!dataValues.custom) {
                let fields = this.$el.querySelectorAll('.inputs-area [data-field-group-name]')
                fields.forEach((field) => {
                    field.className = '';
                })

                containerFields.style.width = '100%';
                placeBtn.style.width = '100%';
                btn.style.bottom = '15px';
                container.classList.remove("form-row");

                return
            }

            containerFields.style.width = dataValues.container.width + '%';
            placeBtn.style.width = dataValues.btnPlace.width + '%';
            btn.style.bottom = dataValues.btnPlace.padding + 'px';
            container.classList.add("form-row");

            dataValues.fields.forEach((field) => {
                let fieldCurrent = this.$el.querySelector('.inputs-area [data-field-group-name="' + field.name + '"]');
                if (fieldCurrent) {
                    fieldCurrent.className = '';
                    fieldCurrent.classList.add(field.size);
                }
            });

        },
    }
}

export default MixinForm;
