<template>
  <div class="icons">
    <a
            v-if="icons.email"
            :href="`mailto:${icons.email}`"
            target="_blank"
            :style="{color: iconsColor.email}"
    >
      <i class="fa fa-envelope"/>
    </a>
    <a
            v-if="icons.facebook_url"
            :href="icons.facebook_url"
            target="_blank"
            :style="{color: iconsColor.facebook}"
    >
      <i class="fab fa-facebook-f"/>
    </a>
    <a
            v-if="icons.twitter_url"
            :href="icons.twitter_url"
            target="_blank"
            :style="{color: iconsColor.twitter}"
    >
      <i class="fab fa-twitter"/>
    </a>
    <a
            v-if="icons.instagram_url"
            :href="icons.instagram_url"
            target="_blank"
            :style="{color: iconsColor.instagram}"
    >
      <i class="fab fa-instagram"/>
    </a>
    <a
            v-if="icons.linkedin_url"
            :href="icons.linkedin_url"
            target="_blank"
            :style="{color: iconsColor.linkedin}"
    >
      <i class="fab fa-linkedin-in"/>
    </a>
    <a
            v-if="icons.youtube_url"
            :href="icons.youtube_url"
            target="_blank"
            :style="{color: iconsColor.youtube}"
    >
      <i class="fab fa-youtube" />
    </a>
    <a
            v-if="icons.medium_url"
            :href="icons.medium_url"
            target="_blank"
            :style="{color: iconsColor.medium}">
      <i class="fab fa-medium-m" />
    </a>
  </div>
</template>

<script>
  export default {
    name: 'SocialBarSimple',
    props: ['icons'],
    data() {
      return {
        iconsColor: {
          email: '#363636',
          facebook: '#1877f2',
          instagram: '#d00174',
          twitter: '#1da1f2',
          linkedin: '#2867b2',
          youtube: '#ff0000',
          medium: '#12100e'
        }
      };
    },
    mounted() {
      console.log( this.icons )
      // this.config = _.clone(this.params);
    },
  };
</script>

<style lang="scss" scoped>
  @import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css);

  .icons {
    font-size: 1.6rem;

    a + a {
      margin-left: 10px;
    }
  }
</style>
