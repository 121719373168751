<template>
    <div :id="id" element="TextComponent" class="sitebuilder-element-container"
         :style="style()"
         :dir="dir()"
         :data-tag="dataTag()"
         :data-hide-desktop="!this.internal_config.showDesktop"
         :data-hide-mobile="!this.internal_config.showMobile"
         :data-editing="this.editing">
        <div
            :style="`transform: rotate(${this.$props.device === 'desktop' ? this.internal_config.rotation : 0}) scale(1)`">
      <span class="sitebuilder-element desktop-element  gradient-config" v-show="!this.editing"
            :style="'font-size: '+ internal_config.fontSize + ' !important;font-weight: '+ internal_config.weight "
            v-html="this.internal_config.content"></span>
            <span class="text-center-sb sitebuilder-element mobile-element gradient-config"
                  :style="'font-size: '+ internal_config.fontSizeMobile + ' !important;font-weight: '+ internal_config.weight + ';text-align: '+ internal_config.alignMobile + ' !important;'"
                  v-html="mobileContent"></span>
        </div>
    </div>
</template>

<script>

import TextData from './Model';

export default {
    name: 'TextComponent',
    props: ['config', 'id', 'preview', 'device'],
    data() {
        return TextData.data();
    },
    methods: {
        dataTag() {
            if (this.internal_config.tag) {
                return this.internal_config.tag;
            }
            return '';
        },
        fontSizeMobileChange() {

            let elements = document.getElementById(this.$el.id).querySelectorAll('.mobile-element p,.mobile-element span,.mobile-element h1,.mobile-element h2,.mobile-element h3,.mobile-element h4,.mobile-element h5,.mobile-element h6,.mobile-element ul,.mobile-element em,.mobile-element ol,.mobile-element li,.mobile-element big,.mobile-element small');

            for (var i in elements) {
                $(elements[i]).css({'font-size': parseInt(this.internal_config.fontSizeMobile) + 'px'});
            }
        },

        alignMobileChange() {

            let elements = document.getElementById(this.$el.id).querySelectorAll('.mobile-element p,.mobile-element span,.mobile-element h1,.mobile-element h2,.mobile-element h3,.mobile-element h4,.mobile-element h5,.mobile-element h6,.mobile-element ul,.mobile-element em,.mobile-element ol,.mobile-element li,.mobile-element big,.mobile-element small');

            for (var i in elements) {
                $(elements[i]).css({'text-align': this.internal_config.alignMobile});
            }
        },

        linkAction(event) {
            this.$root.$emit('linkAction', event.data.link);
        },

        setLinks() {

            let vm = this;

            let elementsLink = $(`#${vm.id} a[type][value][tel]`);

            if (elementsLink.length === 0) {
                return;
            }

            elementsLink.each(function () {
                let elementLink = $(this);
                let link = {
                    type: elementLink.attr('type'),
                    value: elementLink.attr('value'),
                    valueID: elementLink.attr('valueID'),
                    target: elementLink.attr('target'),
                    tel: elementLink.attr('tel'),
                    telSelectedMask: elementLink.attr('telSelectedMask'),
                    telOpenAs: elementLink.attr('telOpenAs'),
                    secondaryLink: vm.$isJson(elementLink.attr('secondaryLink')) ? JSON.parse(elementLink.attr('secondaryLink')) : false,
                }

                if (['email', 'download', 'builderall'].includes(elementLink.attr('type'))) {
                    if (vm.$isJson(link.value)) {
                        link.value = JSON.parse(link.value);
                    } else {
                        link.value = {
                            name: elementLink.attr('name'),
                            src: elementLink.attr('src')
                        };
                    }
                }

                elementLink.css('cursor', 'pointer');
                $(elementLink).addClass('text-action-call');
                $(elementLink).off('click').on('click', () => {
                    document.getElementById('app').__vue__.callLinkAction(link)
                })
            });
        },

        build(config) {
            this.internal_config = config;
            this.internal_config.content = this.internal_config.content.replace(/&nbsp;/g, ' ');

            if (this.internal_config.content.indexOf('clean-text-css') !== -1) {

                if (this.internal_config.content.indexOf('line-height') !== -1) {
                    this.internal_config.content = this.internal_config.content.replace(/display:block;/g, "");
                    // this.internal_config.content = this.internal_config.content.replace(/line-height:/g, "display:block;line-height:");
                }

            }
            if (this.internal_config.content.indexOf('data-tag') !== -1) {

                try {

                    if (localStorage.getItem('leadName') !== null) {
                        this.internal_config.content = this.internal_config.content.replace('[tag_name_lead]', localStorage.getItem('leadName'));
                    } else {
                        this.internal_config.content = this.internal_config.content.replace('[tag_name_lead]', '');
                    }


                } catch (e) {

                    console.log(e)

                }


                let appendScripts = true;

                if (appendScripts) {

                    setTimeout(function () {
                        // $('[data-tag]').off('click');
                        var s = document.createElement("script");
                        s.type = "text/javascript";
                        s.src = "/static/office/script.js";
                        $("head").append(s);
                        setTimeout(function () {
                            $('.sitebuilder-element [data-tag]').css('display', 'initial');
                        }, 4000);
                    }, 2000);
                }
            }

            setTimeout(() => {

                let all = document.querySelectorAll('[element="TextComponent"] span');

                for (var i = 0, max = all.length; i < max; i++) {
                    all[i].style['-webkit-text-fill-color'] = all[i].style.color;
                }
                all.forEach((el) => {
                    if (el.style.lineHeight != '') {
                        el.classList.add('line-height-aqui');
                    }
                })
            }, 2000);
        },

        style() {

            let width = this.internal_config.width;
            let left = this.internal_config.left;

            if (navigator.userAgent.indexOf("Firefox") > 0) {
                width = (parseInt(width) + 10) + 'px';
                left = (parseInt(left) - 5) + 'px';
            }
            return `width: ${width};
                left:  ${left};
                padding-top: ${this.internal_config.paddingTop};
                padding-left: ${this.internal_config.paddingLeft};
                padding-right: ${this.internal_config.paddingRight};
                     z-index: ${this.internal_config.zIndex};max-width: ${this.internal_config.maxWidth};margin: 0 auto;
                padding-bottom: ${this.internal_config.paddingBottom};
								top: ${this.internal_config.top};`;
        },
        dir() {
            if (
                typeof langUser != 'undefined' && (
                    langUser === 'he' ||
                    langUser === 'ja' ||
                    langUser === 'ar')
            ) {
                return 'rtl';
            } else return false;
        },
    },
    mounted() {
        this.build(this.$props.config);
        Object.keys(this.props).forEach(prop => {
            if (typeof this.internal_config[prop] === 'undefined') {
                this.internal_config[prop] = this.props[prop];
            }
        });

        if(this.internal_config.isGradientText){
          let elem = document.getElementById(this.$el.id).querySelectorAll('.gradient-config');
            for(let i = 0; i < elem.length; i++){
                elem[i].classList.add('gradient-text');
                elem[i].style.backgroundImage = this.internal_config.textGradientColor
            }
        }

        if(this.internal_config.checkBoxTextBackgroundHover){
          let element
          let newColor =  this.internal_config.textBackgroundColorHover
            setTimeout(()=>{
                element = document.getElementById(this.$el.id)
            }, 10);
            document.getElementById(this.$el.id).addEventListener('mouseenter', function () {
                element.style.backgroundColor= newColor;
            })
            document.getElementById(this.$el.id).addEventListener('mouseleave', function () {
                element.style.backgroundColor= "";
            })
        }

        this.fontSizeMobileChange();
        this.alignMobileChange();

        this.$root.$emit('applyAnimation', this, 'sitebuilder-element-container');

        this.$nextTick(() => {
            this.setLinks();
        });
    },
    computed: {
        // internal_config.alignMobile replace editor alignments
        mobileContent: function () {
            return this.internal_config.content.replace(/text-align: left|text-align: right|text-align: center|text-align: justify/g, "text-align: inherit");
        }
    },
    watch: {
        'internal_config.content': function (val) {
            if (val) {
                this.setLinks();
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@media (max-width: 980px) {
    .desktop-element {
        display: none !important;
    }

    .mobile-element {
        display: block !important;
    }
}

.gradient-text{
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent ;
    -moz-text-fill-color: transparent;
  }

@media (min-width: 981px) {
    .desktop-element {
        display: block !important;
    }

    .mobile-element {
        display: none !important;
    }
}

.auto-height {
    height: auto !important;
}

.text-center-sb {
    text-align: center;
}

</style>

