import XDate from 'xdate';

export default {
  data() {
    return {
      props: {
        'tag': '',
        name: 'timer',

        /* Link - BEGIN */
        link: {
          type: 'web',
          value: '',
          target: 'self'
        },
        /* Link - END */

        /* Animation - BEGIN  */
        animation: {
          in: {
            action: 'time', // time/scroll/click/hover
            time: 0, // sec
            scroll: 0, // px
            effect: 'none',
            speed: 'slow',
            infinite: false
          },
          out: {
            action: 'time', // time/scroll/click
            time: 0, // sec
            scroll: 0, // px
            effect: 'none',
            delay: '1',
            speed: '1',
            infinite: false
          }
        },
         /* Animation - END  */

      },

      /* Internal_config - BEGIN  */
      internal_config: {
        id: '',
        width: '150px',
        height: '50px',
        left: '0px',
        top: '0px',
        zIndex: 'auto',
        rotation: '0deg',
        link: {
          type: 'web',
          value: '',
          target: 'self'
        },
        theme: 'panels',
        type: 'decrease_sum_time',
        textDescription: 'Promotion ends in ...',
        date_end: '',
        hours: '14',
        minutes: '30',
        seconds: '20',

        labelTextStyle: {
          family: 'arial, helvetica, sans-serif',
          size: '15px',
          color: '#333',
          align: 'center',
          weight: '700',
          style: 'initial'
        },

        numberTextStyle: {
          family: 'arial, helvetica, sans-serif',
          size: '15px',
          color: '#ccc',
          align: 'center',
          weight: '700',
          style: 'initial'
        },

        /* Config appaarence time by themes */
        appearance: {
          panels: {
            fontColor: '#CCC',
            fontColorLabel: '#000',
            backgroundColor: '#000'
          },
          circles: {
            fontColor: '#2c3e50',
            backgroundColor: '#ff0f0f',
            backgroundColorEffect: '#9c9c9c'
          },
          pill: {
            fontColor: '#FFF',
            backgroundColor: '#b03b25'
          },
          text: {
            fontColor: '#2c3e50',
            fontColorLabel: '#000',
            fontDesc: {
              family: 'arial, helvetica, sans-serif',
              size: '15px',
              color: '#ccc',
              align: 'center',
              weight: '700',
              style: 'initial'
            }
          },
          squares: {
            fontColor: '#2c3e50',
            backgroundColor: '#ff0f0f',
            backgroundColorEffect: '#9c9c9c'
          },
          simple: {
            fontColor: '#2c3e50',
            fontColorLabel: '#000'
          }
        },

        settings: {

          time: {},
          save_time: '',

          gtm_save: '',
          date_finish: new XDate().addDays(7).toString(),

          original_date_finish: '',
          original_hours_finish: '',
          original_minutes_finish: '',

          days_to_sum: '5',
          hours_to_sum: '4',
          minutes_to_sum: '30',
          seconds_to_sum: '20'
        },

      }
      /* Internal_config - END  */

    };
  }
};
