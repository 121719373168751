<template>
    <div class="wrapper-login">
        <span v-text="$t('ct-comment-theme-title')" />
        <div>
          <a 
            :href="`/blog-login?post=${url}`" 
            v-text="$t('ct-sign-up')"
          />
          <a 
            :href="`/blog-login?post=${url}`" 
            v-text="$t('ct-sign-in')"
          />
      </div>
    </div>
</template>

<script>
export default {
    name: 'SimpleTheme',
    props: ['url']
}
</script>

<style lang="scss" scoped>
.wrapper-login {
    display: flex;
    flex-direction: column;
    align-items: center;

    background: rgba(0,0,0, 0.1);
    padding: 40px;

    span {
      font-family: 'Muli', sans-serif;
      font-size: 20px;
      font-weight: 900;
      color: #0294FF;

      margin-bottom: 20px;
    }

    div {
      width: 100%;
      display: flex;
      justify-content: space-around;
    }
  }
</style>
