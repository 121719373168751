<template>
  <div class="image-gallery-tiles-container"
       :style="{
            gridColumnGap: `${internal_config.verticalMargin}px`,
            gridRowGap: `${internal_config.horizontalMargin}px`,
            opacity: opacity
          }">
    <div class="image-gallery-tiles-photo"
         v-for="(image, index) in images"
         :class="`image-gallery-tiles-photo-align-${image.align}`"
         :key="index"
         :style="{
                  backgroundImage: 'url(' + image.src + ')',
                  cursor: $props.preview ? 'pointer': '',
                  borderRadius: radius,
                  border : border
                 }"
         @click="$emit('showPhoto', index)"
    ></div>
  </div>
</template>

<script>
export default {
  name: "TilesGallery",
  props: ['internal_config'],
  computed: {
    minHeight() {
      return this.internal_config.minHeight;
    },
    border() {
      return this.internal_config.border;
    },
    opacity() {
      return this.internal_config.opacity;
    },
    radius() {
      return this.internal_config.borderRadius;
    }
  },

  data() {
    return {
      images: [],
    }
  },

  watch: {
    'internal_config.images': function (newValue) {
      this.build();
    },
    'internal_config.imagesPerPage': function (newValue) {
      this.build();
    },
  },

  methods:{
    build(){
      this.images = [];

      let lastAlign = 'min';

      for( let i=0; i < this.internal_config.images.length; i++){

        var ind = i +1;
        var next = lastAlign === 'min' ? 'max' : 'min';

        if(ind % 2 !== 0){
          next = lastAlign
        }
        lastAlign = next

        this.images.push({
          src: this.internal_config.images[i],
          align: next
        })
      }
    }
  },

  created() {
    this.build();
  }
}
</script>

<style lang="scss"  scoped>

.image-gallery-tiles-container{
  width: 100%;
  display: grid;

  grid-template-columns: repeat(3, 1fr);

  .image-gallery-tiles-photo {
    display: flex;
    overflow: hidden;
    float: left;
    background-position: top center;
    background-size: cover;
    position: relative;
  }

  .image-gallery-tiles-photo-align-max{
    grid-column: auto /span 2;
  }
}
</style>
