<template>
    <div
        :id="this.id"
        @contextmenu.prevent="handler($event)"
        class="sitebuilder-element-container"
        element="SliderGalleryComponent"
        :data-hide-desktop="!this.internal_config.showDesktop"
        :data-hide-mobile="!this.internal_config.showMobile"
        :style="style()"
    >
        <div class="slider-gallery-ios-helper" v-if="$isDeviceIOS()">
            <div class="slider-navigation-helper">
                <div class="slider-navigation" v-show="arrowOptions">
                    <div class="mdi mdi-arrow-left-circle" @click="prevSlider" :style="styleArrow()"></div>
                    <div class="mdi mdi-arrow-right-circle" @click="nextSlider" :style="styleArrow()"></div>
                </div>
            </div>
            <div class="slider-gallery-helper">
                <transition-group
                    tag="div"
                    ref="slider-gallery"
                    class="slider-gallery-ios"
                    name="slider-gallery-ios"
                    :duration="{ enter: 1000, leave: 1000 }"
                    :style="{
          opacity: opacity,
          'border-radius': radius,
          border : border,
          'box-shadow': boxShadow,
          minHeight : minHeight,
          'transform': transform
          }"
                >
                    <div
                        v-for="(image, index) in internal_config.images"
                        :key="index"
                        :class="`slider-image-${index}`"
                        :style="{ backgroundImage: `url('${image}')` }"
                        v-show="internal_config.currentIndex === index"
                    ></div>
                </transition-group>

        </div>
        </div>
        <template v-else>
        <div
            ref="slider-gallery"
            class="slider-gallery"
            :style="{
          opacity: opacity,
          'border-radius': radius,
          border : border,
          'box-shadow': boxShadow,
          minHeight : minHeight,
          'transform': transform
          }"
        >
            <div
                v-for="(image, index) in internal_config.images"
                :key="index"
                :class="`slider-image-${index}`"
                :style="{ backgroundImage: `url('${image}')` }"
            ></div>
        </div>
        <div class="slider-navigation" v-show="arrowOptions">
            <div class="mdi mdi-arrow-left-circle" @click="prevSlider" :style="styleArrow()"></div>
            <div class="mdi mdi-arrow-right-circle" @click="nextSlider" :style="styleArrow()"></div>
        </div>
        </template>
    </div>
</template>

<script>
import SliderData from "./Model";

export default {
    name: "SliderGalleryComponent",
    props: ["config", "id", "preview", "device"],
    components: {},
    computed: {
        arrowOptions() {
            return this.internal_config.arrowOptions;
        },
        timerSlider() {
            return this.internal_config.timerSlider;
        },
        minHeight() {
            return this.internal_config.minHeight;
        },
        opacity() {
            return this.internal_config.opacity;
        },
        border() {
            if (typeof this.internal_config.border !== 'undefined') {
                let splitedBorder = this.internal_config.border.split(' ');

                if (typeof splitedBorder[2] !== 'undefined' && this.$globalStoreHelpers.isValidHex(splitedBorder[2])) {
                    splitedBorder[2] = this.$globalStoreHelpers.hexToRGBA(splitedBorder[2]);
                    this.internal_config.border = splitedBorder.join(' ');
                }
            }
            return this.internal_config.border;
        },
        boxShadow() {
            return this.internal_config.boxShadow;
        },
        radius() {
            return this.internal_config.borderRadius;
        },
        transform() {
            return `rotate(${this.$props.device === 'desktop' ? this.internal_config.rotation : 0}) scale(1)`;
        },
    },
    data() {
        return SliderData.data();
    },
    methods: {
        destroyAutoPlay() {
            clearTimeout(this.internal_config.autoPlaySlider);
        },
        buildSlider() {
            if(!this.$isDeviceIOS()) {
                document
                    .getElementById(this.id)
                    .getElementsByClassName(
                        `slider-image-${this.internal_config.currentIndex}`
                    )[0].style.left = 0;
            }
            this.autoPlaySlider();
        },
        prevSlider() {
            const {currentIndex, images} = this.internal_config;
            const nextIndex = currentIndex ? currentIndex - 1 : images.length - 1;

            const nextImage = document
                .getElementById(this.id)
                .getElementsByClassName(`slider-image-${nextIndex}`)[0];

            const prevImage = document
                .getElementById(this.id)
                .getElementsByClassName(`slider-image-${currentIndex}`)[0];

            if(!this.$isDeviceIOS()) {
                nextImage.style.left = "-100%";
                prevImage.style.left = 0;
            }

            nextImage.setAttribute(
                "class",
                `slider-image-${nextIndex} slider-gallery-in-left`
            );
            prevImage.setAttribute(
                "class",
                `slider-image-${currentIndex} slider-gallery-out-right`
            );

            this.internal_config.currentIndex = nextIndex;

            this.destroyAutoPlay();
            this.autoPlaySlider();
        },
        nextSlider() {
            const {currentIndex, images} = this.internal_config;
            const nextIndex =
                currentIndex === images.length - 1 ? 0 : currentIndex + 1;

            const nextImage = document
                .getElementById(this.id)
                .getElementsByClassName(`slider-image-${nextIndex}`)[0];

            const prevImage = document
                .getElementById(this.id)
                .getElementsByClassName(`slider-image-${currentIndex}`)[0];

            if(!this.$isDeviceIOS()) {
                nextImage.style.left = "100%";
                prevImage.style.left = 0;
            }

            nextImage.setAttribute(
                "class",
                `slider-image-${nextIndex} slider-gallery-in-right`
            );
            prevImage.setAttribute(
                "class",
                `slider-image-${currentIndex} slider-gallery-out-left`
            );

            this.internal_config.currentIndex = nextIndex;

            this.destroyAutoPlay();
            this.autoPlaySlider();
        },
        autoPlaySlider() {
            if (!this.internal_config.autoPlaySlider) {
                this.destroyAutoPlay();
                return false;
            }

            this.internal_config.autoPlaySlider = setTimeout(() => {
              this.nextSlider();
            }, this.internal_config.timerSlider);
        },
        animation() {
            return true;
        },
        style() {
            var style = `width: ${this.internal_config.width};
                     height:  ${this.internal_config.height};
                     z-index: ${this.internal_config.zIndex};max-width: ${this.internal_config.maxWidth};margin: 0 auto;
                     padding-top: ${this.internal_config.paddingTop};
                     padding-left: ${this.internal_config.paddingLeft};
                     padding-right: ${this.internal_config.paddingRight};
                     padding-bottom: ${this.internal_config.paddingBottom};
                     left:  ${this.internal_config.left};
                     top: ${this.internal_config.top};`;
            return style;
        },
        styleArrow() {
            return `font-size: ${this.internal_config.arrowSize};
                color: ${this.internal_config.arrowColor}`;
        },
        styleResize() {
            return `{width: ${this.internal_config.width}, height:  ${this.internal_config.height}}`;
        },
        handler(e) {
            this.$root.$emit("focusElement", this);
            this.$root.$emit("handlerElement", this);
            e.stopPropagation();
            e.preventDefault();
        },
        hover() {
            this.$emit("highlightHover", {
                name: this.name,
                posicao: this.$refs.image.getBoundingClientRect()
            });
        }
    },

    created() {

        this.internal_config = window.mergeRecursive(
            this.props,
            this.$props.config
        );

        this.internal_config.currentIndex = 0;
    },
    mounted() {

        this.$root.$emit("applyAnimation", this, "sitebuilder-element-container");

        this.buildSlider();
    }
};
</script>
<style lang="scss" scoped>

[element="SliderGalleryComponent"] {
    transform: translateZ(0);
}

.slider-gallery-ios-helper {
    width: 100%;
    height: 100%;
    position: relative;

    .slider-navigation-helper {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
    }

    .slider-gallery-helper {
        width: 100%;
        height: 100%;
    }
}

.slider-gallery-ios {
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    height: 100%;

    position: relative;

    > div {
        position: absolute;
        height: 100%;
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        background-size: cover;
    }

    .slider-gallery-out-right {
        -webkit-animation: slideOutXRight 1s forwards;
        animation: slideOutXRight 1s forwards;
    }

    .slider-gallery-out-left {
        -webkit-animation: slideOutXLeft 1s forwards;
        animation: slideOutXLeft 1s forwards;
    }

    .slider-gallery-in-right {
        -webkit-animation: slideInXRight 1s forwards;
        animation: slideInXRight 1s forwards;
    }

    .slider-gallery-in-left {
        -webkit-animation: slideInXLeft 1s forwards;
        animation: slideInXLeft 1s forwards;
    }

    //.slider-navigation {
    //    position: relative;
    //}
}



.slider-gallery {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;

    div {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 100%;
        top: 0px;
        background-size: cover;
    }

    .slider-gallery-in-right {
        left: -100%;
        -webkit-animation: slideIn 1s forwards;
        animation: slideIn 1s forwards;
    }

    .slider-gallery-in-left {
        left: 100%;
        -webkit-animation: slideIn 1s forwards;
        animation: slideIn 1s forwards;
    }

    .slider-gallery-out-right {
        -webkit-animation: slideOutRight 1s forwards;
        animation: slideOutRight 1s forwards;
    }

    .slider-gallery-out-left {
        -webkit-animation: slideOutLeft 1s forwards;
        animation: slideOutLeft 1s forwards;
    }
}

.slider-navigation {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    padding: inherit;

    div {
        color: #d1d1d1;
        font-size: 25px;
    }

    &:hover {
        cursor: pointer;
    }
}

@keyframes slideIn {
    100% {
        left: 0;
    }
}

@keyframes slideOutRight {
    100% {
        left: 100%;
    }
}

@keyframes slideOutLeft {
    100% {
        left: -100%;
    }
}

@-webkit-keyframes slideOutLeft {
    100% {
        left: -100%;
    }
}

@-webkit-keyframes slideIn {
    100% {
        left: 0;
    }
}

@-webkit-keyframes slideOutRight {
    100% {
        left: 100%;
    }
}

@keyframes slideInXRight {
    0% {
        -ms-transform: translateX(100%);
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
    100% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
    }
}

@-webkit-keyframes slideInXRight {
    0% {

        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
    100% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
    }
}

@keyframes slideInXLeft {
    0% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
    100% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
    }
}

@-webkit-keyframes slideInXLeft {
    0% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
    100% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
    }
}

@keyframes slideOutXRight {
    0% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
    }
    100% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
}

@-webkit-keyframes slideOutXRight {
    0% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
    }
    100% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
}

@-webkit-keyframes slideOutXLeft {
    0% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
    }
    100% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}

@keyframes slideOutXLeft {
    0% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
    }
    100% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}

</style>
