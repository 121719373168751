import Vue from 'vue';

Vue.directive('toggle', {
  inserted(el, binding, vnode) {
    el.addEventListener('click', function() {
      let elementToggle = el.nextElementSibling;

      this.classList.toggle('active');

      if (elementToggle.style.maxHeight) {
        elementToggle.style.maxHeight = null;
      } else {
        elementToggle.style.maxHeight = elementToggle.scrollHeight + 'px';
      }
    });
  }
});
