<template>
  <div :id="this.id"
       class="sitebuilder-element-container card "
       element="AccordionComponent"
       :data-hide-desktop="!this.internal_config.showDesktop"
       :data-hide-mobile="!this.internal_config.showMobile"
       :style="style()">

    <div class="card-header" :id="'heading'+this.id" @click="collapseThis($event)" v-bind:style="{
        borderRadius: radius,
         background: backgroundColor,
        backgroundImage: backgroundImage,
        backgroundSize: backgroundSize,
        backgroundPosition: backgroundPosition,
        backgroundOrigin: backgroundOrigin,
        backgroundRepeat: backgroundRepeat,
         border : border + ' !important',
        height : height
        }">
      <h5 class="mb-0 clean-text-css h-100">
        <button class="btn btn-link w-100 h-100 icon-place desktop-element" data-toggle="collapse"
                :data-target="'#collapse'+this.id" aria-expanded="false"
                :aria-controls="'collapse'+this.id" :style="textStyle()">
          {{ internal_config.text }}
        </button>
        <button class="btn btn-link w-100 h-100 icon-place  mobile-element" data-toggle="collapse"
                :data-target="'#collapse'+this.id" aria-expanded="false"
                :aria-controls="'collapse'+this.id" :style="textStyleMobile()">
          {{ internal_config.text }}
        </button>
      </h5>
    </div>

    <div :id="'collapse'+this.id" class="collapse" :aria-labelledby="'heading'+this.id"
         v-bind:style="{border : borderContainer}">
      <div class="sitebuilder-element-receiver big-is-receiver card-body" v-bind:style="{
                borderRadius: radiusContainer,
                background: backgroundContainer,
                height : heightContainer
                }">
        <component v-for="(element,index) in elements" :is="element.name" :config="element.config" :key="index"
                   :device="device"
                   :is_popup="is_popup"
                   :id="element.id" :height_start="index" :preview="preview"
                   :childs="element.childs"></component>
      </div>
    </div>
  </div>
</template>

<script>

import Data from './Model';


export default {
	name: 'AccordionComponent',
	props: ['config', 'childs', 'height_start', 'device', 'id', 'preview', 'is_popup'],
	computed: {
		height() {
			return this.internal_config.height;
		},
		border() {
			if (typeof this.internal_config.border !== 'undefined') {
				let splitedBorder = this.internal_config.border.split(' ');

				if (typeof splitedBorder[2] !== 'undefined' && this.$globalStoreHelpers.isValidHex(splitedBorder[2])) {
					splitedBorder[2] = this.$globalStoreHelpers.hexToRGBA(splitedBorder[2]);
					this.internal_config.border = splitedBorder.join(' ');
				}
			}
			return this.internal_config.border;
		},
		radius() {
			return this.internal_config.borderRadius;
		},
		backgroundColor() {

			if (this.$globalStoreHelpers.isValidHex(this.internal_config.backgroundColor)) {
				this.internal_config.backgroundColor = this.$globalStoreHelpers.hexToRGBA(this.internal_config.backgroundColor);
			}

			return this.internal_config.backgroundColor;
		},
		backgroundImage() {
			if (this.internal_config.backgroundImage && this.internal_config.backgroundImage !== 'none') {
				this.internal_config.backgroundImage = this.internal_config.backgroundImage.replace('omb11.com', 'storage.builderall.com');
				return 'url("' + this.internal_config.backgroundImage + '")';
			}
			return '';
		},
		backgroundPosition() {
			if (this.internal_config.backgroundImage && this.internal_config.backgroundImage !== 'none') {
				return this.internal_config.backgroundPosition
			}
			return '';
		},
		backgroundOrigin() {
			if (this.internal_config.backgroundImage && this.internal_config.backgroundImage !== 'none') {
				return this.internal_config.backgroundOrigin
			}
			return '';
		},
		backgroundRepeat() {
			if (this.internal_config.backgroundImage && this.internal_config.backgroundImage !== 'none') {
				return this.internal_config.backgroundRepeat
			}
			return '';
		},
		backgroundSize() {
			if (this.internal_config.backgroundImage && this.internal_config.backgroundImage !== 'none') {
				return this.internal_config.backgroundSize
			}
			return '';
		},
		heightContainer() {
			return this.internal_config.heightContainer;
		},
		borderContainer() {
			return this.internal_config.borderContainer;
		},
		radiusContainer() {
			return this.internal_config.borderRadiusContainer;
		},
		backgroundContainer() {
			return this.internal_config.backgroundColorContainer;
		},
	},
	data() {
		return Data.data();
	},
	methods: {
		animation() {
			return false;
		},
		adjustHeight() {
			let vm = this;
			let elementBounding = vm.$parent.$el.getBoundingClientRect();

			let top = $(vm.$parent.$el).position().top;
			let left = $(vm.$parent.$el).offset().left;
			let width = elementBounding.width;

			let valueChanged = (parseInt(vm.internal_config.height) + parseInt(vm.internal_config.heightContainer)) - parseInt(vm.internal_config.height);

			$('#collapse' + this.id)
				.on('hide.bs.collapse', function () {
					if (typeof vm.$parent.$parent.changeHeight !== 'undefined') {
						vm.$parent.$parent.changeHeight(valueChanged, false, vm.$parent.id, top, left, width);
					} else {
						vm.$parent.$parent.$parent.changeHeight(valueChanged, false, vm.$parent.id, top, left, width);
					}
				})
				.on('show.bs.collapse', function () {
					vm.internal_config.accordion_force = 1
					if (typeof vm.$parent.$parent.changeHeight !== 'undefined') {
						vm.$parent.$parent.changeHeight(valueChanged, true, vm.$parent.id, top, left, width);
					} else {
						vm.$parent.$parent.$parent.changeHeight(valueChanged, true, vm.$parent.id, top, left, width);
					}
				});
		},
		collapseThis(e) {
			e.stopPropagation();
			e.preventDefault();
			// $('#collapse' + this.id).collapse('dispose')
			if (!this.internal_config.accordion_force) {
				this.adjustHeight();
			}

			$('#collapse' + this.id).collapse('toggle');
		},

		style() {
			return `  left:  ${this.internal_config.left};
                top: ${this.internal_config.top};
                padding-top: ${this.internal_config.paddingTop};
                padding-left: ${this.internal_config.paddingLeft};
                padding-right: ${this.internal_config.paddingRight};
                padding-bottom: ${this.internal_config.paddingBottom};
                z-index: ${this.internal_config.zIndex};
                width: ${this.internal_config.width};
                max-width: ${this.internal_config.maxWidth};
                margin: 0 auto;`;
		},
		build() {

			this.adjustHeight();
			this.buildIcon();
		},

		textStyle() {
			if (typeof this.internal_config.textStyle == 'undefined') {
				this.internal_config.textStyle = {
					'family': 'arial, helvetica, sans-serif',
					'size': '15px',
					'sizeMobile': '15px',
					'color': 'White',
					'align': 'center',
					'weight': '700',
					'style': 'initial',
					'stroke': 'none'
				}
			}
			return `font-family:  ${this.internal_config.textStyle.family};
             font-size: ${this.internal_config.textStyle.size};
             text-align: ${this.internal_config.textStyle.align};
             font-weight: ${this.internal_config.textStyle.weight};
             font-style: ${this.internal_config.textStyle.style};
             text-decoration: ${this.internal_config.textStyle.stroke};
             -webkit-text-fill-color: ${this.internal_config.textStyle.color};
             color: ${this.internal_config.textStyle.color}`;
		},
		textStyleMobile() {

			if (typeof this.internal_config.textStyle == 'undefined') {
				this.internal_config.textStyle = {
					'family': 'arial, helvetica, sans-serif',
					'size': '15px',
					'sizeMobile': '15px',
					'color': 'White',
					'align': 'center',
					'weight': '700',
					'style': 'initial',
					'stroke': 'none'
				}
			}
			return `font-family:  ${this.internal_config.textStyle.family};
                 font-size: ${this.internal_config.textStyle.sizeMobile};
                 text-align: ${this.internal_config.textStyle.align};
                 font-weight: ${this.internal_config.textStyle.weight};
                 font-style: ${this.internal_config.textStyle.style};
                 text-decoration: ${this.internal_config.textStyle.stroke};
                 -webkit-text-fill-color: ${this.internal_config.textStyle.color};
                 color: ${this.internal_config.textStyle.color}`;
		},
		buildIcon: function () {
			$(this.$el).find('i').detach();

			if (this.internal_config.icon !== '') {
				if (this.internal_config.iconPosition === 'left') {
					$('<i>').addClass('mdi ' + this.internal_config.icon).prependTo($(this.$el).find('.icon-place').get(0));
					$('<i>').addClass('mdi ' + this.internal_config.icon).prependTo($(this.$el).find('.icon-place').get(1));
				}
				if (this.internal_config.iconPosition === 'right') {
					$('<i>').addClass('mdi ' + this.internal_config.icon).appendTo($(this.$el).find('.icon-place').get(0));
					$('<i>').addClass('mdi ' + this.internal_config.icon).appendTo($(this.$el).find('.icon-place').get(1));
				}
			}
		},
	},
	mounted() {

		let device, childs, id, config;
		({device, childs, id, config} = this.$props);

		if (typeof config.backgroundColor === 'undefined') {
			config.backgroundColor = '#FFFFFF';
		}
		this.internal_config = config;
		this.device = device;

		if (typeof childs === 'undefined') {
			childs = [];
			this.internal_config.childs = [];
		}

		if (typeof id !== 'undefined') {
			this.id = id;
		}

		this.elements = childs.sort((a, b) => (a.config.order_mobile > b.config.order_mobile) ? 1 : ((b.config.order_mobile > a.config.order_mobile) ? -1 : 0));

		// this.internal_config = window.mergeRecursive(this.props, this.$props.config);
		let internal_config = window.mergeRecursive(this.props, this.$props.config);
		this.internal_config = {...internal_config, ...this.internal_config};

		this.build();

	}
}

</script>
<style lang="scss" scoped>

.card-body {
  position: relative;
}

.sitebuilder-element-container.card {
  height: auto !important;
  width: 100% !important;
  top: 0 !important;
  left: 0 !important;
  position: relative;
  background-color: transparent !important;
  border-color: transparent;
}

[data-device="mobile"] .sitebuilder-element-receiver.card-body {
  height: auto !important;
}

[data-device="mobile"] .card-header {
  width: 100%;
}

.card-header {
  padding: 0 !important;
}

.card {
  border: 0 !important;
}

</style>
