import axios from 'axios';
import {split} from "vue-notification/src/util";

function makeUrlPost() {
    let url = '';

    if (window.location.host.indexOf('localhost:') !== -1) {
        url = `https://gesundundfitbleiben.com/cheetah/blog/get-new?post=viren-kann-man-nicht-bekampfen-man-muss-mit-ihnen-leben`;
    }else{
        url = `${url}/cheetah/blog/get-new?post=${window.location.pathname.replace('/', '')}`
    }

    return url;
}

function makeUrlCapture() {
    let url = '';

    if (window.location.host.indexOf('localhost:') !== -1) {
        url = `http://publication.localhost/cheetah/blog/get-capture-config`;
    }else{
        url = `${url}/cheetah/blog/get-capture-config`
    }

    return url;
}

function makeGeneralUrls(path = '') {
    let url = '';

    if (window.location.host.indexOf('localhost:') !== -1 || window.location.host.indexOf('localhost') !== -1) {
        url = `http://blog.localhost/${path}`;
    }else if(window.location.host.indexOf('publication-dev') !== -1 || window.location.host.indexOf('publication-dev') !== -1) {
        url = `https://blog-dev.builderall.io/${path}`;
    } else{
        url = `https://blog-beta.omb11.com/${path}`;
    }

    return url;

}

function makeGeneralUrlsApi(path = '') {
    let url = '';

    if (window.location.host.indexOf('localhost:') !== -1 || window.location.host.indexOf('localhost') !== -1) {
        url = `http://api.localhost/${path}`;
    }else if(window.location.host.indexOf('publication-dev') !== -1 || window.location.host.indexOf('publication-dev') !== -1) {
        url = `https://api-dev.builderall.io/${path}`;
    } else{
        url = `https://sitebuilder-api.omb11.omb11.com/${path}`;
    }

    return url;

}
function getWebsiteIDParam() {
    let url = '';

    if (window.location.host.indexOf('localhost:') !== -1 || window.location.host.indexOf('localhost') !== -1) {
        url = `website_id=MQ==`;
    }else{
        url = `website_id=${OPTIONS_INIT_SITE.options.w_code}`;
    }

    return url;
}

export const getActualNew = () => {
    return axios.get(makeUrlPost());
};

export const storePermission = (url) => {
    return axios.get(makeGeneralUrlsApi(url));

}

export const getCaptureConfig = () => {
    return axios.get(makeUrlCapture());
};

export const getNewByID = (id) => {
    return axios.get(makeGeneralUrls(`/news/get-by-id/${id}` + '?' + getWebsiteIDParam()));
};

export const getFilteredNews = (config) => {
    let path = `/news/get-filtered-news?category=${config.filters.category}&author=${config.filters.author}&featured=${config.filters.featured}&most_accessed=${config.filters.mostAccessed}&amount=${config.filters.amount}&type=${config.type}`;

    return axios.get(makeGeneralUrls(path + '&' + getWebsiteIDParam()));
};


export const getKey = () => {
    let path = `get-key?${getWebsiteIDParam()}`;
    return axios.get(makeGeneralUrls(path));
};

export const getFeedNews = (config, pagination, key = false) => {
    if(config.filters.category.indexOf('/') !== -1){
        config.filters.category = config.filters.category.split('/')[0]
    }
    if(config.filters.author.indexOf('/') !== -1){
        config.filters.author = config.filters.author.split('/')[0]
    }

    let path = `/get-news?category=${config.filters.category}&author=${config.filters.author}&featured=${config.filters.featured}&most_accessed=${config.filters.mostAccessed}&amount=${config.filters.amount}&page=${pagination.current_page}`;


    if(config.filters.search) {
        path = `${path}&search=${config.filters.search}`
    }


    if(key){
        path = `${path}&utc_time=${key}`
    }

    return axios.get(makeGeneralUrls(path + '&' + getWebsiteIDParam()));
};



