<template>
  <div class="card mtf" :style="cardStyle()">
    <a :href="showUrl(`/blog/${article.url}`)" >
      <img v-if="show.thumbnail" class="card-img-top desktop-element"  :style="thumbnailStyle()" :src="article.thumbnail" :alt="`${article.title} thumbnail`">
      <img v-if="show.thumbnail" class="card-img-top mobile-element" :src="article.thumbnail" :alt="`${article.title} thumbnail`">
    </a>

    <div class="card-body">
      <a :href="showUrl(`/blog/${article.url}`)" class="card-title clean-text-css desktop-element" :style="titleStyle()">{{ article.title }}</a>
      <a :href="showUrl(`/blog/${article.url}`)" class="card-title clean-text-css mobile-element free-size" :style="titleStyle(false)">{{ article.title }}</a>
      <div v-show="show.content" class="card-text clean-text-css article-content ">
        <p id="summaryArticle" :style="contentStyle()" class="article-content clean-text-css desktop-element" v-html="article.content" ></p>
        <p id="summaryArticleMobile" :style="contentStyle(false)" class="article-content clean-text-css mobile-element free-size" v-html="article.content" ></p>
      </div>
      <div :style="buttonDivStyle()">
        <a v-show="show.button" :href="showUrl(`/blog/${article.url}`)" :class="`btn`" :style="buttonStyle()">{{ config.readMoreText }}</a>
      </div>
    </div>
    <div :style="categoryStyleDiv()" v-show="show.categories">

      <!--desktop element categories SÓ TEM ELE-->
      <a :href="showUrl(`/c/${category.slug}`)" v-for="category in article.categories" :key="`desktop-category-${category.id}`" :style="categoryStyle()" class="badge mr-1 mt-1">{{ category.name }}</a>

    </div>

    <!--desktop element author and date-->
    <div v-show="show.date || show.author" style="background: none"  :style="authorDivStyle()" class="card-footer mt-3 desktop-element">
      <span v-show="show.date" :style="authorStyle()">  {{ formatDate(article.scheduled_date, config.dateFormat) }}</span>
      <a v-show="show.author" :href="showUrl(`/a/${article.author.slug}`)" :style="authorStyle()">{{ article.author.name }}</a>
    </div>
    <!--mobile element author and date-->
    <div v-show="show.date || show.author" style="background: none"  :style="authorDivStyle()" class="card-footer mt-3 mobile-element">
      <span v-show="show.date" :style="authorStyle(false)">  {{ formatDate(article.scheduled_date, config.dateFormat) }}</span>
      <a v-show="show.author" :href="showUrl(`/a/${article.author.slug}`)" :style="authorStyle(false)">{{ article.author.name }}</a>
    </div>

  </div>
</template>

<script>
import {mixinBlogComponents} from "@/js/mixins/BlogComponents";

export default {
  name: 'CardPostSimple',
  props: ['article', 'config', 'colors', 'show', 'styles', 'sizes'],
  mixins: [mixinBlogComponents],
  methods: {
    cardStyle() {
      return `background-color: ${this.colors.color_1};
              height: ${this.sizes.card.height !== '0px' ? this.sizes.card.height : 'auto'};
               border-radius: ${this.sizes.card.borderRadius};`;
    },
    categoryStyleDiv() {
      let align = this.styles.categories.align === 'left' ? 'flex-start' : this.styles.categories.align === 'right' ? 'flex-end' : 'center';

      return `height: auto;
               display: flex;
               align-items: center;
               flex-wrap: wrap;
               justify-content: ${align}`;
    },
    thumbnailStyle() {
      return `height: ${this.sizes.thumbnail.height !== '0px' ? this.sizes.thumbnail.height : 'auto'};
              border-radius: ${this.sizes.thumbnail.borderRadius};`;
    },
    buttonDivStyle() {
      let align = this.styles.button.align === 'left' ? 'flex-start' : this.styles.button.align === 'right' ? 'flex-end' : 'center';

      return `height: ${this.sizes.buttonDiv.height !== '0px' ? this.sizes.buttonDiv.height : 'auto'};
               display: flex;
               align-items: center;
               justify-content: ${align}`;
    },
    buttonStyle(desktop = true) {
      return `background-color: ${this.colors.color_2};
              box-shadow: none;
              border-radius: ${this.sizes.button.borderRadius};
              font-family:  ${this.styles.button.family};
              font-size: ${(desktop) ? this.styles.button.size : this.styles.button.sizeMobile} !important;
              text-align: center;
              font-weight: ${this.styles.button.weight};
              font-style: ${this.styles.button.style};
              text-decoration: ${this.styles.button.stroke};
              color: ${this.styles.button.color};
              height: ${this.sizes.button.height !== '0px' ? this.sizes.button.height : 'auto'};
              width: ${this.sizes.button.width !== '0px' ? this.sizes.button.width : 'auto'};
              overflow: hidden;
              display: inline-flex;
              justify-content: center;
              align-items: center`;
    },
    categoryStyle(desktop = true) {
      return `background-color: ${this.colors.color_3};
              box-shadow: none;
              border-radius: ${this.styles.categories.borderRadius};
              font-family:  ${this.styles.categories.family};
              font-size: ${(desktop) ? this.styles.categories.size : this.styles.categories.sizeMobile} !important;
              text-align: ${this.styles.categories.align};
              font-weight: ${this.styles.categories.weight};
              font-style: ${this.styles.categories.style};
              text-decoration: ${this.styles.categories.stroke};
              color: ${this.styles.categories.color};
              height: ${this.styles.categories.height};
              overflow: hidden;
              cursor: pointer;`;
    },
    titleStyle(desktop = true) {
      return `font-family:  ${this.styles.title.family};
                  font-size: ${(desktop) ? this.styles.title.size : this.styles.title.sizeMobile} !important;
                  text-align: ${this.styles.title.align};
                  font-weight: ${this.styles.title.weight};
                  font-style: ${this.styles.title.style};
                  text-decoration: ${this.styles.title.stroke};
                  height: ${this.sizes.title.height !== '0px' && desktop ? this.sizes.title.height : 'auto'};
                  color: ${this.styles.title.color};
                  -webkit-text-fill-color: ${this.styles.title.color};
                  cursor: pointer`;
    },
    contentStyle(desktop = true) {
      return `font-family:  ${this.styles.content.family};
                  font-size: ${(desktop) ? this.styles.content.size : this.styles.content.sizeMobile} !important;
                  text-align: ${this.styles.content.align};
                  font-weight: ${this.styles.content.weight};
                  font-style: ${this.styles.content.style};
                  text-decoration: ${this.styles.content.stroke};
                  color: ${this.styles.content.color};
                  -webkit-text-fill-color: ${this.styles.content.color};
                  height: ${this.sizes.content.height !== '0px' ? this.sizes.content.height : 'auto'};
                  overflow: hidden`;
    },
    authorDivStyle() {
      return `text-align: ${this.styles.author.align};`;
    },
    authorStyle(desktop = true) {
      return `font-family:  ${this.styles.author.family};
                  font-size: ${(desktop) ? this.styles.author.size : this.styles.author.sizeMobile} !important;
                  text-align: ${this.styles.author.align};
                  font-weight: ${this.styles.author.weight};
                  font-style: ${this.styles.author.style};
                  text-decoration: ${this.styles.author.stroke};
                  margin-right: 3px;
                  color: ${this.styles.author.color}`;
    }
  },
  mounted() {
    $('#summaryArticle h1, #summaryArticle h2, #summaryArticle h3, #summaryArticle h4, #summaryArticle h5, #summaryArticle h6, #summaryArticle p').addClass('clean-text-css');
    $('#summaryArticleMobile h1, #summaryArticle h2, #summaryArticle h3, #summaryArticle h4, #summaryArticle h5, #summaryArticle h6, #summaryArticle p').addClass('clean-text-css');
    $('#summaryArticle img').css('display', 'none');
    $('#summaryArticleMobile img').css('display', 'none');
    $('#summaryArticle > iframe').css('display', 'none');
    $('#summaryArticleMobile > iframe').css('display', 'none');
  },
  watch: {
    styles() {
      $('#summaryArticle h1, #summaryArticle h2, #summaryArticle h3, #summaryArticle h4, #summaryArticle h5, #summaryArticle h6, #summaryArticle p').addClass('clean-text-css');
      $('#summaryArticleMobile h1, #summaryArticle h2, #summaryArticle h3, #summaryArticle h4, #summaryArticle h5, #summaryArticle h6, #summaryArticle p').addClass('clean-text-css');
      $('#summaryArticle img').css('display', 'none');
      $('#summaryArticleMobile img').css('display', 'none');
      $('#summaryArticle > iframe').css('display', 'none');
      $('#summaryArticleMobile > iframe').css('display', 'none');
    },
    article(value) {
      // do nothing
    }
  }
};
</script>

<style scoped>

  #summaryArticle > iframe {
    display: none !important;
  }

  .card-body, .card-footer{
    padding-left: 0px;
    padding-right: 0px;
  }

  .card{
    padding: 15px;
    /*padding-bottom: 15px;*/
  }

</style>
