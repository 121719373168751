<template>
  <div :data-field-group-name="data.visibility !== 'hidden' ? data.tag : ''">
    <div :class="[this.icon && this.icon.selected ? 'input-group mb-3' : 'form-group']" v-show="this.data.visibility !== 'hidden'">
      <label v-show="this.showLabel" :class="{'d-block w-100': this.showLabel &&this.icon && this.icon.selected }"> {{label}} </label>
      <div class="input-group-prepend" v-if="this.icon && this.icon.selected &&  this.icon.position === 'left'">
        <span class="input-group-text"><i :class="['mdi', this.icon.selected]"></i></span>
      </div>
      <input class="form-control" :required="required" v-model="value" :placeholder="placeholder" v-if="!this.area"
             :name="this.data.tag" :type="[this.pass == true ? 'password':'text']" :validation="validation"/>
      <textarea class="form-control" :required="required" v-model="value" :placeholder="placeholder"
                v-else-if="this.area" :name="this.data.tag"></textarea>
      <div class="input-group-prepend" v-if="this.icon && this.icon.selected && this.icon.position === 'right'">
        <span class="input-group-text"><i :class="['mdi', this.icon.selected]"></i></span>
      </div>
      <small class="form-text text-muted"  style="width: 100%" v-show="data.help_text != ''">{{data.help_text}}</small>
    </div>
  </div>
</template>

<script>
	export default {
		name: 'InputFieldMailingBoss',
		props: ['label', 'value', 'data', 'area', 'showPlaceholder', 'showLabel', 'pass', 'icon', 'validation'],
		data() {
			return {}
		},
		computed: {
			placeholder() {
				return this.showPlaceholder ? this.label : ''
			},
			required() {
			  if(typeof  this.data.required !== 'undefined'){
                return this.data.required === 'yes';
              }else{
                return true;
              }

			},
		},
      mounted() {
        if(this.data.default_value !== null) {
           if(typeof this.$el.querySelector('input') !== 'undefined') {
             try {
                this.$el.querySelector('input').setAttribute('value', this.data.default_value);
                this.$el.querySelector('input').value = this.data.default_value;
                }
                catch(err) {
                 console.log('mbooss textfield error')
                }

           }

        }
      }
    }
</script>
<style scoped>
  textarea {
    resize: none;
  }

  .input-group-text {
    padding: 0 0.75rem;
  }
</style>
