<template>
  <div
    :id="id"
    class="sitebuilder-element-container"
    element="blogNewsSliderComponent"
    :data-hide-desktop="!internal_config.showDesktop"
    :data-hide-mobile="!internal_config.showMobile"
    :config-properties="JSON.stringify(internal_config)"
    :style="style()"
  >
      <div
        ref="blogNewsSlider"
        class="blog-news-slider"
        :style="{
          opacity: opacity,
          borderRadius: radius,
          border : border,
          boxShadow: boxShadow,
          minHeight : minHeight
          }"
      >
        <div
          v-for="(blogNew, index) in news"
          :key="index"
          :class="`news-slider-${index}`"
          :style="{
            backgroundImage: `url('${blogNew.thumbnail}')`,
            cursor: internal_config.optionNews.redirectUrl
            ? 'pointer'
            : ''
          }"
          @click="redirectPost(blogNew.url)"
        >
          <div
          v-if="showTitle || showDescription"
          :style="infoContentStyle()"
          class="news-info-slider">

            <!-- Desktop -->
            <p
            v-show="showTitle"
            :style="titleTextStyle()"
            class="clean-text-css desktop-element"
            v-html="blogNew.title"
            />

            <!-- Mobile -->
            <p
            v-show="showTitle"
            :style="titleTextStyle( false )"
            class="clean-text-css mobile-element news-info-slider-content"
            v-html="blogNew.title"
            />

            <!-- Content -->

            <!-- Desktop -->
            <p
              v-show="showDescription"
              :style="subTextStyle()"
              class="clean-text-css desktop-element"
              v-html="blogNew.content"
             />

            <!-- Mobile -->
            <p
              v-show="showDescription"
              :style="subTextStyle(false)"
              class="clean-text-css mobile-element"
              v-html="blogNew.content"
            />
          </div>
        </div>
      </div>
      <div
        v-show="showArrows"
        class="mdi mdi-chevron-left arrow-left"
        :style="styleArrow()"
        @click="prevSlider"
      />
      <div
        v-show="showArrows"
        class="mdi mdi-chevron-right arrow-right"
        :style="styleArrow()"
        @click="nextSlider"
      />
  </div>
</template>

<script>
  import blogNewsData from "./Model";

  import { getFilteredNews } from '../../../../js/services/blog'


  export default {
    name: "BlogNewsSliderComponent",
    props: ["config", "id"],
    computed: {
      showTitle() {
        return this.internal_config.optionNews.showTitle
      },
      showDescription() {
        return this.internal_config.optionNews.showDescription
      },
      showArrows() {
        return this.internal_config.arrowOptions
      },
      timerSlider() {
        return this.internal_config.timerSlider;
      },
      minHeight() {
        return this.internal_config.minHeight;
      },
      opacity() {
        return this.internal_config.opacity;
      },
      border() {
        return this.internal_config.border;
      },
      boxShadow() {
        return this.internal_config.boxShadow;
      },
      radius() {
        return this.internal_config.borderRadius;
      },
    },
    data() {
      return blogNewsData.data();
    },
    methods: {
      buildSlider() {
        let vm = this;

        getFilteredNews({
          type: 'group',
          filters: {
            category : 'all',
            author : 'all',
            featured : this.internal_config.optionNews.featuredNews,
            mostAccessed : false,
            amount : this.internal_config.optionNews.totalNews
          }
        })
        .then( ({ data }) => {
          if(data.data.length) {
            vm.internal_config.news = [];
            vm.news = data.data;

            this.autoPlaySlider();
          }
        } )
      },
      autoPlaySlider() {
        this.destroyAutoPlay();

        const { autoPlaySlider, timerSlider } = this.internal_config

        if ( !autoPlaySlider) {
          this.internal_config.slider = null;
          return false
        }

        this.internal_config.slider = setTimeout(() => {
          this.nextSlider();
        }, timerSlider);
      },
      destroyAutoPlay() {
        clearTimeout(this.internal_config.slider);
      },
      prevSlider() {
        const currentIndex = this.internal_config.currentIndex;
        const news = this.news;

        const nextIndex = currentIndex
        ? currentIndex - 1
        : news.length - 1;

        const nextImage = this.$refs.blogNewsSlider.children[nextIndex]
        const prevImage = this.$refs.blogNewsSlider.children[currentIndex];

        nextImage.style.left = "-100%";
        prevImage.style.left = 0;

        nextImage.setAttribute(
          "class",
          `news-slider-${nextIndex} slider-in-left`
        );
        prevImage.setAttribute(
          "class",
          `news-slider-${currentIndex} slider-out-right`
        );

        this.internal_config.currentIndex = nextIndex;

        this.autoPlaySlider();
      },
      nextSlider() {
        const currentIndex = this.internal_config.currentIndex;
        const news = this.news;


        const nextIndex = currentIndex === news.length - 1
        ? 0
        : currentIndex + 1;

        const nextImage = this.$refs.blogNewsSlider.children[nextIndex]
        const prevImage = this.$refs.blogNewsSlider.children[currentIndex];

        nextImage.style.left = "100%";
        prevImage.style.left = 0;

        nextImage.setAttribute(
          "class",
          `news-slider-${nextIndex} slider-in-right`
        );

        prevImage.setAttribute(
          "class",
          `news-slider-${currentIndex} slider-out-left`
        );

        this.internal_config.currentIndex = nextIndex;

        this.autoPlaySlider();
      },
      redirectPost(url) {
        if(this.internal_config.optionNews.redirectUrl && url)
          window.location = '/blog/' + url
      },
      style() {
        return `width: ${this.internal_config.width};
                     height:  ${this.internal_config.height};
                     z-index: ${this.internal_config.zIndex};
                     max-width: ${this.internal_config.maxWidth};
                     margin: 0 auto;
                     left:  ${this.internal_config.left};
                     top: ${this.internal_config.top}`;
      },
      titleTextStyle(desktop = true) {
        return `font-family:  ${this.internal_config.titleTextContent.family};
                font-size: ${ desktop
                  ? this.internal_config.titleTextContent.size
                  : this.internal_config.titleTextContent.sizeMobile
                } !important;
                text-align: ${this.internal_config.titleTextContent.align};
                font-weight: ${this.internal_config.titleTextContent.weight};
                font-style: ${this.internal_config.titleTextContent.style};
                text-decoration: ${this.internal_config.titleTextContent.stroke};
                color: ${this.internal_config.titleTextContent.color}`;
      },
      subTextStyle(desktop = true) {
        return `font-family:  ${this.internal_config.subTextStyle.family} !important;
                font-size: ${desktop
                  ? this.internal_config.subTextStyle.size
                  : this.internal_config.subTextStyle.sizeMobile} !important;
                text-align: ${this.internal_config.subTextStyle.align};
                font-weight: ${this.internal_config.subTextStyle.weight};
                font-style: ${this.internal_config.subTextStyle.style};
                text-decoration: ${this.internal_config.subTextStyle.stroke};
                color: ${this.internal_config.subTextStyle.color}`;


      },
      infoContentStyle() {
        return `
          height: ${this.internal_config.infoContentHeight};
          background-color: ${this.internal_config.infoContentColor};

          padding-top: ${this.internal_config.infoContentPadding.paddingTop};
          padding-right: ${this.internal_config.infoContentPadding.paddingRight};
          padding-bottom: ${this.internal_config.infoContentPadding.paddingBottom};
          padding-left: ${this.internal_config.infoContentPadding.paddingLeft};
          `
      },
      styleArrow() {
        return `
          font-size: ${this.internal_config.arrowSize};
          width: ${this.internal_config.arrowSize};
          height: ${this.internal_config.arrowSize};
          background-color: ${this.internal_config.arrowBackground};
          color: ${this.internal_config.arrowColor};
          `
      },
    },
    mounted() {
      this.internal_config = window.mergeRecursive(
        this.props,
        this.$props.config
      );

      this.buildSlider();


      let objIds = [];
      $('[id]').each(function () {
        var ids = $('[id="' + this.id + '"]');
        if (ids.length > 1 && ids[0] == this && parseInt(this.id))
          objIds.push(this.id);
      });
      if (objIds.includes(this.id)) {
        this.id = Date.now() + parseInt(Math.random() * 10000);
      }

      this.$root.$on('setConfigElement', (id, obj) => {
        if (this.id == id) {
          this.config = obj;
          this.internal_config = obj;
        }
      });
    }
  };
</script>
<style lang="scss" scoped>
  .blog-news-slider {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;

    div {
      height: 100%;
      width: 100%;

      display: flex;
      align-items: flex-end;

      position: absolute;

      top: 0;
      background-size: cover;
      left: 100%;

      &:first-child {
        left: 0;
      }
    }

    .news-info-slider {
      position: relative;
      display: flex;
      flex-direction: column;

      p {
        width: 100% !important;
        margin: 0;
      }
    }
  }

  .arrow-left {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 50%;
    margin-left: 10px;
    transform: translateY(-50%);
    border-radius: 50%;
    text-align: center;
    border: 0;
    cursor: pointer;
  }

  .arrow-right {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 50%;
    right: 0;
    margin-right: 10px;
    transform: translateY(-50%);
    border-radius: 50%;
    border: 0;
    cursor: pointer;
  }

  .slider-in-right {
    left: -100%;
    -webkit-animation: slideIn 1s forwards;
    animation: slideIn 1s forwards;
  }

  .slider-in-left {
    left: 100%;
    -webkit-animation: slideIn 1s forwards;
    animation: slideIn 1s forwards;
  }

  .slider-out-right {
    -webkit-animation: slideOutRight 1s forwards;
    animation: slideOutRight 1s forwards;
  }

  .slider-out-left {
    -webkit-animation: slideOutLeft 1s forwards;
    animation: slideOutLeft 1s forwards;
  }

  @keyframes slideIn {
    100% {
      left: 0;
    }
  }

  @keyframes slideOutRight {
    100% {
      left: 100%;
    }
  }

  @keyframes slideOutLeft {
    100% {
      left: -100%;
    }
  }

  @-webkit-keyframes slideOutLeft {
    100% {
      left: -100%;
    }
  }

  @-webkit-keyframes slideIn {
    100% {
      left: 0;
    }
  }

  @-webkit-keyframes slideOutRight {
    100% {
      left: 100%;
    }
  }
</style>
