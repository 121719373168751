export default {
  data() {
    return {
      name: 'ButtonUser',
      typeAuth: false,
      website_user: {
        name: '',
        email: ''
      },
      props: {
        'tag': '',
        width: '150px',
        'height': '50px',
        left: '20px',
        top: '20px',
        'zIndex': 'auto',
        maxWidth: '500px',
        actionsformat: 'login-register',
        showDesktop: true,
        showMobile: true,

        areas: [],

        withDropdown: '1',
        withRegards: '0',

        // Text
        textLogin: '', // text antes do login
        textRegister: '', // text register
        textLogout: '', // text de saida
        textRegards: '', // text de saida

        // icons
        iconLogin: {
          icon: '', position: 'left'
        },
        iconLogout: {
          icon: '', position: 'left'
        },
        iconRegister: {
          icon: '', position: 'left'
        },
        iconRegards: {
          icon: '', position: 'left'
        },

        // Links before login
        links: {
          linkLogin: {
            'type': 'restrict',
            'value': {action: 'login', redirect: '0', areas: [], redirect_elearning_course: false},
          },
          linkRegister: {
            'type': 'restrict',
            'value': {action: 'register', redirect: '0', areas: [], redirect_elearning_course: false},
          },
          linkLogout: {
            'type': 'restrict',
            'value': {action: 'logout', redirect: '0', areas: [], redirect_elearning_course: false},
          }
        },

        buttonStyle: {
          'border': '0 none #000000',
          'borderRadius': '3px 3px 3px 3px',
          'backgroundColor': '#007bff',
          'backgroundColorHover': '#007bff',
          'boxShadow': '0 0 0 0 #000000',

          'textColorHover': '#FFFFFF',
          'textStyle': {
            'family': 'arial, helvetica, sans-serif',
            'size': '15px',
            'sizeMobile': '15px',
            'color': 'White',
            'align': 'center',
            'weight': '700',
            'style': 'initial',
            'stroke': 'none'
          }

        },

        dropdownStyle: {
          'border': '0 none #000000',
          'borderRadius': '0 0 3px 3px',
          'backgroundColor': '#007bff',
          'backgroundColorHover': '#007bff',
          'boxShadow': '0 0 0 0 #000000',

          'textColorHover': '#FFFFFF',
          'textStyle': {
            'family': 'arial, helvetica, sans-serif',
            'size': '15px',
            'sizeMobile': '15px',
            'color': 'White',
            'align': 'center',
            'weight': '700',
            'style': 'initial',
            'stroke': 'none'
          }
        },

        'paddingTop': '15px',
        'paddingLeft': '15px',
        'paddingRight': '15px',
        'paddingBottom': '15px',

        'animation': {
          'in': {
            'action': 'time', // time/scroll/click/hover
            'time': 0, // sec
            'scroll': 0, // px
            'effect': 'none',
            'speed': 'slow',
            'infinite': false
          },
          'out': {
            'action': 'time', // time/scroll/click
            'time': 0, // sec
            'scroll': 0, // px
            'effect': 'none',
            'delay': '1',
            'speed': '1',
            'infinite': false
          }
        }
      },
      internal_config: {
        width: '150px',
        height: '50px',
        left: '20px',
        top: '20px',
        zIndex: 'auto',
        showDesktop: true,
        showMobile: true,
        opacity: 1
      }
    };
  }

};
