<template>
  <div :id="id"
       class="sitebuilder-element-container"
       element="BlogArticleListComponent"
       :data-hide-desktop="!this.internal_config.showDesktop"
       :data-hide-mobile="!this.internal_config.showMobile"
       :type="internal_config.type"
       :config-properties="JSON.stringify(internal_config)" :style="style()">




    <div v-if="checkCondition()">
      <div class="container">
        <div v-if="internal_config.theme === 'simple'" class="card-container">
          <card-post-simple
                  v-for="(article, index) in articles"
                  :key="article.id"
                  :class="getClasses(index)" :style="getCardStyle(index)"
                  :config="internal_config.card.config"
                  :styles="internal_config.card.styles"
                  :colors="internal_config.card.colors"
                  :show="internal_config.card.show"
                  :sizes="internal_config.card.sizes"
                  :article="article"
          ></card-post-simple>
        </div>
        <div v-if="internal_config.theme === 'clean'">
          <card-clean
                  v-for="article in articles"
                  :key="article.id"
                  class="mt-3"
                  :config="internal_config.card.config"
                  :styles="internal_config.card.styles"
                  :colors="internal_config.card.colors"
                  :show="internal_config.card.show"
                  :sizes="internal_config.card.sizes"
                  :article="article"
          />
        </div>
        <div v-if="internal_config.theme === 'hover'" class="card-container">
          <card-hover
                  v-for="(article, index) in articles"
                  :key="article.id"
                  :class="getClasses(index)" :style="getCardStyle(index)"
                  :config="internal_config.card.config"
                  :styles="internal_config.card.styles"
                  :colors="internal_config.card.colors"
                  :show="internal_config.card.show"
                  :sizes="internal_config.card.sizes"
                  :article="article"
          />
        </div>
        <div v-if="internal_config.theme === 'list'">
          <card-list
            v-for="(article, index) in articles"
            :key="article.id"
            class="mt-3"
            :position="index"
            :config="internal_config.card.config"
            :styles="internal_config.card.styles"
            :colors="internal_config.card.colors"
            :show="internal_config.card.show"
            :sizes="internal_config.card.sizes"
            :article="article"
          />
        </div>
        <div v-if="internal_config.type === 'feed' && pagination.show">
          <div style="display: flex; flex-wrap: wrap; justify-content: center">
            <nav aria-label="Page navigation example">
              <ul class="pagination">
                <li v-show="pagination.first" class="page-item"><a  :style="`background-color: ${internal_config.card.colors.color_4}; color:  ${internal_config.card.colors.color_5}; border: 1px solid transparent;   margin-right: 2px`"  class="page-link" :href="`${getPaginationUrl(1)}`">{{ $t('ct-first') }}</a></li>
                <li v-show="pagination.previous" class="page-item"><a  :style="`background-color: ${internal_config.card.colors.color_4}; color:  ${internal_config.card.colors.color_5}; border: 1px solid transparent;   margin-right: 2px`"  class="page-link" :href="`${getPaginationUrl(pagination.current_page - 1)}`">{{ $t('ct-previous') }}</a></li>
                <li v-for="page in pagination.pages" :key="`key-page-${page}`" class="page-item"><a  :style="`background-color: ${internal_config.card.colors.color_4}; color:  ${internal_config.card.colors.color_5}; border: 1px solid transparent;   margin-right: 2px`"  class="page-link" :href="`${getPaginationUrl(page)}`" > {{ page }} </a></li>
                <li v-show="pagination.next" class="page-item"><a  :style="`background-color: ${internal_config.card.colors.color_4}; color:  ${internal_config.card.colors.color_5}; border: 1px solid transparent;   margin-right: 2px`"  class="page-link" :href="`${getPaginationUrl(pagination.current_page + 1)}`">{{ $t('ct-next') }}</a></li>
                <li v-show="pagination.last" class="page-item"><a  :style="`background-color: ${internal_config.card.colors.color_4}; color:  ${internal_config.card.colors.color_5}; border: 1px solid transparent;   margin-right: 2px`"  class="page-link" :href="`${getPaginationUrl(pagination.last_page)}`">{{ $t('ct-last') }}</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>

    </div>
    <div v-else>
      <div v-if="internal_config.newsNotFound" style="background-color: white; height: 300px; width: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column">
        <h3>{{ $t('ct-no-articles-on-your-blog') }}</h3>
      </div>
      <div v-else class="icon-loading" style="background-color: white; height: 300px; width: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column">
        <i class="fas fa-circle-notch fa-spin fa-4x" />
      </div>
    </div>


  </div>
</template>

<script>
  import elementData from './Model';
  import _ from 'lodash';

  import CardPostSimple from './cards/Simple';
  import CardClean from './cards/Clean'
  import CardHover from './cards/Hover';
  import CardList from './cards/List';

  import { getNewByID, getFilteredNews, getFeedNews, getKey } from '../../../../../js/services/blog'
  import moment from "moment";


  export default {
    name: 'BlogArticleListComponent',
    props: ['config', 'id'],
    components: {  CardPostSimple, CardHover, CardClean, CardList },
    data() {
      return elementData.data();
    },
    methods: {
      getPaginationUrl(page) {
        let result = `${this.pagination.path}/page/${page}`;

        if(this.pagination.path.indexOf('?') !== -1) {
          result = `/s/page/${page}${this.pagination.path}`;
        }

        result = result.replace(`page/${this.pagination.current_page}/`, '');
        // return `/page/${this.pagination.current_page}/`;
        return result;
      },
      build(config) {
        this.internal_config = _.merge(this.internal_config, config);
        this.internal_config = _.merge(this.internal_config, { newsNotFound: false })
      },
      animation() {
        return false;
      },
      checkCondition() {
        if (this.internal_config.type === 'featured' && this.internal_config.selectedArticle === 0) { return false }
        if (this.internal_config.type === 'group' && this.articles.length === 0) { return false }
        if (this.internal_config.type === 'feed' && this.articles.length === 0) { return false }

        return true;
      },
      getClasses(index) {
        return 'mt-1 mb-1 card-article';
      },
      getCardStyle(index) {
        if (this.internal_config.type === 'featured') { return 'width: 100%' }
        if (this.internal_config.type === 'group') {
          if (this.articles.length === 1) {
            return 'width: 100%';
          }
        }

        function calcSizes(columns) {
          let widthCalculated = parseInt(100 / columns - 1);
          let marginCalculated = (100 - (widthCalculated * columns)) / columns;
          return `width: ${widthCalculated}%; margin-left: ${marginCalculated}%`;
        }

        if (this.internal_config.headline !== 'none') {
          switch (this.internal_config.headline) {
            case '1-100': {
              let result = (index === 0) ? calcSizes(1) : calcSizes(this.internal_config.columns);
              return result;
            }
            case '2-50': {
              let result = (index <= 1) ? calcSizes(2) : calcSizes(this.internal_config.columns);
              return result;
            }
            case '3-33': {
              let result = (index <= 2) ? calcSizes(3) : calcSizes(this.internal_config.columns);
              return result;
            }
            case '4-25': {
              let result = (index <= 3) ? calcSizes(4) : calcSizes(this.internal_config.columns);
              return result;
            }
            case 'last-column': {
              let result = (index === 0) ? `width:  ${100 / this.internal_config.columns - 1}%; margin-left: ${100 - (100 / this.internal_config.columns - 1)}%;` : calcSizes(this.internal_config.columns);
              return result;
            }
          }
        }

        return calcSizes(this.internal_config.columns);
      },
      getfeedArticles() {

      },
      getNews() {
        let vm = this;

        if(vm.internal_config.type === 'featured') {
          getNewByID(this.internal_config.selectedArticle)
                  .then(({ data }) => {
                    vm.articles.push(data.data)
                  })
        }

        if(vm.internal_config.type === 'group') {
          getFilteredNews(this.internal_config)
                  .then(({ data }) => {
                    vm.articles = data.data
                  })
        }

        if(vm.internal_config.type === 'feed') {

          //verifica se é busca de termo
          if(window.location.pathname === '/s' || window.location.pathname.indexOf('/s/') !== -1){
            let queryString = window.location.search;
            let urlParams = new URLSearchParams(queryString);
            let search = urlParams.get('query');

            vm.internal_config.filters.search = search;
            vm.pagination.path = `?query=${search}`;
          }



          //checca se é busca de categoria
          if(window.location.pathname.indexOf('/c/') !== -1) {
            let category = window.location.pathname.replace('/c/', '');
            vm.internal_config.filters.category = category;
            vm.pagination.path = window.location.pathname;
          }

          //checca se é busca de author
          if(window.location.pathname.indexOf('/a/') !== -1) {
            let author = window.location.pathname.replace('/a/', '');
            vm.internal_config.filters.author = author;
            vm.pagination.path = window.location.pathname;
          }

          if(window.location.pathname.indexOf('/page/') !== -1) {

            console.log(window.location.pathname.indexOf(vm.pagination.path));

            if(window.location.pathname.indexOf(vm.pagination.path) !== -1){
              let page = window.location.pathname.split('/').pop()
              vm.pagination.current_page = page;
            }else{
              let page = window.location.pathname.replace('/page/', '');

              if(page.indexOf('s') !== -1) {
                page = window.location.pathname.split('/').pop()
              }

              vm.pagination.current_page = page;
            }


          }else{
            vm.pagination.current_page = parseInt(1);
          }


          function makePagination(vm) {

            //define se vai ou não ter botão previous
            if(vm.pagination.current_page === 1 ){
              vm.pagination.previous = false;
            }else{
              vm.pagination.previous = true;
              vm.pagination.first = true;
            }


            //define se vai ou não ter botão next
            if(vm.pagination.current_page === vm.pagination.last_page && (vm.pagination.current_page !== 1)){
              vm.pagination.next = false;
            }else{
              vm.pagination.next = true;
              vm.pagination.last = true;
            }


            //montar intervalo de paginas pra ser exibido
            if(vm.pagination.current_page === 1){
              vm.pagination.pages = [1,2,3]
            }

            if(vm.pagination.current_page > 1 && vm.pagination.current_page < vm.pagination.last_page){
              vm.pagination.pages = [vm.pagination.current_page - 1, vm.pagination.current_page ,vm.pagination.current_page + 1]
            }

            if(vm.pagination.current_page === vm.pagination.last_page && (vm.pagination.current_page !== 1)){
              vm.pagination.pages = [vm.pagination.last_page - 2,vm.pagination.last_page -1 , vm.pagination.last_page]
            }

            if(vm.pagination.last_page === 1) {
              vm.pagination.show = false
            }
          }


          getKey()
            .then(({data}) => {
                    if(localStorage.getItem('utc_time_' + data.key) == null){
                      localStorage.setItem('utc_time_' + data.key, moment().utc().unix());
                    }

              getFeedNews(vm.internal_config, vm.pagination, localStorage.getItem('utc_time_' + data.key))
                      .then(({ data }) => {
                        vm.articles = data.data
                        vm.pagination.last_page = data.last_page
                        vm.pagination.current_page = data.current_page
                        makePagination(vm);
                        heightCalc(vm);

                        if(!!vm.internal_config.filters.search) {
                          vm.internal_config.newsNotFound = !vm.articles.length ? true : false
                        }
                      })
                  })



        }



        function heightCalc(vm) {

          let config = vm.internal_config;

          if(config.filters.amount < vm.articles.length){
            let qtdHeadline = 0;

            if(config.headline === '1-100') {
              qtdHeadline = 1;
            }

            if(config.headline === '2-50') {
              qtdHeadline = 2;
            }

            if(config.headline === '3-33') {
              qtdHeadline = 3;
            }

            if(config.headline === 'last-column') {
              qtdHeadline = 1;
            }

            if(config.headline === 'none') {
              qtdHeadline = 0;
            }

            let qtdColumns = config.columns;

            let lines = (config.filters.amount - qtdHeadline) / qtdColumns;

            if(qtdHeadline > 0) {
              lines+=1;
            }

            let actualLines = (vm.articles.length - qtdHeadline) / qtdColumns;

            if(qtdHeadline > 0) {
              actualLines+=1;
            }

            if(actualLines > lines) {
              vm.newLines = actualLines - lines;
              setTimeout(function () {
                let child = $('.mtf')[0]

                let elementBounding = vm.$el.getBoundingClientRect();
                let top = $(vm.$el).position().top;
                let left = $(vm.$el).offset().left;
                let width = elementBounding.width;

                if (typeof vm.$parent.changeHeight !== 'undefined' && typeof vm.$parent.changeHeight === 'function') {
                  vm.$parent.changeHeight($(child).height() * vm.newLines + 100, true, vm.$el.id, top, left - 100, width);
                }
              }, 2000)
            }

          }

        }

      },
      article() {
        return this.internal_config.article;
      },
      style() {
        return `width: ${this.internal_config.width};
               height:  ${this.internal_config.height};
               z-index: ${this.internal_config.zIndex};
               margin: 0 auto;
               left:  ${this.internal_config.left};
               top: ${this.internal_config.top}`;
      },
      styleCard() {
        return `background-color: ${this.internal_config.backgroundColor}`;
      }
    },
    mounted() {
      this.build(this.$props.config);


      this.getNews();
    }
  };
</script>

<style>

  [element="BlogArticleListComponent"] {
    height: auto !important;
  }

  .icon-loading {
    display: inline-block;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 15px;
    color: #006FFF;
  }
  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }


  [data-device='mobile'] .card-article,
  [data-device='tablet'] .card-article{
    width: 100% !important;
    margin-left: 0% !important;
  }

  /* THEME CLEAN CSS  */
  [data-device='mobile'] .clean-theme-desktop,
  [data-device='tablet'] .clean-theme-desktop {
    display: none !important;
  }

  [data-device='desktop'] .clean-theme-mobile {
    display: none !important;
  }


  [data-device='mobile'] .clean-theme-mobile,
  [data-device='tablet'] .clean-theme-mobile {
    display: block !important;
  }

  [data-device='desktop'] .clean-theme-desktop {
    display: flex !important;
  }


  /* THEME HOVER CSS  */
  [data-device='mobile'] .hover-theme-desktop,
  [data-device='tablet'] .hover-theme-desktop {
    display: none !important;
  }

  [data-device='desktop'] .hover-theme-mobile {
    display: none !important;
  }


  [data-device='mobile'] .hover-theme-mobile,
  [data-device='tablet'] .hover-theme-mobile {
    display: flex !important;
  }

  [data-device='desktop'] .hover-theme-desktop {
    display: flex !important;
  }

</style>
