var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"image-gallery-photos-container",style:({
          gridTemplateColumns: ("repeat(" + (_vm.internal_config.columns) + ", auto)"),
          gridColumnGap: ((_vm.internal_config.verticalMargin) + "px"),
          gridRowGap: ((_vm.internal_config.horizontalMargin) + "px"),
          opacity: _vm.opacity
        })},_vm._l((_vm.internal_config.images),function(image,index){return _c('div',{key:image,staticClass:"image-gallery-photo",style:({
            backgroundImage: 'url(' + image + ')',
            cursor: _vm.$props.preview ? 'pointer': '',
            borderRadius: _vm.radius,
            border : _vm.border
           }),on:{"click":function($event){return _vm.$emit('showPhoto', index)}}})}),0)}
var staticRenderFns = []

export { render, staticRenderFns }