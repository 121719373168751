<template>
    <div :class="getClassMain()" :style="getStyle()">
        <template v-if="open_by === 'page'">

            <!--       THREE TABS   -->
            <three-tabs-page v-if="form_theme.theme === 'three-tabs'" :type_active="type_active" :auth_types="auth_types"
                             :areas_register="areas" :active_page="active_page" @change="changeType"
                            ></three-tabs-page>

            <!--       TWO TABS   -->
            <two-tabs-page v-else-if="form_theme.theme === 'two-tabs'" :type_active="type_active" :auth_types="auth_types"
                           :areas_register="areas" :active_page="active_page" @change="changeType"
                          ></two-tabs-page>

            <!--       TWO COLUMNS   -->
            <two-columns-page v-else-if="form_theme.theme === 'two-columns'" :type_active="type_active" :auth_types="auth_types"
                              :areas_register="areas" :active_page="active_page" @change="changeType"
                             ></two-columns-page>
            <!--       IN BLOCK   -->
            <in-block-page v-else-if="form_theme.theme === 'in-block'" :type_active="type_active" :auth_types="auth_types"
                           :areas_register="areas" :active_page="active_page" @change="changeType"
                          ></in-block-page>

            <!--      DEFAULT   -->
            <default-page v-else :type_active="type_active" :areas_register="areas" :active_page="active_page" :auth_types="auth_types"
                          @change="changeType"></default-page>
        </template>

        <section class="section section-shaped section-zero min-vh-100 d-flex justify-content-center align-items-center"
                 v-else>
            <sweet-modal ref="success_send" class="modal-login" @close="onCloseModal()">
                <!--       THREE TABS   -->
                <three-tabs-popup v-if="form_theme.theme === 'three-tabs'" :type_active="type_active" :auth_types="auth_types"
                                  :areas_register="areas" :active_page="active_page" @change="changeType"
                                 ></three-tabs-popup>

                <!--       TWO TABS   -->
                <two-tabs-popup v-else-if="form_theme.theme === 'two-tabs'" :type_active="type_active" :auth_types="auth_types"
                                :areas_register="areas" :active_page="active_page" @change="changeType"
                               ></two-tabs-popup>

                <!--       TWO COLUMNS   -->
                <two-columns-popup v-else-if="form_theme.theme === 'two-columns'" :type_active="type_active" :auth_types="auth_types"
                                   :areas_register="areas" :active_page="active_page" @change="changeType"
                                  ></two-columns-popup>
                <!--       IN BLOCK   -->
                <in-block-popup v-else-if="form_theme.theme === 'in-block'" :type_active="type_active" :auth_types="auth_types"
                                :areas_register="areas"
                                :active_page="active_page" @change="changeType"></in-block-popup>

                <!--      DEFAULT   -->
                <default-popup v-else :type_active="type_active" :areas_register="areas" :active_page="active_page" :auth_types="auth_types"
                               @change="changeType"></default-popup>

            </sweet-modal>
        </section>
    </div>
</template>

<script>

    import {SweetModal} from 'sweet-modal-vue'
    import {mixinRestrictArea} from "./../../../js/mixins/restrictArea";
    import {getCourseElearning} from "../../../js/WebsiteUser";
    import {default as RestrictAreaTypes} from "../../../js/RestrictAreaTypes";

    export default {
        name: 'RestrictArea',
        props: ['notHavePermission', 'open_by', 'not_have_permission'],
        mixins: [mixinRestrictArea],
        components: {
            SweetModal,
            'default-popup': () => import('./custom/default/DefaultPopUp'),
            'three-tabs-popup': () => import('./custom/three-tabs/ThreeTabsPopup'),
            'two-tabs-popup': () => import('./custom/two-tabs/TwoTabsPopUp'),
            'two-columns-popup': () => import('./custom/two-columns/TwoColumnsPopUp'),
            'in-block-popup': () => import('./custom/in-block/InBlockPopUp'),
            'default-page': () => import('./custom/default/DefaultPage'),
            'three-tabs-page': () => import('./custom/three-tabs/ThreeTabsPage'),
            'two-tabs-page': () => import('./custom/two-tabs/TwoTabsPage'),
            'two-columns-page': () => import('./custom/two-columns/TwoColumnsPage'),
            'in-block-page': () => import('./custom/in-block/InBlockPage')
        },
        data() {
            return {
                restricts_areas: [],
                type_active: 'login',
                redirect: false,
                areas: [],
                members_area_active: {},
                active_page: {},
                form_theme: {},
                auth_types: [],
                redirect_elearning_course: false,
            }
        },

        methods: {

            getStyle() {

                if ((this.getBackground('color') || this.getBackground('image')) && this.open_by === 'page') {

                    let color = this.getBackground('color') ? this.getBackground('color') : '';
                    let image = this.getBackground('image') ? `${this.getBackground('image')}` : '';
                    let repeat = this.getBackground('repeat') ? `${this.getBackground('repeat')}` : 'repeat';
                    let size = this.getBackground('size') ? `${this.getBackground('size')}` : 'contain';

                    if (image)
                        return `background: url("${image}") center center / ${size} ${repeat} border-box border-box ${color}`;
                    return `background-color: ${color}`;
                }

                return false;
            },

            getBackground(value) {
                return (this.form_theme.config && this.form_theme.config.background && this.form_theme.config.background[value]) ? this.form_theme.config.background[value] : false;
            },

            getClassMain() {
                let className = 'restrict-container-main';

                className = className + ((this.form_theme.config.style === 'dark') ? ' container-appearance-dark' : ' container-appearance-light');

                className = className + ' theme-' + this.form_theme.theme;

                if (this.open_by === 'page') {
                    className = className + ' type-page';
                }
                return className;
            },

            setAuths() {

                let actualPage = this.getCurrentPage();
                var restrictAreaForMap = [];

                if (this.open_by && this.open_by === 'page') {
                    restrictAreaForMap = actualPage.restrict_areas;
                }

                else if (this.areas.length > 0) {

                    this.members_area_active = restrictsArea.filter(restrict => this.areas.includes(parseInt(restrict.id)));

                    restrictAreaForMap = this.members_area_active;

                }else {
                    restrictAreaForMap = restrictsArea;
                }

                this.auth_types = restrictAreaForMap
                    .filter(area => (parseInt(area.type_id) !== RestrictAreaTypes.ACCESS_BY_BA_CREDENTIALS) && (parseInt(area.type_id) !== RestrictAreaTypes.ACCESS_BY_BA_CREDENTIALS_PREMIUM))
                    .map(area => {
                        if (area['auth_type']) return area['auth_type']; else return 'register';
                    });

                let hasRestrictAreaBA = restrictAreaForMap.filter(area => {
                    return parseInt(area.type_id) === RestrictAreaTypes.ACCESS_BY_BA_CREDENTIALS || parseInt(area.type_id) === RestrictAreaTypes.ACCESS_BY_BA_CREDENTIALS_PREMIUM
                });

                if(hasRestrictAreaBA.length && !this.auth_types.includes('login-ba'))
                    this.auth_types.push('login-ba');

            },

            setAreas(value) {
                this.setAuths();



                this.members_area_active = restrictsArea.filter(restrict => value.includes(parseInt(restrict.id)));

                try{
                    if(value.length === 0){

                        this.members_area_active = restrictsArea;
                        value = restrictsArea.map(restrict => parseInt(restrict.id));
                    }
                }catch (e) {

                }

                if(this.members_area_active  && this.members_area_active.length > 0)
                    this.areas = value;

                this.auth_types = this.members_area_active.map(area => {
                    if (area['auth_type']) return area['auth_type']; else return 'register'
                });

                this.auth_types = [...new Set(this.auth_types)];
            },

            changeType(value) {

                this.setAuths();

                if (this.auth_types.length > 0 && !this.auth_types.includes('both') && !this.auth_types.includes('register') && !this.auth_types.includes('login-ba')) {
                    this.type_active = 'login-token';

                } else if (this.auth_types.length === 1 && this.auth_types.includes('login-ba')) {
                    this.type_active = 'login-ba';

                } else {
                    this.type_active = value;
                    this.$set(this.$data, 'type_active', value);
                }
            },

            setRedirect(value) {
                this.redirect = value;
            },

            setElearningRedirect(values){ // do link

                let restrictArea = this.members_area_active.filter(areas => areas.elearning_course_id);
                this.redirect_elearning_course = !!(typeof values.redirect_elearning_course !== 'undefined' && parseInt(values.redirect_elearning_course) && restrictArea);
            },

            getTheme() {

                if (this.active_page && this.active_page.website_form && this.active_page.website_form.length > 0) {
                    this.form_theme = this.active_page.website_form[0];
                } else
                    this.form_theme = {theme: 'default', config: {style: 'light'}};
            },

            callRedirectElearning(restrictAreas){
                let restricts = restrictAreas.filter( areas => areas.redirect_config);
                let restrictAreaEnable = restrictAreas.filter(area => {
                    return [RestrictAreaTypes.RELEASE_AUTOMATICALLY, RestrictAreaTypes.ACCESS_BY_BA_CREDENTIALS, RestrictAreaTypes.ACCESS_BY_BA_CREDENTIALS_PREMIUM]
                        .includes(parseInt(area.type_id))
                });

                restricts.map( async (restrict, index)  => {

                    if(typeof restrict.redirect_config === 'undefined' && this.open_by === 'page')
                        return;

                    let redirect_config = this.$isJson(restrict.redirect_config) ? JSON.parse(restrict.redirect_config) : {};

                    let md5Password = localStorage.getItem('login-user-token-md5');
                    let hashPassword = localStorage.getItem('login-user-token-hash');
                    let loginUser = localStorage.getItem('login-user');

                    loginUser = this.$isJson(loginUser) ? JSON.parse(loginUser) : false;

                    if(redirect_config.elearning.type === 'elearning-course' || this.open_by === 'popup') {

                        let target = redirect_config.elearning.target === 'blank' && this.open_by === 'page' ? 'blank' : 'same';
                        let id = (typeof restrict.restrict_area_id !== 'undefined') ? restrict.restrict_area_id : restrict.id;

                        if (index === 0) {

                            getCourseElearning(id, loginUser.email,md5Password)
                            .then((res)=>{
                                if (res.data.success) {
                                    if (target === 'blank') {
                                        window.open(res.data.url_course, '_blank');
                                        window.location.reload();
                                    }else
                                        window.location.href = res.data.url_course;
                                } else {
                                    if (restrictAreaEnable.length === 0 && this.open_by === 'popup') {
                                        this.$root.$emit('membersAreaElearningNotApproved');
                                    } else window.location.reload();
                                }
                            })

                        } else {
                            getCourseElearning(id, loginUser.email, md5Password)
                                .then((res) => {
                                    if (res.data.success) {
                                        if (target === 'blank') {
                                            window.open(res.data.url_course, '_blank');
                                            window.location.reload();
                                        } else
                                            window.location.href = res.data.url_course;
                                    } else {
                                        window.location.reload();
                                    }
                                })
                        }
                    }else{
                        window.location.reload();
                    }
                })
            },

            async callRedirect(remember = true) {

                let md5Password = localStorage.getItem('login-user-token-md5');

                if (parseInt(this.redirect) || (this.open_by === 'popup' && typeof md5Password !== 'undefined' && md5Password !== null && this.redirect_elearning_course)) {

                    if (this.redirect_elearning_course && this.members_area_active.filter(areas => areas.elearning_course_id).length > 0 && typeof md5Password !== 'undefined' && md5Password !== null)
                        this.callRedirectElearning(this.members_area_active);
                    else {
                        pagesConfig.forEach(page => {
                            if (+this.redirect === +page.id) {
                                window.location.href = '/' + page.url
                            }
                        });
                    }
                } else {

                    if (this.open_by === 'page' && typeof md5Password !== 'undefined' && md5Password !== null && this.redirect_elearning_course) {
                        await this.callRedirectElearning(this.active_page.restrict_areas);
                    }
                    else if (remember)
                        window.location.reload();
                }

                this.$loading.hide();
            },

            getLogin() {

                return {
                    user: JSON.Parse(localStorage.getItem('login-user')),
                    all: JSON.Parse(localStorage.getItem('login-user-all')),
                    token: localStorage.getItem('login-user-token'),
                }
            },

            removeLogin() {
                this.setLogout();
            },

            back() {

                let homePage;

                try {

                    homePage = pagesConfig.find((page, index) => page !== null && (page.initial === '1'));

                    if(!homePage)
                        homePage = pagesConfig.find((page, index) => page !== null && (page.initial === '0' && index === 0));

                    window.location.href = `${window.origin}/${homePage.url}`

                } catch (e) {
                    console.warn('gethomePage', e);
                }

            },

            openModal() {
                this.$refs.success_send.open();
            },

            onCloseModal(){
                this.$emit('cleanMembersArea');
            },
        },

        created() {
            this.active_page = this.getCurrentPage();
            this.getTheme();

            if (this.open_by === 'page') {
                this.setAuths();
                this.changeType(this.type_active);
                if(this.active_page.restrict_areas)
                    this.redirect_elearning_course = !!this.active_page.restrict_areas.find(areas => areas.elearning_course_id);
            }

        },

        beforeCreate() {

            this.restricts_areas = [];
        },

        mounted() {

            if (this.open_by === 'popup')
                this.$refs.success_send.open();
        }
    }
</script>

<style lang="scss">

  .restrict-container-main {
    @media (max-width: 600px) {
      .sweet-modal {
        top: 20px;
        max-height: calc(100vh - 40px);

        .sweet-content-content {
          max-height: 80vh;
        }
      }
    }

    &.theme-three-tabs, &.theme-two-tabs {
      .restrict-area-form-register {
        @media (max-width: 425px) {
              margin-bottom: 50px;
            }
          }
        }

        .container-box{
            background-color: #FFF;
        }

        &:not(.theme-default) {
            background: #f9f9f9;
        }

        &.theme-three-tabs {

            &.container-appearance-dark {

                .container-main {
                    background-color: #0a001f;

                    .nav-item {
                        .nav-link {
                            color: #5e6475;
                            border-bottom: 1px solid transparent;

                            &.active {
                                color: #00b4ff;
                                border-bottom: 1px solid #00b4ff;
                            }
                        }
                    }
                }

                .form-control {
                    color: #ddd;
                    border-bottom: 1px solid #68728b;
                }

                label {
                    color: #ddd;
                }

                .btn {
                    background-color: #0088d2;

                    &:hover {
                        background-color: #007cb7;
                    }
                }
            }

            &.container-appearance-light {

                .container-main {
                    background-color: #F1F3F7;

                    .nav {
                        border-bottom: 1px solid #d0cece;
                    }

                    .nav-item {
                        .nav-link {
                            font-size: 16px;
                            color: #d0cece;
                            border-bottom: 1px solid transparent;

                            &.active {
                                color: #00b4ff;
                                border-bottom: 1px solid #00b4ff;
                            }
                        }
                    }
                }

                .form-control {
                    color: #68728b;
                    border-bottom: 1px solid #d0cece;
                }

                label {
                    color: #68728b;
                }

                .btn {
                  background-color: #00B147;

                  &:hover {
                    background-color: #00B147;
                  }

                  &:focus {
                    box-shadow: 0 0 0 0.2rem #00b14791;
                  }
                }
            }

            .nav {
                width: 100%;
                align-items: flex-end;

                .nav-item {
                    cursor: pointer;
                    flex: 1;

                    .nav-link {
                        font-size: 16px;
                        &.active {
                        }
                    }
                }
            }

            .form-control {
                display: block;
                width: 100%;
                border: none;
                border-radius: 0;
                background: none;
                transition: .3s ease;
            }

            .container-main {
                border-radius: 6px;
            }

            .modal-body {
                padding: 1rem 3rem;
            }

            .form-control {
                height: 34px;
                padding: 6px 12px;
                font-size: 14px;
                line-height: 1.42857143;

            }

            .btn {
                border-radius: 25px;
                border: none;
                min-width: 130px;
                width: auto;
                height: 35px;
            }
        }

        &.theme-two-tabs {

            .container-main {
                border-radius: 10px;
            }

            .btn-primary{
                font-size: 15px;
                border-radius: 25px;
                padding: 3px 30px;
                margin-bottom: 5px;
                font-weight: bold;
                background-color: #00B147;
                box-shadow: none;
                border: none;

                &:hover, &:focus {
                    background: #00B147;
                    box-shadow: none;
                }
            }

            &.container-appearance-dark {

                .nav {
                    background-color: #303340;

                    .nav-item {
                        .nav-link {
                            color: #545962;
                            background-color: #E5F0F2;
                            border-color: #E5F0F2;

                            &:not(.active) {
                                color: #f9f9f9;
                                background-color: #303340;
                                border-color:  #303340;
                            }
                        }
                    }
                }

                .tab-content {
                    background-color: #E5F0F2;
                }

                .form-control {
                    color: #1A212F;
                    background: #FFFFFF;

                    &:hover, &:focus {
                        background: #FFFFFF;
                        box-shadow: none;
                    }
                }
            }

            &.container-appearance-light {

                .nav {
                    background-color: #e1dcdc;

                    .nav-item {
                        .nav-link {
                            color: #0081F4;
                            background-color: #f9f9f9;
                            border-color: #f9f9f9;

                            &:not(.active) {
                                background-color: #e1dcdc;
                                border-color: #e1dcdc;
                            }
                        }
                    }
                }

                .tab-content {
                    background-color: #f9f9f9;
                }
            }

            .modal-header {
                padding: 0;
                border: 0;
            }

            .nav {
                width: 100%;
                height: 54px;
                display: inline-flex;
                margin: 0;
                border-radius: 20px;
                border: 0;

                .nav-item {
                    flex: 1;
                    white-space: nowrap;
                    border: 0;

                    .nav-link {
                        display: flex;
                        height: 54px;
                        align-items: center;
                        justify-content: center;
                        transition: .3s ease;
                        font-size: 18px;
                        font-weight: bold;
                        white-space: nowrap;
                        border-width: 1px;
                        border-style: solid;
                        border-color: transparent;
                        border-radius: 10px 10px 0 0;

                        &:not(.active) {
                            /*border-radius: 0;*/
                            border-radius: 20px 20px 0 0;
                        }
                    }

                }
            }

            .form-control {
                display: block;
                width: 100%;
                height: 45px;
                border-radius: 25px;
                border: none;
                color: #1289F4;
                background: #E6E6E6;
                transition: .3s ease;
                box-shadow: none;

                &:hover, &:focus {
                    background: #E6E6E6;
                    box-shadow: none;
                }
            }

            .sweet-box-actions{
                top: 6px;
            }

            .btn-success {
                border-radius: 25px;
                width: 100%;
                height: 47px;
                font-size: 18px;
                margin-bottom: 5px;
                font-weight: bold;
                background-color: #00B147;
                box-shadow: none;
                border: none;

                &:hover, &:focus {
                    background: #00B147;
                    box-shadow: none;
                }
            }

            .tab-content {
                padding: 25px 25px 5px 25px;
                border-radius: 0 0 10px 10px;
                margin: -1px;
            }

            .text-forgot-password, .title-forgot-password {
                text-align: center;
                display: block;
                padding: 0 0 10px 0;
                color: #303340;
            }

            .helper-forgot-password {
                padding: 0 0 10px 0;
                color: #303340;
                font-size: 16px;
            }

            .title-forgot-password {
                font-size: 18px;
                font-weight: bold;
            }
        }

        &.theme-two-columns {

            &.container-appearance-dark {

                .sweet-action-close {
                    color: #DEDEDE;
                }

                .container-main {
                    background-color: #2E3436;
                }

                .title {
                    color: #DEDEDE;
                }

                .form-group {

                    label {
                        color: #DEDEDE;
                    }

                    .form-control {
                        color: #DEDEDE;
                        border-bottom: 1px solid #54595C;

                        &:focus {
                            border-color: #BDBDBD;
                        }
                    }
                }

                .btn {
                    background-color: #808080;

                    &:hover {
                        background-color: #b1b1b1;
                        box-shadow: 0 0 0 0.2rem #80808061 !important;
                    }
                }

                .text-forgot-password, .title-forgot-password {
                    color: #DEDEDE;
                }

                .helper-forgot-password {
                    color: #DEDEDE;
                }

                .text-forgot-password {
                    color: #DEDEDE;
                }

            }

            &.container-appearance-light {

                .container-main {
                    background-color: #fff;
                }

                .title {
                    color: #333;
                }

                .form-group {

                    label {
                        color: #333;
                    }

                    .form-control {
                        color: #333;
                        border-bottom: 1px solid #68728b;

                        &:focus {
                            border-color: #BDBDBD;
                        }
                    }
                }

                .btn {
                    background-color: #BDBDBD;

                    &:hover {
                        background-color: #808080;
                        box-shadow: 0 0 0 0.2rem #80808061 !important;
                    }
                }

                .text-forgot-password, .title-forgot-password, .helper-forgot-password, .text-forgot-password {
                    color: #333;
                }

            }

            .container-main {
                display: flex;
                flex-direction: row;
                border-radius: 10px;
                padding: 0 .75rem;

                @media (max-width: 850px) {
                    flex-direction: column;
                }

                .content-box {
                    flex: 1;
                    padding: 2rem 1.5rem;

                    &:not(.forgot) {
                        padding: 1rem 2.5rem;
                        margin: 2rem 0;

                        &:first-child {
                            @media (min-width: 851px) {
                                border-right: 1px solid #54595C;
                            }

                            @media (max-width: 850px) {
                                border-bottom: 1px solid #54595C;
                            }
                        }
                    }
                }

                .text-forgot-password, .title-forgot-password {
                    text-align: center;
                    display: block;
                    padding: 0 0 10px 0;
                }

                .helper-forgot-password {
                    padding: 0 0 10px 0;
                    font-size: 16px;
                }

                .title-forgot-password {
                    font-size: 18px;
                    font-weight: bold;
                }
            }

            .btn {
                border: none;
                border-radius: 30px;
                min-width: 125px;

                &:focus{
                    box-shadow: none;
                }
            }

            .form-group {

                label {
                    float: left;
                }

                .form-control {
                    display: block;
                    width: 100%;
                    border: none;
                    border-radius: 0;
                    /*color: #68728b;*/
                    background: none;
                    transition: .3s ease;
                    /*border-bottom: 1px solid #68728b;*/
                    box-shadow: none;
                    height: 34px;

                    &:focus {
                        box-shadow: none;
                        /*border-color: #66afe9;*/
                        outline: 0;
                    }
                }
            }

            .sweet-modal {
                @media (min-width: 851px) {
                    width: auto;
                    min-width: 600px;
                }
            }
        }

        &.theme-in-block {

            &.container-appearance-light {

                .container-main {
                    background-color: #E6E6E6;
                }

                .title {
                    color: #1A212F;
                }

                .form-control {
                    color: #555;
                    background: #FFFFFF;
                }

                .btn {
                    color: #63666C;
                    border: 1px solid #9B9B9B;

                    &.btn-primary{
                        background-color: #9B9B9B;
                    }

                    &:hover {
                        background: #F15D0D;
                        border-color: #F15D0D;
                        color: #FFF;
                    }

                    &:focus, &:active {
                        background: #F15D0D;
                        border-color: #F15D0D;
                        color: #FFF;
                        box-shadow: 0 0 0 0.2rem #f15d0d70 !important;
                    }
                }
            }

            &.container-appearance-dark {

                .sweet-box-actions .sweet-action-close:hover {
                    background: #172b4d;
                }

                .container-main {
                    background-color: #2E3436;
                }

                label {
                    color: #ececec;
                }

                .title {
                    color: #DEDEDE;
                }

                .form-control {
                    color: #54595C;
                    background: #ececec;
                }

                .btn {
                    color: #ececec;
                    border: 1px solid #9B9B9B;

                    &.btn-primary{
                        background-color: #9B9B9B;
                    }

                    &:hover {
                        background: #F15D0D;
                        border-color: #F15D0D;
                        color: #FFF;
                    }

                    &:focus, &:active {
                      background: #F15D0D;
                      border-color: #F15D0D;
                      color: #FFF;
                      box-shadow: 0 0 0 0.2rem #f15d0d70 !important;
                    }
                }

                .helper-forgot-password {
                    color: #DEDEDE;
                }
            }

            .container-main {
                padding: 15px;

                .title {
                    margin: 25px 0;
                    display: flex;
                    justify-content: center;
                    font-size: 24px;
                }

                label, .form-text {
                    text-align: left;
                    display: block;
                }

                .form-group {
                    padding-top: 5px;
                    margin-bottom: 0;
                }

                .form-control {
                    display: block;
                    width: 100%;
                    height: 65px;
                    border: none;
                    border-radius: 0;
                    transition: .3s ease;
                }

                .btn {
                    border-radius: 0;
                    width: 100%;
                    height: 60px;
                    font-size: 20px;
                    background: none;
                    box-shadow: none;
                    margin-top: 5px;

                    &:hover {
                        border: none;
                    }
                }
            }
        }

        &.theme-default{
            @import './../../../assets/css/argon-clear';
        }
    }

    .modal-login {
        .sweet-modal {
            border-radius: 10px;

            @media (min-width: 600px) {
                width: 500px;
            }

            .sweet-box-actions .sweet-action-close:hover {
                background: #172b4d;
            }
        }
    }
    .modal-body {
        padding: 1.5rem 4.5rem 0;
    }
</style>
