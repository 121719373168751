<template>
    <div class="sitebuilder-element-container"
         element="IframeComponent"
         :data-tag="dataTag()"
         :data-hide-desktop="!this.internal_config.showDesktop"
         :data-hide-mobile="!this.internal_config.showMobile" :id="id"
         :style="style()">

        <div class="embed-responsive embed-responsive-16by9" ref="container_iframe"
             v-bind:style="{height : height, width : width, minHeight : minHeight, 'transform': transform}">
            <iframe v-if="!internal_config.contentLoaded" :width="internal_config.width"
                    :height="internal_config.height" ref="iframe"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    class="embed-responsive-item iframe-full-height"
                    v-bind:style="{'border-radius': radius,border : border}">
            </iframe>
            <template v-else>
                <iframe :width="internal_config.width" :height="internal_config.height"
                        :srcdoc="internal_config.contentLoaded" frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                        class="embed-responsive-item iframe-full-height" ref="iframe"
                        v-bind:style="{'border-radius': radius,border : border}"></iframe>
            </template>
        </div>
    </div>
</template>
<script>

	import Model from './Model';
	import axios from 'axios'

	export default {
		name: 'IframeComponent',
		props: ['config', 'height_start', 'device', 'id', 'preview'],
		data() {
			return Model.data()
		},
		computed: {
			width() {
				return this.internal_config.width;
			},
			height() {
				return this.internal_config.height;
			},
			minHeight() {
				return this.internal_config.minHeight;
			},
			border() {
				if (typeof this.internal_config.border !== 'undefined') {
					let splitedBorder = this.internal_config.border.split(' ');

					if (typeof splitedBorder[2] !== 'undefined' && this.$globalStoreHelpers.isValidHex(splitedBorder[2])) {
						splitedBorder[2] = this.$globalStoreHelpers.hexToRGBA(splitedBorder[2]);
						this.internal_config.border = splitedBorder.join(' ');
					}
				}
				return this.internal_config.border;
			},
			radius() {
				return this.internal_config.borderRadius;
			},
      transform() {
        return `rotate(${this.$props.device === 'desktop' ? this.internal_config.rotation : 0}) scale(1)`;
      }
		},
		methods: {
			dataTag() {
				if (this.internal_config.tag) {
					return this.internal_config.tag;
				}
				return '';
			},
			style() {
				let style = `left:  ${this.internal_config.left};
                    width: ${this.internal_config.width};
                    z-index: ${this.internal_config.zIndex};
                    max-width: ${this.internal_config.maxWidth};margin: 0 auto;
                    height: ${this.internal_config.height};
                    padding-top: ${this.internal_config.paddingTop};
                    padding-left: ${this.internal_config.paddingLeft};
                    padding-right: ${this.internal_config.paddingRight};
                    padding-bottom: ${this.internal_config.paddingBottom};
                    top: ${this.internal_config.top};`;
				return style;
			},

            getUrlWithLogin(url){
                try{

                    let loginPasswordMD5 = !!localStorage.getItem('login-user-token-md5');
                    let loginUser = !!localStorage.getItem('login-user') && this.$isJson(localStorage.getItem('login-user'));

                    // @todo fazer regex para url de curso do elearning
                    if (loginPasswordMD5 && loginUser && url.indexOf('purchased') <= 0) {
                        var user = JSON.parse(localStorage.getItem('login-user'));
                        var passwordMD5 = localStorage.getItem('login-user-token-md5');
                        let email = (typeof user.email !== 'undefined') ? user.email : false;

                        return url + (url.slice(-1) !== '/' ? '/' : '') + (url.indexOf('index') > 0 ? '' : 'index') + '?login=' + (email) + '&password=' + passwordMD5;
                    }
                    else return url;
                }catch(e) {
                    console.log(e)
                }

            },
			buildIframe: function () {

				if (this.internal_config.src != '') {
                    if (this.internal_config.src.indexOf('elearning.eb4us.com/course/') > 0 || this.internal_config.src.indexOf('elearning.builderall.com/course/') > 0) {
                        try {
                            let loginPasswordMD5 = !!localStorage.getItem('login-user-token-md5');
                            let loginUser = !!localStorage.getItem('login-user') && this.$isJson(localStorage.getItem('login-user'));

                            if (loginPasswordMD5 && loginUser) {
                                this.internal_config.src = this.getUrlWithLogin(this.internal_config.src)
                            }

                        } catch (e) {
                            console.log('error elearning iframe', e);
                        }
                    }
					this.$refs.iframe.src = this.internal_config.src;

                    let vm = this;

                    let heightIframe = parseInt(this.internal_config.height) - 20 + 'px';
                    if (this.$props.device === 'mobile') {
                        heightIframe = parseInt(this.internal_config.minHeight) - 20 + 'px';
                    }
                    setTimeout(() => {
                        // $('#' + vm.id).find('iframe')[0].contentDocument.body.style.height = heightIframe;
                                               $('#' + vm.id).find('iframe').height = heightIframe;

                    }, 2000);

				} else {

					// contentIframe = res.data.content
					// let doc = this.$refs.iframe.contentWindow.document;
					// doc.open();
					// doc.write(this.internal_config.content.content);
					// doc.close();

					let contentIframe;
					axios.get('/get_iframe_content?iframe_id=' + this.id)
                        .then(res => {
                            contentIframe = res.data.content;

                            if(contentIframe && this.$storageON()){
                                localStorage.setItem('iframe-content-'+ this.id, contentIframe);
                            }

                            if (contentIframe.indexOf('src="https://elearning.eb4us.com/course/') > 0 || contentIframe.indexOf('src="https://elearning.builderall.com/course/') > 0) {

                                try {
                                    let loginPasswordMD5 = !!localStorage.getItem('login-user-token-md5');
                                    let loginUser = !!localStorage.getItem('login-user') && this.$isJson(localStorage.getItem('login-user'));
                                    var urlMatch = contentIframe.match(/src="(.*?)"/);

                                    if (loginPasswordMD5 && loginUser && !!urlMatch && typeof urlMatch[1] !== 'undefined') {
                                        let url = urlMatch[1];
                                        let urlAuth = this.getUrlWithLogin(url);
                                        // console.log('url final', urlAuth);
                                        contentIframe = contentIframe.split(url).join(urlAuth);
                                    }
                                } catch (e) {
                                    console.log('error elearning iframe', e);
                                }
                            }

                            let doc = this.$refs.iframe.contentWindow.document;
                            doc.open();
                            doc.write(contentIframe);
                            doc.close();

                        })

				}

				if (this.internal_config.fullWidth) {
					this.$el.classList.add('full-width-element');
				} else {
					this.$el.classList.remove('full-width-element');
				}
			},
		},
		mounted() {
			let device, childs, id, config;
			({device, childs, id, config} = this.$props);

			this.internal_config = config;
			this.device = device;

			if (typeof id !== 'undefined') {
				this.id = id;
			}

			Object.keys(this.props).forEach(prop => {
				if (typeof this.internal_config[prop] === 'undefined') {
					this.internal_config[prop] = this.props[prop];
				}
			});

			this.internal_config.contentLoaded = false;

			this.$root.$emit('applyAnimation', this, 'sitebuilder-element-container');


			this.buildIframe();
		}
	}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .vdr {
        height: auto !important;
    }

    iframe body{
        height: 100vh;
    }

    [data-device="desktop"] {
        .embed-responsive {
            min-height: unset !important;
        }
    }

</style>
