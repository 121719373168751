<template>
  <div
    class="sitebuilder-element-container"
    element="YoutubeVideoComponent"
    :data-tag="dataTag()"
    :data-hide-desktop="!this.internal_config.showDesktop"
    :data-hide-mobile="!this.internal_config.showMobile"
    :id="id"
    :style="style()"
  >
    <div class="css" :style="`transform: rotate(${this.$props.device === 'desktop' ? this.internal_config.rotation : 0}) scale(1)`">
      <div id="the-sticky-div" class="embed-responsive embed-responsive-16by9">
        <div class="sticky-video-content">
          <div class="video">
            <iframe
                    :width="internal_config.width"
                    :height="internal_config.height"
                    :src="url"
                    :data-src="getDataUrl()"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    class=" video embed-responsive-item"
                    v-bind:style="{'border-radius': radius,border : border}"
            ></iframe>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import Model from "./Model";

export default {
  name: "YoutubeVideoComponent",
  components: {
    Model
  },
  props: ["config", "height_start", "device", "id", "preview", "is_popup"],
  data() {
    return Model.data();
  },
  computed: {
    width() {
      return this.internal_config.width;
    },
    height() {
      return this.internal_config.height;
    },
    border() {
      if (typeof this.internal_config.border !== "undefined") {
        let splitedBorder = this.internal_config.border.split(" ");

        if (
          typeof splitedBorder[2] !== "undefined" &&
          this.$globalStoreHelpers.isValidHex(splitedBorder[2])
        ) {
          splitedBorder[2] = this.$globalStoreHelpers.hexToRGBA(
            splitedBorder[2]
          );
          this.internal_config.border = splitedBorder.join(" ");
        }
      }
      return this.internal_config.border;
    },
    radius() {
      return this.internal_config.borderRadius;
    }
  },
  methods: {
    dataTag() {
      if (this.internal_config.tag) {
        return this.internal_config.tag;
      }
      return "";
    },
    getDataUrl() {
      if (this.is_popup == true) {
        let url = this.internal_config.src;
        if (this.internal_config.checkBoxAutoPlay) {
          url = url + "&autoplay=1";
        }
        if (this.internal_config.checkBoxLoop) {
          url = url + "&loop=1";
        }
        if (this.internal_config.checkBoxControl) {
          url = url + "&controls=0";
        }
        url =
          url +
          "&start=" +
          this.internal_config.startYoutube +
          "&end=" +
          this.internal_config.endYoutube;

        return url;
      }
    },
    getUrl() {

      if(this.is_popup == true){
        return  null;
      }

      let url = this.internal_config.src;

      if (this.internal_config.checkBoxLoop) {
        let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
        let match = url.match(regExp);
        url = url + "&playlist=" + match[2] + "&loop=1";
      }
      if (this.internal_config.checkBoxAutoPlay) {
        url = url + "&autoplay=1&mute=0";
      }
      if (this.internal_config.checkBoxControl) {
        url = url + "&controls=0";
      }
      url =
        url +
        "&start=" +
        this.internal_config.startYoutube +
        "&end=" +
        this.internal_config.endYoutube;

      return url;
    },
    style() {
      let style = `left:  ${this.internal_config.left};
                     width: ${this.internal_config.width};
                     height : ${this.internal_config.height};
                     z-index: ${this.internal_config.zIndex};max-width: ${this.internal_config.maxWidth};margin: 0 auto;
                     padding-top: ${this.internal_config.paddingTop};
                     padding-left: ${this.internal_config.paddingLeft};
                     padding-right: ${this.internal_config.paddingRight};
                     padding-bottom: ${this.internal_config.paddingBottom};
                     top: ${this.internal_config.top};`;
      return style;
    }
  },
  updated(){
     if (this.internal_config.StickyVideo) {
          this.$root.$emit("applySticky", this,);
          this.internal_config.rotation = 0;
      }
  },
  mounted() {

    // if (this.internal_config.StickyVideo) {
    //      this.internal_config.rotation = 0; // retira o rotate do video quando fixado
    //   }


    let device, childs, id, config;
    ({ device, childs, id, config } = this.$props);

    this.internal_config = config;
    this.device = device;

    if (typeof id !== "undefined") {
      this.id = id;
    }

    Object.keys(this.props).forEach(prop => {
      if (typeof this.internal_config[prop] === "undefined") {
        this.internal_config[prop] = this.props[prop];
      }
    });


			Object.keys(this.props).forEach(prop => {
				if (typeof this.internal_config[prop] === 'undefined') {
					this.internal_config[prop] = this.props[prop];
				}
      });



       let url = this.internal_config.src;

      if (this.internal_config.checkBoxLoop) {
        let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
        let match = url.match(regExp);
        url = url + "&playlist=" + match[2] + "&loop=1";
      }
      if (this.internal_config.checkBoxAutoPlay) {
        url = url + "&autoplay=1&mute=0";
      }
      if (this.internal_config.checkBoxControl) {
        url = url + "&controls=0";
      }
      url =
        url +
        "&start=" +
        this.internal_config.startYoutube +
        "&end=" +
        this.internal_config.endYoutube;

        this.url = url;

        if(this.is_popup == true){
          this.url = '';
        }


      this.$root.$emit('applyAnimation', this, 'sitebuilder-element-container');



		}
	}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .vdr {
    height: auto !important;
  }

  /* .css {
    position: absolute;
    top: 0px;
    left: 0px;
   /* width: 410px;
    height: 250px;
    z-index: auto;
    user-select: auto;
  }  */

   #the-sticky-div {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
  }

  /*Small*/
  .stuckBRs, .stuck-bottom-right-small {
    position: fixed;
    transform: translateY(100%);
    width: 260px !important;
    height: 145px !important;
    animation: fade-in-up .25s ease forwards;

    right: 25px;
    left: unset !important;
    top: unset !important;
    bottom: 10px;
    z-index: 100 !important;
  }

  .stuckTLs, .stuck-top-left-small {
    position: fixed;
    transform: translateY(100%);
    width: 260px !important;
    height: 145px !important;
    animation: fade-in-up .25s ease forwards;

    left: 10px !important;
    top: 10px !important;
    z-index: 100 !important;
  }

  .stuckTRs, .stuck-top-right {
    position: fixed;
    transform: translateY(100%);
    width: 260px !important;
    height: 145px !important;
    animation: fade-in-up .25s ease forwards;

    right: 25px;
    left: unset !important;
    top: 10px !important;
    z-index: 100 !important;
  }

  .stuckBLs, .stuck-bottom-left {
    position: fixed;
    transform: translateY(100%);
    width: 260px !important;
    height: 145px !important;
    animation: fade-in-up .25s ease forwards;

    right: unset;
    left: 10px !important;
    top: unset !important;
    bottom: 10px;
    z-index: 100 !important;
  }
  /*Medium*/
  .stuckBRm, .stuck-bottom-right-medium {
    position: fixed;
    transform: translateY(100%);
    width: 400px !important;
    height: 227px !important;
    animation: fade-in-up .25s ease forwards;

    right: 25px;
    left: unset !important;
    top: unset !important;
    bottom: 10px;
    z-index: 100 !important;
  }

  .stuckTLm, .stuck-top-left-medium {
    position: fixed;
    transform: translateY(100%);
    width: 400px !important;
    height: 227px !important;
    animation: fade-in-up .25s ease forwards;

    left: 10px !important;
    top: 10px !important;
    z-index: 100 !important;
  }

  .stuckTRm, .stuck-top-right-medium {
    position: fixed;
    transform: translateY(100%);
    width: 400px !important;
    height: 227px !important;
    animation: fade-in-up .25s ease forwards;

    right: 25px;
    left: unset !important;
    top: 10px !important;
    z-index: 100 !important;
  }

  .stuckBLm, .stuck-bottom-left {
    position: fixed;
    transform: translateY(100%);
    width: 400px !important;
    height: 227px !important;
    animation: fade-in-up .25s ease forwards;

    right: unset;
    left: 10px !important;
    top: unset !important;
    bottom: 10px;
    z-index: 100 !important;
  }
  /*Large*/
  .stuckBRl, .stuck-bottom-right {
    position: fixed;
    transform: translateY(100%);
    width: 500px !important;
    height: 283px !important;
    animation: fade-in-up .25s ease forwards;

    right: 25px;
    left: unset !important;
    top: unset !important;
    bottom: 10px;
    z-index: 100 !important;
  }

  .stuckTLl, .stuck-top-left {
    position: fixed;
    transform: translateY(100%);
    width: 500px !important;
    height: 283px !important;
    animation: fade-in-up .25s ease forwards;

    left: 10px !important;
    top: 10px !important;
    z-index: 100 !important;
  }

  .stuckTRl, .stuck-top-right {
    position: fixed;
    transform: translateY(100%);
    width: 500px !important;
    height: 283px !important;
    animation: fade-in-up .25s ease forwards;

    right: 25px;
    left: unset !important;
    top: 10px !important;
    z-index: 100 !important;
  }

  .stuckBLl, .stuck-bottom-left {
    position: fixed;
    transform: translateY(100%);
    width: 500px !important;
    height: 283px !important;
    animation: fade-in-up .25s ease forwards;

    right: unset;
    left: 10px !important;
    top: unset !important;
    bottom: 10px;
    z-index: 100 !important;
  }
</style>
