export default {
    data() {
      return {
        sidebar: '2',
        props: {
          'name': 'VideoComponent',
          'zIndex': 'auto',
          'paddingTop': '0px',
          'paddingLeft': '15px',
          'paddingRight': '15px',
          'paddingBottom': '15px',
          'width': '100px',
          'height': '100px',
          'minHeight': '0px',
          'maxWidth': '100px',
          secret: '',
          autoplay: false,
          countdown: false,
          loop: false,
        },
        elements: [],
        internal_config: {
          'theme': '01',
          'width': '100px',
          'maxWidth': '100px',
          'height': '100px',
          'minHeight': '0px',
          'zIndex': 'auto',
          'showDesktop': true,
          'showMobile': true,
          'paddingTop': '0px',
          'paddingLeft': '15px',
          'paddingRight': '15px',
          'paddingBottom': '15px',
          left: '0px',
          top: '0px',
          secret: '',
          autoplay: false,
          countdown: false,
          loop: false,
        }
      };
    }
  };
