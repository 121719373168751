import Http from './Http';
import axios from "axios";

const prefix = '/website/user';

export const register = data => Http.api().post(`${prefix}/register`, data);

export const loginRegister = data => Http.api().post(`${prefix}/login`, data);

export const loginToken = data => Http.api().post(`${prefix}/login/token`, data);

export const loginBA = data => Http.api().post(`${prefix}/login/builderall`, data);

export const forgot = data => Http.api().post(`${prefix}/password/forgot`, data);

export const getFields = layout_id => Http.api().get(`${prefix}/fields/all/${layout_id}`);

// auth token
export const check = data => Http.authApi().post(`${prefix}/check`, data);

export const logout = data => Http.authApi().post(`${prefix}/logout`, data);

export const updateUser = data => Http.authApi().put(`${prefix}/update`, data);

export const getInfoUser = () => Http.authApi().get(`${prefix}/get-info`);

export const getCourseElearning = (id, email, md5Password) => {

    var token = localStorage.getItem('login-user-token')
        ? localStorage.getItem('login-user-token')
        : '';

    let instance = axios.create({
        baseURL: (process.env.NODE_ENV === 'development') ? 'http://publication.localhost/' : '',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }
    });

    return instance.post(`/website/user/elearning/get-course/${id}/${email}/${md5Password}`);
}
