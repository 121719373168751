import axios from "axios";
import {getCourseElearning} from "../WebsiteUser";

export const mixinRestrictArea  = {
    data: function () {
        return {
            currentPage: false,
        }
    },
    created: function () {
    },
    methods: {
        getCurrentPage(){
            let actualPage;

            try {
                if (window.location.pathname === '/') {

                    actualPage = pagesConfig.find((page, index) => page !== null && (page.initial === '1'));

                    if (!actualPage)
                        actualPage = pagesConfig.find((page, index) => page !== null && (page.initial === '0' && index === 0));

                } else {
                    var url = window.location.pathname.replace('/', '');

                    if(url.indexOf('page/') !== -1) {
                        url = 'page';
                    }

                    try {
                        var slit = url.split('/');

                        if (slit.length > 1) {
                            if(slit.length >= 2 && slit[0] === 'blog') {
                                url = slit[1]
                            } else {
                                url = slit[0]
                            }
                        }

                    } catch (e) {
                        console.warn('mixinRestrictArea -> getCurrentPage Split', e);
                    }

                    if (url)
                        actualPage = pagesConfig.find((page) => page !== null && page.url.toLowerCase() === url.toLowerCase());
                }

                if(!actualPage){
                    actualPage = pagesConfig.find((page, index) => page !== null && (page.initial === '1'));

                    if (!actualPage)
                        actualPage = pagesConfig.find((page, index) => page !== null && (page.initial === '0' && index === 0));
                }

                if(actualPage)
                    this.currentPage = actualPage;

            } catch (e) {

                if(typeof pagesConfig === 'undefined') {
                    axios.get(window.location.origin + '/cheetah/version')
                        .then((res => {
                            location.reload();
                        }));
                }

                console.warn('mixinRestrictArea -> getCurrentPage', e);
            }


            return actualPage;
        },


        getLogin() {

            return {
                user: JSON.Parse(localStorage.getItem('login-user')),
                all: JSON.Parse(localStorage.getItem('login-user-all')),
                token: localStorage.getItem('login-user-token'),
            }
        },

        setLogin: function (login) {

            if (login) {

                localStorage.setItem('login-user', JSON.stringify(login.user));
                localStorage.setItem('login-user-all', JSON.stringify(login));
                localStorage.setItem('login-user-token', login.token);
                localStorage.setItem('login-user-token-md5', login.token_md5); //password md5
                localStorage.setItem('login-user-token-hash', login.token_hash); //password md5

                this.$store
                    .dispatch("efetuarLogin", login);

                this.callRedirect();
            }
        },

        setLogout(reload = true){
            localStorage.removeItem('login-user');
            localStorage.removeItem('login-user-all');
            localStorage.removeItem('login-user-token');
            localStorage.removeItem('login-user-token-md5');
            localStorage.removeItem('login-user-token-hash');

            this.$store
                .dispatch("efetuarLogout");

            this.callRedirect(reload);
        },

        callRedirectElearning(restrictAreas, openByForce = false){
            if(openByForce)
                this.open_by = openByForce;

            let restricts = restrictAreas.filter( areas => areas.redirect_config);

            restricts.map( async (restrict, index)  => {

                if(typeof restrict.redirect_config === 'undefined' && this.open_by === 'page')
                    return;

                let redirect_config = this.$isJson(restrict.redirect_config) ? JSON.parse(restrict.redirect_config) : {};

                let md5Password = localStorage.getItem('login-user-token-md5');

                let loginUser = localStorage.getItem('login-user');

                loginUser = this.$isJson(loginUser) ? JSON.parse(loginUser) : false;

                if(redirect_config.elearning.type === 'elearning-course' || this.open_by === 'popup') {

                    let target = redirect_config.elearning.target === 'blank' && this.open_by === 'page' ? 'blank' : 'same';
                    let id = (typeof restrict.restrict_area_id !== 'undefined') ? restrict.restrict_area_id : restrict.id;

                    if (index === 0) {

                        getCourseElearning(id, loginUser.email, md5Password)
                            .then((res) => {

                                if (res.data.success) {
                                    if (target === 'blank') {
                                        window.open(res.data.url_course, '_blank');
                                        window.location.reload();
                                    } else
                                        window.location.href = res.data.url_course;
                                } else {
                                    this.$root.$emit('membersAreaElearningNotApproved');
                                    // window.location.reload();
                                }
                            })

                    } else {
                        getCourseElearning(id, loginUser.email, md5Password)
                            .then((res) => {
                                if (res.data.success) {
                                    if (target === 'blank') {
                                        window.open(res.data.url_course, '_blank');
                                        window.location.reload();
                                    } else
                                        window.location.href = res.data.url_course;
                                } else {
                                    // window.location.reload();
                                }
                            })
                    }
                }else{
                    console.warn('ultimo else');
                    // window.location.reload();
                }
            })
        },

        async callRedirect(remember = true){

            let md5Password = localStorage.getItem('login-user-token-md5');

            if(this.redirect && typeof this.redirect.type !== 'undefined'){
                this.$root.$emit('linkAction', this.redirect);
            }

            else if (this.redirect || (this.open_by === 'popup' && typeof md5Password !== 'undefined' && md5Password !== null && this.redirect_elearning_course)) {

                if (this.redirect_elearning_course && typeof md5Password !== 'undefined' && md5Password !== null)
                    this.callRedirectElearning(this.members_area_active);
                else {
                    pagesConfig.forEach(page => {
                        if (+this.redirect === +page.id) {
                            window.location.href = '/' + page.url
                        }
                    });
                }
            } else {
                if (this.open_by === 'page' && typeof md5Password !== 'undefined' && md5Password !== null && this.redirect_elearning_course) {
                    await this.callRedirectElearning(this.active_page.restrict_areas);
                }
                else if (remember)
                    window.location.reload();
            }

        },

        goToHomePage(){
            let homePage;

            try {

                homePage = pagesConfig.find((page, index) => page !== null && page.initial === '1' && typeof page.is_member !== 'undefined' && !parseInt(page.is_member));

                if(!homePage)
                    homePage = pagesConfig.find((page, index) => page !== null && page.initial === '0' && index === 0 &&  typeof page.is_member !== 'undefined' && !parseInt(page.is_member));

                if (homePage) {
                    if (parseInt(homePage.is_member) && window.location.pathname !== ('/' + homePage.url))
                        window.location.href = `${window.origin}/${homePage.url}`
                }
            } catch (e) {
                console.warn('gethomePage', e);
            }
        }

    }
};
