export default {
  data() {
    return {
      newLines: 0,
      props: {
        'name': 'BlogFeaturedGroupComponent',
        'zIndex': 'auto',
        'paddingTop': '0px',
        'paddingLeft': '15px',
        'paddingRight': '15px',
        'paddingBottom': '15px'
      },
      articles: [],
      pagination: {
        show: true,
        previous: false,
        next: false,
        first: false,
        last: false,
        actual_page: 1,
        path: '',
        pages: [],
        first_page_url: "/?page=1",
        from: 1,

        last_page_url: "/?page=5",
        next_page_url: "/?page=2",
        per_page: 10
      },
      internal_config: {
        version: 1,
        'width': '900px',
        'height': '500px',
        'zIndex': 'auto',
        'showDesktop': true,
        'showMobile': true,
        'paddingTop': '0px',
        'paddingLeft': '15px',
        'paddingRight': '15px',
        'paddingBottom': '15px',
        left: '0px',
        top: '0px',
        theme: 'simple',
        type: 'featured',
        columns: 3,
        headline: 'none',
        filters: {
          category: 'all',
          author: 'all',
          featured: false,
          mostAccessed: false,
          amount: 6,
          search: false,
        },
        selectedArticle: 0,
        articles: [],
        // card props
        card: {
          sizes: {
            thumbnail: {
              height: '0px',
              width: '0px',
              borderRadius: '0 0 0 0'
            },
            title: {
              height: '0px',
              width: '0px'
            },
            content: {
              height: '50px',
              width: '0px'
            },
            buttonDiv: {
              height: '0px',
              width: '0px'
            },
            button: {
              height: '0px',
              width: '0px',
              borderRadius: '0 0 0 0'
            },
            categories: {
              height: '0px',
              width: '0px'
            },
            card: {
              height: '0px',
              width: '0px',
              borderRadius: '0 0 0 0'
            }
          },
          config: {
            dateFormat: 'YYYY-MM-DD',
            readMoreText: 'Read More',
            buttonPosition: 'left'
          },
          colors: {
            color_1: 'white',
            color_2: '#000',
            color_3: '#fff',
            color_4: '#000',
            color_5: '#000'
          },
          show: {
            author: true,
            categories: true,
            thumbnail: true,
            date: true,
            content: false,
            button: true
          },
          styles: {
            title: {
              family: 'Lato, sans-serif',
              size: '25px',
              sizeMobile: '15px',
              color: 'black',
              align: 'left',
              weight: '400',
              style: 'initial',
              stroke: 'none'
            },
            content: {
              family: 'Lato, sans-serif',
              size: '25px',
              sizeMobile: '15px',
              color: 'black',
              align: 'left',
              weight: '400',
              style: 'initial',
              stroke: 'none',
              height: '200px'
            },
            button: {
              family: 'Lato, sans-serif',
              size: '25px',
              sizeMobile: '15px',
              color: 'white',
              align: 'left',
              weight: '400',
              style: 'initial',
              stroke: 'none'
            },
            author: {
              family: 'Lato, sans-serif',
              size: '25px',
              sizeMobile: '15px',
              color: 'black',
              align: 'left',
              weight: '400',
              style: 'initial',
              stroke: 'none'
            },
            categories: {
              family: 'Lato, sans-serif',
              size: '25px',
              sizeMobile: '15px',
              color: 'black',
              align: 'left',
              weight: '400',
              style: 'initial',
              stroke: 'none'
            }
          }
        }
      }
    };
  }
};
