<template>
  <div>
    <!-- Desktop -->
    <div class="card-clean desktop-element clean-theme-desktop mtf" :style="cardStyle()">
      <!-- Thumbnail -->
      <!--        <a :href="showUrl(article.url)">-->
      <div
        @click="openUrl(showUrl(`/blog/${article.url}`))"
        v-show="show.thumbnail"
        class="card-clean__thumbnail"
        :style="thumbnailStyle()"
      />
      <!--        </a>-->
      <div class="card-clean__content">
        <!-- Categories -->
        <div
          v-show="show.categories"
          class="card-clean__content-categories"
        >
          <a
            v-for="category in article.categories"
            :key="category.id"
            :style="categoryStyle()"
            :href="showUrl(`/c/${category.slug}`)"
            class="badge ml-1">
            {{ category.name }}
          </a>
        </div>

        <!-- Content -->
        <div id="summaryArticle" class="card-clean__content-preview">
          <a
            :href="showUrl(`/blog/${article.url}`)"
            class="clean-text-css"
            :style="titleStyle()">
            {{ article.title }}
          </a>
          <p
            v-show="show.content"
            class="clean-text-css"
            :style="contentStyle()"
            v-html="article.content"
          >

          </p>
        </div>

        <!-- Read more -->
        <div
          v-show="show.button"
          :style="buttonDivStyle()">
          <a :href="showUrl(`/blog/${article.url}`)" :class="`btn float-${config.buttonPosition}`" :style="buttonStyle()">{{ config.readMoreText }}</a>
        </div>

        <!-- Author -->
        <div
          class="card-clean__content-info"
          :style="authorStyle()">
          <a :style="authorStyle()"  class="clean-text-css"   :href="showUrl(`/a/${article.author.slug}`)" v-show="show.author" >{{ article.author.name }}</a>
          <span  class="clean-text-css" v-show="show.date">{{ formatDate(article.scheduled_date, config.dateFormat) }}</span>
        </div>
      </div>
    </div>

    <!-- Mobile -->
    <div class="card-clean mobile-element clean-theme-mobile" :style="cardStyle(false)">
      <!-- Thumbnail -->
      <img v-show="show.thumbnail" class="card-img-top" @click="openUrl(showUrl(`/blog/${article.url}`))" :src="article.thumbnail" :alt="`${article.title} thumbnail`">
      <div class="card-clean__content">
        <!-- Categories -->
        <div v-show="show.categories">
          <a
            :href="showUrl(`/c/${category.slug}`)"
            v-for="category in article.categories"
            :key="`desktop-category-${category.id}`"
            :style="categoryStyle(false)"
            class="badge ml-1">
            {{ category.name }}
          </a>
        </div>

        <!-- Content -->
        <div id="summaryArticleMobile" class="card-clean__content-preview">
          <a
            :href="showUrl(`/blog/${article.url}`)"
            class="clean-text-css"
            :style="titleStyle(false)">
            {{ article.title }}
          </a>
          <p
            v-show="show.content"
            class="clean-text-css"
            :style="contentStyle(false)"
            v-html="article.content"
          >
          </p>
        </div>

         <!-- Read more -->
        <div
          v-show="show.button"
          :style="buttonDivStyle()">
          <a :href="showUrl(`/blog/${article.url}`)" :class="`btn float-${config.buttonPosition}`" :style="buttonStyle()">{{ config.readMoreText }}</a>
        </div>

        <!-- Author -->
        <div
          class="card-clean__content-info"
          :style="authorStyle(false)">
          <a :style="authorStyle()"  class="clean-text-css" :href="showUrl(`/a/${article.author.slug}`)"  v-show="show.author">{{ article.author.name }}</a>
          <span  class="clean-text-css" v-show="show.date">{{ formatDate(article.scheduled_date, config.dateFormat) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mixinBlogComponents} from "@/js/mixins/BlogComponents";

  export default {
    name: 'CardPostClean',
    props: ['article', 'config', 'colors', 'show', 'styles', 'sizes'],
    mixins: [mixinBlogComponents],
    methods: {
      openUrl(url) {
        if (url !== '#') {
          window.open(url, '_self');
        }
      },
      cardStyle(desktop = true) {
        return `
          background-color: ${this.colors.color_1};
          display: flex ${desktop ? '' : ''};
          border-radius: ${this.sizes.card.borderRadius};`;
      },
      thumbnailStyle() {
        return `
          background: url(${this.article.thumbnail});
          width: ${this.sizes.thumbnail.width};
          cursor: pointer;
          height: ${this.sizes.thumbnail.height !== '0px' ? this.sizes.thumbnail.height : 'auto'};
          border-radius: ${this.sizes.thumbnail.borderRadius};`;
      },
      buttonDivStyle() {
        let align = this.styles.button.align === 'left' ? 'flex-start' : this.styles.button.align === 'right' ? 'flex-end' : 'center';

        return `height: ${this.sizes.buttonDiv.height !== '0px' ? this.sizes.buttonDiv.height : 'auto'};
               display: flex;
               align-items: center;
               justify-content: ${align}`;
      },
      buttonStyle(desktop = true) {
        return `background-color: ${this.colors.color_2};
              box-shadow: none;
              border-radius: ${this.sizes.button.borderRadius};              font-family:  ${this.styles.button.family};
              font-size: ${(desktop) ? this.styles.button.size : this.styles.button.sizeMobile} !important;
              text-align: center;
              font-weight: ${this.styles.button.weight};
              font-style: ${this.styles.button.style};
              text-decoration: ${this.styles.button.stroke};
              color: ${this.styles.button.color};
              height: ${this.sizes.button.height !== '0px' ? this.sizes.button.height : 'auto'};
              width: ${this.sizes.button.width !== '0px' ? this.sizes.button.width : 'auto'};
              overflow: hidden`;
      },
      categoryStyle(desktop = true) {
        return `background-color: ${this.colors.color_3};
              box-shadow: none;
              border-radius: ${this.styles.categories.borderRadius};
              font-family:  ${this.styles.categories.family};
              font-size: ${(desktop) ? this.styles.categories.size : this.styles.categories.sizeMobile} !important;
              text-align: ${this.styles.categories.align};
              font-weight: ${this.styles.categories.weight};
              font-style: ${this.styles.categories.style};
              text-decoration: ${this.styles.categories.stroke};
              color: ${this.styles.categories.color};
               cursor: pointer;
              height: ${this.styles.categories.height};
              overflow: hidden`;
      },
      titleStyle(desktop = true) {
        return `font-family:  ${this.styles.title.family};
                  font-size: ${(desktop) ? this.styles.title.size : this.styles.title.sizeMobile} !important;
                  text-align: ${this.styles.title.align};
                  font-weight: ${this.styles.title.weight};
                  font-style: ${this.styles.title.style};
                  text-decoration: ${this.styles.title.stroke};
                  cursor: pointer;
                  height: ${this.sizes.title.height !== '0px' ? this.sizes.title.height : 'auto'};
                  color: ${this.styles.title.color};
                  -webkit-text-fill-color: ${this.styles.title.color};
`;
      },
      contentStyle(desktop = true) {
        return `font-family:  ${this.styles.content.family};
                  font-size: ${(desktop) ? this.styles.content.size : this.styles.content.sizeMobile} !important;
                  text-align: ${this.styles.content.align};
                  font-weight: ${this.styles.content.weight};
                  font-style: ${this.styles.content.style};
                  text-decoration: ${this.styles.content.stroke};
                  color: ${this.styles.content.color};
                  -webkit-text-fill-color: ${this.styles.content.color};
                  height: ${this.sizes.content.height !== '0px' ? this.sizes.content.height : 'auto'};
                  overflow: hidden`;
      },
      authorStyle(desktop = true) {
        return `font-family:  ${this.styles.author.family};
                  font-size: ${(desktop) ? this.styles.author.size : this.styles.author.sizeMobile} !important;
                  text-align: ${this.styles.author.align};
                  font-weight: ${this.styles.author.weight};
                  font-style: ${this.styles.author.style};
                  text-decoration: ${this.styles.author.stroke};
                   cursor: pointer;
                  color: ${this.styles.author.color}`;
      }
    },
    mounted() {
      $('#summaryArticle h1, #summaryArticle h2, #summaryArticle h3, #summaryArticle h4, #summaryArticle h5, #summaryArticle h6, #summaryArticle p').addClass('clean-text-css');
      $('#summaryArticleMobile h1, #summaryArticle h2, #summaryArticle h3, #summaryArticle h4, #summaryArticle h5, #summaryArticle h6, #summaryArticle p').addClass('clean-text-css');
      $('#summaryArticle img').css('display', 'none');
      $('#summaryArticleMobile img').css('display', 'none');
      $('#summaryArticle > iframe').css('display', 'none');
      $('#summaryArticleMobile > iframe').css('display', 'none');
    },
    watch: {
      styles() {
        $('#summaryArticle h1, #summaryArticle h2, #summaryArticle h3, #summaryArticle h4, #summaryArticle h5, #summaryArticle h6, #summaryArticle p').addClass('clean-text-css');
        $('#summaryArticleMobile h1, #summaryArticle h2, #summaryArticle h3, #summaryArticle h4, #summaryArticle h5, #summaryArticle h6, #summaryArticle p').addClass('clean-text-css');
        $('#summaryArticle img').css('display', 'none');
        $('#summaryArticleMobile img').css('display', 'none');
        $('#summaryArticle > iframe').css('display', 'none');
        $('#summaryArticleMobile > iframe').css('display', 'none');
      },
      article(value) {
        // do nothing
      }
    }
  };
</script>

<style lang="scss" scoped>

  .card-clean {
    display: flex;
    width: 100%;

    &__thumbnail {
      background-size: cover !important;
      background-repeat: no-repeat !important;
    }

    &__content {
      width: 100%;
      padding: 20px;

      &-preview {
        margin: 20px 0;
      }

      &-info {
        display: flex;
        flex-direction: column;
      }
    }
  }

</style>
