<template>
    <form @submit.prevent="register" method="POST" class="restrict-area-form-register" autocomplete="off" ref="form-register">
        <div class="alert alert-danger" v-if="errors.has('alert')">
            <p class="mb-0 clean-text-css">{{ errors.get('alert') }}</p>
        </div>

        <template v-if="loading">
            <div class="d-flex justify-content-center mb-4 mt-2">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
            </div>
        </template>

        <div v-show="!loading">
            <div class="form-group">
              <input
                  type="text"
                  id="name"
                  class="form-control form-control-alternative"
                  v-model="user.name"
                  :class="{'is-invalid': errors.has('name') }"
                  :placeholder="$t('ct-name')"
                  @keydown="errors.clear('name')"
                  required
              />
              <span v-if="errors.has('name')" class="invalid-feedback" v-text="errors.get('name')"></span>
            </div>

            <div class="form-group">
              <!-- <label for="email">E-mail</label>-->
              <input
                  type="email"
                  id="email"
                  class="form-control form-control-alternative"
                  v-model="user.email"
                  :class="{'is-invalid': errors.has('email') }"
                  :placeholder="$t('ct-email')"
                  @keydown="errors.clear('email')"
                  required
              />
              <span
                  v-if="errors.has('email')"
                  class="invalid-feedback"
                  v-text="errors.get('email')"
              ></span>
            </div>
            <div class="form-group" v-if="!hidden_password">
              <!-- <label for="password">Password</label>-->
              <input
                  type="password"
                  id="password"
                  class="form-control form-control-alternative"
                  v-model="user.password"
                  :class="{'is-invalid': errors.has('password') }"
                  :placeholder="$t('ct_password_')"
                  @keydown="errors.clear('password')"
                  required
              />
              <span v-if="errors.has('password')" class="invalid-feedback"
                    v-text="errors.get('password')"></span>
            </div>

            <div class="form-group" v-if="!hidden_password">
                    <!-- <label for="password-confirmation">Password Confirm</label>-->
                    <input
                            type="password"
                            id="password-confirmation"
                            name="password_confirmation"
                            class="form-control form-control-alternative"
                            v-model="user.password_confirmation"
                            :class="{'is-invalid': errors.has('password_confirmation') }"
                            :placeholder="$t('ct-password-confirm')"
                            @keydown="errors.clear('password_confirmation')"
                            required
                    />
                    <span v-if="errors.has('password_confirmation')" class="invalid-feedback"
                          v-text="errors.get('password_confirmation')"></span>
                </div>

                <div class="form-group " v-for="(field, index) in fields_custom" :class="{'form-input-check' : ['checkbox', 'radio'].includes(field.type)}"
                     :key="field.order">
                    <template v-if="!['checkbox', 'radio', 'select', 'textarea', 'datetime'].includes(field.type)">
                        <label :for="field.label">{{ field.label }}</label>
                        <input :type="field.type" class="form-control form-control-alternative" :id="field.label"
                               :name="field.tag" v-model="user.fields_custom[field.tag]"
                               :required="field.required === 1">
                        <small v-if="field.help_text && field.help_text.length > 0 "
                               class="form-text text-muted">{{ field.help_text }}</small>
                    </template>

    <!--                <template v-else-if="field.type === 'datetime'">-->
    <!--                    <label :for="field.label">{{ field.label }}</label>-->
    <!--                    <input type="datetime-local" class="form-control form-control-alternative" :id="field.label"-->
    <!--                           v-model="user.fields_custom[field.tag]" :required="field.required === 1" pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}">-->
    <!--                    <small v-if="field.help_text && field.help_text.length > 0 "-->
    <!--                           class="form-text text-muted">{{ field.help_text }}</small>-->
    <!--                </template>-->

                    <template v-else-if="field.type === 'select'">
                        <label :for="field.label">{{ field.label }}</label>
                        <select class="form-control form-control-alternative" :id="field.label" :name="field.tag"
                                v-model="user.fields_custom[field.tag]" :required="field.required === 1">
                            <option v-for="(option, index) in field.options" :value="option.value"
                                    v-if="field.options">{{ option.name }}
                            </option>
                        </select>
                        <small v-if="field.help_text && field.help_text.length > 0 "
                               class="form-text text-muted">{{ field.help_text }}</small>
                    </template>

                    <template v-else-if="field.type === 'textarea'">
                        <label :for="field.label">{{ field.label }}</label>
                        <textarea class="form-control form-control-alternative" :id="field.label" :name="field.tag"
                                  v-model="user.fields_custom[field.tag]" rows="3" :required="field.required === 1">{{ field.default_value }}</textarea>
                        <small v-if="field.help_text && field.help_text.length > 0 "
                               class="form-text text-muted">{{ field.help_text }}</small>
                    </template>

                    <template v-else-if="['checkbox', 'radio'].includes(field.type)">
                        <label>{{ field.label }}</label>
                        <div class="form-check" v-for="(option, index) in field.options" :key="index"
                             v-if="field.options">
                            <input class="form-check-input" :type="field.type" :name="field.tag"
                                   v-model="user.fields_custom[field.tag]"
                                   :id="option.value" :value="option.value" :required="field.required === 1"
                                   :ref="field.tag" @change="changeRequired(field)">
                            <label class="form-check-label" :for="option.value">
                                {{ option.name }}
                            </label>
                        </div>
                        <small v-if="field.help_text && field.help_text.length > 0 "
                               class="form-text text-muted">{{ field.help_text }}</small>
                    </template>

                </div>

                <div class="form-group text-center">
                    <button type="submit" class="btn btn-success text-center"> {{$t('ct-confirm')}}</button>
                </div>
            </div>
    </form>
</template>

<script>

    import Errors from "../../../../js/Errors";
    import {register, getFields} from "../../../../js/WebsiteUser";
    import {mixinRestrictArea} from "../../../../js/mixins/restrictArea";
    import axios from "axios";

    export default {
        name: "Register",
        props: ['areas_register', /*'areas_register_obj',*/ 'open_by'],
        mixins: [mixinRestrictArea],
        data() {
            return {
                loading:  true,
                errors: new Errors(),
                user: {
                    name: '',
                    email: '',
                    password: '',
                    password_confirmation: '',
                    fields_custom: {}
                },

                fields_custom: {},

                auth_types: '',
                hidden_password: false,
            }
        },

        methods: {

            changeType(active) {
                this.$emit('change', active)
            },

            changeRequired(field) {
                if (this.user.fields_custom[field.tag].length > 0 && field.required) {
                    this.$refs[field.tag].map(field => {
                        field.required = false
                    })
                } else if (field.required) {
                    this.$refs[field.tag].map(field => {
                        field.required = true
                    })
                }
            },

            async registerOnSocialProof(socialproof_campaign_id, name) {

                try {
                    let campaignId = parseInt(socialproof_campaign_id);

                    if (campaignId && name) {
                        var formData = new FormData();
                        formData.set('user_name', name);

                        await axios({
                            'method': 'post',
                            'url': '/cheetah/social-proof/register/' + campaignId,
                            data: formData,
                        }).then((res) => {
                        });
                    }
                } catch (e) {
                    console.error(e);
                }
            },

            register() {

                this.user.fields_custom_config = this.fields_custom;
                this.user.areas_register = this.areas_register;

                if (
                    this.user.name &&
                    this.user.email &&
                    (this.hidden_password || (this.user.password && this.user.password_confirmation))
                ) {
                    this.errors.clear();
                    this.$loading.show();
                    this.loading = true;

                    register(this.user)
                        .then(({data}) => {

                            if (data.success) {
                                this.$loading.show();
                                if (data.data.socialproof_campaign_id) {
                                    this.registerOnSocialProof(data.data.socialproof_campaign_id, data.data.user.name)
                                }

                                let RestrictAreaVue = (typeof this.$parent.$parent.setLogin === 'function') ? this.$parent.$parent : this.$parent.$parent.$parent;
                                RestrictAreaVue.setLogin(data.data);
                            } else {
                                this.loading = false;
                                if (data.data.validator) {
                                  this.errors.record(data.data.errors);
                                }
                            }

                        })
                        .catch(error => {
                            console.warn(error);
                            this.loading = false;
                            this.errors.record({alert: [this.$t('ct-register-error')]});
                        })
                        .finally(() => this.$loading.hide());
                }
            }
        },

        created() {

            let RestrictAreaVue = (typeof this.$parent.$parent.setLogin === 'function') ? this.$parent.$parent : this.$parent.$parent.$parent;
            if (typeof RestrictAreaVue.form_theme.hidden_password !== 'undefined' && parseInt(RestrictAreaVue.form_theme.hidden_password))
                this.hidden_password = parseInt(RestrictAreaVue.form_theme.hidden_password);

            let actialPage = this.getCurrentPage();
            this.user.layout_id = actialPage.layout_id;

            this.user.page_id = actialPage.id;
            this.user.page_url = actialPage.url;

            getFields(actialPage.layout_id)
                .then(response => {
                    if (response.data.success) {
                        this.fields_custom = response.data.data;
                        this.fields_custom.map(field => {
                            if (field.type === 'checkbox' || field.type === 'radio')
                                this.user.fields_custom[field.tag] = [];
                            else
                                this.user.fields_custom[field.tag] = '';
                        })
                    }
                })
                .finally( () => {
                    this.loading = false;
                });
        }
    }
</script>

<style lang="scss" scoped>
    .loading{
        display: none;
    }

    .form-group.form-input-check {
        display: flex;
        flex-direction: column;
    }
</style>
