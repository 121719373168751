<template>




 <div :id="id"
       class="sitebuilder-element-container"
       element="BlogCategoriesListComponent"
       :data-hide-desktop="!this.internal_config.showDesktop"
       :data-hide-mobile="!this.internal_config.showMobile"
       :config-properties="JSON.stringify(internal_config)" :style="style()">



            <div class="desktop-element"  :style="titleStyle()" v-for="category in categories" :key="category.id">
                <a :href="redir(category.slug)" class="category-list" :style="titleStyle()">{{ category.name }}  </a> <br>
            </div>

            <div class="mobile-element" v-for="category in categories"  :style="titleStyleMobile()" :key="category.id">
                <a :href="redir(category.slug)" class="category-list" :style="titleStyleMobile()">{{ category.name }} </a> <br>
            </div>
            





</div>

  
</template>

<script>

import elementData from './Model';
import axios from 'axios';


export default {
    name: 'BlogCategoriesListComponent',
    props: ['config', 'id'],
    components : { },
     data() {
			return elementData.data();
    },
    methods: {
        
        redir(param){
            let url = '';

            if(this.internal_config.type === 'categories')
                url = url + '/c/'

            if(this.internal_config.type === 'authors')
                url = url + '/a/'

            return url + param;
        },

        build(config) {
            this.internal_config = config;
        },
         animation() {
        return false;
        },
        showResources() {
       
        },

        titleStyle() {
                return `font-family:  ${this.internal_config.titleStyle.family};
                    font-size: ${this.internal_config.titleStyle.size};
                    text-align: ${this.internal_config.titleStyle.align};
                    font-weight: ${this.internal_config.titleStyle.weight};
                    font-style: ${this.internal_config.titleStyle.style};
                    text-decoration: ${this.internal_config.titleStyle.stroke};
                    cursor: pointer;
                    line-height: unset;
                    height: auto;
                    color: ${this.internal_config.titleStyle.color}`;
        },

        titleStyleMobile() {
                return `font-family:  ${this.internal_config.titleStyle.family};
                    font-size: ${this.internal_config.titleStyle.sizeMobile} !important;
                    text-align: ${this.internal_config.titleStyle.align};
                    font-weight: ${this.internal_config.titleStyle.weight};
                    font-style: ${this.internal_config.titleStyle.style};
                    text-decoration: ${this.internal_config.titleStyle.stroke};
                    cursor: pointer;
                    line-height: unset;
                    height: auto;
                    color: ${this.internal_config.titleStyle.color}`;
    },


        //style methods

        style(){
           let {
        left,
        zIndex,
        paddingTop,
        paddingLeft,
        paddingRight,
        paddingBottom,
        top
      } = this.internal_config;

      let style = `
                     left:  ${left};
                     z-index: ${zIndex};max-width: ${this.internal_config.maxWidth};margin: 0 auto;
                     padding-top: ${paddingTop};
                     padding-left: ${paddingLeft};
                     padding-right: ${paddingRight};
                     padding-bottom: ${paddingBottom};
                     top: ${top}`;
                     
      document.documentElement.style.setProperty('--color-hover', this.internal_config.colorReadMore);



      return style; 
        },
    },
      created() {
        let device, childs, id, config;
        ({ device, childs, id, config } = this.$props);
        this.internal_config = config;
        this.device = device;
        if (typeof id !== "undefined") {
            this.id = id;
        }
        this.internal_config = window.mergeRecursive(
        this.props,
        this.$props.config
        );
  },
  mounted(){

        let vm = this;

         this.build(this.$props.config);

        document.documentElement.style.setProperty('--color-hover', this.internal_config.colorReadMore);


        if(typeof this.internal_config.type === 'undefined')
                this.internal_config.type = 'categories'
          //montagem url
        let url = '';

        if(window.location.host == 'publication.localhost'){
            url = 'http://blog.localhost/get-childs'
        }else{
            url = 'https://blog-beta.omb11.com/get-childs'
        }

        url = url + '?website_id=' + window.OPTIONS_INIT_SITE.options.w_code;

        axios.get(url + '&type=' + this.internal_config.type)
        .then( (res) => {
            this.categories = res.data;
        } )


   

         let objIds = [];
            $("[id]").each(function() {
            var ids = $('[id="' + this.id + '"]');
            if (ids.length > 1 && ids[0] == this && parseInt(this.id))
                objIds.push(this.id);
            });
        if (objIds.includes(this.id)) {
        this.id = Date.now() + parseInt(Math.random() * 10000);
        }
  }
}
</script>

<style scoped>

[element="BlogCategoriesListComponent"] .vdr {
    height: auto !important;
}



  .category-list:hover{
    color: var(--color-hover) !important;
    cursor: pointer;
  }

</style>