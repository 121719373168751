import Vue from 'vue'
import App from './App.vue'
import Notifications from 'vue-notification'
import VueLazyload from 'vue-lazyload'
// import VueI18n from 'vue-i18n';
// import { VLazyImagePlugin } from "v-lazy-image/src/index";
import Datetime from 'vue-datetime';
import Loading from './js/Loading';
import 'animate.css/animate.min.css';
import 'vue-datetime/dist/vue-datetime.css';
// import { languages } from '../static/translation/index.js';
import './js/directives/toggle';
import 'urlparam-parse/src/urlparam-parse';

import BoxComponent from './components/apps/Box/Box'
import BoxHoverComponent from './components/apps/BoxHover/BoxHover'
import ImageComponent from "./components/apps/Image/Image.vue";
import TextComponent from "./components/apps/Text/Text.vue";
import ButtonComponent from "./components/apps/Button/Button.vue";
import MailingBossFormComponent from "./components/apps/Forms/MailingBossForm/MailingBossForm";
import LaunchAppComponent from "./components/apps/Forms/Launch/LaunchApp";
import GetResponseFormComponent from "./components/apps/Forms/GetResponseForm/GetResponseForm";
import SimpleFormComponent from "./components/apps/Forms/SimpleForm/SimpleForm";
import MultiStepFormComponent from "./components/apps/Forms/MultiStepForm/MultiStepForm";
import YoutubeVideoComponent from "./components/apps/YoutubeVideo/Youtube";
import IframeComponent from "./components/apps/Iframe/Iframe";
import MenuComponent from "./components/apps/Menu/Menu";
import VimeoVideoComponent from "./components/apps/Vimeo/Vimeo";
import PandaVideoComponent from "./components/apps/PandaVideo/PandaVideo";
import WebinarVideoComponent from "./components/apps/Webinar/Webinar";
import TimerComponent from "./components/apps/Timer/Timer";
import LineVComponent from "./components/apps/LineV/LineV";
import LineComponent from "./components/apps/Line/Line";
import WistiaVideoComponent from "./components/apps/Wistia/Wistia"
import SliderGalleryComponent from "./components/apps/SliderGallery/SliderGallery"
import Image3DComponent from "./components/apps/Image3D/Image3D";
import CounterTextComponent from "./components/apps/CounterText/CounterText";
import CounterBarComponent from "./components/apps/CounterBar/CounterBar";
import ImageGalleryComponent from "./components/apps/ImageGallery/ImageGallery";
import SocialBarComponent from "./components/apps/SocialBar/SocialBar";
import AccordionComponent from "./components/apps/Accordion/Accordion";
import AccordionGroupComponent from "./components/apps/AccordionGroup/AccordionGroup";
import SliderGroupComponent from "./components/apps/SliderDropGallery/SliderGroup/SliderGroup";
import SliderPageComponent from "./components/apps/SliderDropGallery/SliderPage/SliderPage";
import GroupMenuComponent from "./components/apps/BigMenu/GroupMenu/GroupMenu";
import PlaceMenuComponent from "./components/apps/BigMenu/PlaceMenu/PlaceMenu";
import VidelloVideoComponent from "./components/apps/VidelloVideo/Vidello";
import VideoGalleryGroupComponent from "./components/apps/VideoGallery/Gallery/Gallery";
import VideoGalleryComponent from "./components/apps/VideoGallery/Video/Video";
import VideoComponent from "./components/apps/Video/Video";

/** Booking */
import BookingCalendarComponent from "./components/apps/Booking/Calendar/Calendar"

/**  Super Checkout */
import CheckoutComponent from "./components/apps/Checkout/Checkout";

/** Blog */
import BlogComponent from "./components/apps/Blog/Blog"
import PostBlogComponent from "./components/apps/PostBlog/PostBlog"
import BlogFeedNewsComponent from "./components/apps/Blog/Feed/FeedNews";
import BlogPostComponent from "./components/apps/Blog/Post/Post";
import BlogCategoriesListComponent from "./components/apps/Blog/Categories/CategoriesList";
import BlogDatesListComponent from "./components/apps/Blog/Dates/DatesList";
import BlogSearchComponent from "./components/apps/Blog/Search/Search";
import BlogRssComponent from "./components/apps/Blog/RSS/Rss";
import BlogArticleListComponent from "./components/apps/Blog/Lists/Article/ArticleList";
import BlogNewsSliderComponent from "./components/apps/Blog/NewsSlider/NewsSlider"

import Register from "./components/base/restrictArea/structure/Register";
import Login from "./components/base/restrictArea/structure/Login";
import LoginToken from "./components/base/restrictArea/structure/LoginToken";
import LoginBA from "./components/base/restrictArea/structure/LoginBA";
import RememberPassword from "./components/base/restrictArea/structure/RememberPassword";
import NotHavePermission from "./components/base/restrictArea/structure/NotHavePermission";
import ContentNavUserComponent from "./components/apps/Members/ContentNavUser/ContentNavUser";
import ButtonUserComponent from "./components/apps/Members/ButtonUser/ButtonUser";

import LottieFileComponent from "./components/apps/LottieFile/LottieFile.vue"
import FloatingButtonComponent from "./components/apps/FloatingButton/FloatingButton.vue"

import 'atvimg/atvImg.css';
import 'atvimg/atvImg';

/** Quiz */
import QuizComponent from "./components/apps/Quiz/Quiz";


require('intersection-observer');

IntersectionObserver.prototype.POLL_INTERVAL = 100;

Vue.use(Notifications);
// Vue.use(VLazyImagePlugin);
Vue.use(VueLazyload, {
  preLoad: 1.75,
  lazyComponent: true,
  observer: true,
  attempt: 1,
  listenEvents: ['scroll', 'wheel', 'mousewheel', 'resize', 'animationend', 'transitionend', 'touchmove', 'touchend']
});
Vue.use(Datetime);
Vue.component('QuizComponent', QuizComponent)
Vue.component('BookingCalendarComponent', BookingCalendarComponent)
Vue.component('BoxComponent', BoxComponent);
Vue.component('BoxHoverComponent', BoxHoverComponent);
Vue.component('ImageComponent', ImageComponent);
Vue.component('TextComponent', TextComponent);
Vue.component('ButtonComponent', ButtonComponent);
Vue.component('MailingBossFormComponent', MailingBossFormComponent);
Vue.component('LaunchAppComponent', LaunchAppComponent);
Vue.component('GetResponseFormComponent', GetResponseFormComponent);
Vue.component('SimpleFormComponent', SimpleFormComponent);
Vue.component('MultiStepFormComponent', MultiStepFormComponent);
Vue.component('YoutubeVideoComponent', YoutubeVideoComponent);
Vue.component('IframeComponent', IframeComponent);
Vue.component('MenuComponent', MenuComponent);
Vue.component('CheckoutComponent', CheckoutComponent);
Vue.component('VimeoVideoComponent', VimeoVideoComponent);
Vue.component('PandaVideoComponent', PandaVideoComponent);
Vue.component('WebinarVideoComponent', WebinarVideoComponent);
Vue.component('TimerComponent', TimerComponent);
Vue.component('LineVComponent', LineVComponent);
Vue.component('LineComponent', LineComponent);
Vue.component('WistiaVideoComponent', WistiaVideoComponent);
Vue.component('SliderGalleryComponent', SliderGalleryComponent);
Vue.component('Image3DComponent', Image3DComponent);
Vue.component('CounterTextComponent', CounterTextComponent);
Vue.component('CounterBarComponent', CounterBarComponent);
Vue.component('ImageGalleryComponent', ImageGalleryComponent);
Vue.component('SocialBarComponent', SocialBarComponent);
Vue.component('AccordionGroupComponent', AccordionGroupComponent);
Vue.component('AccordionComponent', AccordionComponent);
Vue.component('SliderGroupComponent', SliderGroupComponent);
Vue.component('SliderPageComponent', SliderPageComponent);
Vue.component('BlogComponent',BlogComponent);
Vue.component('PostBlogComponent',PostBlogComponent);
Vue.component('BlogFeedNewsComponent',BlogFeedNewsComponent);
Vue.component('BlogPostComponent',BlogPostComponent);
Vue.component('BlogDatesListComponent',BlogDatesListComponent);
Vue.component('BlogCategoriesListComponent',BlogCategoriesListComponent);
Vue.component('BlogSearchComponent',BlogSearchComponent);
Vue.component('GroupMenuComponent', GroupMenuComponent);
Vue.component('PlaceMenuComponent', PlaceMenuComponent);
Vue.component('VideoGalleryComponent',VideoGalleryComponent);
Vue.component("VideoGalleryGroupComponent",VideoGalleryGroupComponent);
Vue.component("VidelloVideoComponent",VidelloVideoComponent);
Vue.component("BlogRssComponent",BlogRssComponent);
Vue.component("BlogArticleListComponent",BlogArticleListComponent);
Vue.component("BlogNewsSliderComponent", BlogNewsSliderComponent)
Vue.component('VideoComponent', VideoComponent);


Vue.component('ButtonUserComponent', ButtonUserComponent);
Vue.component("ContentNavUserComponent", ContentNavUserComponent);

Vue.component('Register', Register);
Vue.component('Login', Login);
Vue.component('LoginToken', LoginToken);
Vue.component('LoginBuilderall', LoginBA);
Vue.component('RememberPassword', RememberPassword);
Vue.component('NotHavePermission', NotHavePermission);
Vue.component('LottieFileComponent', LottieFileComponent);
Vue.component('FloatingButtonComponent', FloatingButtonComponent);

// Vue.use(VueI18n);

import LottieVuePlayer from "@lottiefiles/vue-lottie-player";

Vue.use(LottieVuePlayer);

var defaultLocale = (window.navigator.userLanguage || window.navigator.language).replace('-','_');
if(defaultLocale.length == 2){
  defaultLocale += '_'+defaultLocale.toUpperCase();
}

if(typeof OPTIONS_INIT_SITE !== 'undefined' && typeof OPTIONS_INIT_SITE.options !== 'undefined' && typeof OPTIONS_INIT_SITE.options.lang !== 'undefined'){
  defaultLocale = OPTIONS_INIT_SITE.options.lang;
}
import Translate from '../public/static/Translate';

Vue.prototype.$translate = new Translate();
Vue.prototype.$translate.init(defaultLocale);
Vue.prototype.$t = Vue.prototype.$translate.get;

// const messages = Object.assign(languages);

// export const i18n = new VueI18n({
//   locale: defaultLocale,
//   fallbackLocale: 'en_US',
//   messages
// });

Vue.config.productionTip = false
Vue.prototype.$loading = new Loading();
window.$t = Vue.prototype.$translate.get;

const isJson = (data) => {
  try {
    JSON.parse(data);
    return true;
  } catch (e) {
    return false;
  }
};

const storageON  = () => {
  try {
    localStorage.setItem("__test", "data");
  } catch (e) {
    return false;
  }
  return true;
}

const isDeviceIOS = () => {
  try {

    let isIOS = !!navigator.platform.toLowerCase().match(/iphone|ipod|ipad|macintel/);
    let agent = window.navigator.userAgent, start = agent.indexOf('Version/');

    if (start > -1 && isIOS) {
      let version = window.Number(agent.substr(start + 8, 2));
      if (version >= 13)
        return isIOS
    }
    return false;
  } catch (e) {
    return false;
  }
};

Vue.prototype.$isJson = isJson;
Vue.prototype.$storageON = storageON;
Vue.prototype.$isDeviceIOS = isDeviceIOS;

Vue.prototype.$globalStoreHelpers = new Vue({
  data: {
    isValidHex: (hex) => /^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex),
    hexToRGBA: (hex, alpha) => {

      function isValidHex(hex) {
        return /^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex)
      }

      function getChunksFromString(st, chunkSize) {
        return st.match(new RegExp(`.{${chunkSize}}`, "g"))
      }

      function convertHexUnitTo256(hexStr) {
        return parseInt(hexStr.repeat(2 / hexStr.length), 16)
      }

      function getAlphafloat(a, alpha) {
        if (typeof a !== "undefined") {
          return a / 256
        }
        if (typeof alpha !== "undefined") {
          if (1 < alpha && alpha <= 100) {
            return alpha / 100
          }
          if (0 <= alpha && alpha <= 1) {
            return alpha
          }
        }
        return 1
      }

      if (!isValidHex(hex)) {
        throw new Error("Invalid HEX")
      }
      const chunkSize = Math.floor((hex.length - 1) / 3);
      const hexArr = getChunksFromString(hex.slice(1), chunkSize);
      const [r, g, b, a] = hexArr.map(convertHexUnitTo256);
      return `rgba(${r}, ${g}, ${b}, ${getAlphafloat(a, alpha)})`
    }
  },
});

window.mergeRecursive = function (obj1, obj2) {

  for (var p in obj2) {
    try {
      // Property in destination object set; update its value.
      if (obj2[p].constructor == Object) {
        obj1[p] = window.mergeRecursive(obj1[p], obj2[p]);

      } else {
        obj1[p] = obj2[p];

      }

    } catch (e) {
      // Property in destination object not set; create it and set its value.
      obj1[p] = obj2[p];

    }
  }

  return obj1;
}

window.addScript = function (src, timeout, body) {
	let s = document.createElement('script');
	s.setAttribute('src', src);
	s.setAttribute('timeout', timeout);
	if (body)
		document.body.appendChild(s);
	else
		document.head.appendChild(s);
};

import Vuex from 'vuex'

Vue.use(Vuex);

import store from './store';

new Vue({
  store: new Vuex.Store(store),
  render: h => h(App),
}).$mount('#app')


