import pt_BR from './pt_BR.json'
import en_US from './en_US.json'
import fr_FR from './fr_FR.json'
import de_DE from './de_DE.json'
import es_ES from './es_ES.json'
import it_IT from './it_IT.json'
import pt_PT from './pt_PT.json'
import ar_AR from './ar_AR.json'
import he_IL from './he_IL.json'
import nl_NL from './nl_NL.json'
import vi_VI from './vi_VI.json'
import ro_RO from './ro_RO.json'
import hu_HU from './hu_HU.json'
import zh_CN from './zh_CN.json'
import ru_RU from './ru_RU.json'
import hi_HI from './hi_HI.json'
import pa_PA from './pa_PA.json'
import ja_JP from './ja_JP.json'
import el_EL from './el_EL.json'
import ko_KO from './ko_KO.json'
import si_SI from './si_SI.json'
import pl_PL from './pl_PL.json'
import tr_TR from './tr_TR.json'
import cz_CZ from './cz_CZ.json'
import sk_SK from './sk_SK.json'

export const languages = {
pt_BR: pt_BR,
en_US: en_US,
fr_FR: fr_FR,
de_DE: de_DE,
es_ES: es_ES,
it_IT: it_IT,
pt_PT: pt_PT,
ar_AR: ar_AR,
he_IL: he_IL,
nl_NL: nl_NL,
vi_VI: vi_VI,
ro_RO: ro_RO,
hu_HU: hu_HU,
zh_CN: zh_CN,
ru_RU: ru_RU,
hi_HI: hi_HI,
pa_PA: pa_PA,
ja_JP: ja_JP,
el_EL: el_EL,
ko_KO: ko_KO,
si_SI: si_SI,
pl_PL: pl_PL,
tr_TR: tr_TR,
cz_CZ: cz_CZ,
sk_SK: sk_SK,
}