export default {
  data() {
    return {
      props: {
        'tag': '',
        'height': '50px',
        'width': '150px',
        'zIndex': 'auto','maxWidth':'768px',

        'rotation': '0deg',

        'init': 0,
        'finish': 1000,
        'timer' : 2000,

        'border': '0 none #000000',
        'borderRadius': '0 0 0 0',
        'backgroundColor': '#21309b',
        'backgroundColorHover': '#21309b',

        'textColorHover': '#FFFFFF',
        'backgroundImage': 'none',/*url("photographer.jpg"); The image used */
        'backgroundPosition': 'center', /* Center the image todo this resource, not possible change at the moment */
        'backgroundOrigin': 'border-box', /* padding-box|border-box|content-box Do not repeat the image */
        'backgroundRepeat': 'no-repeat', /*repeat | repeat-x | repeat-y | no-repeat | space | round   Do not repeat the image */
        'backgroundSize': 'cover', /* 'auto | 50% | 100% 100% | cover | contain  'Resize the background image to cover the entire container */

        'boxShadow': '1px 1px 1px 1px blue',
        'paddingTop': '15px',
        'paddingLeft': '15px',
        'paddingRight': '15px',
        'paddingBottom': '15px',

        'textStyle': {
          'family': 'arial, helvetica, sans-serif',
          'size': '15px',
          'sizeMobile': '15px',
          'color': 'White',
          'align': 'center',
          'weight': '700',
          'style': 'initial',
          'stroke': 'none'
        },
        'animation': {
          'in': {
            'action': 'time',   //time/scroll/click/hover
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'speed': 'slow',
            'infinite': false,
          },
          'out': {
            'action': 'time',   //time/scroll/click
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'delay': '1',
            'speed': '1',
            'infinite': false,
          },
        },
      },
      internal_config: {
        'tag': '',
        'height': '50px',
        'width': '150px',
        'zIndex': 'auto','maxWidth':'768px',
        'rotation': '0deg',
        'init': 0,
        'finish': 1000,

        'border': '0 none #000000',
        'borderRadius': '0 0 0 0',
        'backgroundColor': '#21309b',
        'backgroundColorHover': '#21309b',

        'textColorHover': '#FFFFFF',
        'backgroundImage': 'none',/*url("photographer.jpg"); The image used */
        'backgroundPosition': 'center', /* Center the image todo this resource, not possible change at the moment */
        'backgroundOrigin': 'border-box', /* padding-box|border-box|content-box Do not repeat the image */
        'backgroundRepeat': 'no-repeat', /*repeat | repeat-x | repeat-y | no-repeat | space | round   Do not repeat the image */
        'backgroundSize': 'cover', /* 'auto | 50% | 100% 100% | cover | contain  'Resize the background image to cover the entire container */

        'boxShadow': '1px 1px 1px 1px blue',
        'paddingTop': '15px',
        'paddingLeft': '15px',
        'paddingRight': '15px',
        'paddingBottom': '15px',

        'textStyle': {
          'family': 'arial, helvetica, sans-serif',
          'size': '15px',
          'sizeMobile': '15px',
          'color': 'White',
          'align': 'center',
          'weight': '700',
          'style': 'initial',
          'stroke': 'none'
        },
        'animation': {
          'in': {
            'action': 'time',   //time/scroll/click/hover
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'speed': 'slow',
            'infinite': false,
          },
          'out': {
            'action': 'time',   //time/scroll/click
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'delay': '1',
            'speed': '1',
            'infinite': false,
          },
        },
      },
      name:
        'countertext',
    }
  }

}


