export default {
  data() {
    return {
      sidebar: '2',
      keyChange: 0,
      props: {
        'tag': '',
        'height': '100px',
        'width': '100px',
        'opacity': 1,
        'boxShadow': '0 0 0 0 black initial',
        'type': '',
        'href': '',
        'marginLeft': '0',
        'marginRight': '0',
        'callToAction': {
          'enable': false,
          'text': '',
        },
        'right': false,
        'animation': {
          'in': {
            'action': 'time',   //time/scroll/click/hover
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'speed': 'slow',
            'infinite': false,
          },
          'out': {
            'action': 'time',   //time/scroll/click
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'delay': '1',
            'speed': '1',
            'infinite': false,
          },
        },
      },
      internal_config: {
        type: 'whatsapp',
        href: '',
        callToAction: {
          enable: false,
          text: '',
        },
        right: false,
        showDesktop: true,
        showMobile: true,
        boxShadow: '0 0 0 0 black initial',
        width: '100px',
        height: '100px',
        opacity: 1,
        'marginLeft': '0',
        'marginRight': '0',
      }
    }
  }
}
