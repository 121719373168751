class CurrentPage {
    constructor() {
        this.current_page = this.get();
    }

    get = () => {
        let actualPage = false;

        if (window.location.pathname !== '/') {
            let url = window.location.pathname.replace('/', '');

            if (url.indexOf('page/') !== -1) {
                url = 'page';
            }

            try {
                var slit = url.split('/');

                if (slit.length > 1) {
                    if(slit.length >= 2 && slit[0] === 'blog') {
                        url = slit[1]
                    } else {
                        url = slit[0]
                    }
                }

            } catch (e) {
                console.warn(e);
            }

            if (url && typeof pagesConfig !== 'undefined')
                actualPage = pagesConfig.find((page) => page !== null && page.url.toLowerCase() === url.toLowerCase());

        }

        if (!actualPage && typeof pagesConfig !== 'undefined') {
            actualPage = pagesConfig.find((page) => page !== null && (page.initial === '1'));

            if (!actualPage)
                actualPage = pagesConfig.find((page, index) => page !== null && (page.initial === '0' && index === 0));
        }

        return actualPage;
    }

}

export default CurrentPage;