<template>
   <div class="flip-clock" :style="{'width': width}">
      <tracker-component
      v-for="(tracker, index) in trackers" :key="index"
      :property="tracker"
      :time="flipclock.values"
      :height="config.height"
      :width="config.width"
      :appearance="config.appearance.panels"
      :device="device"
      ref="trackers">
      </tracker-component>
    </div>
</template>

<script>

import TrackerComponent from './Tracker';
import XDate from 'xdate';
import Countdown from 'countdown';

export default {
  name: 'FlipClockComponent',
  components: {
    TrackerComponent
  },
  props: {
    preview: false, config: {}, device: false
  },
  data() {
    return {
      width: '',
      height: '',
      settings: { active: 'false', counter: '', link: ''},
      trackers: ['days', 'hours', 'minutes', 'seconds'],
      flipclock: {
        values: {
          days: 1,
          hours: 2,
          minutes: 3,
          seconds: 4,
          finish: '',
          date: ''
        },

        settings: {
          active: 'false', counter: '', link: ''
        }
      }
    };
  },

  watch: {
    'config.settings': {
      handler(val) {
        this.$emit('updateData');
        this.clear();
        this.getDate();
      },
      deep: true
    },
    'device': {
      handler() {
        this.width = (this.device === 'desktop' && this.config.appearance.panels.width) ? `${this.config.appearance.panels.width}px` : '';
      },
      deep: true
    }
  },

  methods: {

    setValue(index, value) {
      this.flipclock.values[index] = value;
    },

    getDate() {
      this.flipclock.values.finish = XDate(this.config.settings.finish);
      this.flipclock.values.date = XDate(this.config.settings.date);

      var now = XDate();
      var finish = XDate(this.flipclock.values.date);
      var totalSeconds = now.diffSeconds(finish);

      this.flipclock.values.days = Math.floor(totalSeconds / 86400);
      totalSeconds %= 86400;
      this.flipclock.values.hours = Math.floor(totalSeconds / 3600);
      totalSeconds %= 3600;
      this.flipclock.values.minutes = Math.floor(totalSeconds / 60);
      this.flipclock.values.seconds = Math.floor(totalSeconds % 60);

      this.interval();
      this.width = (this.device === 'desktop') ? `${this.config.appearance.panels.width}px` : false;
    },

    interval(link, settings) {
      var vm = this;
      var settings = {...this.flipclock.settings, ...this.flipclock.values};

      this.flipclock.settings.active = true;

      if (XDate(settings.finish, true) > XDate(true)) {
        this.flipclock.settings.active = true;

        if (XDate(settings.finish, true) > XDate(true) && vm.flipclock.settings.active) {
          var diffSecondsTotal = XDate(settings.finish, true).diffSeconds(XDate(true));
          settings.finish = XDate().addSeconds(diffSecondsTotal * (-1));

          vm.setValue('days', Countdown(settings.finish, null, Countdown.DAYS | Countdown.HOURS | Countdown.MINUTES | Countdown.SECONDS).days);
          vm.setValue('hours', Countdown(settings.finish, null, Countdown.DAYS | Countdown.HOURS | Countdown.MINUTES | Countdown.SECONDS).hours);
          vm.setValue('minutes', Countdown(settings.finish, null, Countdown.DAYS | Countdown.HOURS | Countdown.MINUTES | Countdown.SECONDS).minutes);
          vm.setValue('seconds', Countdown(settings.finish, null, Countdown.DAYS | Countdown.HOURS | Countdown.MINUTES | Countdown.SECONDS).seconds);
          vm.verify(XDate(settings.finish, true));
        } else {
          vm.execute();
          vm.setValue('days', 0);
          vm.setValue('hours', 0);
          vm.setValue('minutes', 0);
          vm.setValue('seconds', 0);
        }

        this.flipclock.settings.counter = window.setInterval(function() {
          if (XDate(settings.finish, true) > XDate(true) && vm.flipclock.settings.active) {
            var diffSecondsTotal = XDate(settings.finish, true).diffSeconds(XDate(true));
            settings.finish = XDate(true).addSeconds(diffSecondsTotal * (-1));

            vm.setValue('days', Countdown(settings.finish, null, Countdown.DAYS | Countdown.HOURS | Countdown.MINUTES | Countdown.SECONDS).days);
            vm.setValue('hours', Countdown(settings.finish, null, Countdown.DAYS | Countdown.HOURS | Countdown.MINUTES | Countdown.SECONDS).hours);
            vm.setValue('minutes', Countdown(settings.finish, null, Countdown.DAYS | Countdown.HOURS | Countdown.MINUTES | Countdown.SECONDS).minutes);
            vm.setValue('seconds', Countdown(settings.finish, null, Countdown.DAYS | Countdown.HOURS | Countdown.MINUTES | Countdown.SECONDS).seconds);
            vm.verify(XDate(settings.finish, true));
          } else {
            vm.execute();
            vm.setValue('days', 0);
            vm.setValue('hours', 0);
            vm.setValue('minutes', 0);
            vm.setValue('seconds', 0);
          }
        }, 1000);
      } else {
        vm.execute();
        vm.setValue('days', 0);
        vm.setValue('hours', 0);
        vm.setValue('minutes', 0);
        vm.setValue('seconds', 0);
      }
    },

    clear() {
      clearInterval(this.flipclock.settings.counter);
    },

    verify(date) {
      if (date <= XDate(true) && this.flipclock.settings.active) {
        clearInterval(this.flipclock.settings.counter);
      }
    },

    execute() {
      this.clear();

      if (this.flipclock.settings.active) {
        this.flipclock.settings.active = false;


        if (document.querySelectorAll('.panel-components-right').length === 0) {
          this.config.link.target = 'self';
          this.$root.$emit('linkAction', this.config.link);
        }
      }
    }
  },

  mounted() {
  }
};
</script>

<style lang="scss">
    @import './_flipclock.scss';
</style>
