<template>
  <div class="time-circle-container">
    <div ref='timecircles'></div>
  </div>
</template>

<script>
import './lib/TimeCircles';
import XDate from 'xdate';
import {isTablet} from 'mobile-device-detect';

export default {
    name: 'TimeCirclesComponent',
    props: {
      device: '',
      preview: false,
      config: {},
      options: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        timeCircles: null,
        appearance: {},
        callLink: false,
        date: '',
        width: 0,
        height: 0
      };
    },
    watch: {
      config: {
        handler(val) {

          var isEditor = document.querySelectorAll('.panel-components-right').length > 0;

          if(isEditor){
            this.$emit('updateData');
          }

          this.init();
          this.style();
        },
        deep: true,
      },
      'config.width': function(value) {
        this.width = value;
      },
      'config.height': function(value) {
        this.height = value;
      },
      device: function() {
        this.style();
      }
    },
    methods: {
      getDate() {
        if (this.config.type === 'decrease_date_end') {
          return XDate(XDate(this.config.settings.date_end))
                  .addHours(this.config.settings.original_hours_finish)
                  .addMinutes(this.config.settings.original_minutes_finish)
                  .toString();

        } else {
          var time = XDate()
                  .addDays(this.config.settings.days_to_sum)
                  .addHours(this.config.settings.hours_to_sum)
                  .addMinutes(this.config.settings.minutes_to_sum)
                  .addSeconds(this.config.settings.seconds_to_sum);
          return time.toString();
        }
      },
      treatment() {
        return {
          Days: { show: true, color: this.appearance.backgroundColorEffect, text: this.$t('ct-days') },
          Hours: { show: true, color: this.appearance.backgroundColorEffect, text: this.$t('ct-hours') },
          Minutes: { show: true, color: this.appearance.backgroundColorEffect, text: this.$t('ct-minutes') },
          Seconds: { show: true, color: this.appearance.backgroundColorEffect, text: this.$t('ct-seconds') }
        };
      },
      init() {
        let vm = this;
        let device = this.$parent.device;

        $(this.$refs.timecircles).find('div').remove();

        var timecircles = document.createElement('div');

        this.timeCircles = timecircles;

        if (isTablet || device === 'mobile') {
          timecircles.style.maxHeight = this.height;
          timecircles.style.maxWidth = this.width;
        } else {
          timecircles.style.height = this.height;
          timecircles.style.width = this.width;
        }

        this.$refs.timecircles.appendChild(timecircles);

        timecircles.innerHTML = '';

        timecircles.removeAttribute('data-date');
        timecircles.removeAttribute('data-tc-id');

        timecircles.setAttribute('data-date',  this.config.settings.date);

        $(timecircles).TimeCircles({ count_past_zero: false, time: this.treatment(), circle_bg_color: this.appearance.backgroundColor });

        $(timecircles).TimeCircles().addListener(countdownComplete);

        $(timecircles).find('h4, span').css('color', this.appearance.fontColor);
        $(timecircles).find('h4, span').addClass('not-editor-content');

        if ($(timecircles).TimeCircles().getTime() < 0) {
          countdownComplete(0, 0, 0);
        }

        function countdownComplete(unit, value, total) {
          var isEditor = document.querySelectorAll('.panel-components-right').length > 0;
          if (total <= 0 && !isEditor) {
            if(!vm.callLink) {
              vm.callLink = true;
              vm.config.link.target = 'self';
              vm.$root.$emit('linkAction', vm.config.link);
            }
          }
        }
      },

      style() {
        let vm = this;
        let device = this.$parent.device;

        if (device === 'mobile' || isTablet) {
          if (window.innerWidth >= 700 || isTablet) {
            vm.width = '310px';
            vm.height = 'auto';
            if (typeof vm.config.maxWidth !== 'undefined') {
              vm.width = vm.config.maxWidth;
            }
          } else {
            vm.width = '100%';
            vm.height = 'auto';
          }
        } else {
          vm.width = vm.config.width;
          vm.height = vm.config.height;
        }

        vm.init();
      }
    },
    created() {
      this.$nextTick(() => {
        var isEditor = document.querySelectorAll('.panel-components-right').length > 0;

        if(isEditor){
          this.$emit('updateData');
        }

        this.init();
        this.style();
      });
    },

    mounted() {
      var isEditor = document.querySelectorAll('.panel-components-right').length > 0;

      if(isEditor)
        this.$emit('setCallbackChangeDevice', this.style );

      this.date = this.getDate();
      this.appearance = this.config.appearance.circles;
      this.style();

      if (this.$props.preview) {
        $(this.$refs.timecircles).TimeCircles().start();
      }

      // Force Update data if header com transição
      var $headerSecondary = $('[data-header-modify-on-scroll="on-scroll"]');

      if ($headerSecondary.length) {
        try {

          var checkIsVisible = setInterval(checkHeaderSecondaryIsVisible, 500);
          var vm = this;

          function checkHeaderSecondaryIsVisible() {
            if ($headerSecondary.is(':visible')) {
              vm.init();
              vm.style();
              stopCheckIsVisible();
            }
          }

          function stopCheckIsVisible() {
            clearInterval(checkIsVisible);
          }

        } catch (e) {
          console.error(e);
        }
      }
    },

    beforeMount() {
      setTimeout(() => {
        this.init();
      }, 600);
    }


  };
</script>

<style lang="scss">
  @import './lib/TimeCircles.css';

  .time-circle-container {
    margin: 0 auto;
  }
  @media (max-width: 980px) {
    [element=TimerComponent].sitebuilder-element-container {
      padding-top: 5px !important;
      padding-bottom: 5px !important;
    }
  }

  [data-device="mobile"] #editor-content [element=TimerComponent].sitebuilder-element-container{
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  @media (max-width: 320px) {
    .time-circle-container {
      .time-circle {
        h4 {
          font-size: 5px !important;
          line-height: 5px !important;
        }

        span {
          font-size: 16px !important;
          line-height: 5px !important;
        }
      }
    }
  }
</style>
