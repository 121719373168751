export default {
  data() {
    return {
      sidebar: '2',
      keyChange: 0,
      props: {
        'tag': '',
        'width': '300px',
        'zIndex': 'auto', 'maxWidth': '500px',
        'height': '300px',

        'minHeight': '50px',
        'border': '0 none #000000',
        'boxShadow': '0 0 0 0 black initial',
        'background-color': '#000000',
        'backgroundImage': 'none',/*url("photographer.jpg"); The image used */
        'backgroundPosition': 'center', /* left top | left center | left bottom | right top | right center | right bottom | center top | center center | center bottom */
        'backgroundOrigin': 'border-box', /* padding-box|border-box|content-box Do not repeat the image */
        'backgroundRepeat': 'no-repeat', /*repeat | repeat-x | repeat-y | no-repeat | space | round   Do not repeat the image */
        'backgroundSize': 'cover', /* 'auto | 50% | 100% 100% | cover | contain  'Resize the background image to cover the entire container */
        'backgroundAttachment': 'scroll', /* scroll | fixed 'The background image will scroll or not scroll with the page' */
        'borderRadius': '0 0 0 0',
        'paddingTop': '15px',
        'paddingLeft': '15px',
        'paddingRight': '15px',
        'paddingBottom': '15px',
        'parallaxActive' : false,
        'parallaxEffect' : 'scroll',
        'parallaxSpeed' : 20,

        showDesktop: true,
        showMobile: true,

        'animation': {
          'in': {
            'action': 'time',
            'time': 0,
            'scroll': 0,
            'effect': 'none',
            'speed': 'slow',
            'infinite': false,
          },
          'out': {
            'action': 'time',
            'time': 0,
            'scroll': 0,
            'effect': 'none',
            'delay': '1',
            'speed': '1',
            'infinite': false,
          },
        },
      },
      elements: [],
      internal_config: {
        width: '300px',
        height: '300px',
        'minHeight': '50px',
        'zIndex': 'auto', 'maxWidth': '500px',
        background: '#FFFFFF',
        backgroundColor: '#FFFFFF',
        borderRadius: '0 0 0 0',
        'parallaxActive' : false,
        'parallaxEffect' : 'scroll',
        'parallaxSpeed' : 20,
        'paddingTop': '15px',
        'paddingLeft': '15px',
        'paddingRight': '15px',
        'paddingBottom': '15px',

        url: '',
        speed: 1,
        autoplay: false,
        loop: false,
        controls: false,
        code: '',
      }
    }
  }
}
