var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sitebuilder-element-container card ",style:(_vm.style()),attrs:{"id":this.id,"element":"AccordionComponent","data-hide-desktop":!this.internal_config.showDesktop,"data-hide-mobile":!this.internal_config.showMobile}},[_c('div',{staticClass:"card-header",style:({
      borderRadius: _vm.radius,
       background: _vm.backgroundColor,
      backgroundImage: _vm.backgroundImage,
      backgroundSize: _vm.backgroundSize,
      backgroundPosition: _vm.backgroundPosition,
      backgroundOrigin: _vm.backgroundOrigin,
      backgroundRepeat: _vm.backgroundRepeat,
       border : _vm.border + ' !important',
      height : _vm.height
      }),attrs:{"id":'heading'+this.id},on:{"click":function($event){return _vm.collapseThis($event)}}},[_c('h5',{staticClass:"mb-0 clean-text-css h-100"},[_c('button',{staticClass:"btn btn-link w-100 h-100 icon-place desktop-element",style:(_vm.textStyle()),attrs:{"data-toggle":"collapse","data-target":'#collapse'+this.id,"aria-expanded":"false","aria-controls":'collapse'+this.id}},[_vm._v("\n        "+_vm._s(_vm.internal_config.text)+"\n      ")]),_c('button',{staticClass:"btn btn-link w-100 h-100 icon-place  mobile-element",style:(_vm.textStyleMobile()),attrs:{"data-toggle":"collapse","data-target":'#collapse'+this.id,"aria-expanded":"false","aria-controls":'collapse'+this.id}},[_vm._v("\n        "+_vm._s(_vm.internal_config.text)+"\n      ")])])]),_c('div',{staticClass:"collapse",style:({border : _vm.borderContainer}),attrs:{"id":'collapse'+this.id,"aria-labelledby":'heading'+this.id}},[_c('div',{staticClass:"sitebuilder-element-receiver big-is-receiver card-body",style:({
              borderRadius: _vm.radiusContainer,
              background: _vm.backgroundContainer,
              height : _vm.heightContainer
              })},_vm._l((_vm.elements),function(element,index){return _c(element.name,{key:index,tag:"component",attrs:{"config":element.config,"device":_vm.device,"is_popup":_vm.is_popup,"id":element.id,"height_start":index,"preview":_vm.preview,"childs":element.childs}})}),1)])])}
var staticRenderFns = []

export { render, staticRenderFns }