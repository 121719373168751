<template>
    <div class="wrapper-login">
        <span v-text="$t('ct-comment-theme-title')" />
        <div>
          <a
            :href="`/blog-login?post=${url}`" 
            v-text="$t('ct-sign-up')"
          />
          <a
            :href="`/blog-login?post=${url}`" 
            v-text="$t('ct-sign-in')"
          />
      </div>
    </div>
</template>

<script>
export default {
    name: 'SimpleTheme',
    props: ['url']
}
</script>

<style lang="scss" scoped>
.wrapper-login {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    max-width: 500px;

    background-image: linear-gradient(#b897e5, #6c58c7);
    padding: 40px;
    border-radius: 5px;

    span {
      font-family: 'Lato', sans-serif;
      font-size: 20px;
      font-weight: 900;
      color: #FFFFFF;

      margin-bottom: 20px;
    }

    div {
      width: 100%;
      display: flex;
      justify-content: space-around;
      flex-direction: column;

      a {
            font-family: 'Lato', sans-serif;
            color: #FFFFFF;

            padding: 10px 16px;
            border-radius: 5px;

            text-decoration: none;
            transition: 0.3s;

            &:nth-of-type(1) {
                background: #C2A5EB;

                &:hover {
                    background:  #6c58c7;
                }
            }

            &:nth-of-type(2) {
                background: #5844ad;

                margin-top: 20px;

                &:hover {
                    background: #b897e5;
                }
            }
        }
    }
  }

@media (min-width: 600px) {
    .wrapper-login {
        div {
            flex-direction: row;

            a {
                &:nth-of-type(2) {
                    margin-top: 0px;
                }
            }
        }
    }
}
</style>
