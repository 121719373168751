<template>

    <div :id="id"
         class="sitebuilder-element-container"
         element="BlogSearchComponent"
         :data-hide-desktop="!this.internal_config.showDesktop"
         :data-hide-mobile="!this.internal_config.showMobile"
         :config-properties="JSON.stringify(internal_config)" :style="style()">

        <form @submit.prevent="submitSearch">
            <div
                ref="BlogSearchComponent" 
                class="input-group mb-3"
            >
                <input type="text" class="form-control"  v-model="searchTerm" :placeholder="placeholder()">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary button-search" :style="buttonStyle()" type="submit"> 
                        <i :style="iconStyle()" class="fas fa-search" />
                    </button>
                </div>
            </div>

        </form>

    </div>


</template>

<script>

    import elementData from './Model';




    export default {
        name: 'BlogSearchComponent',
        props: ['config', 'id'],
        components : {  },
        data() {
            return elementData.data();
        },
        methods: {

            placeholder() {
                return this.internal_config.showPlaceholder ? this.internal_config.lbltext : '';
            },
            submitSearch(){
                window.location.href = '/s?query=' +  this.searchTerm;
            },

            build(config) {
                this.internal_config = config;
            },
            animation() {
                return false;
            },


            titleStyle() {
                return `font-family:  ${this.internal_config.titleStyle.family};
                  font-size: ${this.internal_config.titleStyle.size};
                  text-align: ${this.internal_config.titleStyle.align};
                  font-weight: ${this.internal_config.titleStyle.weight};
                  font-style: ${this.internal_config.titleStyle.style};
                  text-decoration: ${this.internal_config.titleStyle.stroke};
                  color: ${this.internal_config.titleStyle.color}`;
            },
            iconStyle() {
                return `color: ${this.internal_config.titleStyle.color};`;
            },
            buttonStyle() {
                if( !!this.$refs.BlogSearchComponent ) {
                    const [ button ] = this.$refs.BlogSearchComponent.getElementsByClassName('button-search');
                    button.style.setProperty('--color-hover', this.internal_config.backgroundColorHover);
                }

                return `font-family:  ${this.internal_config.contentStyle.family};
                  background-color: ${this.internal_config.backgroundColor};
                  font-size: ${this.internal_config.contentStyle.size};
                  text-align: ${this.internal_config.contentStyle.align};
                  font-weight: ${this.internal_config.contentStyle.weight};
                  font-style: ${this.internal_config.contentStyle.style};
                  text-decoration: ${this.internal_config.contentStyle.stroke};
                  color: ${this.internal_config.contentStyle.color};
                  min-height: auto;
                  border-top-left-radius: 0;
                  border-bottom-left-radius: 0;
                  padding: 4px 30px;
                  border-radius: 5px;
                  border: 0`;
    },


            //style methods

            style(){
                let {
                    left,
                    zIndex,
                    paddingTop,
                    paddingLeft,
                    paddingRight,
                    paddingBottom,
                    top
                } = this.internal_config;

                let style = `
                     left:  ${left};
                     z-index: ${zIndex};
                     width: ${this.internal_config.width};
                     margin: 0 auto;
                     padding-top: ${paddingTop};
                     padding-left: ${paddingLeft};
                     padding-right: ${paddingRight};
                     padding-bottom: ${paddingBottom};
					 top: ${top}`;

                return style;
            },
        },
        created() {
            let device, childs, id, config;
            ({ device, childs, id, config } = this.$props);
            this.internal_config = config;
            this.device = device;
            if (typeof id !== "undefined") {
                this.id = id;
            }
            this.internal_config = window.mergeRecursive(
                this.props,
                this.$props.config
            );
        },
        mounted(){

            let vm = this;

            this.build(this.$props.config);

            this.internal_config = window.mergeRecursive(this.props, this.$props.config);


            //verifica se é busca de termo
            if(window.location.pathname === '/s' || window.location.pathname.indexOf('/s/') !== -1){
                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);
                let search = urlParams.get('query');
                this.searchTerm = search;
            }



            let objIds = [];
            $("[id]").each(function() {
                var ids = $('[id="' + this.id + '"]');
                if (ids.length > 1 && ids[0] == this && parseInt(this.id))
                    objIds.push(this.id);
            });
            if (objIds.includes(this.id)) {
                this.id = Date.now() + parseInt(Math.random() * 10000);
            }
        }
    }
</script>

<style lang="scss" scoped>  
.button-search:hover{
    background-color: var(--color-hover) !important;  
}
</style>
