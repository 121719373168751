<template>
  <div
      :id="this.idSimpleForm"
      :data-id="this.id"
      class="sitebuilder-element-container"
      element="MultiStepFormComponent"
      :data-hide-desktop="!this.internal_config.showDesktop"
      :data-hide-mobile="!this.internal_config.showMobile"
      :style="style()"
  >
    <div :style="`transform: rotate(${this.$props.device === 'desktop' ? this.internal_config.rotation : 0}) scale(1)`">
      <form
          v-if="checkGdpr()"
          ref="form"
          :class="horizontal ? 'form-simple horizontal-form': 'form-simple'"
          :target="internal_config.target"
          method="post"
          :action="internal_config.action"
          :receiver="internal_config.receiver"
          :subject="internal_config.subject"
          @submit.prevent="submit($event, internal_config)"
      >
        <input
            v-model="originUid"
            type="hidden"
            name="origin_uid"
        >
        <ul
            id="progressbar"
            ref="progressbar"
        />
        <div
            ref="container"
            class="inputs-area"
        />
        <div
            v-if="internal_config.recaptchaSiteKey"
            class="recaptcha-container"
        >
          <vue-recaptcha :sitekey="internal_config.recaptchaSiteKey"/>
        </div>
        <div
            class="place-btn"
            :style="{ margin: buttonMargin, display: 'flex', justifyContent: 'center', alignItems: 'center' }"
        >
          <div
              v-show="showPreviousButton"
              class="bt-sb bt-sb-previous mx-1"
              :style="{
              width : buttonPreviousWidth,
              'box-shadow': buttonPreviousBoxShadow,
              border : buttonPreviousBorder,
              'border-radius': buttonPreviousRadius,
              background: buttonPreviousBackground,
            }"
          >
            <div
                class="bt-text-area d-flex align-items-center flex-wrap overflow-hidden"
                :style="{minHeight: buttonPreviousHeight}"
            >
              <a
                  class="sb-text button-previous-sb-button"
                  :style="textPreviousStyle()"
                  @click="previous($event)"
                  ref="buttonPreviousClick"
              >{{ internal_config.buttonPrevious.text }}</a>
              <small
                  v-if="internal_config.buttonPrevious.subText"
                  class="sb-sub-text"
                  @click="$refs.buttonPreviousClick.click()"
                  :style="subTextPreviousStyle()"
              >{{ internal_config.buttonPrevious.subText }}</small>
            </div>
          </div>
          <div
              v-show="showSubmitButton"
              class="bt-sb bt-sb-submit mx-1"
              :style="{
              width : buttonWidth,
              'box-shadow': buttonBoxShadow,
              border : buttonBorder,
              'border-radius': buttonRadius,
              background: buttonBackground,
            }"
          >
            <div
                class="bt-text-area d-flex align-items-center flex-wrap overflow-hidden"
                :style="{minHeight: buttonHeight}"
            >
              <a
                  class="sb-text button-sb-button"
                  :style="textStyle()"
                  ref="buttonClick"
                  @click="submit($event, internal_config)"
              >{{ internal_config.button.text }}</a>
              <small
                  v-if="internal_config.button.subText"
                  class="sb-sub-text"
                  @click="$refs.buttonClick.click()"
                  :style="subTextStyle()"
              >{{ internal_config.button.subText }}</small>
            </div>
          </div>
          <div
              v-show="showNextButton"
              class="bt-sb bt-sb-next mx-1"
              :style="{
              width : buttonNextWidth,
              'box-shadow': buttonNextBoxShadow,
              border : buttonNextBorder,
              'border-radius': buttonNextRadius,
              background: buttonNextBackground,
            }"
          >
            <div
                class="bt-text-area d-flex align-items-center flex-wrap overflow-hidden"
                :style="{minHeight: buttonNextHeight}"
            >
              <a
                  class="sb-text button-next-sb-button"
                  :style="textNextStyle()"
                  @click="next($event)"
                  ref="buttonNextClick"
              >{{ internal_config.buttonNext.text }}</a>
              <small
                  v-if="internal_config.buttonNext.subText"
                  class="sb-sub-text"
                  @click="$refs.buttonNextClick.click()"
                  :style="subTextNextStyle()"
              >{{ internal_config.buttonNext.subText }}</small>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import Data from './Model';
import VueRecaptcha from 'vue-recaptcha';

import CheckFields from '../Fields/CheckFields.vue';
import SelectFields from '../Fields/SelectFields.vue';
import TextFields from '../Fields/Text.vue';
import DateFields from '../Fields/DateFields.vue';
import MixinForm from "@/components/apps/Forms/MixinForm";

export default {
  name: 'MultiStepComponent',
  components: {
    VueRecaptcha,
  },
  mixins: [MixinForm],
  props: ['config', 'id', 'preview', 'device'],
  data() {
    return Data.data();
  },
  computed: {
    horizontal() {
      return this.internal_config.horizontal;
    },

    buttonWidth() {
      return parseInt(this.internal_config.button.width) / 2 + '%';
    },
    buttonHeight() {
      return parseInt(this.internal_config.button.height) + 'px';
    },
    buttonBorder() {
      return this.internal_config.button.border;
    },
    buttonBoxShadow() {
      return this.internal_config.button.boxShadow;
    },
    buttonRadius() {
      return this.internal_config.button.borderRadius;
    },
    buttonBackground() {
      let bg = this.internal_config.button.backgroundColor;

      if (this.internal_config.backgroundImage !== 'none' && typeof this.internal_config.backgroundImage !== 'undefined') {
        bg += ' url("' + this.internal_config.button.backgroundImage + '")';
        bg += ' ' + this.internal_config.button.backgroundPosition;
        bg += ' / ' + this.internal_config.button.backgroundSize;
        bg += ' ' + this.internal_config.button.backgroundRepeat;
        bg += ' ' + this.internal_config.button.backgroundOrigin;
      }

      return bg;
    },
    buttonMargin() {
      const horizontal = this.internal_config.horizontal
      const margin = this.internal_config.button.margin

      return horizontal ? `0px 0px 0px ${margin}px` : `${margin}px 0px 0px`
    },

    buttonNextWidth() {
      return parseInt(this.internal_config.buttonNext.width) / 2 + '%';
    },
    buttonNextHeight() {
      return parseInt(this.internal_config.buttonNext.height) + 'px';
    },
    buttonNextBorder() {
      return this.internal_config.buttonNext.border;
    },
    buttonNextBoxShadow() {
      return this.internal_config.buttonNext.boxShadow;
    },
    buttonNextRadius() {
      return this.internal_config.buttonNext.borderRadius;
    },
    buttonNextBackground() {
      let bg = this.internal_config.buttonNext.backgroundColor;

      if (this.internal_config.backgroundImage !== 'none' && typeof this.internal_config.backgroundImage !== 'undefined') {
        bg += ' url("' + this.internal_config.buttonNext.backgroundImage + '")';
        bg += ' ' + this.internal_config.buttonNext.backgroundPosition;
        bg += ' / ' + this.internal_config.buttonNext.backgroundSize;
        bg += ' ' + this.internal_config.buttonNext.backgroundRepeat;
        bg += ' ' + this.internal_config.buttonNext.backgroundOrigin;
      }

      return bg;
    },

    buttonPreviousWidth() {
      return parseInt(this.internal_config.buttonPrevious.width) / 2 + '%';
    },
    buttonPreviousHeight() {
      return parseInt(this.internal_config.buttonPrevious.height) + 'px';
    },
    buttonPreviousBorder() {
      return this.internal_config.buttonPrevious.border;
    },
    buttonPreviousBoxShadow() {
      return this.internal_config.buttonPrevious.boxShadow;
    },
    buttonPreviousRadius() {
      return this.internal_config.buttonPrevious.borderRadius;
    },
    buttonPreviousBackground() {
      let bg = this.internal_config.buttonPrevious.backgroundColor;

      if (this.internal_config.backgroundImage !== 'none' && typeof this.internal_config.backgroundImage !== 'undefined') {
        bg += ' url("' + this.internal_config.buttonPrevious.backgroundImage + '")';
        bg += ' ' + this.internal_config.buttonPrevious.backgroundPosition;
        bg += ' / ' + this.internal_config.buttonPrevious.backgroundSize;
        bg += ' ' + this.internal_config.buttonPrevious.backgroundRepeat;
        bg += ' ' + this.internal_config.buttonPrevious.backgroundOrigin;
      }

      return bg;
    },

    showSubmitButton() {
      if (typeof this.internal_config.currentIndex !== undefined &&
          this.internal_config.groups &&
          this.internal_config.currentIndex === (this.internal_config.groups.length - 1)
      )
        return true;
    },
    showPreviousButton() {

      if (this.internal_config.form.showPreviousButton === false) {
        return false
      }

      if (typeof this.internal_config.currentIndex !== undefined &&
          this.internal_config.groups &&
          this.internal_config.currentIndex >= 1
      )
        return true;
    },
    showNextButton() {
      if (typeof this.internal_config.currentIndex !== undefined &&
          this.internal_config.groups &&
          this.internal_config.currentIndex < (this.internal_config.groups.length - 1)
      ) {
        return true;
      }
    }
  },

  created() {
    if (typeof this.config.horizontal_config_mobile === 'undefined' && typeof this.config.horizontal_config !== 'undefined') {
      this.$props.config.horizontal_config_mobile = Object.assign({}, this.config.horizontal_config);
    }

    if (typeof this.config.button.textStyle.sizeMobile === 'undefined' || this.config.button.textStyle.sizeMobile === '') {
      this.$props.config.button.textStyle.sizeMobile = this.config.button.textStyle.size;
    }

    if (typeof this.config.button.subTextStyle.sizeMobile === 'undefined' || this.config.button.subTextStyle.sizeMobile === '') {
      this.$props.config.button.subTextStyle.sizeMobile = this.config.button.subTextStyle.size;
    }
  },

  mounted() {


    let vm = this;

    this.build(this.$props.config);

    this.internal_config.button.subText = this.internal_config.button.subtext;

    this.$root.$emit('applyAnimation', this, 'sitebuilder-element-container');
    this.$el.classList.add('preview');

    this.$el.addEventListener('mouseover', function () {
      //submit
      document.body.style.setProperty(
          '--button-background-color--hover',
          vm.internal_config.button.backgroundColorHover
      );
      document.body.style.setProperty(
          '--button-text-color--hover',
          vm.internal_config.button.textColorHover
      );
      //next
      document.body.style.setProperty(
          '--button-background-color-next--hover',
          vm.internal_config.buttonNext.backgroundColorHover
      );
      document.body.style.setProperty(
          '--button-text-color-next--hover',
          vm.internal_config.buttonNext.textColorHover
      );
      //previous
      document.body.style.setProperty(
          '--button-background-color-previous--hover',
          vm.internal_config.buttonPrevious.backgroundColorHover
      );
      document.body.style.setProperty(
          '--button-text-color-previous--hover',
          vm.internal_config.buttonPrevious.textColorHover
      );
    });

    /**
     * configuração para listas do tipo ranking
     */
    let originUrl = new URL(window.location.href)
    let param = originUrl.searchParams.get('origin_uid');
    if (param !== null) {
      this.originUid = param
    }

    this.buildProgressBar(this);
    this.buildFields(this);
    this.hideGroups()

    this.inputStyle();
    this.placeholderStyle()

    this.setColumns()

    this.testAlan(this.internal_config.horizontal_config, this.internal_config.horizontal_config_mobile)

  },

  methods: {

    registerOnSocialProof() {

      try {

        let campaignId = parseInt(this.internal_config.socialproof_campaign_id);
        let userNameTAG = this.internal_config.socialproof_user_name_tag;

        let inputUserName = this.$refs.form.querySelector(`[name="${userNameTAG}"]`);

        if (campaignId && userNameTAG && inputUserName) {
          var formData = new FormData();
          formData.set('user_name', inputUserName.value);

          axios({
            'method': 'post',
            'url': '/cheetah/social-proof/register/' + campaignId,
            data: formData,
          }).then((res) => {
          });
        }
      } catch (e) {
        console.error(e);
      }
    },

    verifyRequired() {
      if (this.internal_config.newField.required == 'no') {
        this.$refs.visibilyField.style.display = 'block';
        this.$refs.visibilyFieldOption.style.display = 'block';
      } else {
        this.$refs.visibilyField.style.display = 'none';
        this.$refs.visibilyFieldOption.style.display = 'none';
      }
    },

    getValidUrl(url = "") {
      let newUrl = url.trim().replace(/\s/g, "");
      newUrl = window.decodeURIComponent(newUrl);

      if (/^(:\/\/)/.test(newUrl)) {
        return `http${newUrl}`;
      }
      if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
        return `http://${newUrl}`;
      }

      return newUrl;
    },

    getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return false;
    },

    checkGdpr() {

      try {
        if (window.gdprSettings && window.gdprSettings.active) {
          if (this.internal_config.recaptchaSiteKey) {
            return this.getCookie('cookieControl')
          }
        }
      } catch (e) {
        console.log(e)
      }


      return true;
    },

    submit(e, config) {

      e.preventDefault()


      var kvpairs = [];
      let form = document.getElementById(this.idSimpleForm).querySelector('form');
      var inputs = form.querySelectorAll('.form-group, .input-group');
      let valid = true;
      let message = [];
      const fieldsGroup = config.groups.map(group => group.fields)
      const fields = []

      fieldsGroup.forEach(fieldGroup => {
        fields.push(...fieldGroup)
      })

      for (var i = 0; i < inputs.length; i++) {
        var el = inputs[i].querySelector('input, select, textarea');
        var checkFieldsRequired = inputs[i].hasAttribute('data-check-required');

        if (!checkFieldsRequired) {
          fields.forEach(field => {

            try {

              if (el.name.toLowerCase().indexOf('email') !== -1 && el.value !== '') {
                localStorage.setItem('leadEmail', el.value);
              }

              if (el.name.toLowerCase() === 'fname' && el.value !== '') {
                localStorage.setItem('leadName', el.value);
              }

              if (el.name.toLowerCase() === 'name' && el.value !== '') {
                localStorage.setItem('leadName', el.value);
              }

              if (el.name.toLowerCase() === 'fnome' && el.value !== '') {
                localStorage.setItem('leadName', el.value);
              }

              if (el.name.toLowerCase() === 'nome' && el.value !== '') {
                localStorage.setItem('leadName', el.value);
              }

              if (el.name.toLowerCase() === 'cadastro_nome' && el.value !== '') {
                localStorage.setItem('leadName', el.value);
              }

            } catch (error) {
              console.log(error)
            }


            if (el.name.toLowerCase().indexOf('email') !== -1 && el.value !== '') {
              localStorage.setItem('leadEmail', el.value);
            }
            if (field.tag === el.name && field.required === 'yes' && el.value === '') {
              valid = false;
              var isRequiredMessage = config.isRequiredMessage != '' ? config.isRequiredMessage : "is required";
              message.push(field.label + " " + isRequiredMessage + ".")
            }
          });

          kvpairs.push(encodeURIComponent(el.name) + "=" + encodeURIComponent(el.value));
        } else {

          let inputsCheck = inputs[i].querySelectorAll('[type=checkbox][data-check-field],[type=radio][data-check-field]');
          let checked = 0;
          let values = [];

          if (inputsCheck.length > 0) {

            inputsCheck.forEach(element => {
              if (element.checked) {
                checked++;
                values.push(element.value);
              }

            });

            let inputsCheckRequired = inputs[i].querySelectorAll('[type=checkbox][data-required=true],[type=radio][data-required=true]');

            if (inputsCheckRequired.length > 0 && checked === 0) {

              fields.forEach(field => {
                let name = field.field === 'checkbox' ? field.tag + '[]' : field.tag;

                if (name === el.name && field.required === 'yes' && checked === 0 && (field.field === 'radio' || field.field === 'checkbox')) {
                  valid = false;
                  var isRequiredMessage = config.isRequiredMessage !== '' ? config.isRequiredMessage : "is required";
                  message.push(field.label + " " + isRequiredMessage + ".")
                }
              });

            }

            if (values.length > 0) {
              values.forEach(value => {
                kvpairs.push(encodeURIComponent(el.name) + "=" + encodeURIComponent(value));
              });
            } else {
              kvpairs.push(encodeURIComponent(el.name) + "=");
            }
          }
        }

      }

      var queryString = kvpairs.join("&");


      if (valid) {

        let action = this.internal_config.action;

        if (action !== '') {

          if (typeof __baCRMTracking !== 'undefined') {
            __baCRMTracking.onSubmit(e)
          }

          if (localStorage.getItem('MAILING_BOSS_TRACKING') && localStorage.getItem('leadEmail') != null && typeof MailingBossTracking != 'undefined') {
            console.log('mboss lead register');
            var options = {
              leadEmail: localStorage.getItem('leadEmail'),
              reference: localStorage.getItem('MAILING_BOSS_REFERENCE')
            };
            MailingBossTracking.init(localStorage.getItem('MAILING_BOSS_TOKEN'), options);
          }

          let vm = this;

          if (action.indexOf('app.mailingboss.com') > 0) {
            if (typeof config.redirect !== 'undefined' && config.redirect !== '') {
              if (action.indexOf("?") !== -1) {
                this.internal_config.action = action + '&redir=' + vm.getValidUrl(config.redirect);
              } else {
                this.internal_config.action = action + '?redir=' + vm.getValidUrl(config.redirect);
              }
            }
            setTimeout(function () {
              vm.registerOnSocialProof();
              vm.$refs.form.submit();
            }, 2000);
          } else {
            let recaptchaResponse = (document.getElementById('g-recaptcha-response') || {}).value;
            if (this.internal_config.recaptchaSiteKey && (recaptchaResponse === '' || recaptchaResponse === null)) {
              valid = false;
              message.push("unverified captcha");
            }

            if (!valid) {
              this.$root.$emit('errorMessagePopup', message);
              return;
            }

            vm.registerOnSocialProof();
            if (vm.internal_config.action.indexOf('https://office.builderall.com/br/office/create') > -1) {
              vm.iconLoading = true;
              let body = document.getElementsByTagName('body')[0]
              body.classList.add('loading-office-checkout');
            }


            if (
                typeof config.redirect !== 'undefined' &&
                config.redirect !== ''
            ) {
              axios.post(vm.internal_config.action, queryString)
                  .then(res => {
                    window.location.href = vm.getValidUrl(config.redirect);
                  })

              setTimeout(function () {
                window.location.href = vm.getValidUrl(config.redirect);
              }, 2000);
            } else {
              this.$refs.form.submit();
            }


          }

        } else {

          if (config.receiver === '' || config.receiver === null) {
            valid = false;
            message.push("receiver not configured");
          }

          if (config.subject === '' || config.subject === null) {
            valid = false;
            message.push("subject not configured");
          }

          let recaptchaResponse = (document.getElementById('g-recaptcha-response') || {}).value;
          if (this.internal_config.recaptchaSiteKey && (recaptchaResponse === '' || recaptchaResponse === null)) {
            valid = false;
            message.push("unverified captcha");
          }

          if (valid) {

            if (typeof __baCRMTracking !== 'undefined') {
              __baCRMTracking.onSubmit(e)

            }

            if (this.internal_config.recaptchaSiteKey) {
              queryString = queryString + '&g-recaptcha-response=' + recaptchaResponse + '&id=' + this.id;
            }

            let vm = this;

            queryString = queryString + '&receiver=' + config.receiver + '&subject=' + config.subject;
            axios.post('/cheetah/form_submit', queryString)
                .then(res => {
                  vm.registerOnSocialProof();

                  vm.$root.$emit('successMessagePopup', config.successMessage);
                  if (config.redirect !== '') {
                    window.location.href = this.getValidUrl(config.redirect);
                  }
                })
          }
        }


      }
      if (!valid) {
        // this.errorMessage = message;
        // this.$refs.required_fields.open()
        e.preventDefault();
        this.$root.$emit('errorMessagePopup', message);
      }

      // this.$refs.form.submit()
    },
    next(e) {
      e.preventDefault();

      this.internal_config.currentIndex = this.internal_config.currentIndex + 1;
      this.hideGroups();
    },
    previous(e) {
      e.preventDefault();

      this.internal_config.currentIndex = this.internal_config.currentIndex - 1;
      this.hideGroups();
    },
    buildProgressBar: function (vm) {

      this.$refs.progressbar.innerHTML = '';

      if (vm.internal_config.navBar.type === 'none')
        return;

      vm.internal_config.groups.forEach((group, indexG) => {
        const li = $('<li>')
            .css({
              color: vm.internal_config.navBar.titleTextColor,
              'font-size': vm.internal_config.navBar.titleSize,
              'width': (100 / (vm.internal_config.groups.length)) + '%'
            })
            .addClass('progress-item ' + (vm.internal_config.currentIndex === indexG ? 'active' : ''))
            .text(vm.internal_config.navBar.showTitle ? group.name : '')
            .appendTo(
                $(this.$refs.progressbar)
                    .get(0)
            )


        if (vm.internal_config.navBar.type === 'icon') {
          document.body.style.setProperty('--progressbar-li-before-content', 'no-close-quote');
          $('<i>')
              .addClass('mdi ' + group.icon)
              .appendTo(li)
        } else {
          document.body.style.setProperty('--progressbar-li-before-content', 'counter(step)');
        }
      })

      document.body.style.setProperty('--progressbar-li-before-font-size', this.internal_config.navBar.size);
      document.body.style.setProperty('--progressbar-li-before-color', this.internal_config.navBar.activeTextColor);
      document.body.style.setProperty('--progressbar-li-before-background', this.internal_config.navBar.backgroundColorActive);
      document.body.style.setProperty('--progressbar-li-before-radius', this.internal_config.navBar.borderRadius);

      document.body.style.setProperty('--progressbar-li-after-background', this.internal_config.navBar.backgroundColorInactive);
      document.body.style.setProperty('--progressbar-li-after-color', this.internal_config.navBar.inactiveTextColor);
    },
    buildFields: function (vm) {
      this.$refs.container.innerHTML = '';

      vm.internal_config.groups.forEach((group, indexG) => {

        const groupRef = vm.createGroupPlace(indexG);

        if (vm.internal_config.form.showTitle) {
          vm.createTitleMultiStep(group.name, groupRef);
        }

        if (vm.internal_config.form.showDescription) {
          vm.createDescriptionMultiStep(group.description, groupRef);
        }

        group.fields.forEach(field => {

          let identifier = field.field;
          if (typeof identifier === 'undefined') {
            identifier = 'text';
          }

          if (identifier === 'textarea') vm.createTextField(field, true, false, groupRef);
          if (identifier === 'text') vm.createTextField(field, false, false, groupRef);
          if (identifier === 'password') vm.createTextField(field, false, true, groupRef);
          if (identifier === 'select') vm.createDropDown(field, false, groupRef);
          if (identifier === 'multiselect') vm.createDropDown(field, true, groupRef);
          if (identifier === 'dropdown') vm.createDropDown(field, false, groupRef);
          if (identifier === 'datetime') vm.createDateTime(field, true, groupRef);
          if (identifier === 'date') vm.createDateTime(field, false, groupRef);
          if (identifier === 'checkbox') vm.createCheckField(field, 'checkbox', groupRef);
          if (identifier === 'radio') vm.createCheckField(field, 'radio', groupRef);
        })
      });

      this.inputStyle();
      this.$loading.hide();
    },

    textStyle() {
      let sizeMobile = typeof this.internal_config.button.textStyle.sizeMobile !== 'undefined' && this.internal_config.button.textStyle.sizeMobile ?
          this.internal_config.button.textStyle.sizeMobile : false;
      return `font-family:  ${this.internal_config.button.textStyle.family};
                 font-size: ${(this.device === 'mobile') ? sizeMobile : this.internal_config.button.textStyle.size};
                 text-align: ${this.internal_config.button.textStyle.align};
                 font-weight: ${this.internal_config.button.textStyle.weight};
                 font-style: ${this.internal_config.button.textStyle.style};
                 color: ${this.internal_config.button.textStyle.color}`;
    },

    subTextStyle() {
      let sizeMobile = typeof this.internal_config.button.subTextStyle.sizeMobile !== 'undefined' && this.internal_config.button.subTextStyle.sizeMobile ?
          this.internal_config.button.subTextStyle.sizeMobile : false;
      return `font-family:  ${this.internal_config.button.subTextStyle.family};
                 font-size: ${(this.device === 'mobile') ? sizeMobile : this.internal_config.button.subTextStyle.size};
                 text-align: ${this.internal_config.button.subTextStyle.align};
                 font-weight: ${this.internal_config.button.subTextStyle.weight};
                 font-style: ${this.internal_config.button.subTextStyle.style};
                 color: ${this.internal_config.button.subTextStyle.color}`;
    },

    textNextStyle() {
      let sizeMobile = typeof this.internal_config.buttonNext.textStyle.sizeMobile !== 'undefined' && this.internal_config.buttonNext.textStyle.sizeMobile ?
          this.internal_config.buttonNext.textStyle.sizeMobile : false;
      return `font-family:  ${this.internal_config.buttonNext.textStyle.family};
                 font-size: ${(this.device === 'mobile') ? sizeMobile : this.internal_config.buttonNext.textStyle.size};
                 text-align: ${this.internal_config.buttonNext.textStyle.align};
                 font-weight: ${this.internal_config.buttonNext.textStyle.weight};
                 font-style: ${this.internal_config.buttonNext.textStyle.style};
                 color: ${this.internal_config.buttonNext.textStyle.color}`;
    },

    subTextNextStyle() {
      let sizeMobile = typeof this.internal_config.buttonNext.subTextStyle.sizeMobile !== 'undefined' && this.internal_config.buttonNext.subTextStyle.sizeMobile ?
          this.internal_config.buttonNext.subTextStyle.sizeMobile : false;
      return `font-family:  ${this.internal_config.buttonNext.subTextStyle.family};
                 font-size: ${(this.device === 'mobile') ? sizeMobile : this.internal_config.buttonNext.subTextStyle.size};
                 text-align: ${this.internal_config.buttonNext.subTextStyle.align};
                 font-weight: ${this.internal_config.buttonNext.subTextStyle.weight};
                 font-style: ${this.internal_config.buttonNext.subTextStyle.style};
                 color: ${this.internal_config.buttonNext.subTextStyle.color}`;
    },

    textPreviousStyle() {
      let sizeMobile = typeof this.internal_config.buttonPrevious.textStyle.sizeMobile !== 'undefined' && this.internal_config.buttonPrevious.textStyle.sizeMobile ?
          this.internal_config.buttonPrevious.textStyle.sizeMobile : false;
      return `font-family:  ${this.internal_config.buttonPrevious.textStyle.family};
                 font-size: ${(this.device === 'mobile') ? sizeMobile : this.internal_config.buttonPrevious.textStyle.size};
                 text-align: ${this.internal_config.buttonPrevious.textStyle.align};
                 font-weight: ${this.internal_config.buttonPrevious.textStyle.weight};
                 font-style: ${this.internal_config.buttonPrevious.textStyle.style};
                 color: ${this.internal_config.buttonPrevious.textStyle.color}`;
    },

    subTextPreviousStyle() {
      let sizeMobile = typeof this.internal_config.buttonPrevious.subTextStyle.sizeMobile !== 'undefined' && this.internal_config.buttonPrevious.subTextStyle.sizeMobile ?
          this.internal_config.buttonPrevious.subTextStyle.sizeMobile : false;
      return `font-family:  ${this.internal_config.buttonPrevious.subTextStyle.family};
                 font-size: ${(this.device === 'mobile') ? sizeMobile : this.internal_config.buttonPrevious.subTextStyle.size};
                 text-align: ${this.internal_config.buttonPrevious.subTextStyle.align};
                 font-weight: ${this.internal_config.buttonPrevious.subTextStyle.weight};
                 font-style: ${this.internal_config.buttonPrevious.subTextStyle.style};
                 color: ${this.internal_config.buttonPrevious.subTextStyle.color}`;
    },

    buildIconInsideButton: function (type, apply) {
      if (this.internal_config[type].icon !== '') {
        if (this.internal_config[type].iconPosition === 'left') {
          $('<i>')
              .addClass('mdi ' + this.internal_config[type].icon)
              .prependTo(
                  $(this.$el)
                      .find('.' + apply + '-sb-button')
                      .get(0)
              );
        }
        if (this.internal_config[type].iconPosition === 'right') {
          $('<i>')
              .addClass('mdi ' + this.internal_config[type].icon)
              .appendTo(
                  $(this.$el)
                      .find('.' + apply + '-sb-button')
                      .get(0)
              );
        }
      }
    },
    buildIcon: function () {
      $(this.$el)
          .find('i')
          .detach();

      this.buildIconInsideButton('button', 'button');
      this.buildIconInsideButton('buttonNext', 'button-next');
      this.buildIconInsideButton('buttonPrevious', 'button-previous');
    },
    build(config) {
      this.internal_config = config;

      this.internal_config.currentIndex = 0;

      this.buildIcon();
    },
    style() {
      return `left:  ${this.internal_config.left};
				        padding-top: ${this.internal_config.paddingTop};
                z-index: ${this.internal_config.zIndex};
                max-width: ${this.internal_config.maxWidth};
                margin: 0 auto;
                padding-left: ${this.internal_config.paddingLeft};
                padding-right: ${this.internal_config.paddingRight};
                padding-bottom: ${this.internal_config.paddingBottom};
                width: ${this.internal_config.width};
                top: ${this.internal_config.top}`;
    },
    createCheckField(field, type, placeAt) {
      let ComponentClass = Vue.extend(CheckFields);
      let instance = new ComponentClass({
        propsData: {
          type: type,
          showLabel: this.internal_config.showLabel,
          showPlaceholder: this.internal_config.showPlaceholder,
          label: field.label,
          options: field.options,
          selected: [],
          data: field
        }
      });
      instance.$mount();
      $(placeAt).append(instance.$el)
    },
    createDropDown(field, multiple, placeAt) {
      let ComponentClass = Vue.extend(SelectFields);
      let instance = new ComponentClass({
        propsData: {
          label: field.label,
          options: field.options,
          selected: field.default_value,
          showLabel: this.internal_config.showLabel,
          showPlaceholder: this.internal_config.showPlaceholder,
          data: field,
          multiple: multiple
        }
      });
      instance.$mount();
      $(placeAt).append(instance.$el)
    },
    createDateTime(field, useCurrent, placeAt) {
      let ComponentClass = Vue.extend(DateFields);
      let instance = new ComponentClass({
        propsData: {
          label: field.label,
          data: field,
          showLabel: this.internal_config.showLabel,
          showPlaceholder: this.internal_config.showPlaceholder,
          value: new Date().toString(),
          type: useCurrent ? 'datetime' : 'date',
          icon: field.icon,
        }
      });
      instance.$mount();
      $(placeAt).append(instance.$el)
    },
    createTextField(field, area, pass, placeAt) {
      let ComponentClass = Vue.extend(TextFields);
      let instance = new ComponentClass({
        propsData: {
          label: field.label,
          value: field.default_value,
          pass: pass,
          color: this.internal_config.inputStyle.color,
          showLabel: this.internal_config.showLabel,
          showPlaceholder: this.internal_config.showPlaceholder,
          icon: field.icon,
          data: field,
          area: area
        }
      });
      instance.$mount();
      $(placeAt).append(instance.$el)
    },
    createGroupPlace(groupIndex) {
      return $('<div>')
          .addClass('step-group multi-group-' + groupIndex)
          .appendTo(
              $(this.$refs.container)
                  .get(0)
          );
    },
    createTitleMultiStep(name, groupPlace) {
      let sizeMobile = typeof this.internal_config.button.subTextStyle.sizeMobile !== 'undefined' && this.internal_config.titleFormStyle.sizeMobile ?
          this.internal_config.titleFormStyle.sizeMobile : false;
      $('<div>')
          .text(name)
          .css({
            'margin-bottom': '5px',
            'font-family': this.internal_config.titleFormStyle.family,
            'font-size': (this.device === 'mobile') ? sizeMobile : this.internal_config.titleFormStyle.size,
            'text-align': this.internal_config.titleFormStyle.align,
            'font-weight': this.internal_config.titleFormStyle.weight,
            'font-style': this.internal_config.titleFormStyle.style,
            'color': this.internal_config.titleFormStyle.color
          })
          .addClass('title-multi-step')
          .appendTo(
              groupPlace
          );

    },
    createDescriptionMultiStep(description, groupPlace) {
      let sizeMobile = typeof this.internal_config.descriptionFormStyle.sizeMobile !== 'undefined' && this.internal_config.descriptionFormStyle.sizeMobile ?
          this.internal_config.descriptionFormStyle.sizeMobile : false;
      $('<div>')
          .text(description)
          .css({
            'margin-bottom': '5px',
            'font-family': this.internal_config.descriptionFormStyle.family,
            'font-size': (this.device === 'mobile') ? sizeMobile : this.internal_config.descriptionFormStyle.size,
            'text-align': this.internal_config.descriptionFormStyle.align,
            'font-weight': this.internal_config.descriptionFormStyle.weight,
            'font-style': this.internal_config.descriptionFormStyle.style,
            'color': this.internal_config.descriptionFormStyle.color
          })
          .addClass('description-multi-step')
          .appendTo(
              groupPlace
          );
    },
    inputStyle() {
      let vm = this;

      let elements = this.$refs.container.getElementsByClassName(
          'form-control'
      );
      [].forEach.call(elements, function (el) {
        el.style.backgroundColor = vm.internal_config.inputBgColor;
        el.style.border = vm.internal_config.inputBorder;

        if (el.tagName === 'TEXTAREA' && vm.internal_config.textareaHeight) {
          el.style.height = vm.internal_config.textareaHeight;
          el.classList.add('form-control-textarea-has-height');
        } else {
          el.style.height = vm.internal_config.inputHeight;
        }
        el.style.borderRadius = vm.internal_config.inputRadius;

        el.style.fontFamily = vm.internal_config.inputStyle.family;
        el.style.fontSize = vm.internal_config.inputStyle.size;
        el.style.textAlign = vm.internal_config.inputStyle.align;
        el.style.fontStyle = vm.internal_config.inputStyle.style;
        el.style.fontStyle = vm.internal_config.inputStyle.style;
        el.style.color = vm.internal_config.inputStyle.color;
      });
      vm.$el.style.color = vm.internal_config.labelStyle;
    },

    hideGroups() {
      const vm = this;
      this.internal_config.groups.forEach((group, indexG) => {
        if (indexG !== vm.internal_config.currentIndex) {
          const elements = vm.$refs.container.getElementsByClassName(
              'multi-group-' + indexG
          );
          elements[0].style.display = 'none';
        }

        const progressItems = vm.$refs.progressbar.getElementsByClassName('progress-item');

        [].forEach.call(progressItems, function (el, i) {

          el.classList.remove('active');
        });


      })
      this.internal_config.groups.forEach((group, indexG) => {
        if (indexG === vm.internal_config.currentIndex) {
          const elements = vm.$refs.container.getElementsByClassName(
              'multi-group-' + indexG
          );
          elements[0].style.display = 'block';
        }

        const progressItems = vm.$refs.progressbar.getElementsByClassName('progress-item');

        [].forEach.call(progressItems, function (el, i) {
          if (i <= vm.internal_config.currentIndex)
            el.classList.add('active');
        })

      })
    },
    placeholderStyle() {
      const elements = this.$refs.container.getElementsByClassName(
          'form-control'
      );

      for (let element of elements) {
        element.style.setProperty('--placeholder-color', this.internal_config.placeholderColor || '#5c5c5c');
      }
    },
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.sitebuilder-element-container .vdr,
.vdr,
.bt-sb,
form.form-simple {
  height: auto !important;
}

.bt-sb.bt-sb-submit:hover {
  cursor: pointer;
  background-color: var(--button-background-color--hover) !important;

  * {
    color: var(--button-text-color--hover) !important;
  }
}

.bt-sb.bt-sb-next:hover {
  cursor: pointer;
  background-color: var(--button-background-color-next--hover) !important;

  * {
    color: var(--button-text-color-next--hover) !important;
  }
}

.bt-sb.bt-sb-previous:hover {
  cursor: pointer;
  background-color: var(--button-background-color-previous--hover) !important;

  * {
    color: var(--button-text-color-previous--hover) !important;
  }
}

.place-btn {
  text-align: center;

  .bt-sb {
    max-width: 49%;
    display: inline-block;
  }

  .sb-text,
  .sb-sub-text {
    width: 100%;
  }
}

.bt-text-area {
  display: block;
}

[data-device="tablet"] .bt-text-area,
[data-device="mobile"] .bt-text-area {
  width: auto !important;
}
</style>
<style lang="scss">

label {
  max-width: 100%;
  word-wrap: break-word
}

input.form-control, textarea.form-control {
  &::placeholder {
    color: var(--placeholder-color);
  }
}

.list-item {
  display: block;
  width: 100%;
  border: 1px solid lightgrey;
  border-radius: 5px;
  margin-top: 2px;
  cursor: pointer;
}

.list-item.selected {
  border: 1px solid blue;
}

/*progressbar*/
#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  /*CSS counters to number the steps*/
  counter-reset: step;
}

#progressbar li {
  list-style-type: none;
  text-transform: uppercase;
  float: left;
  position: relative;
  letter-spacing: 1px;
  text-align: center;

  .mdi {
    position: absolute;
    top: 0;
    left: calc(50% - (var(--progressbar-li-before-font-size) - var(--progressbar-li-before-font-size) / 4));
    font-size: calc(var(--progressbar-li-before-font-size) + (var(--progressbar-li-before-font-size) / 2));
    color: var(--progressbar-li-after-color);
  }
}

#progressbar li:before {
  content: var(--progressbar-li-before-content);
  counter-increment: step;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  margin: 0 auto 10px auto;
  font-size: var(--progressbar-li-before-font-size);
  color: var(--progressbar-li-after-color);
  background: var(--progressbar-li-after-background);
  border-radius: var(--progressbar-li-before-radius);
}

/*progressbar connectors*/
#progressbar li:after {
  content: '';
  width: 100%;
  height: 2px;
  background: var(--progressbar-li-after-background);
  color: var(--progressbar-li-after-color);
  position: absolute;
  left: -50%;
  top: 9px;
  z-index: -1; /*put it behind the numbers*/
}

#progressbar li:first-child:after {
  /*connector not needed before the first step*/
  content: none;
}

/*marking active/completed steps blue*/
/*The number of the step and the connector before it = blue*/
#progressbar li.active:before, #progressbar li.active:after {
  background: var(--progressbar-li-before-background);
  color: var(--progressbar-li-before-color);
}

#progressbar li.active .mdi {
  color: var(--progressbar-li-before-color);
}
</style>
