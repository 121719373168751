export default {
	data() {
		return {
			props: {
				'tag': '',
				'width': '300px',
				'height': '300px',
				'zIndex': 'auto', 'maxWidth': '768px',
				'border': '0 none #000000',
				'boxShadow': '0 0 0 0 black initial',
				'background-color': '#000000',
				'backgroundImage': 'none',/*url("photographer.jpg"); The image used */
				'backgroundPosition': 'center', /* left top | left center | left bottom | right top | right center | right bottom | center top | center center | center bottom */
				'backgroundOrigin': 'border-box', /* padding-box|border-box|content-box Do not repeat the image */
				'backgroundRepeat': 'no-repeat', /*repeat | repeat-x | repeat-y | no-repeat | space | round   Do not repeat the image */
				'backgroundSize': 'cover', /* 'auto | 50% | 100% 100% | cover | contain  'Resize the background image to cover the entire container */
				'backgroundAttachment': 'scroll', /* scroll | fixed 'The background image will scroll or not scroll with the page' */
				'borderRadius': '0 0 0 0',
				'paddingTop': '15px',
				'paddingLeft': '15px',
				'paddingRight': '15px',
				'paddingBottom': '15px',
				'parallaxActive': false,
				'parallaxEffect': 'scroll',
				'parallaxSpeed': 20,
				'animation': {
					'in': {
						'action': 'time',   //time/scroll/click/hover
						'time': 0,       // sec
						'scroll': 0,     // px
						'effect': 'none',
						'speed': 'slow',
						'infinite': false,
					},
					'out': {
						'action': 'time',   //time/scroll/click
						'time': 0,       // sec
						'scroll': 0,     // px
						'effect': 'none',
						'delay': '1',
						'speed': '1',
						'infinite': false,
					},
				
				},
				hotmart: {
					enabled: false,
					key: '',
					background: '#333333'
					},
				eduzz: {
					enabled: false,
					code: 1
					},
				/**
				 * pinConfig : configuração para fixação de elementos - Pin.vue
				 */
				'pinConfig': {
					'pin': false,
					'manual': false,
					'type': 'mid-mid',
					'pTop': '0',
					'pBottom': '0',
					'pLeft': '0',
					'pRight': '0',
					'position': '',
				},
			},
			elements: [],
			internal_config: {
				width: '300px',
				height: '300px',
				background: '#FFFFFF',
				backgroundColor: '#FFFFFF',
				borderRadius: '0 0 0 0',
				'paddingTop': '15px',
				'paddingLeft': '15px',
				'paddingRight': '15px',
				'paddingBottom': '15px',

				/**
				 * pinConfig : configuração para fixação de elementos - Pin.vue
				 */
				'pinConfig': {
					'pin': false,
					'manual': false,
					'type': 'mid-mid',
					'pTop': '0',
					'pBottom': '0',
					'pLeft': '0',
					'pRight': '0',
					'position': '',
				},

				// hotmart
				hotmart: {
					enabled: false,
					key: '',
					background: '#333333'
					}
			}
		}
	}
}