<template>
    <div
        class="sitebuilder-element-container"
        element="VidelloVideoComponent"
        :data-hide-desktop="!this.internal_config.showDesktop"
        :data-hide-mobile="!this.internal_config.showMobile"
        :id="id"
        :style="style()"
    >
        <div
            :style="`transform: rotate(${this.$props.device === 'desktop' ? this.internal_config.rotation : 0}) scale(1)`">
            <div id="the-sticky-div" class="the-sticky-div-content embed-responsive embed-responsive-16by9">
                <div class="sticky-video-content">
                    <div class="video">
                        <iframe
                            :width="internal_config.width"
                            :height="internal_config.height"
                            :src="internal_config.src"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                            class="embed-responsive-item"
                            v-bind:style="{'border-radius': radius,border : border}"
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Model from "./Model";
import mixinComponents from "@/js/mixins/MixinComponents";

export default {
    name: "VidelloVideoComponent",
    props: ["config", "height_start", "device", "id", "preview"],
    mixins: [mixinComponents],
    data() {
        return Model.data();
    },
    computed: {
        border() {
            return this.internal_config.border;
        },
    },

    methods: {
        style() {
            let style = `left:  ${this.internal_config.left};
                    width: ${this.internal_config.width};
                     z-index: ${this.internal_config.zIndex};max-width: ${this.internal_config.maxWidth};margin: 0 auto;
                     padding-top: ${this.internal_config.paddingTop};
                     padding-left: ${this.internal_config.paddingLeft};
                     padding-right: ${this.internal_config.paddingRight};
                     padding-bottom: ${this.internal_config.paddingBottom};
                     top: ${this.internal_config.top}`;
            return style;
        },
    },
    created() {
        let device, childs, id, config;
        ({device, childs, id, config} = this.$props);
        this.internal_config = config;
        this.device = device;
        if (typeof id !== "undefined") {
            this.id = id;
        }
        this.internal_config = window.mergeRecursive(
            this.props,
            this.$props.config
        );
    },
    mounted() {
        if (this.device == "desktop") {
            if (this.internal_config.StickyVideo) {
                this.$root.$emit("applySticky", this,);
            }
        }

        this.$root.$emit("applyAnimation", this, "sitebuilder-element-container");

        let objIds = [];
        $("[id]").each(function () {
            var ids = $('[id="' + this.id + '"]');
            if (ids.length > 1 && ids[0] == this && parseInt(this.id))
                objIds.push(this.id);
        });
        if (objIds.includes(this.id)) {
            this.id = Date.now() + parseInt(Math.random() * 10000);
        }

        this.$root.$on("setConfigElement", (id, obj) => {
            if (this.id == id) {
                this.config = obj;
                this.internal_config = obj;
            }
        });

        this.$forceUpdate();
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.vdr {
    height: auto !important;
}

iframe {
    overflow: hidden;
}
</style>
