export default {
  data() {
    return {
      props: {
        'tag': '',
        'height': '150px',
        'heightTop': '150px',
        'width': '550px',
        'zIndex': 'auto',
        'maxWidth': '500px',

        'paddingTop': '5px',
        'paddingLeft': '5px',
        'paddingRight': '5px',
        'paddingBottom': '5px',

        'amountOfNews' : '5',

        'textStyle': {
          'family': 'arial, helvetica, sans-serif',
          'size': '15px',
          'sizeMobile': '15px',
          'color': 'White',
          'align': 'center',
          'weight': '700',
          'style': 'initial',
          'stroke' : 'none'
        },

        'paddingTopContainer': '5px',
        'paddingLeftContainer': '5px',
        'paddingRightContainer': '5px',
        'paddingBottomContainer': '5px',

        'arrowSize' : '20px',
        'arrowColor' : '#ffffff',

        'tree': {
          '0': {
            'name' : 'category1',
            'childs' : {
              '0': {
                'url': 'https://player.vimeo.com/video/384459672',
                'thumbnail': 'http://placehold.it/200/f4f4f4&text=1',
                'id' : '',
                'label' : 'video 1',
              },
              '1': {
                'url': 'https://www.youtube.com/watch?v=1sfGOrN33Q4',
                'thumbnail': 'http://placehold.it/200/000000&text=2',
                'id' : '',
                'label' : 'video 2',
              },
              '2': {
                'url': 'https://www.youtube.com/watch?v=1sfGOrN33Q4',
                'thumbnail': 'http://placehold.it/200/d1d1d1&text=3',
                'id' : '',
                'label' : 'video 3',
              },
              '3': {
                'url': 'https://www.youtube.com/watch?v=1sfGOrN33Q4',
                'thumbnail': 'http://placehold.it/200/f2f2f2&text=4',
                'id' : '',
                'label' : 'video 4',
              },
              '4': {
                'url': 'https://www.youtube.com/watch?v=1sfGOrN33Q4',
                'thumbnail': 'http://placehold.it/200/b1b1b1&text=5',
                'id' : '',
                'label' : 'video 5',
              },
              '5': {
                'url': 'https://www.youtube.com/watch?v=1sfGOrN33Q4',
                'thumbnail': 'http://placehold.it/200/b9b99b&text=6',
                'id' : '',
                'label' : 'video 6',
              }
            }
          },
          '1': {
            'name' : 'category2',
            'childs' : {
              '0': {
                'url': 'https://player.vimeo.com/video/384459672',
                'thumbnail': 'http://placehold.it/200/f4f4f4&text=1',
                'id' : '',
                'label' : 'video 1',
              },
              '1': {
                'url': 'https://www.youtube.com/watch?v=1sfGOrN33Q4',
                'thumbnail': 'http://placehold.it/200/000000&text=2',
                'id' : '',
                'label' : 'video 2',
              },
              '2': {
                'url': 'https://www.youtube.com/watch?v=1sfGOrN33Q4',
                'thumbnail': 'http://placehold.it/200/d1d1d1&text=3',
                'id' : '',
                'label' : 'video 3',
              },
              '3': {
                'url': 'https://www.youtube.com/watch?v=1sfGOrN33Q4',
                'thumbnail': 'http://placehold.it/200/f2f2f2&text=4',
                'id' : '',
                'label' : 'video 4',
              },
            }
          }
        },

        'border': '0 none #000000',
        'borderCategory': '0 none #000000',
        'boxShadow': '0 0 0 0 black initial',
        'borderRadius': '0 0 0 0',

        'background-color': '#000000',
        'backgroundImage': 'none',/*url("photographer.jpg"); The image used */
        'backgroundPosition': 'center', /* Center the image todo this resource, not possible change at the moment */
        'backgroundOrigin': 'border-box', /* padding-box|border-box|content-box Do not repeat the image */
        'backgroundRepeat': 'no-repeat', /*repeat | repeat-x | repeat-y | no-repeat | space | round   Do not repeat the image */
        'backgroundSize': 'cover', /* 'auto | 50% | 100% 100% | cover | contain  'Resize the background image to cover the entire container */

        'showDesktop': true,
        'showMobile': true,

        'animation': {
          'in': {
            'action': 'time',   //time/scroll/click/hover
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'speed': 'slow',
            'infinite': false,
          },
          'out': {
            'action': 'time',   //time/scroll/click
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'delay': '1',
            'speed': '1',
            'infinite': false,
          },
        }
      },
      elements: [],
      internal_config: {
        'tag': '',
        'height': '50px',
        'width': '550px',
        'zIndex': 'auto',
        'maxWidth': '500px',

        showDesktop: true,
        showMobile: true,

        'amountOfNews' : '5',


        'arrowSize' : '20px',
        'arrowColor' : '#ffffff',

        'paddingTop': '15px',
        'paddingLeft': '15px',
        'paddingRight': '15px',
        'paddingBottom': '15px',

        'animation': {
          'in': {
            'action': 'time',   //time/scroll/click/hover
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'speed': 'slow',
            'infinite': false,
          },
          'out': {
            'action': 'time',   //time/scroll/click
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'delay': '1',
            'speed': '1',
            'infinite': false,
          },
        }
      },
      name:
        'slidergroup',
    }
  }
}
