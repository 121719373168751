<template>
  <div :id="this.id" class="sitebuilder-element-container" element="FloatingButtonComponent" :style="style()"
    :config-properties="JSON.stringify(internal_config)" :data-hide-desktop="!this.internal_config.showDesktop"
    :data-hide-mobile="!this.internal_config.showMobile">
    <div class="floating-button">
      <a :href="internal_config.type == 'email' ? 'mailto:'+internal_config.href : internal_config.href" target="_blank" rel="noreferrer" @click="linkAnchorForce" @mouseover="hover"> 
        <img class="floating-button-image" ref="image"
          :src="`/images/floating-button/${internal_config.type}.png`"
          v-bind:style="{ 'opacity': opacity, 'width': width, 'height': width, 'border-radius': '100%', 'box-shadow': internal_config.boxShadow, }" />
      </a>
      <a v-if="internal_config.callToAction.enable == true"  rel="noreferrer" @click="linkAnchorForce" class="call-to-action call-to-action-animation"
        :href="internal_config.href" :class="internal_config.right === false ? 'left' : 'right'">
        <div class="call-to-action-text">{{ internal_config.callToAction.text }}</div>
      </a>
    </div>
  </div>
</template>

<script>

import Data from './Model';
import MixinComponents from "@/js/mixins/MixinComponents";

export default {
  name: 'FloatingButtonComponent',
  props: ['config', 'id', 'preview'],
  mixins: [MixinComponents],

  data() {
    return Data.data();
  },
  methods: {
    animation() {
      return true;
    },
    build(config) {
      this.internal_config = config;
    },
    style() {
      var style = `width: ${this.internal_config.width} !important;
                     height:  ${this.internal_config.height} !important;
                     z-index: 9999;
                      ${this.internal_config.right === true ? 'right' : 'left'}:  0 !important;
                     bottom: 20px !important;
                     border-radius: 100% !important;
                     position: fixed !important;
                     margin-left: 20px !important;
                     margin-right: 40px !important;
                     `
      return style;
    },
    hover() {
      this.internal_config.callToAction.enable = false;
    },
    linkAnchorForce() {
      this.$root.$emit("linkAnchorForce");
    }
  },
  computed: {
    opacity() {
      return this.internal_config.opacity;
    },
    width() {
      return this.internal_config.width;
    },
  },
  mounted() {

    this.build(this.$props.config);

    this.internal_config = window.mergeRecursive(this.props, this.$props.config);

    this.$root.$emit('applyAnimation', this, 'sitebuilder-element-container');
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

[data-device="desktop"] .sitebuilder-element-container {
  top: auto !important;
  left: auto !important;
  padding: 0 !important;
  max-width: initial !important;
}

[data-device="tablet"] .sitebuilder-element-container {
  top: auto !important;
  left: auto !important;
  padding: 0 !important;
  max-width: initial !important;
}

[data-device="mobile"] .sitebuilder-element-container {
  left: auto !important;
  top: auto !important;
  padding: 0 !important;
  max-width: initial !important;
}

.floating-button {
  width: 100%;
  width: 100%;
  display: flex;
  position: relative;
  -webkit-box-pack: start;
  justify-content: flex-start;
  align-items: center;
}

.floating-button-image {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}


.call-to-action {
  box-shadow: rgba(136, 136, 136, 0.4) 0px 1px 7px;
  flex-shrink: 0;
  border: 1px solid rgb(226, 226, 226);
  border-radius: 5px;
  background: white;
  cursor: pointer;
  /* max-width: 172px; */
  max-height: 100px;
  display: flex;
  order: 2;
  text-decoration: none !important;
  color: rgb(0, 0, 0) !important;
}

.left {
  left: calc(100% + 20px);
}

.right {
  right: calc(100% + 20px);
}

.call-to-action::before,
.call-to-action::after {
  content: "";
  position: absolute;
  background: white;
  top: 50%;
  margin-top: -4px;
  width: 8px;
  height: 8px;
  z-index: 1;
  transform: rotate(-45deg);
}

.call-to-action.left::after,
.call-to-action.left::before {
  border-top: 1px solid rgb(226, 226, 226);
  border-left: 1px solid rgb(226, 226, 226);
  left: -5px;
}

.call-to-action.right::after,
.call-to-action.right::before {
  border-right: 1px solid rgb(226, 226, 226);
  right: -5px;
}

@keyframes ijMbJr {

  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.call-to-action-animation {
  transform: translateX(0px);
  position: absolute;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-delay: 5s;
  animation-fill-mode: backwards;
  animation-name: ijMbJr;
}

.call-to-action-text {
  text-align: center;
  padding: 10px 10px;
  line-height: 16px;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 14px;
  margin: auto;
  white-space: nowrap;
}
</style>
