<template>
    <div class="sitebuilder-element-container"
         element="WistiaVideoComponent"
         :data-tag="dataTag()"
         :data-hide-desktop="!this.internal_config.showDesktop"
         :data-hide-mobile="!this.internal_config.showMobile" :id="id"
         :style="style()">
        <div class="css"
             :style="`transform: rotate(${this.$props.device === 'desktop' ? this.internal_config.rotation : 0}) scale(1)`">
            <div id="the-sticky-div" class="the-sticky-div-content embed-responsive embed-responsive-16by9">
                <div class="sticky-video-content">
                    <div class="video">
                        <iframe v-if="internal_config.src !== 'https://fast.wistia.net/embed/medias/3u83knod8u'"
                                :width="internal_config.width"
                                :height="internal_config.height"
                                :src="internal_config.src"
                                frameborder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen class="embed-responsive-item"
                                v-bind:style="{'border-radius': radius, border : border}">
                        </iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import Model from './Model';
import mixinComponents from "@/js/mixins/MixinComponents";

export default {
    name: 'WistiaVideoComponent',
    props: ['config', 'height_start', 'device', 'id', 'preview'],
    mixins: [mixinComponents],
    data() {
        return Model.data()
    },
    computed: {},
    methods: {
        style() {
            let style = `left:  ${this.internal_config.left};
                     width: ${this.internal_config.width};
                     height: ${this.internal_config.height};
                     z-index: ${this.internal_config.zIndex};max-width: ${this.internal_config.maxWidth};margin: 0 auto;
                     padding-top: ${this.internal_config.paddingTop};
                     padding-left: ${this.internal_config.paddingLeft};
                     padding-right: ${this.internal_config.paddingRight};
                     padding-bottom: ${this.internal_config.paddingBottom};
										 top: ${this.internal_config.top};`;
            return style;
        },

        getUrl() {
            let url = this.internal_config.src;
            return url
        },
    },
    updated() {

        if (this.internal_config.StickyVideo) {
            this.$root.$emit("applySticky", this,);
            this.internal_config.rotation = 0;
        }
    },
    created() {
        this.internal_config = window.mergeRecursive(this.props, this.$props.config);
    },

    mounted() {

        let device, childs, id, config;
        ({device, childs, id, config} = this.$props);

        this.internal_config = config;
        this.device = device;

        if (typeof id !== 'undefined') {
            this.id = id;
        }

        Object.keys(this.props).forEach(prop => {
            if (typeof this.internal_config[prop] === 'undefined') {
                this.internal_config[prop] = this.props[prop];
            }
        });

        this.$root.$emit('applyAnimation', this, 'sitebuilder-element-container');
    }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
