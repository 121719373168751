<template>
  <div
    class="sitebuilder-element-container"
    element="PostBlogComponent"
    :data-hide-desktop="!this.internal_config.showDesktop"
    :data-hide-mobile="!this.internal_config.showMobile"
    :id="id"
    :config-properties="JSON.stringify(internal_config)"
    v-on:click="focusElement($event)"
    @contextmenu.prevent="handler($event)"
    :style="style()"
  >
      <!-- <div class="divBlock" v-show="!this.preview"></div> -->
      <div v-bind:style="{'border-radius': radius,border : border}">
        <div class="card card-1">
          <div class="title">
            <h2 style="font-family: 'Times New Roman', Times, serif !important;">{{ post.title }}</h2>
          </div>
          <div class="card-img-block">
            <img
              class="card-img-top"
              src="https://neilpatel.com/wp-content/uploads/2018/09/blog-neil-patel-700x475-min-700x475.jpg"
              alt="Card image cap"
            />
          </div>

          <div class="avatar">
            <img
              src="https://abrilexame.files.wordpress.com/2018/10/capaprofile.jpg?quality=70&strip=info&w=720"
              class="rounded-circle mr-3"
              height="50px"
              width="50px"
              alt="avatar"
            />
            <small href="#" class="card-subtitle mb-2 text-muted">BuilderAll </small>
            <small class="card-subtitle mb-2 text-muted">1/1/2000 12:30</small>
          </div>
          <hr />
          <div class="card-body">
            <div v-html="post.content">
             
            </div>
          </div>
          <hr />
          <div style="margin: 15px;" class="tags small">
            <p>
              <a v-for="category in post.categories" :key="category.id" title href class="color5">{{ category.name }}</a>
            </p>
          </div>
          <hr />
          <div>
            <h5 style="margin: 15px;" class="Coment">Comentários</h5>
          </div>
          <!-- Barra de comentarios -->
          <div class="col-md-8 bootstrap snippets">
            <div class="panel">
              <div class="panel-body">
                <textarea style="width : 500px;" class="form-control" rows="2" placeholder="What are you thinking?"></textarea>
                <div class="mar-top clearfix">
                  <button class="btn btn-sm btn-primary pull-right" type="submit">
                    <i class="fa fa-pencil fa-fw"></i> Share
                  </button>
                  <a class="btn btn-trans btn-icon fa fa-video-camera add-tooltip" href="#"></a>
                  <a class="btn btn-trans btn-icon fa fa-camera add-tooltip" href="#"></a>
                  <a class="btn btn-trans btn-icon fa fa-file add-tooltip" href="#"></a>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </div>
  </div>
</template>
<script>
import Model from "./Model";
import Resources from "./Resources.vue";
import axios from 'axios'

export default {
  name: "PostBlogComponent",
  components: {
    Model,
    Resources
  },
  props: ["config", "height_start", "device", "id", "preview"],
  data() {
    return Model.data();
  },
  computed: {
    width() {
      return this.internal_config.width;
    },
    height() {
      return this.internal_config.height;
    },
    border() {
      return this.internal_config.border;
    },
    radius() {
      return this.internal_config.borderRadius;
    }
  },
  methods: {
    animation() {
      return true;
    },
    rebuild() {
      let vm = this;
      let config = this.internal_config;
      this.internal_config = {};
      this.$nextTick(() => {
        vm.internal_config = config;
        this.$root.$emit("rSidebarEdition", vm);
      });
    },

    onResize: function(x, y, width, height) {
      this.internal_config.width = width + "px";
      this.internal_config.height = height + "px";

      this.$refs.resourcesData.setValuesData(this);
    },
    focusElement(e) {
      this.$root.$emit("focusElement", this);
      this.$root.$emit("handlerElement", this);

      e.stopPropagation();
      e.preventDefault();
    },
    showResources() {
      return this.$refs.resourcesData.getValuesData();
    },
    //change order on html
    changeOrderPanel(elem, idx) {
      let id = elem.$el.id,
        sibling =
          idx === 1
            ? document.getElementById(id).nextSibling
            : document.getElementById(id).previousSibling;

      if (sibling === null) {
        return true;
      }

      let vm = this;
      this.$root.$emit("changeOrderElementsInside", vm.id, id, sibling.id, idx);
    },
    handler: function(e) {
      this.$root.$emit("focusElement", this);
      this.$root.$emit("handlerElement", this);
      e.stopPropagation();
      e.preventDefault();
    },
    style() {
      let {
        left,
        width,
        zIndex,
        paddingTop,
        paddingLeft,
        paddingRight,
        paddingBottom,
        top
      } = this.internal_config;

      let style = `  width: ${width} !important;
                     left:  ${left};
                     z-index: ${zIndex};max-width: ${this.internal_config.maxWidth};margin: 0 auto;
                     padding-top: ${paddingTop};
                     padding-left: ${paddingLeft};
                     padding-right: ${paddingRight};
                     padding-bottom: ${paddingBottom};
					 top: ${top}`;

      return style;
    }
  },
  created() {
    let device, childs, id, config;
    ({ device, childs, id, config } = this.$props);
    this.internal_config = config;
    this.device = device;
    if (typeof id !== "undefined") {
      this.id = id;
    }
    this.internal_config = window.mergeRecursive(
      this.props,
      this.$props.config
    );
  },
  mounted() {

    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    var idPost = urlParams.get('id');


    axios.get('https://blog-beta.omb11.com/get-new/' + idPost)
    .then((res) => {
      this.post = res.data;
    })

    if (this.$props.preview) {
      this.$root.$emit("applyAnimation", this, "sitebuilder-element-container");
    }
    let objIds = [];
    $("[id]").each(function() {
      var ids = $('[id="' + this.id + '"]');
      if (ids.length > 1 && ids[0] == this && parseInt(this.id))
        objIds.push(this.id);
    });
    if (objIds.includes(this.id)) {
      this.id = Date.now() + parseInt(Math.random() * 10000);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vdr {
  height: auto !important;
}
.card {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  height: 100%;
}

.card-1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.card-img-block img {
  width: 100%;
  left: 0.2%;
  margin: 0 auto;
  position: relative;
  top: 5%;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.63);
}
.card-block h2 {
  margin: 0 0 5px;
  font-weight: 600;
  font-size: 25px;
}
.avatar {
  margin: 20px;
}
.card-body {
  padding-top: 0;
}
.Coment .title {
  margin: 30px;
}
#hr {
  margin: 0%;
}
.tags a {
  display: inline-block;
  height: 24px;
  line-height: 23px;
  position: relative;
  margin: 0 12px 8px 0;
  padding: 0 12px 0 10px;
  background: #777;
  -moz-border-radius-bottomleft: 5px;
  -webkit-border-bottom-left-radius: 5px;
  border-bottom-left-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -webkit-border-top-left-radius: 5px;
  border-top-left-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 12px;
  font-family: "Lucida Grande", "Lucida Sans Unicode", Verdana, sans-serif;
  text-decoration: none;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  font-weight: bold;
}

.tags a:before {
  content: "";
  position: absolute;
  top: 10px;
  right: 1px;
  float: left;
  width: 5px;
  height: 5px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #fff;
  -moz-box-shadow: -1px -1px 2px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: -1px -1px 2px rgba(0, 0, 0, 0.4);
  box-shadow: -1px -1px 2px rgba(0, 0, 0, 0.4);
}

.tags a:after {
  content: "";
  position: absolute;
  top: 0;
  right: -12px;
  width: 0;
  height: 0;
  border-color: transparent transparent transparent #777;
  border-style: solid;
  border-width: 12px 0 12px 12px;
}

.tags a.color5 {
  background: #00a6df;
}
.tags a.color5:after {
  border-color: transparent transparent transparent #00a6df;
}
.tags a:hover {
  background: rgb(4, 105, 236) !important;
}
.tags a:hover:after {
  border-color: transparent transparent transparent rgb(4, 105, 236) !important;
}
</style>
