<template>
    <div :id="id"
         class="sitebuilder-element-container"
         element="BlogPostComponent"
         :data-hide-desktop="!this.internal_config.showDesktop"
         :data-hide-mobile="!this.internal_config.showMobile"
         :config-properties="JSON.stringify(internal_config)" :style="style()">

        <simple-theme
            :article="article"
            :display_date="displayDate"
            :show="internal_config.show"
            :colors="internal_config.colors"
            :styles="internal_config.styles"
            :capture="capture_settings"
            :is_logged="isLoggedRestrictArea()"
            :key="internal_config.key"
        />


    </div>
</template>

<script>
    import elementData from './Model';
    import _ from 'lodash';
    import axios from 'axios'
    import SimpleTheme from './themes/Simple';
    import { getActualNew, getCaptureConfig, storePermission } from '../../../../js/services/blog'
    import moment from 'moment/moment';


    export default {
        name: 'BlogPostComponent',
        props: ['config', 'id'],
        components: { SimpleTheme },
        data() {
            return elementData.data();
        },
        methods: {
            build(config) {
                this.internal_config = _.merge(this.internal_config, config);
            },
            isLoggedRestrictArea() {
                return !!(localStorage.getItem('login-user') && localStorage.getItem('login-user-token'))
            },
            animation() {
                return false;
            },
            getTitle() {
                return this.article.title;
            },
            style() {
                return `width: ${this.internal_config.width};
               height:  ${this.internal_config.width};
               z-index: ${this.internal_config.zIndex};
               margin: 0 auto;
               padding-top: ${this.internal_config.paddingTop};
               padding-left: ${this.internal_config.paddingLeft};
               padding-right: ${this.internal_config.paddingRight};
               padding-bottom: ${this.internal_config.paddingBottom};
               left:  ${this.internal_config.left};
               top: ${this.internal_config.top}`;
            },
            formatDate(date, format) {
                const locale = window.navigator.userLanguage || window.navigator.language;
                moment.locale(locale)

                return moment.utc(String(date)).local().format(format);
            },

        },
        mounted() {
            this.build(this.$props.config);
            let vm = this;

            getActualNew()
                .then( ({ data }) => {

                    const is_capture = false;
                    vm.article = data;
                    console.log(vm.article);
                    console.log(vm.internal_config.show.formatDate);
                    vm.article.scheduled_date = vm.formatDate(vm.article.scheduled_date, vm.internal_config.show.formatDate)

                    $('.post-date.desktop-element').text(vm.article.scheduled_date);
                    $('.post-date.mobile-element').text(vm.article.scheduled_date);

                    if(vm.article.is_capture == '1'){
                        vm.article.is_capture = true;
                    }else{
                        vm.article.is_capture = false;
                    }

                    if(vm.article.is_capture) {
                        if(!this.isLoggedRestrictArea() && vm.article.is_capture) {
                            getCaptureConfig()
                            .then( (capture_data) => {
                                vm.capture_settings = capture_data.data;
                                vm.capture_settings.theme = JSON.parse(vm.capture_settings.theme)
                                vm.capture_settings.login_button_settings = JSON.parse(vm.capture_settings.login_button_settings);
                                vm.capture_settings.register_button_settings = JSON.parse(vm.capture_settings.register_button_settings);
                            } )
                        }
                    }

                    if(vm.article.enabled_comments == '1'){
                        vm.article.enabled_comments = true;
                    }else{
                        vm.article.enabled_comments = false;
                    }

                    if(typeof vm.article.enabled_comments !== 'undefined' && vm.article.enabled_comments == '1'){
                        vm.article.enabled_comments = true;
                    }else{
                        vm.article.enabled_comments = false;
                    }


                    //register access
                    let url_access = 'https://blog-beta.omb11.com/register-view/';
                    let pathName =  window.location.pathname.replace('/blog/', '');
                    pathName =  pathName.replace('/', '');
                    url_access = url_access + pathName + '?website_id=' + window.OPTIONS_INIT_SITE.options.w_code


                    if(localStorage.getItem('url_view_' + vm.article.url) == null){
                        localStorage.setItem('url_view_' + vm.article.url, true);
                        url_access = url_access + '&first=true'
                    }

                    axios.get(url_access)
                        .then( response => console.log(response) )

                } )


            if(this.isLoggedRestrictArea) {

                if(localStorage.getItem('login-user') !== null) {
                        let id = 0;
                        restrictsArea.forEach( (area) => {
                            if(area.is_blog == 1) {
                            id = area.id
                            }
                        });

                    let email = JSON.parse(localStorage.getItem('login-user')).email;

                    if(id !== 0) {
                        storePermission(`set-blog-area?user_email=${email}&restrict_id=${id}`);
                    }
                }
            }






            $('p>img').each( function(i, p){
                let p2 = ($(p).parent())
                if(p2.height() == 0){ $(p2).css('height', p.height) }
            }  )
            setTimeout(function(){
                $('p>img').each( function(i, p){
                    let p2 = ($(p).parent())
                    if(p2.height() == 0){ $(p2).css('height', p.height) }
                } )


                $('p>img').css('max-width', '100%');
                $('.ql-video').css('max-width', '100%');
                $('.ql-video').css('pointer-events', 'visible');
                $('a').removeAttr('rel');
                $('.content-post > iframe').css('max-width', '100%')
                $('.content-post > p > iframe').css('max-width', '100%')




            }, 1000)


            this.$nextTick(() => {

                $('.content-post > p > img').each( function(i, p){
                    let p2 = ($(p).parent())
                    if(p2.height() == 0){ $(p2).css('height', p.height) }
                } )
                $('.content-post > a').removeAttr('rel');

                vm.heightLoad = (vm.$el.getBoundingClientRect().height);


                if( vm.heightLoad > 400){
                    vm.heightLoad = 620;
                }



                setInterval( () => {
                    let heightNow = $('#' + vm.$el.id).height();

                    if(vm.heightNow > 0) {
                        vm.heightNow = heightNow - vm.heightNow;
                    }else{
                        vm.heightNow = heightNow;

                    }


                    if (vm.heightNow > vm.heightLoad) {
                        let elementBounding = vm.$el.getBoundingClientRect();
                        let top = $(vm.$el).position().top;
                        let left = $(vm.$el).offset().left;
                        let width = elementBounding.width;
                        let newHeight = vm.heightNow - vm.heightLoad;
                        vm.heightLoad = vm.heightNow;



                        if (typeof vm.$parent.changeHeight !== 'undefined' && typeof vm.$parent.changeHeight === 'function') {
                            vm.$parent.changeHeight(newHeight, true, vm.$el.id, top, left - 100, width);
                        }
                        vm.heightLoad = vm.heightNow;
                    }else{

                        // if(vm.heightLoad === vm.heightNow){
                        //     return;
                        // }
                        // let elementBounding = vm.$el.getBoundingClientRect();

                        //  let top = $(vm.$el).position().top;
                        // let left = $(vm.$el).offset().left;
                        // let width = elementBounding.width;
                        // let newHeight = vm.heightLoad -  vm.heightNow;

                        // vm.heightLoad = vm.heightNow;


                        // // console.log('esse ' + vm.heightNow)
                        // // console.log('esse ' + vm.heightLoad)

                        // vm.$parent.changeHeight(newHeight * (-1), true, vm.$el.id, top, left - 100, width);

                    }
                }, 1000 )

            })

            setTimeout( function() {
                vm.internal_config.key = vm.internal_config.key +1;
            }, 2000 )



            // vm.$parent.changeHeight(12000, true, vm.$parent.id, top, left, width, true);


            setInterval(() => {
                $('.content-post > p > img').each( function(i, p){
                    let p2 = ($(p).parent())
                    if(p2.height() == 0){ $(p2).css('height', p.height) }
                } )

                $('.content-post > a').removeAttr('rel');

            }, 2000);


        },
        computed: {
            displayDate: function() {
                return this.article.scheduled_date
            }
        },
    };
</script>

<style lang="scss">
[element="BlogPostComponent"] {
  @import '@/assets/css/quill.core';
}
</style>
<style lang="scss" >

[element="BlogPostComponent"] {
    height: auto !important;
}

.content-post {
    img, p > i frame {
        max-width: 100% !important;
    }

    h1, h2, h3, h4, h5, h6,
    p {
        margin: 0;
        font-size: unset !important;
    }

    .ql-video {
        max-width: 100% !important;
        pointer-events: visible;
    }

    .ql-align {
        &-center{
            text-align: center;
        }
        &-right {
            text-align: right;
        }
        &-justify {
            text-align: justify;
        }
    }

    .ql-font {
        &-andale-mono {
            font-family: 'Andale Mono', sans-serif;
        }
        &-arial {
            font-family: 'Arial', sans-serif;
        }
        &-arial-black {
            font-family: 'Arial Black', sans-serif;
        }
        &-book-antiqua {
            font-family: 'Book Antiqua', sans-serif;
        }
        &-comic-sans-ms {
            font-family: 'Comic Sans MS', sans-serif;
        }
        &-courier-new {
            font-family: 'Courier New', sans-serif;
        }
        &-georgia {
            font-family: 'Georgia', sans-serif;
        }
        &-helvetica {
            font-family: 'Helvetica', sans-serif;
        }
        &-impact {
            font-family: 'Impact', sans-serif;
        }
        &-lato {
            font-family: 'Lato', sans-serif;
        }
        &-montserrat {
            font-family: 'Montserrat', sans-serif;
        }
        &-muli {
            font-family: 'Muli', sans-serif;
        }
        &-nunito {
            font-family: 'Nunito', sans-serif;
        }
        &-open-sans {
            font-family: 'Open Sans', sans-serif;
        }
        &-playfair-display {
            font-family: 'Playfair Display', sans-serif;
        }
        &-symbol {
            font-family: 'Symbol', sans-serif;
        }
        &-roboto {
            font-family: 'Roboto', sans-serif;
        }
        &-tahoma {
            font-family: 'Tahoma', sans-serif;
        }
        &-terminal {
            font-family: 'Terminal', sans-serif;
        }
        &-times-new-roman {
            font-family: 'Times New Roman', sans-serif;
        }
        &-trebuchet-ms {
            font-family: 'Trebuchet MS', sans-serif;
        }
        &-verdana {
            font-family: 'Verdana', sans-serif;
        }
    }
}
</style>
