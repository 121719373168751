var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sitebuilder-element-container big-is-receiver",style:(_vm.style()),attrs:{"id":this.id,"element":"GroupMenuComponent","data-hide-desktop":!this.internal_config.showDesktop,"data-hide-mobile":!this.internal_config.showMobile}},[_c('div',{staticClass:"vdr"},[_c('div',{staticClass:"container-menu-group",style:({
      borderRadius: _vm.radius,
      background: _vm.background,
      border : _vm.border,
      boxShadow: _vm.boxShadow,
      paddingTop : _vm.paddingTop,
      paddingLeft : _vm.paddingLeft,
      paddingRight : _vm.paddingRight,
      paddingBottom : _vm.paddingBottom,
      gap: (_vm.horizontalMargin + "px"),
      })},_vm._l((_vm.elements),function(element,index){return _c(element.name,{key:index,tag:"component",attrs:{"config":element.config,"device":_vm.device,"is_popup":_vm.is_popup,"id":element.id,"height_start":index,"preview":_vm.preview,"childs":element.childs}})}),1)])])}
var staticRenderFns = []

export { render, staticRenderFns }