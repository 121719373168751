export default {
  data() {
    return {
      props: {
        'tag': '',
        'width': '550px',
        'zIndex': 'auto',
        'maxWidth': '500px',

        'height': '50px',
        'heightContainer': '300px',

        'heightHeader': '60px',
        'heightBody': '90px',

        'videoData' : {},

        'icon' : 'mdi-close-circle-outline',
        'fontSize': '15px',
        'color': '#0f0f0f',


        'paddingTop': '15px',
        'paddingLeft': '15px',
        'paddingRight': '15px',
        'paddingBottom': '15px',

        'showDesktop': true,
        'showMobile': true,

        'border': '0 none #000000',
        'backgroundColor': '#F0F0F0',
        'borderRadius': '0 0 0 0',

        'borderContainer': '0 none #000000',
        'borderRadiusContainer': '0 0 0 0',
        'backgroundColorContainer': '#FFFFFF',

        'animation': {
          'in': {
            'action': 'time',   //time/scroll/click/hover
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'speed': 'slow',
            'infinite': false,
          },
          'out': {
            'action': 'time',   //time/scroll/click
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'delay': '1',
            'speed': '1',
            'infinite': false,
          },
        }
      },
      elements: [],
      internal_config: {
        'tag': '',
        'height': '50px',
        'width': '550px',
        'zIndex': 'auto',
        'maxWidth': '500px',

        showDesktop: true,
        showMobile: true,

        'paddingTop': '15px',
        'paddingLeft': '15px',
        'paddingRight': '15px',
        'paddingBottom': '15px',

        'icon' : 'mdi-close-circle-outline',
        'fontSize': '15px',
        'color': '#0f0f0f',

        'animation': {
          'in': {
            'action': 'time',   //time/scroll/click/hover
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'speed': 'slow',
            'infinite': false,
          },
          'out': {
            'action': 'time',   //time/scroll/click
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'delay': '1',
            'speed': '1',
            'infinite': false,
          },
        }
      },
      name:
        'accordion',
    }
  }
}
