export default {
  data() {
    return {
      props: {
        'tag': '',
        'height': '150px',
        'width': '150px',
        'zIndex': 'auto','maxWidth':'768px',
        'rotation': '0deg',
        'opacity': 1,
        'src': 'https://storage.builderall.com/franquias/2/181114/editor-html/2700563.jpeg',
        'paddingTop': '15px',
        'paddingLeft': '15px',
        'paddingRight': '15px',
        'paddingBottom': '15px',
        'marginLeft': '0',
        'marginRight': '0',
        'alt': '',
        'title': '',
        'link': {
          'type': 'web',
          'value': '',
          'target': '_blank',
          'targetID': 0
        },
        'animation': {
          'in': {
            'action': 'time',   //time/scroll/click/hover
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'speed': 'slow',
            'infinite': false,
          },
          'out': {
            'action': 'time',   //time/scroll/click
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'delay': '1',
            'speed': '1',
            'infinite': false,
          },
        },
         
      },
      internal_config: {
        'zIndex': 'auto','maxWidth':'768px',
        'rotation': '0deg',
        src: '',
        width: '150px',
        height: '150px',
        left: '0px',
        top: '0px',
        showDesktop: true,
        showMobile: true,
        opacity: 1,
        'paddingTop': '15px',
        'paddingLeft': '15px',
        'paddingRight': '15px',
        'paddingBottom': '15px',
        'marginLeft': '0',
        'marginRight': '0',
        'link': {
          'type': 'web',
          'value': '',
          'target': '_blank',
          'targetID': 0
        },
      },
      name: 'image',
      alt: String(),
      real_src: String(),
    }
  }
}
