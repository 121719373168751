export default {
  data() {
    return {
      props: {

        'separatorActive' : false,
        'separatorHtml' : '',
        'separatorEffect' : 'waves-floating',
        'separatorColors' : {
          'first' : '#ffffff',
          'second' : '#eff20a',
          'third' : '#2e3032',
          'fourth' : '#001bf4',
          'custom': false,
          'value1': 5,
          'value2': 8,
          'value3': 5,
          'value4': 5,
        },

        'tag': '',
        'showMobile' : true,
        'showDesktop' : true,
        'height': '400px',
        'minHeight': '50px',
        'background-color': '#F0F0F0',
        'backgroundColor': '#F0F0F0',
        'border': '0 none #000000',
        'boxShadow': '0 0 0 0 black initial',
        'backgroundImage': 'none',/*url("photographer.jpg"); The image used */
        'backgroundPosition': 'center', /* left top | left center | left bottom | right top | right center | right bottom | center top | center center | center bottom */
        'backgroundOrigin': 'border-box', /* padding-box|border-box|content-box Do not repeat the image */
        'backgroundRepeat': 'no-repeat', /*repeat | repeat-x | repeat-y | no-repeat | space | round   Do not repeat the image */
        'backgroundSize': 'cover', /* 'auto | 50% | 100% 100% | cover | contain  'Resize the background image to cover the entire container */
        'backgroundAttachment': 'scroll', /* scroll | fixed 'The background image will scroll or not scroll with the page' */
        'borderRadius': '0 0 0 0',
        'fixed' : false,
        'infiniteSliderAnimation': false,
        'fixedMobile' : false,
        'anchorActive' : false,
        'parallaxActive' : false,
        'parallaxEffect' : 'scroll',
        'parallaxSpeed' : 20,
        'anchorName' : '',

        'internalBackgroundColor': '#F0F0F000',

        'internalBackgroundImage': 'none',/*url("photographer.jpg"); The image used */
        'internalBackgroundPosition': 'center', /* left top | left center | left bottom | right top | right center | right bottom | center top | center center | center bottom */
        'internalBackgroundOrigin': 'border-box', /* padding-box|border-box|content-box Do not repeat the image */
        'internalBackgroundRepeat': 'no-repeat', /*repeat | repeat-x | repeat-y | no-repeat | space | round   Do not repeat the image */
        'internalBackgroundSize': 'cover', /* 'auto | 50% | 100% 100% | cover | contain  'Resize the background image to cover the entire container */
        'internalBackgroundAttachment': 'scroll', /* scroll | fixed 'The background image will scroll or not scroll with the page' */

        'video' : '',
        'videoInternal' : false,
        'autoplay' : false,
        'muted' : false,
        'loop' : false,

        'arrowActive' : false,
        'arrowWidth': 33,
        'arrowPanelWidth' : 0,
        'arrowWidthMobile': 100,
        'arrowHeight': 50,
        'arrowColor': '#F0F0F0',

        hidePanel : false,
        timeHidePanel: 0,
        typeHidePanel: 'time',
        unitsHidePanel: 'seconds',
        nameHidePanel: 'name-hide-panel',

        'animation': {
          'in': {
            'action': 'time',   //time/scroll/click/hover
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'speed': 'slow',
            'infinite': false,
          },
          'out': {
            'action': 'time',   //time/scroll/click
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'delay': '1',
            'speed': '1',
            'infinite': false,
          },
        }
      },
      elements: [],
      internal_config: {
        'fixed' : false,
        'fixedMobile' : false,
        showMobile : true,
        'showDesktop' : true,
        height: '400px',
        'minHeight': '50px',
        backgroundColor: '#F0F0F0',
        border: '0 none #000000',
        boxShadow: '0 0 0 0 black initial',
        borderRadius: '0 0 0 0',
        anchorActive : false,
        anchorName : '',
        'parallaxActive' : false,
        'parallaxEffect' : 'scroll',
        'parallaxSpeed' : 20,
        id: '',
        'paddingTop': '15px',
        'paddingLeft': '15px',
        'paddingRight': '15px',
        'paddingBottom': '15px',
      }
    }
  }
}
