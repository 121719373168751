export default {
    data() {
      return {
        props: {
          'tag': '',
          'width': '300px',
          'height': '300px',
          'border': '0 none #000000',
          'boxShadow': '0 0 0 0 black initial',
          'borderRadius': '0 0 0 0',
          'paddingTop': '15px',
          'paddingLeft': '15px',
          'paddingRight': '15px',
          'paddingBottom': '15px',
          'zIndex': 'auto','maxWidth':'768px',
          'rotation': '0deg',
          'src': '',

          //Elements for StickyVideo
          'StickyVideo' : false,
          'positionSticky' : '',
          'btnClick' : '',
          'sizeSticky' : '',
  
          'animation': {
            'in': {
              'action': 'time',   //time/scroll/click/hover
              'time': 0,       // sec
              'scroll': 0,     // px
              'effect': 'none',
              'speed': 'slow',
              'infinite': false,
            },
            'out': {
              'action': 'time',   //time/scroll/click
              'time': 0,       // sec
              'scroll': 0,     // px
              'effect': 'none',
              'delay': '1',
              'speed': '1',
              'infinite': false,
            },
          },
        },
        elements: [],
        internal_config: {
          'width': '300px',
          'height': '300px',
          'border': '0 none #000000',
          'boxShadow': '0 0 0 0 black initial',
          'borderRadius': '0 0 0 0',
          'paddingTop': '15px',
          'paddingLeft': '15px',
          'paddingRight': '15px',
          'paddingBottom': '15px',
          'src': '',
          'rotation': '0deg',
  
          'animation': {
            'in': {
              'action': 'time',   //time/scroll/click/hover
              'time': 0,       // sec
              'scroll': 0,     // px
              'effect': 'none',
              'speed': 'slow',
              'infinite': false,
            },
            'out': {
              'action': 'time',   //time/scroll/click
              'time': 0,       // sec
              'scroll': 0,     // px
              'effect': 'none',
              'delay': '1',
              'speed': '1',
              'infinite': false,
            },
          },
        }
      }
    }
  }
  