<template>
    <div class="sitebuilder-element-container is-receiver"
         element="BoxHoverComponent"
         ref="elem"
         :id="id"
         :data-hide-desktop="!this.internal_config.showDesktop"
         :data-hide-mobile="!this.internal_config.showMobile"
         :config-properties="JSON.stringify(internal_config)"
         :style="style()">
        <div class="box-hover-wrapper box-hover-circle left_to_right scale_up"
             :class="`effect${internal_config.effect} ${internal_config.direction}`"
             ref="box-hover">
            <div href="#" class="box-hover-content box-hover-content-action">
                <div class="box-hover-content-img" v-bind:style="styleBoxContainer('cover')"></div>
                <div class="box-hover-content-info" :style="styleBoxContainer('hover')">
                    <component v-for="(element,index) in elements"
                               :is="element.name"
                               :config="element.config"
                               :key="index"
                               :id="element.id"
                               :height_start="index"
                               :preview="preview"
                               :childs="element.childs"></component>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import Model from './Model';

export default {
    name: 'BoxHoverComponent',
    components: {
        Model
    },
    props: ['config', 'childs', 'height_start', 'device', 'id', 'preview'],
    data() {
        return Model.data();
    },
    computed: {

        boxShadow() {
            return this.internal_config.boxShadow;
        },
        radius() {
            return this.internal_config.borderRadius;
        },

        styleBoxImg() {
            return {
                border: this.border(),
                'border-radius': this.radius,
                'box-shadow': this.boxShadow,
            }
        },
    },
    methods: {
        styleBoxContainer(type) {
            var style =  {
                background: this.background(type),
                border: this.border(),
                'border-radius': this.radius,
                'box-shadow': this.boxShadow,
                minHeight: this.minHeight(),
            };

            var heights = {
                minHeight: this.minHeight(),
                height: 'auto',
            }

            return this.device !== 'desktop' ? {...style, ...heights} : style;
        },

        background(type = 'cover') {
            let bg = this.internal_config[type].backgroundColor;

            if (this.internal_config[type].backgroundImage !== 'none' && typeof this.internal_config[type].backgroundImage !== 'undefined') {
                this.internal_config[type].backgroundImage = this.internal_config[type].backgroundImage.replace('omb11.com', 'storage.builderall.com');

                bg += ' url("' + this.internal_config[type].backgroundImage + '")';
                bg += ' ' + this.internal_config[type].backgroundPosition;
                bg += ' / ' + this.internal_config[type].backgroundSize;
                bg += ' ' + this.internal_config[type].backgroundRepeat;
                bg += ' ' + this.internal_config[type].backgroundOrigin;
            }
            return bg;
        },

        border() {
            return this.internal_config.border;
        },

        width() {
            return this.internal_config.width;
        },

        height() {
            return this.internal_config.height;
        },

        minHeight() {
            return this.internal_config.minHeight;
        },

        style() {
            return {
                top: this.internal_config.top,
                left: this.internal_config.left,
                'padding-top': this.internal_config.paddingTop,
                'padding-left': this.internal_config.paddingLeft,
                'z-index': this.internal_config.zIndex,
                'max-width': this.internal_config.maxWidth,
                'padding-right': this.internal_config.paddingRight,
                'padding-bottom': this.internal_config.paddingBottom,
                minHeight: this.minHeight(),
                width: this.width(),
                height: this.height(),
                margin: '0 auto',
            };
        },

        fixObjBackground(config) {
            var newConfig = JSON.parse(JSON.stringify(config));

            if (typeof config.src !== 'undefined') {
                newConfig.cover.backgroundImage = config.src;
            }

            if (typeof config['backgroundImage'] !== 'undefined') {
                newConfig.hover = {
                    'backgroundColor': config['backgroundColor'],
                    'backgroundImage': config['backgroundImage'],
                    'backgroundPosition': config['backgroundPosition'],
                    'backgroundOrigin': config['backgroundOrigin'],
                    'backgroundRepeat': config['backgroundRepeat'],
                    'backgroundSize': config['backgroundSize'],
                };
            }

            return newConfig;
        },

        build(config) {
            let vm = this;
            vm.internal_config = _.merge(vm.internal_config, config);
        },

    },

    created() {
        let {childs, config} = this.$props;

        this.build(config);

        this.elements = childs.sort((a, b) => (a.config.order_mobile > b.config.order_mobile) ? 1 : ((b.config.order_mobile > a.config.order_mobile) ? -1 : 0));

        let internalConfig = window.mergeRecursive(this.props, config);
        this.internal_config = this.fixObjBackground({...internalConfig, ...this.internal_config});
    },
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
