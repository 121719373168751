export default {
    data() {
      return {
        props: {
          'width': '300px',
          'height': '300px',
          'zIndex': 'auto','maxWidth': '500px',
          'rotation': '0deg',
          'border': '0 none #000000',
          'boxShadow': '0 0 0 0 black initial',
          'borderRadius': '0 0 0 0',
          'paddingTop': '15px',
          'paddingLeft': '15px',
          'paddingRight': '15px',
          'paddingBottom': '15px',
          'src': 'https://embed.vidello.com/1319/nzuk1m6bmyd895bt/player.html',
          
          //Elements for StickyVideo
          'StickyVideo' : false,
          'positionSticky' : '',
          'btnClick' : '',
          'sizeSticky' : '',
          'sizeBtn' : null,
          'ative' : false,

          'animation': {
            'in': {
              'action': 'time',   //time/scroll/click/hover
              'time': 0,       // sec
              'scroll': 0,     // px
              'effect': 'none',
              'speed': 'slow',
              'infinite': false,
            },
            'out': {
              'action': 'time',   //time/scroll/click
              'time': 0,       // sec
              'scroll': 0,     // px
              'effect': 'none',
              'delay': '1',
              'speed': '1',
              'infinite': false,
            },
          },
        },
        elements: [],
        internal_config: {
          'width': '300px',
          'height': '300px',
          'zIndex': 'auto',
          'rotation': '0deg',
          'border': '0 none #000000',
          'boxShadow': '0 0 0 0 black initial',
          'borderRadius': '0 0 0 0',
          'paddingTop': '15px',
          'paddingLeft': '15px',
          'paddingRight': '15px',
          'paddingBottom': '15px',
          'src': 'https://embed.vidello.com/1319/nzuk1m6bmyd895bt/player.html',
          'srcFinal' :'',
          /* 'checkAutoPlay': false,
          'checkBlockPlay': false, */

          'animation': {
            'in': {
              'action': 'time',   //time/scroll/click/hover
              'time': 0,        // sec
              'scroll': 0,      // px
              'effect': 'none',
              'speed': 'slow',
              'infinite': false,
            },
            'out': {
              'action': 'time',   //time/scroll/click
              'time': 0,        // sec
              'scroll': 0,     // px
              'effect': 'none',
              'delay': '1',
              'speed': '1',
              'infinite': false,
            },
          },
        }
      }
    }
  }

