<template>
  <div class="row align-items-center px-2 py-3 shadow container-box">
    <div v-if="config.access_denied.btn.enable && ['left', 'top'].includes(config.access_denied.btn.position)"
         :class="`${config.access_denied.btn.position === 'left' ? 'col-4 text-left': 'col-12 text-center mb-2'}`">
      <a href="#" class="btn btn-sm btn-primary align-items-center justify-content-center d-inline-flex"
         v-if="config.access_denied.btn.text"
         @click.prevent="redirectLink">{{ config.access_denied.btn.text }}</a>
      <a href="#" class="btn btn-sm btn-primary align-items-center justify-content-center d-inline-flex" v-else
         @click.prevent="redirectLink">{{ $t('ct-home') }}</a>
    </div>

    <div :class="`${(config.access_denied.btn.enable && ['right', 'left'].includes(config.access_denied.btn.position)) ? 'col-8' : 'col-12 text-center'}`">
      <h5 class="mb-0 clean-text-css" v-if="config.access_denied.message">{{ config.access_denied.message }}</h5>
      <h5 class="mb-0 clean-text-css" v-else>{{ $t('ct-restrict-area-not-have-permission') }}</h5>
    </div>

    <div v-if="config.access_denied.btn.enable && ['right', 'bottom'].includes(config.access_denied.btn.position)"
         :class="`${config.access_denied.btn.position === 'right' ? 'col-4 text-right': 'col-12 text-center mt-2'} `">
      <a href="#" class="btn btn-sm btn-primary align-items-center justify-content-center d-inline-flex"
         v-if="config.access_denied.btn.text"
         @click.prevent="redirectLink">{{ config.access_denied.btn.text }}</a>
      <a href="#" class="btn btn-sm btn-primary align-items-center justify-content-center d-inline-flex" v-else
         @click.prevent="redirectLink">{{ $t('ct-home') }}</a>
    </div>
  </div>
</template>

<script>
import {mixinRestrictAreaConfig} from './../../../../js/mixins/restrictAreaForm';

export default {
  name: "NotHavePermission",
  mixins: [mixinRestrictAreaConfig],
}
</script>
