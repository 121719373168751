export default {
  data() {
    return {
      key: 0,
      heightLoad: 0,
      heightNow: 0,
      is_capture: 0,
      capture_settings: {
        theme: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        },
        login_button_settings: {
          text: 'Login',
          "style":{"fontFamily":"Courier New","fontSize":"14px","background":"#4396E6","color":"#E0E0E0","borderRadius":"50px","padding":"37px","margin":"23px"}
        },
        register_button_settings: {
          text: 'Register',
          "style":{"fontFamily":"Courier New","fontSize":"14px","background":"#4396E6","color":"#E0E0E0","borderRadius":"50px","padding":"37px","margin":"23px"}
        }
      }, 
      article: {
        title: '',
        scheduled_date: '2020-01-01',
        display_date: '',
        content: '',
        author: {
          name: '',
          thumbnail: '',
          slug: '#',
          email: false,
          facebook_url: false,
          instagram_url: false,
          twitter_url: false,
          linkedin_url: false,
          youtube_url: false,
          medium_url: false
        },
        categories: [

        ]
      },
      props: {
        'name': 'BlogPostComponent',
        'zIndex': 'auto',
        'paddingTop': '0px',
        'paddingLeft': '15px',
        'paddingRight': '15px',
        'paddingBottom': '15px',
      },
      elements: [],
      internal_config: {
        'width': '1000px',
        'height': '500px',
        'zIndex': 'auto',
        'showDesktop': true,
        'showMobile': true,
        'paddingTop': '0px',
        'paddingLeft': '15px',
        'paddingRight': '15px',
        'paddingBottom': '15px',
        left: '0px',
        top: '0px',
        show: {
          display: false,
          categories: true,
          author: true,
          date: true,
          formatDate: 'YYYY-MM-DD',
          shareBar: {
            display: true,
            email: true,
            copy: true,
            facebook: true,
            instagram: true,
            twitter: true,
            linkedin: true,
            youtube: true,
            medium: true,
            pinterest: true,
            whatsapp: true,
            telegram: true,
            align: 'left',
            theme: 'circle'
          }
        },
        colors: {
          color_1: 'white',
          color_2: '#000',
          color_3: '#fff',
          color_4: '#000',
          color_5: '#000'
        },
        styles: {
          title: {
            family: 'Lato, sans-serif',
            size: '25px',
            sizeMobile: '15px',
            color: 'black',
            align: 'left',
            weight: '400',
            style: 'initial',
            stroke: 'none'
          },
          author: {
            family: 'Lato, sans-serif',
            size: '25px',
            sizeMobile: '15px',
            color: 'black',
            align: 'left',
            weight: '400',
            style: 'initial',
            stroke: 'none'
          },
          categories: {
            family: 'Lato, sans-serif',
            size: '25px',
            sizeMobile: '15px',
            color: 'black',
            align: 'left',
            weight: '400',
            style: 'initial',
            stroke: 'none'
          }
        }
      }
    };
  }
};
