<template>

<div>
    <div class="container">
        <div class="row">
            <div v-for="post in options.posts" :key="post.number" class="col-md-4 col-blog">
                <div class="inner">
                <p class="category" >
                    <a :href="'/c/' + category.slug" :style="{color: options.colorReadMore }" v-for="category in post.categories"  :key="category.id">
                        {{ category.name }}  <br>
                    </a>
                    <br>
                    <span :style="{color: options.colorReadMore, 'margin-top': '15px' }" class="date"><i class="fa fa-clock-o"></i> {{ post.date_show }}</span>
                    </p>
                  <h2 class="clean-text-css desktop-element" :style="options.titleStyle">{{ post.title }}</h2>
                  <h2 class="clean-text-css mobile-element" :style="options.titleStyleMobile">{{ post.title }}</h2>
                  <p :style="options.contentStyle"  v-html="post.content.substr(0, 500).toLowerCase()" class="clean-text-css desktop-element" >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec suscipit justo ex, vel scelerisque magna volutpat ut. Nunc ultrices facilisis orci, pulvinar gravida orci mattis quis.</p>
                  <p :style="options.contentStyleMobile"  v-html="post.content.substr(0, 500).toLowerCase()" class="clean-text-css mobile-element" >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec suscipit justo ex, vel scelerisque magna volutpat ut. Nunc ultrices facilisis orci, pulvinar gravida orci mattis quis.</p>
              
                  <a v-if="post.readEnable" :style="{color: options.colorReadMore }" class="read-more" @click="redir(post.url)" :href="'/' + post.url">{{ options.textReadMore }} </a>
                </div>
            </div>
        </div>
        </div>
</div>
  
</template>

<script>
export default {
    props: ['options'],
    name: 'ThemeGridSimple',
    created(){
        let root = document.documentElement;
        root.style.setProperty('--btn-theme-grid-color', this.options.cardBgColor);
        root.style.setProperty('--btn-theme-grid-color-hover', this.options.colorDetails);
        root.style.setProperty('--btn-theme-grid-color-readmorehover', this.options.colorReadMoreHover);

    },
    methods: {
      redir(string){
        window.location.href =  string;
      },
    },
    watch:{
      options(){
        let root = document.documentElement;
        root.style.setProperty('--btn-theme-grid-color', this.options.cardBgColor);
        root.style.setProperty('--btn-theme-grid-color-hover', this.options.colorDetails);
        root.style.setProperty('--btn-theme-grid-color-readmorehover', this.options.colorReadMoreHover);
      }
    },
}
</script>

<style scoped>
  [data-device="mobile"] .col-blog {
   -ms-flex: 0 0 50%;
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }

  [data-device="mobile"] .row {
    display: block;
  }



.fly {
  -webkit-transform: translateX(-200px);
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -o-transition: 0.4s;
  -ms-transition: 0.4s;
  transition: 0.4s;
}

.flyIn {
  -webkit-transform: translateX(0px);
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -o-transition: 0.4s;
  -ms-transition: 0.4s;
  transition: 0.4s;
}

.col-md-4:hover {
  background: var(--btn-theme-grid-color-hover);
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -o-transition: 0.4s;
  -ms-transition: 0.4s;
  transition: 0.4s;
  -webkit-transform: translateX(20px);
  -moz-transform: translateX(20px);
  -o-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
  position: relative;
  z-index: 5000;
}

.post:hover {
  text-decoration: none;
}

.fa {
  margin-right: 5px;
}

.col-md-4 {
  height: auto;
  overflow: hidden;
  background: var(--btn-theme-grid-color);
  border: 1px solid #1e2328;
  position: relative;
  z-index: 0;
}
.col-md-4 .inner {
  padding: 20px;
}
.col-md-4 .inner .category {
  color: #f26743;
  font: 900 13px Lato;
  text-transform: uppercase;
}
.col-md-4 .inner .date {
  font: 900 13px Lato;
  color: #9b9eab;
  /* margin-left: 5px; */
}
.col-md-4 .inner h2 {
  font: 900 24px Lato;
  text-transform: uppercase;
  margin-bottom: 18px;
  color: #fff;
}
.col-md-4 .inner p {
  font: 400 14px Lato;
  line-height: 22px;
  color: #9b9eab;
}
.col-md-4 .inner .read-more {
  color: #f26743;
  display: block;
  font: 900 13px Lato;
  position: relative;
  text-transform: uppercase;
  margin-top: 15px;;
}
.col-md-4 .inner .read-more:hover {
  text-decoration: none;
}


.col-md-4 .inner .read-more:hover{
        text-decoration: none;
        color: var(--btn-theme-grid-color-readmorehover) !important;
    }

a:active,
a:focus {
  color: #f26743;
  text-decoration: none;
}


</style>