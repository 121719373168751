<template>
  <div
    class="image-gallery-one-main-container"
    :style="{
            gridTemplateColumns: `repeat(${internal_config.columns}, auto)`,
            gridColumnGap: `${internal_config.verticalMargin}px`,
            gridRowGap: `${internal_config.horizontalMargin}px`,
            opacity: opacity
          }"
    >
    <div
      class="image-gallery-one-main-photo"
      v-for="(image, index) in internal_config.images"
      :key="index"
      :style="{
              backgroundImage: 'url(' + image + ')',
              cursor: $props.preview ? 'pointer': '',
              borderRadius: radius,
              border : border
             }"
      @click="$emit('showPhoto', index)"
    ></div>
  </div>
</template>

<script>

export default {
  name: "OneMainGallery",
  props: ['internal_config'],
  computed: {
    minHeight() {
      return this.internal_config.minHeight;
    },
    opacity() {
      return this.internal_config.opacity;
    },
    border() {
      return this.internal_config.border;
    },
    radius() {
      return this.internal_config.borderRadius;
    }
  },

  data() {
    return {
      images: [],
    }
  },

  watch: {
    'internal_config.images': function (newValue) {
      this.build();
    },
    'internal_config.columns': function (newValue) {
      this.build();
    },
    'internal_config.imagesPerPage': function (newValue) {
      this.build();
    },
  },

  methods:{
    build(){

    }
  },

  created() {
    this.build();
  }
}
</script>

<style lang="scss" scoped>
/** GALLERY */

.image-gallery-one-main-container {
  display: grid;
  width: 100%;
  height: 100%;
}

.image-gallery-one-main-photo {
  overflow: hidden;
  float: left;
  background-position: top center;
  background-size: cover;
  position: relative;

  &:nth-child(1){
    grid-column: auto/span 2;
  }
}
</style>
