<template>
    <div :id="this.id"
         class="sitebuilder-element-container big-is-receiver"
         element="VideoGalleryGroupComponent"
         :data-hide-desktop="!this.internal_config.showDesktop"
         :data-hide-mobile="!this.internal_config.showMobile"
         :style="style()">

        <div class="vdr" id="video-galery-vdr">

            <div v-for="(category, index) in internal_config.tree" :key="index"
                 :class="'container-fluid carrousel-'+index "
                 v-bind:style="{
              background: background,
              paddingTop : paddingTop + '!important',
              paddingLeft : paddingLeft + '!important',
              paddingRight : paddingRight + '!important',
              paddingBottom : paddingBottom + '!important',
              borderRadius: radius,
              border : border,
              boxShadow: boxShadow
              }">
                <p class="clean-text-css" :style="textStyle()">{{category.name}}</p>
                <div id="myCarousel" class="carousel" data-ride="carousel" data-interval="9000">
                    <div class="carousel-inner" role="listbox" v-bind:style="{height : heightTop}">
                        <div v-for="(video, indexVideo) in category.childs"
                             :class="'item ' + (indexVideo == 0 ?'active':'')"
                             :url="video.url" :key="indexVideo">
                            <div class="element-item" :data-id="video.id">
                                <a href="#" v-bind:style="{height: heightTop}">
                                    <img class="place-img" :src="video.thumbnail"
                                         v-bind:style="{maxHeight: heightTopImg}">
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- Controls -->
                    <a class="left carousel-control" v-bind:style="{height : heightTop}" href="#myCarousel"
                       role="button"
                       data-slide="prev" @click="prev(index)" v-show="showArrow(category.childs)">
                        <span class="mdi mdi-chevron-left" aria-hidden="true"
                              :style="{color:arrowColor, fontSize : arrowSize}"></span>
                    </a>
                    <a class="right carousel-control" v-bind:style="{height : heightTop}" href="#myCarousel"
                       role="button"
                       data-slide="next" @click="next(index)" v-show="showArrow(category.childs)">
                        <span class="mdi mdi-chevron-right" aria-hidden="true"
                              :style="{color:arrowColor, fontSize : arrowSize}"></span>
                    </a>
                    <div class="container-gallery-video" v-bind:style="{height : height}" :data-id="dataId">
                        <div v-for="(video, indexVideo) in category.childs" :key="indexVideo" class="h-100 gallery-container">
                            <component v-for="(element,index) in elements" :is="element.name" :config="element.config"
                                       :key="index"
                                       v-if="video.id == element.id"
                                       :id="element.id" :height_start="index" :preview="preview"
                                       :childs="element.childs"
                                       :style="styleApp()">
                            </component>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import Data from './Model';

	export default {
		name: 'VideoGalleryGroupComponent',
		props: ['config', 'childs', 'device', 'id', 'preview'],
		computed: {
			arrowColor() {
				return this.internal_config.arrowColor;
			},
			arrowSize() {
				return parseInt(this.internal_config.arrowSize) + 'px';
			},
			dataId() {
				return this.id
			},
			paddingTop() {
				return this.internal_config.paddingTopContainer;
			},
			paddingLeft() {
				return this.internal_config.paddingLeftContainer;
			},
			paddingRight() {
				return this.internal_config.paddingRightContainer;
			},
			paddingBottom() {
				return this.internal_config.paddingBottomContainer;
			},
			boxShadow() {
				return this.internal_config.boxShadow;
			},
			border() {
				return this.internal_config.border;
			},
			height() {
				return parseInt(this.internal_config.height) - parseInt(this.internal_config.heightTop) + 'px';
			},
			heightTop() {
				return this.internal_config.heightTop;
			},
			heightTopImg() {
				return parseInt(this.internal_config.heightTop) - 5 + 'px';
			},
			radius() {
				return this.internal_config.borderRadius;
			},
			// bg-color bg-image position/bg-size bg-repeat bg-origin
			background() {
				let bg = this.internal_config.backgroundColor;

				if (this.internal_config.backgroundImage !== 'none' && typeof this.internal_config.backgroundImage !== 'undefined') {
					this.internal_config.backgroundImage = this.internal_config.backgroundImage.replace('omb11.com', 'storage.builderall.com');

					bg += ' url("' + this.internal_config.backgroundImage + '")';
					bg += ' ' + this.internal_config.backgroundPosition;
					bg += ' / ' + this.internal_config.backgroundSize;
					bg += ' ' + this.internal_config.backgroundRepeat;
					bg += ' ' + this.internal_config.backgroundOrigin;
				}

				return bg;
			}

		},
		data() {
			return Data.data();
		},
		methods: {
			textStyle() {
				return `font-family:  ${this.internal_config.textStyle.family};
                 font-size: ${this.internal_config.textStyle.size};
                 text-align: ${this.internal_config.textStyle.align};
                 font-weight: ${this.internal_config.textStyle.weight};
                 font-style: ${this.internal_config.textStyle.style};
                 text-decoration: ${this.internal_config.textStyle.stroke};
                 -webkit-text-fill-color: ${this.internal_config.textStyle.color};
                 border-bottom: ${this.internal_config.borderCategory};
                 margin-bottom: 10px;
                 color: ${this.internal_config.textStyle.color}`;
			},
			widthItem(childs) {
				if (!this.showArrow(childs)) {
					return (parseInt(this.internal_config.width) / (Object.keys(childs).length)) + 'px';
				}
				let amountOfNews = this.internal_config.amountOfNews;
				return (parseInt(this.internal_config.width) / (amountOfNews)) + 'px';
			},
			showArrow(childs) {
				if (this.$props.device !== 'desktop') {
					return true;
				}
				return parseInt(this.internal_config.amountOfNews) <= Object.keys(childs).length - 1;
			},
			prev(index) {
				let thisEl = $(this.$el).find('.carrousel-' + index).find('.item.active');

				let prev = $(thisEl).prev();
				if (!prev.length) {
					prev = $(thisEl).siblings(':last');
				}
				thisEl.removeClass('active');
				prev.addClass('active');
			},
			next(index) {

				let thisEl = $(this.$el).find('.carrousel-' + index).find('.item.active');

				let next = $(thisEl).next();
				if (!next.length) {
					next = $(thisEl).siblings(':first');
				}
				thisEl.removeClass('active');
				next.addClass('active');

			},
			showVideo(id) {

        $('[element="VideoGalleryComponent"]').find("iframe").each(function () {
					let src = $(this).attr('src');
					$(this).attr('src', src);
				});
				
				$('[element="VideoGalleryComponent"]').hide();
				let element = $('#' + id);

				//Tratamento para sempre colocar display : none nos elementos
				let vgDisplay = document.querySelectorAll('.h-100')
				$.each(vgDisplay, function(index, value) {
					$(vgDisplay).addClass('gallery-container')
				});
				
				element.show();
				$(element).parent().removeClass('gallery-container')
				element.parent().parent().show();
				this.alterHeight();
			},
			alterHeight() {
				let vm = this;

				let elementBounding = vm.$el.getBoundingClientRect();

				let top = $(vm.$el).position().top;
				let left = $(vm.$el).offset().left;
				let width = elementBounding.width;
				let height = vm.internal_config.realHeight;

				setTimeout(() => {

					vm.internal_config.realHeight = elementBounding.height;

					let valueChanged = parseInt(height - elementBounding.height);


					if (typeof vm.$parent.changeHeight !== 'undefined') {
						vm.$parent.changeHeight(valueChanged, false, vm.id, top, left, width);
					} else {
						vm.$parent.$parent.changeHeight(valueChanged, false, vm.id, top, left, width);
					}

				}, 300)
			},

			getDataVideo(id) {
				let tree = this.internal_config.tree;

				for (let i in tree) {
					let childs = tree[i]['childs'];
					for (let j in childs) {
						if (childs[j]['id'] == id) {
							return childs[j];
						}
					}
				}
			},
			style() {
				return `left:  ${this.internal_config.left};
                top: ${this.internal_config.top};
				        padding-top: ${this.internal_config.paddingTop};
                padding-left: ${this.internal_config.paddingLeft};
                padding-right: ${this.internal_config.paddingRight};
                padding-bottom: ${this.internal_config.paddingBottom};
                z-index: ${this.internal_config.zIndex};
                width: ${this.internal_config.width};
                max-width: ${this.internal_config.maxWidth};
                margin: 0 auto;`;
			},
			styleApp() {
				return `left:  ${this.internal_config.paddingLeftContainer};
                top: ${this.internal_config.paddingTopContainer};
                display:none;
                width: calc(100% - ${parseInt(this.internal_config.paddingRightContainer) * 2}px !important;
                height: calc(100% - ${parseInt(this.internal_config.paddingBottomContainer) * 2}px !important;`;
			},
			checkVideoApp: function () {
				let vm = this;
				let tree = this.internal_config.tree;

				for (let i in tree) {
					let childs = tree[i]['childs'];
					for (let j in childs) {
						if (childs[j]['id'] === '') {
							this.$root.$emit('createBaseElement', 'VideoGalleryComponent', {}, this.id, (value) => {
								vm.internal_config.tree[i]['childs'][j]['id'] = value;
							});
						}
					}
				}
			},
			build() {
				this.checkVideoApp();

				//3-6
				let amountOfNews = this.internal_config.amountOfNews;
				let vm = this;


				setTimeout(() => {
					$(vm.$el).find('.carousel-inner').each((i, el) => {

						let lengthCarousel = $(el).find('.item').length;

						if (amountOfNews < lengthCarousel) {
							$(el).parent().carousel({
								interval: 10000
							})
						}

						let countItems = amountOfNews
						if ($(el).find('.item').length < amountOfNews) {
							countItems = $(el).find('.item').length;
						}


						$(el).find('.item').each((j, elItem) => {

							let next = $(elItem).next();
							if (!next.length) {
								next = $(elItem).siblings(':first');
							}
							next.children(':first-child').clone().appendTo($(elItem));

							for (let i = 0; i < (countItems - 2); i++) {
								next = next.next();
								if (!next.length) {
									next = $(elItem).siblings(':first');
								}

								next.children(':first-child').clone().appendTo($(elItem));
							}
						});
					});

					$(vm.$el).find('.container-gallery-video').hide();

					$('.element-item').on('click', (e) => {
						$(vm.$el).find('.container-gallery-video').hide();
						vm.showVideo(e.currentTarget.getAttribute('data-id'))
						
					})

					vm.internal_config.realHeight = vm.$el.getBoundingClientRect().height;

				}, 3000);
			}
		},
		mounted() {
			let device, childs, id, config;
			({device, childs, id, config} = this.$props);

			if (typeof config.backgroundColor === 'undefined') {
				config.backgroundColor = '#FFFFFF';
			}
			this.internal_config = config;
			this.device = device;

			if (typeof childs === 'undefined') {
				childs = [];
				this.internal_config.childs = [];
			}

			if (typeof id !== 'undefined') {
				this.id = id;
			}

			this.elements = childs.sort((a, b) => (a.config.order_mobile > b.config.order_mobile) ? 1 : ((b.config.order_mobile > a.config.order_mobile) ? -1 : 0));

			// this.internal_config = window.mergeRecursive(this.props, this.$props.config);
			let internal_config = window.mergeRecursive(this.props, this.$props.config);
			this.internal_config = {...internal_config, ...this.internal_config};


			this.build();

		}
	};

</script>
<style lang="scss" scoped>


    .sitebuilder-element-container {
        .vdr {
            height: unset !important;

        }
    }

    .container-gallery-video {
        position: relative;
    }

    [data-device="mobile"],
    [data-device="tablet"] {
        .container-gallery-video {
            height: auto !important;
        }
    }

    [data-device="tablet"] {
        iframe.video-margin {
            height: 425px;
        }
    }

    [data-preview="true"] {
        .container-gallery-video {
            overflow: hidden;
        }
    }

    img.place-img {
        margin-left: 5px;
    }
	.gallery-container {
		display: none;
	}

    [element="VideoGalleryGroupComponent"] {

        border: none;

        .carousel-inner {
            .item.active {
                display: inline-flex;
            }

        }

        .carousel-control {
            width: 20px;
            text-align: center;
            vertical-align: middle;
            background: none;

            display: flex; /*CSS3*/
            align-items: center; /*Vertical align*/
            justify-content: center; /*horizontal align*/
        }

        .carousel-control.right {
            right: -20px;
        }

        .carousel-control.left {
            left: -20px;
        }
    }

</style>
