<template>
  <div>
    <!-- Desktop -->
    <div
        :class="`mtf card-list desktop-element clean-theme-desktop ${ !!(position & 1) ? 'alt': '' }`"
        :style="cardStyle()"
      >
      <div class="card-list__content-info">

        <!-- Thumbnail -->
        <div
          v-show="show.thumbnail"
          class="card-list__thumbnail"
          :style="thumbnailStyle()"
        />

        <ul class="details">

          <!-- Author -->
          <li class="author">
            <a
              v-show="show.author"
              :href="showUrl(`/a/${article.author.slug}`)"
              :style="authorStyle()"
              v-text="article.author.name"
            />
          </li>

          <!-- Date -->
          <li
            v-show="show.date"
            class="date"
            :style="authorStyle()"
            v-text="formatDate(article.scheduled_date, config.dateFormat)"
          />

          <!-- Categories -->
          <li
            v-show="show.categories"
            class="categories"
            v-for="({ id, name, slug }) in article.categories"
            :key="id"
          >
            <a
              :href="showUrl(`/c/${slug}`)"
              :style="categoryStyle()"
              v-text="name"
            />
          </li>
        </ul>
      </div>

      <!-- Content -->
      <div
      class="card-list__content-preview"
      :style="{
        backgroundColor: colors.color_2
      }">

        <!-- Title -->
        <a
          :href="showUrl(`/blog/${article.url}`)"
          class="clean-text-css"
          :style="titleStyle()"
          v-text="article.title"
        />

        <!-- Post -->
        <p
        v-show="show.content"
        class="clean-text-css"
        :style="contentStyle()"
        v-html="article.content.substr(0, 300)"
        />

        <!-- Read more -->
        <p
        v-show="show.button"
        class="read-more"
        :style="alignButton()"
        >
          <a
            :href="showUrl(`/blog/${article.url}`)"
            class="clean-text-css"
            :style="buttonStyle()"
            v-text="config.readMoreText"
          />
        </p>
      </div>
    </div>

    <!-- Mobile -->
     <div
        :class="`card-list mobile-element clean-theme-mobile ${ !!(position & 1) && 'alt' }`"
        :style="cardStyle()"
      >
      <div
      class="card-list__content-info"
      :style="contentInfo()">

        <!-- Thumbnail -->
        <div
          v-show="show.thumbnail"
          class="card-list__thumbnail"
          :style="thumbnailStyle(false)"
        />

        <ul class="details">

          <!-- Author -->
          <li class="author">
            <a
              v-show="show.author"
              :href="showUrl(`/a/${article.author.slug}`)"
              :style="authorStyle(false)"
              v-text="article.author.name"
            />
          </li>

          <!-- Date -->
          <li
            v-show="show.date"
            class="date"
            :style="authorStyle(false)"
            v-text="formatDate(article.scheduled_date, config.dateFormat)"
          />

          <!-- Categories -->
          <li
            v-show="show.categories"
            class="categories"
            v-for="({ id, name, slug }) in article.categories"
            :key="id"
          >
            <a
              :href="showUrl(`/c/${slug}`)"
              :style="categoryStyle(false)"
              v-text="name"
            />
          </li>
        </ul>
      </div>

      <!-- Content -->
      <div
      class="card-list__content-preview"
      :style="{
        backgroundColor: colors.color_2
      }">

        <!-- Title -->
        <a
          :href="showUrl(`/blog/${article.url}`)"
          class="clean-text-css"
          :style="titleStyle(false)"
          v-text="article.title"
        />

        <!-- Post -->
        <p
        v-show="show.content"
        class="clean-text-css"
        :style="contentStyle(false)"
        v-html="article.content.substr(0, 300)"
        />

        <!-- Read more -->
        <p
        v-show="show.button"
        class="read-more"
        :style="alignButton()"
        >
          <a
            :href="showUrl(`/blog/${article.url}`)"
            class=""
            :style="buttonStyle(false)"
            v-text="config.readMoreText"
          />
        </p>
      </div>
    </div>
  </div>

</template>

<script>

import {mixinBlogComponents} from "@/js/mixins/BlogComponents";

export default {
  name: 'List',
  props: ['article', 'position', 'config', 'colors', 'show', 'styles', 'sizes'],
  mixins: [mixinBlogComponents],
  methods: {
    cardStyle() {
      const root = document.documentElement;
      root.style.setProperty('--detail-color-theme-1', this.colors.color_1);

      return `border-radius: ${this.sizes.card.borderRadius};`
    },
    contentInfo(desktop = true) {
      return `
        width: 100%;
        height: 150px;
        `
    },
    thumbnailStyle(desktop = true) {
      return `
        background-image: url(${ this.article.thumbnail });
        border-radius: ${this.sizes.thumbnail.borderRadius};
      `;
    },
    authorStyle(desktop = true) {
      return `font-family:  ${this.styles.author.family};
                  font-size: ${desktop ? this.styles.author.size : this.styles.author.sizeMobile} !important;
                  text-align: ${this.styles.author.align};
                  font-weight: ${this.styles.author.weight};
                  font-style: ${this.styles.author.style};
                  text-decoration: ${this.styles.author.stroke};
                  color: ${this.styles.author.color}`;
    },
    categoryStyle(desktop = true) {
      return `background-color: ${this.colors.color_3};
              box-shadow: none;
              border-radius: ${this.styles.categories.borderRadius};
              font-family:  ${this.styles.categories.family};
              font-size: ${desktop ? this.styles.categories.size : this.styles.categories.sizeMobile} !important;
              text-align: ${this.styles.categories.align};
              font-weight: ${this.styles.categories.weight};
              font-style: ${this.styles.categories.style};
              text-decoration: ${this.styles.categories.stroke};
              color: ${this.styles.categories.color};
              height: ${this.styles.categories.height};
              overflow: hidden`;
    },
    titleStyle(desktop = true) {
      return `font-family:  ${this.styles.title.family};
                  font-size: ${desktop ? this.styles.title.size : this.styles.title.sizeMobile} !important;
                  text-align: ${this.styles.title.align};
                  font-weight: ${this.styles.title.weight};
                  font-style: ${this.styles.title.style};
                  text-decoration: ${this.styles.title.stroke};
                  height: ${this.sizes.title.height !== '0px' ? this.sizes.title.height : 'auto'};
                  color: ${this.styles.title.color};
                  -webkit-text-fill-color: ${this.styles.title.color};
                `;
    },
    contentStyle(desktop = true) {
      return `font-family:  ${this.styles.content.family};
                  font-size: ${desktop ? this.styles.content.size : this.styles.content.sizeMobile} !important;
                  text-align: ${this.styles.content.align};
                  font-weight: ${this.styles.content.weight};
                  font-style: ${this.styles.content.style};
                  text-decoration: ${this.styles.content.stroke};
                  color: ${this.styles.content.color};
                  -webkit-text-fill-color: ${this.styles.content.color};
                  height: ${this.sizes.content.height !== '0px' ? this.sizes.content.height : 'auto'};
                  overflow: hidden`;
    },
    alignButton() {
      const align = this.styles.button.align === 'left' ? 'flex-start' : this.styles.button.align === 'right' ? 'flex-end' : 'center';

      return `height: ${this.sizes.buttonDiv.height !== '0px' ? this.sizes.buttonDiv.height : 'auto'};
               display: flex;
               align-items: center;
               justify-content: ${align}`;

    },
    buttonStyle(desktop = true) {
      const root = document.documentElement;
      root.style.setProperty('--btn-theme-grid-color-readmorehover', this.colors.color_4);

      return `
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: ${this.colors.color_5};
              box-shadow: none;
              border-radius: ${this.sizes.button.borderRadius};
              font-family:  ${this.styles.button.family};
              font-size: ${desktop ? this.styles.button.size : this.styles.button.sizeMobile} !important;
              text-align: ${this.styles.button.align};
              font-weight: ${this.styles.button.weight};
              font-style: ${this.styles.button.style};
              text-decoration: ${this.styles.button.stroke};
              color: ${this.styles.button.color};
              height: ${this.sizes.button.height !== '0px' ? this.sizes.button.height : 'auto'};
              width: ${this.sizes.button.width !== '0px' ? this.sizes.button.width : 'auto'};
              overflow: hidden;
              cursor: pointer;`;
    },
    altMethod() {

      if (this.options.theme == 2) {
        return true
      }


      if (this.options.theme == 3) {

        if (typeof data !== 'undefined' && data.number % 2 == 0) {
          return true;
        }
      }

      return false;
    },
  },
}
</script>

<style scoped>
  .card-list {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    margin: 1rem auto;
    box-shadow: 0 3px 7px -1px rgba(0, 0, 0, 0.1);
    margin-bottom: 1.6%;
    line-height: 1.4;
    font-family: sans-serif;
    border-radius: 5px;
    overflow: hidden;
    z-index: 0;
  }

  .card-list a {
    color: inherit;
  }

  .card-list:hover .card-list__thumbnail {
    -webkit-transform: scale(1.3) rotate(3deg);
    transform: scale(1.3) rotate(3deg);
  }

  .card-list .card-list__content-info {
    position: relative;
    z-index: 0;
    height: 200px;
  }

  .card-list .card-list__thumbnail {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    -webkit-transition: -webkit-transform 0.2s;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
  }

  .card-list .details,
  .card-list .details ul {
    margin: auto;
    padding: 0;
    list-style: none;
  }

  .card-list .details {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -100%;
    margin: auto;
    -webkit-transition: left 0.2s;
    transition: left 0.2s;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    padding: 10px;
    width: 100%;
    font-size: 0.9rem;
  }

  .card-list .details a {
    -webkit-text-decoration: dotted underline;
    text-decoration: dotted underline;
  }

  .card-list .details ul li {
    display: inline-block;
  }

  .card-list .details .categories li {
    margin-right: 2px;
  }

  .card-list .details .categories li:first-child {
    margin-left: -4px;
  }

  .card-list .card-list__content-preview {
    padding: 1rem;
    background: #fff;
    position: relative;
    z-index: 1;
  }

  .card-list .card-list__content-preview h1,
  .card-list .card-list__content-preview h2 {
    font-family: Poppins, sans-serif;
  }

  .card-list .card-list__content-preview h1 {
    line-height: 1;
    margin: 0;
    font-size: 1.7rem;
  }

  .card-list .card-list__content-preview h2 {
    font-size: 1rem;
    font-weight: 300;
    text-transform: uppercase;
    color: #a2a2a2;
    margin-top: 5px;
  }


  .card-list .card-list__content-preview .read-more a {
    display: inline-block;
    position: relative;
  }

  .card-list .card-list__content-preview .read-more:hover a{
    color: var(--btn-theme-grid-color-readmorehover) !important;
  }

  .card-list .card-list__content-preview .read-more a:after {
    content: "\F055";
    font-family: "Material Design Icons";
    margin-left: -10px;
    opacity: 0;
    vertical-align: middle;
    -webkit-transition: margin 0.3s, opacity 0.3s;
    transition: margin 0.3s, opacity 0.3s;
  }

  .card-list .card-list__content-preview .read-more a:hover:after {
    margin-left: 5px;
    opacity: 1;
  }

  .card-list p {
    position: relative;
    margin: 1rem 0 0;
  }

  .card-list p:first-of-type {
    margin-top: 1.25rem;
  }

  .card-list p:first-of-type:before {
    content: "";
    position: absolute;
    height: 5px;
    background: var(--my-color-var) ;
    width: 35px;
    top: -0.75rem;
    border-radius: 3px;
  }

  .card-list:hover .details {
    left: 0%;
  }

  @media (min-width: 640px) {
    .card-list {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-direction: row;
    }

    .card-list .card-list__content-info {
      flex-basis: 40%;
      height: auto;
    }

    .card-list .card-list__content-preview {
      flex-basis: 60%;
    }

    .card-list .card-list__content-preview:before {
      -webkit-transform: skewX(-3deg);
      transform: skewX(-3deg);
      content: "";
      background: var(--detail-color-theme-1) !important;
      width: 30px;
      position: absolute;
      left: -10px;
      top: 0;
      bottom: 0;
      z-index: -1;
    }

    .card-list.alt {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      flex-direction: row-reverse;
    }

    .card-list.alt .card-list__content-preview:before {
      left: inherit;
      right: -10px;
      -webkit-transform: skew(3deg);
      transform: skew(3deg);
    }

    .card-list.alt .details {
      padding-left: 25px;
    }
  }


</style>
