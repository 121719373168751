<template>
    <div
        class="sitebuilder-element-container"
        element="VimeoVideoComponent"
        :data-hide-desktop="!this.internal_config.showDesktop"
        :data-hide-mobile="!this.internal_config.showMobile"
        :id="id"
        :style="style()"
    >
        <div class="css">
            <div id="the-sticky-div" class="embed-responsive embed-responsive-16by9">
                <div class="sticky-video-content">
                    <div class="video">
                        <iframe
                            :width="internal_config.width"
                            :height="internal_config.height"
                            :src="getUrl()"
                            :data-src="getDataUrl()"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                            class="embed-responsive-item"
                            v-bind:style="{'border-radius': radius,border : border}"
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Model from "./Model";

export default {
    name: "VimeoVideoComponent",
    props: ["config", "height_start", "device", "id", "preview", "is_popup"],
    data() {
        return Model.data();
    },
    computed: {
        width() {
            return this.internal_config.width;
        },
        height() {
            return this.internal_config.height;
        },
        border() {
            if (typeof this.internal_config.border !== "undefined") {
                let splitedBorder = this.internal_config.border.split(" ");

                if (
                    typeof splitedBorder[2] !== "undefined" &&
                    this.$globalStoreHelpers.isValidHex(splitedBorder[2])
                ) {
                    splitedBorder[2] = this.$globalStoreHelpers.hexToRGBA(
                        splitedBorder[2]
                    );
                    this.internal_config.border = splitedBorder.join(" ");
                }
            }
            return this.internal_config.border;
        },
        radius() {
            return this.internal_config.borderRadius;
        }
    },
    methods: {

        getUrlBase() {
            let url = this.internal_config.src;
            let linkTemp = document.createElement('a');
            linkTemp.href = url;
            let search = linkTemp.search !== '';

            url = url + ((search) ? '&' : '?');

            if (
                this.internal_config.checkBoxAutoPlay &&
                this.internal_config.checkBoxLoop
            ) {
                url = url + "autoplay=1&loop=1";
            } else {
                if (this.internal_config.checkBoxLoop) {
                    url = url + "loop=1";
                } else {
                    if (this.internal_config.checkBoxAutoPlay) {
                        url = url + "autoplay=1";
                    }
                }
            }

            url = url.replace("??", "?");

            return url;
        },

        getUrl() {
            if (this.is_popup == true) {
                return null;
            }

            return this.getUrlBase();
        },

        getDataUrl() {
            if (this.is_popup == true) {
                return this.getUrlBase();
            }
        },

        style() {
            let style = `left:  ${this.internal_config.left};
					 z-index: ${this.internal_config.zIndex};max-width: ${
                this.internal_config.maxWidth
            };margin: 0 auto;
					 width: ${this.internal_config.width};
					 height: ${this.internal_config.height};
					 padding-top: ${this.internal_config.paddingTop};
					 padding-left: ${this.internal_config.paddingLeft};
                     padding-right: ${this.internal_config.paddingRight};
                     padding-bottom: ${this.internal_config.paddingBottom};
					 top: ${this.internal_config.top};
					 transform: rotate(${this.$props.device == "desktop" ? this.internal_config.rotation : 0}) scale(1)`;
            return style;
        },
    },
    created() {
        let device, childs, id, config;
        ({device, childs, id, config} = this.$props);
        this.internal_config = config;
        this.device = device;
        if (typeof id !== "undefined") {
            this.id = id;
        }
        this.internal_config = window.mergeRecursive(
            this.props,
            this.$props.config
        );
    },

    mounted() {

        this.$root.$emit("applyAnimation", this, "sitebuilder-element-container");

        if (this.internal_config.StickyVideo) {
            this.$nextTick(() => {
                    this.$root.$emit("applySticky", this);
                    this.internal_config.rotation = 0;
                }
            )
        }
    },
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vdr {
    height: auto !important;
}

#the-sticky-div {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}

/*Small*/
.stuckBRs, .stuck-bottom-right-small {
    position: fixed;
    transform: translateY(100%);
    width: 260px !important;
    height: 145px !important;
    animation: fade-in-up .25s ease forwards;

    right: 25px;
    left: unset !important;
    top: unset !important;
    bottom: 10px;
    z-index: 100 !important;
}

.stuckTLs, .stuck-top-left-small {
    position: fixed;
    transform: translateY(100%);
    width: 260px !important;
    height: 145px !important;
    animation: fade-in-up .25s ease forwards;

    left: 10px !important;
    top: 10px !important;
    z-index: 100 !important;
}

.stuckTRs, .stuck-top-right {
    position: fixed;
    transform: translateY(100%);
    width: 260px !important;
    height: 145px !important;
    animation: fade-in-up .25s ease forwards;

    right: 25px;
    left: unset !important;
    top: 10px !important;
    z-index: 100 !important;
}

.stuckBLs, .stuck-bottom-left {
    position: fixed;
    transform: translateY(100%);
    width: 260px !important;
    height: 145px !important;
    animation: fade-in-up .25s ease forwards;

    right: unset;
    left: 10px !important;
    top: unset !important;
    bottom: 10px;
    z-index: 100 !important;
}

/*Medium*/
.stuckBRm, .stuck-bottom-right-medium {
    position: fixed;
    transform: translateY(100%);
    width: 400px !important;
    height: 227px !important;
    animation: fade-in-up .25s ease forwards;

    right: 25px;
    left: unset !important;
    top: unset !important;
    bottom: 10px;
    z-index: 100 !important;
}

.stuckTLm, .stuck-top-left-medium {
    position: fixed;
    transform: translateY(100%);
    width: 400px !important;
    height: 227px !important;
    animation: fade-in-up .25s ease forwards;

    left: 10px !important;
    top: 10px !important;
    z-index: 100 !important;
}

.stuckTRm, .stuck-top-right-medium {
    position: fixed;
    transform: translateY(100%);
    width: 400px !important;
    height: 227px !important;
    animation: fade-in-up .25s ease forwards;

    right: 25px;
    left: unset !important;
    top: 10px !important;
    z-index: 100 !important;
}

.stuckBLm, .stuck-bottom-left {
    position: fixed;
    transform: translateY(100%);
    width: 400px !important;
    height: 227px !important;
    animation: fade-in-up .25s ease forwards;

    right: unset;
    left: 10px !important;
    top: unset !important;
    bottom: 10px;
    z-index: 100 !important;
}

/*Large*/
.stuckBRl, .stuck-bottom-right {
    position: fixed;
    transform: translateY(100%);
    width: 500px !important;
    height: 283px !important;
    animation: fade-in-up .25s ease forwards;

    right: 25px;
    left: unset !important;
    top: unset !important;
    bottom: 10px;
    z-index: 100 !important;
}

.stuckTLl, .stuck-top-left {
    position: fixed;
    transform: translateY(100%);
    width: 500px !important;
    height: 283px !important;
    animation: fade-in-up .25s ease forwards;

    left: 10px !important;
    top: 10px !important;
    z-index: 100 !important;
}

.stuckTRl, .stuck-top-right {
    position: fixed;
    transform: translateY(100%);
    width: 500px !important;
    height: 283px !important;
    animation: fade-in-up .25s ease forwards;

    right: 25px;
    left: unset !important;
    top: 10px !important;
    z-index: 100 !important;
}

.stuckBLl, .stuck-bottom-left {
    position: fixed;
    transform: translateY(100%);
    width: 500px !important;
    height: 283px !important;
    animation: fade-in-up .25s ease forwards;

    right: unset;
    left: 10px !important;
    top: unset !important;
    bottom: 10px;
    z-index: 100 !important;
}
</style>
