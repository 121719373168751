<template>
  <div :data-field-group-name="data.visibility !== 'hidden' ? data.tag : ''">
    <div class="form-group" v-show="this.data.visibility !== 'hidden'">
      <label v-show="this.showLabel"> {{label}} </label>
      <select class="form-control" :required="required" v-model="selected" :multiple="multiple" :name="this.data.tag"
              :placeholder="placeholder">
        <option v-if="!showLabel" value="" disabled>{{label}}</option>
				<option v-show="label === 'dropdown'" value="" disabled > {{ $t('ct-choose-creation-type') }}...</option>
        <option class="form-check" v-for="element in options" :value="element.value">{{element.name}}</option>
      </select>
      <small class="form-text text-muted"  style="width: 100%" v-show="data.help_text != ''">{{data.help_text}}</small>
    </div>
  </div>
</template>

<script>
	export default {
		name: 'DropDownMailingBoss',
		props: ['options', 'label', 'selected', 'data', 'multiple', 'showPlaceholder', 'showLabel'],
		data() {
			return {}
		},
		computed: {
			placeholder() {
				return this.showPlaceholder ? this.label : ''
			},
			required() {
				return this.data.required === 'yes';
			}
		}
	}
</script>
