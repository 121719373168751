var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"elem",staticClass:"sitebuilder-element-container page height-100",style:(_vm.style()),attrs:{"id":this.id,"element":"SliderPageComponent","data-hide-desktop":!this.internal_config.showDesktop,"data-hide-mobile":!this.internal_config.showMobile}},[_c('div',{staticClass:" height-100",attrs:{"id":this.id}},[_c('div',{staticClass:"sitebuilder-element-receiver height-100",style:({
    borderRadius: _vm.radius,
    border: _vm.border,

    background: _vm.backgroundColor,
    backgroundImage: _vm.backgroundImage,
    backgroundSize: _vm.backgroundSize,
    backgroundPosition: _vm.backgroundPosition,
    backgroundOrigin: _vm.backgroundOrigin,
    backgroundRepeat: _vm.backgroundRepeat,
    backgroundAttachment: _vm.backgroundAttachment,
    minHeight:'50px'

    })},_vm._l((_vm.elements),function(element,index){return _c(element.name,{key:index,tag:"component",attrs:{"config":element.config,"device":_vm.device,"is_popup":_vm.is_popup,"id":element.id,"height_start":index,"preview":_vm.preview,"childs":element.childs}})}),1)])])}
var staticRenderFns = []

export { render, staticRenderFns }