import XDate from 'xdate';
import countdown from 'countdown';

class ApplyConfig {
  constructor(timer) {
    this.timer = timer;
    this.el = timer.$el;
    this.id = this.el.id;
    this.config = timer.internal_config ? timer.internal_config : timer.config;
    this.settings = { active: 'false', counter: '', link: '' };
    this.isEditor = document.querySelectorAll('.panel-components-right').length > 0;
  }

  getLocalStorage(key) {
    return localStorage.getItem(key + (this.isEditor ? '-editor-' : '') + this.id);
  }

  getSumDate(config) {
    return XDate()
      .addDays(config.days)
      .addHours(config.hours)
      .addMinutes(config.minutes)
      .addSeconds(config.seconds)
      .toString();
  }

  getStartUserEveryAccess(config) {
    return XDate()
      .addDays(config.days_to_sum)
      .addHours(config.hours_to_sum)
      .addMinutes(config.minutes_to_sum)
      .addSeconds(config.seconds_to_sum)
      .toString();
  }

  getDecreaseSumTimeUserStart(config) {
    if (this.isEditor) {
      this.setLocalStorage();
    }

    var storedValue = this.getLocalStorage('idTimer');

    if (this.config.type !== 'decrease_sum_time_user_start') { return }

    if (!storedValue) {
      this.setLocalStorage();
      storedValue = localStorage.getItem('idTimer' + (this.isEditor ? '-editor-' : '') + this.id);
    }

    if (storedValue.indexOf('GMT') === -1) {
      let gtm, diffSecondsTotal;

      if (typeof this.config.settings.gmt_save === 'undefined') {
        gtm = this.config.settings.date_finish.substr(this.config.settings.date_finish.indexOf('GMT'), 8);
      } else {
        gtm = this.config.settings.gmt_save;
      }

      storedValue += ' ' + gtm;

      diffSecondsTotal = XDate(storedValue, true).diffSeconds(XDate(true));

      return XDate().addSeconds(diffSecondsTotal * (-1)).toString('yyyy-MM-dd HH:mm:ss');
    } else {
      return storedValue;
    }
  }

  getDefaultType() {
    let date = this.config.settings.original_date_finish;
    let gmtSave = this.config.settings.gmt_save;

    let nowDate = XDate();
    let nowDateGTM0 = XDate(true);
    let gmtNow = nowDate.toString().substr(nowDate.toString().indexOf('GMT'), 8);

    let diffSecondsTotal = XDate(nowDateGTM0).diffSeconds(XDate(nowDate));

    if ((gmtNow !== gmtSave) && this.config.settings.original_date_finish_gmt0) {
      let split = this.config.settings.original_date_finish_gmt0.indexOf('GMT');
      let dateFinishGmt0 = XDate(this.config.settings.original_date_finish_gmt0.substr(0, split));
      let datafinalcomfuso = date = dateFinishGmt0.addSeconds(diffSecondsTotal).toString(); // /data final com o fuso certo

      diffSecondsTotal = XDate(datafinalcomfuso, true).diffSeconds(XDate(true));

      return XDate().addSeconds(diffSecondsTotal * (-1));
    }
    return date;
  }

  treatment() {
    if ((this.config.type === 'decrease_sum_time_user_start' || this.config.type === 'decrease_sum_time' || this.config.type === 'start_user_every_access') && this.isEditor) {
      this.config.settings.save_time = XDate().toString();
    }

    this.config.settings.original_minutes_finish = this.config.minutes;
    this.config.settings.original_hours_finish = this.config.hours;

    this.config.settings.minutes_to_sum = this.config.minutes;
    this.config.settings.days_to_sum = this.config.days;
    this.config.settings.hours_to_sum = this.config.hours;
    this.config.settings.seconds_to_sum = this.config.seconds;

    this.config.settings.original_minutes_finish = this.config.minutes;
    this.config.settings.original_hours_finish = this.config.hours;

    this.config.settings.minutes_to_sum = this.config.minutes;
    this.config.settings.days_to_sum = this.config.days;
    this.config.settings.hours_to_sum = this.config.hours;
    this.config.settings.seconds_to_sum = this.config.seconds;

    if (this.isEditor) {
      if (this.config.theme === 'circles') {
        if (this.config.type === 'decrease_date_end' || this.config.type === 'decrease_sum_time') {
          this.config.settings.date = this.getDefaultType().toString();
        } else if (this.config.type === 'start_user_every_access') {
          this.config.settings.date = this.getStartUserEveryAccess(this.config.settings);
        } else if (this.config.type === 'decrease_sum_time_user_start') {
          this.config.settings.date = this.getDecreaseSumTimeUserStart();
        } else {
          this.config.settings.date = this.getDefaultType().toString();
        }
      }
    }

    if (this.config.theme === 'panels') {
      switch (this.config.type) {
        case 'start_user_every_access':
          this.config.settings.finish = this.getStartUserEveryAccess(this.config.settings);
          break;

        case 'decrease_sum_time_user_start':
          if (this.isEditor) {
            this.config.settings.finish = this.getStartUserEveryAccess(this.config.settings);
          } else {
            this.config.settings.finish = this.getDecreaseSumTimeUserStart();
          }
          break;

        default:

          this.config.settings.date = this.config.settings.original_date_finish;

          this.config.settings.finish = this.getDefaultType();

          break;
      }
    }

    return this.config;
  }

  setStorageConfig(){
    let config = localStorage.getItem('idTimer-saveTime-config-' + (this.isEditor ? 'editor-' : '') + this.id);
    let obj = {
      days: +this.config.settings.days_to_sum,
      hours: +this.config.settings.hours_to_sum,
      minutes: +this.config.settings.minutes_to_sum,
      seconds: +this.config.settings.seconds_to_sum
    };

    if(config === JSON.stringify(obj) && this.config.type === 'decrease_sum_time_user_start')
      return;
    else if (this.config.type === 'decrease_sum_time_user_start' && config !== JSON.stringify(obj)){
      localStorage.setItem('idTimer-saveTime-config-' + (this.isEditor ? 'editor-' : '') + this.id, JSON.stringify(obj));
    }
  }

  setLocalStorage() {
    if (this.config.type === 'decrease_sum_time_user_start') {

      if ((localStorage.getItem('idTimer-saveTime-' + (this.isEditor ? 'editor-' : '') + this.id) === null &&
        localStorage.getItem('idTimer' + (this.isEditor ? '-editor-' : '') + this.id) === null)) {
        let time = XDate()
          .addDays(this.config.settings.days_to_sum)
          .addHours(this.config.settings.hours_to_sum)
          .addMinutes(this.config.settings.minutes_to_sum)
          .addSeconds(this.config.settings.seconds_to_sum)
          .toString();

        localStorage.setItem('idTimer' + (this.isEditor ? '-editor-' : '') + this.id, time);
        localStorage.setItem('idTimer-saveTime-' + (this.isEditor ? 'editor-' : '') + this.id, this.config.settings.save_time);
        localStorage.setItem('idTimer-saveTime-start' + (this.isEditor ? 'editor-' : '') + this.id, XDate().toString());
        this.setStorageConfig();
      } else if (this.getLocalStorage('idTimer') !== null) {

        this.setStorageConfig();

        let saveDate = localStorage.getItem('idTimer-saveTime-' + (this.isEditor ? 'editor-' : '') + this.id);

        if (!(this.config.settings.save_time === saveDate)) {
          let time = XDate()
            .addDays(this.config.settings.days_to_sum)
            .addHours(this.config.settings.hours_to_sum)
            .addMinutes(this.config.settings.minutes_to_sum)
            .addSeconds(this.config.settings.seconds_to_sum)
            .toString();

          localStorage.setItem('idTimer' + (this.isEditor ? '-editor-' : '') + this.id, time);
          localStorage.setItem('idTimer-saveTime-' + (this.isEditor ? 'editor-' : '') + this.id, this.config.settings.save_time);
          localStorage.setItem('idTimer-saveTime-start' + (this.isEditor ? 'editor-' : '') + this.id, XDate().toString());
        }
      }
    }
  }

  build() {
    var settings = {};

    this.setLocalStorage();

    switch (this.config.theme) {
      case 'circles':

        if (this.config.type === 'decrease_date_end' || this.config.type === 'decrease_sum_time') {
          settings.date = this.getDefaultType().toString();
        } else if (this.config.type === 'start_user_every_access') {
          settings.date = this.getStartUserEveryAccess(this.config.settings);
        } else if (this.config.type === 'decrease_sum_time_user_start') {
          settings.date = this.getDecreaseSumTimeUserStart();
        } else {
          settings.date = this.getDefaultType().toString();
        }

        break;

      case 'panels':

        break;

      case 'pill':
      case 'text':
      case 'simple':
      case 'squares':

        var days = this.el.querySelector('[data-timer-element=days]');
        var hours = this.el.querySelector('[data-timer-element=hours]');
        var minutes = this.el.querySelector('[data-timer-element=minutes]');
        var seconds = this.el.querySelector('[data-timer-element=seconds]');

        switch (this.config.type) {
          case 'start_user_every_access':
            settings.finish = this.getStartUserEveryAccess(this.config.settings);
            break;

          case 'decrease_sum_time_user_start':
            settings.finish = this.getDecreaseSumTimeUserStart();
            break;
          case 'decrease_date_end':
            settings.finish = this.config.settings.original_date_finish;
            break;
          default:

            settings.date = this.config.settings.original_date_finish;

            settings.finish = this.getDefaultType();
            break;
        }

        if (!this.isEditor && !days) {
          setTimeout(() => {
            return this.build();
          }, 500);
          return settings;
        }

        this.interval(days, hours, minutes, seconds, settings);

        break;
    }

    return settings;
  }

  interval(days, hours, minutes, seconds, settings, link = '') {
    var self = this;
    this.settings.link = link;
    this.settings.active = true;

    if (XDate(settings.finish, true) > XDate(true)) {
      this.settings.active = true;

      self.setInterval(self, days, hours, minutes, seconds, settings, link);

      this.settings.counter = window.setInterval(function() {
        self.setInterval(self, days, hours, minutes, seconds, settings, link);
      }, 1000);
    } else {
      self.execute();
      days.textContent = 0;
      hours.textContent = 0;
      minutes.textContent = 0;
      seconds.textContent = 0;
    }
  }

  setInterval(self, days, hours, minutes, seconds, settings, link) {
    if (XDate(settings.finish, true) > XDate(true) && self.settings.active) {
      var diffSecondsTotal = XDate(settings.finish, true).diffSeconds(XDate(true));

      settings.finish = XDate(true).addSeconds(diffSecondsTotal * (-1));

      days.textContent = countdown(settings.finish, null, countdown.DAYS | countdown.HOURS | countdown.MINUTES | countdown.SECONDS).days;
      hours.textContent = countdown(settings.finish, null, countdown.DAYS | countdown.HOURS | countdown.MINUTES | countdown.SECONDS).hours;
      minutes.textContent = countdown(settings.finish, null, countdown.DAYS | countdown.HOURS | countdown.MINUTES | countdown.SECONDS).minutes;
      seconds.textContent = countdown(settings.finish, null, countdown.DAYS | countdown.HOURS | countdown.MINUTES | countdown.SECONDS).seconds;

      self.verify(XDate(settings.finish, true));
    } else {
      self.execute();
      days.textContent = 0;
      hours.textContent = 0;
      minutes.textContent = 0;
      seconds.textContent = 0;
    }
  }

  clear() {
    clearInterval(this.settings.counter);
  }

  verify(date) {
    if (date <= XDate(true) && this.settings.active) {
      clearInterval(this.settings.counter);
    }
  }

  execute() {
    this.clear();
    if (this.settings.active) {
      this.settings.active = false;
      if (!this.isEditor && !this.config.callLink) {
        this.config.callLink = true;
        this.timer.$root.$emit('linkAction', this.config.link);
      }
    }
  }
}

export default ApplyConfig;
