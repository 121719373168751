export default {
  data() {
    return {
      props: {
        'tag': '',
        'width': '550px',
        'zIndex': 'auto',
        'maxWidth': '500px',

        'height': '50px',
        'heightContainer': '200px',
        'widthContainer': '300px',
        'leftContainer': '0px',

        'text' : 'title menu',

        'heightHeader': '60px',
        'heightBody': '90px',

        'paddingTop': '15px',
        'paddingLeft': '15px',
        'paddingRight': '15px',
        'paddingBottom': '15px',

        'showDesktop': true,
        'showMobile': true,
        'widthContainerFull': false,

        'border': '0 none #000000',
        'backgroundColor': '#F0F0F0',
        'backgroundColorHover': '#F0F0F0',
        'textColorHover': '#000000',
        'backgroundColorSelected': '#F0F0F0',
        'textColorSelected': '#000000',
        'borderRadius': '0 0 0 0',

        'borderContainer': '0 none #000000',
        'borderRadiusContainer': '0 0 0 0',
        'backgroundColorContainer': '#FFFFFF',

        'textStyle': {
          'family': 'arial, helvetica, sans-serif',
          'size': '15px',
          'sizeMobile': '15px',
          'color': '#000000',
          'align': 'center',
          'weight': '700',
          'style': 'initial',
          'stroke' : 'none'
        },

        'icon': '',
        'iconPosition': 'left',
        bottomMargin: 0,
        leftMargin: 0,
        positionContainer: 'start-menu',
        typeOpenContainer: 'by-click',
        enableOnlyLink: false,
        enableLineBreak: true,
        link: {
          'type': 'web',
          'value': '',
          'target': '_blank'
        },
      },
      elements: [],
      internal_config: {
        'tag': '',
        'height': '50px',
        'width': '550px',
        'zIndex': 'auto',
        'maxWidth': '500px',

        showDesktop: true,
        showMobile: true,

        'widthContainerFull': false,

        'paddingTop': '15px',
        'paddingLeft': '15px',
        'paddingRight': '15px',
        'paddingBottom': '15px',
      },
      name:
        'accordion',
    }
  }
}
