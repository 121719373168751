<template>
  <div :id="this.id"
       class="sitebuilder-element-container big-is-receiver"
       element="GroupMenuComponent"
       :data-hide-desktop="!this.internal_config.showDesktop"
       :data-hide-mobile="!this.internal_config.showMobile"
       :style="style()">

    <div class="vdr">

      <div class="container-menu-group" v-bind:style="{
        borderRadius: radius,
        background: background,
        border : border,
        boxShadow: boxShadow,
        paddingTop : paddingTop,
        paddingLeft : paddingLeft,
        paddingRight : paddingRight,
        paddingBottom : paddingBottom,
        gap: `${horizontalMargin}px`,
        }">
        <component v-for="(element,index) in elements" :is="element.name" :config="element.config" :key="index"
                   :device="device"
                   :is_popup="is_popup"
                   :id="element.id" :height_start="index" :preview="preview" :childs="element.childs"></component>
      </div>
    </div>

  </div>
</template>

<script>

import Data from './Model';

export default {
  name: 'GroupMenuComponent',
  props: ['config', 'childs', 'device', 'id', 'preview', 'is_popup'],
  components: {
    Data
  },
  computed: {
    paddingTop() {
      return this.internal_config.paddingTopContainer;
    },
    paddingLeft() {
      return this.internal_config.paddingLeftContainer;
    },
    paddingRight() {
      return this.internal_config.paddingRightContainer;
    },
    paddingBottom() {
      return this.internal_config.paddingBottomContainer;
    },
    boxShadow() {
      return this.internal_config.boxShadow;
    },
    horizontalMargin() {
      return typeof this.internal_config.horizontalMargin !== 'undefined' ? this.internal_config.horizontalMargin : 0;
    },
    border() {
      return this.internal_config.border;
    },
    radius() {
      return this.internal_config.borderRadius;
    },
    background() {
      let bg = this.internal_config.backgroundColor;

      if (this.internal_config.backgroundImage !== 'none' && typeof this.internal_config.backgroundImage !== 'undefined') {

        this.internal_config.backgroundImage = this.internal_config.backgroundImage.replace('omb11.com', 'storage.builderall.com');

        bg += ' url("' + this.internal_config.backgroundImage + '")';
        bg += ' ' + this.internal_config.backgroundPosition;
        bg += ' / ' + this.internal_config.backgroundSize;
        bg += ' ' + this.internal_config.backgroundRepeat;
        bg += ' ' + this.internal_config.backgroundOrigin;
      }

      return bg;
    },

  },
  data() {
    return Data.data();
  },
  methods: {
    style() {
      return `left:  ${this.internal_config.left};
                top: ${this.internal_config.top};
				        padding-top: ${this.internal_config.paddingTop};
                padding-left: ${this.internal_config.paddingLeft};
                padding-right: ${this.internal_config.paddingRight};
                padding-bottom: ${this.internal_config.paddingBottom};
                z-index: ${this.internal_config.zIndex};
                width: ${this.internal_config.width};
                max-width: ${this.internal_config.maxWidth};
                margin: 0 auto;`;
    },
    build() {
      let vm = this;
      setTimeout(() => {
        if (vm.$el.querySelectorAll('[element]').length === 0) {
          vm.addMenu();
        }

      }, 3000);
    },
  },
  mounted() {

    let device, childs, id, config;
    ({device, childs, id, config} = this.$props);

    if (typeof config.backgroundColor === 'undefined') {
      config.backgroundColor = '#FFFFFF';
    }
    this.internal_config = config;
    this.device = device;

    if (this.internal_config.backDrop) {
      document.getElementById('page-mask').className = 'page-mask-active'
    }

    if (typeof childs === 'undefined') {
      childs = [];
      this.internal_config.childs = [];
    }

    if (typeof id !== 'undefined') {
      this.id = id;
    }

    let objIds = [];
    $('[id]').each(function () {
      var ids = $('[id="' + this.id + '"]');
      if (ids.length > 1 && ids[0] == this && parseInt(this.id))
        objIds.push(this.id);
    });

    if (objIds.includes(this.id)) {
      let newId = Date.now() + parseInt(Math.random() * 10000);
      this.id = newId;

      childs.forEach((el, index) => {
        try {
          childs[index].config.parent_id = newId;
        } catch (e) {
        }
      });

      this.$refs.elem.id = newId;
    }

    this.elements = childs.sort((a, b) => (a.config.order_mobile > b.config.order_mobile) ? 1 : ((b.config.order_mobile > a.config.order_mobile) ? -1 : 0));

    // this.internal_config = window.mergeRecursive(this.props, this.$props.config);
    let internal_config = window.mergeRecursive(this.props, this.$props.config);
    this.internal_config = {...internal_config, ...this.internal_config};

    this.build();
  }
}

</script>
<style lang="scss" scoped>

.card-body {
  position: relative;
}

.sitebuilder-element-container {

  .vdr {
    height: unset !important;

  }
}

[data-device="desktop"] {
  [element="GroupMenuComponent"].zindexBig {
    z-index: 99 !important;
  }

  .container-menu-group {
    display: flex;
  }
}


</style>
<style>
.editor-content-layout-item [element="GroupMenuComponent"]{
  z-index: 999 !important;
}
</style>
