export const apiUrl = () => {
    const domain = window.location.hostname
    if (domain.match(/(builderall\.local)$/)) {

        return 'https://s-checkout.builderall.local'
    } else if(domain.match(/builderall\.com$/)) {
        return 'https://s-checkout.builderall.com'
    } else if(domain.match(/builderall\.io$/)) {
        return 'https://s-checkout.builderall.com'
    } else {
        return window.location.origin + '/sc-bridge'
    }
}
