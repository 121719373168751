<template>
    <div class="flip" v-bind:style="styles.container">
        <div class="flip-clock-slot" v-bind:style="styles.label">{{ $t(`ct-${property}`) }}</div>
        <div class="flip-clock-piece">
      <div class="flip-clock-card flip-card" :style="styles.card">
        <b class="flip-card-top" :style="styles.number">{{ current }}</b>
        <b class="flip-card-bottom" :data-value="current" :style="styles.number"></b>
        <b class="flip-card-back" :data-value="previous" :style="styles.number"></b>
        <b class="flip-card-back-bottom" :data-value="previous" :style="styles.number"></b>
      </div>
      <div class="flip-clock-divider" v-show="property !== 'seconds'" v-bind:style="styles.dotContainer">
        <div class="flip-clock-dot top" v-bind:style="styles.dotTop"></div>
        <div class="flip-clock-dot bottom" v-bind:style="styles.dotBottom"></div>
      </div>
      </div>
    </div>
</template>

<script>
export default {
    name: 'TrackerComponent',
    props: ['property', 'time', 'appearance', 'width', 'height', 'config', 'device'],
    data() {
        return {
            current: 0,
            previous: 0,
            show: false,
            styles: {
                card: {},
                dotTop: {},
                dotBottom: {},
                dotContainer: {},
                number: {},
                label: {},
                size: {},
                container: {}
            }
        };
    },

  watch: {
    'time': {
      handler(newValue) {
        this.changeTimer(newValue);
      },
      deep: true
    },
    'appearance': {
      handler() {
        this.applyStyle();
      },
      deep: true
    },

    'device': {
      handler() {
        this.applyStyle();
      },
      deep: true
    }
  },

  methods: {
    applyStyle() {
        if (this.appearance.width && this.appearance.width < 550 && this.device === 'desktop') {
            let i = this.appearance.width - 10;
            let maxFlip = i / 4;
            let fontSize = maxFlip * 0.47;
            let dotWidth = 20;
            let dotheight = maxFlip * 0.75;
            var dot = 8;
            let topBottom = (dotheight - (2 * dot)) / 2.8;
            let fontLabel = fontSize * 0.26;

            if (i < 420) {
                fontSize = maxFlip * 0.45;
                fontLabel = fontSize * 0.4;
                dotheight = maxFlip * 0.6;
                topBottom = (dotheight - (2 * dot)) / 3;
                dotWidth = 12;
                dot = 6;
            }

            this.styles = {
                card: {fontSize: `${fontSize}px`},
                label: {color: this.appearance.fontColorLabel, fontSize: `${fontLabel}px`},
                container: {minWidth: `${maxFlip}px`, fontSize: `${fontSize}px`},
                dotTop: {
                    background: this.appearance.backgroundColor,
                    width: `${dot}px`,
                    height: `${dot}px`,
                    top: `${topBottom}px`
                },
                dotBottom: {
                    background: this.appearance.backgroundColor,
                    width: `${dot}px`,
                    height: `${dot}px`,
                    bottom: `${topBottom}px`
                },
                dotContainer: {width: `${dotWidth}px`, height: `${dotheight}px`}
            };
        } else {
            this.styles.container = {};
            this.styles.card = {};
            this.styles.label = {color: this.appearance.fontColorLabel};
            this.styles.dotTop = {background: this.appearance.backgroundColor};
            this.styles.dotBottom = {background: this.appearance.backgroundColor};
            this.styles.dotContainer = {};
        }

      this.styles.number = {background: this.appearance.backgroundColor, color: this.appearance.fontColor};
    },
    changeTimer(newValue) {
      if (newValue[this.property] === undefined) {
        this.show = false;
        return;
      }

      var val = newValue[this.property];

      this.show = true;

      val = (val < 0 ? 0 : val);

      if (val !== this.current) {
        this.previous = this.current;
        this.current = val;

        this.$el.classList.remove('flip');
        void this.$el.offsetWidth;
        this.$el.classList.add('flip');
      }
    }
  },

  created() {}
};
</script>

<style lang="scss" scoped>
</style>
