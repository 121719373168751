<template>
</template>
<script>
	export default {
		data() {
			let vm = this;
			return {
				element: null,
				resources: {
					zIndex: {
						name: 'zIndex',
						defaultValue: '',
						labelDesc: 'zIndex',
						callback: function () {
							vm.resources.zIndex.defaultValue = this.value;
							vm.element.internal_config.zIndex = this.value;

						},
					},
					maxWidth: {
						name: 'maxWidth',
						defaultValue: '500',
						min: '200',
						max: '768',
						labelDesc: 'max-width-tablet',
						callback: function () {
						vm.resources.maxWidth.defaultValue = this.value;
						vm.element.internal_config.maxWidth = this.value + 'px';

						},
				
					},
					themeBlog: {
					
					name: 'themeBlog',
					defaultValue: '',
					labelDesc: 'themeBlog',
					options: [
						{ name: vm.$t('ct-blog-feed'), value: 'feed', selected: 'selected', disabled: false},
						{ name: vm.$t('ct-blog-category'), value: 'category', selected: false, disabled: false},
					],
					callback: function() {
						vm.resources.theme.defaultValue = this.value;
						vm.element.internal_config.theme = this.value;
						vm.applyChange(vm.element.internal_config);
						vm.element.rebuild();
						}
					},
					width: {
						name: 'width',
						defaultValue: '300',
						min: '50',
						max: '1000',
						labelDesc: 'width',
						callback: function () {
							vm.resources.width.defaultValue = this.value;
							vm.element.internal_config.width = this.value + 'px';
						},
					},
					border: {
						name: 'border',
						defaultValue: '1px solid black',
						min: '0',
						max: '50',
						labelDesc: 'border',
						callback: function () {
							let value = this.value + 'px ' + this.value2 + ' ' + this.value3;

							vm.resources.border.defaultValue = value;
							vm.element.internal_config.border = value;
						},
					},
					borderRadius: {
						name: 'borderRadius',
						defaultValue: '0 0 0 0',
						min: '0',
						max: '360',
						labelDesc: 'borderRadius',
						callback: function () {

							let value = this.v1 + 'px ' + this.v2 + 'px ' + this.v3 + 'px ' + this.v4 + 'px';

							vm.resources.borderRadius.defaultValue = value;
							vm.element.internal_config.borderRadius = value;
						},
					},
					padding: {
						name: 'padding',
						defaultLeft: '15',
						defaultTop: '15',
						defaultRight: '15',
						defaultBottom: '15',
						min: '0',
						max: '50',
						labelDesc: 'padding',
						callback: function () {
							vm.resources.padding.defaultLeft = this.l;
							vm.element.internal_config.paddingLeft = this.l + 'px';

							vm.resources.padding.defaultTop = this.t;
							vm.element.internal_config.paddingTop = this.t + 'px';

							vm.resources.padding.defaultRight = this.r;
							vm.element.internal_config.paddingRight = this.r + 'px';

							vm.resources.padding.defaultBottom = this.b;
							vm.element.internal_config.paddingBottom = this.b + 'px';
						},
					},
				}
			}
		},
		methods: {
			setMinValuesData(resource, value) {
				this.resources[resource].min = parseInt(value);
			},
			setValuesData(data) {
				this.element = data;
        		this.resources.maxWidth.defaultValue = parseInt(data.internal_config.maxWidth);
				
				this.resources.themeBlog.defaultValue = data.internal_config.themeBlog;	

				this.resources.width.defaultValue = parseInt(data.internal_config.width);
				this.resources.border.defaultValue = data.internal_config.border;
				this.resources.borderRadius.defaultValue = data.internal_config.borderRadius;
				this.resources.padding.defaultLeft = parseInt(data.internal_config.paddingLeft);
				this.resources.padding.defaultTop = parseInt(data.internal_config.paddingTop);
				this.resources.padding.defaultRight = parseInt(data.internal_config.paddingRight);
				this.resources.padding.defaultBottom = parseInt(data.internal_config.paddingBottom);
			},
			getValuesData() {
				return this.resources;
			}
		},
	}
</script>
