import axios from 'axios'
let url = (process.env.NODE_ENV === 'development') || location.hostname === "website.localhost" || location.hostname === "127.0.0.1" ? 'http://127.0.0.1:8000/api/' : 'https://cheetah-api.builderall.com/api/';

const http = axios.create({
    baseURL: url,
    headers: {
        'Accept': 'application/json',
        'Content': 'application/json'
    }
});

export default http
