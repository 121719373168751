export default {
  data() {
    return {
      props: {
        'tag': '',
        'align': 'left',
        'zIndex': 'auto',
        'rotation': '0deg',
        'maxWidth': '768px',
        'vertical': false,

        'itemEdition': {
          'name': 'contact',
          'icon': {},
          'children': [],
          'link': {
            'type': 'web',
            'value': '',
            'target': '_blank',
            'targetID': ''
          },
        },
        'subitemEdition': {
          'name': '',
          'icon': {},
          'link': {
            'type': 'web',
            'value': '',
            'target': '_blank',
            'targetID': ''
          }
        },

        'height': '50px',
        'width': '150px',
        'fullWidth': false,

        'spacing': '0',
        'itemMenuHeight': 40,
        'itemMenuWidth': 80,
        'itemMenuBorder': '0px solid black',
        'itemMenuBorderRadius': '0 0 0 0',

        'border': '0 none #000000',
        'borderRadius': '0 0 0 0',
        'backgroundColor': '#21309b',
        'boxShadow': '1px 1px 1px 1px blue',

        'paddingTop': '15px',
        'paddingLeft': '15px',
        'paddingRight': '15px',
        'paddingBottom': '15px',

        'itemMenuBoxShadow': '0 0 0 0 black initial',
        'itemMenuBox3D': false,

        'btnStyle': {
          'family': 'arial, helvetica, sans-serif',
          'size': '15px',
          'color': '#FFFFFF',
          'colorHover': '',
          'align': 'center',
          'weight': '700',
          'style': 'initial',

          'height': '50px',
          'width': '50px',

          'backgroundColor': '#21309b',
          'backgroundColorActive': '#65a5c2',
          'backgroundColorHover': '#21309b',

        },
        'itemMenuSubBorder': '0px solid black',
        'subStyle': {
          'family': 'arial, helvetica, sans-serif',
          'size': '15px',
          'color': '#FFFFFF',
          'colorHover': '',
          'align': 'center',
          'weight': '700',
          'style': 'initial',


          'backgroundColor': '#21309b',
          'backgroundColorActive': '#65a5c2',
          'backgroundColorHover': '#21309b',

        },
        'itemList': [
          {
            'name': 'home',
            'icon': {},
            'iconPosition': 'left',
            'children': [],
            'link': {
              'type': 'web',
              'value': '',
              'target': '_blank',
              'targetID': ''
            },
          },
          {
            'name': 'contact',
            'icon': {},
            'iconPosition': 'left',
            'children': [],
            'link': {
              'type': 'web',
              'value': '',
              'target': '_blank',
              'targetID': ''
            },
          },
          {
            'name': 'dropdown',
            'icon': '',
            'iconPosition': 'left',
            'children': [
              {
                'name': 'item-dropdown',
                'icon': '',
                'iconPosition': 'left',
                'link': {
                  'type': 'web',
                  'value': '',
                  'target': '_blank',
                  'targetID': ''
                },
              },
              {
                'name': 'item-dropdown-2',
                'icon': '',
                'iconPosition': 'left',
                'link': {
                  'type': 'web',
                  'value': '',
                  'target': '_blank',
                  'targetID': ''
                },
              },
            ],
            'link': {
              'type': 'web',
              'value': '',
              'target': '_blank',
              'targetID': ''
            },
          },

        ],
        'animation': {
          'in': {
            'action': 'time',   //time/scroll/click/hover
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'speed': 'slow',
            'infinite': false,
          },
          'out': {
            'action': 'time',   //time/scroll/click
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'delay': '1',
            'speed': '1',
            'infinite': false,
          },
        },
      },
      internal_config: {
        'zIndex': 'auto',
        'rotation': '0deg',
        'maxWidth':'768px',
        width: '150px',
        height: '50px',
        left: '0px',
        top: '0px',
        align: 'left',
        showDesktop: true,
        showMobile: true,
        fieldAnimated: false,
        menuMobileEffect: '',
        fullWidth: false,
        opacity: 1,
        boxShadow: '0 0 0 0 black initial',
        borderRadius: '0 0 0 0',
        'paddingTop': '15px',
        'paddingLeft': '15px',
        'paddingRight': '15px',
        'paddingBottom': '15px',
        'burguerMenu': {
          position: 'left',
          icon: 'mdi-menu',
          showCloseIcon: true,
          displayCloseIcon: false,
          backgroundColor: '#000',
          color: '#fff'
        },
        'brand': {
          image: null,
          position: 'left',
          width: '100px'
        },
        'itemMenuBorder': '0px solid black',
        'itemMenuBorderRadius': '0 0 0 0',
        'itemMenuBox3D': false,
        'itemMenuBoxShadow': '0 0 0 0 black initial',
        'itemMenuHeight': 40,
        'itemMenuWidth': 100,
        'itemEdition': {
          'name': '',
          'icon': {},
          'children': []
        },
        'subitemEdition': {
          'name': '',
          'icon': {},
          'link': {
            'type': 'web',
            'value': '',
            'target': '_blank',
            'targetID': ''
          }
        },
        textStyle: {
          'family': 'Arial',
          'size': '15px',
          'color': 'White',
          'align': 'center',
          'weight': '700',
          'style': 'initial',
        },
        'itemMenuSubBorder': '0px solid black',
        subTextStyle: {
          'family': 'Arial',
          'size': '15px',
          'color': 'White',
          'align': 'center',
          'weight': '400',
          'style': 'initial',
        },
        'link': {
          'type': 'web',
          'value': '',
          'target': '_blank'
        },
      },
      name:
          'menu',
    }
  }
}
