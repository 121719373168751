<template>

<div>


<section>
	<div v-for="post in options.posts" :key="post.number" :style="{'background-image': 'url(' + 'https://omb11.com' + post.thumbnail + ')'}"  class="blog-card web">
		<div  class="content-mask">
			<span v-for="category in post.categories_list" :key="category.id" :style="{'background': options.colorDetails}" class="category">{{ category }}</span>
			<h1 class="clean-text-css" :style="options.titleStyle">Improving Website Performance</h1>
			<p  :style="options.contentStyle"  v-html="post.content.substr(0, 300).toLowerCase()"  class="clean-text-css">A practical guide to speeding up your website loading-times</p>
			<div class="post-detail">
				<span class="icon"><svg class="svg-inline--fa fa-calendar-alt fa-w-14" aria-hidden="true" data-prefix="far" data-icon="calendar-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M148 288h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm108-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 96v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm192 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96-260v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48zm-48 346V160H48v298c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"></path></svg></span>
        <span class="date"> {{ post.date_show }}  </span>
			</div>
		</div>
		<div class="horizontal"></div>
	</div>

</section>

</div>
  
</template>

<script>

import moment from 'moment'

export default {
    props: ['options'],
    name: 'ThemeHxagon',
    watch:{
      options(){
        let root = document.documentElement;
        root.style.setProperty('--bg-color-blog',  this.options.cardBgColor);
        root.style.setProperty('--bg-color-blog-gradient', 'linear-gradient(to top right, ' + this.options.cardBgColor + ' 50%, transparent 0)');
      }
    },
    methods: {
      calcDate(post){
        if(post.dripping_total_seconds > 0){
          return moment(post.created_at).add(post.dripping_total_seconds, 'seconds').calendar();
        }else{
          return post.date_show;
        }
      }
    },
    computed: {
      showDate(){
        return moment().add(1, 'days').calendar();
      }
    }
}
</script>

<style scoped>

* {
  -webkit-transition: all .35s ease-in-out;
  transition: all .35s ease-in-out;
}

.blog-card {
  display: block;
  position: relative;
  background: #444;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat !important;
  width: 85%;
  max-width: 700px;
  height: 380px;
  box-shadow: 4px 8px 28px rgba(39, 44, 49, 0.1), 1px 3px 8px rgba(39, 44, 49, 0.075);
  margin: 30px;
  overflow: hidden !important;
}
.blog-card:hover {
  box-shadow: 0 0 1px rgba(39, 44, 49, 0.2), 0 3px 16px rgba(39, 44, 49, 0.25);
  -webkit-transform: translate3D(0, -0.5px, 0);
          transform: translate3D(0, -0.5px, 0);
}
.blog-card .content-mask {
  display: inline-block;
  background:  var(--bg-color-blog);
  width: 61%;
  height: 101%;
  padding: 0.75em 0 0.75em 2.5em;
  overflow: hidden;
  z-index: 5;
}
.blog-card .horizontal {
  display: inline-block;
  position: relative;
  /* background: -webkit-gradient(linear, left bottom, right top, color-stop(50%, rgba(255, 255, 255, 0.9)), color-stop(0, transparent)); */
  background: var(--bg-color-blog-gradient);
  height: 101%;
  width: 20%;
  top: -5%;
  right: 0.8%;
  overflow: hidden;
}

.blog-card.python {
  background-image: url("https://sg.fiverrcdn.com/photos/115522316/original/0f1ff5d0ae8e6d044d936feaa91630dcec3a0941.jpg?1533894592");
}
.blog-card.web {
  background: url("https://www.hostinger.com/tutorials/wp-content/uploads/sites/2/2016/12/improving-website-performance-using-a-content-delivery-network-cdn.jpg");
}

.content-container {
  margin-right: -5vw;
}

.category {
  display: inline-block;
  background: #e23f7e;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
  letter-spacing: 1px;
  text-align: center;
  min-width: 45px;
  border-radius: 5px;
  box-shadow: 0 0 1px rgba(172, 28, 56, 0.1), 0 2px 6px rgba(172, 28, 56, 0.175);
  margin: 3vh 2px 2.5vh;
  padding: 6px 8px;
  cursor: pointer;
  z-index: 9;
}
.category.python {
  background: #2a9d5a;
}
.category:hover {
  box-shadow: 0 0 1px rgba(10, 96, 58, 0.2), 0 3px 6px rgba(39, 44, 49, 0.25);
}

h1 {
  background: transparent;
  color: rgba(0, 0, 0, 0.9);
  font-family: 'Nunito', 'Open Sans', sans-serif;
  font-size: 2.5em;
  font-weight: 800;
  line-height: 1.2;
  height: auto;
  border-bottom: 2px solid rgba(204, 204, 204, 0.75);
  margin-top: 0;
  margin-bottom: 0.35em;
  padding-bottom: 5px;
}

p {
  font-size: 1.1em;
  line-height: 1.4;
  width: 100%;
  margin-top: 0.3em;
  padding-left: 2px;
  z-index: 9;
}

.post-detail {
  color: rgba(0, 0, 0, 0.55);
  margin-top: 6.5vh;
  padding-left: 2px;
  vertical-align: middle;
}
.post-detail .icon svg {
  position: relative;
  width: 14px;
  top: 2.25px;
  margin-right: 7px;
}
.post-detail .date {
  color: rgba(0, 0, 0, 0.65);
  font-size: 0.85em;
}

.credit {
  position: relative;
  font-family: 'Nunito' Helvetica, sans-serif;
  font-size: 1.5em;
  font-weight: 200;
  text-align: center;
  bottom: 0.75em;
  margin-bottom: 10vh;
}
.credit .creator-link {
  color: #000;
  font-family: "Kaushan Script", "Lato", Helvetica, sans-serif;
  font-size: 1.05em;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-decoration: none;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}
.credit .creator-link:hover {
  color: #FFF;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.6);
  -webkit-transition: all 350ms linear;
  transition: all 350ms linear;
}

</style>