<template>
    <div :id="this.id"
         class="sitebuilder-element-container"
         element="ButtonUserComponent"
         :data-hide-desktop="!this.internal_config.showDesktop"
         :data-hide-mobile="!this.internal_config.showMobile"
         :style="style()">
        <div class="container-button-members-area">
            <div v-bind:style="{height: height, width : width,border : getButtonStyle('border'),'border-radius': getButtonStyle('borderRadius'),background: getButtonStyle('backgroundColor'),'box-shadow': getButtonStyle('boxShadow')}"
                 class="btn-members-area" :class="[internal_config.actionsformat, (isAuthenticated ? 'is-auth': '')]">
                <template v-if="isAuthenticated">

                    <div class="btn-members-area-text" :class="{'btn-member-dropdown' : parseInt(internal_config.withDropdown) }" :style="textButtonStyle()" @click.prevent="toggleDropdown()"
                         v-if="parseInt(internal_config.withDropdown)">
                       <span @click.prevent="goToMemberPage()">
                         <template
                                 v-if="internal_config.iconRegards && internal_config.iconRegards.icon && internal_config.iconRegards.position === 'left'">
                             <i :class="['mdi', internal_config.iconRegards.icon]"></i>
                         </template>
                           <template v-if="typeAuthentication && typeAuthentication === 'token'">
                               {{ internal_config.textRegards || $t('ct-hello') }}
                           </template>
                           <template v-else>
                               {{(parseInt(internal_config.withRegards)) ? internal_config.textRegards || $t('ct-hello') : ''}}&nbsp{{ website_user.name }}
                           </template>
                         <template
                                 v-if="internal_config.iconRegards && internal_config.iconRegards.icon && internal_config.iconRegards.position === 'right'">
                             <i :class="['mdi', internal_config.iconRegards.icon]"></i>
                         </template>
                        </span>
                        <i class="mdi mdi-chevron-down float-right icon-member-dropdown"></i>
                    </div>

                    <template v-else>
                        <div class="btn-members-area-text" :style="textButtonStyle()"
                             @click.prevent="goToMemberPage()">
                  <span>
                    <template
                            v-if="internal_config.iconRegards.icon && internal_config.iconRegards.position === 'left'"><i
                            :class="['mdi', internal_config.iconRegards.icon]"></i></template
                    >{{(parseInt(internal_config.withRegards)) ? internal_config.textRegards || $t('ct-hello') : ''}}&nbsp{{ website_user.name }}<template
                          v-if="internal_config.iconRegards.icon && internal_config.iconRegards.position === 'right'"><i
                          :class="['mdi', internal_config.iconRegards.icon]"></i></template>
                  </span>
                        </div>

                        <div class="btn-members-area-text" :style="textButtonStyle()" @click.prevent="logout()">
                  <span>
                    <template v-if="internal_config.iconLogout.icon && internal_config.iconLogout.position === 'left'">
                      <i :class="['mdi', internal_config.iconLogout.icon]"></i>
                    </template
                    >{{ internal_config.textLogout || this.$t('ct-logout')}}<template
                          v-if="internal_config.iconLogout.icon && internal_config.iconLogout.position === 'right'"><i
                          :class="['mdi', internal_config.iconLogout.icon]"></i>
                    </template>
                  </span>
                        </div>
                    </template>
                </template>

                <template v-else>
                    <div class="btn-members-area-text" :style="textButtonStyle()"
                         v-show="['login-register', 'login'].includes(internal_config.actionsformat)"
                         @click.prevent="login()">
                  <span>
                    <template v-if="internal_config.iconLogin.icon && internal_config.iconLogin.position === 'left'"><i
                            :class="['mdi', internal_config.iconLogin.icon]"></i></template
                    >{{ internal_config.textLogin || this.$t('ct-login')}}<template
                          v-if="internal_config.iconLogin.icon && internal_config.iconLogin.position === 'right'"><i
                          :class="['mdi', internal_config.iconLogin.icon]"></i></template>
                  </span>
                    </div>
                    <!-- <div>|</div> -->
                    <div class="btn-members-area-text" :style="textButtonStyle()"
                         v-show="['login-register', 'register'].includes(internal_config.actionsformat)"
                         @click.prevent="register()">
                  <span>
                    <template
                            v-if="internal_config.iconRegister.icon && internal_config.iconRegister.position === 'left'">
                      <i :class="['mdi', internal_config.iconRegister.icon]"></i>
                    </template
                    >{{(internal_config.textRegister && internal_config.textRegister.length > 0 ? internal_config.textRegister : this.$t('ct-register'))}}<template
                          v-if="internal_config.iconRegister.icon && internal_config.iconRegister.position === 'right'"><i
                          :class="['mdi', internal_config.iconRegister.icon]"></i>
                    </template>
                  </span>
                    </div>
                </template>
            </div>
            <div v-show="internal_config.menuDropdown" class="ma-menu-dropdown"
                 :style="{ width : width, border : getDropdownStyle('border'), 'box-shadow': getDropdownStyle('boxShadow'), 'border-radius': getDropdownStyle('borderRadius'), backgroundColor: getDropdownStyle('backgroundColor')}">
                <nav>
                    <a :style="textDropdownStyle()" href="#" @click.prevent="logout" class="ma-dropdown-item">{{ internal_config.textLogout || this.$t('ct-logout')}}</a>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>

    import Data from './Model';
    import {mapGetters} from "vuex";

    export default {
        name: 'ButtonUserComponent',
        props: ['config', 'id', 'device'],
        data() {
            return Data.data();
        },
        computed: {
            ...mapGetters(['isAuthenticated','typeAuthentication', 'userWebsite', 'userWebsiteName']),
            width() {
                return this.internal_config.width;
            },
            height() {
                return this.internal_config.height;
            },
            border() {
                return this.internal_config.border;
            },
            boxShadow() {
                return this.internal_config.boxShadow;
            },
            radius() {
                return this.internal_config.borderRadius;
            },
            background() {
                return this.internal_config.backgroundColor
            }
        },
        watch: {
            'device' :function (){
                // console.log('device change', this.device);
            },
            'userWebsite.user' : function(user){
                this.website_user = Object.assign({}, this.website_user, user);
            },
            'typeAuthentication' : function (type){
                this.typeAuth = type;
            }
        },
        methods: {

            getButtonStyle(config){
                return this.internal_config.buttonStyle[config];
            },

            getDropdownStyle(config){
                return this.internal_config.dropdownStyle[config];
            },

            formatText(textStyle){
                return {
                    fontFamily: textStyle.family,
                    fontSize: (this.device !== 'mobile' ? textStyle.size : textStyle.sizeMobile),
                    'text-align': textStyle.align,
                    'font-weight': textStyle.weight,
                    'font-style': textStyle.style,
                    'text-decoration': textStyle.stroke,
                    color: textStyle.color
                };
            },

            textDropdownStyle() {
                let textStyle = this.formatText(this.internal_config.dropdownStyle.textStyle);
                return {
                    borderRadius: this.internal_config.dropdownStyle.borderRadius,
                    ...{...textStyle}
                };
            },

            textButtonStyle() {
                let textStyle = this.formatText(this.internal_config.buttonStyle.textStyle);
                return {
                    borderRadius: this.internal_config.buttonStyle.borderRadius,
                    ...{...textStyle}
                };
            },
            style() {
                return `
                    left: ${this.internal_config.left};
                    paddingTop: ${this.internal_config.paddingTop};
                    padding-left: ${this.internal_config.paddingLeft};
                    z-index: ${this.internal_config.zIndex};
                    max-width: ${this.internal_config.maxWidth};
                    margin: 0 auto;
                    padding-right: ${this.internal_config.paddingRight};
                    padding-bottom: ${this.internal_config.paddingBottom};
                    top: ${this.internal_config.top}`;
            },
            styleResize() {
                return `{width: ${this.internal_config.width}, height:  ${this.internal_config.height}}`;
            },

            toggleDropdown(){
                var value = !this.internal_config.menuDropdown;
                this.internal_config = Object.assign({}, this.internal_config, { menuDropdown : value });
            },

            goToMemberPage(){
                let memberPage = pagesConfig.find((page, index) => page !== null && typeof page.is_member !== 'undefined' && parseInt(page.is_member));

                if (memberPage) {
                   window.location.href = `${window.origin}/${memberPage.url}`
                }
            },

            login() {
                this.$root.$emit('linkAction', this.internal_config.links.linkLogin);
            },

            register() {
                this.$root.$emit('linkAction', this.internal_config.links.linkRegister);
            },

            logout() {
                this.$root.$emit('linkAction', this.internal_config.links.linkLogout);
            }
        },
        created() {
            this.internal_config = Object.assign({}, this.internal_config, window.mergeRecursive(this.props, this.$props.config));

            this.internal_config.menuDropdown = false;
            this.website_user.name = this.userWebsiteName;
            this.typeAuth = this.typeAuthentication;
        },

        mounted() {
            let vm = this;
            this.$root.$emit('applyAnimation', this, 'sitebuilder-element-container');

            this.$nextTick(() => {
                this.$el.querySelectorAll('.btn-members-area-text').forEach(function (element) {
                    element.addEventListener('mouseover', function () {
                        document.body.style.setProperty('--button-user-background-color--hover', vm.internal_config.buttonStyle.backgroundColorHover);
                        document.body.style.setProperty('--button-user-text-color--hover', vm.internal_config.buttonStyle.textColorHover);
                    });
                });

                this.$el.querySelectorAll('.ma-dropdown-item').forEach(function (element) {
                    element.addEventListener('mouseover', function () {
                        document.body.style.setProperty('--dropdown-user-background-color--hover', vm.internal_config.dropdownStyle.backgroundColorHover);
                        document.body.style.setProperty('--dropdown-user-text-color--hover', vm.internal_config.dropdownStyle.textColorHover);
                    });
                });
            });
        }
    };
</script>

<style scoped lang="scss">
    .container-button-members-area {
        display: inline-flex;
        flex-direction: column;
        width: 100%;

        .ma-menu-dropdown {
            display: flex;
            flex-direction: column;

            &:hover{
                background-color: var(--dropdown-user-background-color--hover) !important;

                .ma-dropdown-item {
                    color: var(--dropdown-user-text-color--hover) !important;
                }
            }

            .ma-dropdown-item {
                display: block;
                width: 100%;
                padding: .25rem 1.5rem;
                font-weight: 400;
                text-align: inherit;
                white-space: nowrap;
                transition: 500ms all;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .ma-dropdown-divider {
                height: 0;
                margin: .25rem 1rem;
                overflow: hidden;
                border-top: 1px solid #e9ecef;
            }
        }
    }

    .btn-members-area {
        display: inline-flex;
        flex-direction: row;

        &.register:not(.is-auth), &.login:not(.is-auth) {
            .btn-members-area-text {
                min-width: 100% !important;
                max-width: 100% !important;
            }
        }

        &.is-auth{
            .btn-member-dropdown{
                width: 100%;
            }
            .btn-members-area-text:not(.btn-member-dropdown):first-child{
                flex: 2;
                max-width: 65%;
            }
            .btn-members-area-text:not(.btn-member-dropdown):last-child{
                flex: 1;
                /*padding: 0 5px;*/
                max-width: 35%;
            }
        }

        &:not(.is-auth){
            .btn-members-area-text{
                max-width: 50%;
            }

            .btn-members-area-text {
                &:not(.btn-member-dropdown) {

                    span {
                        max-width: 100%;
                    }
                }
            }
        }

        .btn-members-area-text {
            display: inline-flex;
            cursor: pointer;
            flex: 1;
            height: 100%;
            padding-right: 10px;
            padding-left: 10px;
            justify-content: space-between;
            align-items: center;

            &:hover{
                background-color: var(--button-user-background-color--hover) !important;
                color: var(--button-user-text-color--hover) !important;
            }

            .icon-member-dropdown{
                min-width: 10%;
                text-align: right;
            }

            &:first-child:not(.btn-member-dropdown){
                span{
                    max-width: 100%;
                }
            }
            span {
                display: table-cell;
                flex: 1;
                vertical-align: middle;
                max-width: 90%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: inherit;
                i {
                    margin-right: 5px;
                    margin-left: 5px;
                }
            }
        }
    }

    [data-device="mobile"] {

        [element="ButtonUserComponent"] .btn-members-area {
            width: 100% !important;
            height: auto !important;
        }

        .container-button-members-area .ma-menu-dropdown{
            width: 100% !important;
        }


        [element="ButtonUserComponent"] .btn-members-area-text {
            padding: 6px 0;
        }
    }

</style>
