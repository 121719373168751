<template>
    <div :id="this.id"
         class="sitebuilder-element-container card big-is-receiver"
         element="VideoGalleryComponent"
         :data-hide-desktop="!this.internal_config.showDesktop"
         :data-hide-mobile="!this.internal_config.showMobile"
         :style="style()">

        <div class=" row-gallery" v-bind:style="{
      border:border,
      background: background,
      borderRadius: radius,
    }">

            <div class="col-gallery" v-bind:style="{width:width}">
                <iframe
                        :src="srcVideo"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                        class="embed-responsive-item video-margin"
                ></iframe>
            </div>

            <div class="sitebuilder-element-receiver col-gallery" v-bind:style="{width:widthPlace}">
                <component v-for="(element,index) in elements" :is="element.name" :config="element.config" :key="index"
                           :id="element.id" :height_start="index" :preview="preview" :childs="element.childs"
                           @setCallbackChangeDevice="setCallbackChangeDevice"></component>
            </div>

            <i @click="closeThis" :class="'mdi close-icon ' + internal_config.icon"
               v-bind:style="{fontSize: fontSize, color: color}"></i>
        </div>

    </div>
</template>

<script>

	import Data from './Model';

	export default {
		name: 'VideoGalleryComponent',
		props: ['config', 'childs', 'height_start', 'device', 'id', 'preview', 'url', 'thumbnail'],
		components: {
			Data,
		},
		computed: {
			border() {
				return this.internal_config.border;
			},
			width() {
				return this.internal_config.width;
			},
			widthPlace() {
				return (100 - parseInt(this.internal_config.width)) + '%';
			},
			radius() {
				return this.internal_config.borderRadius;
			},
			fontSize() {
				return this.internal_config.fontSize;
			},
			color() {
				return this.internal_config.color;
			},
			background() {
				return this.internal_config.backgroundColor;
			},
			heightContainer() {
				return this.internal_config.heightContainer;
			},
			srcVideo() {

				try {
					let url = this.internal_config.videoData.url || '';

					if (typeof url !== undefined || url !== "") {
						if (url.indexOf('vidello') !== -1) {
							return url
						} else {

							//vimeo
							let matchVimeo = url.match(/(videos|video|channels|\.com)\/([\d]+)/);
							if (matchVimeo) {
								return "https://player.vimeo.com/video/" + matchVimeo[2] + "?";
							} else {
								let matchWistia = url.match(/https?:\/\/(.+)?(wistia\.com|wi\.st)\/(medias|embed)\/(.*)/);
								if (matchWistia) {
									return "https://fast.wistia.com/embed/medias/" + matchWistia[4];
								} else {
									let match = url.match(/^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/);
									if (match && match[2].length == 11) {
										return "https://www.youtube.com/embed/" + match[2] + "?";
									} else {
										return url;
									}
								}
							}
						}
					}
				} catch (e) {

				}
			}
		},
		data() {
			return Data.data();
		}
		,
		methods: {
			style() {
				return `left:  ${this.internal_config.left};
                top: ${this.internal_config.top};
				        padding-top: ${this.internal_config.paddingTop};
                padding-left: ${this.internal_config.paddingLeft};
                padding-right: ${this.internal_config.paddingRight};
                padding-bottom: ${this.internal_config.paddingBottom};
                z-index: ${this.internal_config.zIndex};
                margin: 0 auto;`;
			},
			closeThis() {

				$("iframe").each(function () {
					let src = $(this).attr('src');
					$(this).attr('src', src);
				});

				$(this.$el).hide();
				$('.container-gallery-video').hide();

				this.$parent.alterHeight();
			},
			setCallbackChangeDevice(values) {
				this.$emit('setCallbackChangeDevice', values);
			}
		},
		mounted() {

			let device, childs, id, config;
			({device, childs, id, config} = this.$props);

			if (typeof config.backgroundColor === 'undefined') {
				config.backgroundColor = '#FFFFFF';
			}
			this.internal_config = config;
			this.device = device;

			if (typeof childs === 'undefined') {
				childs = [];
				this.internal_config.childs = [];
			}

			if (typeof id !== 'undefined') {
				this.id = id;
			}


			this.elements = childs.sort((a, b) => (a.config.order_mobile > b.config.order_mobile) ? 1 : ((b.config.order_mobile > a.config.order_mobile) ? -1 : 0));

			// this.internal_config = window.mergeRecursive(this.props, this.$props.config);
			let internal_config = window.mergeRecursive(this.props, this.$props.config);
			this.internal_config = {...internal_config, ...this.internal_config};

			let videoData = this.$parent.getDataVideo(this.id);

			if (typeof videoData === 'undefined') {
				document.getElementById(this.id).parentNode.removeChild(document.getElementById(this.id));
			}

			this.internal_config.videoData = videoData;

		}
	}

</script>
<style lang="scss" scoped>

    .card-body {
        position: relative;
    }

    /*.sitebuilder-element-container.card {*/
    /*  height: auto !important;*/
    /*  width: 100% !important;*/
    /*  top: 0 !important;*/
    /*  left: 0 !important;*/
    /*  position: relative;*/
    /*  background-color: transparent !important;*/
    /*}*/

    [element="VideoGalleryComponent"] {
        border: none;
    }

    [data-device="desktop"] [element="VideoGalleryComponent"] {
        position: absolute;
    }

    [data-device="mobile"] .sitebuilder-element-receiver.card-body {
        height: auto !important;
    }

    .card-header {
        padding: 0 !important;
    }

    .row-gallery {
        width: 100% !important;
        height: 100% !important;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        top: 0;
        left: 0;

        .col-gallery {
            display: flex;
            flex-direction: column;
            width: 50%;
            height: 100% !important;
            position: relative;

            .video-margin {
                margin: auto;
                flex-grow: 1;
                border: none;
                padding: 0;
                max-height: 75%;
                max-width: 90%;
                width: 90%;
            }
        }
    }

    [data-device="mobile"] .row-gallery {
        width: 100% !important;
        height: 100% !important;
        display: block;
        flex-direction: initial;
        top: 0;
        left: 0;

        .col-gallery {
            display: block;
            flex-direction: initial;
            width: 100% !important;

            .video-margin {
                max-width: 100%;
                width: 100%;
            }
        }
    }

    [data-device="tablet"] {
        iframe.video-margin {
            height: 425px;
        }
    }

    .close-icon {
        position: absolute;
        right: 5px;
        top: 0;
        cursor: pointer;

        &:hover {
            opacity: 0.7;
        }
    }

</style>
