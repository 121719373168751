const MixinComponents = {
	computed: {
		width() {
			return this.internal_config.width;
		},
		height() {
			return this.internal_config.height;
		},
		radius() {
			return this.internal_config.borderRadius;
		},
		boxShadow() {
			return this.internal_config.boxShadow;
		},
		border() {
			if (typeof this.internal_config.border !== 'undefined') {
				let splitedBorder = this.internal_config.border.split(' ');

				if (typeof splitedBorder[2] !== 'undefined' && this.$globalStoreHelpers.isValidHex(splitedBorder[2])) {
					splitedBorder[2] = this.$globalStoreHelpers.hexToRGBA(splitedBorder[2]);
					this.internal_config.border = splitedBorder.join(' ');
				}
			}
			return this.internal_config.border;
		},
	},
	methods: {
		dataTag() {
			if (this.internal_config.tag) {
				return this.internal_config.tag;
			}
			return '';
		},
	}
};

export default MixinComponents;
