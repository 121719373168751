<template>
    <div :id="this.id"
         class="sitebuilder-element-container"
         element="ContentNavUserComponent"
         :data-hide-desktop="!this.internal_config.showDesktop"
         :data-hide-mobile="!this.internal_config.showMobile"
         :style="style()">
        <div class="sitebuilder-element-container container-nav-user" v-if="isAuthenticated && typeAuth && typeAuth === 'login'"
             v-bind:style="{height: height, width : width,border: border,'border-radius': radius,background: background,'box-shadow': boxShadow}">
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item" v-for="(tab, index) in internal_config.visibleTabs">
                    <a class="nav-link" @click.prevent="changeTab(tab)"
                       :class="{ 'active': internal_config.activeTab === tab}" :id="`pills-${tab}-tab`"
                       :style="getStyleNav(internal_config.activeTab === tab)" data-toggle="pill"
                       :href="`#pills-${tab}`" role="tab"
                       aria-controls="pills-profile" aria-selected="false">
                        <template v-if="parseInt(internal_config.iconsEnable) && tab === 'profile'"><i
                                class="mdi mdi-account"></i></template>
                        <template v-if="parseInt(internal_config.iconsEnable) && tab === 'areas'"><i
                                class="mdi mdi-folder-lock"></i></template>
                        {{ $t(`ct-tab-${tab}`)}}
                    </a>
                </li>
            </ul>
            <div class="tab-content" id="pills-tabContent" :style="getStyleNavContent()">

                <div class="tab-pane fade" :class="{ 'show active': internal_config.activeTab === tab}"
                     :id="`pills-${tab}`"
                     v-for="(tab, index) in internal_config.visibleTabs" :aria-labelledby="`pills-${tab}-tab`">
                    <keep-alive>
                        <component :is="`${tab}-tab-component`" :buttonStyle="getStyleNav('active')"></component>
                    </keep-alive>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

    import Data from './Model';
    import ProfileTabComponent from './Profile';
    import AreasTabComponent from './Areas';
    import {mapGetters} from "vuex";
    import {mixinRestrictArea} from "../../../../js/mixins/restrictArea";

    export default {
        name: 'ContentNavUserComponent',
        props: ['config', 'id', 'device'],
        mixins: [mixinRestrictArea],
        components: {
            ProfileTabComponent,
            AreasTabComponent
        },
        computed: {
            ...mapGetters(['isAuthenticated', 'typeAuthentication']),
            width() {
                return this.internal_config.width;
            },
            height() {
                return this.internal_config.height;
            },
            border() {
                return this.internal_config.tabContent.border;
            },
            boxShadow() {
                return this.internal_config.tabContent.boxShadow;
            },
            radius() {
                return this.internal_config.tabContent.borderRadius;
            },
            background() {
                return this.internal_config.tabContent.backgroundColor;
            }
        },
        data() {
            return Data.data();
        },
        watch: {
            'typeAuthentication' : function (type){
                this.typeAuth = type;
            }
        },
        methods: {

            changeTab(tab) {
                this.internal_config.activeTab = tab;
            },

            animation() {
                return true;
            },

            formatText(textStyle, active = false) {
                return {
                    fontFamily: textStyle.family,
                    fontSize: (this.device !== 'mobile' ? textStyle.size : textStyle.sizeMobile),
                    'text-align': textStyle.align,
                    'font-weight': textStyle.weight,
                    'font-style': textStyle.style,
                    'text-decoration': textStyle.stroke,
                    color: (active) ? this.internal_config.tabNav.textColorTabActive : textStyle.color
                };
            },
            style() {
                return `
                    left: ${this.internal_config.left};
                    paddingTop: ${this.internal_config.paddingTop};
                    padding-left: ${this.internal_config.paddingLeft};
                    z-index: ${this.internal_config.zIndex};
                    max-width: ${this.internal_config.maxWidth};
                    margin: 0 auto;
                    padding-right: ${this.internal_config.paddingRight};
                    padding-bottom: ${this.internal_config.paddingBottom};
                    top: ${this.internal_config.top}`;
            },

            getStyleNav(active = false) {
                let textStyle = this.formatText(this.internal_config.tabNav.textStyle, active);
                return {
                    backgroundColor: (active) ? this.internal_config.tabNav.backgroundTabActiveColor : this.internal_config.tabNav.backgroundTabColor,
                    border: this.internal_config.tabNav.border,
                    borderRadius: this.internal_config.tabNav.borderRadius,
                    boxShadow: this.internal_config.tabNav.boxShadow,
                    ...{...textStyle}
                };
            },

            getStyleNavContent() {
                return this.formatText(this.internal_config.tabContent.textStyle);
            }
        },
        created() {

            if(!this.isAuthenticated){
                this.goToHomePage();
            }

            this.internal_config = Object.assign({}, this.internal_config, window.mergeRecursive(this.props, this.$props.config));
            this.typeAuth = this.typeAuthentication;
            document.body.style.setProperty('--tab-user-bg-color--hover', this.internal_config.tabNav.backgroundTabHoverColor);

            if (!this.internal_config.activeTab && this.internal_config.visibleTabs)
                this.internal_config.activeTab = this.internal_config.visibleTabs[0];
        },

        mounted() {
            this.$root.$emit('applyAnimation', this, 'sitebuilder-element-container');
        }
    };
</script>

<style scoped lang="scss">

    .tab-content{
        padding: 15px;
    }

    .nav-pills .nav-link{
        min-width: 120px;
        transition: 500ms all;
    }

    .container-nav-user .nav-pills .nav-link:hover{
        background-color: var(--tab-user-bg-color--hover) !important;
    }

    [data-device="mobile"] [element="ContentNavUserComponent"] .container-nav-user {
        width: 100% !important;
        height: auto !important;
    }
</style>
