<template>
    <form class="mx-sm-5 mt-sm-3" autoComplete="off"  @submit.prevent="save">
        <p class="clean-text-css">{{ $t('ct-tab-profile-edit')}}</p>

        <template v-if="loading">
            <div class="d-flex justify-content-center mb-4 mt-2">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="row">
                <div class="alert alert-success col-12" role="alert" v-show="successUpdate">
                    {{successUpdate}}
                </div>
                <div class="alert alert-danger col-12" role="alert" v-show="errors.has('alert')">
                    {{errors.get('alert')}}
                </div>
            </div>
            <div class="form-row">

                <div class="form-group col-md-5" :class="{'has-error': errors.has('name') }">
                    <label for="member-area-name">{{ $t('ct-name')}}</label>
                    <input type="text" autoComplete="off" class="form-control" id="member-area-name" name="name" v-model="user.name"
                           :placeholder="$t('ct-name')" :class="{'is-invalid': errors.has('name') }" @keydown="errors.clear('name')">
                    <span v-if="errors.has('name')" class="invalid-feedback" v-text="errors.get('name')"></span>
                </div>

                <div class="form-group col-md-7" :class="{'has-error': errors.has('name') }">
                    <label for="member-area-email">{{ $t('ct-email')}}</label>
                    <input type="email" autoComplete="off" class="form-control" id="member-area-email" v-model="user.email" :placeholder="$t('ct-email')"
                           :class="{'is-invalid': errors.has('email') }"
                           @keydown="errors.clear('email')" disabled
                    />
                    <span v-if="errors.has('email')" class="invalid-feedback" v-text="errors.get('email')"></span>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-md-4" :class="{'has-error': errors.has('password') }">
                    <label for="member-area-password">{{ $t('ct-password-new') }}</label>
                    <input type="password"  class="form-control" id="member-area-password" name="password"
                           :class="{'is-invalid': errors.has('password') }"
                           :placeholder="$t('ct-password-new')"
                           @keydown="errors.clear('password')"
                           v-model="user.password" autoComplete="off"
                    />
                    <span v-if="errors.has('password')" class="invalid-feedback"
                          v-text="errors.get('password')"></span>
                </div>
                <div class="form-group col-md-4" v-if="user.password" :class="{'has-error': errors.has('password_confirmation') }">
                    <label for="member-area-password-confirmation">{{$t('ct-password-confirm')}}</label>
                    <input type="password" autoComplete="off" class="form-control" id="member-area-password-confirmation"
                           name="password_confirmation"
                           v-model="user.password_confirmation"
                           :class="{'is-invalid': errors.has('password_confirmation') }"
                           :placeholder="$t('ct-password-confirm')"
                           @keydown="errors.clear('password_confirmation')"
                           required
                    />
                    <span v-if="errors.has('password_confirmation')" class="invalid-feedback"
                          v-text="errors.get('password_confirmation')"></span>
                </div>
                <div class="form-group col-md-4" v-if="user.password" :class="{'has-error': errors.has('password_current') }">
                    <label for="member-area-password-current">{{$t('ct-password-current')}}</label>
                    <input type="password" autoComplete="off" class="form-control" id="member-area-password-current"
                           name="password_current"
                           v-model="user.password_current"
                           :class="{'is-invalid': errors.has('password_current') }"
                           :placeholder="$t('ct-password-current')"
                           @keydown="errors.clear('password_current')"
                           required
                    />
                    <span v-if="errors.has('password_current')" class="invalid-feedback"
                          v-text="errors.get('password_current')"></span>
                </div>
            </div>

            <div class="text-center">
                <button type="submit" class="btn" :style="buttonStyle">{{ $t('ct-save')}}</button>
            </div>
        </template>
    </form>

</template>

<script>
    import {mapGetters} from "vuex";
    import Errors from "../../../../js/Errors";
    import {updateUser} from "../../../../js/WebsiteUser";

    export default {
        name: 'Profile',
        props: ['buttonStyle'],
        data() {
            return {
                loading: false,
                successUpdate: false,
                errors: new Errors(),
                user: {
                    name: '',
                    email: '',
                    password: false,
                    password_confirmation: false,
                    password_current: false,

                },
            }
        },
        computed: {
            ...mapGetters(['userWebsite']),
        },
        methods: {
            save() {

                if (!this.errors.any()) {
                    this.loading = true;
                    this.successUpdate = false;

                    updateUser(this.user)
                        .then(res => {
                            if (res.data.success) {
                                this.successUpdate = this.$t('ct-profile-updated');
                                setTimeout(() => {
                                    this.successUpdate = false;
                                    window.location.reload();
                                }, 3000);
                                this.$store.dispatch('setUser', res.data.data);
                                this.user = this.userWebsite.user;
                            } else {
                                if (res.data.validator) {
                                    this.errors.record(res.data.errors);
                                }
                            }
                        })
                        .catch(error => {

                            this.errors.record({alert: [this.$t('ct-profile-update-error')]});

                        })
                        .finally(() => this.loading = false);
                }

            }
        },

        created() {
            this.user = this.userWebsite.user;
        }
    }
</script>

<style lang="scss" scoped>
    p {
        font-size: 18px !important;
        margin-bottom: 10px !important;
    }

    .btn {
        font-weight: bold;

        &:hover {
            background-color: var(--tab-user-bg-color--hover) !important;
        }
    }

    .form-control::placeholder, .form-control::placeholder-shown{
        text-decoration: none !important;
        font-weight: 500;
    }

    .alert:not(.alert-secondary) {
        color: #fff;
    }

    .alert {
        font-size: .875rem;
        padding: 1rem 1.5rem;
        border: 0;
        border-radius: .25rem;
    }

    .alert {
        position: relative;
        margin-bottom: 1rem;
        padding: 1rem 1.5rem;
        border: .0625rem solid transparent;
        border-radius: .25rem;
    }

    .alert-success {
        color: #fff;
        border-color: #4fd69c;
        background-color: #4fd69c;
    }

    .alert-danger {
        color: #fff;
        border-color: #f75676;
        background-color: #f75676;
    }
</style>