export default {
  data() {
    return {
      props: {
        'tag': '',
        'height': '150px',
        'width': '150px',
        'zIndex': 'auto','maxWidth':'768px',
        'rotation': '0deg',
        'opacity': 1,
        'src': 'https://omb11.com/franquias/2/181114/editor-html/2700563.jpeg',
        'border': '0 none #000000',
        'boxShadow': '1px 1px 1px 1px blue',
        'borderRadius': '0 0 0 0',
        'paddingTop': '15px',
        'paddingLeft': '15px',
        'paddingRight': '15px',
        'paddingBottom': '15px',
        'marginLeft': '0',
        'marginRight': '0',
        showNormal : false,
        'animation': {
          'in': {
            'action': 'time',   //time/scroll/click/hover
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'speed': 'slow',
            'infinite': false,
          },
          'out': {
            'action': 'time',   //time/scroll/click
            'time': 0,       // sec
            'scroll': 0,     // px
            'effect': 'none',
            'delay': '1',
            'speed': '1',
            'infinite': false,
          },
        },
      },
      componentKey: 0, // for force reload
      internal_config: {
        src: '',
        width: '150px',
        height: '150px',
        left: '0px',
        top: '0px',
        showDesktop: true,
        showMobile: true,
        opacity: 1,
        boxShadow: '0 0 0 0 black initial',
        borderRadius: '0 0 0 0',
        'paddingTop': '15px',
        'paddingLeft': '15px',
        'paddingRight': '15px',
        'paddingBottom': '15px',
        'marginLeft': '0',
        'marginRight': '0',
        'rotation': '0deg',
         
      },
      name: 'image',
      alt: String(),
      real_src: String(),
    }
  }
}
